import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";
import { useToast } from "../../../hooks/toast";

const useReceita = () => {

    const history = useHistory();
    const { addToast } = useToast();
    const [receita, setReceita] = useState([]);
    const [receitas, setReceitas] = useState([]);
    const [error, setError] = useState(null);

    async function getReceitas(params?: object) {
        try {
            const response = await api.get(`revenue${stringfyParams(params)}`);
            setReceitas(response.data);
        } catch (e) {
            setError(e);
        }
    }

    async function getReceita(id: string) {
        try {
            const response = await api.get(`revenue/${id}`);
            setReceita(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    }

    async function createReceita(data: object) {
        try {
            const response = await api.post('revenue', data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            addToast({
                type: 'success',
                title: 'Receita cadastradoa',
                description: 'Receita cadastrada com sucesso!',
            });
            history.push("/orcamentario/receita");
            return response;

        } catch (e) {
            setError(e);
        }
    }

    async function updateReceita(id: string, data: object) {
        try {
            const response = await api.put(`revenue/${id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            addToast({
                type: 'success',
                title: 'Receita atualizada!',
                description: 'Receita atualizada com sucesso!',
            });            
            history.push("/orcamentario/receita");
            return response
        } catch (e) {
            setError(e);
        }
    }


    return { receita, receitas, getReceitas, getReceita, createReceita, updateReceita, error };
}

export default useReceita;