
import { User } from "../hooks/auth";

const getPermission = (login:User, profile:string) => {
   
    const profiles = login.profiles;
    
    if(!profiles) {
        return '';
    }

    const profileModule = profiles.find((element) => element.tbl_module.nome == profile)


    return profileModule?.nome;
}


export default getPermission;