const organizeDataNumeroProcesso = (data: object, v: string,  l: string, m: string) => {
    if (typeof data === 'object') {
        data = data.map( d => 
            ({
                value: d[v],
                label: `${d[l]} - ( SIGAD - ${d[m]})`,
            })
        );
    }
    return data;
}
export default organizeDataNumeroProcesso;