import {
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import {
  ContainerButton, Form, FormContainer, Input, SelectInput, TextTitle, Textarea, Title
} from '../../../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useContratoGestao from "../../../../../actions/cruds/useContratoGestao";
import useOrgao from "../../../../../actions/cruds/useOrgao";
import useOrgaoGestor from "../../../../../actions/cruds/useOrgaoGestor";
import Panel from "../../../../../components/Panel";
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from "@mui/material";


export function RegContratoGestao() {

  const formRef = useRef<FormHandles>(null);

  const { createContrato } = useContratoGestao();

  const { orgaos, getOrganizations } = useOrgao();
  const { orgaoGestor, getOrgaoGestor } = useOrgaoGestor();

  const [valueOrgaoGestor, setValueOrgaoGestor] = useState<Object>();
  const [valueNumeroContrato, setValueNumeroContrato] = useState<Number>();


  useEffect(() => {
    getOrganizations();
    getOrgaoGestor();
  }, []);


  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        createContrato(data);
      } catch (err) {
        alert(err);
      }
    },
    [],
  );

  const organizeDataSelect = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.id,
        label: d.nome,
      }));
    }

    return data;
  }

  const handleChangeOrgaoGestor = (orgaoGestorSelected) => {
    setValueOrgaoGestor(orgaoGestorSelected.label)
  }

  const handleChangeNumeroContrato = (numeroContratoSelected) => {
    setValueNumeroContrato(numeroContratoSelected.target.value);
  }


  return (
    <Container>
      <Title color='#333'>
        <span>
          Cadastrar contrato de gestão
        </span>
      </Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Contrato de gestão">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Organização: </TextTitle>
              <SelectInput
                id='idOrganizacao'
                name='organization_id'
                options={organizeDataSelect(orgaos.data)}
                placeholder="Selecionar"
                required
              />
            </Grid>

            <Grid xs={6}>
              <TextTitle>Órgão gestor: </TextTitle>
              <SelectInput
                id='idOrgaogestor'
                name='management_entity_id'
                options={organizeDataSelect(orgaoGestor.data)}
                onChange={handleChangeOrgaoGestor}
                placeholder="Selecionar"
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de assinatura: </TextTitle>
              <Input type="date" name='data_assinatura' />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Prazo de delegação do contrato: </TextTitle>
              <Input type="date" name='prazo_delegacao' />
            </Grid>
            <Grid xs={6} lg={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input
                mask="__"
                replacement="allNumber"
                id="codigo"
                name='codigo'
                required
              />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Número do contrato: </TextTitle>
              <Input type="text" name='nome' onChange={handleChangeNumeroContrato} required />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Contrato de gestão:</TextTitle>
              <Input type="text" name='valor final' disabled value={valueOrgaoGestor && valueNumeroContrato ? `CG - ${valueOrgaoGestor} - ${valueNumeroContrato}` : null} required />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Resolução do conselho">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Resolução do conselho:</TextTitle>
              <Textarea name="resolucao_conselho"></Textarea>
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/orcamentario/cadastros/contratosgestao">
          <button>
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <button type="submit" onClick={() => formRef.current?.submitForm()}>
          <FontAwesomeIcon icon={faSave} /> Salvar
        </button>
      </ContainerButton>
    </Container>
  )
}