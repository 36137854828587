import React, { useCallback, useRef } from 'react';
import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton, Input, TextTitle, Title } from '../../../../../styles/global';
import Panel from '../../../../../components/Panel';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faX } from '@fortawesome/free-solid-svg-icons';
import useInstanciaProtocolo from '../../../../../actions/cruds/Protocolo/useInstanciaProtocolo';
// import { Container } from './styles';


const InstanciaProtocoloCreate: React.FC = () => {
    const { createInstanciaProtocolo } = useInstanciaProtocolo();

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => {
            try {
                console.log("data", data);
                createInstanciaProtocolo(data);
            } catch (err) {
                console.log(err);
            }
        }, [],
    );

    return (
        <Container>
            <Title>
                <span>Cadastrar Instância do Protocolo</span>
            </Title>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Instância'>
                    <Grid container spacing={6}>
                        <Grid xs={12}>
                            <TextTitle>Nome:</TextTitle>
                            <Input type="text" name='nome' required />
                        </Grid>
                    </Grid>
                </Panel>
                <ContainerButton>
                    <Link to="/protocolo/cadastros/instancia">
                        <button><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
                    </Link>
                    <button type="submit" form="form1"><FontAwesomeIcon icon={faSave} /> Salvar</button>
                </ContainerButton>
            </Form>
        </Container>
    );
};

export default InstanciaProtocoloCreate;