import styled from "styled-components";
import SecondaryTable from "../../../../components/SecondaryTable";

export const BudgetTable = styled(SecondaryTable)`
    & .MuiDataGrid-columnHeader, .MuiDataGrid-cell {
        border-right: 1px solid #f0f0f0;
    }

    & .MuiDataGrid-columnHeader:last-child, .MuiDataGrid-cell:last-child {
        border-right: none;
    }

    & .pap--finalidade {
        background-color: #305496;
        color: #fff;

        &:hover {
            background-color: #305496!important;
        }
        &.Mui-selected {
            background-color: #305496!important;
        }
    }

    & .pap--programa {
        background-color: #6B95C7;
        color: #fff;

        &:hover {
            background-color: #6B95C7!important;
        }
        &.Mui-selected {
            background-color: #6B95C7!important;
        }
    }
    & .pap--acao {
        background-color: #D9E1F2;
        color: #000;

        &:hover {
            background-color: #D9E1F2!important;
        }
        &.Mui-selected {
            background-color: #D9E1F2!important;
        }
    }

    & .pap--subacao {
        background-color: #fff;
    }

    & .pap--total {
        background-color: #0f243e;
        color: #fff;

        &:hover {
            background-color: #0f243e!important;
        }
        &.Mui-selected {
            background-color: #0f243e!important;
        }
    }
`;