import { PDFViewer } from '@react-pdf/renderer'
import DocumentPage from '../../../../components/PDFComponents/DocumentPage';
import { useEffect, useState } from 'react';
import { formatCPF, formatarData, formatarReais } from '../../../../utils/formatDados';
import { useParams } from 'react-router-dom';
import useInstrumento from '../../../../actions/cruds/InstrumentoContratual/useInstrumento';
import useDotacao from '../../../../actions/cruds/Orcamentario/useDotacao';

const OrcamentarioDotacaoFichaPDF: React.FC = () => {
  const { dotacaoId } = useParams();
  const { dotacao, getDotacao } = useDotacao();
  const { instrumento, getInstrumento } = useInstrumento();

  const [justificativa, setJustificativa] = useState<Array<Object>>([]);
  const [linhas, setLinhas] = useState<Array<Object>>([]);

  useEffect(() => {
    getDotacao(dotacaoId);
  }, [])

  useEffect(() => {
    if (dotacao?.id) {

      if (dotacao?.allocation_budgets?.length > 0) {
        const updatedLinhas = []

        dotacao?.allocation_budgets?.forEach((linha, index) => {
          if (index == 0) {
            updatedLinhas.push(
              {
                id: "linhas",
                row: "",
                value: "Linhas",
              },
            )
          }
          updatedLinhas.push(
            {
              id: "linha",
              row: `Linha ${index + 1}:`,
              value: `Código do Projeto: ${linha?.project_line?.expense_type?.codigo}.${linha?.project_line?.organization?.codigo.padStart(2, '0')}.${linha?.project_line?.management_contract?.codigo.padStart(2, '0')}.${linha?.project_line?.comittee?.codigo.padStart(2, '0')}.${linha?.project_line?.unit?.codigo.padStart(2, '0')}.${linha?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${linha?.project_line?.pap_program?.codigo.padStart(2, '0')}.${linha?.project_line?.pap_action?.codigo.padStart(2, '0')}.${linha?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${dotacao?.law_process?.project?.id.toString().padStart(2, '0')}.${linha?.project_line?.description?.codigo.padStart(3, '0')}\nCódigo PAP: ${linha?.project_line?.pap_finality?.codigo}.${linha?.project_line?.pap_program?.codigo}.${linha?.project_line?.pap_action?.codigo}\nValor da Dotação: ${formatarReais(linha?.valor)}`
            },
          );
        });

        setLinhas(updatedLinhas);
      };

      if (dotacao?.parecer == 'Não Prosseguir') {
        setJustificativa([
          {
            id: "justificativa",
            row: "Justificativa:",
            value: dotacao?.justificativa ?? "-"
          },
        ]);
      };
    };

  }, [dotacao])

  if (dotacao?.id) {
    const pdfData = {
      version: "Formulário eletrônico - SIGA Águas",
      title: "FICHA DE DOTAÇÃO",
      items: [
        {
          id: "agevapImg",
          logoWithColumns: true,
          itemsColumn: [
            {
              id: "atesteNumero",
              row: "DOTAÇÃO Nº",
              value: dotacao?.numero ?? "-"
            },
            {
              id: "instrumentoNumero",
              row: "PROCESSO Nº",
              value: dotacao?.law_process?.codigo ?? "-"
            },
          ]
        },
        {
          id: "codigoNup",
          row: "Código NUP - SIGAD:",
          value: dotacao?.codigo_nup ?? "-"
        },
        {
          id: "dataSolicitacao",
          row: "Data da solicitação:",
          value: formatarData(dotacao?.data_solicitacao, false) ?? "-"
        },
        {
          id: "assunto",
          row: "Assunto:",
          value: dotacao?.assunto ?? "-"
        },
        {
          id: "tipo",
          row: "Tipo de atividade:",
          value: `[ ${dotacao?.tipo_atividade == 'Meio' ? "X" : "  "} ] Meio           [ ${dotacao?.tipo_atividade == 'Fim' ? "X" : "  "} ] Fim`,
        },
        {
          id: "rateio",
          row: "O projeto é rateio?",
          value: `[ ${dotacao?.rateio ? "X" : "  "} ] Sim           [ ${dotacao?.rateio ? "  " : "X"} ] Não`,
        },
        {
          id: "parecer",
          row: "Parecer da solicitação de pagamento:",
          value: `[ ${dotacao?.parecer == 'Prosseguir' ? "X" : "  "} ] Prosseguir           [ ${dotacao?.parecer == 'Não Prosseguir' ? "X" : "  "} ] Não Prosseguir`,
        },
      ],
    };

    if (dotacao?.parecer == 'Não Prosseguir' && justificativa?.length > 0) {
      justificativa.forEach((just) => pdfData.items.push(just));
    };
    if (linhas?.length > 0) {
      linhas.forEach((linha) => pdfData.items.push(linha));
    };

    return (
      <PDFViewer width="100%" height="1300" className="app" >
        <DocumentPage data={pdfData} />
      </PDFViewer>
    );
  };
};


export default OrcamentarioDotacaoFichaPDF;