import styled from 'styled-components';

import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import lateralMenu from '../../assets/lateralMenu.svg';

export const Container = styled.div`
    /* position: relative; */
    height: 100vh;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -85px;
        width: 87px;
        background-image: url(${lateralMenu});
        background-size: contain;
        background-repeat: no-repeat;
    }
`;


export const StyledSidebar = styled(Sidebar).attrs({
    backgroundColor: '#77CCD0'
})`
    height: 100%;
    overflow-y: hidden!important;

    .ps-submenu-content {
        background: transparent;
    }

    nav {
        min-height: 90%;
    }
`;

export const StyledMenu = styled(Menu).attrs({
})`
    min-height: 100%;
`;

export const StyledMenuItem = styled(MenuItem).attrs({
})`
    color: #fff;

    &:hover {
        a {
            background-color: #00aeef!important;
        }
    }
`;

export const StyledSubMenu = styled(SubMenu).attrs({
})`
    color: #fff;

    &:hover > a {
        
            background-color: #00aeef!important;
        
    }
`;

export const CollapseBtn = styled.button`
    position: absolute;
    top: 30px;
    right: -90px;
    border: none;
    z-index: 999;
`;