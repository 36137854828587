import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../styles/global";
import Panel from "../../../../components/Panel";
import { Button, Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { SaveWithProgress } from "../../../../components/SaveCircularIntegration";
import { formatarReais } from "../../../../utils/formatDados";
import organizeDataNumeroProcesso from "../../../../utils/organizeDataNumeroProcesso";
import useProcesso from "../../../../actions/cruds/Processos/useProcesso";
import Radio from "../../../../components/InputRadio";
import useProjeto from "../../../../actions/cruds/useProjeto";
import SecondaryTable from "../../../../components/SecondaryTable";
import { GridColDef, GridRenderEditCellParams, GridValueFormatterParams, useGridApiRef } from "@mui/x-data-grid";
import { useToast } from "../../../../hooks/toast";
import InputNumberFormat from "../../../../components/InputNumberFormat";
import useDotacao from "../../../../actions/cruds/Orcamentario/useDotacao";

const OrcamentarioDotacaoCreate: React.FC = () => {
  const { createDotacao } = useDotacao();
  const { processos, getProcessos, processo, getProcesso } = useProcesso();
  const { projeto, getProjetoId } = useProjeto();

  const [salvando, setSalvando] = useState<boolean>(false);
  const [linhasProjeto, setLinhasProjeto] = useState<Array<Object>>([]);
  const [valorLinhas, setValorLinhas] = useState<Array<Object>>([]);
  const { addToast } = useToast();

  const [rubricaFront, setRubricaFront] = useState([]);
  const [rubricaBack, setRubricaBack] = useState([]);

  useEffect(() => {
    getProcessos([{ label: 'ativo', value: true }]);
    formRef.current?.setFieldValue("data_solicitacao", new Date().toISOString().split('T')[0])
  }, []);

  useEffect(() => {
    if (processo?.id) {
      formRef.current?.setFieldValue("rateio", `${processo?.project?.rateio}`);
      setLinhasProjeto([]);
      if (processo?.project) {
        getProjetoId(processo?.project.id);
      }
    };

  }, [processo]);

  useEffect(() => {
    if (projeto?.id) {
      setLinhasProjeto(organizeDataLinhas(projeto?.project_lines))
      setValorLinhas(organizeDataLinhas(projeto?.project_lines))
    }
  }, [projeto]);

  const organizeDataLinhas = (data: any[]) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          codigo: `${d?.expense_type?.codigo}.${d?.organization?.codigo.padStart(2, '0')}.${d?.management_contract?.codigo.padStart(2, '0')}.${d?.comittee?.codigo.padStart(2, '0')}.${d?.unit?.codigo.padStart(2, '0')}.${d?.pap_finality?.codigo.padStart(2, '0')}.${d?.pap_program?.codigo.padStart(2, '0')}.${d?.pap_action?.codigo.padStart(2, '0')}.${d?.poa_sub_action?.codigo.padStart(2, '0')}.${projeto.id?.toString().padStart(2, '0')}.${d?.description?.codigo.padStart(3, '0')}`,
          pap: `${d.pap_finality.codigo}.${d.pap_program.codigo}.${d.pap_action.codigo}`,
          val: 0,
        }));
        return data;
      }
    };
    return [];
  };

  const tipoAtividadeOptions = [
    { value: "Meio", label: "Meio" },
    { value: "Fim", label: "Fim" },
  ];

  const optionsBoolean = [
    { id: 'true', value: true, label: "Sim" },
    { id: 'false', value: false, label: "Não" },
  ];

  const apiRef = useGridApiRef();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true)

        if (linhasProjeto?.length > 0) {
          const rowsData = Array.from(apiRef.current.getRowModels(), ([, value]) => (value));
          console.log("rowsdata", rowsData);
          const rubricaSemValor = rowsData.every(row => row.valor);
          if (!rubricaSemValor) {
            addToast({
              type: 'error',
              title: 'Erro',
              description: "Todas as linhas da Rubrica orçamentária devem conter valor.",
            });
            return;
          }

          data["allocation_budgets_attributes"] = rowsData.map(d => ({
            project_line_id: d.id,
            valor: d.valor,
          }));
        }

        data["situacao"] = "aguardando_validacao";

        console.log("data", data);
        await createDotacao(data);
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    }, [linhasProjeto]
  );

  const dataTitleLinhas: GridColDef[] = [
    { field: "codigo", headerName: "Código do projeto", flex: 3 },
    { field: "pap", headerName: "Código do PAP", flex: 1 },
    {
      field: "valor",
      headerName: "Valor da Dotação",
      editable: true,
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "R$ 0,00";
        }
        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <InputNumberFormat {...params} />
      ),
    },
  ];

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  const handleUpdateLinha = (updatedRow: Object) => {
    const objUpdatedRow = { id: updatedRow?.id, val: updatedRow?.valor };
    const updatedValueLinhas = [...valorLinhas.filter((linha) => linha.id !== updatedRow?.id), objUpdatedRow];
    setValorLinhas(updatedValueLinhas)

    return updatedRow
  };

  return (
    <Container>
      <Form id="formDotacao" ref={formRef} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
        <Title color="#333">
          <span>Cadastrar Dotação</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data da solicitação:</TextTitle>
              <Input type="date" name="data_solicitacao" required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número do processo:</TextTitle>
              <SelectInput
                name="law_process_id"
                placeholder="Selecione..."
                options={organizeDataNumeroProcesso(processos.data, "id", "codigo", "numero")}
                onInputChange={(e) => {
                  getProcessos([{ label: 'numero', value: e }, { label: 'ativo', value: true }]);
                }}
                onChange={(e) => getProcesso(e.value)}
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Assunto:</TextTitle>
              <Input type="text" name="assunto" required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Tipo de atividade:</TextTitle>
              <SelectInput
                name="tipo_atividade"
                placeholder="Selecione..."
                options={tipoAtividadeOptions}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>O projeto é um rateio?</TextTitle>
              <Radio
                name="rateio"
                options={optionsBoolean}
                required
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Linha">
          <Grid container spacing={6}>
            {linhasProjeto?.length > 0 ? (
              <>
                <Grid xs={12}>
                  <SecondaryTable
                    apiRef={apiRef}
                    columsData={dataTitleLinhas}
                    rowsData={linhasProjeto}
                    processRowUpdate={(updatedRow: Object) => handleUpdateLinha(updatedRow)}
                    onProcessRowUpdateError={(error) => console.log(error)}
                  />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Total: {formatarReais(valorLinhas?.reduce(function (acc, obj) { return acc + parseFloat(obj?.val); }, 0))}</TextTitle>
                </Grid>
              </>
            ) :
              <Grid xs={12}>
                <TextTitle>Não há linhas cadastradas.</TextTitle>
              </Grid>
            }
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/orcamentario/dotacao">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formDotacao"}></SaveWithProgress>
      </ContainerButton2>
    </Container >
  );
};

export default OrcamentarioDotacaoCreate;
