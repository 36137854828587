import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import MainTable from "../../../../components/MainTable";
import api from "../../../../services/api";
import { Title } from "../../../../styles/global";

const TipoProcesso: React.FC = () => {
  const [tiposProcessos, setTiposProcessos] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      api
        .get(`law_process_types?busca_rapida=${searchValue}`)
        .then((response) => {
          setTiposProcessos(response.data);
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`demand_origins?page=${tiposProcessos.pagination.current_page + page}`)
      .then((response) => {
        setTiposProcessos(response.data);
      })
      .catch((error) => console.log(error));
  };

  const columnData = [
    { headerName: "ID", field: "id" },
    { headerName: "Tipo do Processo", field: "nome" },
    { headerName: "Situação", field: "ativo" },
  ];

  const organizeData = (data: any) => {
    if (typeof data === "object") {
      data = data.map((d: any) => ({
        id: d.id,
        nome: d.nome,
        ativo: d.ativo,
      }));
    }

    return data;
  };

  return (
    <>
      <Title color="#333">
        <span>Gestão de Tipo do Processo</span>
      </Title>
      <Container color="#FFF">
        <MainTable
          onSearchChange={setSearchValue}
          data={organizeData(tiposProcessos.data)}
          pagination={tiposProcessos.pagination}
          handlePagination={handlePagination}
          hideFilter={true}
          isMaxWidthCell
          registerLink="/processos/cadastros/tipoprocesso/cadastrar"
          columns={columnData}
          dropdown={{
            view: { linkTo: "/processos/cadastros/tipoprocesso/visualizar" },
            editar: { linkTo: "/processos/cadastros/tipoprocesso/editar" },
            desativar: { linkTo: "law_process_types" },
          }}
        />
      </Container>
    </>
  );
};

export default TipoProcesso;
