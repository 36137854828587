import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useProcessoTipos = () => {

    const history = useHistory();
    const [tiposProcessos, setTiposProcessos] = useState([]);
    const [tipoProcesso, setTipoProcesso] = useState({});
    const [error, setError] = useState(null);

    async function getTiposProcessos(params?: object) {
        try {
            const response = await api.get(`law_process_types${stringfyParams(params)}`);
            setTiposProcessos(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getTipoProcesso(id: string) {
        try {
            const response = await api.get(`law_process_types/${id}`);
            // console.log(response);
            setTipoProcesso(response.data);
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createTipoProcesso(data: any) {
        try {
            const response = await api.post('law_process_types', data);
            alert("Tipo de Processo cadastrado com sucesso!");
            history.push("/processos/cadastros/tipoprocesso");

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro ao cadastrar Processo");
            setError(e);
            // console.log(error);
        }
    }

    async function updateTipoProcesso(id: number, data: any) {
        try {
          const response = await api.put(`law_process_types/${(id)}`, data);
          alert("Tipo do Processo atualizado com sucesso!");
          history.push("/processos/cadastros/tipoprocesso");
        } catch (e) {
          setError(e);
          // console.log(error);
        }
      }


    return {tiposProcessos, getTiposProcessos, tipoProcesso, getTipoProcesso, createTipoProcesso, updateTipoProcesso, error};
}

export default useProcessoTipos;