import React, { useEffect, useState } from "react";
import { CSidebar } from "../../../components/Sidebar";
import Header from "../../../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faClock, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Badge,
  Container,
  SearchContainer,
  StyledDataCell,
  StyledHeaderCell,
  StyledTable,
} from "./styles";
import api from "../../../services/api";
import ProcessoModal from "../../../components/ProcessoModal";
import { formatarData } from "../../../utils/formatDados";
import { Link, useParams } from "react-router-dom";
import { ContainerButton, Title } from "../../../styles/global";
import MainTable from "../../../components/MainTable";
import BadgeTable from "../../../components/BadgeTable";

const ProcessoAcaoPlanejamento: React.FC = () => {
  const [planejamentosProcesso, setPlanejamentosProcesso] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const { processoId } = useParams();

  console.log(planejamentosProcesso)

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      api
        .get(`law_process_plannings?law_process_id=${processoId}&busca_rapida=${searchValue}`)
        .then((response) => {
          setPlanejamentosProcesso(response.data);
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`law_process_plannings?law_process_id=${processoId}&page=${planejamentosProcesso.pagination.current_page + page}`)
      .then((response) => {
        setPlanejamentosProcesso(response.data);
      })
      .catch((error) => console.log(error));
  };


  const organizeDataAcaoPlanejamento = (data: any[]) => {
    const withoutData = <i>Sem dados</i>;
    const withoutDateInit = <i>Não iniciado</i>;
    const withoutDateDefine = <i>Não definido</i>;

    if (typeof data === "object") {
      data = data.map((item) => {
        const status = item.status ? optionsStatus.find((status) => status.value == item.status)?.label : withoutData;
        const previsao_inicio =
          formatarData(item.previsao_inicio, false) ?? withoutDateInit;
        const previsao_termino =
          formatarData(item.previsao_termino, false) ?? withoutData;
        const data_inicio = item?.data_inicio ?
          formatarData(item.data_inicio, false) : withoutDateInit;
        const data_termino = item?.data_termino ?
          formatarData(item.data_termino, false) : withoutDateDefine;

        return {
          status: status,
          previsao_inicio: previsao_inicio,
          previsao_termino: previsao_termino,
          data_inicio: data_inicio,
          data_termino: data_termino,
          id: item.id,
          item: item.law_process_category_action.item,
          acao: item.law_process_category_action.nome,
          situacao: <BadgeTable status={item.situacao} tempo={true} />,
        };
      });

      data.sort((a, b) => a.item - b.item);
    }

    return data;
  };

  const optionsStatus = [
    { value: "em_andamento", label: "Em Andamento" },
    { value: "a_fazer", label: "A fazer" },
    { value: "concluido", label: "Concluído" },
  ];

  const columnData = [
    { headerName: "Item", field: "item" },
    { headerName: "Ação", field: "acao" },
    { headerName: "Status", field: "status" },
    { headerName: "Situação", field: "situacao" },
    { headerName: "Prev. Início", field: "previsao_inicio" },
    { headerName: "Prev. Término", field: "previsao_termino" },
    { headerName: "Data Inicial", field: "data_inicio" },
    { headerName: "Data Conclusão", field: "data_termino" },
  ];

  return (
    <>
      <Title color="#333" >
        <span>{planejamentosProcesso?.data ? planejamentosProcesso?.data[0]?.law_process_category_action?.law_process_category?.nome : ""}</span>
      </Title>
      <Container color="#FFFFFF">
        <MainTable
          onSearchChange={setSearchValue}
          data={organizeDataAcaoPlanejamento(planejamentosProcesso.data)}
          pagination={planejamentosProcesso.pagination}
          handlePagination={handlePagination}
          isMaxWidthCell
          hideCreate={true}
          columns={columnData}
          dropdown={{
            atualizarAcaoPlanejamento: { linkTo: "/processos/acaoplanejamento/atualizar" },
          }}
        />
      </Container>
      <ContainerButton>
        <Link to="/processos">
          <button><FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar</button>
        </Link>
      </ContainerButton>
    </>
  );
};

export default ProcessoAcaoPlanejamento;
