import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useProcesso = () => {

    const history = useHistory();
    const [processos, setProcessos] = useState([]);
    const [processo, setProcesso] = useState({});
    const [error, setError] = useState(null);

    async function getProcessos(params?: object) {
        try {
            const response = await api.get(`law_processes${stringfyParams(params)}`);
            setProcessos(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getProcesso(id: string) {
        try {
            const response = await api.get(`law_processes/${id}`);
            // console.log(response);
            setProcesso(response.data);
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createProcesso(data: any) {
        try {
            const response = await api.post('law_processes', data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            alert("Processo criado com sucesso!");
            history.push("/processos");

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro ao cadastrar Processo");
            setError(e);
            // console.log(error);
        }
    }

    async function updateProcesso(id: string, data: any) {
        try {
            const response = await api.put(`law_processes/${id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            alert("Processo atualizado com sucesso!");
            history.push("/processos");

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro ao atualizar Processo");
            setError(e);
            // console.log(error);
        }
    }

    async function updateProcessoAcaoPlanejamento(id: string, data: any) {
        try {
            const response = await api.put(`law_processes/${id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro ao atualizar Processo");
            setError(e);
            // console.log(error);
        }
    }

    async function getProcessoXls(data?: any) {
        try {
            const response = await api.get(`law_processes/xls`, {
                responseType: "blob", // importante!
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Processos.xlsx");
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }


    return { processos, processo, getProcessos, getProcesso, createProcesso, updateProcesso, updateProcessoAcaoPlanejamento, getProcessoXls, error };
}

export default useProcesso;