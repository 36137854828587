import {
  faCloudArrowUp,
  faPlus,
  faSave,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import useComite from "../../../actions/cruds/useComite";
import useInstancia from "../../../actions/cruds/useInstancia";
import useInstituicao from "../../../actions/cruds/useInstituicao";
import useRepresentanteInstituicao from "../../../actions/cruds/useRepresentanteInstituicao";
import Panel from "../../../components/Panel";
import bankApi from "../../../services/bankApi";
import Grid from "@mui/material/Unstable_Grid2";

import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton,
  Form,
  Input,
  InputAddBtn,
  SelectInput,
  TextTitle,
  Title,
} from "../../../styles/global";

import { FormHandles } from "@unform/core";
import useEndereco from "../../../actions/cruds/useEndereco";
import InputFile from "../../../components/InputFile";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import { Link } from "react-router-dom";
import emailChecker from "../../../utils/emailChecker";

export function RegRepresentante() {
  const [banks, setBanks] = useState([]);
  const [institutionCompetences, setInstitutionCompetences] = useState([]);
  const [institutionCompetencesNames, setInstitutionCompetencesNames] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [competenceOptions, setCompetenceOptions] = useState([]);
  const [instanceOptions, setInstanceOptions] = useState([]);
  const [valueInstitution, setValueInstitution] = useState([]);
  const [valueCompetence, setValueCompetence] = useState([]);
  const [valueInstance, setValueInstance] = useState([]);

  const { getAddress, endereco, setEndereco } = useEndereco();
  const { instituicoes, getInstituicoes, instituicao, getInstituicao } = useInstituicao();
  const { createRepresentanteInstituicao } = useRepresentanteInstituicao();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await bankApi.get("banks/v1");
        setBanks(
          data.map((d) => ({
            value: d.fullName,
            label: `${d.code ? d.code + " - " : ""} ${d.fullName}`,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    getInstituicoes();

  }, []);

  const handlerContact = (btn) => {
    const randon_id = Date.now();
    const tipoContato = btn.target.id;

    if (tipoContato === 'email') {
      const emailValue = formRef.current?.getFieldValue("email");
      const emailValido = emailChecker(emailValue);

      if (!emailValido) {
        alert("Email inválido! Insira um email valido. Ex: 'email@email.com'");
        return;
      }
    };

    if (tipoContato != "telefone" && tipoContato != "email") {
      return;
    };

    setContacts([
      ...contacts,
      {
        front_id: randon_id,
        tipo: btn.target.id,
        contato:
          btn.target.id === "telefone"
            ? formRef.current.getFieldValue("telefone")
            : formRef.current.getFieldValue("email"),
      },
    ]);

    formRef.current?.setFieldValue("telefone", "");
    formRef.current?.setFieldValue("email", "");
  };

  const optionsUF = [
    { value: "AC", label: "AC - Acre" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "BA", label: "BA - Bahia" },
    { value: "CE", label: "CE - Ceará" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "GO", label: "GO - Goiás" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "PA", label: "PA - Pará" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PI", label: "PI - Piauí" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RR", label: "RR - Roraima" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "EX", label: "EX - Estrangeiro" },
  ];

  const dataAddress = () => {
    getAddress(formRef.current.getFieldValue("cep"));
  };

  useEffect(() => {
    // necessario para selecionar programaticamente uma opção do select
    const selected = optionsUF.find((o) => o.value === endereco.uf);
    setSelectedOption(selected);
  }, [endereco]);

  const handleChangeSelect = (selectedOption) => {
    // necessario para selecionar programaticamente uma opção do select
    setSelectedOption(selectedOption);
  };

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        data["representative_contacts_attributes"] = contacts;

        console.log("dataSubmit", data);
        createRepresentanteInstituicao(data);

        // addToast({
        //   type: 'success',
        //   title: 'Login efetuado',
        //   description: 'Seu login foi feito com sucesso!',
        // });

        // history.push('/dashboard');
      } catch (err) {
        // addToast({
        //   type: 'error',
        //   title: 'Erro na autenticação',
        //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        // });
      }
    },
    [contacts]
  );

  const dataTitleContact: GridColDef[] = [
    { field: "tipo", headerName: "Tipo", flex: 1 },
    { field: "contato", headerName: "Contato", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteContactClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  function onDeleteContactClick(
    e: MouseEvent<HTMLButtonElement, MouseEvent>,
    row: any
  ): void {
    setContacts(contacts.filter((el) => el.front_id !== row.front_id));
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Cadastro de representantes</span>
        </Title>
        <Panel titulo="Identificação do representante">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>
                CPF: <span>*</span>
              </TextTitle>
              <Input name="cpf" type="text" mask="cpf" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Data de nascimento: <span>*</span>
              </TextTitle>
              <Input name="data_nascimento" type="date" />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>
                Nome: <span>*</span>
              </TextTitle>
              <Input name="nome" type="text" required/>
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                RG: <span>*</span>
              </TextTitle>
              <Input name="rg" type="text" mask="rg" />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Órgão Expedidor: <span>*</span>
              </TextTitle>
              <Input name="orgao_expedidor" type="text" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                CNH: <span>*</span>
              </TextTitle>
              <Input name="cnh" type="text" mask="cnh" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Data de vencimento: <span>*</span>
              </TextTitle>
              <Input name="cnh_data_vencimento" type="date" />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "150px" }}
                  type="text"
                  name="cep"
                  mask="cep"
                />
                <InputAddBtn type="button" onClick={dataAddress}>
                  <FontAwesomeIcon icon={faPlus} /> Pesquisar CEP{" "}
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro: </TextTitle>
              <Input
                type="text"
                name="logradouro"
                value={endereco.logradouro}
                onChange={(e) =>
                  setEndereco({ ...endereco, logradouro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro: </TextTitle>
              <Input
                type="text"
                name="bairro"
                value={endereco.bairro}
                onChange={(e) =>
                  setEndereco({ ...endereco, bairro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input type="text" name="numero" />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input
                type="text"
                name="complemento"
                value={endereco.complemento}
                onChange={(e) =>
                  setEndereco({ ...endereco, complemento: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={endereco.localidade}
                onChange={(e) =>
                  setEndereco({ ...endereco, localidade: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF: </TextTitle>
              <SelectInput
                name="uf"
                options={optionsUF}
                value={selectedOption}
                onChange={handleChangeSelect}
                placeholder="Selecionar"
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Contato">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Email:</TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "115px" }}
                  type="text"
                  name="email"
                />
                <InputAddBtn
                  id="email"
                  name="email_contato"
                  type="button"
                  onClick={handlerContact}
                >
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs={6}>
              <TextTitle>Telefone:</TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "115px" }}
                  type="text"
                  name="telefone"
                  mask="phone"
                />
                <InputAddBtn
                  id="telefone"
                  name="telefone_contato"
                  type="button"
                  onClick={handlerContact}
                >
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            {contacts.length > 0 && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleContact}
                  rowsData={contacts}
                  getRowId={(row) => row.front_id}
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Dados bancários">
          <Grid container spacing={6}>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Tipo de conta: <span>*</span>
              </TextTitle>
              <SelectInput
                id="banco_tipo_conta"
                name="banco_tipo_conta"
                options={[
                  { value: "corrente", label: "Conta Corrente" },
                  { value: "poupanca", label: "Conta Poupança" },
                ]}
                placeholder="Selecionar"
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Banco: <span>*</span>
              </TextTitle>
              <SelectInput
                id="banco_nome"
                name="banco_nome"
                options={banks}
                placeholder="Selecionar"
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Agência: <span>*</span>
              </TextTitle>
              <Input name="banco_agencia" type="text" />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Conta: <span>*</span>
              </TextTitle>
              <Input name="banco_conta" type="text" />
            </Grid>
          </Grid>
        </Panel>

        <ContainerButton>
          <Link to="/membros/representantes">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
            </button>
          </Link>
          <button>
            <FontAwesomeIcon icon={faSave} /> Salvar
          </button>
        </ContainerButton>
      </Form>
    </Container>
  );
}
