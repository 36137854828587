import {
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import {
  ContainerButton, Form, Input, SelectInput, TextTitle, Title
} from '../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef } from "react";
import useProjeto from "../../../actions/cruds/useProjeto";
import Panel from "../../../components/Panel";
import { Container } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Radio from "../../../components/InputRadio";
import organizeDataSelect from "../../../utils/organizeDataSelect";
import useDemandas from "../../../actions/cruds/useDemandas";
import { Link, useParams } from "react-router-dom";


interface ProjetoProps{
  codigo: string;
  nome: string;
  ativo: string;
}

export function EditProjeto() {

  const {idProjeto} = useParams();

  const formRef = useRef<FormHandles>(null);

  const { updateProjeto, getProjetoId, projeto } = useProjeto();

  const {getDemandas, demandas, getDemanda, demanda} = useDemandas();

  useEffect(() => {
    getProjetoId(idProjeto);
    getDemandas();
  }, []);

  useEffect(() => {
    formRef.current?.setFieldValue("demand_id", [{label: projeto.demand.codigo, value: projeto.demand.id}]);
    formRef.current?.setFieldValue("tipo_atividade", [{label: projeto.tipo_atividade, value: projeto.tipo_atividade}]);
    formRef.current?.setFieldValue("rateio", projeto.rateio ? "true" : "false");
  }, [projeto]);
  
  const handleSubmit = useCallback(
    async(data: ProjetoProps) => {
      try {  
        updateProjeto(idProjeto, data);
        // console.log(data);
      } catch(err) {

      }
    }, []
  );

  const onChangeProtocolo = (selectedDemanda: object) => {
    // console.log(selectedDemanda);
    const selectedDemandaObj = Array.isArray(selectedDemanda) ? selectedDemanda[0] : selectedDemanda;
    if (selectedDemandaObj.value) {
      formRef.current?.clearField("origem");
      getDemanda(selectedDemandaObj.value);
    }
  };

  useEffect(() => {
    formRef.current?.setFieldValue("origem", demanda.demand_origin.nome);
  }, [demanda]);

  return (
        <Container>
          <Title color='#333'>
            <span>
              Editar projeto
            </span>
          </Title>

            <Form id="projectForm" ref={formRef} onSubmit={handleSubmit}>
              <Panel titulo="Identificação">
                <Grid container spacing={6}>
                  <Grid xs={12} lg={6}>
                    <TextTitle>Código do protocolo:  <span>*</span></TextTitle>
                    <SelectInput 
                      id='demand_id'
                      name='demand_id'
                      options={organizeDataSelect(demandas.data, "id", "codigo")}
                      onInputChange={d => { getDemandas([{label: "codigo", value: d}]) } }
                      onChange={onChangeProtocolo}
                      placeholder="Selecione"
                      required
                    />
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <TextTitle>Origem da demanda: </TextTitle>
                    <Input type="text" name='origem' disabled/>
                  </Grid>

                  <Grid xs={6}>
                    <TextTitle>Tipo de atividade: </TextTitle>
                    <SelectInput 
                      id='tipo_atividade'
                      name='tipo_atividade'
                      options={[{label: "Meio", value: "Meio"}, {label: "Fim", value: "Fim"}]}
                      placeholder="Selecione"
                    /> 
                  </Grid>
                  <Grid xs={6}>
                    <TextTitle>O projeto será um rateio? <span>*</span></TextTitle>
                    <Radio
                        name="rateio"
                        options={[{ id: "true", value: "true", label: " Sim" }, { id: "false", value: "false", label: " Não" }]}
                        required
                      />
                  </Grid>

                  <Grid xs={12}>
                    <TextTitle>Descrição do Projeto: <span>*</span></TextTitle>
                    <Input type="text" name='nome' defaultValue={projeto.nome} required />
                  </Grid>
                </Grid>
              </Panel>
            </Form>
            <ContainerButton>
              <button>
                <Link
                  to={"/projetos"}
                  className="button_return"
                  style={{ textDecoration: "none", color: "black" }}
                >
                    <FontAwesomeIcon icon={faX} /> Cancelar
                </Link>
              </button>
              <button type="submit" form="projectForm">
                <FontAwesomeIcon icon={faSave}/> Salvar
              </button>
            </ContainerButton>
          </Container>
  )
}
