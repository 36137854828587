import React, { useEffect, useRef, useState } from "react";

import {
  ContainerButton,
  ContainerButton2,
  Form,
  Input,
  TextTitle,
  Textarea,
  Title,
} from "../../../styles/global";
import Panel from "../../../components/Panel";
import { Button, Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "../../../components/InputRadio";
import useProtocolo from "../../../actions/cruds/Protocolo/useProtocolo";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { FormHandles } from "@unform/core";
import agevapLogoImg from "../../../assets/agevapLogoImg.png"

const ProtocoloView: React.FC = () => {
  const { protocolo, getProtocolo } = useProtocolo();
  const { protocoloId } = useParams();

  const [estimativaDias, setEstimativaDias] = useState<String>('');
  const [localOrigem, setLocalOrigem] = useState<String>('');

  useEffect(() => {
    getProtocolo(protocoloId);
  }, []);

  useEffect(() => {
    setLocalOrigem(protocolo?.local);

    const localOrigemRadio = formRef.current?.getFieldRef("local");
    if (protocolo?.local == 'interno') {
      localOrigemRadio[0].checked = true;
    } else if (protocolo?.local == 'externo') {
      localOrigemRadio[1].checked = true;
    };

    // const rateioRadio = formRef.current?.getFieldRef("rateio");
    // if (protocolo?.rateio == true) {
    //   rateioRadio[0].checked = true;
    // } else if (protocolo?.rateio == false) {
    //   rateioRadio[1].checked = true;
    // };

  }, [protocolo])

  useEffect(() => {
    const dataInicio = new Date(protocolo?.data_arquivo);
    const dataFim = new Date(protocolo?.data_previsao_conclusao);

    if (dataFim < dataInicio) {
      setEstimativaDias("Data de Conclusão anterior à Data de Início");
      return
    }

    const diferencaEmMilissegundos = Math.abs(
      dataFim.getTime() - dataInicio.getTime()
    );

    const differenceDate = Math.floor(
      diferencaEmMilissegundos / (1000 * 60 * 60 * 24)
    );

    setEstimativaDias(`${differenceDate} dias`);
  }, [protocolo])

  const formRef = useRef<FormHandles>(null);

  const optionsRateio = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  const optionsLocal = [
    { label: "Interno", value: "interno" },
    { label: "Externo", value: "externo" },
  ];

  const optionsSituacao = [
    { label: "Não iniciado", value: "nao_iniciado", id: "001" },
    { label: "Em andamento", value: "em_andamento", id: "002" },
    { label: "Concluído", value: "concluido", id: "003" },
  ];

  const organizeDataComprovante = (data) => {
    if (typeof data === "object") {
      console.log(data)
      data = data.map((d) => {
        return {
          id: d.id,
          data: d.data_encerramento_atendimento,
          titulo: d.titulo_comprovacao,
          arquivo: `${d.url_arquivo}/${d.nome_arquivo}`,
        };
      });
    }
    return data;
  };

  const organizeDataSolicitanteComite = (data) => {
    if (typeof data === "object") {
      data = data.map((d) => {
        return {
          id: d?.id,
          comite: d?.nome,
        };
      });
    }
    return data;
  };


  const dataTitleSolicitanteComite: GridColDef[] = [
    { field: "comite", headerName: "Comitê", flex: 1 },
  ];

  const dataTitleDocumento: GridColDef[] = [
    {
      field: "data_arquivo",
      headerName: "Data do Documento",
      flex: 1,
      headerClassName: "header_table",
    },
    {
      field: "titulo_arquivo",
      headerName: "Nome do Documento",
      flex: 3,
      headerClassName: "header_table",
    },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      headerClassName: "header_table",
      renderCell: (params) => {
        return (
          <>
            <Link to={{ pathname: `${params.row.url_arquivo}/${params.row.nome_arquivo}` }} target="_blank">
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
              </Button>
            </Link>
          </>
        );
      }
    }
  ];

  const dataTitleComprovante: GridColDef[] = [
    {
      field: "data",
      headerName: "Data do Comprovante",
      flex: 1,
      headerClassName: "header_table",
    },
    {
      field: "titulo",
      headerName: "Descrição do Comprovante",
      flex: 3,
      headerClassName: "header_table",
    },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      headerClassName: "header_table",
      renderCell: (params) => {
        return (
          <>
            <Link to={{ pathname: `${params.row.arquivo}` }} target="_blank">
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
              </Button>
            </Link>
          </>
        );
      }
    }
  ];

  return (
    <Container>
      <Form ref={formRef}>
        <Title color="#333" className="no-print">
          <span>Visualizar Protocolo</span>
        </Title>
        <div className="displayOnPrint">
          <Title color="#333" >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <span>Ficha do Protocolo</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <span>
                <img
                  src={agevapLogoImg}
                  style={{ width: "120px", margin: "0px 30px 0px 0px" }}
                  alt=""
                />
                Protocolo Nº: {protocolo?.codigo}
              </span>
            </div>
          </Title>
        </div>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data da entrada:</TextTitle>
              <Input
                name="data_entrada"
                type="date"
                value={protocolo?.data_entrada}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Selecione de onde está vindo essa demanda:</TextTitle>
              <Radio name="local" options={optionsLocal} checkedValue={protocolo?.local} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Organização:</TextTitle>
              <Input
                type="text"
                name="organization_id"
                value={protocolo?.organization && `${protocolo?.organization?.codigo} - ${protocolo?.organization?.nome}`}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Despesa:</TextTitle>
              <Input
                type="text"
                name="expense_type_id"
                value={protocolo?.expense_type && `${protocolo?.expense_type?.codigo} - ${protocolo?.expense_type?.nome}`}
                disabled
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Instância:</TextTitle>
              <Input
                type="text"
                name="protocol_instance_id"
                value={protocolo?.protocol_instance?.nome}
                disabled
              />
            </Grid>
            {/* <Grid xs={12}>
              <TextTitle>A demanda será um rateio?</TextTitle>
              <Radio name="rateio" options={optionsRateio} checkedValue={protocolo?.rateio} disabled />
            </Grid> */}
            <Grid xs={12}>
              <TextTitle>Solicitante:</TextTitle>
              <Input
                type="text"
                name="solicitante_id"
                value={localOrigem == 'interno' ? (protocolo?.representative?.id ? `${protocolo?.representative?.nome} - Representante` : protocolo?.solicitante?.id ? `${protocolo?.solicitante?.nome} - Funcionário` : "") : localOrigem == 'externo' ? protocolo?.external_requester?.nome : ''}
                disabled
              />
            </Grid>
            {localOrigem == "interno" ?
              <>
                {protocolo.comittees?.length > 0 && (
                  <Grid xs={6}>
                    <SecondaryTable
                      columsData={dataTitleSolicitanteComite}
                      rowsData={organizeDataSolicitanteComite(protocolo?.comittees)}
                    />
                  </Grid>
                )}
              </>
              // <>
              //   <Grid xs={6}>
              //     <TextTitle>Comitê:</TextTitle>
              //     <Input
              //       type="text"
              //       name="comittee_id"
              //       defaultValue={protocolo?.institution_competence?.comittee?.nome}
              //       disabled
              //     />
              //   </Grid>
              //   <Grid xs={6}>
              //     <TextTitle>Instância:</TextTitle>
              //     <Input
              //       type="text"
              //       name="instance_id"
              //       defaultValue={`${protocolo?.institution_competence?.instance?.nome} - ${protocolo?.institution_competence?.actuation?.nome}`}
              //       disabled
              //     />
              //   </Grid>
              // </>
              : localOrigem == 'externo' ?
                <>
                  <Grid xs={6}>
                    <TextTitle>Telefone:</TextTitle>
                    <Input type="text" mask="phone" name="external_requester_telefone" value={protocolo?.external_requester?.telefone} disabled />
                  </Grid>
                  <Grid xs={6}>
                    <TextTitle>Email:</TextTitle>
                    <Input type="email" name="external_requester_email" value={protocolo?.external_requester?.email} disabled />
                  </Grid>
                  <Grid xs={12}>
                    <TextTitle>Local:</TextTitle>
                    <Input type="text" name="external_requester_local" value={protocolo?.external_requester?.local} disabled />
                  </Grid>
                </>
                : null}
            <Grid xs={12}>
              <TextTitle>Código NUP - SIGAD:</TextTitle>
              <Input type="text" name="codigo_nup" value={protocolo?.codigo_nup} disabled />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Documento">
          <Grid container spacing={6}>
            {protocolo.url_arquivo && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleDocumento}
                  rowsData={[protocolo]}
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Demanda">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Origem demanda:</TextTitle>
              <Input type="text" name="origem" value={protocolo?.demand_origin?.nome} disabled />
            </Grid>
            {/* <Grid xs={6}>
              <TextTitle>Instância:</TextTitle>
              <Input type="text" name="instancia" value={"Instancia do Protocolo"} disabled />
            </Grid> */}
            <Grid xs={12}>
              <TextTitle>Demanda:</TextTitle>
              <Input name="demanda" value={protocolo?.demanda} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Tipo de encaminhamento:</TextTitle>
              <Input
                type="text"
                name="forwarding_type_id"
                value={`${protocolo?.forwarding_type?.codigo} - ${protocolo?.forwarding_type?.nome}`}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Situação:</TextTitle>
              <Input
                type="text"
                name="situacao"
                value={protocolo.situacao ? optionsSituacao.find(o => o.value == protocolo?.situacao)?.label : ''}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Responsável pela demanda:</TextTitle>
              <Input
                type="text"
                name="user_id"
                value={protocolo?.user?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data estimada de conclusão:</TextTitle>
              <Input
                name="data_previsao_conclusao"
                type="date"
                value={protocolo?.data_previsao_conclusao}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Código do encaminhamento:</TextTitle>
              <Input name="codigo_encaminhamento" value={protocolo?.codigo} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Estimativa em dias:</TextTitle>
              <Input name="estimativa_dias" value={estimativaDias} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de Conclusão:</TextTitle>
              {protocolo?.data_encerramento_atendimento ?
                <Input type="date" name="data_conclusao" value={protocolo?.data_encerramento_atendimento} disabled />
                : <Input name="data_conclusao" disabled />
              }
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Observação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Observação:</TextTitle>
              <Textarea name="observacao" value={protocolo?.observacao} disabled />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Comprovação do atendimento">
          <Grid container spacing={6}>
            {protocolo.url_comprovacao && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleComprovante}
                  rowsData={organizeDataComprovante([protocolo])}
                />
              </Grid>
            )}
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2 className="no-print">
        <Link
          to={"/protocolo"}
          className="button_return"
          style={{ textDecoration: "none", color: "black" }}
        >
          <FontAwesomeIcon icon={faChevronLeft} /> Voltar
        </Link>
        <Link to={`/protocolo/fichaPDF/${protocoloId}`} target="_blank">
          <button className="button_save">
            <FontAwesomeIcon icon={faDownload} size="1x" /> Download PDF
          </button>
        </Link>
      </ContainerButton2>
    </Container>
  );
};

export default ProtocoloView;
