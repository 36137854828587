import React from 'react';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { AxiosInterceptor } from '../services/api';

const AppProvider: React.FC = ({ children }) => (
  // <AuthProvider>
  //   <ToastProvider>{children}</ToastProvider>
  // </AuthProvider>
   
  
  <ToastProvider>
    <AuthProvider>
      <AxiosInterceptor>
        {children}
      </AxiosInterceptor>
    </AuthProvider>
  </ToastProvider>
  
);

export default AppProvider;