import { Container } from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useCargo from '../../../../../actions/cruds/useCargo';
import useUsuario from '../../../../../actions/cruds/useUsuario';
import Panel from '../../../../../components/Panel';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton, Input, SelectInput, TextTitle, Title } from '../../../../../styles/global';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faX } from '@fortawesome/free-solid-svg-icons';
import organizeDataSelect from '../../../../../utils/organizeDataSelect';
import { formatarReais, formatarRealparaDecimal } from '../../../../../utils/formatDados';
// import { Container } from './styles';


const CargoRhEdit: React.FC = () => {

    const { cargoId } = useParams();

    const [selectedVinculo, setSelectedVinculo] = useState([]);

    const { cargo, getCargo, updateCargo } = useCargo();
    const { tiposEmpregados, getTiposEmpregados } = useUsuario();

    useEffect(() => {
        getCargo(cargoId);
        getTiposEmpregados([{ label: 'ativo', value: true }]);
    }, []);

    useEffect(() => {
        if (cargo.employee_type)
            setSelectedVinculo([{ label: cargo.employee_type.nome, value: cargo.employee_type.id }]);
    }, [cargo]);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => {
            try {
                data['salario'] = formatarRealparaDecimal(formRef.current?.getFieldValue("salario"));
                console.log(data);

                updateCargo(cargoId, data);

                // addToast({
                //   type: 'success',
                //   title: 'Login efetuado',
                //   description: 'Seu login foi feito com sucesso!',
                // });

                // history.push('/dashboard');
            } catch (err) {
                // addToast({
                //   type: 'error',
                //   title: 'Erro na autenticação',
                //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
                // });
            }
        },
        [],
    );

    const handleChangeVinculo = vinculoSelected => { // necessario para selecionar programaticamente uma opção do select
        setSelectedVinculo(vinculoSelected);
    };

    return (
        <Container>
            <Title>
                <span>Editar Cargo</span>
            </Title>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Cargo'>
                    <Grid container spacing={6}>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Cargo:</TextTitle>
                            <Input type="text" name='nome' defaultValue={cargo.nome} required />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Tipo de Vínculo:</TextTitle>
                            <SelectInput
                                id="employee_type_id"
                                name="employee_type_id"
                                options={organizeDataSelect(tiposEmpregados.data, "id", "nome") || []} // paliativo ate Diego arrumar para que possa ativar o comentario anterior
                                placeholder="Selecione um vínculo"
                                onInputChange={e => { getTiposEmpregados([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
                                value={selectedVinculo}
                                onChange={handleChangeVinculo}
                                required
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Salário:</TextTitle>
                            <Input
                                type="text"
                                name='salario'
                                format="currency"
                                currency="BRL"
                                defaultValue={formatarReais(cargo.salario)}
                            // value={formatarReais(cargo.salario)}
                            />

                        </Grid>
                    </Grid>
                </Panel>
                <ContainerButton>
                    <Link to="/rh/cadastros/cargo">
                        <button><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
                    </Link>
                    <button type="submit" form="form1"><FontAwesomeIcon icon={faSave} /> Salvar</button>
                </ContainerButton>
            </Form>
        </Container>
    );
};

export default CargoRhEdit;