import { useCallback, useEffect, useRef, useState } from "react";

import {
  faCloudArrowUp,
  faPlus,
  faSave,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";

import Header from "../../../../../components/Header";
import Panel from "../../../../../components/Panel";
import { CSidebar } from "../../../../../components/Sidebar";
import Grid from "@mui/material/Unstable_Grid2";

import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton,
  Form,
  FormContainer,
  Input,
  InputAddBtn,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../../styles/global";

import Radio from "../../../../../components/InputRadio";
import useEndereco from "../../../../../actions/cruds/useEndereco";
import SecondaryTable from "../../../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import InputFile from "../../../../../components/InputFile";
import organizeDataSelect from "../../../../../utils/organizeDataSelect";
import useOrgao from "../../../../../actions/cruds/useOrgao";
import useUnidade from "../../../../../actions/cruds/useUnidade";
import useUsuario from "../../../../../actions/cruds/useUsuario";
import useCargo from "../../../../../actions/cruds/useCargo";
import useGerencia from "../../../../../actions/cruds/useGerencia";
import useProjeto from "../../../../../actions/cruds/useProjeto";
import useFornecedor from "../../../../../actions/cruds/useFornecedor";
import InputImage from "../../../../../components/InputImage";
import { Link } from "react-router-dom";
import emailChecker from "../../../../../utils/emailChecker";

export function RegTerceirizado() {
  const { createUsers, usuarios, getUsers } = useUsuario();
  const { getAddress, endereco, setEndereco } = useEndereco();
  const { orgaos, getOrganizations } = useOrgao();
  const { unidades, getUnidades } = useUnidade();
  const { cargos, getCargos } = useCargo();
  const { gerencias, getGerencias } = useGerencia();
  const { projetos, getProjetos } = useProjeto();
  const { fornecedores, getFornecedores } = useFornecedor();

  const [contacts, setContacts] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    getUsers([{ label: 'ativo', value: true }]);
    getOrganizations([{ label: 'ativo', value: true }]);
    getUnidades([{ label: 'ativo', value: true }]);
    getCargos([{ label: 'ativo', value: true }]);
    getGerencias([{ label: 'ativo', value: true }]);
    getProjetos([{ label: 'ativo', value: true }]);
    getFornecedores([{ label: 'ativo', value: true }]);
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {

        const dataDesligamento = formRef.current?.getFieldValue("data_desligamento");
        if (dataDesligamento) {
          data["ativo"] = false;
        };

        data["employee_type_id"] = 4;
        data["user_contacts_attributes"] = contacts;

        console.log(data);
        createUsers(data);
      } catch (err) { }
    },
    [contacts]
  );

  const organizeDataEmpresas = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          value: d.id,
          label: `${d.cpf_cnpj} - ${d.nome_razao_social}`,
        }));
        return data;
      }
    }
    return [];
  };

  const handlerContact = (btn) => {
    const randon_id = Date.now();
    const tipoContato = btn.target.id;

    if (tipoContato === 'email') {
      const emailValue = formRef.current?.getFieldValue("email");
      const emailValido = emailChecker(emailValue);

      if (!emailValido) {
        alert("Email inválido! Insira um email valido. Ex: 'email@email.com'");
        return;
      }
    };

    if (tipoContato != "telefone" && tipoContato != "email") {
      return;
    };

    setContacts([
      ...contacts,
      {
        front_id: randon_id,
        tipo: btn.target.id,
        contato:
          btn.target.id === "telefone"
            ? formRef.current.getFieldValue("telefone")
            : formRef.current.getFieldValue("email"),
      },
    ]);

    formRef.current?.setFieldValue("telefone", "");
    formRef.current?.setFieldValue("email", "");
  };

  const optionsUF = [
    { value: "AC", label: "AC - Acre" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "BA", label: "BA - Bahia" },
    { value: "CE", label: "CE - Ceará" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "GO", label: "GO - Goiás" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "PA", label: "PA - Pará" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PI", label: "PI - Piauí" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RR", label: "RR - Roraima" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "EX", label: "EX - Estrangeiro" },
  ];

  const optionsGenero = [
    { value: "masculino", label: "Masculino" },
    { value: "feminino", label: "Feminino" },
  ];

  const optionsGrau = [
    { value: "Ensino Médio", label: "Ensino Médio" },
    { value: "Técnico", label: "Técnico" },
    { value: "Superior (Graduação)", label: "Superior (Graduação)" },
    { value: "Pós-graduação", label: "Pós-graduação" },
    { value: "Mestrado", label: "Mestrado" },
    { value: "Doutorado", label: "Doutorado" },
  ];

  const optionsEstadoCivil = [
    { value: "solteiro", label: "Solteiro" },
    { value: "casado", label: "Casado" },
    { value: "separado", label: "Separado" },
    { value: "divorciado", label: "Divorciado" },
    { value: "viuvo", label: "Viúvo" },
  ];

  const optionsBoolean = [
    { value: "true", label: " Sim" },
    { value: "false", label: " Não" },
  ];

  const dataAddress = () => {
    getAddress(formRef.current.getFieldValue("cep"));
  };

  const handleChangeSelect = (selectedOption) => {
    // necessario para selecionar programaticamente uma opção do select
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    // necessario para selecionar programaticamente uma opção do select
    const selected = optionsUF.find((o) => o.value === endereco.uf);
    setSelectedOption(selected);
  }, [endereco]);

  const dataTitleContacts: GridColDef[] = [
    { field: "tipo", headerName: "Tipo", flex: 1 },
    { field: "contato", headerName: "Contato", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteContactClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  function onDeleteContactClick(
    e: MouseEvent<HTMLButtonElement, MouseEvent>,
    row: any
  ): void {
    setContacts(contacts.filter((el) => el.front_id !== row.front_id));
  }

  return (
    <Container>
      <Title color="#333">
        <span>Registro de cadastro do terceirizado</span>
      </Title>

      <Form id="formEmpregados" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12} lg={3}>
              <InputImage name="avatar" />
            </Grid>
            <Grid container xs={12} lg={9}>
              <Grid container>
                <Grid xs={6} lg={6}>
                  <TextTitle>Gênero: </TextTitle>
                  <SelectInput
                    name="genero"
                    options={optionsGenero}
                    placeholder="Selecionar"
                  />
                </Grid>
                <Grid xs={6} lg={6}>
                  <TextTitle>Grau de Instrução: </TextTitle>
                  <SelectInput
                    name="grau_instrucao"
                    options={optionsGrau}
                    placeholder="Selecionar"
                  />
                </Grid>
                <Grid xs={12} lg={12}>
                  <TextTitle>Nome: </TextTitle>
                  <Input type="text" name="nome" required />
                </Grid>
                <Grid xs={6} lg={6}>
                  <TextTitle>
                    Data de nascimento: <span>*</span>
                  </TextTitle>
                  <Input type="date" name="data_nascimento" />
                </Grid>
                <Grid xs={6} lg={6}>
                  <TextTitle>Estado civil: </TextTitle>
                  <SelectInput
                    name="estado_civil"
                    options={optionsEstadoCivil}
                    placeholder="Selecionar"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} lg={6}>
              <TextTitle>CPF do empregado: </TextTitle>
              <Input type="text" name="cpf" mask="cpf" required />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Formação: </TextTitle>
              <Input type="text" name="formacao" />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>
                RG: <span>*</span>
              </TextTitle>
              <Input type="text" name="rg" mask="rg" />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>
                Órgão Expedidor: <span>*</span>
              </TextTitle>
              <Input type="text" name="orgao_expedidor" />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Contato">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>Email:</TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "115px" }}
                  type="text"
                  name="email"
                />
                <InputAddBtn
                  id="email"
                  name="email_contato"
                  type="button"
                  onClick={handlerContact}
                >
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Telefone:</TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "115px" }}
                  type="text"
                  name="telefone"
                  mask="phone"
                />
                <InputAddBtn
                  id="telefone"
                  name="telefone_contato"
                  type="button"
                  onClick={handlerContact}
                >
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs={12}>
              <SecondaryTable
                columsData={dataTitleContacts}
                rowsData={contacts}
                getRowId={(row) => row.front_id}
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "150px" }}
                  type="text"
                  name="cep"
                  mask="cep"
                />
                <InputAddBtn type="button" onClick={dataAddress}>
                  <FontAwesomeIcon icon={faPlus} /> Pesquisar CEP{" "}
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro: </TextTitle>
              <Input
                type="text"
                name="logradouro"
                value={endereco.logradouro}
                onChange={(e) =>
                  setEndereco({ ...endereco, logradouro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro: </TextTitle>
              <Input
                type="text"
                name="bairro"
                value={endereco.bairro}
                onChange={(e) =>
                  setEndereco({ ...endereco, bairro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input type="text" name="numero" />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input
                type="text"
                name="complemento"
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={endereco.localidade}
                onChange={(e) =>
                  setEndereco({ ...endereco, localidade: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF: </TextTitle>
              <SelectInput
                name="uf"
                options={optionsUF}
                value={selectedOption}
                onChange={handleChangeSelect}
                placeholder="Selecionar"
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados institucionais">
          <Grid container spacing={6}>
            <Grid xs={6} lg={6}>
              <TextTitle>Cargo: </TextTitle>
              <SelectInput
                name="cargo_atual_id"
                options={
                  cargos.data
                    ? organizeDataSelect(
                      cargos.data.filter(
                        (cargo) => cargo.employee_type.key === "terceirizado"
                      ),
                      "id",
                      "nome"
                    )
                    : []
                }
                placeholder="Selecionar"
                onInputChange={(e) => {
                  getCargos([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Empresa: </TextTitle>
              <SelectInput
                name="empresa_id"
                options={organizeDataEmpresas(fornecedores.data)}
                placeholder="Selecionar"
                onInputChange={(e) => {
                  getFornecedores([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Data de admissão: </TextTitle>
              <Input type="date" name="data_admissao" />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Data de desligamento: </TextTitle>
              <Input type="date" name="data_desligamento" />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Projeto: </TextTitle>
              <SelectInput
                name="project_id"
                options={organizeDataSelect(projetos.data, "id", "nome")}
                placeholder="Selecionar"
                onInputChange={(e) => {
                  getProjetos([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Email: </TextTitle>
              <Input type="email" name="email" required />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Gerencia: </TextTitle>
              <SelectInput
                name="gerencia_id"
                options={organizeDataSelect(gerencias.data, "id", "nome")}
                placeholder="Selecionar"
                onInputChange={(e) => {
                  getGerencias([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Gestor do contrato: </TextTitle>
              <SelectInput
                name="gestor_contrato_id"
                options={
                  usuarios.data
                    ? organizeDataSelect(
                      usuarios.data.filter(
                        (usuario) =>
                          usuario.employee_type &&
                          usuario.employee_type.id == "1"
                      ),
                      "id",
                      "nome"
                    )
                    : []
                }
                placeholder="Selecionar"
                onInputChange={(e) => {
                  getUsers([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados do local de trabalho">
          <Grid container spacing={6}>
            <Grid xs={6} lg={6}>
              <TextTitle>Organização: </TextTitle>
              <SelectInput
                name="organization_id"
                options={organizeDataSelect(orgaos.data, "id", "nome")}
                placeholder="Selecionar"
                onInputChange={(e) => {
                  getOrganizations([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Local de trabalho: </TextTitle>
              <SelectInput
                name="local_trabalho_id"
                options={organizeDataSelect(unidades.data, "id", "nome")}
                placeholder="Selecionar"
                onInputChange={(e) => {
                  getUnidades([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/rh/empregados">
          <button>
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <button type="submit" form="formEmpregados">
          <FontAwesomeIcon icon={faSave} /> Salvar
        </button>
      </ContainerButton>
    </Container>
  );
}
