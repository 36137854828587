import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useEffect, useState } from "react";

import Header from "../../../components/Header";

import { CSidebar } from "../../../components/Sidebar";

import { Container, Title } from "./style";
import { useAuth } from "../../../hooks/auth";
import getPermission from "../../../utils/getPermission";

import useCnae from "../../../actions/cruds/useCnae";
import MainTable from "../../../components/MainTable";
import api from "../../../services/api";

export function AtividadeCNAE() {
  // const { cnaes, getCnaes } = useCnae();
  const [cnaes, setCnaes] = useState([]);

  // useEffect(() => {
  //   getCnaes();
  // }, []);


  const {login} = useAuth();
  const fornecedoresPermission = getPermission(login,"Fornecedores") ?? '';



  useEffect(() => {
    api
      .get("cnae_activities")
      .then((response) => {
        setCnaes(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`cnae_activities?page=${cnaes.pagination.current_page + page}`)
      .then((response) => {
        setCnaes(response.data);
      })
      .catch((error) => console.log(error));
  };

  const columnData = [
    { headerName: "Código", field: "codigo" },
    { headerName: "Descrição", field: "nome" },
  ];

  const organizeData = (data: any) => {
    if (typeof data === "object") {
      data = data.map((d: any) => ({
        id: d.id,
        codigo: d.codigo,
        nome: d.nome,
      }));
    }
    return data;
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ height: "100%" }}>
        <CSidebar />
      </div>
      <div style={{ display: "block", width: "100%" }}>
        <Header />
        <Title color="#333">
          <span>Gestão de atividade CNAE</span>
        </Title>
        <Container color="#FFF">
          <MainTable
            data={organizeData(cnaes.data)}
            pagination={cnaes.pagination}
            permission={fornecedoresPermission}
            handlePagination={handlePagination}
            hideFilter={true}
            isMaxWidthCell
            registerLink="/fornecedores/cnae/cadastrar"
            columns={columnData}
            dropdown={{
              editar: { linkTo: "/fornecedores/cnae/editar" },
              excluir: { linkTo: "cnae_activities" },
            }}
          />
        </Container>
      </div>
    </div >
  );
}
