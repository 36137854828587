import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useProcessoCategoria = () => {

    const history = useHistory();
    const [categoriasProcessos, setCategoriasProcessos] = useState([]);
    const [categoriaProcesso, setCategoriaProcesso] = useState({});
    const [error, setError] = useState(null);

    async function getCategoriasProcessos(params?: object) {
        try {
            const response = await api.get(`law_process_categories${stringfyParams(params)}`);
            setCategoriasProcessos(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getCategoriaProcesso(id: string) {
        try {
            const response = await api.get(`law_process_categories/${id}`);
            // console.log(response);
            setCategoriaProcesso(response.data);
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createCategoriaProcesso(data: any) {
        try {
            const response = await api.post('law_process_categories', data);
            alert("Categoria de Processo cadastrado com sucesso!");
            history.push("/processos");

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro ao cadastrar Processo");
            setError(e);
            // console.log(error);
        }
    }

    async function updateCategoriaProcesso(id: number, data: any) {
        try {
          const response = await api.put(`law_process_categories/${(id)}`, data);
          alert("Categoria do Processo atualizado com sucesso!");
          history.push("/processos");
        } catch (e) {
          setError(e);
          // console.log(error);
        }
      }


    return {categoriasProcessos, getCategoriasProcessos, categoriaProcesso, getCategoriaProcesso, createCategoriaProcesso, updateCategoriaProcesso, error};
}

export default useProcessoCategoria;