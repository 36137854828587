import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef } from "react";
import Header from "../../../../components/Header";
import Panel from "../../../../components/Panel";
import { CSidebar } from "../../../../components/Sidebar";
import Grid from "@mui/material/Unstable_Grid2";

import {
  ContainerButton,
  Form,
  FormContainer,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../styles/global";
import { Link, useHistory } from "react-router-dom";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import organizeDataSelect from "../../../../utils/organizeDataSelect";
import useUsuario from "../../../../actions/cruds/useUsuario";
import { Container } from "@mui/material";

export function RegEmpregados() {
  const history = useHistory();
  const { tiposEmpregados, getTiposEmpregados } = useUsuario();

  useEffect(() => {
    getTiposEmpregados();
  }, []);

  const formRef = useRef<FormHandles>();

  const handleNext = () => {
    const tipoEmpregado = formRef.current?.getFieldValue("employee_type_id");
    if(!tipoEmpregado){
      return;
    }
    history.push(`/rh/empregados/cadastrar${tipoEmpregado}`);
  };

  return (
    <>
      <Container>
        <Title>
          <span>Registro de cadastro</span>
        </Title>
        <Form ref={formRef}>
          <Panel titulo="Identificação">
            <Grid container spacing={6}>
              <Grid xs={12} lg={6}>
                <TextTitle>
                  Selecione o tipo de funcionário: <span>*</span>
                </TextTitle>
                <SelectInput
                  id="idEmpregado"
                  name="employee_type_id"
                  options={organizeDataSelect(
                    tiposEmpregados.data,
                    "key",
                    "nome"
                  )}
                  placeholder="Selecionar"
                />
              </Grid>
            </Grid>

            <FormContainer>
              <div>
                <div>
                  
                </div>
              </div>
            </FormContainer>
          </Panel>
        </Form>
        <ContainerButton>
          <Link to="/rh/empregados">
            <button>
              <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Sair
            </button>
          </Link>
          <button type="submit" onClick={handleNext}>
            <FontAwesomeIcon icon={faChevronRight} /> Próxima etapa
          </button>
        </ContainerButton>
      </Container>
    </>
  );
}
