import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container } from './styles';

const Card: React.FC = () => {
    return (
        <Container>
            <div>
                <div>
                    <FontAwesomeIcon icon={faUserGroup} size='lg' />
                </div>

                <div>
                    <p>
                        Usuários Ativos
                    </p>
                    <p>
                        34
                    </p>
                </div>

                <div>
                    <p>+10%</p>
                </div>
            </div>
        </Container>
    );
};

export default Card;