import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { GridColDef, GridToolbar, GridValueFormatterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import usePap from '../../../../actions/cruds/usePap';
import usePapOrcamento from '../../../../actions/cruds/usePapOrcamento';
import InputNumberFormat from '../../../../components/InputNumberFormat';
import Panel from '../../../../components/Panel';
import { useToast } from '../../../../hooks/toast';
import { ContainerButton, Title } from '../../../../styles/global';
import { formatarReais } from '../../../../utils/formatDados';
import { BudgetTable } from './styles';

interface ITitleRow {
    field: string;
    headerName: string;
    flex?: number;
    editable?: boolean;
    sortable?: boolean;
}

const PapOrcamento: React.FC = () => {
    const [titleRows, setTitleRows] = useState<ITitleRow[]>([]);

    const { idPap } = useParams();

    const { getPap, getPapBudget, budget } = usePap();
    const { createPapOrcamento, updatePapOrcamento } = usePapOrcamento();
    const {addToast} = useToast();

    useEffect(() => {
        getPap(idPap);
        getPapBudget([{ label: "id", value: idPap }]);
    }, []);

    useEffect(() => {
        if(!budget.data) return;
        // console.log('budget: ', budget);

        // Inserção dinamica do titulo de coluna da tabela, essa parte na descrição inical
        setTitleRows(
            [
                { 
                    field: "pap",
                    headerName: `PAP - ${budget.data[0].periodo_de ?? ""} a ${budget.data[0].periodo_ate ?? ""} - ${budget.data[0].comittee.nome ?? ""}`,
                    sortable: false, flex: 2
                }
            ]
        );

        // Inserção dinamica do titulo de coluna da tabela, essa parte em relação aos ANOS do PAP
        let {periodo_de, periodo_ate} = budget.data[0];
        const yearsList: { field: string; headerName: string; sortable: boolean; flex: number; editable: boolean; }[] = [];
        for (; periodo_de <= periodo_ate; periodo_de++) {
            yearsList.push(
                {
                    field: `budget_value_year_${periodo_de}`,
                    headerName: periodo_de.toString(),
                    sortable: false, flex: 1,
                    editable: true,
                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                        if (params.value == null) {
                            return "R$ 0,00";
                        }
                        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    },
                    renderEditCell: (params: GridRenderEditCellParams) => (
                        <InputNumberFormat {...params} />
                    ),
                }
            );
        }
        setTitleRows(titleRows => [...titleRows, ...yearsList]);

        // Inserção dinamica do titulo de coluna da tabela, parte do total da linha
        setTitleRows(
            titleRows => [...titleRows,
                            { field: "totalRow", headerName: "Total", sortable: false, flex: 1,
                                valueGetter: (params) => {
                                    let soma = 0;
                                    const {periodo_de, periodo_ate} = budget.data[0];
                                    Object.keys(params.row).findIndex((key) => { 
                                        const keyExist = key.startsWith('budget_value_year_');
                                        const year = key.match(/[^_]+$/)[0];                                 
                                        if ( keyExist && (parseInt(year) >= periodo_de && parseInt(year) <= periodo_ate) ) {
                                            // console.log(typeof parseInt(year), typeof periodo_de);
                                            // console.log(key.match(/[^_]+$/)[0]);
                                            soma = soma + params.row[key]
                                        }
                                    });
                                    return formatarReais( soma );
                                }
                            }]
        );

        // console.log('titleRows: ', titleRows);
    }, [budget]);

    const dataTitle: GridColDef[] = titleRows;

    const organizeData = (data: any[]) => {
        let outputArr: { id: any; nome: any; codigo: any; stacked_code: any; pap: string; }[] = [];

        /**
         * Convert a multilevel person object into one level
         * @param {Object} person The person object
         */
        const convertToOneLevel = (person, parentCode = null, budget = []) => {
            const newPerson = {
                id: person.id,
                nome: person.nome,
                codigo: person.codigo,
                parent_code: parentCode ? parentCode : undefined,
                stacked_code: parentCode ? `${parentCode}.${person.codigo}` : person.codigo,
                pap: `${parentCode ? `${parentCode}.${person.codigo}` : person.codigo} - ${person.nome}`
            };

            budget.map(b => {
                newPerson[`budget_value_year_${b.ano}`] = b.valor;
                newPerson[`budget_id_year_${b.ano}`] = b.id;
                
                // console.log(b);
                // console.log("-");
            });           

            outputArr.push(newPerson);

            const idx = Object.keys(person).findIndex((key) =>
                key.startsWith('pap_programs') || key.startsWith('pap_actions')
            );
            // console.log(idx)
            if (idx !== -1) {
                person[Object.keys(person)[idx]].map((p) => {
                        // console.log('p: ', p);
                        convertToOneLevel(
                            p,
                            newPerson.stacked_code ? newPerson.stacked_code : newPerson.codigo,
                            p.pap_budgets
                        );
                    }
                );
            }
        };

        data.forEach((p) => convertToOneLevel(p));

        const parents = outputArr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.parent_code === value.parent_code && t.parent_code !== undefined
            ))
        );
        // console.log('parents: ', parents);
        // console.log('outputArr: ', outputArr);

        // Soma para os PROGRAMAS
        parents.map(parent => {
            // console.log(parent.parent_code);

            const groupToSum = outputArr.filter(f => (parent.parent_code === f.parent_code));
            // console.log('groupToSum: ', groupToSum);

            groupToSum.map(itemToSum => {

                // console.log('itemToSum: ', itemToSum);
                if (itemToSum.parent_code.includes('.')) { // Programa
                    // console.log('itemToSum: ', itemToSum);
                    const keysYears = Object.keys(itemToSum).filter(key => key.startsWith("budget_value_year_"));
                    // console.log('keysYears: ', itemToSum.parent_code, keysYears);
                    keysYears.map((year) => {
                        outputArr.map((out, i) => {
                            if (out.stacked_code == itemToSum.parent_code) {
                                // console.log(out.stacked_code, itemToSum.parent_code, outputArr[i]);
                                Object.assign(outputArr[i], {
                                    [year]: groupToSum.reduce((total, obj) => ((obj[year] || 0) + (total || 0)), 0)
                                });
                            }
                        });
                    });
                }
            });

        });

        // A mesma coisa mas agora para FINALIDADES
        parents.map(parent => {
            // console.log(parent.parent_code);

            const groupToSum = outputArr.filter(f => (parent.parent_code === f.parent_code));
            // console.log('groupToSum: ', groupToSum);

            groupToSum.map(itemToSum => {

                // console.log('itemToSum: ', itemToSum);
                if (!itemToSum.parent_code.includes('.'))  { // Finalidade
                    // console.log('itemToSum: ', itemToSum);
                    const keysYears = Object.keys(itemToSum).filter(key => key.startsWith("budget_value_year_"));
                    // console.log(keysYears);
                    keysYears.map((year) => {
                        // console.log(year);
                        outputArr.map((out, i) => {
                            // console.log(out.stacked_code, itemToSum.parent_code);
                            if (out.stacked_code == itemToSum.parent_code) {
                                // console.log(out.stacked_code, itemToSum.parent_code, outputArr[i]);
                                Object.assign(outputArr[i], {
                                    [year]: groupToSum.reduce((total, obj) => ((obj[year] || 0) + (total || 0)), 0)
                                });
                            }
                        });
                        // console.log(outputArr);
                    });
                }
            });

        });


        // Ordem crescente por stacked_code (código total da linha. Ex.: 3.2.5)
        function sortCallback(a, b) {

            function padParts(version) {
                return version
                    .split('.')
                    .map(function (part) {
                        return '00000000'.substring(0, 8 - part.length) + part;
                    })
                    .join('.');
            }
        
            a = padParts(a.stacked_code);
            b = padParts(b.stacked_code);
        
            return a.localeCompare(b);
        }

        // Gerador linha TOTAL por ano
        const finalidades = outputArr.filter(line => line.parent_code === undefined);
        const reducedArrayTotal = finalidades.reduce((accumulator, item) => {
            Object.keys(item).filter(key => key.startsWith("budget_value_year_")).forEach(key => {
              accumulator[key] = (accumulator[key] || 0) + item[key];
            });
            return accumulator;
        }, {});
        
        const {periodo_de, periodo_ate} = budget.data[0];
        outputArr.push({id:9999, pap: `TOTAL POR ANO (${periodo_de} a ${periodo_ate})`, stacked_code: "9999", ...reducedArrayTotal});

        return outputArr.sort(sortCallback);
    }

    const typePap = (params?: object) => {
        const {stacked_code, id} = params?.row;
        const dots = stacked_code.match( new RegExp('\\.','g') ); // Array com quantidade de "." encontrados

        if (dots) {
            switch (dots.length) {
                case 1:
                    return "programa";
                    
                case 2: 
                    return "acao";
            }
        } else { // Quando null (não achou nenhum "." cai aqui)
            if (id === 9999) {
                return "total";
            }
            return "finalidade";
        }
    }

    const processRowUpdate = React.useCallback(
        async (newRow, oldRow) => {
            // console.log(newRow);

            const cellEdited = {};

            Object.keys(newRow).map(newKey => {
                // console.log(newKey);
                if (oldRow[newKey] != newRow[newKey]) { // Se a key for a mesma E o value for diferente (foi atualizada/criada)
                    const year = (newKey.match(/_([^_]+)$/) || [])[1] || null;
                    // console.log('true:', newRow[newKey]);
                    cellEdited['pap_id'] = idPap;
                    cellEdited['pap_action_id'] = newRow.id;
                    cellEdited['comittee_id'] = budget.data[0]?.comittee.id || null;
                    cellEdited['valor'] = newRow[newKey];
                    if (year) {
                        cellEdited['ano'] = year;
                        cellEdited['budget_id'] = newRow[`budget_id_year_${year}`];
                    }
                }
            });

            if (!Object.keys(cellEdited).length) return oldRow

            if (cellEdited.budget_id) {
                await updatePapOrcamento(cellEdited.budget_id, cellEdited);
            } else {
                await createPapOrcamento(cellEdited);
            }
            await getPapBudget([{ label: "id", value: idPap }]);

          return newRow;
        },
        [budget],
    );
    
    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        // setSnackbar({ children: error.message, severity: 'error' });
        addToast({type: 'error', title: 'Erro', description: error.message});
    }, []);

    return (
        <Container>
            <Title>
                <span>Cadastro de orçamento PAP</span>
            </Title>

            <Panel titulo='Orçamento'>
                <Grid container spacing={6}>
                    <Grid xs={12}>
                        <BudgetTable
                            // hideFooter={true}
                            columsData={dataTitle}
                            rowsData={budget.data ? organizeData(budget.data[0]?.pap_finalities) : []}
                            getRowId={(row) => row.pap}
                            getRowClassName={(params) => `pap--${typePap(params)}`}
                            isCellEditable={(params) => (params.row?.stacked_code.match( new RegExp('\\.','g') ) &&
                                                            params.row?.stacked_code.match( new RegExp('\\.','g') ).length % 2 === 0)
                                                                ? true : false} // desabilita celulas que não são ações
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                        />
                    </Grid>
                </Grid>
            </Panel>

            <ContainerButton>
                <Link to="/orcamentario/pap">
                    <button><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
                </Link>
            </ContainerButton>
        </Container>
    );
};

export default PapOrcamento;