import React, { TextareaHTMLAttributes, useEffect, useRef } from "react";
import { useField } from "@unform/core";

function TextAreaRoot(props: React.HTMLProps<HTMLDivElement>) {
  return <div {...props} />;
}

export const TextArea = {
  Root: TextAreaRoot,
};

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name?: string;
  placeholder?: string;
}

export const Textarea: React.FC<TextAreaProps> = ({ name, rows = 3, ...rest }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, registerField } = useField(name!);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return ref.value;
      },
      clearValue: (ref) => {
        ref.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <textarea ref={inputRef} rows={rows} {...rest} />
  )
};