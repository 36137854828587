import { PDFViewer } from '@react-pdf/renderer'
import DocumentPage from '../../../components/PDFComponents/DocumentPage';
import { useEffect, useState } from 'react';
import useProcesso from '../../../actions/cruds/Processos/useProcesso';
import useProtocolo from '../../../actions/cruds/Protocolo/useProtocolo';
import { formatarData } from '../../../utils/formatDados';
import { useParams } from 'react-router-dom';

const ProcessoFichaPDF: React.FC = () => {
  const { processoId } = useParams();
  const { processo, getProcesso } = useProcesso();
  const { protocolo, getProtocolo } = useProtocolo();

  const [competenciasProtocolo, setCompetenciasProtocolo] = useState<Array<String>>([]);

  useEffect(() => {
    getProcesso(processoId);
  }, [])

  useEffect(() => {
    if (processo?.id) {
      if (processo?.demand?.id) {
        getProtocolo(processo?.demand?.id)
      }
    };
  }, [processo])

  useEffect(() => {
    if (protocolo?.id && protocolo?.comittees?.length) {
      setCompetenciasProtocolo([]);
      const arrayComittes = protocolo?.comittees;

      arrayComittes.forEach(function (elem, idx, array) {
        if (idx === array.length - 1) {
          setCompetenciasProtocolo((prevValue) => [
            ...prevValue, elem.nome]);
          return;
        };
        setCompetenciasProtocolo((prevValue) => [
          ...prevValue, `${elem.nome}, `]);
      });
    };
  }, [protocolo]);

  if (processo?.id) {
    const pdfData = {
      version: "Formulário eletrônico - SIGA Águas",
      title: "FICHA DE PROTOCOLO E ABERTURA DE PROCESSO",
      items: [
        {
          id: "agevapImg",
          logoWithColumns: true,
          itemsColumn: [
            {
              id: "protocoloSiga",
              row: "PROTOCOLO SIGA Nº",
              value: protocolo?.codigo ?? "-"
            },
            {
              id: "processoSigad",
              row: "PROCESSO SIGAD Nº",
              value: processo?.numero ?? "-"
            },
          ]
        },
        {
          id: "dataProtocolo",
          row: "Data de abertura do processo:",
          value: formatarData(processo?.created_at, false) ?? "-"
        },
        {
          id: "tipoDemanda",
          row: "Tipo da demanda:",
          value: `[ ${protocolo?.local == 'interno' ? "X" : "  "} ] Interno           [ ${protocolo?.local == 'externo' ? "X" : "  "} ] Externo`
        },
        {
          id: "origemDemanda",
          row: "Origem da demanda:",
          value: protocolo?.demand_origin?.nome ?? "-"
        },
        {
          id: "descricaoDemanda",
          row: "Descrição da demanda:",
          value: protocolo?.demanda ?? "-"
        },
        {
          id: "dataOrigemDemanda",
          row: "Data da origem da demanda:",
          value: formatarData(protocolo?.data_arquivo, false) ?? "-"
        },
        {
          id: "prazoAtendimento",
          row: "Prazo para atendimento da demanda:",
          value: formatarData(protocolo?.data_previsao_conclusao, false) ?? "-"
        },
        {
          id: "tipoEncaminhamento",
          row: "Tipo de encaminhamento:",
          value: `[ ${protocolo?.forwarding_type?.codigo == '01' ? "X" : "  "} ]   Administrativo          [ ${protocolo?.forwarding_type?.codigo == '04' ? "X" : "  "} ]   Institucional\n[ ${protocolo?.forwarding_type?.codigo == '02' ? "X" : "  "} ]   Estratégico                [ ${protocolo?.forwarding_type?.codigo == '05' ? "X" : "  "} ]   Jurídico\n[ ${protocolo?.forwarding_type?.codigo == '03' ? "X" : "  "} ]   Financeiro                 [ ${protocolo?.forwarding_type?.codigo == '06' ? "X" : "  "} ]   Técnico`
        },
        {
          id: "tipoDespesa",
          row: "Tipo de despesa:",
          value: `[ ${protocolo?.expense_type?.codigo == '4' ? "X" : "  "} ] Custeio           [ ${protocolo?.expense_type?.codigo == '5' ? "X" : "  "} ] Investimento`,
        },
        {
          id: "rateio",
          row: "Rateio:",
          value: processo?.rateio ? "Sim" : "Não"
        },
        {
          id: "competencia",
          row: "Competência:",
          value: protocolo?.comittees?.length ? (competenciasProtocolo.length ? competenciasProtocolo : "-") : "-"
        },
        {
          id: "local",
          row: "Local:",
          value: processo?.unit?.nome ?? "-"
        },
        {
          id: "areaResponsavel",
          row: "Área responsável:",
          value: processo?.administration?.nome ?? "-"
        },
        {
          id: "responsavel",
          row: "Responsável pela demanda:",
          value: processo?.solicitante?.nome ?? "-"
        },
        {
          id: "assuntoProcesso",
          row: "Assunto do processo:",
          value: processo?.assunto_processo ?? "-"
        },
        {
          id: "gestorProcesso",
          row: "Gestor do processo:",
          value: processo?.gestor_processo?.nome ?? "-"
        },
        {
          id: "gestorSubstitutoProcesso",
          row: "Gestor substituto do processo:",
          value: processo?.gestor_substituto?.nome ?? "-"
        },
        {
          id: "observacao",
          row: "Observação:",
          value: processo?.observacao ?? "-"
        },
      ],
    };

    return (
      <PDFViewer width="100%" height="1300" className="app" >
        <DocumentPage data={pdfData} />
      </PDFViewer>
    );
  };
};


export default ProcessoFichaPDF;