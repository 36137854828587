export const formatCPF = (document: string) => {
  if (!document) return "Documento inválido";

  document = document.replace(/\D/g, "");

  if (document.length === 11) {
    // Formatar CPF
    return `${document.substring(0, 3)}.${document.substring(
      3,
      6
    )}.${document.substring(6, 9)}-${document.substring(9)}`;
  } else if (document.length === 14) {
    // Formatar CNPJ
    return `${document.substring(0, 2)}.${document.substring(
      2,
      5
    )}.${document.substring(5, 8)}/${document.substring(
      8,
      12
    )}-${document.substring(12)}`;
  } else {
    return "Documento inválido";
  }
};

export const formatarData = (data: string, hours: boolean = true) => {
  if(!data){
    return "";
  };
  
  const dataObj = new Date(data);

  const dia = String(dataObj.getUTCDate()).padStart(2, "0");
  const mes = String(dataObj.getUTCMonth() + 1).padStart(2, "0");
  const ano = dataObj.getUTCFullYear();
  const hora = String(dataObj.getUTCHours()).padStart(2, "0");
  const minuto = String(dataObj.getUTCMinutes()).padStart(2, "0");

  if (hours) {
    return `${dia}/${mes}/${ano} - ${hora}h${minuto}`;
  } else {
    return `${dia}/${mes}/${ano}`;
  }
};

export const formatarReais = (valor: number | string): string | null => {
  if (typeof valor === "string") {
    valor = parseFloat(valor);
  }

  if (typeof valor !== "number" || isNaN(valor)) {
    return null;
  }

  const formatoReais = {
    style: "currency",
    currency: "BRL",
  };

  return valor.toLocaleString("pt-BR", formatoReais);
};

export const formatarRealparaDecimal = (valor: string = "0") => {
  // console.log("format: ", valor, typeof valor, valor.toString().replaceAll(".", "").replace(",", "."));
  if (parseInt(valor) || parseInt(valor) === 0) return valor.toString().replaceAll(".", "").replace(",", "."); // Se ja for um numero valido ele retorna o valor em string, sem quebrar.
  // console.log("format2: ", parseFloat(valor.replace(/[^0-9,]*/g, "").replace(",", ".")).toFixed(2));
  return parseFloat(valor.replace(/[^0-9,]*/g, "").replace(",", ".")).toFixed(
    2
  );
};

export const pontuarCpf = (cpf: number | string) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export const formatarTamanhoArquivo = (size: number) => {
  const e = (Math.log(size) / Math.log(1e3)) | 0;
  return +(size / Math.pow(1e3, e)).toFixed(2) + ' ' + ('kMGTPEZY'[e - 1] || '') + 'B';
}

export const cpfToNumber = (cpf: string) => {
  if (!cpf) return "";
  return cpf.match(/\d/g).join("");
}

export const capitalizeFirstLetter = (word: string) => {
  if(!word){
      return;
  };
  const firstLetterCap = word.charAt(0).toUpperCase();
  const remainingLetters = word.slice(1);

  return firstLetterCap + remainingLetters;
};