import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useFornecedor = () => {
  const history = useHistory();
  const [fornecedores, setFornecedores] = useState([]);
  const [fornecedor, setFornecedor] = useState({
    nome_razao_social: "",
    cpf_cnpj: "",
    cnae_activity: {},
    supplier_contacts: {},
    supplier_representatives: {},
    consorcio_ids: {},
  });
  const [error, setError] = useState(null);

  async function getFornecedores(params?: any) {
    try {
      const response = await api.get(`suppliers${stringfyParams(params)}`);
      setFornecedores(response.data);
      // return response.data;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function getFornecedor(id) {
    try {
      const response = await api.get(`suppliers/${id}`);
      setFornecedor(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function createFornecedores(data) {
    try {
      const response = await api.post("suppliers", data);
      alert("Fornecedor criado!");

      history.goBack();

      // setUsuarios(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function updateFornecedor(id: string, data: any) {
    try {
      const response = await api.put(`suppliers/${id}`, data);
      alert("Fornecedor editado com sucesso!");
      history.goBack();
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function DesativarFornecedor(id: any, data: any) {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("ativo", data);

    try {
      // console.log('Data desativar: ', data);

      const response = await api.put(`suppliers/${id}`, formData);

      window.location.reload();

      return response.data;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function getFornecedoresXls(data?: any) {
    try {
      const response = await api.get(`suppliers/xls`, {
        responseType: "blob", // importante!
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Fornecedores.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  return {
    fornecedores,
    fornecedor,
    DesativarFornecedor,
    getFornecedores,
    getFornecedor,
    createFornecedores,
    updateFornecedor,
    getFornecedoresXls,
    error,
  };
};

export default useFornecedor;
