import {
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useField } from "@unform/core";
import { InputMask } from "@react-input/mask";
import { InputNumberFormat } from '@react-input/number-format';

export interface TextInputRootProps {
  children: ReactNode;
}

function TextInputRoot(props: TextInputRootProps) {
  return <div {...props}>{props.children}</div>;
}

export const TextInput = {
  Root: TextInputRoot,
};

interface TextInputInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  placeholder?: string;
  mask?: "phone" | "cpf" | "cep" | "rg" | "cnpj" | "cnh" | "pis" | "time" | "cpfCnpj" | "agenciaBancaria" | "contaBancaria" | string;
  replacement?: "allNumber" | string;
  onMask?: any;
  // @react-input/number-format
  format?: "decimal" | "currency" | "percent" | "unit";
  // @react-input/number-format;
  currency?: "BRL";
  onNumberFormat?: any;
}

export const TextInputInput: React.FC<TextInputInputProps> = ({
  name,
  mask,
  replacement,
  currency,
  format,
  ...props
}) => {
  // console.log('defaultValue: ', props.defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name!);
  const [value, setValue] = useState(props.defaultValue);
  const [numberUnformatted, setNumberUnformatted] = useState(0);

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      clearValue: ref => {
        ref.value = '';
      }
    });
  }, [fieldName, registerField]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // const getMaskFormat = (): string => {
  //   const maskFormats: Record<string, string> = {
  //     phone: "(__) ____-_____",
  //     cpf: "___.___.___-__",
  //     cep: "_____-___",
  //     rg: "__.___.___-_",
  //     cnpj: "__.___.___/____-__",
  //     cpfCnpj: "___.___.___-__",
  //     cnh: "_________",
  //     pis: "___._____.__-_",
  //     time: "__:__"
  //   };

  //   return mask ? maskFormats[mask] || "" : "";
  // };

  // const modify = (input: string) => {
  //   return { mask: input.length > 11 ? '__.___.___/____-__' : '___.___.___-___' };
  // };

  const maskFormats: Record<string, string> = {
    phone: "(__) ____-_____",
    cpf: "___.___.___-__",
    cep: "_____-___",
    rg: "__.___.___-_",
    cnpj: "__.___.___/____-__",
    cpfCnpj: "___.___.___-___",
    cnh: "___________",
    pis: "___._____.__-_",
    time: "__:__",
    agenciaBancaria: "____-__",
    contaBancaria: "____-__",
  };

  const getMaskFormat = (): string => {
    return mask ? maskFormats[mask] || mask : ""; 
  };

  const modify = (input: string) => {
    switch (mask) {
      case "cpfCnpj":
        return { mask: input.length > 11 ? '__.___.___/____-__' : maskFormats[mask] };

      case "contaBancaria":
        switch (input.length) {
          case 6:
            return { mask: '_____-__' };
          case 7:
            return { mask: '______-__' };
          case 8:
            return { mask: '_______-__' };
          case 9:
            return { mask: '________-__' };
          case 10:
            return { mask: '_________-__' };
          case 11:
            return { mask: '__________-__' };
          case 12:
            return { mask: '___________-__' };
          case 13:
            return { mask: '____________-__' };
          case 14:
            return { mask: '_____________-_' };
          default:
            return { mask: maskFormats[mask] };
        };
    };
  };

  const replacementFormats: Record<string, object> = {
    allNumber: { _: /\d/ }
  };

  const getReplacementFormat = (): object => {
    return replacement ? replacementFormats[replacement] || replacement : { _: /./ }; 
  };

  if (mask) {
    return (
      <InputMask
        type="text"
        mask={getMaskFormat()}
        // replacement={{ _: /\d/ }} // apenas numeros
        replacement={getReplacementFormat()}
        modify={modify} // caso precise modificar a mascara
        ref={inputRef}
        value={value}
        onChange={handleInputChange}
        {...props}
      />
    );
  } else if (format) {
    return (
      <InputNumberFormat
        locales="pt-BR"
        format={format}
        currency={currency}
        // onNumberFormat={ (event) => setNumberUnformatted(event.detail.number) } // valor sem mascara
        ref={inputRef}
        value={value}
        onChange={handleInputChange}
        // data-customValue={numberUnformatted} // custom atributo que recebe o valor sem mascara
        {...props}
      />
    );
  } else {
    return (<input ref={inputRef} {...props} />);

  }
};
