import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import useRepresentanteInstituicao from "../../../actions/cruds/useRepresentanteInstituicao";
import Panel from "../../../components/Panel";

import {
  ContainerButton,
  Form,
  Input,
  TextTitle,
  Title,
} from "../../../styles/global";

import { Link, useParams } from "react-router-dom";
import SecondaryTable from "../../../components/SecondaryTable";
import { Button, Container } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export function ViewRepresentante() {
  const { representativeId } = useParams();

  const { representanteInstituicao, getRepresentanteInstituicao } = useRepresentanteInstituicao();

  useEffect(() => {
    getRepresentanteInstituicao(representativeId);
  }, [representativeId]);

  const organizeDataContact = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          id: d.id,
          tipo: d.tipo,
          contato: d.contato,
        }));
        return data;
      }
    }
    return [];
  };

  const dataTitleContact: GridColDef[] = [
    { field: "tipo", headerName: "Tipo", flex: 1 },
    { field: "contato", headerName: "Contato", flex: 1 },
  ];

  return (
    <Container>
      <Form>
        <Title color="#333">
          <span>Visualização do representante</span>
        </Title>
        <Panel titulo="Identificação do representante">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>
                CPF: <span>*</span>
              </TextTitle>
              <Input
                name="cpf"
                value={representanteInstituicao.rg}
                type="text"
                mask="cpf"
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Data de nascimento: <span>*</span>
              </TextTitle>
              <Input
                name="data_nascimento"
                value={representanteInstituicao.data_nascimento}
                type="date"
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>
                Nome: <span>*</span>
              </TextTitle>
              <Input
                name="nome"
                type="text"
                value={representanteInstituicao.nome}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                RG: <span>*</span>
              </TextTitle>
              <Input
                name="rg"
                type="text"
                value={representanteInstituicao.rg}
                mask="rg"
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Órgão Expedidor: <span>*</span>
              </TextTitle>
              <Input
                name="orgao_expedidor"
                type="text"
                value={representanteInstituicao.orgao_expedidor}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                CNH: <span>*</span>
              </TextTitle>
              <Input
                name="cnh"
                type="text"
                value={representanteInstituicao.cnh}
                mask="cnh"
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Data de vencimento: <span>*</span>
              </TextTitle>
              <Input
                name="cnh_data_vencimento"
                type="date"
                value={representanteInstituicao.cnh_data_vencimento}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "150px" }}
                  type="text"
                  name="cep"
                  value={representanteInstituicao.cep}
                  mask="cep"
                  disabled
                />
              </div>
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro:</TextTitle>
              <Input
                type="text"
                name="logradouro"
                value={representanteInstituicao.logradouro}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro:</TextTitle>
              <Input
                type="text"
                name="bairro"
                value={representanteInstituicao.bairro}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número:</TextTitle>
              <Input
                type="text"
                name="numero"
                value={representanteInstituicao.numero}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento:</TextTitle>
              <Input
                type="text"
                name="complemento"
                value={representanteInstituicao.complemento}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Múnicipio:</TextTitle>
              <Input
                type="text"
                name="municipio"
                value={representanteInstituicao.municipio}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF:</TextTitle>
              <Input
                type="text"
                name="uf"
                value={representanteInstituicao.uf}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        {
          representanteInstituicao.representative_contacts && (
            <Panel titulo="Contato">
              <Grid container spacing={6}>
                <Grid xs={12}>
                  <SecondaryTable
                    columsData={dataTitleContact}
                    rowsData={organizeDataContact(
                      representanteInstituicao.representative_contacts
                    )}
                  />
                </Grid>
              </Grid>
            </Panel>
          )
        }

        <Panel titulo="Dados bancários">
          <Grid container spacing={6}>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Tipo de conta: <span>*</span>
              </TextTitle>
              <Input
                type="text"
                name="banco_tipo_conta"
                value={
                  representanteInstituicao.banco_tipo_conta
                    ? `Conta ${representanteInstituicao.banco_tipo_conta}`
                    : ""
                }
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Banco: <span>*</span>
              </TextTitle>
              <Input
                type="text"
                name="banco_nome"
                value={representanteInstituicao.banco_nome}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Agência: <span>*</span>
              </TextTitle>
              <Input
                name="banco_agencia"
                type="text"
                value={representanteInstituicao.banco_agencia}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Conta: <span>*</span>
              </TextTitle>
              <Input
                name="banco_conta"
                type="text"
                value={representanteInstituicao.banco_conta}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <ContainerButton>
          <Link to="/membros/representantes">
            <button>
              <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
            </button>
          </Link>
        </ContainerButton>
      </Form >
    </Container >
  );
}
