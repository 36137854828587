import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useFornecedor from "../../../actions/cruds/useFornecedor";
import Header from "../../../components/Header";
import Radio from "../../../components/InputRadio";
import Panel from "../../../components/Panel";
import { CSidebar } from "../../../components/Sidebar";
import TableSimple from "../../../components/SimpleTable";

import {
  ContainerButton,
  Form,
  FormContainer,
  Input,
  TextTitle,
  Title,
  SelectInput,
  ContainerButton2,
} from "../../../styles/global";
import { FormHandles } from "@unform/core";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export function ViewFornecedor() {
  const { supplierId } = useParams();
  const { fornecedor, getFornecedor } = useFornecedor();
  const [tipoFornecedor, setTipoFornecedor] = useState<Boolean>(false);

  useEffect(() => {
    getFornecedor(supplierId);
  }, [supplierId]);

  useEffect(() => {
    if (fornecedor.cpf_cnpj) {
      if (fornecedor.cpf_cnpj.length <= 11) {
        setTipoFornecedor(false);
      } else {
        setTipoFornecedor(true);
      }
    };
  }, [fornecedor]);

  const organizeDataContact = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          id: d.id,
          tipo: d.tipo,
          contato: d.contato,
        }));
        return data;
      }
    }
    return [];
  };

  const organizeDataRepresentatives = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          id: d.id,
          cpf: d.cpf,
          nome: d.nome,
        }));
        return data;
      }
    }

    return [];
  };

  const organizeDataCertificates = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          id: d.id,
          tipo: d.tipo,
          data_vencimento: d.data_vencimento,
          status_certidao: d.status_certidao,
          descricao: d.descricao,
          arquivo: `${d.url_arquivo}/${d.nome_arquivo}`
        }));
        return data;
      }
    }
    return [];
  };

  const organizeDataConsorcio = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          id: d.id,
          cpf_cnpj: d.cpf_cnpj,
          nome_razao_social: d.nome_razao_social,
        }));
        return data;
      }
    }
    return [];
  };

  //Função necessária para transformar os dados da conta bancária, recebida do db como string, para um array de objeto (necessário para ser utilizado no componente)
  const createArrayBancoTable = (
    inputString1,
    inputString2,
    inputString3,
    inputString4
  ) => {
    const accountData = {
      id: Date.now(),
      tipo: "Principal",
      tipoconta: inputString1,
      banco: inputString2,
      agencia: inputString3,
      conta: inputString4,
    };
    return [accountData];
  };

  const formRef = useRef<FormHandles>(null);

  const dataTitleContact: GridColDef[] = [
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 1,
      headerClassName: "header_table",
    },
    {
      field: "contato",
      headerName: "Contato",
      flex: 1,
      headerClassName: "header_table",
    },
  ];

  const dataTitleRepresentatives: GridColDef[] = [
    {
      field: "cpf",
      headerName: "CPF",
      flex: 1,
      headerClassName: "header_table",
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      headerClassName: "header_table",
    },
  ];

  const dataTitleConsorcio: GridColDef[] = [
    {
      field: "cpf_cnpj",
      headerName: "CNPJ",
      flex: 1,
      headerClassName: "header_table",
    },
    {
      field: "nome_razao_social",
      headerName: "Razão social",
      flex: 1,
      headerClassName: "header_table",
    },
  ];

  const dataTitleCertificates: GridColDef[] = [
    {
      field: "tipo",
      headerName: "Tipo do documento",
      flex: 1,
      headerClassName: "header_table",
    },
    {
      field: "data_vencimento",
      headerName: "Data de vencimento",
      flex: 1,
      headerClassName: "header_table",
    },
    {
      field: "status_certidao",
      headerName: "Status",
      flex: 1,
      headerClassName: "header_table",
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 1,
      headerClassName: "header_table",
    },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      headerClassName: "header_table",
      renderCell: (params) => {
        return (
          <>
            <Link to={{ pathname: params.row.arquivo }} target="_blank">
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
              </Button>
            </Link>
          </>
        );
      }
    }

  ];

  return (
    <Container>
      <Title>
        <span>Visualização de fornecedor</span>
      </Title>
      <Form ref={formRef}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>CPF / CNPJ:</TextTitle>
              <Input
                type="text"
                name="cpfcnpj"
                mask="cpfCnpj"
                placeholder={fornecedor?.cpf_cnpj || "-"}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Nome / Razão Social:</TextTitle>
              <Input
                type="text"
                name="razaosocial"
                placeholder={fornecedor?.nome_razao_social || "-"}
                disabled
              />
            </Grid>
            {tipoFornecedor ? (
              <>
                <Grid xs={6}>
                  <TextTitle>Inscrição Estadual: </TextTitle>
                  <Input
                    type="text"
                    name="inscrEstadual"
                    placeholder={fornecedor?.inscricao_estadual || "-"}
                    disabled
                  />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Nome Fantasia: </TextTitle>
                  <Input
                    type="text"
                    name="nomeFantasia"
                    placeholder={fornecedor?.nome_fantasia || "-"}
                    disabled
                  />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>
                    Este CNPJ que está sendo cadastrado trata-se de um
                    consórcio?
                  </TextTitle>
                  <Input
                    disabled
                    type="text"
                    name="consorcio"
                    placeholder={fornecedor.consorcio ? "Sim" : " Não"}
                  />
                </Grid>
              </>
            ) : (
              <Grid xs={6}>
                <TextTitle>RG:</TextTitle>
                <Input
                  disabled
                  type="text"
                  name="rg"
                  placeholder={fornecedor?.rg || "-"}
                  mask="rg"
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Contato">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {fornecedor?.supplier_contacts?.length > 0 &&
                < SecondaryTable
                  columsData={dataTitleContact}
                  rowsData={fornecedor?.supplier_contacts}
                  getRowId={(row) => row.id}
                />
              }
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <Input
                type="text"
                name="cep"
                placeholder={fornecedor?.cep || "-"}
                disabled
              />
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro:</TextTitle>
              <Input
                type="text"
                name="logradouro"
                placeholder={fornecedor?.logradouro || "-"}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro:</TextTitle>
              <Input
                type="text"
                name="bairro"
                placeholder={fornecedor?.bairro || "-"}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número:</TextTitle>
              <Input
                type="text"
                name="numero"
                placeholder={fornecedor?.numero || "-"}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento:</TextTitle>
              <Input
                type="text"
                name="complemento"
                placeholder={fornecedor?.complemento || "-"}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Muncípio:</TextTitle>
              <Input
                type="text"
                name="municipio"
                placeholder={fornecedor?.municipio || "-"}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF:</TextTitle>
              <Input
                type="text"
                name="uf"
                placeholder={fornecedor?.uf || "-"}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        {fornecedor.consorcio ? null :
          <Panel titulo="Dados bancários">
            <Grid container spacing={6}>
              <Grid xs={6} lg={3}>
                <TextTitle>Tipo de conta:</TextTitle>
                <Input
                  type="text"
                  name="banco_nome"
                  placeholder={fornecedor.banco_tipo_conta || "-"}
                  disabled
                />
              </Grid>
              <Grid xs={6} lg={3}>
                <TextTitle>Banco:</TextTitle>
                <Input
                  type="text"
                  name="banco_nome"
                  placeholder={fornecedor.banco_nome || "-"}
                  disabled
                />
              </Grid>
              <Grid xs={6} lg={3}>
                <TextTitle>Agência:</TextTitle>
                <Input
                  type="text"
                  name="banco_agencia"
                  placeholder={fornecedor.banco_agencia || "-"}
                  disabled
                />
              </Grid>
              <Grid xs={6} lg={3}>
                <TextTitle>Nº da Conta:</TextTitle>
                <Input
                  type="text"
                  name="banco_conta"
                  placeholder={fornecedor.banco_conta || "-"}
                  disabled
                />
              </Grid>
            </Grid>
          </Panel>
        }

        {
          Object.keys(fornecedor?.cnae_activity).length !== 0 && fornecedor?.consorcio == false && (
            <Panel titulo="Atividade CNAE">
              <Grid container spacing={6}>
                <Grid xs={12}>
                  <TextTitle>Atividade Econômica:</TextTitle>
                  <Input
                    id="ativPrincipal"
                    name="cnae_activity_id"
                    placeholder={fornecedor.cnae_activity.nome || "-"}
                    disabled
                  />
                </Grid>
              </Grid>
            </Panel>
          )
        }

        {
          Object.keys(fornecedor?.supplier_representatives).length !==
          0 && (
            <Panel titulo="Representantes legais">
              <Grid container spacing={6}>
                <Grid xs={12}>
                  {fornecedor?.supplier_representatives?.length > 0 &&
                    <SecondaryTable
                      columsData={dataTitleRepresentatives}
                      getRowId={(row) => row.id}
                      rowsData={organizeDataRepresentatives(
                        fornecedor?.supplier_representatives
                      )}
                    />
                  }
                </Grid>
              </Grid>
            </Panel>
          )
        }

        {
          fornecedor.consorcio && fornecedor?.consorcio_parceiro?.length > 0 && (
            <Panel titulo="Consórcio">
              <Grid container spacing={6}>
                <Grid xs={12}>
                  {fornecedor?.consorcio_parceiro?.length > 0 &&
                    <SecondaryTable
                      columsData={dataTitleConsorcio}
                      rowsData={organizeDataConsorcio(
                        fornecedor?.consorcio_parceiro
                      )}
                    />
                  }
                </Grid>
              </Grid>
            </Panel>
          )
        }

        <Panel titulo="Certidões">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {fornecedor?.supplier_certificates?.length > 0 &&
                <SecondaryTable
                  columsData={dataTitleCertificates}
                  rowsData={organizeDataCertificates(
                    fornecedor?.supplier_certificates
                  )}
                />
              }
            </Grid>
          </Grid>
        </Panel>
      </Form >

      <ContainerButton2>
        <Link to="/fornecedores">
          <button className="button_return">
            <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
          </button>
        </Link>
        <Link to={`/fornecedores/fichaPDF/${supplierId}`} target="_blank">
          <button className="button_save">
            <FontAwesomeIcon icon={faDownload} size="1x" /> Download PDF
          </button>
        </Link>
      </ContainerButton2>
    </Container >
  );
}
