import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";


const useSubAcao = () => {
  const history = useHistory();
  const [subacao, setSubAcao] = useState('');
  const [ error, setError] = useState(null);

  // Lista todos as subacoes
  async function getSubAcao(params){
    try{
      const response = await api.get(`sub_actions${stringfyParams(params)}`);
      setSubAcao(response.data);
    } catch(e){
      setError(e);
    }
  }

  // Cria um nova subacao 
  async function createSubAcao(data: any) {
    try {
      const response = await api.post('sub_actions', data);
      alert("Sub-ação criada!");
      console.log('Response createSubAcao: ' , response);
      history.goBack();

    } catch(e) {
      setError(e);
    }
  } 

  return { subacao, getSubAcao, createSubAcao, error };
}

export default useSubAcao;