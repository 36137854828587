import {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { FormHandles } from "@unform/core";
import {
  faX
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "../../../../components/Panel";
import {
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Textarea,
  Title
} from '../../../../styles/global';
import {
  SubTitle,
} from "../styles";
import useProcesso from "../../../../actions/cruds/Processos/useProcesso";
import organizeDataSelect from "../../../../utils/organizeDataSelect";
import useFornecedor from "../../../../actions/cruds/useFornecedor";
import useUsuario from "../../../../actions/cruds/useUsuario";
import useBancos from "../../../../actions/cruds/useBancos";
import Radio from "../../../../components/InputRadio";
import useInstrumento from "../../../../actions/cruds/InstrumentoContratual/useInstrumento";
import SecondaryTable from "../../../../components/SecondaryTable";
import { GridColDef, GridRenderEditCellParams, GridValueFormatterParams, useGridApiRef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import useAteste from "../../../../actions/cruds/Financeiro/useAteste";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { SaveWithProgress } from "../../../../components/SaveCircularIntegration";
import InputFilev2 from "../../../../components/InputFilev2";
import { capitalizeFirstLetter, formatCPF, formatarData, formatarReais, formatarRealparaDecimal } from "../../../../utils/formatDados";
import useContratoGestao from "../../../../actions/cruds/useContratoGestao";
import { useToast } from "../../../../hooks/toast";
import InputNumberFormat from "../../../../components/InputNumberFormat";

const FinanceiroAtesteCreate: React.FC = () => {
  const { processos, getProcessos, processo, getProcesso } = useProcesso();
  const { fornecedor, getFornecedor, fornecedores, getFornecedores } = useFornecedor();
  const { usuarios, getUsers } = useUsuario();
  const { bancos, getBancos } = useBancos();
  const { instrumento, getInstrumento, instrumentos, getInstrumentos } = useInstrumento();
  const { createAteste } = useAteste();
  const { contrGestao, getContratos } = useContratoGestao();
  const { addToast } = useToast();

  const [tipoPagamento, setTipoPagamento] = useState<String>('');
  const [rubricaFront, setRubricaFront] = useState([]);
  const [valorTotalAteste, setValorTotalAteste] = useState<number>();
  const [consorcio, setConsorcio] = useState<boolean>(false);
  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    // getProcessos([{ label: 'ativo', value: true }]);
    getFornecedores([{ label: 'ativo', value: true }]);
    getUsers([{ label: 'ativo', value: true }]);
    getContratos([{ label: 'ativo', value: true }]);
    getInstrumentos([{ label: 'ativo', value: true }]);
    getBancos();
  }, []);

  useEffect(() => {
    if (instrumento?.id) {
      getFornecedor(instrumento?.supplier?.id);
    };

  }, [instrumento]);

  useEffect(() => {
    if (fornecedor?.consorcio == true) {
      setConsorcio(true);
      formRef.current?.setFieldValue("supplier_id", "");
    } else if (fornecedor?.consorcio == false) {
      setConsorcio(false);
      if (instrumento?.tipo_instrumento == 'Convênios') {
        formRef.current?.setFieldValue("supplier_id", { value: fornecedor?.id, label: fornecedor?.nome_razao_social, banco_agencia: instrumento?.banco_agencia, banco_conta: instrumento?.banco_conta, banco_nome: instrumento?.banco_nome, banco_tipo_conta: instrumento?.banco_tipo_conta, cpf_cnpj: fornecedor?.cpf_cnpj });
      } else if (instrumento?.tipo_intrumento != 'Convênios') {
        formRef.current?.setFieldValue("supplier_id", { value: fornecedor?.id, label: fornecedor?.nome_razao_social, banco_agencia: fornecedor?.banco_agencia, banco_conta: fornecedor?.banco_conta, banco_nome: fornecedor?.banco_nome, banco_tipo_conta: fornecedor?.banco_tipo_conta, cpf_cnpj: fornecedor?.cpf_cnpj });
      }
    };

  }, [fornecedor]);

  useEffect(() => {
    const supplierSelected = formRef.current?.getFieldRef("supplier_id")?.props?.value;
    formRef.current?.setFieldValue("supplier_cpf_cnpj", formatCPF(supplierSelected?.cpf_cnpj));
    formRef.current?.setFieldValue("banco_tipo_conta", capitalizeFirstLetter(supplierSelected?.banco_tipo_conta));
    formRef.current?.setFieldValue("banco_nome", supplierSelected?.banco_nome);
    formRef.current?.setFieldValue("banco_agencia", supplierSelected?.banco_agencia);
    formRef.current?.setFieldValue("banco_conta", supplierSelected?.banco_conta);


  }, [tipoPagamento])


  useEffect(() => {
    if (instrumento?.id) {
      if (instrumento?.law_process?.id) {
        getProcesso(instrumento?.law_process?.id);
      }
    };

  }, [instrumento]);

  useEffect(() => {
    if (instrumento?.contractual_instrument_budgets && processo?.project?.id) {
      const newRubricaFront = instrumento.contractual_instrument_budgets.map((rubrica) => {
        return {
          id: rubrica.id,
          valor: null,
          percentual: null,
          contractual_instrument_budget_id: rubrica.id,
          project_line: rubrica.project_line,
          project_id: processo?.project?.id,
        };
      });

      setRubricaFront(newRubricaFront);
    };

  }, [instrumento, processo])

  const organizeDataCertidao = (data) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        supplier_id: d.supplier_id,
        nome: d.nome,
        tipo: d.tipo,
        data_vencimento: formatarData(d.data_vencimento, false),
        descricao: d.descricao,
        status_certidao: d.status_certidao,
        arquivo: `${d.url_arquivo}/${d.nome_arquivo}`,
        documento: d.nome,
      }));
    }
    return data;
  };

  const organizeDataFornecedor = (data: any) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: d.id,
          label: d.nome_razao_social,
          banco_agencia: d.banco_agencia,
          banco_conta: d.banco_conta,
          banco_nome: d.banco_nome,
          banco_tipo_conta: d.banco_tipo_conta,
          cpf_cnpj: d.cpf_cnpj,
        }));
        return data;
      }
    };
    return [];
  };

  const organizeDataLinhas = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          contrato: d?.project_line?.management_contract?.nome_completo,
          comite: d?.project_line?.comittee?.nome,
          codigo_contabil: `${d?.project_line?.expense_type?.codigo}.${d?.project_line?.organization?.codigo.padStart(2, '0')}.${d?.project_line?.management_contract?.codigo.padStart(2, '0')}.${d?.project_line?.comittee?.codigo.padStart(2, '0')}.${d?.project_line?.unit?.codigo.padStart(2, '0')}.${d?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${d?.project_line?.pap_program?.codigo.padStart(2, '0')}.${d?.project_line?.pap_action?.codigo.padStart(2, '0')}.${d?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${d?.project_id?.toString().padStart(2, '0')}.${d?.project_line?.description?.codigo.padStart(3, '0')}`,
          valor: d.valor,
        }));
        return data;
      }
    }
    return [];
  };

  const apiRef = useGridApiRef();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true);

        data["valor_total"] = valorTotalAteste;

        const rowsData = Array.from(apiRef.current.getRowModels(), ([, value]) => (value));
        var valorRubricasFront = rowsData.reduce((total, item) => total + parseFloat(item.valor), 0);
        const rubricaSemValor = rowsData.every(row => row.valor);

        if (valorRubricasFront != valorTotalAteste) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: "Somatória das Rubricas deve ser igual ao valor total do ateste!",
          });
          return;
        };

        if (!rubricaSemValor) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: "Todas as linhas da Rubrica orçamentária devem conter valor.",
          });
          return;
        }

        data["attest_budgets_attributes"] = rowsData.map(d => ({
          contractual_instrument_budget_id: d.id,
          valor: d.valor,
          percentual: ((d.valor / valorTotalAteste) * 100).toFixed(2),
        }));

        data["situacao"] = "cadastro_concluido";

        console.log('data: ', data);
        await createAteste(data);

      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: `${err}`,
        });
        return;
      } finally {
        setSalvando(false);
      }
    },
    [valorTotalAteste, rubricaFront],
  );

  const tipoPagamentoOptions = [
    { value: "Depósito", label: "Depósito" },
    { value: "Boleto/Fatura", label: "Boleto/Fatura" }
  ];

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };


  const handlerChangeEmpresa = (fornecedorSelected: Object) => {
    if (!fornecedorSelected) {
      formRef.current?.setFieldValue("supplier_cpf_cnpj", "");
      formRef.current?.setFieldValue("banco_tipo_conta", "");
      formRef.current?.setFieldValue("banco_nome", "");
      formRef.current?.setFieldValue("banco_agencia", "");
      formRef.current?.setFieldValue("banco_conta", "");
    }
    formRef.current?.setFieldValue("supplier_cpf_cnpj", formatCPF(fornecedorSelected?.cpf_cnpj));
    formRef.current?.setFieldValue("banco_tipo_conta", capitalizeFirstLetter(fornecedorSelected?.banco_tipo_conta));
    formRef.current?.setFieldValue("banco_nome", fornecedorSelected?.banco_nome);
    formRef.current?.setFieldValue("banco_agencia", fornecedorSelected?.banco_agencia);
    formRef.current?.setFieldValue("banco_conta", fornecedorSelected?.banco_conta);

  };

  const dataTitleLinhas: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    {
      field: "valor",
      headerName: "Valor (R$)",
      flex: 2,
      editable: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "Defina um valor!";
        }
        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <InputNumberFormat {...params} />
      ),
    },
    {
      field: "percentual",
      headerName: "Percentual (%)",
      flex: 1,
      valueGetter: (params) => {
        if (!params.row.valor || !valorTotalAteste) {
          return "Defina valor da linha e valor total do instrumento!";
        }
        return ((params.row.valor / valorTotalAteste) * 100).toFixed(2);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    }
  ];

  const dataTitleCertidao: GridColDef[] = [
    { field: "documento", headerName: "Documento", flex: 2 },
    { field: "data_vencimento", headerName: "Data de vencimento", flex: 2 },
    { field: "status_certidao", headerName: "Status", flex: 2 },
    {
      field: "action",
      headerName: "Documento",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link to={{ pathname: params.row.arquivo }} target="_blank">
            <Button variant="text">
              <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
            </Button>
          </Link>
        );
      }
    }
  ];

  return (
    <Container>
      <Form id="formAteste" ref={formRef} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
        <Title color='#333'>
          <span>Cadastro do Formulário de ateste</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Número do Instrumento: <span>*</span></TextTitle>
              <SelectInput
                name="contractual_instrument_id"
                placeholder="Selecione..."
                options={organizeDataSelect(instrumentos.data, "id", "numero")}
                onInputChange={(e) => {
                  getInstrumentos([{ label: "numero", value: e }, { label: 'ativo', value: true }])
                }}
                onChange={(e) => e?.value ? getInstrumento(e?.value) : null}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número do Processo:</TextTitle>
              <Input name="numero_processo" type="text" value={instrumento?.law_process?.id ? `${instrumento.law_process?.codigo} - SIGAD (${instrumento.law_process?.numero})` : ""} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Modalidade da contratação: <span>*</span></TextTitle>
              <Input name="modalidade_contratacao" type="text" value={instrumento.tipo_instrumento} disabled></Input>
            </Grid>
            <Grid xs={12}>
              <SubTitle>Ordenadores de Despesa: (NI nº 1620/2021)</SubTitle>
            </Grid>
            <Grid xs={6}>
              <TextTitle>Ordenador 01: <span>*</span></TextTitle>
              <SelectInput
                name="ordenador1_id"
                placeholder="Selecione..."
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: "nome", value: e }, { label: 'ativo', value: true }])
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Ordenador 02: <span>*</span></TextTitle>
              <SelectInput
                name="ordenador2_id"
                placeholder="Selecione..."
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: "nome", value: e }, { label: 'ativo', value: true }])
                }}
                required
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados do contrato">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data de início do contrato:</TextTitle>
              <Input type="date" name="data_inicio_vigencia" value={instrumento?.data_inicio_vigencia} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de término do contrato:</TextTitle>
              <Input type="date" name="data_termino_vigencia" value={instrumento?.data_termino_vigencia} disabled />
            </Grid>
            <Grid xs={4}>
              <TextTitle>Valor do contrato:</TextTitle>
              <Input type="text" name="valor_contrato" value={formatarReais(instrumento?.valor_total)} disabled />
            </Grid>
            <Grid xs={4}>
              <TextTitle>Desembolso:</TextTitle>
              <Input type="text" name="desembolso" disabled />
            </Grid>
            <Grid xs={4}>
              <TextTitle>Saldo do contrato:</TextTitle>
              <Input type="text" name="saldo_contrato" disabled />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados Bancários">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Empresa: <span>*</span></TextTitle>
              <SelectInput
                id="supplier_id"
                name="supplier_id"
                options={organizeDataFornecedor(fornecedor?.consorcio_parceiro)}
                onChange={(e) => handlerChangeEmpresa(e)}
                isDisabled={!consorcio}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>CPF/CNPJ:</TextTitle>
              <Input name="supplier_cpf_cnpj" type="text" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Pagamento: <span>*</span></TextTitle>
              <Radio
                name="tipo_pagamento"
                options={tipoPagamentoOptions}
                onChange={(e) => setTipoPagamento(e.target.value)}
                required
              />
            </Grid>
            {tipoPagamento == 'Boleto/Fatura' ? (
              <>
                <Grid xs={6}>
                  <TextTitle>Código do boleto: </TextTitle>
                  <Input name="codigo_boleto" type="text" />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Data de vencimento:</TextTitle>
                  <Input name="data_vencimento" type="date" />
                </Grid>
              </>
            ) : (
              <>
                <Grid xs={12}>
                  {instrumento?.tipo_instrumento == 'Convênios' ? (
                    <TextTitle>Conta bancária da empresa (convênio): </TextTitle>
                  )
                    : (
                      <TextTitle>Conta bancária da empresa: </TextTitle>
                    )}
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Tipo de conta:</TextTitle>
                  <Input
                    id="tipoConta"
                    name="banco_tipo_conta"
                    type="text"
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Banco:</TextTitle>
                  <Input
                    id="banco"
                    name="banco_nome"
                    type="text"
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Agência:</TextTitle>
                  <Input name="banco_agencia" type="text" disabled />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Conta:</TextTitle>
                  <Input name="banco_conta" type="text" disabled />
                </Grid>
              </>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Objeto do pagamento">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Descrição:</TextTitle>
              <Textarea name="objeto_pagamento" defaultValue={instrumento?.objeto} disabled />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Medição:</TextTitle>
              <Textarea name="medicao" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número da nota Técnica:</TextTitle>
              <Input name="numero_nota_tecnica" type="text" required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Nota Técnica:</TextTitle>
              <InputFilev2 name="nome_arquivo" required />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Rubrica orçamentária">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Valor total do ateste:</TextTitle>
              <Input
                format="currency"
                currency="BRL"
                name="valor_total"
                onNumberFormat={(event: object) => setValorTotalAteste(event.detail?.number)}
                required
              />
            </Grid>
            <Grid xs={12}>
              {rubricaFront?.length > 0 && (
                <SecondaryTable
                  apiRef={apiRef}
                  columsData={dataTitleLinhas}
                  rowsData={rubricaFront ? organizeDataLinhas(rubricaFront) : []}
                // onCellEditStop={(params, event) => {
                //   if (params.field == 'valor') {
                //     updateValorRubricaPorId(params.value, params.id, event.target.value, valorTotalAteste);
                //   };
                // }}
                />
              )}
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Regularidade fiscal e trabalhista (data de validade das certidões)">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {fornecedor?.supplier_certificates?.length > 0 && (
                <SecondaryTable
                  columsData={dataTitleCertidao}
                  rowsData={organizeDataCertidao(fornecedor?.supplier_certificates)}
                />
              )}
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/financeiro/formularioateste">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formAteste"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default FinanceiroAtesteCreate;