import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const usePerfilAcesso = () => {
  const history = useHistory();
  const [perfisAcesso, setPerfisAcesso] = useState([]);
  const [error, setError] = useState(null);

  async function getPerfisAcesso(params) {
    try {
      const response = await api.get(`profiles${stringfyParams(params)}`);
      console.log(response);
      setPerfisAcesso(response.data);
      return response.data;
    } catch (e) {
      setError(e);
    }
  }

  return {perfisAcesso, getPerfisAcesso};
};

export default usePerfilAcesso;