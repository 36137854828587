import { useState } from "react";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useCompetenciasRepresentanteInstituicao = () => {

    const [error, setError] = useState(null);
    const [competenciasRepresentante, setCompetenciasRepresentante] = useState([]);

    async function getCompetenciasRepresentante(params){
        try {
            const response = await api.get(`representative_competences${stringfyParams(params)}`);
            setCompetenciasRepresentante(response.data);
        } catch (e) {
            setError(e);
        }
    }

    async function deleteCompetence(id: string) {
        try {
            const response = await api.delete(`representative_competences/${id}`);
            alert("Sucesso: Competência Instância excluida!");

            return true;
        } catch (e) {
            setError(e);
            alert("Error: tente novamente!");
            // console.log(error);
        }
    }

    return {getCompetenciasRepresentante, competenciasRepresentante, deleteCompetence, error};
}

export default useCompetenciasRepresentanteInstituicao;