import React from 'react';
import { Loader } from './styles';
import './style.css';


const BensPatrimonios: React.FC = () => {
    return (
        <Loader src="https://homolog.agevap.gt4w.com.br/moduloBensPatrimonio/#/abreFechaCompetencia" frameborder="0" title='Sistema legado'></Loader>
    );
};

export default BensPatrimonios;