import {
  faChevronLeft,
  faSave,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  ContainerButton, ContainerButton2, Form, Input, TextTitle, Textarea, Title
} from '../../../../styles/global';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../../components/Panel";
import { Link, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import InputFilev2 from "../../../../components/InputFilev2";
import useAutorizacaoDespesa from "../../../../actions/cruds/Financeiro/useAutorizacaoDespesa";
import { SaveWithProgress } from "../../../../components/SaveCircularIntegration";


const FinanceiroInformarPagamentoDespesa: React.FC = () => {
  const { autorizacaoDespesaId } = useParams();
  const { autorizacaoDespesa, getAutorizacaoDespesa, updateAutorizacaoDespesa } = useAutorizacaoDespesa();

  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getAutorizacaoDespesa(autorizacaoDespesaId);
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true);

        data["situacao"] = "concluido"

        console.log("data", data);
        await updateAutorizacaoDespesa(autorizacaoDespesaId, data);

      } catch (err) {

      } finally {
        setSalvando(false);
      }
    },
    [],
  );

  return (
    <Container>
      <Form id="FormDespesa" ref={formRef} onSubmit={handleSubmit}>
        <Title color='#333'>
          <span>Informar Pagamento de Despesa</span>
        </Title>
        <Panel titulo="Pagamento da Despesa">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Insira a data de pagamento da despesa:</TextTitle>
              <Input name="data_pagamento" type="date" required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Insira o documento referente ao pagamento da despesa:</TextTitle>
              <InputFilev2 name="nome_arquivo" required />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Observação:</TextTitle>
              <Textarea name="observacao" />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/financeiro/autorizacaodespesa">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"FormDespesa"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default FinanceiroInformarPagamentoDespesa;