import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useAcao = () => {
  const history = useHistory();
  const[acao, setAcoes] = useState('');
  const [error, setError] = useState(null);


  // Lista todas as Ações
  async function getAcoes(params){
    try{
      const response = await api.get(`tbl_actions${stringfyParams(params)}`);
      setAcoes(response.data);
    } catch(e){
      setError(e);
    }
  }
 

  // Cria uma nova Ação 
  async function createAcoes(data: any) {
    try {
      const response = await api.post('tbl_actions', data);
      alert("Despesa criada!");
      console.log('Response createAcao: ' , response);
      history.goBack();

    } catch(e) {
      setError(e);
    }
  } 

  // Busca uma Ação pelo id
  async function getAcaoId(id: string){
    try {
      const response = await api.get(`tbl_actions/${id}`);

      console.log('Response getAcaoId: ', response);
      setAcoes(response.data);

      return response.data;

    } catch(e){
      setError(e);
    }
  }

  // Atualiza a Ação pelo id
  async function updateAcao(id: string, data: any){
    try {
      const response = await api.put(`tbl_actions/${id}`, data);
      console.log('Response updateAcao  : ', response);
      setAcoes(response.data);

      return response.data;

    } catch(e){
      setError(e);
    }
  }

  // Desativa a Ação pelo id
  async function DesativarAcao(id: any, data: any){

    const formData = new FormData();

    formData.append('ativo', data); 

    try {
      const response = await api.put(`tbl_actions/${id}`, formData);

      window.location.reload();
      
      return response.data;

    } catch(e){
      setError(e);
    }
  }


  return { acao, getAcoes, createAcoes, getAcaoId, updateAcao, DesativarAcao, error }
}

export default useAcao;