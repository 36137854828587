import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    row: {
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        borderBottomColor: '#000',
        borderBottomWidth: 2,
        height: 40,
    },
    version: {
        fontFamily: 'Helvetica',
        textAlign: 'right',
        paddingRight: 2,
        borderBottomColor: '#000',
        borderBottomWidth: 2,
        marginVertical: 1,
        fontSize: 7,
    },
    title: {
        textAlign: 'center',
        marginVertical: 4,
        fontSize: 14,
    },
  });