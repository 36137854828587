import { Container } from "@mui/material";
import { Form } from "@unform/web";
import React, { useEffect, useRef } from "react";
import Panel from "../../../../../components/Panel";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ContainerButton,
  Input,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { Link, useParams } from "react-router-dom";
import useUnidade from "../../../../../actions/cruds/useUnidade";

const UnidadeRhCreate: React.FC = () => {
  const { getUnidade, unidade } = useUnidade();
  const { unidadeId } = useParams();

  useEffect(() => {
    getUnidade(unidadeId);
  }, []);

  useEffect(() => {
    console.log("unidadeee", unidade);
  }, [unidade]);

  const formRef = useRef<FormHandles>(null);

  return (
    <Container>
      <Title>
        <span>Visualizar Unidade</span>
      </Title>

      <Form id="form1" ref={formRef}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6} lg={2}>
              <TextTitle>Código:</TextTitle>
              <Input
                type="text"
                name="codigo"
                value={unidade?.codigo}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Unidade:</TextTitle>
              <Input type="text" name="nome" disabled value={unidade?.nome} />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Organização:</TextTitle>
              <Input
                id="organization_id"
                name="organization_id"
                value={unidade?.organization.nome}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Sigla:</TextTitle>
              <Input type="text" name="sigla" value={unidade?.sigla} disabled />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>E-mail:</TextTitle>
              <Input type="text" name="email" value={unidade?.email} disabled />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Telefone:</TextTitle>
              <Input
                type="text"
                name="telefone"
                value={unidade?.telefone}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <Input
                type="text"
                id="cep"
                name="cep"
                value={unidade?.cep}
                disabled
              />
            </Grid>

            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro:</TextTitle>
              <Input
                type="text"
                id="logradouro"
                name="logradouro"
                value={unidade?.logradouro}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro:</TextTitle>
              <Input
                type="text"
                id="bairro"
                name="bairro"
                value={unidade?.bairro}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input
                type="text"
                id="numero"
                name="numero"
                value={unidade?.numero}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input
                type="text"
                id="complemento"
                name="complemento"
                value={unidade?.complemento}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={unidade?.municipio}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF:</TextTitle>
              <Input id="idEstado" name="uf" value={unidade?.uf} disabled />
            </Grid>
          </Grid>
        </Panel>
        <ContainerButton>
          <Link to="/rh/cadastros/unidade">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Voltar
            </button>
          </Link>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default UnidadeRhCreate;
