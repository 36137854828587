import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import MainTable from "../../../../components/MainTable";
import api from "../../../../services/api";
import { Title } from "../../../../styles/global";
import { useAuth } from "../../../../hooks/auth";
import getPermission from "../../../../utils/getPermission";

const InstanciaProtocolo: React.FC = () => {
  const [instanciasProtocolo, setInstanciasProtocolo] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const {login} = useAuth();
  const protocoloPermission = getPermission(login,"Protocolo") ?? '';

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      api
        .get(`protocol_instances?busca_rapida=${searchValue}`)
        .then((response) => {
          setInstanciasProtocolo(response.data);
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`protocol_instances?page=${instanciasProtocolo.pagination.current_page + page}`)
      .then((response) => {
        setInstanciasProtocolo(response.data);
      })
      .catch((error) => console.log(error));
  };

  const columnData = [
    { headerName: "Código", field: "id" },
    { headerName: "Instância", field: "nome" },
    { headerName: "Situação", field: "ativo" },
  ];

  const organizeData = (data: any) => {
    if (typeof data === "object") {
      data = data.map((d: any) => ({
        id: d.id,
        nome: d.nome,
        ativo: d.ativo,
      }));
    }

    return data;
  };

  return (
    <>
      <Title color="#333">
        <span>Gestão de Instância do protocolo</span>
      </Title>
      <Container color="#FFF">
        <MainTable
          onSearchChange={setSearchValue}
          data={organizeData(instanciasProtocolo.data)}
          pagination={instanciasProtocolo.pagination}
          permission={protocoloPermission}
          handlePagination={handlePagination}
          hideFilter={true}
          isMaxWidthCell
          registerLink="/protocolo/cadastros/instancia/cadastrar"
          columns={columnData}
          dropdown={{
            view: { linkTo: "/protocolo/cadastros/instancia/visualizar" },
            editar: { linkTo: "/protocolo/cadastros/instancia/editar" },
            desativar: { linkTo: "protocol_instances" },
          }}
        />
      </Container>
    </>
  );
};

export default InstanciaProtocolo;
