import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";


const useDocumentoProjeto = () => {
  const history = useHistory();
  const [documentosProjeto, setDocumentosProjeto] = useState([]);
  const [ error, setError] = useState(null);

  // Lista todas as Linhas de projetos
  async function getDocumentosProjetos(params?: object){
    try{
      const response = await api.get(`project_documents${stringfyParams(params)}`);
      setDocumentosProjeto(response.data);
    } catch(e){
      setError(e);
    }
  }

  // Lista o projeto por ID
  async function getDocumentoProjeto(id: string){
    try{
      const response = await api.get(`project_documents/${id}`);

      // setLinhaProjeto(response.data);

      // return response.data;
    } catch(e){
      setError(e);
    }
  }

  // Cria um novo projeto 
  async function createDocumentoProjeto(data: any) {
    try {
      await api.post('project_documents', data,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      alert("Documento do Projeto criado!");

    } catch(e) {
      alert("Erro ao criar Documento do Projeto.");
      setError(e);
    }
  } 

  async function deleteDocumentoProjeto(id: number){
    try{
      await api.delete(`project_documents/${id}`);

      alert("Documento do Projeto deletado!");

    }catch(e){
      alert("Erro ao deletar Documento do Projeto.");
      setError(e);
    }
  }

  return { getDocumentoProjeto, documentosProjeto, createDocumentoProjeto, deleteDocumentoProjeto, error };
}

export default useDocumentoProjeto;