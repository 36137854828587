import {
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  ContainerButton2, Form, Input, TextTitle, Title
} from '../../../../../styles/global';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../../../components/Panel";
import { Link, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { SaveWithProgress } from "../../../../../components/SaveCircularIntegration";
import Radio from "../../../../../components/InputRadio";
import optionsBoolean from "../../../../../utils/optionsBoolean";
import useUsuario from "../../../../../actions/cruds/useUsuario";
import useFerias from "../../../../../actions/cruds/Rh/useFerias";

const RhFeriasCreate: React.FC = () => {
  const { empregadoId } = useParams();
  const { usuario, getUser } = useUsuario();
  const { createFerias } = useFerias();

  const [termino, setTermino] = useState<String>('');
  const [quantidadeDias, setQuantidadeDias] = useState<number>(0);
  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getUser(empregadoId);
  }, []);

  useEffect(() => {
    if (usuario?.id) {
      if (usuario?.employee_type?.key == 'empregado') {
        setQuantidadeDias(30);
      } else if (usuario?.employee_type?.key == 'estagiario' || usuario?.employee_type?.key == 'jovem_aprendiz') {
        setQuantidadeDias(15);
      };
    };

  }, [usuario]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true);

        data["user_id"] = empregadoId;
        data["situacao"] = "aguardando_confirmacao";

        console.log("data", data);
        await createFerias(data);

      } catch (err) {

      } finally {
        setSalvando(false);
      }
    },
    [],
  );

  const handlerChangeDataInicio = (dataInicio: string) => {
    let date = new Date(dataInicio.target.value);
    date.setDate(date.getDate() + quantidadeDias);
    date.setUTCHours(0, 0, 0, 0);
    const formattedDate = date.toISOString().split('T')[0];

    setTermino(formattedDate)
  };

  return (
    <Container>
      <Form id="formFerias" ref={formRef} onSubmit={handleSubmit}>
        <Title color='#333'>
          <span>Cadastrar Férias do empregado</span>
        </Title>

        <Panel titulo="Exercício">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data de Admissão:</TextTitle>
              <Input name="dataAdmissao" type="date" value={usuario?.data_admissao} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número de dias:</TextTitle>
              <Input type="text" name="quantidade_dias" value={quantidadeDias} disabled />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Período aquisitivo de:</TextTitle>
              <Input name="periodo_aquisitivo_de" type="date" required />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>até:</TextTitle>
              <Input name="periodo_aquisitivo_ate" type="date" required />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Período para gozo de:</TextTitle>
              <Input type="date" name="periodo_gozo_de" required />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>até:</TextTitle>
              <Input type="date" name="periodo_gozo_ate" required />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Programação de Férias">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Início:</TextTitle>
              <Input name="inicio" type="date" onChange={handlerChangeDataInicio} required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Término:</TextTitle>
              <Input name="termino" type="date" value={termino} disabled />
            </Grid>
            {usuario?.employee_type?.key == 'empregado' && (
              <Grid xs={6}>
                <TextTitle>Adiantamento do 13º?</TextTitle>
                <Radio name="adiantamento" options={optionsBoolean} required />
              </Grid>
            )}
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to={`/rh/empregados/ferias/${empregadoId}`}>
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formFerias"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default RhFeriasCreate;