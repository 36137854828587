import {
  useEffect,
  useRef,
  useState
} from "react";
import { FormHandles } from "@unform/core";
import {
  faChevronLeft, faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "../../../../components/Panel";
import {
  ButtonDownloadArch,
  ContainerButton,
  Form,
  FormContainer,
  Input,
  Instancia,
  TextTitle,
  Title
} from "../../../../styles/global";
import { Link, useParams } from "react-router-dom";
import Radio from "../../../../components/InputRadio";
import SecondaryTable from "../../../../components/SecondaryTable";
import { Button, Container } from "@mui/material";
import useApostilamentoInstrumento from "../../../../actions/cruds/InstrumentoContratual/useApostilamento";
import Grid from "@mui/material/Unstable_Grid2";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import { formatarReais } from "../../../../utils/formatDados";


const InstrumentoApostilamentoView: React.FC = () => {

  const { apostilamentoInstrumento, getApostilamentoInstrumento } = useApostilamentoInstrumento();

  const [valorApostilamento, setValorApostilamento] = useState<String>();
  const [organizeDataApostilamento, setOrganizeDataApostilamento] = useState<Object>([{ id: '0' }]);
  const [valueTipoDespesa, setValueTipoDespesa] = useState<Object>();
  const [valueOrganizacao, setValueOrganizacao] = useState<Object>();
  const [valueContrato, setValueContrato] = useState<Object>();
  const [valueComite, setValueComite] = useState<Object>();
  const [valueUnidade, setValueUnidade] = useState<Object>();
  const [valueFinalidade, setValueFinalidade] = useState<Object>();
  const [valuePrograma, setValuePrograma] = useState<Object>();
  const [valueAcao, setValueAcao] = useState<Object>();
  const [valueSubacao, setValueSubacao] = useState<Object>();
  const [valueProjeto, setValueProjeto] = useState<Object>();
  const [valueDescricao, setValueDescricao] = useState<Object>();
  const [valueCodigoContabil, setValueCodigoContabil] = useState<Array<Object>>([]);


  const additiveId = useParams();
  const apostilamentoId = additiveId.idApostilamento;


  useEffect(() => {
    getApostilamentoInstrumento(apostilamentoId);

  }, [])

  useEffect(() => {
    if (apostilamentoInstrumento.id) {
      if (apostilamentoInstrumento.pagamento_rateado != null) {
        const consorcioRateadoRadio = formRef.current?.getFieldRef("pagamento_rateado");
        if (typeof consorcioRateadoRadio == 'object' && apostilamentoInstrumento.pagamento_rateado == true) {
          consorcioRateadoRadio[0].checked = true;
        } else if (typeof consorcioRateadoRadio == 'object' && apostilamentoInstrumento.pagamento_rateado == false) {
          consorcioRateadoRadio[1].checked = true;
        }
      };

      setOrganizeDataApostilamento([
        {
          id: apostilamentoInstrumento.id,
          aditivo_descricao: apostilamentoInstrumento.apostilamento_descricao,
          arquivo: `${apostilamentoInstrumento.url_apostilamento_arquivo}/${apostilamentoInstrumento.apostilamento_arquivo}`
        }
      ]);

      // if (apostilamentoInstrumento.apostille_budgets != undefined) {
      //   const apostilamentoAccountingCode = apostilamentoInstrumento.apostille_budgets[0].accounting_code;
      //   setValorApostilamento(apostilamentoInstrumento.apostille_budgets[0].valor)
      //   setValueTipoDespesa(apostilamentoAccountingCode.expense_type.nome);
      //   setValueOrganizacao(apostilamentoAccountingCode.organization.nome);
      //   setValueContrato(apostilamentoAccountingCode.management_contract.nome);
      //   setValueComite(apostilamentoAccountingCode.comittee.nome);
      //   setValueUnidade(apostilamentoAccountingCode.unit.nome);
      //   setValueFinalidade(apostilamentoAccountingCode.finality.nome);
      //   setValuePrograma(apostilamentoAccountingCode.program.nome);
      //   setValueAcao(apostilamentoAccountingCode.tbl_action.nome);
      //   setValueSubacao(apostilamentoAccountingCode.sub_action.nome);
      //   setValueProjeto(apostilamentoAccountingCode.project.nome);
      //   setValueDescricao(apostilamentoAccountingCode.description.nome);
      //   setValueCodigoContabil(apostilamentoAccountingCode.codigo_contabil);
      // };

    };

  }, [apostilamentoInstrumento])

  const formRef = useRef<FormHandles>(null);

  const dataTitleAditivo: GridColDef[] = [
    { field: "aditivo_descricao", headerName: "Descrição", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Link to={{ pathname: params.row.arquivo }} target="_blank">
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
              </Button>
            </Link>
          </>
        );
      }
    }
  ];

  const dataTitleRubrica: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    { 
      field: "valor",
      headerName: "Valor (R$)",
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
            return "Defina um valor!";
        }
        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      }
    }
  ];

  const organizeData = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.accounting_code.id,
          contrato: d.accounting_code.management_contract.nome_completo,
          comite: d.accounting_code.comittee.nome,
          codigo_contabil: `${d.accounting_code.expense_type.codigo}.${d.accounting_code.organization.codigo.padStart(2, '0')}.${d.accounting_code.management_contract.codigo.padStart(2, '0')}.${d.accounting_code.comittee.codigo.padStart(2, '0')}.${d.accounting_code.unit.codigo.padStart(2, '0')}.${d.accounting_code.pap_finality.codigo.padStart(2, '0')}.${d.accounting_code.pap_program.codigo.padStart(2, '0')}.${d.accounting_code.pap_action.codigo.padStart(2, '0')}.${d.accounting_code.poa_sub_action.codigo.padStart(2, '0')}.${d.accounting_code.project?.id.toString().padStart(2, '0')}.${d.accounting_code.description.codigo.padStart(3, '0')}`,
          valor: d.valor
        }));
        return data;
      }
    }
    return [];
  };

  return (
    <Container>
      <Title color='#333'>
        <span>
          Visualização do Apostilamento
        </span>
      </Title>

      <Form ref={formRef}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Nome do apostilamento: <span>*</span></TextTitle>
              <Input
                type="text"
                name="nome"
                disabled
                value={apostilamentoInstrumento.nome}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de assinatura: <span>*</span></TextTitle>
              <Input
                type="date"
                name="data_assinatura"
                disabled
                value={apostilamentoInstrumento.data_assinatura}
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Rubrica orçamentária">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Os pagamentos serão rateados? <span>*</span></TextTitle>
              <Radio
                name='pagamento_rateado'
                options={[
                  { id: "rateadoSim", value: "true", label: "Sim" },
                  { id: "rateadoNao", value: "false", label: "Não" }
                ]}
                disabled
              />
            </Grid>
            <Grid xs={12}>
              <SecondaryTable
                columsData={dataTitleRubrica}
                rowsData={organizeData(apostilamentoInstrumento?.apostille_budgets)}
              />
            </Grid>
            <Grid xs={6} style={{marginTop: "20px"}}>
              <TextTitle>Total: {formatarReais(organizeData(apostilamentoInstrumento?.apostille_budgets)?.reduce(function (acc, obj) { return acc + parseFloat(obj?.valor); }, 0))}</TextTitle>
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Apostilamento">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Descrição: </TextTitle>
              <Input type="text" name="apostilamento_descricao" value={apostilamentoInstrumento.apostilamento_descricao} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Apostilamento:</TextTitle>
              <Link
                to={{
                  pathname: `${apostilamentoInstrumento?.url_apostilamento_arquivo}/${apostilamentoInstrumento?.apostilamento_arquivo}`,
                }}
                target="_blank"
              >
                <ButtonDownloadArch name='selecionararquivo' type="button">
                  <FontAwesomeIcon icon={faDownload} size="1x" /> Download...
                </ButtonDownloadArch>
              </Link>
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/instrumentos">
          <button><FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar</button>
        </Link>
      </ContainerButton>
    </Container>
  )
};

export default InstrumentoApostilamentoView;