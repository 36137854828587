import { Container } from '@mui/material';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ButtonAdd, ContainerButton2, CreatableSelectInput, Input, SelectInput, TextTitle, Title } from '../../../../styles/global';
import { Link, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import Panel from '../../../../components/Panel';
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import SecondaryTable from '../../../../components/SecondaryTable';
import usePoa from '../../../../actions/cruds/usePoa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { SaveWithProgress } from '../../../../components/SaveCircularIntegration';
import { v4 as uuid } from 'uuid';
import usePap from '../../../../actions/cruds/usePap';
import usePapFinalidade from '../../../../actions/cruds/usePapFinalidade';
import organizeDataSelect from '../../../../utils/organizeDataSelect';
import usePapPrograma from '../../../../actions/cruds/usePapPrograma';
import usePapAcao from '../../../../actions/cruds/usePapAcao';
import usePoaSubAcao from '../../../../actions/cruds/usePoaSubacao';
import usePoaDepara from '../../../../actions/cruds/usePoaDepara';

const PoaDePara: React.FC = () => {
  const { idPoa } = useParams();
  const { getPoa, selectedPoa } = usePoa();
  const { getPaps } = usePap();
  const { getPapFinalidades, papFinalidades, createPapFinalidades } = usePapFinalidade();
  const { getPapProgramas, papProgramas} = usePapPrograma();
  const { getPapAcoes, papAcoes} = usePapAcao();
  const { getPoaSubacoes, poaSubacoes } = usePoaSubAcao();
  const { poasDepara, getPoasDepara, deletePoaDepara, createPoaDepara } = usePoaDepara();

  const [finalidade, setFinalidade] = useState([]);
  const [programa, setPrograma] = useState([]);
  const [acao, setAcao] = useState([]);
  const [subacao, setSubacao] = useState([]);
  const [linhasDepara, setLinhasDepara] = useState<Array<Object>>([]);
  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getPoa(idPoa);
    getPoasDepara([{label: 'poa_id', value: idPoa}]);
    getPapFinalidades();
    getPapProgramas();
    getPapAcoes();
    getPoaSubacoes();
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        data['poa_id'] = idPoa;

        await createPoaDepara(data);

        getPoasDepara([{label: 'poa_id', value: idPoa}]);
        // console.log(data);
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    },
    [formRef, linhasDepara],
  );

  const organizeDataFinalidade = (data: any[]) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: d.pap_action?.pap_program?.pap_finality?.id,
          label: `${d.pap_action?.pap_program?.pap_finality?.codigo} - ${d.pap_action?.pap_program?.pap_finality?.nome}`,
          codigo: d.pap_action?.pap_program?.pap_finality?.codigo,
        }));

        const codigosUnicos = {};
        data = data.reduce((aux, objeto) => {
          if (!codigosUnicos[objeto.value]) {
            codigosUnicos[objeto.value] = true
            aux.push(objeto);
          }
          return aux;
        }, []);

        return data;
      }
    };
    return [];
  };

  const organizeDataPrograma = (data: any[]) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: d.pap_action?.pap_program?.id,
          label: `${d.pap_action?.pap_program?.codigo} - ${d.pap_action?.pap_program?.nome}`,
          codigo: d.pap_action?.pap_program?.codigo,
        }));

        const codigosUnicos = {};
        data = data.reduce((aux, objeto) => {
          if (!codigosUnicos[objeto.value]) {
            codigosUnicos[objeto.value] = true
            aux.push(objeto);
          }
          return aux;
        }, []);

        return data;
      }
    };
    return [];
  };

  const organizeDataAcao = (data: any[]) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: d.pap_action?.id,
          label: `${d.pap_action?.codigo} - ${d.pap_action?.nome}`,
          codigo: d.pap_action?.codigo,
        }));

        const codigosUnicos = {};
        data = data.reduce((aux, objeto) => {
          if (!codigosUnicos[objeto.value]) {
            codigosUnicos[objeto.value] = true
            aux.push(objeto);
          }
          return aux;
        }, []);

        return data;
      }
    };
    return [];
  };

  const organizeDataSubacao = (data: any[]) => {
    // console.log("data", data)
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: d.id,
          label: `${d.codigo} - ${d.nome}`,
          codigo: d.codigo,
        }));

        const codigosUnicos = {};
        data = data.reduce((aux, objeto) => {
          if (!codigosUnicos[objeto.value]) {
            codigosUnicos[objeto.value] = true
            aux.push(objeto);
          }
          return aux;
        }, []);

        return data;
      }
    };
    return [];
  };

  useEffect(() => {
    console.log("linhas", linhasDepara)

  }, [linhasDepara])

  const resetValues = (field1?: string, field2?: string, field3?: string, field4?: string) => {
    field1 ? formRef.current?.clearField(field1) : null;
    field2 ? formRef.current?.clearField(field2) : null;
    field3 ? formRef.current?.clearField(field3) : null;
    field4 ? formRef.current?.clearField(field4) : null;
  }

  const dataTitleLinhasDepara: GridColDef[] = [
    { field: "poa", headerName: "POA", flex: 1 },
    { field: "referencia", headerName: "Referência", flex: 1 },
    {
      field: "actions",
      type: 'actions',
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      getActions: ({ id }) => {
        return [
            <GridActionsCellItem
              icon={<FontAwesomeIcon icon="fa-solid fa-trash-can" />}
              label="Delete"
              onClick={() => handleDeleteClick(id)}
              color="inherit"
              sx={{color: "#e62121"}}
            />
        ];
      }
    }
  ];

  async function handleDeleteClick(id: GridRowId): React.MouseEventHandler<HTMLButtonElement> | undefined {
    await deletePoaDepara(id);
    getPoasDepara([{label: 'poa_id', value: idPoa}]);
}


  const organizeData = (data: any[]) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          poa: `${d?.pap_finality.codigo}.${d?.pap_program.codigo}.${d?.pap_action.codigo}.${d?.poa_sub_action?.codigo || ''}`,
          referencia: `${d.code_finality_to}.${d.code_program_to}.${d.code_action_to}.${d.code_sub_action_to}`
        }));
        return data;
      }
    }
  }

  return (
    <Container>
      <Title>
        <span>Refereciar - POA</span>
      </Title>

      <Form id="formDepara" ref={formRef} onSubmit={handleSubmit} >
        <Panel titulo='De para'>
          <Grid container spacing={6}>
            <Grid xs={5}>
              <TextTitle>POA</TextTitle>
            </Grid>
            <Grid xs={7}>
              <TextTitle>Referência</TextTitle>
            </Grid>
            <Grid xs={5}>
              <TextTitle>Finalidade: <span>*</span></TextTitle>
              <SelectInput
                id="pap_finality_id"
                name="pap_finality_id"
                placeholder="Selecione..."
                options={organizeDataFinalidade(selectedPoa?.subacoes)}
                onChange={(e) => {
                  setFinalidade(e);
                  resetValues("subacaoPoa", "acaoPoa", "programaPoa");
                }}
                required
              />
            </Grid>
            <Grid xs={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input
                mask="__"
                replacement="allNumber"
                id="code_finality_to"
                name='code_finality_to'
                required
              />
            </Grid>
            <Grid xs={5}>
              <TextTitle>Finalidade: <span>*</span></TextTitle>
              <Input name="name_finality_to" required />
            </Grid>

            <Grid xs={5}>
              <TextTitle>Programa: <span>*</span></TextTitle>
              <SelectInput
                id="pap_program_id"
                name="pap_program_id"
                placeholder="Selecione..."
                options={organizeDataPrograma(selectedPoa?.subacoes?.filter((e) => e.pap_action?.pap_program?.pap_finality?.id == finalidade?.value))}
                onChange={(e) => {
                  setPrograma(e)
                  resetValues("subacaoPoa", "acaoPoa")
                }}
                required
              />
            </Grid>
            <Grid xs={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input
                mask="__"
                replacement="allNumber"
                id="code_program_to"
                name='code_program_to'
                required
              />
            </Grid>
            <Grid xs={5}>
              <TextTitle>Programa: <span>*</span></TextTitle>
              <Input name="name_program_to" required />
            </Grid>

            <Grid xs={5}>
              <TextTitle>Ação: <span>*</span></TextTitle>
              <SelectInput
                id="pap_action_id"
                name="pap_action_id"
                options={organizeDataAcao(selectedPoa?.subacoes?.filter((e) => e.pap_action?.pap_program?.pap_finality?.id == finalidade?.value && e.pap_action?.pap_program?.id == programa?.value))}
                onChange={(e) => {
                  setAcao(e)
                  resetValues("subacaoPoa")
                }}
                required
              />
            </Grid>
            <Grid xs={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input
                mask="__"
                replacement="allNumber"
                id="code_action_to"
                name='code_action_to'
                required
              />
            </Grid>
            <Grid xs={5}>
              <TextTitle>Ação: <span>*</span></TextTitle>
              <Input name="name_action_to" required />
            </Grid>

            <Grid xs={5}>
              <TextTitle>Sub-ação: <span>*</span></TextTitle>
              <SelectInput
                id="poa_sub_action_id"
                name="poa_sub_action_id"
                placeholder="Selecione..."
                options={organizeDataSubacao(selectedPoa?.subacoes?.filter((e) => e.pap_action?.pap_program?.pap_finality?.id == finalidade?.value && e.pap_action?.pap_program?.id == programa?.value && e.pap_action?.id == acao?.value))}
                onChange={(e) => setSubacao(e)}
                required
              />
            </Grid>
            <Grid xs={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input
                mask="__"
                replacement="allNumber"
                id="code_sub_action_to"
                name='code_sub_action_to'
                required
              />
            </Grid>
            <Grid xs={5}>
              <TextTitle>Sub-ação: <span>*</span></TextTitle>
              <Input name="name_sub_action_to" required />
            </Grid>
            <Grid xs={6}>
              <ButtonAdd id='depara' name='depara' type='submit'>
                <FontAwesomeIcon icon={faPlus} size="xl" /> Adicionar
              </ButtonAdd>
            </Grid>
            <Grid xs={12}>
              <SecondaryTable
                columsData={dataTitleLinhasDepara}
                rowsData={organizeData(poasDepara.data)}
              />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/orcamentario/poa">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        {/* <SaveWithProgress form={"formProcesso"} disabled={salvando} ></SaveWithProgress> */}
      </ContainerButton2>
    </Container>
  );
};

export default PoaDePara;