import styled from "styled-components";

export const Container = styled.div`
  margin: 0 0 50px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;

  h1 {
    font-weight: bold;
  }
`;

export const Header = styled.div`
  background-color: #00aeef;
  color: #fff;
  padding: 20px 40px;
  border-radius: 15px 15px 0 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 40px;

  .header_table {
    background-color: #f0f0f0;
  }
`;
