import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../styles/global";
import Panel from "../../../../components/Panel";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { SaveWithProgress } from "../../../../components/SaveCircularIntegration";
import organizeDataSelect from "../../../../utils/organizeDataSelect";
import useReceita from "../../../../actions/cruds/Orcamentario/useReceita";
import useOrgao from "../../../../actions/cruds/useOrgao";
import useContratoGestao from "../../../../actions/cruds/useContratoGestao";
import useComite from "../../../../actions/cruds/useComite";
import { SubTitle } from "../styles";

const OrcamentarioReceitaEdit: React.FC = () => {
  const { receitaId } = useParams();
  const { receita, getReceita, updateReceita } = useReceita();
  const { orgaos, getOrganizations } = useOrgao();
  const { contrGestao, getContratos } = useContratoGestao();
  const { comites, getComittees } = useComite();

  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getOrganizations([{ label: "ativo", value: true }]);
    getContratos([{ label: "ativo", value: true }]);
    getComittees([{ label: "ativo", value: true }]);

  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true)

        // await updateReceita(data);
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    }, []
  );

  return (
    <Container>
      <Form id="formReceita" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Edição de Receita anual por Comitê</span>
        </Title>

        <Panel titulo="Receita - Mês">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Organização:</TextTitle>
              <SelectInput
                name="organization_id"
                placeholder="Selecione..."
                options={organizeDataSelect(orgaos.data, "id", "nome")}
                onInputChange={(e) => {
                  getOrganizations([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Contrato de Gestão:</TextTitle>
              <SelectInput
                name="management_contract_id"
                placeholder="Selecione..."
                options={organizeDataSelect(contrGestao.data, "id", "nome_completo")}
                onInputChange={(e) => {
                  getContratos([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Comitê:</TextTitle>
              <SelectInput
                name="expense_type_id"
                placeholder="Selecione..."
                options={organizeDataSelect(comites.data, "id", "nome")}
                onInputChange={(e) => {
                  getComittees([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Tipo de receita:</TextTitle>
              <SelectInput
                name="tipo_receita"
                placeholder="Selecione..."
                options={[{ value: 1, label: "Tipo1" }]}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Ano da receita</TextTitle>
              <Input type="text" name="ano_receita" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Mês:</TextTitle>
              <SelectInput
                name="mes_receita"
                placeholder="Selecione..."
                options={[{ value: 1, label: "Janeiro" }, { value: 2, label: "Fevereiro" }]}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>*Data da receita*</TextTitle>
              <Input type="month" name="data_receita" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor</TextTitle>
              <Input name="valor_receita" format="currency" currency="BRL" />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Receita Anual">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Estimado - POA:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Saldo ano anterior:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor repassado:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor rendimento:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Ano da receira:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor disponível:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={12}>
              <SubTitle>Indicador</SubTitle>
            </Grid>
            <Grid xs={6}>
              <TextTitle>Estimado POA / DISPONÍVEL:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/orcamentario/receita">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formReceita"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default OrcamentarioReceitaEdit;
