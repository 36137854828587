import {
  useEffect,
  useRef,
  useState
} from "react";
import { FormHandles } from "@unform/core";
import {
  faDownload,
  faX
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "../../../../components/Panel";
import {
  ButtonDownloadArch,
  ContainerButton2,
  Form,
  Input,
  TextTitle,
  Textarea,
  Title
} from '../../../../styles/global';
import Radio from "../../../../components/InputRadio";
import SecondaryTable from "../../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import useAteste from "../../../../actions/cruds/Financeiro/useAteste";
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { formatarData, formatarReais } from "../../../../utils/formatDados";
import { SubTitle } from "../styles";
import useContaBancaria from "../../../../actions/cruds/Financeiro/useContaBancaria";
import useInstrumento from "../../../../actions/cruds/InstrumentoContratual/useInstrumento";
import useAutorizacaoDespesa from "../../../../actions/cruds/Financeiro/useAutorizacaoDespesa";
import useProcesso from "../../../../actions/cruds/Processos/useProcesso";

const FinanceiroAutorizacaoDespesaView: React.FC = () => {
  const { autorizacaoDespesaId } = useParams();
  const { autorizacaoDespesa, getAutorizacaoDespesa } = useAutorizacaoDespesa();
  const { ateste, getAteste } = useAteste();
  const { instrumento, getInstrumento } = useInstrumento();
  const { processo, getProcesso } = useProcesso();

  const [contasBancariasOptions, setContasBancariasOptions] = useState([]);
  const [valorImposto, setValorImposto] = useState<number>(0);
  const [valorLiquido, setValorLiquido] = useState<number>(0);
  const [rubricaAteste, setRubricaAteste] = useState<Array<Object>>([]);

  useEffect(() => {
    getAutorizacaoDespesa(autorizacaoDespesaId);
  }, [])

  useEffect(() => {
    if (autorizacaoDespesa?.id) {
      getAteste(autorizacaoDespesa?.attest?.id);

      const valorImpostos = (autorizacaoDespesa?.pis + autorizacaoDespesa?.cofins + autorizacaoDespesa?.iss + autorizacaoDespesa?.ir + autorizacaoDespesa?.inss);
      const valorLiquido = autorizacaoDespesa?.invoice?.valor - valorImpostos;
      setValorImposto(valorImpostos);
      setValorLiquido(valorLiquido);
    };
  }, [autorizacaoDespesa])

  useEffect(() => {
    if (ateste?.id) {
      formRef.current?.setFieldValue("tipo_pagamento", ateste.tipo_pagamento);
      getInstrumento(ateste?.contractual_instrument?.id);
    };

  }, [ateste]);

  useEffect(() => {
    if (instrumento?.id) {
      if (instrumento?.law_process?.id) {
        getProcesso(instrumento?.law_process?.id);
      }
    };

  }, [instrumento]);

  // useEffect(() => {
  //   if (ateste?.attest_budgets && autorizacaoDespesa?.expense_authorization_budgets) {
  //     const atesteRubrica = ateste?.attest_budgets.find((rubrica) => rubrica.id == autorizacaoDespesa?.expense_authorization_budgets[0]?.attest_budget?.id);
  //     setRubricaAteste(atesteRubrica);
  //   };

  // }, [autorizacaoDespesa, ateste])

  // useEffect(() => {
  //   console.log("rubricaAteste", rubricaAteste);

  // }, [rubricaAteste])

  const organizeDataLinhas = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          contractual_instrument_budget_id: d?.contractual_instrument_budget?.id,
          contrato: d?.contractual_instrument_budget?.project_line?.management_contract?.nome_completo,
          comite: d?.contractual_instrument_budget?.project_line?.comittee?.nome,
          codigo_contabil: `${d?.contractual_instrument_budget?.project_line?.expense_type?.codigo}.${d?.contractual_instrument_budget?.project_line?.organization?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.management_contract?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.comittee?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.unit?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.pap_program?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.pap_action?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${processo?.project?.id?.toString().padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.description?.codigo.padStart(3, '0')}`,
          valor: formatarReais(d.valor),
          percentual: `${d.percentual}%`,
        }));
        return data;
      }
    }
    return [];
  };

  const organizeDataContaContabil = (data) => {
    console.log(data)
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          contractual_instrument_budget_id: d?.attest_budget?.contractual_instrument_budget?.id,
          contrato: d?.attest_budget?.contractual_instrument_budget?.project_line?.management_contract?.nome_completo,
          comite: d?.attest_budget?.contractual_instrument_budget?.project_line?.comittee?.nome,
          codigo_contabil: `${d?.attest_budget?.contractual_instrument_budget?.project_line?.expense_type?.codigo}.${d?.attest_budget?.contractual_instrument_budget?.project_line?.organization?.codigo.padStart(2, '0')}.${d?.attest_budget?.contractual_instrument_budget?.project_line?.management_contract?.codigo.padStart(2, '0')}.${d?.attest_budget?.contractual_instrument_budget?.project_line?.comittee?.codigo.padStart(2, '0')}.${d?.attest_budget?.contractual_instrument_budget?.project_line?.unit?.codigo.padStart(2, '0')}.${d?.attest_budget?.contractual_instrument_budget?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${d?.attest_budget?.contractual_instrument_budget?.project_line?.pap_program?.codigo.padStart(2, '0')}.${d?.attest_budget?.contractual_instrument_budget?.project_line?.pap_action?.codigo.padStart(2, '0')}.${d?.attest_budget?.contractual_instrument_budget?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${processo?.project?.id?.toString().padStart(2, '0')}.${d?.attest_budget?.contractual_instrument_budget?.project_line?.description?.codigo.padStart(3, '0')}`,
          valor: formatarReais(d?.valor),
          percentual: `${d?.percentual}%`,
          conta: `${d?.bank_account?.apelido} - Conta ${d?.bank_account?.conta_tipo}, Ag: ${d?.bank_account?.conta_agencia} CC: ${d?.bank_account?.conta_numero}`
        }));
        return data;
      }
    }
    return [];
  };

  const organizeDataNotaFiscal = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          contrato: d.management_contract.nome_completo,
          documento_fiscal: d.documento_fiscal,
          valor: formatarReais(d.valor),
          tipo_documento: d.tipo,
          dataDocumento: formatarData(d.data, false),
          url_arquivo: `${d.url_arquivo}/${d.nome_arquivo}`,
          attest_budgets: d.attest_budgets,
        }));

        data.forEach((data) => {
          let codigo = [];
          let linhasConcatenadas;

          data.attest_budgets?.forEach((inv, index) => {
            codigo.push(organizeDataAttestBudget(inv))
            linhasConcatenadas = codigo.map(objeto => objeto.linha).join('\n');
          });

          data.linhas = linhasConcatenadas;
        });

        return data;
      }
    }
    return [];
  };

  const organizeDataAttestBudget = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        return {
          linha: `${data?.contractual_instrument_budget?.project_line?.expense_type?.codigo}.${data?.contractual_instrument_budget?.project_line?.organization?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.management_contract?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.comittee?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.unit?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.pap_program?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.pap_action?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${processo?.project?.id?.toString().padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.description?.codigo.padStart(3, '0')}`,
        };
      }
    }
    return [];
  };

  const formRef = useRef<FormHandles>(null);

  const tipoPagamentoOptions = [
    { id: "Depósito", value: "Depósito", label: "Depósito" },
    { id: "Boleto/Fatura", value: "Boleto/Fatura", label: "Boleto/Fatura" }
  ];

  const dataTitleRubrica: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 3 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 2 },
    { field: "valor", headerName: "Valor", flex: 1 },
    { field: "percentual", headerName: "Percentual", flex: 1 },
  ];

  const dataTitleNotaFiscal: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de Gestão", flex: 2 },
    { field: "linhas", headerName: "Linhas", flex: 2 },
    { field: "dataDocumento", headerName: "Data", flex: 1 },
    { field: "tipo_documento", headerName: "Tipo", flex: 1 },
    { field: "documento_fiscal", headerName: "Documento Fiscal", flex: 1 },
    { field: "valor", headerName: "Valor", flex: 1 },
    {
      field: "action",
      headerName: "Documento",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link to={{ pathname: params.row.url_arquivo }} target="_blank">
            <Button variant="text">
              <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
            </Button>
          </Link>
        );
      }
    }
  ];

  const dataTitleContaContabil: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    { field: "valor", headerName: "Valor", flex: 2 },
    { field: "conta", headerName: "Conta", flex: 2 },
  ];

  return (
    <Container>
      <Form id="formAutorizacao" ref={formRef} >
        <Title color='#333'>
          <span>Visualizar Autorização de Despesa</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Número do Instrumento:</TextTitle>
              <Input name="numeroContrato" type="text" value={ateste.contractual_instrument?.numero} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número do Processo: <span>*</span></TextTitle>
              <Input
                type="text"
                name="numeroProcesso"
                value={instrumento?.law_process?.id ? `${instrumento.law_process?.codigo} - SIGAD (${instrumento.law_process?.numero})` : ""}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Modalidade da contratação: <span>*</span></TextTitle>
              <Input name="modalidadeContratacao" type="text" value={ateste.contractual_instrument?.tipo_instrumento} disabled />
            </Grid>
            <Grid xs={12}>
              <SubTitle>Ordenadores de Despesa: (NI nº 1620/2021) </SubTitle>
            </Grid>
            <Grid xs={6}>
              <TextTitle>Ordenador 01: <span>*</span></TextTitle>
              <Input
                type="text"
                name="ordenador1"
                value={ateste.ordenador1?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Ordenador 02: <span>*</span></TextTitle>
              <Input
                type="text"
                name="ordenador2"
                value={ateste.ordenador2?.nome}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados do contrato">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data de início do contrato:</TextTitle>
              <Input type="date" name="dataInicio" value={instrumento?.data_inicio_vigencia} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de término do contrato:</TextTitle>
              <Input type="date" name="dataTermino" value={instrumento?.data_termino_vigencia} disabled />
            </Grid>
            <Grid xs={4}>
              <TextTitle>Valor do contrato:</TextTitle>
              <Input type="text" name="valorTotal" value={formatarReais(instrumento?.valor_total)} disabled />
            </Grid>
            <Grid xs={4}>
              <TextTitle>Desembolso:</TextTitle>
              <Input type="text" name="desembolso" disabled />
            </Grid>
            <Grid xs={4}>
              <TextTitle>Saldo do contrato:</TextTitle>
              <Input type="text" name="saldoContrato" disabled />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados da Empresa">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Empresa: <span>*</span></TextTitle>
              <Input
                type="text"
                id="supplier"
                name="supplier"
                value={ateste.supplier?.nome_razao_social}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>CPF/CNPJ:</TextTitle>
              <Input name="supplierCpfCnpj" type="text" value={ateste.supplier?.cpf_cnpj} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Pagamento: <span>*</span></TextTitle>
              <Radio
                name="tipoPagamento"
                options={tipoPagamentoOptions}
                disabled
              />
            </Grid>
            {ateste.tipo_pagamento == 'Boleto/Fatura' ? (
              <>
                <Grid xs={6}>
                  <TextTitle>Código do boleto: </TextTitle>
                  <Input name="codigoBoleto" type="text" value={ateste.codigo_boleto} disabled />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Data de vencimento:</TextTitle>
                  <Input name="dataVencimento" type="date" value={ateste.data_vencimento} disabled />
                </Grid>
              </>
            ) : (
              <>
                <Grid xs={12}>
                  {ateste?.contractual_instrument?.tipo_instrumento == 'Convênios' ? (
                    <TextTitle>Conta bancária da empresa (convênio): </TextTitle>
                  )
                    : (
                      <TextTitle>Conta bancária da empresa: </TextTitle>
                    )}
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Tipo de conta:</TextTitle>
                  <Input
                    type="text"
                    id="tipoConta"
                    name="bancoIipoConta"
                    value={ateste.banco_tipo_conta}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Banco:</TextTitle>
                  <Input
                    type="text"
                    id="banco"
                    name="bancoNome"
                    value={ateste.banco_nome}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Agência:</TextTitle>
                  <Input name="bancoAgencia" type="text" value={ateste.banco_agencia} disabled />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Conta:</TextTitle>
                  <Input name="bancoConta" type="text" value={ateste.banco_conta} disabled />
                </Grid>
              </>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Objeto do pagamento">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Descrição:</TextTitle>
              <Textarea name="objetoPagamento" defaultValue={ateste.objeto_pagamento} disabled />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Medição:</TextTitle>
              <Textarea name="medicao1" value={ateste.medicao} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número da nota Técnica:</TextTitle>
              <Input name="numeroNotaTecnica" type="text" value={ateste.numero_nota_tecnica} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Nota Técnica:</TextTitle>
              <Link
                to={{
                  pathname: `${ateste?.url_arquivo}/${ateste?.nome_arquivo}`,
                }}
                target="_blank"
              >
                <ButtonDownloadArch name='selecionararquivo' type="button">
                  <FontAwesomeIcon icon={faDownload} size="1x" /> Download...
                </ButtonDownloadArch>
              </Link>
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Rubrica orçamentária">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Valor total do ateste:</TextTitle>
              <span>R${ateste.valor_total}</span>
            </Grid>
            <Grid xs={12}>
              {ateste?.attest_budgets?.length > 0 && processo?.id && (
                <SecondaryTable
                  columsData={dataTitleRubrica}
                  rowsData={organizeDataLinhas(ateste?.attest_budgets)}
                />
              )}
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Nota Fiscal">
          <Grid container spacing={6}>
            {ateste.invoices?.length > 0 && processo?.id && (
              <>
                <Grid xs={12}>
                  <SecondaryTable
                    columsData={dataTitleNotaFiscal}
                    rowsData={organizeDataNotaFiscal(ateste?.invoices)}
                  />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Total: {formatarReais(ateste?.invoices?.reduce(function (acc, obj) { return acc + parseFloat(obj?.valor); }, 0))}</TextTitle>
                </Grid>
              </>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Retenções">
          <Grid container spacing={6}>
            <Grid xs={6} lg={4}>
              <TextTitle>Contrato de gestão:</TextTitle>
              <Input type="text" id="contratoGestao" name="contratoGestao" value={autorizacaoDespesa?.invoice?.management_contract?.nome_completo} disabled />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Nota fiscal:</TextTitle>
              <Input type="text" id="notafiscal" name="notafiscal" value={autorizacaoDespesa?.invoice?.documento_fiscal} disabled />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Valor da nota:</TextTitle>
              <Input type="text" id="tipoConta" name="banco_tipo_conta" value={formatarReais(autorizacaoDespesa?.invoice?.valor)} disabled />
            </Grid>
            <Grid xs={6} lg={2.4}>
              <TextTitle>PIS:</TextTitle>
              <Input id="pis" name="pis" type="text" value={formatarReais(autorizacaoDespesa?.pis)} disabled />
            </Grid>
            <Grid xs={6} lg={2.4}>
              <TextTitle>COFINS:</TextTitle>
              <Input id="cofins" name="cofins" type="text" value={formatarReais(autorizacaoDespesa?.cofins)} disabled />
            </Grid>
            <Grid xs={6} lg={2.4}>
              <TextTitle>ISS:</TextTitle>
              <Input name="iss" type="text" value={formatarReais(autorizacaoDespesa?.iss)} disabled />
            </Grid>
            <Grid xs={6} lg={2.4}>
              <TextTitle>IR:</TextTitle>
              <Input name="ir" type="text" value={formatarReais(autorizacaoDespesa?.ir)} disabled />
            </Grid>
            <Grid xs={6} lg={2.4}>
              <TextTitle>INSS:</TextTitle>
              <Input name="inss" type="text" value={formatarReais(autorizacaoDespesa?.inss)} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor total de impostos:</TextTitle>
              <Input type="text" id="valorTotalImposto" name="valorTotalImposto" value={formatarReais(valorImposto)} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor líquido da nota:</TextTitle>
              <Input type="text" id="valorLiquidoNota" name="valorLiquidoNota" value={formatarReais(valorLiquido)} disabled />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Conta contábil">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {autorizacaoDespesa?.expense_authorization_budgets?.length > 0 && processo?.id && (
                <SecondaryTable
                  columsData={dataTitleContaContabil}
                  rowsData={organizeDataContaContabil(autorizacaoDespesa?.expense_authorization_budgets)}
                />
              )}
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Pagamento da Despesa">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data de pagamento da despesa:</TextTitle>
              <Input name="data_pagamento" type="text" value={formatarData(autorizacaoDespesa?.data_pagamento, false)} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Documento referente ao pagamento da despesa:</TextTitle>
              <Link
                to={{
                  pathname: `${autorizacaoDespesa.url_arquivo}/${autorizacaoDespesa.nome_arquivo}`,
                }}
                target="_blank"
              >
                <ButtonDownloadArch name='selecionararquivo' type="button">
                  <FontAwesomeIcon icon={faDownload} size="1x" /> Download...
                </ButtonDownloadArch>
              </Link>
            </Grid>
            <Grid xs={12}>
              <TextTitle>Observação:</TextTitle>
              <Textarea name="observacao" defaultValue={autorizacaoDespesa.observacao} disabled />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/financeiro/autorizacaodespesa">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <Link to={`/financeiro/autorizacaodespesa/fichaPDF/${autorizacaoDespesaId}`} target="_blank">
          <button className="button_save">
            <FontAwesomeIcon icon={faDownload} size="1x" /> Download PDF
          </button>
        </Link>
      </ContainerButton2>
    </Container>
  );
};

export default FinanceiroAutorizacaoDespesaView;