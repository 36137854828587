import styled, { css } from "styled-components";

interface IStyledDataCellProps {
  isMaxWidthCell?: boolean;
}

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    border: 0;
    width: 100%;
  }

  .input_container {
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 42px;
    padding: 0 12px 0 16px;
    border-radius: 8px;
    border: 1px solid #cccccc;

    button {
      border: 0;
      border-left: 1px solid #cccccc;
      padding-left: 12px;
      background-color: #ffffff;
    }
  }

  .container_option {
    display: flex;
  }

  .button_option {
    display: flex;
    align-items: center;
    font-weight: 600;

    color: #ffffff;

    border: 0;
    border-radius: 6px;
    height: 42px;
    font-size: 16px;
    padding: 0 16px;
    margin-left: 16px;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 24px;

  border: 1px solid #cccccc;
`;

export const StyledHeaderCell = styled.th`
  background-color: #08acef;
  color: white;
  padding: 14px;
  text-align: left;
  cursor: pointer;
`;

export const StyledDataCell = styled.td<IStyledDataCellProps>`
  border: 1px solid #cccccc;
  background-color: #ffffff;
  border-left: 0;
  border-right: 0;
  padding: 12px;

  text-align: justify;
  white-space: normal;
  word-wrap: break-word;

  ${({ isMaxWidthCell }) =>
    !isMaxWidthCell &&
    css`
      max-width: 150px;
    `}
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  border-top: none;
  padding: 10px;

  div {
    display: flex;
    align-items: center;
  }

  button {
    padding: 5px 8px;
    margin: 0 5px;
    cursor: pointer;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;

    &:disabled {
      background-color: #dcdcdc;
      cursor: not-allowed;
    }
  }

  span {
    margin: 0 10px;
  }
`;
