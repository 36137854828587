import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useAtuacao = () => {

    const history = useHistory();
    const [atuacoes, setAtuacoes] = useState([]);
    const [atuacao, setAtuacao] = useState([]);
    const [error, setError] = useState(null);

    async function getAtuacoes(params) {
        try {
            const response = await api.get(`actuations${stringfyParams(params)}`);
            setAtuacoes(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    };

    async function getAtuacao(id) {
        try {
            const response = await api.get(`actuations/${id}`);
            setAtuacao(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    };

    return { atuacoes, getAtuacoes, atuacao, getAtuacao, error };
}

export default useAtuacao;