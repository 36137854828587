import {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { FormHandles } from "@unform/core";
import {
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "../../../../components/Panel";
import {
  ButtonAdd,
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Title
} from '../../../../styles/global';
import useProcesso from "../../../../actions/cruds/Processos/useProcesso";
import organizeDataSelect from "../../../../utils/organizeDataSelect";
import useInstrumento from "../../../../actions/cruds/InstrumentoContratual/useInstrumento";
import SecondaryTable from "../../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import useAteste from "../../../../actions/cruds/Financeiro/useAteste";
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import InputFilev2 from "../../../../components/InputFilev2";
import { formatarData, formatarReais, formatarRealparaDecimal } from "../../../../utils/formatDados";
import useContratoGestao from "../../../../actions/cruds/useContratoGestao";
import { useToast } from "../../../../hooks/toast";
import useInvoice from "../../../../actions/cruds/Financeiro/useInvoices";

const FinanceiroAtesteNotaFiscal: React.FC = () => {
  const { atesteId } = useParams();
  const { ateste, getAteste } = useAteste();
  const { createInvoice, deleteInvoice } = useInvoice();
  const { processo, getProcesso } = useProcesso();
  const { instrumento, getInstrumento } = useInstrumento();
  const { contrGestao, getContratos } = useContratoGestao();
  const { addToast } = useToast();

  const [notaFiscalList, setNotaFiscalList] = useState<Array<Object>>([]);
  const [linhasContrato, setLinhasContrato] = useState<Array<Object>>([]);
  const [linhas, setLinhas] = useState<Array<Object>>([]);
  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getAteste(atesteId);
    getContratos([{ label: 'ativo', value: true }]);
  }, []);



  useEffect(() => {
    if (ateste.id) {
      getInstrumento(ateste?.contractual_instrument?.id);
      setNotaFiscalList(ateste?.invoices)
    }

  }, [ateste]);

  useEffect(() => {
    if (instrumento?.id) {
      if (instrumento?.law_process?.id) {
        getProcesso(instrumento?.law_process?.id);
      }
    };

  }, [instrumento]);

  useEffect(() => {
    formRef.current?.clearField("notaContrato");
    formRef.current?.clearField("notaDocumentoFiscal");
    formRef.current?.setFieldValue("notaValor", "");
    formRef.current?.clearField("notaDocumento");
  }, [notaFiscalList])

  const organizeDataLinhas = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          contrato: d?.contractual_instrument_budget?.project_line?.management_contract?.nome_completo,
          comite: d?.contractual_instrument_budget?.project_line?.comittee?.nome,
          codigo_contabil: `${d?.contractual_instrument_budget?.project_line?.expense_type?.codigo}.${d?.contractual_instrument_budget?.project_line?.organization?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.management_contract?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.comittee?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.unit?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.pap_program?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.pap_action?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${processo?.project?.id?.toString().padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.description?.codigo.padStart(3, '0')}`,
          valor: formatarReais(d.valor),
          percentual: `${d.percentual}%`,
        }));
        return data;
      }
    }
    return [];
  };

  const organizeDataOptionsLinhas = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: d.id,
          label: `Código: ${d?.contractual_instrument_budget?.project_line?.expense_type?.codigo}.${d?.contractual_instrument_budget?.project_line?.organization?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.management_contract?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.comittee?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.unit?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.pap_program?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.pap_action?.codigo.padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${processo?.project?.id?.toString().padStart(2, '0')}.${d?.contractual_instrument_budget?.project_line?.description?.codigo.padStart(3, '0')} - Valor: ${formatarReais(d?.valor)}`,
        }));
        return data;
      }
    }
    return [];
  };

  const organizeDataNotaFiscal = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          contrato: d.management_contract.nome_completo,
          documento_fiscal: d.documento_fiscal,
          valor: formatarReais(d.valor),
          tipo_documento: d.tipo,
          dataDocumento: formatarData(d.data, false),
          url_arquivo: `${d.url_arquivo}/${d.nome_arquivo}`,
          attest_budgets: d.attest_budgets,
        }));

        data.forEach((data) => {
          let codigo = [];
          let linhasConcatenadas;

          data.attest_budgets?.forEach((inv, index) => {
            codigo.push(organizeDataAttestBudget(inv))
            linhasConcatenadas = codigo.map(objeto => objeto.linha).join('\n');
          });

          data.linhas = linhasConcatenadas;
        });

        return data;
      }
    }
    return [];
  };

  const organizeDataAttestBudget = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        return {
          linha: `${data?.contractual_instrument_budget?.project_line?.expense_type?.codigo}.${data?.contractual_instrument_budget?.project_line?.organization?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.management_contract?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.comittee?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.unit?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.pap_program?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.pap_action?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${processo?.project?.id?.toString().padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.description?.codigo.padStart(3, '0')}`,
        };
      }
    }
    return [];
  };

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true);

        data["valor"] = formatarRealparaDecimal(data.valor);
        data["attest_id"] = atesteId;
        data["attest_budget_ids"] = linhas;


        console.log('data: ', data);
        await createInvoice(data);

      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: `${err}`,
        });
        return;
      } finally {
        setSalvando(false);
        getAteste(atesteId);
      }
    },
    [atesteId, notaFiscalList, linhas],
  );

  const tipoDocumentoFiscalOptions = [
    { value: "Nota Fiscal", label: "Nota Fiscal" },
  ];

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  const handleChangeContrato = (contrato: Object) => {
    if (ateste?.attest_budgets) {
      const linhasFiltered = ateste?.attest_budgets.filter((linha) => linha?.contractual_instrument_budget?.project_line?.management_contract?.id == contrato?.value);
      setLinhasContrato(linhasFiltered);
    }
  };

  const handleLinhas = (options) => {
    let linhasHelper = [];
    options.map((o) => linhasHelper.push(o.value));
    setLinhas(linhasHelper);
  };

  const dataTitleLinhas: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    { field: "valor", headerName: "Valor", flex: 1 },
    { field: "percentual", headerName: "Percentual", flex: 1 },
  ];

  const dataTitleNotaFiscal: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de Gestão", flex: 1 },
    { field: "linhas", headerName: "Linhas", flex: 2 },
    { field: "dataDocumento", headerName: "Data", flex: 1 },
    { field: "tipo_documento", headerName: "Tipo", flex: 1 },
    { field: "documento_fiscal", headerName: "Documento Fiscal", flex: 1 },
    { field: "valor", headerName: "Valor", flex: 1 },
    {
      field: "action",
      headerName: "Documento",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Link to={{ pathname: params.row.url_arquivo }} target="_blank">
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
              </Button>
            </Link>
            <Button variant="text" onClick={(e) => onDeleteNotaFiscal(e, params.row)}>
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      }
    }
  ];

  async function onDeleteNotaFiscal(e: any, row: any): Promise<void> {
    await deleteInvoice(row.id)
    getAteste(atesteId);
  };

  return (
    <Container>
      <Form id="formAteste" ref={formRef} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
        <Title color='#333'>
          <span>Notas Fiscais do Ateste</span>
        </Title>

        <Panel titulo="Rubrica orçamentária">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Valor total do ateste:</TextTitle>
              <span>R${ateste.valor_total}</span>
            </Grid>
            <Grid xs={12}>
              {ateste.attest_budgets?.length > 0 && processo?.id && (
                <SecondaryTable
                  columsData={dataTitleLinhas}
                  rowsData={organizeDataLinhas(ateste?.attest_budgets)}
                />
              )}
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Nota Fiscal">
          <Grid container spacing={6}>
            <Grid xs={6} lg={4}>
              <TextTitle>Selecione o contrato de gestão:</TextTitle>
              <SelectInput
                id="management_contract_id"
                name="management_contract_id"
                options={organizeDataSelect(contrGestao.data, "id", "nome_completo")}
                onChange={handleChangeContrato}
                required
              />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Data do documento:</TextTitle>
              <Input name="data" type="date" required />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Tipo de Documento Fiscal:</TextTitle>
              <SelectInput
                id="tipo"
                name="tipo"
                options={tipoDocumentoFiscalOptions}
                required
              />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Documento Fiscal:</TextTitle>
              <Input name="documento_fiscal" type="text" required />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Valor da nota:</TextTitle>
              <Input name="valor" format="currency" currency="BRL" required />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Documento:</TextTitle>
              <InputFilev2 name="nome_arquivo" required />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Rubrica:</TextTitle>
              <SelectInput
                isMulti
                name="attestBudgets"
                options={organizeDataOptionsLinhas(linhasContrato)}
                placeholder="Selecione..."
                onChange={handleLinhas}
                required
              />
            </Grid>
            <Grid xs={6} lg={4}>
              <ButtonAdd id='adicionarunidade' name='adicionarunidade' type="submit">
                <FontAwesomeIcon icon={faSave} size="xl" /> Adicionar e Salvar
              </ButtonAdd>
            </Grid>
            {ateste.invoices?.length > 0 && processo?.id && (
              <>
                <Grid xs={12}>
                  <SecondaryTable
                    columsData={dataTitleNotaFiscal}
                    rowsData={organizeDataNotaFiscal(ateste?.invoices)}
                  />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Total: {formatarReais(ateste?.invoices?.reduce(function (acc, obj) { return acc + parseFloat(obj?.valor); }, 0))}</TextTitle>
                </Grid>
              </>
            )}
          </Grid>
        </Panel>

      </Form>

      <ContainerButton2>
        <Link to="/financeiro/formularioateste">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
      </ContainerButton2>
    </Container>
  );
};

export default FinanceiroAtesteNotaFiscal;