import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";



const usePrograma = () => {
  const history = useHistory();
  const [programas, setProgramas] = useState('');
  const [error, setError] = useState(null);

   // Lista todos os Programas
   async function getProgramas(params){
    try{
      const response = await api.get(`programs${stringfyParams(params)}`);
      setProgramas(response.data);
    } catch(e){
      setError(e);
    }
  }

  // Cria um novo programa 
  async function createPrograma(data: any) {
    try {
      const response = await api.post('programs', data);
      alert("Programa criado!");
      console.log('Response createPrograma: ' , response);
      history.goBack();

    } catch(e) {
      setError(e);
    }
  } 

  return { programas, getProgramas, createPrograma, error};
}

export default usePrograma;