import { useEffect, useState } from "react";
import api from "../../services/api";

const useEndereco = () => {

    const [endereco, setEndereco] = useState({
        localidade: '',
        logradouro: '',
        complemento: '',
        bairro: '',
        uf: ''
    });
    const [error, setError] = useState(null);

    async function getAddress(data) {
        const cep = {"cep": data};

        try {
            const response = await api.get('address/getAddressByCep', {params: cep});
            setEndereco(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    return {endereco, setEndereco, getAddress, error};
}

export default useEndereco;