import { Box, Button, Container, Step, StepLabel, Stepper } from '@mui/material';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton, ContainerButton2, Input, SelectInput, TextTitle, Title } from '../../../../styles/global';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faSave, faX } from '@fortawesome/free-solid-svg-icons';
import { FormHandles } from '@unform/core';
import Panel from '../../../../components/Panel';
import usePap from '../../../../actions/cruds/usePap';
import useOrgao from '../../../../actions/cruds/useOrgao';
import useContratoGestao from '../../../../actions/cruds/useContratoGestao';
import useComite from '../../../../actions/cruds/useComite';
import organizeDataSelect from '../../../../utils/organizeDataSelect';
import { GridColDef } from '@mui/x-data-grid';
import SecondaryTable from '../../../../components/SecondaryTable';


const PapView: React.FC = () => {

    const {idPap} = useParams();
    const {getPap, selectedPap} = usePap();

    useEffect(() => {
        getPap(idPap);
    }, []);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => { 
        try {
            console.log(data);
            
            // const response = createPap(data);
            // console.log('resposta: ', response);

            // addToast({
            //   type: 'success',
            //   title: 'Login efetuado',
            //   description: 'Seu login foi feito com sucesso!',
            // });

            // history.push('/dashboard');
        } catch (err) {    
            // addToast({
            //   type: 'error',
            //   title: 'Erro na autenticação',
            //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
            // });
        }
        },
        [],
    );

    const dataTitle: GridColDef[] = [
        { field: "despesa", headerName: "Despesa", flex: 1 },
        { field: "finalidade", headerName: "Finalidade", flex: 2 },
        { field: "programa", headerName: "Programa", flex: 2 },
        { field: "acao", headerName: "Ação", flex: 2 },
    ];

    const organizeData = (data: any[]) => {
        console.log('data: ', data);
        if (typeof data === 'object') {
          data = data.map(d => ({
            id: d.id,
            despesa: d.pap_program.pap_finality.expense_type.nome,
            finalidade: `${d.pap_program.pap_finality.codigo} - ${d.pap_program.pap_finality.nome}`,
            programa: `${d.pap_program.codigo} - ${d.pap_program.nome}`,
            acao: `${d.codigo} - ${d.nome}`
          }));
        }
        return data;
    }

    return (
        <Container>
            <Title>
                <span>Visualizar PAP</span>
            </Title>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Identificação'>
                    <Grid container spacing={6}>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Organização: <span>*</span></TextTitle>
                            <SelectInput
                                id="organization_id" 
                                name="organization_id"
                                value={[{label: selectedPap.comittee.management_contract.organization.nome.nome, value: selectedPap.comittee.management_contract.organization.nome.id}]}
                                placeholder="Selecione"
                                isDisabled
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Contrato de gestão: <span>*</span></TextTitle>
                            <SelectInput
                                id="management_contract_id" 
                                name="management_contract_id"
                                value={[{label: selectedPap.comittee.management_contract.nome_completo, value: selectedPap.comittee.management_contract.id}]}
                                placeholder="Selecione"
                                isDisabled
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Comitê: <span>*</span></TextTitle>
                            <SelectInput
                                id="comittee_id" 
                                name="comittee_id"
                                value={[{label: selectedPap.comittee.nome, value: selectedPap.comittee.id}]}
                                placeholder="Selecione"
                                isDisabled
                            />
                        </Grid>
                        <Grid xs={6} lg={3}>
                            <TextTitle>Período do PAP início:</TextTitle>
                            <Input type="number" min="2001" max="2099" step="1" name='periodo_de' disabled value={selectedPap.periodo_de} />
                        </Grid>
                        <Grid xs={6} lg={3}>
                            <TextTitle>Período do PAP fim:</TextTitle>
                            <Input type="number" min="2001" max="2099" step="1" name='periodo_ate' disabled value={selectedPap.periodo_ate} />
                        </Grid>
                    </Grid>
                </Panel>

                <Panel titulo='Finalidade, programa e ação'>
                    <SecondaryTable
                        hideFooter={true}
                        rowsData={organizeData(selectedPap.acoes) || []}
                        columsData={dataTitle}
                    />
                </Panel>        
            </Form>
        </Container>
    );
};

export default PapView;