import {
  faPlus,
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import {
  ButtonAdd,
  ContainerButton, Form, FormContainer, Input, SelectInput, TextTitle, Title
} from '../../../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../../../components/Panel";
import useOrgao from "../../../../../actions/cruds/useOrgao";
import useOrgaoGestor from "../../../../../actions/cruds/useOrgaoGestor";
import useContratoGestao from "../../../../../actions/cruds/useContratoGestao";
import useUnidade from "../../../../../actions/cruds/useUnidade";
import useComite from "../../../../../actions/cruds/useComite";
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import SecondaryTable from "../../../../../components/SecondaryTable";



export function EditComite() {
  const { idComite } = useParams();

  const { orgaos, getOrganizations } = useOrgao();
  const { orgaoGestor, getOrgaoGestor } = useOrgaoGestor();
  const { contrGestao, getContratos, setContrGestao } = useContratoGestao();
  const { unidades, getUnidades, createUnidadeComite } = useUnidade();
  const { getComittee, selectComite, updateComittees } = useComite();

  const [unidadesComite, setUnidadesComite] = useState([]);
  const [valueUnidade, setValueUnidade] = useState<Object>();

  useEffect(() => {
    getComittee(idComite);
    getOrganizations();
    getOrgaoGestor();
    getUnidades();
  }, []);

  useEffect(() => {
    const { management_contract } = selectComite;
    formRef.current?.setFieldValue("organizacao_id", management_contract ? [{ label: management_contract.organization.nome.nome, value: management_contract.organization.nome.id }] : []);
    formRef.current?.setFieldValue("orgao_id", management_contract ? [{ label: management_contract.management_entity.nome, value: management_contract.management_entity.id }] : []);
    formRef.current?.setFieldValue("management_contract_id", management_contract ? [{ label: management_contract.nome_completo, value: management_contract.id }] : []);
    formRef.current?.setFieldValue("codigo", selectComite.codigo);
    formRef.current?.setFieldValue("nome", selectComite.nome);
    formRef.current?.setFieldValue("sigla", selectComite.sigla);
    formRef.current?.setFieldValue("data_assinatura", selectComite.data_assinatura);
    formRef.current?.setFieldValue("decreto", selectComite.decreto);

    setUnidadesComite(selectComite.units);
  }, [selectComite]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        data["unit_ids"] = unidadesComite.map(item => item.id);
        updateComittees(idComite, data);
        console.log(data);

      } catch (err) {

      }
    }, [unidadesComite]);


  const organizeDataSelect = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.id,
        label: d.nome,
      }));
    }

    return data;
  }

  const organizeDataContratoGestao = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.id,
        label: d.nome_completo,
      }));
    }

    return data;
  }

  const organizeDataUnidadesComite = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        nome: d.nome,
        id: d.id
      }));
    }

    return data;
  }

  const handleChangeUnidade = (unidadeSelected) => {
    setValueUnidade(unidadeSelected);
  }

  const handleAdicionarUnidade = () => {
    setUnidadesComite([...unidadesComite, {
      "id": valueUnidade.value,
      "nome": valueUnidade.label
    }]);

  }

  const dataTitle: GridColDef[] = [
    { field: "nome", headerName: "Unidade", flex: 4 },
    {
      field: "actions",
      type: 'actions',
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-trash-can" />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
            sx={{ color: "#e62121" }}
          />
        ];
      }
    }
  ];

  function handleDeleteClick(id: number): void {
    setUnidadesComite(unidadesComite.filter(u => u.id !== id));
  }

  const onChangeOrganizacao = (selectedOrganizacao: object) => {
    formRef.current?.clearField("management_contract_id");
    setContrGestao([]);

    const orgao = formRef.current?.getFieldValue("orgao_id");
    const selectedOrganizacaoObj = Array.isArray(selectedOrganizacao) ? selectedOrganizacao[0] : selectedOrganizacao;

    if (orgao) {
      getContratos([
        { label: "organization_id", value: selectedOrganizacaoObj.value },
        { label: "management_entity_id", value: orgao }
      ]);
    }
  };

  const onChangeOrgao = (selectedOrgao: object) => {
    formRef.current?.clearField("management_contract_id");
    setContrGestao([]);

    const organizacao = formRef.current?.getFieldValue("organizacao_id");
    const selectedOrgaoObj = Array.isArray(selectedOrgao) ? selectedOrgao[0] : selectedOrgao;

    if (organizacao) {
      getContratos([
        { label: "organization_id", value: organizacao },
        { label: "management_entity_id", value: selectedOrgaoObj.value }
      ]);
    }
  };

  return (
    <Container>
      <Title color='#333'>
        <span>
          Editar Comitê
        </span>
      </Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Comitê">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Organização: </TextTitle>
              <SelectInput
                id='idOrganizacao'
                name='organizacao_id'
                options={organizeDataSelect(orgaos.data)}
                onChange={onChangeOrganizacao}
                placeholder="Selecionar"
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Órgão gestor: </TextTitle>
              <SelectInput
                id='orgao_id'
                name='orgao_id'
                options={organizeDataSelect(orgaoGestor.data)}
                onChange={onChangeOrgao}
                placeholder="Selecionar"
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Contrato de gestão:  <small>(Com base na organização e órgão gestor escolhidos)</small></TextTitle>
              <SelectInput
                id='idContratogestao'
                name='management_contract_id'
                options={organizeDataContratoGestao(contrGestao.data)}
                placeholder="Selecionar"
                required
              />
            </Grid>
            <Grid xs={6} lg={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input
                mask="__"
                replacement="allNumber"
                id="codigo"
                name='codigo'
                required
              />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Comitê: </TextTitle>
              <Input type="text" name='nome' required />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Sigla:</TextTitle>
              <Input type="text" name="sigla" />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Data de assinatura:</TextTitle>
              <Input type="date" name="data_assinatura" />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Decreto de criação:</TextTitle>
              <Input type="text" name="decreto" />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Unidades">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Unidade: </TextTitle>
              <SelectInput
                id='idUnidade'
                name='unidade_id'
                options={organizeDataSelect(unidades.data)}
                onChange={handleChangeUnidade}
                placeholder="Selecionar"
              />
            </Grid>

            <Grid xs={12}>
              <ButtonAdd id='adicionarunidade' name='adicionarunidade' type="button" onClick={handleAdicionarUnidade}>
                <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
              </ButtonAdd>
            </Grid>

            <Grid xs={12}>
              {unidadesComite.length > 0 && (
                <SecondaryTable
                  hideFooter={true}
                  rowsData={organizeDataUnidadesComite(unidadesComite)}
                  columsData={dataTitle}
                />
              )}
            </Grid>
          </Grid>
        </Panel>

        <ContainerButton>
          <Link to="/orcamentario/cadastros/comites">
            <button type="button"><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
          </Link>
          <button type="submit"><FontAwesomeIcon icon={faSave} /> Salvar</button>
        </ContainerButton>
      </Form>
    </Container>
  )
}
