import React from 'react';
import { Container, Content, Header } from './styles';

interface PanelProps {
    titulo: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
}

const Panel: React.FC<PanelProps> = ({ titulo, children }) => {
    return (
        <Container>
            <Header>
                <h1>{titulo}</h1>
            </Header>
            <Content>
                {children}
            </Content>
        </Container>
    );
};

export default Panel;