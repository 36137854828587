import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useProtocolo = () => {
    const history = useHistory();
    const [protocolos, setProtocolos] = useState([]);
    const [protocolo, setProtocolo] = useState([]);
    const [tiposEncaminhamento, setTiposEncaminhamento] = useState([]);
    const [solicitantesProtocolo, setSolicitantesProtocolo] = useState([]);
    const [error, setError] = useState(null);

    async function getProtocolos(params?: object) {
        try {
            const response = await api.get(`demands${stringfyParams(params)}`);
            setProtocolos(response.data);
        } catch (e) {
            setError(e);
        }
    }

    async function getProtocolo(id: string) {
        try {
            const response = await api.get(`demands/${(id)}`);
            setProtocolo(response.data);
        } catch (e) {
            setError(e);
        }
    }

    async function createProtocolo(data: any) {
        try {
            const response = await api.post("demands", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("response: ", response);
            alert("Protocolo cadastrado com sucesso");
            history.push("/protocolo");

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro: Não foi possível cadastrar, verifique os dados.");
            setError(e);
            // console.log(error);
        }
    }

    async function updateProtocolo(id: string, data: any) {
        try {
            const response = await api.put(`demands/${(id)}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("response: ", response);
            alert("Protocolo atualizado com sucesso");
            history.push("/protocolo");

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro: Não foi possível cadastrar, verifique os dados.");
            setError(e);
            // console.log(error);
        }
    }

    async function updateSituacaoProtocolo(id: string, data: any) {
        try {
            const response = await api.put(`demands/${(id)}`, data)
        } catch (e) {
            setError(e);
        }
    };

    async function getTiposEncaminhamento(params?: object) {
        try {
            const response = await api.get(`forwarding_types${stringfyParams(params)}`);
            setTiposEncaminhamento(response.data);
        } catch (e) {
            setError(e);
        }
    }

    async function getSolicitantesProtocolo(params?: object) {
        try {
            const response = await api.get(`demands/getSolicitantes${stringfyParams(params)}`);
            setSolicitantesProtocolo(response.data);
        } catch (e) {
            setError(e);
        }
    }

    async function getProtocolosXls(data?: any) {
        try {
          const response = await api.get(`demands/xls`, {
            responseType: "blob", // importante!
          });
    
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Protocolos.xlsx");
          document.body.appendChild(link);
          link.click();
        } catch (e) {
          setError(e);
          // console.log(error);
        }
      }

    return { protocolos, getProtocolos, protocolo, getProtocolo, tiposEncaminhamento, getTiposEncaminhamento, createProtocolo, updateProtocolo, solicitantesProtocolo, getSolicitantesProtocolo, updateSituacaoProtocolo, getProtocolosXls, error };
}

export default useProtocolo;