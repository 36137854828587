import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useInstanciaProtocolo = () => {
  const history = useHistory();
  const [instanciasProtocolo, setInstanciasProtocolo] = useState([]);
  const [instanciaProtocolo, setInstanciaProtocolo] = useState([]);
  const [error, setError] = useState(null);

  async function getInstanciasProtocolo(params) {
    try {
      const response = await api.get(`protocol_instances${stringfyParams(params)}`);
      setInstanciasProtocolo(response.data);
    } catch (e) {
      setError(e);
    }
  }

  async function getInstanciaProtocolo(id: string) {
    try {
      const response = await api.get(`protocol_instances/${(id)}`);
      setInstanciaProtocolo(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function createInstanciaProtocolo(data: any) {
    try {
      const response = await api.post('protocol_instances', data);
      alert("Instância do Protocolo cadastrado com sucesso");
      history.push("/protocolo/cadastros/instancia");
      return response.data;
    } catch (e) {
      setError(e);
    }
  }


  async function updateInstanciaProtocolo(id: number, data: any) {
    try {
      const response = await api.put(`protocol_instances/${(id)}`, data);
      alert("Instância do Protocolo atualizado com sucesso!");
      history.push("/protocolo/cadastros/instancia");
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  return { instanciasProtocolo, getInstanciasProtocolo, instanciaProtocolo, getInstanciaProtocolo, createInstanciaProtocolo, updateInstanciaProtocolo }

};

export default useInstanciaProtocolo;