import styled from 'styled-components';

export const Container = styled.div`

    margin: 20px 50px;
    h1  {
        font-weight: 700;
    }

    .CardsContainer {
        margin: 50px 0;
        display: flex;
        gap: 20px;
    }
`;

export const ChartContainer = styled.div`
    display: flex;
    gap: 30px;

    > *:first-child {
        flex: 2;
    }
`;

export const SimplePanel = styled.div`
    background-color: #fff;
    padding: 20px;
    flex: 1;

    h4 {
        margin-bottom: 30px;
    }
`;