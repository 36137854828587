import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";


const useDespesas = () => {

  const history = useHistory();
  const [despesas, setDespesas] = useState('');
  const [ error, setError] = useState(null);

  // Lista todas as despesas
  async function getDespesas(params?: object){
    try{
      const response = await api.get(`expense_types${stringfyParams(params)}`);
      setDespesas(response.data);
    } catch(e){
      setError(e);
    }
  }
 

  // Cria uma nova despesa 
  async function createDespesas(data: any) {
    try {
      const response = await api.post('expense_types', data);
      alert("Despesa criada!");
      history.goBack();

    } catch(e) {
      setError(e);
    }
  } 

  // Busca uma despesa pelo id
  async function getDespesaId(id: string){
    try {
      const response = await api.get(`expense_types/${id}`);
      setDespesas(response.data);

      return response.data;

    } catch(e){
      setError(e);
    }
  }

  // Atualiza a despesa pelo id
  async function updateDespesa(id: string, data: any){
    try {
      const response = await api.put(`expense_types/${id}`, data);
      setDespesas(response.data);
      alert("Despesa alterada!");
      return response.data;

    } catch(e){
      setError(e);
    }
  }

  async function DesativarDespesa(id: any, data: any){

    const formData = new FormData();
    
    formData.append('ativo', data); 

    try {
      const response = await api.put(`expense_types/${id}`, formData);
      
      // console.log('Response updateDespesa: ', response);
      
      window.location.reload();

      return response.data;

    } catch(e){
      setError(e);
    }
  }


  // useEffect(() => {
  //   getDespesas();
  // }, []);

  return { despesas, getDespesas, getDespesaId, updateDespesa, createDespesas, DesativarDespesa, error };
}

export default useDespesas;