import { Container } from "@mui/material";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useCargo from "../../../../../actions/cruds/useCargo";
import useUsuario from "../../../../../actions/cruds/useUsuario";
import Panel from "../../../../../components/Panel";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ContainerButton,
  Input,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";
import organizeDataSelect from "../../../../../utils/organizeDataSelect";
import { formatarReais } from "../../../../../utils/formatDados";
// import { Container } from './styles';

const CargoRhView: React.FC = () => {
  const { cargoId } = useParams();

  const [selectedVinculo, setSelectedVinculo] = useState([]);

  const { cargo, getCargo, updateCargo } = useCargo();
  const { tiposEmpregados, getTiposEmpregados } = useUsuario();

  useEffect(() => {
    getCargo(cargoId);
    getTiposEmpregados();
  }, []);

  useEffect(() => {
    if (cargo.employee_type)
      setSelectedVinculo([
        { label: cargo.employee_type.nome, value: cargo.employee_type.id },
      ]);
  }, [cargo]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: object) => {
    try {
      console.log(data);

      updateCargo(cargoId, data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChangeVinculo = (vinculoSelected) => {
    // necessario para selecionar programaticamente uma opção do select
    setSelectedVinculo(vinculoSelected);
  };

  return (
    <Container>
      <Title>
        <span>Editar Cargo</span>
      </Title>

      <Form id="form1" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Cargo">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>Cargo:</TextTitle>
              <Input
                type="text"
                name="nome"
                placeholder={cargo.nome}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Tipo de Vínculo:</TextTitle>
              <Input
                name="employee_type_id"
                placeholder={cargo.employee_type.nome}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Salário:</TextTitle>
              <Input
                type="string"
                name="salario"
                value={formatarReais(cargo.salario)}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>
        <ContainerButton>
          <Link to="/rh/cadastros/cargo">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Voltar
            </button>
          </Link>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default CargoRhView;
