import { PDFViewer } from '@react-pdf/renderer'
import DocumentPage from '../../../components/PDFComponents/DocumentPage';
import { useEffect, useState } from 'react';
import { formatarData, formatarReais } from '../../../utils/formatDados';
import { useParams } from 'react-router-dom';
import useJuridico from '../../../actions/cruds/Juridico/useJuridico';

const JuridicoFichaPDF: React.FC = () => {
  const { processoJuridicoId } = useParams();
  const { processoJuridico, getProcessoJuridico } = useJuridico();

  const [historico, setHistorico] = useState<Array<Object>>([]);

  useEffect(() => {
    getProcessoJuridico(processoJuridicoId);
  }, [])

  useEffect(() => {
    if (processoJuridico?.id) {
      if (processoJuridico?.judicial_process_histories?.length > 0 && processoJuridico?.judicial_process_histories[0]?.id) {
        const updatedHistorico = [
          {
            id: "historico",
            row: "",
            value: "Histórico do Processo"
          },
        ];
        processoJuridico?.judicial_process_histories?.forEach((historico, index) => {
          updatedHistorico.push(
            {
              id: "historico",
              row: `Histórico ${index + 1}:`,
              value: `Data: ${formatarData(historico?.data, false) ?? "-"}\nDescrição: ${historico?.observacao ?? "-"}`
            },
          );
        });
        setHistorico(updatedHistorico);
      };
    };
  }, [processoJuridico]);

  if (processoJuridico?.id) {
    const pdfData = {
      version: "Formulário eletrônico - SIGA Águas",
      title: "FICHA DE PROCESSO JURÍDICO",
      items: [
        {
          id: "agevapImg",
          logoWithColumns: true,
          itemsColumn: [
            {
              id: "processoJuridicoNumero",
              row: "JURÍDICO Nº",
              value: processoJuridico?.id ?? "-"
            },
            {
              id: "processoNumero",
              row: "PROCESSO Nº",
              value: processoJuridico?.law_process?.codigo ?? "-"
            },
          ]
        },
        {
          id: "organizacao",
          row: "Organização:",
          value: processoJuridico?.organization?.nome ?? "-"
        },
        {
          id: "dataInicio",
          row: "Data de início do processo:",
          value: formatarData(processoJuridico?.data_inicio_processo, false) ?? "-"
        },
        {
          id: "tipoProcesso",
          row: "Tipo de processo:",
          value: processoJuridico?.tipo_processo ?? "-"
        },
        {
          id: "localProcesso",
          row: "Local do processo:",
          value: processoJuridico?.local_processo ?? "-"
        },
        {
          id: "estado",
          row: "Estado:",
          value: processoJuridico?.estado ?? "-"
        },
        {
          id: "prognostico",
          row: "Prognóstico:",
          value: `[ ${processoJuridico?.prognostico == 'Provável' ? "X" : "  "} ] Provável           [ ${processoJuridico?.prognostico == 'Possível' ? "X" : "  "} ] Possível           [ ${processoJuridico?.prognostico == 'Remoto' ? "X" : "  "} ] Remoto`,
        },
        {
          id: "advogado",
          row: "Advogado:",
          value: processoJuridico?.user?.nome ?? "-"
        },
        {
          id: "valorEstimado",
          row: "Valor Estimado:",
          value: formatarReais(processoJuridico?.valor_estimado) ?? "-"
        },
        {
          id: "partes",
          row: "Partes do processo:",
          value: processoJuridico?.partes_processo ?? "-"
        },
        {
          id: "assunto",
          row: "Assunto do processo:",
          value: processoJuridico?.assunto ?? "-"
        },
        {
          id: "dataFinal",
          row: "Data de finalização:",
          value: formatarData(processoJuridico?.data_termino, false) ?? "-"
        },
        {
          id: "valor",
          row: "Valor do processo:",
          value: formatarReais(processoJuridico?.valor_final) ?? "-"
        },
      ],
    };

    if (historico?.length > 0) {
      historico.forEach((hist) => pdfData.items.push(hist))
    };

    return (
      <PDFViewer width="100%" height="1300" className="app" >
        <DocumentPage data={pdfData} />
      </PDFViewer>
    );
  };
};


export default JuridicoFichaPDF;