import { Container } from "@mui/material";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useEndereco from "../../../../../actions/cruds/useEndereco";
import useOrgao from "../../../../../actions/cruds/useOrgao";
import useUnidade from "../../../../../actions/cruds/useUnidade";
import Panel from "../../../../../components/Panel";
import {
  ContainerButton,
  Input,
  InputAddBtn,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import Grid from "@mui/material/Unstable_Grid2";
import organizeDataSelect from "../../../../../utils/organizeDataSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave, faX } from "@fortawesome/free-solid-svg-icons";
import optionsUfs from "../../../../../utils/UFs";
import { Link, useParams } from "react-router-dom";
// import { Container } from './styles';

const UnidadeRhEdit: React.FC = () => {
  const { unidadeId } = useParams();

  const [selectedOption, setSelectedOption] = useState({});
  const [selectedOrg, setSelectedOrg] = useState([]);

  const { getAddress, endereco, setEndereco } = useEndereco();
  const { orgaos, getOrganizations } = useOrgao();
  const { unidade, getUnidade, updateUnidade } = useUnidade();

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: object) => {
    try {
      console.log(data);

      updateUnidade(unidadeId, data);

      // addToast({
      //   type: 'success',
      //   title: 'Login efetuado',
      //   description: 'Seu login foi feito com sucesso!',
      // });

      // history.push('/dashboard');
    } catch (err) {
      // addToast({
      //   type: 'error',
      //   title: 'Erro na autenticação',
      //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
      // });
    }
  }, []);

  const dataAddress = () => {
    const element = document.getElementById("cep");
    getAddress(element.value);
  };

  const handleChangeSelect = (selectedOption) => {
    // necessario para selecionar programaticamente uma opção do select
    setSelectedOption(selectedOption);
  };

  const handleChangeSelectOrg = (selectedOption) => {
    // necessario para selecionar programaticamente uma opção do select
    setSelectedOrg(selectedOption);
  };

  useEffect(() => {
    getUnidade(unidadeId);
    getOrganizations([{ label: "ativo", value: true }]);

  }, []);

  useEffect(() => {
    // necessario para selecionar programaticamente uma opção do select
    const selected = optionsUfs.find((o) => o.value === unidade.uf);
    setSelectedOption(selected);

    // const selected = optionsUfs.find(o => o.value === unidade.uf);
    setSelectedOrg([
      { label: unidade.organization.nome, value: unidade.organization.id },
    ]);

    formRef.current?.setFieldValue("codigo", unidade.codigo);
  }, [unidade]);

  useEffect(() => {
    // necessario para selecionar programaticamente uma opção do select
    const selected = optionsUfs.find((o) => o.value === endereco.uf);
    setSelectedOption(selected);
  }, [endereco]);

  return (
    <Container>
      <Title>
        <span>Editar Unidade</span>
      </Title>

      <Form id="form1" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6} lg={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input
                mask="__"
                replacement="allNumber"
                id="codigo"
                name='codigo'
                required
              />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Unidade:</TextTitle>
              <Input type="text" name="nome" defaultValue={unidade.nome} required />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Organização:</TextTitle>
              <SelectInput
                id="organization_id"
                name="organization_id"
                options={organizeDataSelect(orgaos.data, "id", "nome") || []}
                placeholder="Selecione uma organização"
                onInputChange={(e) => {
                  getOrganizations([{ label: "nome", value: e }, { label: "ativo", value: true }]);
                }}
                value={selectedOrg}
                onChange={handleChangeSelectOrg}
                required
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Sigla:</TextTitle>
              <Input type="text" name="sigla" defaultValue={unidade.sigla} />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>E-mail:</TextTitle>
              <Input type="text" name="email" defaultValue={unidade.email} />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Telefone:</TextTitle>
              <Input
                type="text"
                name="telefone"
                defaultValue={unidade.telefone}
                mask="phone"
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "150px" }}
                  type="text"
                  id="cep"
                  name="cep"
                  defaultValue={unidade.cep}
                  mask="cep"
                />
                <InputAddBtn type="button" onClick={dataAddress}>
                  <FontAwesomeIcon icon={faPlus} /> Pesquisar CEP
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs="auto">
            </Grid>

            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro:</TextTitle>
              <Input
                type="text"
                id="logradouro"
                name="logradouro"
                value={
                  endereco.logradouro ? endereco.logradouro : unidade.logradouro
                }
                onChange={(e) =>
                  setEndereco({ ...endereco, logradouro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro:</TextTitle>
              <Input
                type="text"
                id="bairro"
                name="bairro"
                value={endereco.bairro ? endereco.bairro : unidade.bairro}
                onChange={(e) =>
                  setEndereco({ ...endereco, bairro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input
                type="text"
                id="numero"
                name="numero"
                defaultValue={unidade.numero}
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input
                type="text"
                id="complemento"
                name="complemento"
                defaultValue={unidade.complemento}
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={
                  endereco.localidade ? endereco.localidade : unidade.municipio
                }
                onChange={(e) =>
                  setEndereco({ ...endereco, localidade: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF:</TextTitle>
              <SelectInput
                id="idEstado"
                name="uf"
                options={optionsUfs}
                value={selectedOption}
                onChange={handleChangeSelect}
                placeholder="Selecionar"
              />
            </Grid>
          </Grid>
        </Panel>
        <ContainerButton>
          <Link to="/rh/cadastros/unidade">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
            </button>
          </Link>
          <button type="submit" form="form1">
            <FontAwesomeIcon icon={faSave} /> Salvar
          </button>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default UnidadeRhEdit;
