import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import Panel from "../../../../../components/Panel";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { SaveWithProgress } from "../../../../../components/SaveCircularIntegration";
import useOrgao from "../../../../../actions/cruds/useOrgao";
import organizeDataSelect from "../../../../../utils/organizeDataSelect";
import useContaBancaria from "../../../../../actions/cruds/Financeiro/useContaBancaria";
import useDespesas from "../../../../../actions/cruds/useDespesas";
import useContratoGestao from "../../../../../actions/cruds/useContratoGestao";
import useComite from "../../../../../actions/cruds/useComite";
import bankApi from "../../../../../services/bankApi";
import { capitalizeFirstLetter } from "../../../../../utils/formatDados";

const FinanceiroContaBancariaEdit: React.FC = () => {
  const { contaBancariaId } = useParams();
  const { contaBancaria, getContaBancaria, updateContaBancaria } = useContaBancaria();
  const { orgaos, getOrganizations } = useOrgao();
  const { despesas, getDespesas } = useDespesas();
  const { contrGestao, getContratos } = useContratoGestao();
  const { comites, getComittees } = useComite();

  const [banks, setBanks] = useState([]);
  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getContaBancaria(contaBancariaId);
    getOrganizations([{ label: 'ativo', value: true }]);
    getDespesas([{ label: 'ativo', value: true }]);
    getContratos([{ label: 'ativo', value: true }]);
    getComittees([{ label: 'ativo', value: true }]);

    const fetchData = async () => {
      try {
        const { data } = await bankApi.get("banks/v1");
        setBanks(
          data.map((d) => ({
            value: d.fullName,
            label: `${d.code ? d.code + " - " : ""} ${d.fullName}`,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (contaBancaria?.id) {
      formRef.current?.setFieldValue("expense_type_id", { value: contaBancaria?.expense_type?.id, label: contaBancaria?.expense_type?.nome });
      formRef.current?.setFieldValue("organization_id", { value: contaBancaria?.organization?.id, label: contaBancaria?.organization?.nome });
      formRef.current?.setFieldValue("management_contract_id", { value: contaBancaria?.management_contract?.id, label: contaBancaria?.management_contract?.nome_completo });
      formRef.current?.setFieldValue("comittee_id", { value: contaBancaria?.comittee?.id, label: contaBancaria?.comittee?.nome });
      formRef.current?.setFieldValue("apelido", contaBancaria?.apelido);
      formRef.current?.setFieldValue("vinculante", contaBancaria?.vinculante);
      formRef.current?.setFieldValue("conta_tipo", { value: contaBancaria?.conta_tipo, label: `Conta ${capitalizeFirstLetter(contaBancaria?.conta_tipo)}` });
      formRef.current?.setFieldValue("conta_banco", { value: contaBancaria?.conta_banco, label: contaBancaria?.conta_banco });
      formRef.current?.setFieldValue("conta_agencia", contaBancaria?.conta_agencia);
      formRef.current?.setFieldValue("conta_numero", contaBancaria?.conta_numero);
    };

  }, [contaBancaria]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true)

        console.log("data", data);
        await updateContaBancaria(contaBancariaId, data);
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    }, []
  );

  return (
    <Container>
      <Form id="formConta" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Edição da Conta bancária</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Tipo de despesa:</TextTitle>
              <SelectInput
                name="expense_type_id"
                placeholder="Selecione..."
                options={organizeDataSelect(despesas.data, "id", "nome")}
                onInputChange={(e) => {
                  getDespesas([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Organização:</TextTitle>
              <SelectInput
                name="organization_id"
                placeholder="Selecione..."
                options={organizeDataSelect(orgaos.data, "id", "nome")}
                onInputChange={(e) => {
                  getOrganizations([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Contrato de gestão:</TextTitle>
              <SelectInput
                name="management_contract_id"
                placeholder="Selecione..."
                options={organizeDataSelect(contrGestao.data, "id", "nome_completo")}
                onInputChange={(e) => {
                  getContratos([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Comitê de bacia:</TextTitle>
              <SelectInput
                name="comittee_id"
                placeholder="Selecione..."
                options={organizeDataSelect(comites.data, "id", "nome")}
                onInputChange={(e) => {
                  getComittees([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Apelido da conta:</TextTitle>
              <Input
                type="text"
                name="apelido"
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Vinculante:</TextTitle>
              <Input
                type="text"
                name="vinculante"
                required
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados bancários">
          <Grid container spacing={6}>
            <Grid xs={6} lg={3}>
              <TextTitle>Tipo de conta:</TextTitle>
              <SelectInput
                id="tipoConta"
                name="conta_tipo"
                options={[
                  { value: "corrente", label: "Conta Corrente" },
                  { value: "poupanca", label: "Conta Poupança" },
                ]}
                placeholder="Selecione..."
                required
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Banco:</TextTitle>
              <SelectInput
                id="tipoBanco"
                name="conta_banco"
                options={banks}
                placeholder="Selecione..."
                required
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Agência:</TextTitle>
              <Input type="text" mask='agenciaBancaria' name="conta_agencia" required />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Nº da Conta:</TextTitle>
              <Input type="text" mask='contaBancaria' name="conta_numero" required />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/financeiro/cadastros/contabancaria">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formConta"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default FinanceiroContaBancariaEdit;
