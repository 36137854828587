import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useDescricao = () => {
  const history = useHistory();
  const[descricao, setDescricao] = useState([]);
  const[selectDescricao, setSelectDescricao] = useState({});
  const [error, setError] = useState(null);


  // Lista todas as descrições
  async function getDescricoes(params){
    try{
      const response = await api.get(`descriptions${stringfyParams(params)}`);
      setDescricao(response.data);
    } catch(e){
      setError(e);
    }
  }
 

  // Cria uma nova descrição 
  async function createDescricao(data: any) {
    try {
      const response = await api.post('descriptions', data);
      alert("Descrição criada!");
      history.goBack();

    } catch(e: any) {
      alert('Descrição já existente!');
      setError(e);
    }
  } 

  // Busca uma Descrição pelo id
  async function getDescricaoId(id: string){
    try {
      const response = await api.get(`descriptions/${id}`);
      setSelectDescricao(response.data);

      return response.data;

    } catch(e){
      setError(e);
    }
  }

  // Atualiza a Descrição pelo id
  async function updateDescricao(id: string, data: any){
    try {
      const response = await api.put(`descriptions/${id}`, data);
      alert("Descrição alterada!");

      return response.data;

    } catch(e){
      setError(e);
    }
  }

  return { descricao, createDescricao, getDescricoes, getDescricaoId, updateDescricao, selectDescricao, error }
}

export default useDescricao;