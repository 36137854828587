import {
  faChevronLeft,
  faCloudArrowUp,
  faPlus,
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import useInstituicao from "../../../actions/cruds/useInstituicao";
import Header from "../../../components/Header";
import Panel from "../../../components/Panel";
import { CSidebar } from "../../../components/Sidebar";

import Grid from '@mui/material/Unstable_Grid2';

import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton,
  Form,
  FormContainer,
  Input,
  SelectInput,
  Textarea,
  TextTitle,
  Title
} from '../../../styles/global';

import InputFile from "../../../components/InputFile";
import useDocumentosInstituicao from "../../../actions/cruds/useDocumentosInstituicao";
import { Link, useParams } from "react-router-dom";
import organizeDataSelect from "../../../utils/organizeDataSelect";
import useComite from "../../../actions/cruds/useComite";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import InputFilev2 from "../../../components/InputFilev2";
import useProjeto from "../../../actions/cruds/useProjeto";
import useDocumentoProjeto from "../../../actions/cruds/useDocumentoProjeto";


interface CreateProjectDocumentFormData {
  nome: string;
  email: string;
  cpf: string;
  password: string;
  organization_id: number;
}

export function CreateProjectDocument() {

  const { idProjeto } = useParams();

  const {getProjetoId, projeto} = useProjeto();
  const {createDocumentoProjeto, deleteDocumentoProjeto} = useDocumentoProjeto();

  useEffect(() => {
    getProjetoId(idProjeto);
  }, []);


  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        // console.log(data);

        data["project_id"] = idProjeto;
        await createDocumentoProjeto(data);

        getProjetoId(idProjeto);

        formRef.current?.reset();

        // addToast({
        //   type: 'success',
        //   title: 'Login efetuado',
        //   description: 'Seu login foi feito com sucesso!',
        // });

        // history.push('/dashboard');
      } catch (err) {
        // addToast({
        //   type: 'error',
        //   title: 'Erro na autenticação',
        //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        // });
      }
    },
    [],
  );

  const organizeData = (data) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        descricao: d.nome,
        url_arquivo: `${d.url_arquivo}/${d.nome_arquivo}`
      }));
    }
    return data;
  }

  const dataTitle: GridColDef[] = [
    { field: "descricao", headerName: "Descrição do documento", flex: 4 },
    {
      field: "actions",
      type: 'actions',
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      getActions: (params) => {
        return [
          <>
            <Link to={{ pathname: params.row.url_arquivo }} target="_blank">
              <GridActionsCellItem
                icon={<FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />}
                label="Delete"
                onClick={() => handleDeleteClick(id)}
                color="inherit"
                sx={{color: "#1473e6"}}
              />
            </Link>
            
            <GridActionsCellItem
              icon={<FontAwesomeIcon icon="fa-solid fa-trash-can" />}
              label="Delete"
              onClick={() => handleDeleteClick(params.id)}
              color="inherit"
              sx={{color: "#e62121"}}
            />
          </> 
        ];
      }
    }
  ];

  async function handleDeleteClick(id: number): Promise<void> {
    await deleteDocumentoProjeto(id);
    getProjetoId(idProjeto);
  }

  return (
    <Container>
      <Title color='#333'>
        <span>Documentos do Projeto</span>
      </Title>
      <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
        <Panel titulo={`Projeto: ${projeto.id || ""} - ${projeto.nome || ""}`}>
          <Grid container spacing={6}>
            <Grid xs={12} lg={6} >
              <TextTitle>Nome: <span>*</span></TextTitle>
              <Input type="text" name="nome" required />
            </Grid>

            <Grid xs={6}>
              <TextTitle>Documento: <span>*</span></TextTitle>
              <InputFilev2 name="nome_arquivo" required />
            </Grid>
          </Grid>
          <Grid xs={12}>
            <ButtonAdd type="submit"><FontAwesomeIcon icon={faPlus} /> Adicionar</ButtonAdd>
          </Grid>
          <Grid xs={12}>
            <SecondaryTable
              rowsData={organizeData(projeto.project_documents)}
              columsData={dataTitle}
            />
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/projetos">
          <button><FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar</button>
        </Link>
      </ContainerButton>
    </Container>
  )
}

