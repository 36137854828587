import {
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import {
  ContainerButton, Form, FormContainer, Input, SelectInput, TextTitle, Textarea, Title
} from '../../../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../../../components/Panel";
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from "@mui/material";
import useOrgao from "../../../../../actions/cruds/useOrgao";
import useOrgaoGestor from "../../../../../actions/cruds/useOrgaoGestor";
import organizeDataSelect from "../../../../../utils/organizeDataSelect";
import { Link, useParams } from "react-router-dom";
import useContratoGestao from "../../../../../actions/cruds/useContratoGestao";



export function EditContratoGestao() {

  const { idContrato } = useParams();

  const { getContratoId, selectContrGestao, updateContrato } = useContratoGestao();
  const { orgaos, getOrganizations } = useOrgao();
  const { orgaoGestor, getOrgaoGestor } = useOrgaoGestor();

  const [orgao, setOrgao] = useState("");
  const [contrato, setContrato] = useState("");

  useEffect(() => {
    getContratoId(idContrato);
    getOrganizations();
    getOrgaoGestor();
  }, []);

  useEffect(() => {
    const { organization } = selectContrGestao;
    const { management_entity } = selectContrGestao;
    formRef.current?.setFieldValue("organization_id", organization ? [{ label: organization.nome, value: organization.id }] : []);
    formRef.current?.setFieldValue("management_entity_id", management_entity ? [{ label: management_entity.nome, value: management_entity.id }] : []);
    formRef.current?.setFieldValue("codigo", selectContrGestao.codigo);
    formRef.current?.setFieldValue("nome", selectContrGestao.nome);
    formRef.current?.setFieldValue("data_assinatura", selectContrGestao.data_assinatura);
    formRef.current?.setFieldValue("prazo_delegacao", selectContrGestao.prazo_delegacao);

    setOrgao(management_entity.nome);
    setContrato(selectContrGestao.nome);
  }, [selectContrGestao]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        console.log("data", data)
        updateContrato(idContrato, data);
      } catch (err) {

      }
    },
    [],
  );

  return (
    <Container>
      <Title color='#333'>
        <span>
          Editar contrato de gestão
        </span>
      </Title>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Contrato de gestão">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Organização: </TextTitle>
              <SelectInput
                id='organization_id'
                name='organization_id'
                options={organizeDataSelect(orgaos.data, "id", "nome")}
                placeholder="Selecionar"
                required
              />
            </Grid>

            <Grid xs={12}>
              <TextTitle>Órgão gestor: </TextTitle>
              <SelectInput
                id='management_entity_id'
                name='management_entity_id'
                options={organizeDataSelect(orgaoGestor.data, "id", "nome")}
                placeholder="Selecionar"
                onChange={o => setOrgao(o.label)}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de assinatura: </TextTitle>
              <Input type="date" name='data_assinatura' />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Prazo de delegação do contrato: </TextTitle>
              <Input type="date" name='prazo_delegacao' />
            </Grid>
            <Grid xs={6} lg={2}>
              <TextTitle>Código: </TextTitle>
              <Input
                mask="__"
                replacement="allNumber"
                id="codigo"
                name='codigo'
                required
              />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Número do contrato: </TextTitle>
              <Input type="text" name='nome' onChange={c => setContrato(c.target.value)} required />
            </Grid>

            <Grid xs={12}>
              <TextTitle>Contrato de gestão:</TextTitle>
              <Input type="text" name='valor final' disabled value={`CG - ${orgao} - ${contrato}`} required />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Resolução do conselho">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Resolução do conselho:</TextTitle>
              <Textarea name="resolucao_conselho" defaultValue={selectContrGestao?.resolucao_conselho}></Textarea>
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/orcamentario/cadastros/contratosgestao">
          <button type="button"><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
        </Link>
        <button type="submit" onClick={() => formRef.current?.submitForm()}><FontAwesomeIcon icon={faSave} /> Salvar</button>
      </ContainerButton>
    </Container>
  )
}