import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton2, Input, SelectInput, TextTitle, Textarea, Title } from '../../../../styles/global';
import Panel from '../../../../components/Panel';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import useProcessoPlanejamento from '../../../../actions/cruds/Processos/useProcessoPlanejamento';
import useProcesso from '../../../../actions/cruds/Processos/useProcesso';
import { SaveWithProgress } from '../../../../components/SaveCircularIntegration';

const ProcessoAtualizarAcaoPlanejamento: React.FC = () => {
  const { planejamentoId } = useParams();
  const { planejamentoProcesso, getPlanejamentoProcesso, updatePlanejamentoProcesso } = useProcessoPlanejamento();
  const { processo, getProcesso, updateProcessoAcaoPlanejamento } = useProcesso();

  const [status, setStatus] = useState<String>('');
  const [atrasado, setAtrasado] = useState<boolean>(false);
  const [contratosGestaoUpdate, setContratosGestaoUpdate] = useState<Array<Number>>([]);
  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getPlanejamentoProcesso(planejamentoId);
  }, [])

  useEffect(() => {
    if (planejamentoProcesso?.id) {
      if (planejamentoProcesso?.data_termino > planejamentoProcesso?.previsao_termino) {
        setAtrasado(true);
      }
      formRef.current?.setFieldValue("data_inicio", planejamentoProcesso?.data_inicio);
      formRef.current?.setFieldValue("data_termino", planejamentoProcesso?.data_termino);
      formRef.current?.setFieldValue("status", { value: planejamentoProcesso?.status, label: optionsStatus.find((status) => status.value == planejamentoProcesso?.status)?.label });

      getProcesso(planejamentoProcesso?.law_process?.id);
    };
  }, [planejamentoProcesso]);

  useEffect(() => {
    if (planejamentoProcesso?.data_termino > planejamentoProcesso?.previsao_termino) {
      formRef.current?.setFieldValue("resultado", { value: planejamentoProcesso?.resultado, label: optionsResultado.find((status) => status.value == planejamentoProcesso?.resultado)?.label });

    };
  }, [atrasado]);

  useEffect(() => {
    if (processo?.management_contracts) {
      let contratosGestao: number[] = [];
      processo?.management_contracts.forEach((contrato) => contratosGestao.push(contrato.id));
      setContratosGestaoUpdate(contratosGestao);
    };

  }, [processo]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true);

        const status = formRef.current?.getFieldValue("status");
        if (status == 'concluido' && planejamentoProcesso?.law_process_category_action?.nome == 'Arquivamento do Processo') {
          updateProcessoAcaoPlanejamento(processo.id, { "status" : "concluido", "management_contract_ids": contratosGestaoUpdate });
        };

        console.log("data", data);
        await updatePlanejamentoProcesso(planejamentoId, data);
      } catch (err) {
        console.log(err);
      } finally {
        setSalvando(false);
      }
    }, [contratosGestaoUpdate],
  );

  const optionsStatus = [
    { value: "a_fazer", label: "A fazer" },
    { value: "em_andamento", label: "Em andamento" },
    { value: "concluido", label: "Concluído" },
  ];

  const optionsResultado = [
    { value: "eficaz", label: "Eficaz" },
    { value: "nao_eficaz", label: "Não Eficaz" },
  ];

  const handlerChangeDataConclusao = (dataConclusao: string) => {
    if (dataConclusao > planejamentoProcesso?.previsao_termino) {
      setAtrasado(true);
    } else {
      setAtrasado(false);
    };
  };

  return (
    <Container>
      <Title>
        <span>Atualizar Ação do Planejamento</span>
      </Title>

      <Form id="formAcaoPlanejamento" ref={formRef} onSubmit={handleSubmit} >
        <Panel titulo={planejamentoProcesso?.law_process_category_action?.nome}>
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Gestor do processo:</TextTitle>
              <Input type="text" name='gestor' value={planejamentoProcesso?.law_process?.gestor_processo?.nome} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Área responsável:</TextTitle>
              <Input type="text" name='area' value={planejamentoProcesso?.law_process?.administration?.nome} disabled />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Categoria do processo:</TextTitle>
              <Textarea name='tipoprocesso' value={planejamentoProcesso?.law_process?.law_process_category?.nome} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de Previsão de início:</TextTitle>
              <Input type="date" name='dataPrevisaoInicio' value={planejamentoProcesso?.previsao_inicio} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de Previsão de término:</TextTitle>
              <Input type="date" name='dataPreivsaoTermino' value={planejamentoProcesso?.previsao_termino} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de início:</TextTitle>
              <Input type="date" name='data_inicio' disabled={status == 'a_fazer' || planejamentoProcesso?.data_inicio} required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de conclusão:</TextTitle>
              <Input type="date" name='data_termino' disabled={status != 'concluido' || planejamentoProcesso?.data_termino} onChange={(e) => handlerChangeDataConclusao(e.target.value)} required />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Status:</TextTitle>
              <SelectInput
                name='status'
                options={optionsStatus}
                placeholder="Selecione..."
                onChange={(e) => setStatus(e?.value)}
                isDisabled={planejamentoProcesso?.status == 'concluido'}
                required />
            </Grid>
            {atrasado && (
              <>
                <Grid xs={12}>
                  <TextTitle>Justificativa:</TextTitle>
                  <Textarea name='justificativa' defaultValue={planejamentoProcesso?.justificativa} disabled={planejamentoProcesso?.justificativa} required />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Resultado:</TextTitle>
                  <SelectInput
                    name='resultado'
                    options={optionsResultado}
                    placeholder="Selecione..."
                    isDisabled={planejamentoProcesso?.resultado}
                    required
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Panel>

        <ContainerButton2>
          <Link to={`/processos/acaoplanejamento/${planejamentoProcesso?.law_process?.id}`}>
            <button className="button_return">
              <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
            </button>
          </Link>
          <SaveWithProgress form={"formAcaoPlanejamento"} disabled={salvando} ></SaveWithProgress>
        </ContainerButton2>
      </Form>
    </Container>
  );
};

export default ProcessoAtualizarAcaoPlanejamento;