import { useEffect, useRef, useState } from "react";

import { faChevronLeft, faDownload, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Header from "../../../components/Header";
import Panel from "../../../components/Panel";
import { CSidebar } from "../../../components/Sidebar";

import TableSimple from "../../../components/SimpleTable";

import {
  ButtonDownloadArch,
  ContainerButton,
  ContainerButton2,
  Form,
  FormContainer,
  Input,
  TextTitle,
  Title,
} from "../../../styles/global";
import Grid from "@mui/material/Unstable_Grid2";


import { Link, useParams } from "react-router-dom";
import useInstrumento from "../../../actions/cruds/InstrumentoContratual/useInstrumento";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import { formatarData, formatarReais } from "../../../utils/formatDados";
import useFornecedor from "../../../actions/cruds/useFornecedor";
import { FormHandles } from "@unform/core";
import useProcesso from "../../../actions/cruds/Processos/useProcesso";


const InstrumentoView: React.FC = () => {
  const { instrumentId } = useParams();
  const { instrumento, getInstrumento } = useInstrumento();
  const { fornecedor, getFornecedor } = useFornecedor();
  const { processo, getProcesso } = useProcesso();

  const [bankAccount, setBankAccount] = useState([]);

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    getInstrumento(instrumentId);
  }, []);

  useEffect(() => {
    // console.log("dados isntrumento:", instrumento);
    if (instrumento?.supplier?.id) {
      getFornecedor(instrumento?.supplier?.id);
    }

    if (instrumento?.law_process?.id) {
      getProcesso(instrumento.law_process.id);
    }
    
  }, [instrumento]);

  useEffect(() => {
    formRef.current?.setFieldValue(
      "supplier_name",
      fornecedor.nome_razao_social
    );
    if (
      fornecedor?.consorcio &&
      Array.isArray(fornecedor?.consorcio_ids) &&
      fornecedor?.consorcio_ids?.length
    ) {
      setBankAccount([...fornecedor.consorcio_parceiro]);
    } else if (fornecedor?.nome_razao_social) {
      setBankAccount([
        {
          nome_razao_social: fornecedor.nome_razao_social,
          banco_tipo_conta: fornecedor.banco_tipo_conta,
          banco_nome: fornecedor.banco_nome,
          banco_agencia: fornecedor.banco_agencia,
          banco_conta: fornecedor.banco_conta,
        },
      ]);
    }
  }, [fornecedor]);

  useEffect(() => {
    console.log('a: ', processo.assunto_processo);
    // formRef.current?.setFieldValue("law_process_desc", "aaa");
    formRef.current?.setFieldValue('law_process_desc', 'bbb');
  }, [processo]);

  //Função necessária para transformar a descrição do contrato, recebida do db como string, para um array de objeto - necessário para ser utilizado no componente
  const createArrayDescricaoDoc = (inputString) => {
    if (typeof inputString === "string") {
      const obj = {
        descricao: inputString,
      };
      return [obj];
    } else {
      return [];
    }
  };

  const organizeDataHistoryActions = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          id: d.id,
          created_at: formatarData(d.created_at),
          action: d.action,
          desc: d.description ? d.description : "Não especificada",
        }));
        return data;
      }
    }

    return [];
  };

  const dataTitleAdditives: GridColDef[] = [
    { field: "nome", headerName: "Nome do aditivo", flex: 1 },
    { field: "data_assinatura", headerName: "Data da assinatura", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`../aditivo/visualizar/${params.row.id}`}
              target="_blank"
            >
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-eye" />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const dataTitleApostilles: GridColDef[] = [
    { field: "nome", headerName: "Nome do aditivo", flex: 1 },
    { field: "data_assinatura", headerName: "Data da assinatura", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`../apostilamento/visualizar/${params.row.id}`}
              target="_blank"
            >
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-eye" />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const dataTitleRubrica: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    { 
      field: "valor",
      headerName: "Valor (R$)",
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
            return "Defina um valor!";
        }
        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      }
    },
    {
      field: "percentual",
      headerName: "Percentual (%)",
      flex: 1,
      valueGetter: (params) => {
        return ((params.row.valor / instrumento?.valor_total) * 100).toFixed(2);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    }
  ];

  const dataActionsHistory: GridColDef[] = [
    { field: "created_at", headerName: "Data e hora da ação", flex: 1 },
    { field: "nome_user", headerName: "Responsável", flex: 1 },
    { field: "action", headerName: "Ação", flex: 1 },
    { field: "desc", headerName: "Descrição/Justificativa", flex: 1 },
  ];

  const dataTitleAccounts: GridColDef[] = [
    { field: "nome_razao_social", headerName: "Fornecedor", flex: 1 },
    { field: "banco_tipo_conta", headerName: "Tipo de conta", flex: 1 },
    { field: "banco_nome", headerName: "Banco", flex: 1 },
    { field: "banco_agencia", headerName: "Agência", flex: 1 },
    { field: "banco_conta", headerName: "Conta", flex: 1 },
  ];

  const organizeData = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.project_line.id,
          contrato: d.project_line.management_contract.nome_completo,
          comite: d.project_line.comittee.nome,
          codigo_contabil: `${d.project_line.expense_type.codigo}.${d.project_line.organization.codigo.padStart(2, '0')}.${d.project_line.management_contract.codigo.padStart(2, '0')}.${d.project_line.comittee.codigo.padStart(2, '0')}.${d.project_line.unit.codigo.padStart(2, '0')}.${d.project_line.pap_finality.codigo.padStart(2, '0')}.${d.project_line.pap_program.codigo.padStart(2, '0')}.${d.project_line.pap_action.codigo.padStart(2, '0')}.${d.project_line.poa_sub_action.codigo.padStart(2, '0')}.${processo?.project?.id.toString().padStart(2, '0')}.${d.project_line.description.codigo.padStart(3, '0')}`,
          valor: d.valor
        }));
        return data;
      }
    }
    return [];
  };

  return (

    <Container>
      <Form>
        <Title color="#333">
          <span>Visualizar instrumento contratual</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Número do processo: </TextTitle>
              <Input
                id="law_process_id"
                name="law_process_id"
                placeholder={instrumento?.law_process ? `${instrumento?.law_process?.codigo} - ( SIGAD - ${instrumento?.law_process?.numero})` : ""}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Descrição do processo:</TextTitle>
              <Input
                id="law_process_desc"
                name="law_process_desc"
                type="text"
                disabled
                placeholder={processo.assunto_processo}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Tipo de instrumento: <span>*</span>
              </TextTitle>
              <Input
                id="tipoInstrumento"
                name="teste"
                placeholder={instrumento.tipo_instrumento}
                disabled
              />
            </Grid>
            
            <Grid xs={6}>
              {instrumento.tipo_descricao &&
                <>
                  <TextTitle>
                    Descrição: <span>*</span>
                  </TextTitle>
                  <Input
                    name="descricao"
                    type="text"
                    placeholder={instrumento.tipo_descricao}
                    disabled
                  />
                </>
              }
            </Grid>
            
            <Grid xs={6}>
              <TextTitle>
                CPF/CNPJ do fornecedor: <span>*</span>
              </TextTitle>
              <Input
                id="law_process_id"
                name="law_process_id"
                value={instrumento?.supplier?.cpf_cnpj}
                onChange={(e) => e ? getFornecedor(e.value) : formRef.current.setFieldValue("supplier_name", "")}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Nome do Fonecedor: </TextTitle>
              <Input
                name="nomefornecedor"
                type="text"
                placeholder={instrumento.supplier.nome_razao_social}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Número do instrumento: <span>*</span>
              </TextTitle>
              <Input
                name="numInstrumento"
                type="text"
                placeholder={instrumento.numero}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Instrumento Convocatório: </TextTitle>
              <Input
                name="instConvocatorio"
                type="text"
                placeholder={instrumento.instrumento_convocatorio}
                disabled
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>
                Objeto do instrumento: <span>*</span>
              </TextTitle>
              <Input
                name="objeto_instrumento"
                type="text"
                placeholder={instrumento.objeto}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Data de assinatura do contrato: <span>*</span>
              </TextTitle>
              <Input
                name="dataAssinaturaContrato"
                type="text"
                placeholder={formatarData(instrumento.data_assinatura_contrato, false)}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data do início da vigência: </TextTitle>
              <Input
                name="dataIniVigencia"
                type="text"
                placeholder={formatarData(instrumento.data_inicio_vigencia, false)}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de término da vigência: </TextTitle>
              <Input
                name="dataTermVigencia"
                type="text"
                placeholder={formatarData(instrumento.data_termino_vigencia, false)}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Valor total do instrumento: <span>*</span>
              </TextTitle>
              <Input
                name="totalInstrumento"
                type="text"
                placeholder={formatarReais(instrumento?.valor_total)}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Gestor do instrumento contratual: <span>*</span>
              </TextTitle>
              <Input
                id="gesInstrContratual"
                name="teste"
                placeholder={instrumento.gestor?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Substitudo do gestor do instrumento contratual:
                <span>*</span>
              </TextTitle>
              <Input
                id="subgesInstrContratual"
                name="teste"
                placeholder={instrumento.gestor_substituto?.nome}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Rubrica orçamentária">
          <Grid container spacing={6}>
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleRubrica}
                  rowsData={organizeData(instrumento?.contractual_instrument_budgets)}
                />
              </Grid>
              <Grid xs={6} style={{marginTop: "20px"}}>
                <TextTitle>Total: {formatarReais(organizeData(instrumento.contractual_instrument_budgets)?.reduce(function (acc, obj) { return acc + parseFloat(obj?.valor); }, 0))}</TextTitle>
              </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados Bancários">
          <Grid container spacing={6}>
            {instrumento?.tipo_instrumento == 'Convênios' ? (
              <>
                <Grid xs={12}>
                  <TextTitle>Conta bancária do Fornecedor (convênio): </TextTitle>
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Tipo de conta:</TextTitle>
                  <Input
                    id="tipoConta"
                    name="teste"
                    placeholder={instrumento.banco_tipo_conta}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Banco:</TextTitle>
                  <Input
                    id="banco"
                    name="teste"
                    placeholder={instrumento.banco_nome}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Agência:</TextTitle>
                  <Input
                    name="agencia"
                    type="text"
                    placeholder={instrumento.banco_agencia}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Conta:</TextTitle>
                  <Input
                    name="conta"
                    type="text"
                    placeholder={instrumento.banco_conta}
                    disabled
                  />
                </Grid>
              </>
            ) : (
              <Grid xs={12}>
                {bankAccount.length > 0 && (
                  <SecondaryTable
                    columsData={dataTitleAccounts}
                    rowsData={bankAccount}
                    getRowId={(row) => row.nome_razao_social}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Contrato">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Descrição: </TextTitle>
              <Input
                type="text"
                name="contratoDescricao"
                value={instrumento.contrato_descricao}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Contrato:</TextTitle>
              <Link
                to={{
                  pathname: `${instrumento.url_contrato_arquivo}/${instrumento.contrato_arquivo}`,
                }}
                target="_blank"
              >
                <ButtonDownloadArch name='selecionararquivo' type="button">
                  <FontAwesomeIcon icon={faDownload} size="1x" /> Download...
                </ButtonDownloadArch>
              </Link>
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Extrato da publicação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data da Publicação:</TextTitle>
              <Input
                type="date"
                name="extPublicacao"
                value={instrumento.publicacao_data}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Extrato:</TextTitle>
              <Link
                to={{
                  pathname: `${instrumento.url_publicacao_arquivo}/${instrumento.publicacao_arquivo}`,
                }}
                target="_blank"
              >
                <ButtonDownloadArch name='selecionararquivo' type="button">
                  <FontAwesomeIcon icon={faDownload} size="1x" /> Download...
                </ButtonDownloadArch>
              </Link>
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Descrição: <span>*</span>
              </TextTitle>
              <Input
                type="text"
                name="extPublicacao"
                value={instrumento.publicacao_descricao}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Valor do Contrato">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Valor total do contrato:</TextTitle>
              <Input
                name="valortotal"
                type="text"
                placeholder={formatarReais(instrumento.valor_total)}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Os pagamentos serão rateados? <span>*</span>
              </TextTitle>
              <Input
                name="rateado"
                type="text"
                placeholder={
                  instrumento.pagamentos_rateados ? "Sim" : "Não"
                }
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Aditivo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {instrumento.apostilles?.length > 0 && (
                <SecondaryTable
                  columsData={dataTitleAdditives}
                  rowsData={instrumento.additives}
                />
              )}
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Apostilamento">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {instrumento.apostilles?.length > 0 && (
                <SecondaryTable
                  columsData={dataTitleApostilles}
                  rowsData={instrumento.apostilles}
                />
              )}
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Histórico de Ações">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <SecondaryTable
                columsData={dataActionsHistory}
                rowsData={organizeDataHistoryActions(instrumento.audits)}
              />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/instrumentos">
          <button className="button_return"          >
            <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
          </button>
        </Link>
        <Link to={`/instrumentos/fichaPDF/${instrumentId}`} target="_blank">
          <button className="button_save">
            <FontAwesomeIcon icon={faDownload} size="1x" /> Download PDF
          </button>
        </Link>
      </ContainerButton2>
    </Container >
  );
};

export default InstrumentoView;