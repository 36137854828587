import React from 'react';
import Panel from '../../../components/Panel';
import { CsvBtn } from './styles';
import { Container, Title } from '../../../styles/global';
import useProcesso from '../../../actions/cruds/Processos/useProcesso';

const ProcessoRelatorio: React.FC = () => {

  const { getProcessoXls } = useProcesso();

  const handleSimpleXls = () => {
    getProcessoXls();
  }


  return (
    <Container>
      <Title>
        <span>Relatórios de Processos</span>
      </Title>

      <Panel titulo='Relatório simples'>
        <p>Relatório rápido com todas as colunas:</p>
        <div style={{ width: "100%" }}>
          {/* <a href={instituicoesXls}> */}
          <CsvBtn className='csv' type="button" onClick={handleSimpleXls}>Excel</CsvBtn>
          {/* </a> */}
        </div>
      </Panel>

      {/* <Panel titulo='Relatório dinâmico'>
          <p>Selecione as colunas que deseja mostrar no relatório:</p>

          <ContainerBtns>
            <button type="button">Nome</button>
            <button type="button">Email</button>
            <button type="button">Endereço</button>
            <button type="button">Setor</button>
            <button type="button">Telefone</button>
          </ContainerBtns>

          <div style={{width:"100%", textAlign: "right"}}>
            <button className='filtrar' type="button">Filtrar</button>
          </div>

          <hr style={{margin: '30px 0'}} />

          <div style={{width:"100%"}}> */}
      {/* <button className='csv' type="button">Excel</button> */}
      {/* </div>

          <Table2 headers={[]} data={data} />
          
        </Panel> */}
    </Container>
  );
};

export default ProcessoRelatorio;