import { useState } from "react";
import bankApi from "../../services/bankApi";

const useBancos = () => {

    const [bancos, setBancos] = useState([]);
    const [error, setError] = useState(null);

    async function getBancos() {
        try {
            const {data} = await bankApi.get('banks/v1');
            setBancos(data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }


    return {bancos, getBancos, error};
}

export default useBancos;