import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useProcessoPlanejamento = () => {

    const history = useHistory();
    const [planejamentosProcessos, setPlanejamentosProcessos] = useState([]);
    const [planejamentoProcesso, setPlanejamentoProcesso] = useState({});
    const [error, setError] = useState(null);

    async function getPlanejamentosProcessos(params?: object) {
        try {
            const response = await api.get(`law_process_plannings${stringfyParams(params)}`);
            setPlanejamentosProcessos(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getPlanejamentoProcesso(id: string) {
        try {
            const response = await api.get(`law_process_plannings/${id}`);
            // console.log(response);
            setPlanejamentoProcesso(response.data);
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createPlanejamentoProcesso(data: any) {
        try {
            const response = await api.post('law_process_plannings', data);

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro ao cadastrar o Planejamento");
            setError(e);
            // console.log(error);
        }
    }

    async function updatePlanejamentoProcesso(id: number, data: any) {
        try {
          const response = await api.put(`law_process_plannings/${(id)}`, data);
          alert("Planejamento do Processo atualizado com sucesso!");
          history.goBack();
        } catch (e) {
          setError(e);
          // console.log(error);
        }
      }


    return {planejamentosProcessos, getPlanejamentosProcessos, planejamentoProcesso, getPlanejamentoProcesso, createPlanejamentoProcesso, updatePlanejamentoProcesso, error};
}

export default useProcessoPlanejamento;