import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const usePapFinalidade = () => {
  const history = useHistory();
  const { addToast } = useToast();
  
  const [papFinalidades, setPapFinalidades] = useState([]);
  const [papFinalidade, setPapFinalidade] = useState({});
  const [newPapFinalidades, setNewPapFinalidades] = useState({});

  async function getPapFinalidades(params) {
    try {
      const response = await api.get(`pap_finalities${stringfyParams(params)}`);
      setPapFinalidades(response.data);
    } catch (e) {
      alert("Erro ao retornar Finalidades PAP");
    }
  }

  async function getPapFinalidade(id) {
    try {
      const response = await api.get(`pap_finalities/${id}`);
      setPapFinalidade(response.data);
    } catch (e) {
      alert("Erro ao retornar Finalidade PAP");
    }
  }

  async function createPapFinalidades(data) {
    try {
        const response = await api.post('pap_finalities', data);
        // alert("Finalidade PAP criada!");
        setNewPapFinalidades( response.data );
    } catch (e) {
        // alert("Erro ao cadastrar Finalidade PAP");
    }
  }

  async function updatePapFinalidade(id: number, data: object) {
    try {
      const response = await api.put(`pap_finalities/${id}`, data);
      
      addToast({
        type: 'success',
        title: 'Finalidade alterada',
        description: 'Alteração da Finalidade foi realizada com sucesso!',
      });

      return response;
    } catch (e) {}
  }

  return {
    setPapFinalidade,
    papFinalidade,
    papFinalidades,
    getPapFinalidade,
    getPapFinalidades,
    createPapFinalidades,
    newPapFinalidades,
    updatePapFinalidade
  };
};

export default usePapFinalidade;
