import { useState } from "react";
import api from "../../services/api";

const useContatoRepresentanteInstituicao = () => {

    const [error, setError] = useState(null);

    async function deleteContatoRepresentanteInstituicao(id: string) {
        try {
            const response = await api.delete(`representative_contacts/${id}`);
            alert("Sucesso: contato do representante excluido!");

            return true;
        } catch (e) {
            setError(e);
            alert("Error: tente novamente!");
            // console.log(error);
        }
    }

    return {deleteContatoRepresentanteInstituicao, error};
}

export default useContatoRepresentanteInstituicao;