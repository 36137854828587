import React, { useCallback, useRef } from 'react';
import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton, Input, TextTitle, Title } from '../../../../../styles/global';
import Panel from '../../../../../components/Panel';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faX } from '@fortawesome/free-solid-svg-icons';
import useGerencia from '../../../../../actions/cruds/useGerencia';
// import { Container } from './styles';


const GerenciaRhCreate: React.FC = () => {

    const {createGerencia} = useGerencia();

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => { 
        try {
            console.log(data);
            
            createGerencia(data);

            // addToast({
            //   type: 'success',
            //   title: 'Login efetuado',
            //   description: 'Seu login foi feito com sucesso!',
            // });

            // history.push('/dashboard');
        } catch (err) {    
            // addToast({
            //   type: 'error',
            //   title: 'Erro na autenticação',
            //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
            // });
        }
        },
        [],
    );

    return (
        <Container>
            <Title>
                <span>Cadastrar Gerência</span>
            </Title>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Gerência'>
                    <Grid container spacing={6}>
                        <Grid xs={12}>
                            
                            <TextTitle>Nome:</TextTitle>
                            <Input type="text" name='nome' />
                            
                        </Grid>
                    </Grid>
                </Panel>
                <ContainerButton>
                    <Link to="/rh/cadastros/gerencia">
                        <button><FontAwesomeIcon icon={faX} size="1x"/> Cancelar</button>
                    </Link>
                    <button type="submit" form="form1"><FontAwesomeIcon icon={faSave}/> Salvar</button>
                </ContainerButton>
            </Form>
        </Container>
    );
};

export default GerenciaRhCreate;