import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BoxSave, ButtonWithProgress, ProgressCircle } from "./styles";

interface SaveWithProgressProps {
    disabled: boolean;
    form?: string;
}

export function SaveWithProgress(props: SaveWithProgressProps) {

    return (
        <BoxSave>
            {props.disabled && (
                <ProgressCircle size={28}/>
            )}
            <ButtonWithProgress type="submit" form={props.form} disabled={props.disabled}>
                <FontAwesomeIcon icon={faSave} /> Salvar
            </ButtonWithProgress>
        </BoxSave>
    );
}