import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { IDropdownCancelar } from "../components/DropdownMainTable/Dropdowns";
import api from "../services/api";

export const cancelarSituacao = async (props: IDropdownCancelar) => {
    const { id, situacao, linkTo } = props;
    let { activateSituacao } = props;

    if (linkTo == 'expense_authorizations') {
        let expenseAuthorizationResponse: any;

        await api
            .get(`${linkTo}/${id}`)
            .then((response) => {
                expenseAuthorizationResponse = response.data;
            })
            .catch((error) => console.log(error));

            if(expenseAuthorizationResponse.iss ){
                if(expenseAuthorizationResponse.nome_arquivo){
                    console.log("arquivo");
                    activateSituacao = 'concluido'
                } else{
                    activateSituacao = 'aguardando_confirmacao_pagamento'
                    console.log("iss")
                }
            } else{
                activateSituacao = 'aguardando_realizacao_pagamento';
            }

    };



    confirmAlert({
        message: `Deseja realmente ${situacao == 'Cancelado' ? "reativar" : "cancelar"} o item selecionado?`,
        buttons: [
            {
                label: situacao == 'Cancelado' ? "Reativar" : "Cancelar",
                onClick: () => handleDesativeStatus({ id, situacao, linkTo, activateSituacao }),
                style: { backgroundColor: "#366EFA" },
            },
            {
                label: "Fechar",
                style: { backgroundColor: "#366EFA" },
            },
        ],
    });
};

const handleDesativeStatus = (props: IDropdownCancelar) => {
    const { id, situacao, linkTo } = props;
    let { activateSituacao } = props;
    let status = true;

    if (situacao != "Cancelado") {
        activateSituacao = 'cancelado';
        status = false;
    };
    api
        .put(`${linkTo}/${id}`, { situacao: activateSituacao, ativo: status })
        .then(() => {
            window.alert(`Item ${situacao == 'Cancelado' ? "Reativado" : "Cancelado"} com sucesso!`);
            window.location.reload();
        })
        .catch(() => alert(`Erro ao cancelar o item: ${id}`));
};
