import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

const useDocumentosInstituicao = () => {

    const history = useHistory();
    const [documentosPorInstituicao, setDocumentosPorInstituicao] = useState([]);
    const [error, setError] = useState(null);

    async function getDocumentosPorInstituicao(id) {
        try {
            const response = await api.get(`institution_documents/?institution_id=${id}`);
            setDocumentosPorInstituicao(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createDocumentosInstituicao(data) {
        try {
            const response = await api.post('institution_documents', data,{headers: {
                "Content-Type": "multipart/form-data",
              },});
            alert("Documento adicionado!");
            history.go(0);

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro, tente novamente!");
            setError(e);
            // console.log(error);
        }
    }

    async function deleteDocumentosInstituicao(data) {
        const {id, institution_id} = data;
        try {
            const response = await api.delete(`institution_documents/${id}`);

            getDocumentosPorInstituicao(institution_id);

            alert("Sucesso! Documento removido.");
            // return response;
        } catch (e) {
            setError(e);
            alert("Algo deu errado ao remover documento.");
            // console.log(error);
        }
    }


    return {documentosPorInstituicao, getDocumentosPorInstituicao, createDocumentosInstituicao, deleteDocumentosInstituicao, error};
}

export default useDocumentosInstituicao;