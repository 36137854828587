import { useEffect, useState } from "react";

import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "../../../../components/Panel";

import * as React from "react";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import {
  ContainerButton,
  ContainerButton2,
  Form,
  Input,
  TextTitle,
  Title,
} from "../../../../styles/global";

import Radio from "../../../../components/InputRadio";
import SecondaryTable from "../../../../components/SecondaryTable";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import useUsuario from "../../../../actions/cruds/useUsuario";
import {
  ContainerTab,
  ContainerView,
  ImageView,
  InputView,
  TabsView,
} from "./style";
import { Link, useParams } from "react-router-dom";
import { Button, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { formatarReais, pontuarCpf } from "../../../../utils/formatDados";
import useProcesso from "../../../../actions/cruds/Processos/useProcesso";

export function ViewEmpregado() {
  const { empregadoId } = useParams();

  const { usuario, getUser } = useUsuario();
  const { processo, getProcesso } = useProcesso();

  const [tipoFuncionario, setTipoFuncionario] = useState<String>("");
  const [valueGenero, setValueGenero] = useState<String>("");
  const [valueEstadoCivil, setValueEstadoCivil] = useState<String>("");
  const [valueUF, setValueUF] = useState<String>("");
  const [valueTipoConta, setValueTipoConta] = useState<String>("");
  const [valueTipoTrabalho, setValueTipoTrabalho] = useState<String>("");
  const [valueCargaHoraria, setValueCargaHoraria] = useState<String>("");
  const [valueCategoriaCnh, setValueCategoriaCnh] = useState<String>("");
  const [tipoCarteiraProfissional, setTipoCarteiraProfissional] = useState<String>('fisica');

  useEffect(() => {
    getUser(empregadoId);
  }, []);

  useEffect(() => {
    if (usuario.id) {
      if (usuario.employee_type) {
        setTipoFuncionario(usuario.employee_type.key);
      }

      const generoUsuario = optionsGenero.filter(
        (genero) => genero.value === usuario.genero
      );
      setValueGenero(generoUsuario.length ? generoUsuario[0].label : "");

      const categoriaCnh = optionsCategoriaCnh.filter((categoria) => categoria.value === usuario.cnh_categoria);
      setValueCategoriaCnh(categoriaCnh.length ? categoriaCnh[0].label : "");

      const estadoCivilUsuario = optionsEstadoCivil.filter(
        (estado) => estado.value === usuario.estado_civil
      );
      setValueEstadoCivil(
        estadoCivilUsuario.length ? estadoCivilUsuario[0].label : ""
      );

      const valueUFUsuario = optionsUF.filter((uf) => uf.value === usuario.uf);
      setValueUF(valueUFUsuario.length ? valueUFUsuario[0].label : "");

      const tipoContaUsuario = optionsTipoConta.filter(
        (tipo) => tipo.value === usuario.banco_tipo_conta
      );
      setValueTipoConta(
        tipoContaUsuario.length ? tipoContaUsuario[0].label : ""
      );

      const tipoTrabalhoUsuario = optionsTipoTrabalho.filter(
        (tipo) => tipo.value === usuario.tipo_trabalho
      );
      setValueTipoTrabalho(
        tipoTrabalhoUsuario.length ? tipoTrabalhoUsuario[0].label : ""
      );

      const cargaHorariaUsuario = optionsCargaHoraria.filter(
        (carga) => carga.value === usuario.carga_horaria
      );
      setValueCargaHoraria(
        cargaHorariaUsuario.length ? cargaHorariaUsuario[0].label : ""
      );
    }

    if (usuario.carteira_trabalho_tipo != null) {
      setTipoCarteiraProfissional(usuario.carteira_trabalho_tipo);
    }

    if (usuario.law_process) {
      // formRef.current?.setFieldValue('law_process_id', {value: usuario?.law_process?.id, label: `${usuario.law_process?.codigo} - (SIGAD - ${usuario.law_process?.numero || 'sem nº'})`});
    }

    if (usuario?.law_process?.id) {
      getProcesso(usuario.law_process.id);
    }
    
  }, [usuario]);

  const organizeDataContact = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          id: d.id,
          tipo: d.tipo,
          contato: d.contato,
        }));
        return data;
      }
    }
    return [];
  };

  const organizeDataSalario = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.project_line.id,
          contrato: d.project_line.management_contract.nome_completo,
          comite: d.project_line.comittee.nome,
          codigo_contabil: `${d.project_line.expense_type.codigo}.${d.project_line.organization.codigo.padStart(2, '0')}.${d.project_line.management_contract.codigo.padStart(2, '0')}.${d.project_line.comittee.codigo.padStart(2, '0')}.${d.project_line.unit.codigo.padStart(2, '0')}.${d.project_line.pap_finality.codigo.padStart(2, '0')}.${d.project_line.pap_program.codigo.padStart(2, '0')}.${d.project_line.pap_action.codigo.padStart(2, '0')}.${d.project_line.poa_sub_action.codigo.padStart(2, '0')}.${usuario?.law_process?.project?.id.toString().padStart(2, '0')}.${d.project_line.description.codigo.padStart(3, '0')}`,
          valor: d.valor
        }));
        return data;
      }
    }
    return [];
  };

  const optionsUF = [
    { value: "AC", label: "AC - Acre" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "BA", label: "BA - Bahia" },
    { value: "CE", label: "CE - Ceará" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "GO", label: "GO - Goiás" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "PA", label: "PA - Pará" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PI", label: "PI - Piauí" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RR", label: "RR - Roraima" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "EX", label: "EX - Estrangeiro" },
  ];

  const optionsGenero = [
    { value: "masculino", label: "Masculino" },
    { value: "feminino", label: "Feminino" },
  ];

  const optionsEstadoCivil = [
    { value: "solteiro", label: "Solteiro(a)" },
    { value: "casado", label: "Casado(a)" },
    { value: "separado", label: "Separado(a)" },
    { value: "divorciado", label: "Divorciado(a)" },
    { value: "viuvo", label: "Viúvo(a)" },
  ];

  const optionsTipoConta = [
    { value: "corrente", label: "Conta Corrente" },
    { value: "poupanca", label: "Conta Poupança" },
  ];

  const optionsBoolean = [
    { value: "true", label: "Sim" },
    { value: "false", label: "Não" },
  ];

  const optionsCategoriaCnh = [
    { value: "a", label: "A" },
    { value: "b", label: "B" },
    { value: "c", label: "C" },
    { value: "d", label: "D" },
    { value: "e", label: "E" },
  ];

  const optionsCarteiraProfissional = [
    { value: "fisica", label: " Física" },
    { value: "digital", label: " Digital" },
  ];

  const optionsAreaAtuacao = [
    { value: "meio", label: "Área meio" },
    { value: "fim", label: "Área fim" },
  ];

  const optionsTipoTrabalho = [
    { value: "presencial", label: "Presencial" },
    { value: "hibrido", label: "Híbrido" },
    { value: "remoto", label: "Remoto" },
  ];

  const optionsCargaHoraria = [
    { value: "40", label: "40 horas semanais (Segunda a sexta)" },
  ];

  const dataTitleContacts: GridColDef[] = [
    { field: "tipo", headerName: "Tipo", flex: 1 },
    { field: "contato", headerName: "Contato", flex: 1 },
  ];

  const dataTitleRubrica: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    { 
      field: "valor",
      headerName: "Valor (R$)",
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
            return "Defina um valor!";
        }
        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      }
    },
    {
      field: "percentual",
      headerName: "Percentual (%)",
      flex: 1,
      valueGetter: (params) => {
        return ((params.row.valor / usuario?.salario) * 100).toFixed(2);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    }
  ];

  const dataTitleContracts: GridColDef[] = [
    { field: "data_assinatura", headerName: "Data de assinatura", flex: 1 },
    { field: "data_vencimento", headerName: "Data de vencimento", flex: 1 },
  ];

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  console.log(usuario)

  return (
    // <ContainerView>
    <Container>
      <Title color="#333">
        <span>Visualizar empregado</span>
      </Title>
      <Form id="formEmpregados">
        <ContainerView>
          <Grid container spacing={6}>
            <Grid xs={12} lg={4}>
              <ImageView src={usuario.avatar} id="avatar" name="avatar" />
            </Grid>
            <Grid container xs={12} lg={8}>
              <Grid container>
                <Grid xs={12} lg={6}>
                  <TextTitle>Nome: </TextTitle>
                  <InputView
                    type="text"
                    name="nome"
                    placeholder={usuario.nome}
                    disabled
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Cargo: </TextTitle>
                  <InputView
                    type="text"
                    name="cpf"
                    placeholder={
                      usuario.cargo_atual ? usuario.cargo_atual.nome : ""
                    }
                    disabled
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Local de trabalho: </TextTitle>
                  <InputView
                    type="text"
                    name="cpf"
                    placeholder={
                      usuario.local_trabalho
                        ? usuario.local_trabalho.nome
                        : ""
                    }
                    disabled
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Data de contratação: </TextTitle>
                  <InputView
                    type="text"
                    name="nome"
                    placeholder={usuario.data_admissao}
                    disabled
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Gerência: </TextTitle>
                  <InputView
                    type="text"
                    name="cpf"
                    placeholder={
                      usuario.gerencia ? usuario.gerencia.nome : ""
                    }
                    disabled
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Tipo do funcionário: </TextTitle>
                  <InputView
                    type="text"
                    name="cpf"
                    placeholder={
                      usuario.employee_type ? usuario.employee_type.nome : ""
                    }
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContainerView>

        <Box sx={{ width: "100%", p: 0 }}>
          <ContainerTab>
            <Box sx={{ p: 0 }}>
              <TabsView
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Dados pessoais" {...a11yProps(0)} />
                <Tab label="Dados institucionais" {...a11yProps(1)} />
                <Tab label="Histórico" {...a11yProps(2)} />
              </TabsView>
            </Box>
          </ContainerTab>
          <CustomTabPanel value={value} index={0}>

            <Panel titulo="Identificação">
              <Grid container spacing={6}>
                <Grid xs={12}>
                  <TextTitle>Nome: </TextTitle>
                  <Input
                    type="text"
                    name="nome"
                    value={usuario.nome}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={4}>
                  <TextTitle>Gênero: </TextTitle>
                  <Input
                    type="text"
                    name="genero"
                    placeholder={valueGenero}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={4}>
                  <TextTitle>
                    Data de nascimento: <span>*</span>
                  </TextTitle>
                  <Input
                    type="text"
                    name="data_nascimento"
                    placeholder={usuario.data_nascimento}
                    disabled
                  />
                </Grid>
                {tipoFuncionario === "terceirizado" ? (
                  <Grid xs={6} lg={4}>
                    <TextTitle>Formação: </TextTitle>
                    <Input
                      type="text"
                      name="formacao"
                      placeholder={usuario.formacao}
                      disabled
                    />
                  </Grid>
                ) : (
                  <Grid xs={6} lg={4}>
                    <TextTitle>Estado civil: </TextTitle>
                    <Input
                      type="text"
                      name="estado_civil"
                      placeholder={valueEstadoCivil}
                      disabled
                    />
                  </Grid>
                )}
                <Grid xs={6} lg={4}>
                  <TextTitle>CPF: </TextTitle>
                  <Input
                    type="text"
                    name="cpf"
                    placeholder={usuario.cpf ? pontuarCpf(usuario.cpf) : ''}
                    mask="cpf"
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={4}>
                  <TextTitle>
                    RG: <span>*</span>
                  </TextTitle>
                  <Input
                    type="text"
                    name="rg"
                    placeholder={usuario.rg}
                    mask="rg"
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={4}>
                  <TextTitle>
                    Órgão Expedidor: <span>*</span>
                  </TextTitle>
                  <Input
                    type="text"
                    name="orgao_expedidor"
                    placeholder={usuario.orgao_expedidor}
                    disabled
                  />
                </Grid>


                {tipoFuncionario == "empregado" ? (
                  <>
                    <Grid xs={6} lg={4}>
                      <TextTitle>
                        CNH: <span>*</span>
                      </TextTitle>
                      <Input
                        type="text"
                        name="cnh"
                        placeholder={usuario.cnh}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={4}>
                      <TextTitle>
                        Categoria: <span>*</span>
                      </TextTitle>
                      <Input
                        type="text"
                        name="cnh_categoria"
                        placeholder={valueCategoriaCnh}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={4}>
                      <TextTitle>
                        Data de vencimento: <span>*</span>
                      </TextTitle>
                      <Input
                        type="text"
                        name="cnh_vencimento"
                        placeholder={usuario.cnh_vencimento}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={4}>
                      <TextTitle>Número do cartão do SUS: </TextTitle>
                      <Input
                        type="text"
                        name="cartao_sus"
                        placeholder={usuario.cartao_sus}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={4}>
                      <TextTitle>Formação: </TextTitle>
                      <Input
                        type="text"
                        name="formacao"
                        placeholder={usuario.formacao}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={4}>
                      <TextTitle>Grau de Instrução: </TextTitle>
                      <Input
                        type="text"
                        name="grau_instrucao"
                        placeholder={usuario.grau_instrucao}
                        disabled
                      />
                    </Grid>
                  </>
                ) : tipoFuncionario === "terceirizado" ? null : (
                  <>
                    <Grid xs={6} lg={4}>
                      <TextTitle>
                        Está vinculado a um convênio?{" "}
                      </TextTitle>
                      <Radio
                        name="vinculado_convenio"
                        options={optionsBoolean}
                        checkedValue={usuario.vinculado_convenio}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={4}>
                      <TextTitle>Convênio: </TextTitle>
                      <Input
                        type="text"
                        name="convenio"
                        placeholder={usuario.convenio}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={4}>
                      <TextTitle>Período atual: </TextTitle>
                      <Input
                        type="text"
                        name="periodo_atual"
                        placeholder={usuario.periodo_atual}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={4}>
                      <TextTitle>Curso: </TextTitle>
                      <Input
                        type="text"
                        name="curso"
                        placeholder={usuario.curso}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={4}>
                      <TextTitle>Previsão de conclusão: </TextTitle>
                      <Input
                        type="text"
                        name="previsao_conclusao"
                        placeholder={usuario.previsao_conclusao}
                        disabled
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Panel>

            <Panel titulo="Contato">
              <Grid container spacing={6}>
                <Grid xs={12}>
                  {usuario.user_contacts ? usuario.user_contacts.length > 0 &&
                    <SecondaryTable
                      columsData={dataTitleContacts}
                      rowsData={organizeDataContact(usuario.user_contacts)}
                    />
                    : null}
                </Grid>
              </Grid>
            </Panel>

            <Panel titulo="Endereço">
              <Grid container spacing={6}>
                <Grid xs={12} lg={6}>
                  <TextTitle>CEP: </TextTitle>
                  <Input
                    type="text"
                    name="cep"
                    placeholder={usuario.cep}
                    mask="cep"
                    disabled
                  />
                </Grid>
                <Grid xs="auto"></Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Logradouro: </TextTitle>
                  <Input
                    type="text"
                    name="logradouro"
                    placeholder={usuario.logradouro}
                    disabled
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Bairro: </TextTitle>
                  <Input
                    type="text"
                    name="bairro"
                    placeholder={usuario.bairro}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Número: </TextTitle>
                  <Input
                    type="text"
                    name="numero"
                    placeholder={usuario.numero}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Complemento: </TextTitle>
                  <Input
                    type="text"
                    name="complemento"
                    placeholder={usuario.complemento}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Município: </TextTitle>
                  <Input
                    type="text"
                    name="municipio"
                    placeholder={usuario.municipio}
                    disabled
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>UF: </TextTitle>
                  <Input
                    type="text"
                    name="uf"
                    placeholder={valueUF}
                    disabled
                  />
                </Grid>
              </Grid>
            </Panel>

            {tipoFuncionario === "terceirizado" ? null : (
              <>
                <Panel titulo="Carteira profissional">
                  <Grid container spacing={6}>
                    <Grid xs={6}>
                      <TextTitle>
                        Tipo de carteira profissional:
                      </TextTitle>
                      <Radio
                        name="carteira_trabalho_tipo"
                        options={optionsCarteiraProfissional}
                        checkedValue={usuario.carteira_trabalho_tipo}
                        disabled
                      />
                    </Grid>
                    {tipoCarteiraProfissional == 'fisica' ? (
                      <>
                        <Grid xs={12} lg={6}>
                          <TextTitle>Data de emissão: </TextTitle>
                          <Input
                            type="text"
                            name="pis_data_emissao"
                            placeholder={usuario.pis_data_emissao}
                            disabled
                          />
                        </Grid>
                        <Grid xs={12} lg={6}>
                          <TextTitle>Número: </TextTitle>
                          <Input
                            type="text"
                            name="carteira_trabalho_numero"
                            placeholder={usuario.carteira_trabalho_numero}
                            disabled
                          />
                        </Grid>
                        <Grid xs={12} lg={6}>
                          <TextTitle>Série: </TextTitle>
                          <Input
                            type="text"
                            name="carteira_trabalho_serie"
                            placeholder={usuario.carteira_trabalho_serie}
                            disabled
                          />
                        </Grid>
                      </>
                    ) : null}
                    <Grid xs={12} lg={6}>
                      <TextTitle>Número do PIS: </TextTitle>
                      <Input
                        type="text"
                        name="pis_numero"
                        placeholder={usuario.pis_numero}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Panel>

                <Panel titulo="Dados bancários">
                  <Grid container spacing={6}>
                    <Grid xs={6} lg={3}>
                      <TextTitle>Tipo de conta:</TextTitle>
                      <Input
                        type="text"
                        name="banco_tipo_conta"
                        placeholder={valueTipoConta}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={3}>
                      <TextTitle>Banco:</TextTitle>
                      <Input
                        type="text"
                        name="banco_nome"
                        placeholder={usuario.banco_nome}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={3}>
                      <TextTitle>Agência:</TextTitle>
                      <Input
                        type="text"
                        name="banco_agencia"
                        placeholder={usuario.banco_agencia}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={3}>
                      <TextTitle>Nº da Conta:</TextTitle>
                      <Input
                        type="text"
                        name="banco_conta"
                        placeholder={usuario.banco_conta}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Panel>
              </>
            )}
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            {tipoFuncionario === "empregado" ? (
              <>
                <Panel titulo="Dados institucionais">
                  <Grid container spacing={6}>
                    <Grid xs={6}>
                      <TextTitle>Número do processo seletivo: </TextTitle>
                      <Input
                        type="text"
                        name="numero_processo_seletivo"
                        placeholder={usuario.numero_processo_seletivo}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Número da inscrição: </TextTitle>
                      <Input
                        type="text"
                        name="numero_inscricao"
                        placeholder={usuario.numero_inscricao}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>
                        Data de assinatura do contrato:
                      </TextTitle>
                      <Input
                        type="text"
                        name="data_assinatura_contrato"
                        placeholder={usuario.data_assinatura_contrato}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Data de admissão: </TextTitle>
                      <Input
                        type="text"
                        name="data_admissao"
                        placeholder={usuario.data_admissao}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Cargo de admissão: </TextTitle>
                      <Input
                        type="text"
                        name="cargo_admissao_id"
                        placeholder={
                          usuario.cargo_admissao
                            ? usuario.cargo_admissao.nome
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Cargo atual: </TextTitle>
                      <Input
                        type="text"
                        name="cargo_atual_id"
                        placeholder={
                          usuario.cargo_atual
                            ? usuario.cargo_atual.nome
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Data de desligamento: </TextTitle>
                      <Input
                        type="text"
                        name="data_desligamento"
                        placeholder={usuario.data_desligamento}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Email: </TextTitle>
                      <Input
                        type="text"
                        name="email"
                        placeholder={usuario.email}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Panel>
              </>
            ) : tipoFuncionario === "terceirizado" ? (
              <>
                <Panel titulo="Dados institucionais">
                  <Grid container spacing={6}>
                    <Grid xs={6}>
                      <TextTitle>Cargo: </TextTitle>
                      <Input
                        type="text"
                        name="cargo_atual_id"
                        placeholder={
                          usuario.cargo_atual
                            ? usuario.cargo_atual.nome
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Empresa: </TextTitle>
                      <Input
                        type="text"
                        name="empresa_id"
                        placeholder={
                          usuario.empresa
                            ? usuario.empresa.nome_razao_social
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Data de admissão: </TextTitle>
                      <Input
                        type="text"
                        name="data_admissao"
                        placeholder={usuario.data_admissao}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Data de desligamento: </TextTitle>
                      <Input
                        type="text"
                        name="data_desligamento"
                        placeholder={usuario.data_desligamento}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Projeto: </TextTitle>
                      <Input
                        name="project"
                        placeholder={
                          usuario.project ? usuario.project.nome : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Email: </TextTitle>
                      <Input
                        type="text"
                        name="email"
                        placeholder={usuario.email}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Gerencia: </TextTitle>
                      <Input
                        type="Text"
                        name="gerencia_id"
                        placeholder={
                          usuario.gerencia ? usuario.gerencia.nome : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Gestor do contrato: </TextTitle>
                      <Input
                        type="Text"
                        name="gestor_contrato_id"
                        placeholder={
                          usuario.gestor_contrato
                            ? usuario.gestor_contrato.nome
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Panel>
              </>
            ) : (
              <>
                <Panel titulo="Dados institucionais">
                  <Grid container spacing={6}>
                    <Grid xs={6}>
                      <TextTitle>Email: </TextTitle>
                      <Input
                        type="text"
                        name="email"
                        placeholder={usuario.email}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Cargo: </TextTitle>
                      <Input
                        type="text"
                        name="cargo_atual_id"
                        placeholder={
                          usuario.cargo_atual
                            ? usuario.cargo_atual.nome
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Gerencia: </TextTitle>
                      <Input
                        type="text"
                        name="gerencia_id"
                        placeholder={
                          usuario.gerencia ? usuario.gerencia.nome : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Superior imediato: </TextTitle>
                      <Input
                        type="text"
                        name="superior_imediato_id"
                        placeholder={
                          usuario.superior_imediato
                            ? usuario.superior_imediato.nome
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Data de admissão: </TextTitle>
                      <Input
                        type="text"
                        name="data_admissao"
                        placeholder={usuario.data_admissao}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Data de desligamento: </TextTitle>
                      <Input
                        type="text"
                        name="data_desligamento"
                        placeholder={usuario.data_desligamento}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Panel>
              </>
            )}

            <Panel titulo="Dados do local de trabalho">
              <Grid container spacing={6}>
                <Grid xs={6}>
                  <TextTitle>Organização: </TextTitle>
                  <Input
                    type="text"
                    name="organization_id"
                    placeholder={
                      usuario.organization
                        ? usuario.organization.nome
                        : ""
                    }
                    disabled
                  />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Local de trabalho: </TextTitle>
                  <Input
                    type="text"
                    name="local_trabalho_id"
                    placeholder={
                      usuario.local_trabalho
                        ? usuario.local_trabalho.nome
                        : ""
                    }
                    disabled
                  />
                </Grid>
                {tipoFuncionario === "empregado" ? (
                  <>
                    <Grid xs={6}>
                      <TextTitle>Gerencia: </TextTitle>
                      <Input
                        type="text"
                        name="gerencia_id"
                        placeholder={
                          usuario.gerencia ? usuario.gerencia.nome : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Superior imediato: </TextTitle>
                      <Input
                        type="text"
                        name="superior_imediato_id"
                        placeholder={
                          usuario.superior_imediato
                            ? usuario.superior_imediato.nome
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Backup: </TextTitle>
                      <Input
                        type="text"
                        name="substituto_id"
                        placeholder={
                          usuario.substituto
                            ? usuario.substituto.nome
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>
                        Autorizado o uso de veículo?:{" "}
                      </TextTitle>
                      <Radio
                        name="uso_veiculo"
                        options={optionsBoolean}
                        checkedValue={usuario.uso_veiculo}
                        disabled
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Panel>
            {tipoFuncionario === "terceirizado" ? null : (
              <>
                <Panel titulo="Dados salariais">
                  <Grid container spacing={6}>
                    <Grid xs={12} lg={6}>
                      <TextTitle>Salário: </TextTitle>
                      <Input
                        type="text"
                        name="salario"
                        placeholder={formatarReais(usuario.salario)}
                        disabled
                      />
                    </Grid>
                    <Grid xs={12} lg={6}>
                      <TextTitle>Area de atuação: </TextTitle>
                      <Radio
                        name="area_atuacao"
                        options={optionsAreaAtuacao}
                        checkedValue={usuario.area_atuacao}
                        disabled
                      />
                    </Grid>

                    <Grid xs={6}>
                      <TextTitle>
                        Número do processo: <span>*</span>
                      </TextTitle>
                      <Input
                        type="text"
                        name="law_process_id"
                        disabled
                        placeholder={usuario?.law_process ? `${usuario.law_process?.codigo} - (SIGAD - ${usuario.law_process?.numero || 'sem nº'})` : ""}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextTitle>Descrição do processo:</TextTitle>
                      <Input name="law_process_desc" placeholder={processo?.assunto_processo} type="text" disabled />
                    </Grid>
                    
                    <Grid xs={12}>
                      {usuario.user_budgets ? usuario.user_budgets.length > 0 &&
                        <SecondaryTable
                          columsData={dataTitleRubrica}
                          rowsData={
                            usuario.user_budgets
                              ? organizeDataSalario(usuario.user_budgets)
                              : []
                          }
                        />
                        : null}
                    </Grid>
                    <Grid xs={6} style={{marginTop: "20px"}}>
                      <TextTitle>
                        Total: {formatarReais(
                                  organizeDataSalario(
                                    usuario?.user_budgets
                                  )?.reduce(function (acc, obj) { return acc + parseFloat(obj?.valor); }, 0))}
                      </TextTitle>
                    </Grid>
                  </Grid>
                </Panel>

                {/* <Panel titulo="Jornada de trabalho">
                  <Grid container spacing={6}>
                    <Grid xs={12} lg={6}>
                      <TextTitle>Informe o tipo do trabalho: </TextTitle>
                      <Input
                        type="text"
                        name="tipo_trabalho"
                        placeholder={valueTipoTrabalho}
                        disabled
                      />
                    </Grid>
                    <Grid xs={12} lg={6}>
                      <TextTitle>Informe a carga horária: </TextTitle>
                      <Input
                        type="text"
                        name="carga_horaria"
                        placeholder={valueCargaHoraria}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={3}>
                      <TextTitle>
                        Entrada período 1: <span>*</span>
                      </TextTitle>
                      <Input
                        type="text"
                        name="hora_entrada_1"
                        placeholder={usuario.hora_entrada_1}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={3}>
                      <TextTitle>
                        Saída período 1: <span>*</span>
                      </TextTitle>
                      <Input
                        type="text"
                        name="hora_saida_1"
                        placeholder={usuario.hora_saida_1}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={3}>
                      <TextTitle>
                        Entrada período 2: <span>*</span>
                      </TextTitle>
                      <Input
                        type="text"
                        name="hora_entrada_2"
                        placeholder={usuario.hora_entrada_2}
                        disabled
                      />
                    </Grid>
                    <Grid xs={6} lg={3}>
                      <TextTitle>
                        Saída período 2: <span>*</span>
                      </TextTitle>
                      <Input
                        type="text"
                        name="hora_saida_2"
                        placeholder={usuario.hora_saida_2}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Panel> */}
              </>
            )}

            {tipoFuncionario === "jovem_aprendiz" ||
              tipoFuncionario === "estagiario" ? (
              <Panel titulo="Contrato">
                <Grid container spacing={6}>
                  <Grid xs={12}>
                    {usuario.user_contracts ? usuario.user_contracts.length > 0 &&
                      <SecondaryTable
                        columsData={dataTitleContracts}
                        rowsData={usuario.user_contracts}
                      />
                      : null}
                  </Grid>

                </Grid>
              </Panel>
            ) : null}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}></CustomTabPanel>
        </Box>
      </Form >

      <ContainerButton2>
        <Link to="/rh/empregados">
          <button className="button_return">
            <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
          </button>
        </Link>
        <Link to={`/rh/empregados/fichaPDF/${empregadoId}`} target="_blank">
          <button className="button_save">
            <FontAwesomeIcon icon={faDownload} size="1x" /> Download PDF
          </button>
        </Link>
      </ContainerButton2>
    </Container >
  );
}
