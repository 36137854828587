import styled from "styled-components";

export const ContainerImgUpload = styled.div`
    width: 100%;
    max-width: 220px;
    min-width: 200px;
`;

export const PreviewImgUploadContainer = styled.div`
    background-color: #efefef;
    height: 250px;
    border-radius: 16px 16px 0 0;

    display: flex;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px 16px 0 0;
    }
`;

export const DefaultImage = styled.div`
    margin: auto;
    color: #d7d7d7;
`;

export const ButtonImgUploadContainer = styled.div`

`;