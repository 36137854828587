import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";

import { Container } from "./styles";
import { TextTitle } from "../../styles/global";
import api from "../../services/api";
import { InputMask } from "@react-input/mask";

interface INupModalProps {
  itemId: number;
  route: string;
  isOpen: boolean;
  handleClose: () => void;
}

const ModalCodigoNup: React.FC<INupModalProps> = ({
  itemId,
  route,
  isOpen,
  handleClose,
}) => {
  const [numeroNup, setNumeroNup] = useState("");

  useEffect(() => {
    if (itemId > 0)
      api
        .get(`${route}/${itemId}`)
        .then((response) => {
          if (response.data.codigo_nup) {
            setNumeroNup(response.data.codigo_nup);
          } else{
            setNumeroNup("")
          }
        })
        .catch((error) => console.log(error));
  }, [itemId]);

  const handleSubmit = () => {
    api
      .put(`${route}/${itemId}`, { codigo_nup: numeroNup })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        window.alert("Erro ao cadastrar Código NUP.");
        console.log(error);
      });
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Container>
        <div className="modal">
          <div className="header">
            <h2>NUP - SIGAD</h2>

            <button onClick={handleClose}>X</button>
          </div>

          <div className="content">
            <TextTitle>Código NUP - SIGAD:</TextTitle>
            <InputMask
              name="codigo"
              mask="_________________________"
              replacement={{ _: /\d/ }}
              defaultValue={numeroNup}
              onChange={(e) => setNumeroNup(e.target.value)}
            />
          </div>

          <div className="row">
            <button className="button_cancel" onClick={handleClose}>
              Cancelar
            </button>
            <button className="button_save" onClick={handleSubmit}>
              Cadastrar
            </button>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default ModalCodigoNup;
