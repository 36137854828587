import { Root, Trigger, Content, Item } from "@radix-ui/react-dropdown-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Container } from "./styles";
import {
  IDropdownItem,
  IDropdownDesativar,
  IDropdownNumeroProcesso,
  DropdownEdit,
  DropdownView,
  DropdownExlcuir,
  DropdownDesativar,
  DropdownDocumentos,
  DropdownCertidoes,
  DropdownAditivos,
  DropdownApostilamento,
  DropdownPlanejamento,
  IDropdownExcluir,
  DropdownOrcamento,
  DropdownNumeroProcesso,
  DropdownLinha,
  DropdownComprovante,
  IDropdownCodigoNup,
  DropdownCodigoNup,
  DropdownNormaInterna,
  DropdownAcaoPlanejamento,
  DropdownAtualizarAcaoPlanejamento,
  DropdownVincularProtocolo,
  DropdownFinalizarProcessoJudicial,
  DropdownHistoricoProcessoJudicial,
  DropdownRealizarPagamentoDespesa,
  DropdownInformarPagamentoDespesa,
  DropdownValidarDotacao,
  IDropdownCancelar,
  DropdownCancelar,
  DropdownViewInstrumento,
  DropdownEditInstrumento,
  IDropdownInstrumento,
  DropdownFerias,
  DropdownConfirmarFerias,
  DropdownDePara,
  DropdownNotaFiscal,
} from "./Dropdowns";

export interface IDropDownMainTableVisible {
  visible: boolean;
}

export interface IDropDownMainTable {
  view?: IDropdownItem & IDropDownMainTableVisible;
  editar?: IDropdownItem & IDropDownMainTableVisible;
  documentos?: IDropdownItem & IDropDownMainTableVisible;
  aditivo?: IDropdownItem & IDropDownMainTableVisible;
  apostilamento?: IDropdownItem & IDropDownMainTableVisible;
  certidoes?: IDropdownItem & IDropDownMainTableVisible;
  desativar?: IDropdownDesativar & IDropDownMainTableVisible;
  cancelar?: IDropdownCancelar & IDropDownMainTableVisible;
  excluir?: IDropdownExcluir & IDropDownMainTableVisible;
  planejamento?: IDropdownItem & IDropDownMainTableVisible;
  acaoPlanejamento?: IDropdownItem & IDropDownMainTableVisible;
  orcamento?: IDropdownItem & IDropDownMainTableVisible;
  numeroProcesso?: IDropdownNumeroProcesso & IDropDownMainTableVisible;
  codigoNup?: IDropdownCodigoNup & IDropDownMainTableVisible;
  linha?: IDropdownItem & IDropDownMainTableVisible;
  comprovante?: IDropdownItem & IDropDownMainTableVisible;
  normaInterna?: IDropdownItem & IDropDownMainTableVisible;
  atualizarAcaoPlanejamento?: IDropdownItem & IDropDownMainTableVisible;
  vincularProtocolo?: IDropdownItem & IDropDownMainTableVisible;
  historicoProcessoJudicial?: IDropdownItem & IDropDownMainTableVisible;
  finalizarProcessoJudicial?: IDropdownItem & IDropDownMainTableVisible;
  realizarPagamentoDespesa?: IDropdownItem & IDropDownMainTableVisible;
  informarPagamentoDespesa?: IDropdownItem & IDropDownMainTableVisible;
  validarDotacao?: IDropdownItem & IDropDownMainTableVisible;
  viewInstrumento?: IDropdownInstrumento & IDropDownMainTableVisible
  editarInstrumento?: IDropdownInstrumento & IDropDownMainTableVisible
  ferias?: IDropdownItem & IDropDownMainTableVisible;
  confirmarFerias?: IDropdownItem & IDropDownMainTableVisible;
  dePara?: IDropdownItem & IDropDownMainTableVisible;
  notaFiscal?: IDropdownItem & IDropDownMainTableVisible;
}

const DropDownMainTable: React.FC<IDropDownMainTable> = (props) => {
  return (
    <Container>
      <Root>
        <Trigger className="button_trigger">
          <span>Ações</span>
          <FontAwesomeIcon icon={faChevronDown} size="1x" color="#111111" />
        </Trigger>

        <Content>
          <div className="dropdown_content">

            {props.view?.visible && (
              <Item className="dropdown_item">
                <DropdownView linkTo={props.view.linkTo} />
              </Item>
            )}

            {props.editar?.visible && (
              <Item className="dropdown_item">
                <DropdownEdit linkTo={props.editar.linkTo} />
              </Item>
            )}

            {props.viewInstrumento?.visible && (
              <Item className="dropdown_item">
                <DropdownViewInstrumento
                  id={props.viewInstrumento.id}
                  updateId={props.viewInstrumento.updateId}
                  openModal={props.viewInstrumento.openModal}
                  tipo={props.viewInstrumento.tipo}
                />
              </Item>
            )}

            {props.editarInstrumento?.visible && (
              <Item className="dropdown_item">
                <DropdownEditInstrumento
                  id={props.editarInstrumento.id}
                  updateId={props.editarInstrumento.updateId}
                  openModal={props.editarInstrumento.openModal} 
                  tipo={props.editarInstrumento.tipo}
                  />
              </Item>
            )}

            {props.documentos?.visible && (
              <Item className="dropdown_item">
                <DropdownDocumentos
                  linkTo={props.documentos.linkTo}
                  handler={props.documentos.handler}
                />
              </Item>
            )}

            {props.certidoes?.visible && (
              <Item className="dropdown_item">
                <DropdownCertidoes
                  linkTo={props.certidoes.linkTo}
                  handler={props.certidoes.handler}
                />
              </Item>
            )}

            {props.aditivo?.visible && (
              <Item className="dropdown_item">
                <DropdownAditivos
                  linkTo={props.aditivo.linkTo}
                  handler={props.aditivo.handler}
                />
              </Item>
            )}

            {props.apostilamento?.visible && (
              <Item className="dropdown_item">
                <DropdownApostilamento
                  linkTo={props.apostilamento.linkTo}
                  handler={props.apostilamento.handler}
                />
              </Item>
            )}

            {props.orcamento?.visible && (
              <Item className="dropdown_item">
                <DropdownOrcamento
                  linkTo={props.orcamento.linkTo}
                  handler={props.orcamento.handler}
                />
              </Item>
            )}


            {props.codigoNup?.visible && (
              <Item className="dropdown_item">
                <DropdownCodigoNup
                  id={props.codigoNup.id}
                  updateId={props.codigoNup.updateId}
                  openModal={props.codigoNup.openModal}
                />
              </Item>
            )}

            {props.comprovante?.visible && (
              <Item className="dropdown_item">
                <DropdownComprovante linkTo={props.comprovante.linkTo} />
              </Item>
            )}

            {props.linha?.visible && (
              <Item className="dropdown_item">
                <DropdownLinha linkTo={props.linha.linkTo} />
              </Item>
            )}

            {props.numeroProcesso?.visible && (
              <Item className="dropdown_item">
                <DropdownNumeroProcesso
                  id={props.numeroProcesso.id}
                  updateId={props.numeroProcesso.updateId}
                  openModal={props.numeroProcesso.openModal}
                />
              </Item>
            )}

            {props.planejamento?.visible && (
              <Item className="dropdown_item">
                <DropdownPlanejamento
                  linkTo={props.planejamento.linkTo}
                  handler={props.planejamento.handler}
                />
              </Item>
            )}

            {props.acaoPlanejamento?.visible && (
              <Item className="dropdown_item">
                <DropdownAcaoPlanejamento
                  linkTo={props.acaoPlanejamento.linkTo}
                  handler={props.acaoPlanejamento.handler}
                />
              </Item>
            )}

            {props.normaInterna?.visible && (
              <Item className="dropdown_item">
                <DropdownNormaInterna linkTo={props.normaInterna.linkTo} />
              </Item>
            )}

            {props.vincularProtocolo?.visible && (
              <Item className="dropdown_item">
                <DropdownVincularProtocolo linkTo={props.vincularProtocolo.linkTo} />
              </Item>
            )}

            {props.atualizarAcaoPlanejamento?.visible && (
              <Item className="dropdown_item">
                <DropdownAtualizarAcaoPlanejamento linkTo={props.atualizarAcaoPlanejamento.linkTo} />
              </Item>
            )}

            {props.historicoProcessoJudicial?.visible && (
              <Item className="dropdown_item">
                <DropdownHistoricoProcessoJudicial linkTo={props.historicoProcessoJudicial.linkTo} />
              </Item>
            )}

            {props.finalizarProcessoJudicial?.visible && (
              <Item className="dropdown_item">
                <DropdownFinalizarProcessoJudicial linkTo={props.finalizarProcessoJudicial.linkTo} />
              </Item>
            )}

            {props.realizarPagamentoDespesa?.visible && (
              <Item className="dropdown_item">
                <DropdownRealizarPagamentoDespesa linkTo={props.realizarPagamentoDespesa.linkTo} />
              </Item>
            )}

            {props.informarPagamentoDespesa?.visible && (
              <Item className="dropdown_item">
                <DropdownInformarPagamentoDespesa linkTo={props.informarPagamentoDespesa.linkTo} />
              </Item>
            )}

            {props.validarDotacao?.visible && (
              <Item className="dropdown_item">
                <DropdownValidarDotacao linkTo={props.validarDotacao.linkTo} />
              </Item>
            )}

            {props.ferias?.visible && (
              <Item className="dropdown_item">
                <DropdownFerias linkTo={props.ferias.linkTo} />
              </Item>
            )}

            {props.confirmarFerias?.visible && (
              <Item className="dropdown_item">
                <DropdownConfirmarFerias linkTo={props.confirmarFerias.linkTo} />
              </Item>
            )}

            {props.dePara?.visible && (
              <Item className="dropdown_item">
                <DropdownDePara linkTo={props.dePara.linkTo} />
              </Item>
            )}

            {props.notaFiscal?.visible && (
              <Item className="dropdown_item">
                <DropdownNotaFiscal linkTo={props.notaFiscal.linkTo} />
              </Item>
            )}

            {props.desativar?.visible && (
              <Item className="dropdown_item">
                <DropdownDesativar
                  linkTo={props.desativar.linkTo}
                  id={props.desativar.id}
                  status={props.desativar.status}
                  desactiveText={props.desativar.desactiveText}
                />
              </Item>
            )}

            {props.cancelar?.visible && (
              <Item className="dropdown_item">
                <DropdownCancelar
                  linkTo={props.cancelar.linkTo}
                  id={props.cancelar.id}
                  situacao={props.cancelar.situacao}
                  activateSituacao={props.cancelar.activateSituacao}
                />
              </Item>
            )}

            {props.excluir?.visible && (
              <Item className="dropdown_item">
                <DropdownExlcuir
                  id={props.excluir.id}
                  linkTo={props.excluir.linkTo}
                />
              </Item>
            )}
          </div>
        </Content>
      </Root>
    </Container>
  );
};

export default DropDownMainTable;
