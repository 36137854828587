import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useJuridico = () => {

    const history = useHistory();
    const [processosJuridicos, setProcessosJuridicos] = useState([]);
    const [processoJuridico, setProcessoJuridico] = useState([]);
    const [error, setError] = useState(null);

    async function getProcessosJuridicos(params?: object) {
        try {
            const response = await api.get(`judicial_processes${stringfyParams(params)}`);
            setProcessosJuridicos(response.data);
        } catch (e) {
            setError(e);
        }
    };

    async function getProcessoJuridico(id: string) {
        try {
            const response = await api.get(`judicial_processes/${id}`);
            setProcessoJuridico(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    };

    async function createProcessoJuridico(data: any) {
        try {
            const response = await api.post('judicial_processes', data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            alert("Processo jurídico cadastrado com sucesso!");
            history.push("/juridico");

        } catch (e) {
            alert("Erro ao cadastrar Processo jurídico");
            setError(e);
        }
    };

    async function updateProcessoJuridico(id: string, data: any) {
        try {
            const response = await api.put(`judicial_processes/${id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            alert("Processo jurídico atualizado com sucesso!");
            history.push("/juridico");

        } catch (e) {
            alert("Erro ao atualizar Processo jurídico");
            setError(e);
        }
    };

    async function getProcessoJuridicoXls(data?: any) {
        try {
            const response = await api.get(`judicial_processes/xls`, {
                responseType: "blob", // importante!
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Processos_juridicos.xlsx");
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }


    return { processosJuridicos, processoJuridico, getProcessosJuridicos, getProcessoJuridico, createProcessoJuridico, updateProcessoJuridico, getProcessoJuridicoXls, error };
};

export default useJuridico;