import { useCallback, useRef } from 'react';
import { Logo } from '../../Logo';
import { Button } from '../../components/Button/Button';
import { TextInput } from '../../components/Input/Input';
import { Text } from '../../components/Text/Text';

import { Card, FormDiv, FormatedTextInput, LogoDiv, TextTitle, TitleDiv } from './styles';

import { FormHandles } from '@unform/core';
import { useAuth } from '../../hooks/auth';

import { Form } from '@unform/web';
import { Input } from '../../styles/global';
import { cpfToNumber } from '../../utils/formatDados';

interface SignInFormData {
    cpf: string;
    password: string;
}

const Login: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    const { signIn } = useAuth();

    const handleSubmit = useCallback(
        async (data: SignInFormData) => {
            data["cpf"] = cpfToNumber( data.cpf );
            console.log(data);
          try {    
            await signIn({
                cpf: data.cpf,
                password: data.password
            });

          } catch (err) {    

          }
        },
        [],
    );

      //---------------------------

    
    // // const [data, setData] = useState<Usuario[]>([]);
    // const [cpf, setCpf] = useState("");
    // const [password, setPassword] = useState(""); 
   
    // // const navigate = useNavigate();

    // const handleSignIn = () => {

    //     // const { cpf, password};

    //     login(cpf, password).then(
    //         () => {
    //             navigate("/dashboard");
    //             window.location.reload();
    //         },
    //         (error) =>{
    //             console.log("Aconteceu algum erro inesperado", error);
    //         }
    //     )
    //     // event.preventDefault();
    //     // const API_URL = "http://3.212.21.14:5000/auth";
    //     //  axios.post(API_URL, params).then(
    //     //     function(response){ 
    //     //         console.log('esse é o console' + response.data);
    //     //     }
    //     // );
    // }

    return(
        <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card>
                <LogoDiv>
                    <Logo />
                </LogoDiv>
                <FormDiv>
                    <TitleDiv>
                        <Text href='' title='Login SIGA' />
                    </TitleDiv>
                    <Form ref={formRef} onSubmit={handleSubmit} >
                        <label htmlFor='cpf' >
                            <TextTitle>CPF</TextTitle>
                            <Input
                                type="text"
                                name="cpf"
                                mask="cpf"
                                required
                            />             
                        </label>
                        <label htmlFor='password' >
                            <TextTitle>Senha</TextTitle>
                            <Input
                                type="password"
                                name='password'
                                required
                            /> 
                        </label> 
                        <Button type='submit'>Entrar</Button>
                    </Form>
                    <footer >
                        <Text href='/esqueci-senha' title='Esqueci minha senha!' />
                    </footer>
                </FormDiv>
            </Card>
        </div>
    )
}

export default Login;