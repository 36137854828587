import { clsx } from "clsx";
import { ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";

import OptionTypeBase from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
    Props as CreatableSelectProps,
} from 'react-select';

import { useField } from "@unform/core";

const InputCss = styled.input`
    width: 350px;
    height: 40px; 
    letter-spacing: 2px;
    font-size: 1.3rem;
    padding-left: 0.5rem;
    font-weight: 500; 
    border: none;
    border-radius: 0.5rem;
    border-left: 3px solid #f1f1f1;
    border-top: 3px solid #f1f1f1;
    box-shadow: 10px 2px 2px solid #333;
    background: #fafafa; 
`;


export interface CreatableSelectInputRootProps{
    children: ReactNode;
}

function CreatableSelectInputRoot(props: CreatableSelectInputRootProps) {
    return(
        <div className={clsx(InputCss)} {...props}>
            {props.children}
        </div>
    )
}

interface CreatableSelectInputInputProps extends CreatableSelectProps<OptionTypeBase> {
    name: string;
}

export const CreatableSelectInputInput: React.FC<CreatableSelectInputInputProps> = ({ name, ...props }) => {
    const creatableSelectRef = useRef<HTMLSelectElement>(null);
    const { fieldName, defaultValue, registerField } = useField(name!);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: creatableSelectRef.current,
            getValue: (ref) => ref.state.selectValue[0] ? ref.state.selectValue[0].value : "",
            setValue: (ref, value) => {
                ref.setValue(value || null);
            },
            clearValue: (ref: any) => {
                ref.clearValue();
            }
        });
    }, [fieldName, registerField]);
        
    return(
        <CreatableSelect ref={creatableSelectRef} defaultValue={defaultValue} classNamePrefix="react-creatable-select" {...props}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
        />
    )
}

export const CreatableSelectInput = {
    Root: CreatableSelectInputRoot,
}