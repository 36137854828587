import { faPlus, faSave, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Panel from "../../../components/Panel";

import {
  ButtonAdd,
  ContainerButton,
  Form,
  FormContainer,
  Input,
  InputAddBtn,
  SelectInput,
  TextTitle,
} from "../../../styles/global";

import { ButtonSearch, Title } from "./style";

import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import useFornecedor from "../../../actions/cruds/useFornecedor";
import TableSimple from "../../../components/SimpleTable";
import useCnae from "../../../actions/cruds/useCnae";
import useEndereco from "../../../actions/cruds/useEndereco";
import bankApi from "../../../services/bankApi";
import Radio from "../../../components/InputRadio";
import { Link, useParams } from "react-router-dom";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import useContatoFornecedor from "../../../actions/cruds/useContatoFornecedor";
import Grid from "@mui/material/Unstable_Grid2";
import emailChecker from "../../../utils/emailChecker";

export function EditFornecedor() {
  const { cnaes, getCnaes } = useCnae();
  const { getAddress, endereco, setEndereco } = useEndereco();
  const {
    fornecedor,
    getFornecedor,
    fornecedores,
    getFornecedores,
    updateFornecedor,
  } = useFornecedor();
  const { deleteContatoFornecedor } = useContatoFornecedor();

  const [banks, setBanks] = useState([]);
  const [valueUF, setValueUF] = useState([]);
  const [valueAccountType, setValueAccountType] = useState([]);
  const [valueBank, setValueBank] = useState([]);
  const [valueCnae, setValueCnae] = useState([]);

  //Variáveis para visualização dos dados na página
  const [contacts, setContacts] = useState([]);
  const [representatives, setRepresentatives] = useState([]);

  //Variáveis para implementação dos dados no db
  const [contactsUpdate, setContactsUpdate] = useState([]);
  const [representativesUpdate, setRepresentativesUpdate] = useState([]);
  const [valueConsorcio, setValueConsorcio] = useState([]);
  const [consorcios, setConsorcios] = useState([]);
  const [showFieldsConsorcio, setShowFieldsConsorcio] = useState(true);
  const [tipoFornecedor, setTipoFornecedor] = useState<Boolean>(true);

  const { supplierId } = useParams();

  useEffect(() => {
    getFornecedor(supplierId);
    const fetchData = async () => {
      try {
        const { data } = await bankApi.get("banks/v1");
        setBanks(
          data.map((d) => ({
            value: d.fullName,
            label: `${d.code ? d.code + " - " : ""} ${d.fullName}`,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    getCnaes();
    getFornecedores();
  }, []);

  //Popula os campos do tipo SelectInput (dropdown) - valor UF, Banco, Tipo de Conta, CNAE, Contatos, Representantes e Consórcio  - quando os valores de fornecedor for retornado e carregado//
  useEffect(() => {

    if (fornecedor.cpf_cnpj) {
      if (fornecedor.cpf_cnpj.length <= 11) {
        setTipoFornecedor(false);
      } else {
        setTipoFornecedor(true);
      }
    };

    if (fornecedor.uf) {
      const initialValueUF = optionsUF.find((o) => o.value === fornecedor.uf);
      setValueUF(initialValueUF);
    }

    if (fornecedor.banco_nome) {
      const initialValueBank = banks.find(
        (o) => o.value === fornecedor.banco_nome
      );
      setValueBank(initialValueBank);
    }

    if (fornecedor.banco_tipo_conta) {
      const initialValueAccountType = optionsAccountType.find(
        (o) => o.value === fornecedor.banco_tipo_conta
      );
      setValueAccountType(initialValueAccountType);
    }

    if (cnaes.data) {
      const initialValueCnae = cnaes.data.find(
        (o) => o.nome === fornecedor.cnae_activity.nome
      );
      if (initialValueCnae) {
        const cnaeInitialValue = [];
        cnaeInitialValue.push(initialValueCnae);
        const valueCnaeInitialValue = organizeDataCnaes(cnaeInitialValue);

        setValueCnae(valueCnaeInitialValue);
      }
    }

    //Lógica para selecionar o campo Consorcio do tipo Radio//
    if (tipoFornecedor) {
      const consorcioRadioField = formRef.current?.getFieldRef("consorcio");
      if (fornecedor.consorcio == true) {
        consorcioRadioField[0].checked = true;
      } else if (fornecedor.consorcio == false) {
        consorcioRadioField[1].checked = true;
      }
      setShowFieldsConsorcio(consorcioRadioField[0].checked);
    }

    if (fornecedor.supplier_contacts) {
      //Adicona todos os contatos já existentes às variáveis de visualização e implementação no db para o update
      if (fornecedor.supplier_contacts.length) {
        const contactMap = organizeDataContact(fornecedor.supplier_contacts);
        setContacts([...contactMap]);
        setContactsUpdate([...fornecedor.supplier_contacts]);
      }
    }

    if (fornecedor.supplier_representatives) {
      //Adicona todos os representates já existentes às variáveis de visualização e implementação no db para o update
      if (fornecedor.supplier_representatives.length) {
        const representativeMap = organizeDataRepresentatives(
          fornecedor.supplier_representatives
        );
        setRepresentatives([...representativeMap]);
        setRepresentativesUpdate([...fornecedor.supplier_representatives]);
      }
    }

    if (fornecedor.cep) {
      // ja popula o estado de endereço caso traga um cep cadastrado
      dataAddress(fornecedor.cep);
    };

    if (fornecedor.consorcio_parceiro) {
      //Adicona todos os consórcios já existentes às variáveis de visualização e implementação no db para o update
      if (fornecedor.consorcio_parceiro.length > 0) {
        const consorcioParceiros = organizeDataFornecedores(fornecedor.consorcio_parceiro);
        setValueConsorcio(consorcioParceiros)
      }
    };

  }, [fornecedor]);



  useEffect(() => {
    if (endereco.uf.length != 0) {
      const selected = optionsUF.find((o) => o.value === endereco.uf);
      setValueUF(selected);
    }
  }, [endereco]);

  const organizeDataCnaes = (data) => {
    if (typeof data === "object") {
      data = data.map((d) => ({
        value: d.id,
        label: `${d.codigo} - ${d.nome}`,
      }));
    }

    return data;
  };

  const organizeDataFornecedores = (data) => {
    if (typeof data === "object") {
      data = data.filter((obj) => obj.id != supplierId);
      data = data.map((d) => ({
        value: d.id,
        label: `${d.cpf_cnpj} - ${d.nome_razao_social}`,
      }));
    }
    // data = data.filter(item => item.value != idFornecedor);

    return data;
  };

  const organizeDataRepresentatives = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          id: d.id,
          cpf: d.cpf,
          nome: d.nome,
        }));
        return data;
      }
    }
    return [];
  };

  const organizeDataContact = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          id: d.id,
          tipo: d.tipo,
          contato: d.contato,
        }));
        return data;
      }
    }
    return [];
  };

  const dataAddress = () => {
    getAddress(formRef.current.getFieldValue("cep"));
  };

  const handlerContact = (btn) => {
    const randon_id = Date.now();
    const tipoContato = btn.target.id;

    if (tipoContato === 'email') {
      const emailValue = formRef.current?.getFieldValue("email");
      const emailValido = emailChecker(emailValue);

      if (!emailValido) {
        alert("Email inválido! Insira um email valido. Ex: 'email@email.com'");
        return;
      }
    };

    if (tipoContato != "telefone" && tipoContato != "email") {
      return;
    };

    setContacts([
      ...contacts,
      {
        new_insert: true,
        id: randon_id,
        tipo: btn.target.id,
        contato:
          btn.target.id === "telefone"
            ? formRef.current.getFieldValue("telefone")
            : formRef.current.getFieldValue("email"),
      },
    ]);

    setContactsUpdate([
      ...contactsUpdate,
      {
        new_insert: true,
        front_id: randon_id,
        tipo: btn.target.id,
        contato:
          btn.target.id === "telefone"
            ? formRef.current.getFieldValue("telefone")
            : formRef.current.getFieldValue("email"),
      },
    ]);

    formRef.current?.setFieldValue("telefone", "");
    formRef.current?.setFieldValue("email", "");
  };

  const handlerRepresentatives = () => {
    const randon_id = Date.now();

    setRepresentatives([
      ...representatives,
      {
        new_insert: true,
        id: randon_id,
        cpf: formRef.current.getFieldValue("representative_cpf"),
        nome: formRef.current.getFieldValue("representative_nome"),
      },
    ]);

    setRepresentativesUpdate([
      ...representativesUpdate,
      {
        new_insert: true,
        front_id: randon_id,
        cpf: formRef.current.getFieldValue("representative_cpf"),
        nome: formRef.current.getFieldValue("representative_nome"),
      },
    ]);
  };

  //Mudar os valores de Consórcio
  const handleConsorcio = (selectedConsorcio) => {
    let consorciosHelper = [];

    selectedConsorcio.map((o) => consorciosHelper.push(o.value));
    setConsorcios(consorciosHelper);

    if (selectedConsorcio.length && fornecedores.data?.length) {
      const consorcioValues = consorciosHelper.map((consorcio) =>
        fornecedores.data.find((o) => o.id === consorcio)
      );

      const consorcioFinalValues = organizeDataFornecedores(consorcioValues);

      setValueConsorcio(consorcioFinalValues);
    } else {
      setValueConsorcio([]);
    }
  };

  //Muda os valores da UF
  const handleChangeUF = (selectedUF: object) => {
    setValueUF(selectedUF);
  };

  //Muda os valores do Tipo de Conta
  const handleChangeAccountType = (selectedAccountType: object) => {
    setValueAccountType(selectedAccountType);
  };

  //Muda os valores de Banco
  const handleChangeBank = (selectedBank: object) => {
    setValueBank(selectedBank);
  };

  //Muda os valores de CNAE
  const handleChangeCnae = (selectedCnae: object) => {
    setValueCnae(selectedCnae);
  };

  const handleChangeRadioConsorcio = () => {
    const consorcioRadioField = formRef.current?.getFieldRef("consorcio");
    setShowFieldsConsorcio(consorcioRadioField[0].checked);
  };

  const optionsUF = [
    { value: "AC", label: "AC - Acre" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "BA", label: "BA - Bahia" },
    { value: "CE", label: "CE - Ceará" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "GO", label: "GO - Goiás" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "PA", label: "PA - Pará" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PI", label: "PI - Piauí" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RR", label: "RR - Roraima" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "EX", label: "EX - Estrangeiro" },
  ];

  const optionsAccountType = [
    { value: "corrente", label: "Conta Corrente" },
    { value: "poupanca", label: "Conta Poupança" },
  ];

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        data["supplier_contacts_attributes"] = contactsUpdate;
        data["supplier_representatives_attributes"] = representativesUpdate;
        data["consorcio_ids"] = consorcios;
        data["tipo_pessoa"] = "pessoa_fisica";

        updateFornecedor(supplierId, data);
      } catch (err) { }
    },
    [contactsUpdate, consorcios, representativesUpdate]
  );

  const dataTitleContact: GridColDef[] = [
    { field: "tipo", headerName: "Tipo de contato", flex: 1 },
    { field: "contato", headerName: "Contato", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteContactClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  async function onDeleteContactClick(e: any, row: any): Promise<void> {
    if (row.new_insert) {
      // Se a linha ja estiver cadastrada no banco chama a rota que deleta
      setContactsUpdate(contactsUpdate.filter((el) => el.front_id !== row.id));
    } else {
      setContactsUpdate(contactsUpdate.filter((el) => el.id !== row.id));
      deleteContatoFornecedor(row.id);
    }
    setContacts(contacts.filter((el) => el.id !== row.id));
  }

  const dataTitleRepresentative: GridColDef[] = [
    { field: "cpf", headerName: "CPF", flex: 1 },
    { field: "nome", headerName: "Nome", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteRepresentativeClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  function onDeleteRepresentativeClick(e: any, row: any): void {
    if (row.new_insert) {
      // Se a linha ja estiver cadastrada no banco chama a rota que deleta
      setRepresentativesUpdate(
        representativesUpdate.filter((el) => el.front_id !== row.id)
      );
    } else {
      deleteContatoFornecedor(row.id);
      setRepresentativesUpdate(
        representativesUpdate.filter((el) => el.id !== row.id)
      );
    }

    setRepresentatives(representatives.filter((el) => el.id !== row.id));
  }


  return (
    <Container>
      <Title color="#333" >
        <span>Editar Fornecedor</span>
      </Title>
      <Form id="editFornecedor" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>CPF / CNPJ: </TextTitle>
              <Input
                type="text"
                name="cpf_cnpj"
                mask="cpfCnpj"
                value={fornecedor.cpf_cnpj}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Nome / Razão Social:</TextTitle>
              <Input
                type="text"
                name="nome_razao_social"
                defaultValue={fornecedor.nome_razao_social}
                required
              />
            </Grid>
            {tipoFornecedor ? (
              <>
                <Grid xs={6}>
                  <TextTitle>Inscrição Estadual:</TextTitle>
                  <Input
                    type="text"
                    name="inscricao_estadual"
                    defaultValue={fornecedor.inscricao_estadual}
                  />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Nome Fantasia:</TextTitle>
                  <Input
                    type="text"
                    name="nome_fantasia"
                    defaultValue={fornecedor.nome_fantasia}
                  />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>
                    Este CNPJ que está sendo cadastrado trata-se de um
                    consórcio?
                  </TextTitle>
                  <Radio
                    name="consorcio"
                    options={[
                      { id: "01", value: "true", label: "Sim" },
                      { id: "02", value: "false", label: "Não" },
                    ]}
                    onClick={handleChangeRadioConsorcio}
                  />
                </Grid>
              </>
            ) : (
              <Grid xs={6}>
                <TextTitle>RG:</TextTitle>
                <Input
                  type="text"
                  name="rg"
                  defaultValue={fornecedor.rg}
                  mask="rg"
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Contato">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>Email:</TextTitle>
              <div style={{ position: 'relative' }}>
                <Input style={{ paddingRight: '115px' }} type="text" name="email" />
                <InputAddBtn
                  id="email"
                  name="email"
                  type="button"
                  onClick={handlerContact}
                >
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Telefone:</TextTitle>
              <div style={{ position: 'relative' }}>
                <Input style={{ paddingRight: '115px' }} type="text" name="telefone" mask="phone" />
                <InputAddBtn
                  id="telefone"
                  name="telefone"
                  type="button"
                  onClick={handlerContact}
                >
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            {contacts.length > 0 && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleContact}
                  rowsData={contacts}
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP:</TextTitle>
              <div style={{ position: 'relative' }}>
                <Input
                  type="text"
                  name="cep"
                  defaultValue={fornecedor.cep}
                  mask="cep"
                />
                <InputAddBtn type="button" onClick={dataAddress}><FontAwesomeIcon icon={faPlus} /> Pesquisar CEP </InputAddBtn>
              </div>
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6} >
              <TextTitle>Logradouro: </TextTitle>
              <Input
                type="text"
                name="logradouro"
                value={
                  endereco.logradouro
                    ? endereco.logradouro
                    : fornecedor.logradouro
                }
                onChange={(e) =>
                  setEndereco({
                    ...endereco,
                    logradouro: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid xs={12} lg={6} >
              <TextTitle>Bairro:</TextTitle>
              <Input
                type="text"
                name="bairro"
                value={
                  endereco.bairro ? endereco.bairro : fornecedor.bairro
                }
                onChange={(e) =>
                  setEndereco({ ...endereco, bairro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input
                type="text"
                name="numero"
                defaultValue={fornecedor.numero}
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input
                type="text"
                name="complemento"
                defaultValue={fornecedor.complemento}
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={
                  endereco.localidade
                    ? endereco.localidade
                    : fornecedor.municipio
                }
                onChange={(e) =>
                  setEndereco({
                    ...endereco,
                    localidade: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF: </TextTitle>
              <SelectInput
                id="idEstado"
                name="uf"
                value={valueUF}
                options={optionsUF}
                onChange={handleChangeUF}
                placeholder="Selecione"
              />
            </Grid>
          </Grid>
        </Panel>

        {showFieldsConsorcio ? null : (
          <Panel titulo="Dados bancários">
            <Grid container spacing={6}>
              <Grid xs={6} lg={3}>
                <TextTitle>Tipo de conta:</TextTitle>
                <SelectInput
                  id="tipoConta"
                  name="banco_tipo_conta"
                  options={optionsAccountType}
                  value={valueAccountType}
                  onChange={handleChangeAccountType}
                  placeholder="Selecione"
                />
              </Grid>
              <Grid xs={6} lg={3}>
                <TextTitle>Banco:</TextTitle>
                <SelectInput
                  id="tipoBanco"
                  name="banco_nome"
                  options={banks}
                  value={valueBank}
                  onChange={handleChangeBank}
                  placeholder="Selecione"
                />
              </Grid>
              <Grid xs={6} lg={3}>
                <TextTitle>Agência:</TextTitle>
                <Input
                  type="text"
                  name="banco_agencia"
                  defaultValue={fornecedor.banco_agencia}
                />
              </Grid>
              <Grid xs={6} lg={3}>
                <TextTitle>Nº da Conta:</TextTitle>
                <Input
                  type="text"
                  name="banco_conta"
                  defaultValue={fornecedor.banco_conta}
                />
              </Grid>
            </Grid>
          </Panel>
        )}

        {showFieldsConsorcio ? null : (
          <Panel titulo="Atividade CNAE">
            <Grid container spacing={6}>
              <Grid xs={12}>
                <TextTitle>Atividade Principal:</TextTitle>
                <SelectInput
                  className="selectInput"
                  id="idAtividade"
                  name="cnae_activity_id"
                  options={organizeDataCnaes(cnaes.data) || []}
                  onInputChange={(e) => {
                    getCnaes([{ label: "nome", value: e }]);
                  }}
                  value={valueCnae}
                  onChange={handleChangeCnae}
                  placeholder="Selecione"
                />
              </Grid>
            </Grid>
          </Panel>
        )}

        {tipoFornecedor && (
          <Panel titulo="Representantes legais">
            <Grid container spacing={6}>
              <Grid xs={12} lg={6}>
                <TextTitle>CPF:</TextTitle>
                <Input type="text" name="representative_cpf" mask="cpf" />
              </Grid>
              <Grid xs={12} lg={6}>
                <TextTitle>Nome:</TextTitle>
                <Input type="text" name="representative_nome" />
              </Grid>
              <Grid xs={12} lg={6}>
                <ButtonAdd type="button" onClick={handlerRepresentatives}>
                  <FontAwesomeIcon icon={faPlus} /> Adicionar
                </ButtonAdd>
              </Grid>
              {representatives.length > 0 && (
                <Grid xs={12}>
                  <SecondaryTable
                    columsData={dataTitleRepresentative}
                    rowsData={representatives}
                  />
                </Grid>
              )}
            </Grid>
          </Panel>
        )}

        {tipoFornecedor && showFieldsConsorcio ? (
          <Panel titulo="Consórcio">
            <Grid container spacing={6}>
              <Grid xs={12}>
                <TextTitle>
                  Utilize os campos abaixo para adicionar as empresas
                  participantes deste consórcio:
                </TextTitle>
              </Grid>
              <Grid xs={12}>
                <TextTitle>CPF:</TextTitle>
                <SelectInput
                  isMulti
                  id="consorcioId"
                  name="consorcio_ids_select"
                  placeholder="Selecione"
                  value={valueConsorcio}
                  options={fornecedores.data ? organizeDataFornecedores(fornecedores.data.filter((fornecedor) => fornecedor.ativo == true)) : []}
                  onChange={handleConsorcio}
                  onInputChange={(e) => {
                    getFornecedores([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                  }}
                />
              </Grid>
            </Grid>
          </Panel>
        ) : null}
      </Form>

      <ContainerButton>
        <Link to="/fornecedores">
          <button>
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <button type="submit" form="editFornecedor">
          <FontAwesomeIcon icon={faSave} /> Salvar
        </button>
      </ContainerButton>
    </Container >
  );
}
