import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px 50px;

    .filtrar {
        background: #00AEEF 0% 0% no-repeat padding-box;
        border-radius: 8px;
        border: none;
        color: #fff;
        padding: 12px 30px;
        font-size: 20px;
        margin-top: 30px;
    }

    .csv {
        background: #758BFD 0% 0% no-repeat padding-box;
        border-radius: 8px;
        border: none;
        color: #fff;
        padding: 12px 30px;
        font-size: 20px;
        margin-top: 30px;
    }
`;

export const ContainerBtns = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;

    display: flex;
    gap: 10px;

    button {
        background: #DCDCDC 0% 0% no-repeat padding-box;
        border-radius: 12px;
        border: none;
        padding: 7px 24px;
    }

    button:first-child {
        background: #00AEEF;
        color: #fff;
    }

    button:last-child {
        background: #00AEEF;
        color: #fff;
    }
`;