import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  ContainerButton2,
  Form,
  SelectInput,
  TextTitle,
  Textarea,
  Title,
} from "../../../styles/global";
import Panel from "../../../components/Panel";
import Radio from "../../../components/InputRadio";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { FormHandles } from "@unform/core";
import organizeDataSelect from "../../../utils/organizeDataSelect";
import InputFilev2 from "../../../components/InputFilev2";
import SecondaryTable from "../../../components/SecondaryTable";
import { SaveWithProgress } from "../../../components/SaveCircularIntegration";
import useUsuario from "../../../actions/cruds/useUsuario";
import optionsBoolean from "../../../utils/optionsBoolean";
import useProjeto from "../../../actions/cruds/useProjeto";
import useProtocolo from "../../../actions/cruds/Protocolo/useProtocolo";
import useContratoGestao from "../../../actions/cruds/useContratoGestao";
import useGerencia from "../../../actions/cruds/useGerencia";
import useUnidade from "../../../actions/cruds/useUnidade";
import { GridColDef } from "@mui/x-data-grid";
import useProcesso from "../../../actions/cruds/Processos/useProcesso";
import { ButtonAddSide } from "../styles";
import useProcessoCategoria from "../../../actions/cruds/Processos/useProcessoCategoria";

const ProcessoCreate: React.FC = () => {
  const { usuarios, getUsers } = useUsuario();
  const { projetos, getProjetos, projeto, getProjetoId } = useProjeto();
  const { protocolos, getProtocolos, updateSituacaoProtocolo } = useProtocolo();
  const { contrGestao, getContratos } = useContratoGestao();
  const { categoriasProcessos, getCategoriasProcessos } = useProcessoCategoria();
  const { gerencias, getGerencias } = useGerencia();
  const { unidades, getUnidades } = useUnidade();
  const { createProcesso } = useProcesso();

  const [possuiProjeto, setPossuiProjeto] = useState<String>();
  const [processoRateado, setProcessoRateado] = useState<String>("false");
  const [contratoGestao, setContratoGestao] = useState<Object>([]);
  const [contratosGestaoList, setContratosGestaoList] = useState<Array<Object>>([]);
  const [idContratosGestao, setIdContratosGestao] = useState<Array<Number>>([]);
  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getUsers([{ label: 'employee_type_id', value: 1 }, { label: 'ativo', value: true }]);
    getProjetos([{ label: 'ativo', value: true }]);
    getProtocolos([{ label: 'ativo', value: true }]);
    getContratos([{ label: 'ativo', value: true }]);
    getCategoriasProcessos([{ label: 'ativo', value: true }]);
    getGerencias([{ label: 'ativo', value: true }]);
    getUnidades([{ label: 'ativo', value: true }]);
  }, []);

  useEffect(() => {
    formRef.current?.setFieldValue("demand_id", "");
    formRef.current?.getFieldRef("rateio").forEach((radio) => radio.checked = false);
    setProcessoRateado("false");
    setContratoGestao([]);
    setContratosGestaoList([]);
    setIdContratosGestao([]);
  }, [possuiProjeto]);

  useEffect(() => {
    if (projeto?.id) {
      setIdContratosGestao([]);
      formRef.current?.setFieldValue("demand_id", { value: projeto?.demand?.id, label: projeto?.demand?.codigo });
      formRef.current?.setFieldValue("rateio", `${projeto?.rateio}`);
      setContratosGestaoList(projeto?.project_lines)
    };
  }, [projeto]);

  useEffect(() => {
    const contratosGestaoData = organizeDataLinhasProjeto(contratosGestaoList);
    let contratosGestaoProjeto: number[] = [];
    contratosGestaoData.forEach((contrato: Object) => contratosGestaoProjeto.push(contrato.id));
    setIdContratosGestao(contratosGestaoProjeto);

  }, [contratosGestaoList])

  const organizeDataLinhasProjeto = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.management_contract.id,
          contratoGestao: d.management_contract.nome_completo,
        }));

        const codigosUnicos = {};
        data = data.reduce((aux, objeto) => {
          if (!codigosUnicos[objeto.id]) {
            codigosUnicos[objeto.id] = true
            aux.push(objeto);
          }
          return aux;
        }, []);

        return data;
      };
    };
    return [];
  };


  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true);

        if (idContratosGestao.length > 0) {
          data["management_contract_ids"] = idContratosGestao;
        };

        const codigoProtocolo = formRef.current?.getFieldRef("demand_id").props.value.label;
        const idProtocolo = formRef.current?.getFieldRef("demand_id")?.props?.value?.value;
        data["codigo"] = codigoProtocolo;
        data["status"] = "em_andamento";

        console.log("data", data);
        await createProcesso(data);

        await updateSituacaoProtocolo(idProtocolo, { "situacao": "em_andamento" });
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    }, [idContratosGestao, possuiProjeto]
  );

  const handlerChangeProjeto = (projeto: object) => {
    if (projeto?.value) {
      getProjetoId(projeto.value);
    } else {
      formRef.current?.setFieldValue("demand_id", "");
    };
  };

  const handlerAddContratoGestao = () => {
    if (contratosGestaoList.find((contrato) => contrato.management_contract.id == contratoGestao.management_contract.id)) {
      return;
    }
    setContratosGestaoList([...contratosGestaoList, contratoGestao]);
  };

  const dataTitleContratoGestao: GridColDef[] = [
    { field: "contratoGestao", headerName: "Contrato de Gestão", flex: 2 },
  ];

  return (
    <Container>
      <Form id="formProcesso" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Cadastrar solicitação de abertura de Processo</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Solicitante:</TextTitle>
              <SelectInput
                name="solicitante_id"
                placeholder="Selecione..."
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: 'nome', value: e }, { label: 'employee_type_id', value: 1 }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Esse processo possui um projeto?</TextTitle>
              <Radio name="possui_projeto" options={optionsBoolean} onChange={(e) => setPossuiProjeto(e.target.value)} required />
            </Grid>
            {possuiProjeto == 'true' ?
              <>
                <Grid xs={6}>
                  <TextTitle>Código do Projeto:</TextTitle>
                  <SelectInput
                    name="project_id"
                    placeholder="Selecione..."
                    options={organizeDataSelect(projetos.data, "id", "id", ["nome"])}
                    onInputChange={(e) => {
                      getProjetos([{ label: 'id', value: e }, { label: 'ativo', value: true }]);
                    }}
                    onChange={(e) => handlerChangeProjeto(e)}
                    required
                  />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Código do Protocolo:</TextTitle>
                  <SelectInput name="demand_id" placeholder="" options={[]} isDisabled />
                </Grid>
              </>
              : possuiProjeto == 'false' ?
                <Grid xs={6}>
                  <TextTitle>Código do Protocolo:</TextTitle>
                  <SelectInput
                    name="demand_id"
                    placeholder="Selecione..."
                    options={organizeDataSelect(protocolos.data, "id", "codigo")}
                    onInputChange={(e) => {
                      getProtocolos([{ label: 'codigo', value: e }, { label: 'ativo', value: true }]);
                    }}
                    required
                  />
                </Grid>
                : null}
          </Grid>
        </Panel>

        <Panel titulo="Competências">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Esse processo é um rateio?</TextTitle>
              <Radio
                name="rateio"
                options={[{ id: "true", value: "true", label: "Sim" }, { id: "false", value: "false", label: "Não" }]}
                disabled={possuiProjeto == "true"}
                onChange={(e) => setProcessoRateado(e.target.value)}
                required
              />
            </Grid>
            {possuiProjeto == 'false' && (
              <Grid xs={6}>
                <TextTitle>Contrato de Gestão:</TextTitle>
                <SelectInput
                  name="management_contract_ids"
                  placeholder="Selecione..."
                  options={organizeDataSelect(contrGestao.data, "id", "nome_completo")}
                  onInputChange={(e) => {
                    getContratos([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                  }}
                  onChange={(e) => setContratoGestao({ management_contract: { id: e.value, nome_completo: e.label } })}
                  required
                />
              </Grid>
            )}
            {possuiProjeto == 'false' && processoRateado == "true" && (
              <Grid xs={6}>
                <ButtonAddSide type="button" onClick={() => handlerAddContratoGestao()}>
                  <FontAwesomeIcon icon={faPlus} /> Adicionar
                </ButtonAddSide>
              </Grid>
            )}
            <Grid xs={6}>
              {contratosGestaoList.length ?
                <SecondaryTable
                  columsData={dataTitleContratoGestao}
                  rowsData={organizeDataLinhasProjeto(contratosGestaoList)}
                />
                : contratosGestaoList && formRef.current?.getFieldValue("project_id") ? <p>Não há Contrato de Gestão cadastrado nesse Projeto.</p> : null
              }
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Processo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Categoria do Processo:</TextTitle>
              <SelectInput
                name="law_process_category_id"
                placeholder="Selecione..."
                options={organizeDataSelect(categoriasProcessos.data, "id", "nome")}
                onInputChange={(e) => {
                  getCategoriasProcessos([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Assunto do Processo:</TextTitle>
              <Textarea name="assunto_processo" required />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados Institucionais">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Gestor do processo:</TextTitle>
              <SelectInput
                name="gestor_processo_id"
                placeholder="Selecione..."
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: 'nome', value: e }, { label: 'employee_type_id', value: 1 }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Gestor substituto do processo:</TextTitle>
              <SelectInput
                name="gestor_substituto_id"
                placeholder="Selecione..."
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: 'nome', value: e }, { label: 'employee_type_id', value: 1 }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Área responsável:</TextTitle>
              <SelectInput
                name="administration_id"
                placeholder="Selecione..."
                options={organizeDataSelect(gerencias.data, "id", "nome")}
                onInputChange={(e) => {
                  getGerencias([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Local:</TextTitle>
              <SelectInput
                name="unit_id"
                placeholder="Selecione..."
                options={organizeDataSelect(unidades.data, "id", "nome")}
                onInputChange={(e) => {
                  getUnidades([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Processo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Documentos de Formalização:</TextTitle>
              <Textarea name="documento_abertura" placeholder={`Informe os documentos referentes à abertura do Processo. Ex: Carta de solicitação da demanda do Comitê que justifique a abertura desse processo.\nCaso não haja um documento, insira uma breve informação que justifique sua abertura.\nObs: A carta ou documento correlatado deverá ser cadastrada e inserida no sistema Protón.`} required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Documento: <span>*</span></TextTitle>
              <InputFilev2 name="processo_arquivo" required />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Observação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Observação:</TextTitle>
              <Textarea name="observacao" placeholder={`Esse campo deverá ser preenchido caso haja alguma observação relevante que deva constar na Ficha de Abertura do Processo.\nEx: Número de um processo que deu origem à abertura desse novo processo.`} />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/processos">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress form={"formProcesso"} disabled={salvando} ></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default ProcessoCreate;
