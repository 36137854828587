import {
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";


import {
  ContainerButton, Form, FormContainer, Input, TextTitle, Title
} from '../../../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import useDescricao from "../../../../../actions/cruds/useDescricao";
import Panel from "../../../../../components/Panel";
import { Container } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';


interface DescricaoProps{
  codigo: string;
  nome: string;
}

export function RegDescricao() {

  const formRef = useRef<FormHandles>(null);


  const { createDescricao} = useDescricao();

  const handleSubmit = useCallback(
    async(data: DescricaoProps) => {
      try{
        createDescricao(data);
      }catch(err) {
        alert(err);
      }
    },
    [],
  );

  return (
    <Container>
          <Title color='#333'>
            <span>
              Cadastrar descrição
            </span>
          </Title>
          <Form id="form1" ref={formRef} onSubmit={handleSubmit}>
            <Panel titulo="Descrição">
              <Grid container spacing={6}>
                <Grid xs={2}>
                  <TextTitle>Código: <span>*</span></TextTitle>
                  <Input 
                    mask="___"
                    replacement="allNumber"
                    id="codigo" 
                    name='codigo' 
                    required
                  />
                </Grid>
                <Grid xs={10}>
                  <TextTitle>Descrição: <span>*</span></TextTitle>
                  <Input type="text" name='nome' required /> 
                </Grid>
              </Grid>                
            </Panel>
          </Form>
          <ContainerButton>
            <Link to="/orcamentario/cadastros/descricoes">
              <button type="button"><FontAwesomeIcon icon={faX} size="1x"/> Cancelar</button>
            </Link>
            <button type="submit" form="form1">
              <FontAwesomeIcon icon={faSave}/> Salvar
            </button>
          </ContainerButton>
    </Container>
  )
}