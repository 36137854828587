import { Container } from "@mui/material";
import { Form } from "@unform/web";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../../../components/Panel";
import {
  ContainerButton,
  Input,
  InputAddBtn,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave, faX } from "@fortawesome/free-solid-svg-icons";
import { FormHandles } from "@unform/core";
import Radio from "../../../../../components/InputRadio";
import useEndereco from "../../../../../actions/cruds/useEndereco";
import useOrgao from "../../../../../actions/cruds/useOrgao";
// import { Container } from './styles';

const OrganizacaoRhCreate: React.FC = () => {
  const { getAddress, endereco, setEndereco } = useEndereco();
  const { createOrganization } = useOrgao();

  const [selectedOption, setSelectedOption] = useState({});

  const handleChangeSelect = (selectedOption) => {
    // necessario para selecionar programaticamente uma opção do select
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    // necessario para selecionar programaticamente uma opção do select
    const selected = options.find((o) => o.value === endereco.uf);
    setSelectedOption(selected);
  }, [endereco]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: object) => {
    try {
      console.log(data);

      createOrganization(data);

      // addToast({
      //   type: 'success',
      //   title: 'Login efetuado',
      //   description: 'Seu login foi feito com sucesso!',
      // });

      // history.push('/dashboard');
    } catch (err) {
      // addToast({
      //   type: 'error',
      //   title: 'Erro na autenticação',
      //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
      // });
    }
  }, []);

  const radioOptions = [
    { id: "01", value: "matriz", label: " Matriz   " },
    { id: "02", value: "filial", label: " Filial" },
  ];

  const dataAddress = () => {
    const element = document.getElementById("cep");
    getAddress(element.value);
  };

  const options = [
    { value: "AC", label: "AC - Acre" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "BA", label: "BA - Bahia" },
    { value: "CE", label: "CE - Ceará" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "GO", label: "GO - Goiás" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "PA", label: "PA - Pará" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PI", label: "PI - Piauí" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RR", label: "RR - Roraima" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "EX", label: "EX - Estrangeiro" },
  ];

  return (
    <Container>
      <Title>
        <span>Cadastrar Organização</span>
      </Title>

      <Form id="form1" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Selecione o tipo da organização:</TextTitle>
              <Radio name="tipo" options={radioOptions} required />
            </Grid>
            <Grid xs={12} lg={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input 
                mask="__"
                replacement="allNumber"
                id="codigo" 
                name='codigo' 
                required
              />
            </Grid>
            <Grid xs={12} lg={4}>
              <TextTitle>CNPJ:</TextTitle>
              <Input type="text" name="cnpj" mask="cnpj" required />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Nome:</TextTitle>
              <Input type="text" name="nome" required />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>E-mail:</TextTitle>
              <Input type="email" name="email" required />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Telefone:</TextTitle>
              <Input type="text" name="telefone" mask="phone" />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: "relative" }}>
                <Input style={{ paddingRight: "150px" }} type="text" id="cep" name="cep" mask="cep" />
                <InputAddBtn type="button" onClick={dataAddress}>
                  <FontAwesomeIcon icon={faPlus} /> Pesquisar CEP
                </InputAddBtn>
              </div>
              
            </Grid>
            <Grid xs="auto">
              
            </Grid>

            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro:</TextTitle>
              <Input
                type="text"
                id="logradouro"
                name="logradouro"
                value={endereco.logradouro}
                onChange={(e) =>
                  setEndereco({ ...endereco, logradouro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro:</TextTitle>
              <Input
                type="text"
                id="bairro"
                name="bairro"
                value={endereco.bairro}
                onChange={(e) =>
                  setEndereco({ ...endereco, bairro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input type="text" id="numero" name="numero" />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input type="text" id="complemento" name="complemento" />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={endereco.localidade}
                onChange={(e) =>
                  setEndereco({ ...endereco, localidade: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF:</TextTitle>
              <SelectInput
                id="idEstado"
                name="uf"
                options={options}
                value={selectedOption}
                onChange={handleChangeSelect}
                placeholder="Selecionar"
              />
            </Grid>
          </Grid>
        </Panel>
        <ContainerButton>
          <Link to="/rh/cadastros/organizacao">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
            </button>
          </Link>
          <button type="submit" form="form1">
            <FontAwesomeIcon icon={faSave} /> Salvar
          </button>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default OrganizacaoRhCreate;
