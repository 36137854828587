import React from 'react';
import Panel from '../../../components/Panel';
import { ContainerBtns } from './styles';
// import { Button } from '../../components/Button/Button';
import Header from '../../../components/Header';
import { CSidebar } from '../../../components/Sidebar';
import Table2 from '../../../components/Table2/Table2';
import useRepresentanteInstituicao from '../../../actions/cruds/useRepresentanteInstituicao';
import { Container } from '@mui/material';
import { Title } from '../../../styles/global';
import { CsvBtn } from '../../members/Relatorios/styles';

const RelatorioRepresentantes: React.FC = () => {

  const {getRepresentantesXls} = useRepresentanteInstituicao();

  const data = [
  {
    "codigo": 1,
    "usuario": "lucas.pereira",
    "nome": "Lucas Pereira",
    "email": "lucas.perera@gmail.com",
    "orgao": "AGEVAP",
    "ultimoacesso": "19/01/2022",
    "status": "Ativo",
    "acoes": ""
  },
  {
    "codigo": 2,
    "usuario": "dias.lucas",
    "nome": "Dias Lucas",
    "email": "dias.lucas@gmail.com",
    "orgao": "TESTE",
    "ultimoacesso": "20/01/2022",
    "status": "Inativo",
    "acoes": ""
  }
];

  const handleSimpleXls = () => {
    getRepresentantesXls();
  }

  return(
    <Container>
        <Title>
          <span>Relatórios de Representantes</span>
        </Title>

        <Panel titulo='Relatório simples'>
          <p>Relatório rápido com todas as colunas:</p>
          <div style={{width:"100%"}}>
            {/* <a href={instituicoesXls}> */}
              <CsvBtn className='csv' type="button" onClick={handleSimpleXls}>Excel</CsvBtn>
            {/* </a> */}
          </div>
        </Panel>

        {/* <Panel titulo='Relatório dinâmico'>
          <p>Selecione as colunas que deseja mostrar no relatório:</p>

          <ContainerBtns>
            <button type="button">Nome</button>
            <button type="button">Email</button>
            <button type="button">Endereço</button>
            <button type="button">Setor</button>
            <button type="button">Telefone</button>
          </ContainerBtns>

          <div style={{width:"100%", textAlign: "right"}}>
            <button className='filtrar' type="button">Filtrar</button>
          </div>

          <hr style={{margin: '30px 0'}} />

          <div style={{width:"100%"}}> */}
            {/* <button className='csv' type="button">Excel</button> */}
          {/* </div>

          <Table2 headers={[]} data={data} />
          
        </Panel> */}
    </Container>
  );
};

export default RelatorioRepresentantes;