import React, { useEffect, useRef, InputHTMLAttributes, useState } from "react";
import { useField } from "@unform/core";
import { LabelInput, RadioContainer, RadioInput } from "./styles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  checkedValue?: boolean | string | null;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
  required?: boolean;
}

const Radio: React.FC<Props> = ({ name, options, checkedValue, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = "" } = useField(name);
  const [checked, setChecked] = useState<string | null>(String(checkedValue));

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find((ref) => ref.checked)?.value || "";
      },
      setValue: (refs: HTMLInputElement[], id: string) => {
        const inputRef = refs.find((ref) => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs: HTMLInputElement[]) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  useEffect(() => {
    setChecked(String(checkedValue));
  }, [checkedValue]);

  return (
    <>
      <RadioContainer>
        {options.map((option, index) => (
          <LabelInput htmlFor={option.id} key={option.id}>
            <RadioInput
              ref={(ref) => ref && (inputRefs.current[index] = ref)}
              id={option.id}
              type="radio"
              name={name}
              // checked={String(option.value) == checked}
              defaultChecked={option.value == String(checkedValue)}
              value={option.value}
              {...rest}
            />
            <span style={{ marginLeft: 4 }}>{option.label}</span>
          </LabelInput>
        ))}
      </RadioContainer>
    </>
  );
};

export default Radio;
