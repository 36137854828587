import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";
import { useToast } from "../../../hooks/toast";

const useFerias = () => {

    const history = useHistory();
    const { addToast } = useToast();
    const [feriasList, setFeriasList] = useState([]);
    const [ferias, setFerias] = useState([]);
    const [error, setError] = useState(null);

    async function getFeriasList(params?: object) {
        try {
            const response = await api.get(`user_vacations${stringfyParams(params)}`);
            setFeriasList(response.data);
        } catch (e) {
            setError(e);
        }
    };

    async function getFerias(id: string) {
        try {
            const response = await api.get(`user_vacations/${id}`);
            setFerias(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    };

    async function createFerias(data: any) {
        try {
            const response = await api.post('user_vacations', data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            addToast({
                type: 'success',
                title: 'Férias cadastrada!',
                description: 'Férias cadastrada com sucesso!',
            });
            history.goBack();

        } catch (e) {
            setError(e);
        }
    };

    async function updateFerias(id: string, data: any) {
        try {
            const response = await api.put(`user_vacations/${id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            addToast({
                type: 'success',
                title: 'Férias atualizada!',
                description: 'Férias atualizada com sucesso!',
            });
            history.goBack();

        } catch (e) {
            setError(e);
        }
    };


    return { feriasList, ferias, getFeriasList, getFerias, createFerias, updateFerias, error };
};

export default useFerias;