import {
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import {
  ContainerButton, Form, FormContainer, Input, TextTitle, Title
} from '../../../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef } from "react";
import Panel from "../../../../../components/Panel";
import { Container, useForkRef } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Link, useParams } from "react-router-dom";
import useDescricao from "../../../../../actions/cruds/useDescricao";



export function EditDescricao() {

  const {idDescricao} = useParams();

  const {getDescricaoId, selectDescricao, updateDescricao} = useDescricao();

  useEffect(() => {
    getDescricaoId(idDescricao);
  }, []);

  useEffect(() => {
    formRef.current?.setFieldValue("codigo", selectDescricao.codigo);
  }, [selectDescricao]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async(data: object) => {
      try{
        updateDescricao(idDescricao, data);
      }catch(err) {

      }
    },
    [],
  );

  return (
    <Container>
      <Title color='#333'>
        <span>
          Editar descrição
        </span>
      </Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Descrição">
          <Grid container spacing={6}>
            <Grid xs={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              {/* <Input type="text" name='codigo' defaultValue={selectDescricao.codigo} required/> */}
              <Input 
                mask="___"
                replacement="allNumber"
                id="codigo" 
                name='codigo' 
                required
              />
            </Grid>
            <Grid xs={10}>
              <TextTitle>Descrição: <span>*</span></TextTitle>
              <Input type="text" name='nome' defaultValue={selectDescricao.nome} required /> 
            </Grid>
          </Grid>                 
        </Panel>

        <ContainerButton>
          <Link to="/orcamentario/cadastros/descricoes">
            <button type="button"><FontAwesomeIcon icon={faX} size="1x"/> Cancelar</button>
          </Link>
          <button type="submit"><FontAwesomeIcon icon={faSave}/> Salvar</button>
        </ContainerButton>
      </Form>
    </Container>
  )
}