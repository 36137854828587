import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    tableContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderTopWidth: 2,
        borderLeftWidth: 2,
        borderRightWidth: 2,
        borderColor: '#000',
    },
});