import { faBell, faCircleUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, Redirect, Router, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { Container, ContentContainer, ContentMenu, SelectOrg } from './styles';
import { withRouter } from 'react-router-dom';


const Header: React.FC = () => {

    const {login, signOut} = useAuth();

    const history = useHistory();

    const [isUserSubmenuOpen, setIsUserSubmenuOpen] = useState(false);

    const toggleUserSubmenu = () => {
        
        setIsUserSubmenuOpen(!isUserSubmenuOpen);
        console.log(isUserSubmenuOpen);
      };

    const handleRedirect = () => {
        history.push(`/edit-my-user/${login.id}`);
    };  

    return (
        <Container className='superiorMenuContainer'>
            <ContentContainer>
                <ContentMenu>
                    <SelectOrg
                        name="color"
                        options={[{label: "AGEVAP", value: 1}, {label: "AGEDOCE", value: 2}, {label: "Todas as organizações", value: 3}]}
                        placeholder="Selecione uma organização"
                    />

                    <Link to="#">
                        <FontAwesomeIcon icon={faBell} size="2xl" />
                    </Link>
                    
                    <Link to="#" onClick={toggleUserSubmenu} style={{display: "flex", flexDirection: "column"}}>
                         <FontAwesomeIcon icon={faCircleUser} size="2xl" />
                         {isUserSubmenuOpen && (
                            <div style={{
                                backgroundColor: "white", 
                                padding: "0",
                                marginTop: "50px",
                                position: "absolute",
                                border: "1px solid #ccc",
                                width: "10%",
                                height: "7%"
                            }}>
                                <div onClick={handleRedirect} style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    fontSize:"20px",
                                    position:"inherit"
                                      }}>
                                        Meu cadastro
                                </div>
                            </div>
                         )}
                    </Link>
                        
                    <p>{login.nome}</p>

                    <Link to="#" className='logout' onClick={signOut}>
                        <FontAwesomeIcon icon={faRightFromBracket} size="2xl" />
                    </Link>
                    
                </ContentMenu>
            </ContentContainer>
        </Container>
    )
};

export default withRouter(Header);