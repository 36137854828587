import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";


const useProjeto = () => {
  const history = useHistory();
  const [projetos, setProjetos] = useState([]);
  const [projeto, setProjeto] = useState({
    project_lines: [],
    project_documents: [],
    demand: {}
  });
  const [ error, setError] = useState(null);

  // Lista todos os Projetos
  async function getProjetos(params?: object){
    try{
      const response = await api.get(`projects${stringfyParams(params)}`);
      setProjetos(response.data);
    } catch(e){
      setError(e);
    }
  }

  // Lista o projeto por ID
  async function getProjetoId(id: string){
    try{
      const response = await api.get(`projects/${id}`);

      setProjeto(response.data);

      // return response.data;
    } catch(e){
      setError(e);
    }
  }

  // Cria um novo projeto 
  async function createProjeto(data: any) {
    try {
      await api.post('projects', data);
      alert("Projeto criado!");
      history.goBack();

    } catch(e) {
      setError(e);
    }
  } 

  async function updateProjeto(id: any, data: any){
    try{
      const response = await api.put(`projects/${id}`, data);

      alert("Projeto atualizado!");
      history.goBack();
    }catch(e){
      setError(e);
    }
  }

  async function desativarProjeto(id: any, data: any){
    
    const formData = new FormData();

    formData.append('id', id);
    formData.append('ativo', data);

    try{
      console.log('Erro!');

      const response = await api.put(`projects/${id}`, formData);
      
      // console.log('funcao data', data);
      
      window.location.reload();

      return response.data;
    }
    catch(e){
      setError(e);
    }
  }

  return { projetos, desativarProjeto, updateProjeto, getProjetos, getProjetoId, createProjeto, projeto, error };
}

export default useProjeto;