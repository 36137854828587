import React, { useEffect, useState } from "react";

import { Container } from "./styles";
import { ContainerButton2, Title } from "../../../../styles/global";
import MainTable from "../../../../components/MainTable";
import { formatarData } from "../../../../utils/formatDados";
import useFerias from "../../../../actions/cruds/Rh/useFerias";
import { Link, useParams } from "react-router-dom";
import useUsuario from "../../../../actions/cruds/useUsuario";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faX } from "@fortawesome/free-solid-svg-icons";

const RhFerias: React.FC = () => {
  const { empregadoId } = useParams();
  const { usuario, getUser } = useUsuario();
  const { feriasList, getFeriasList } = useFerias();

  const [searchValue, setSearchValue] = useState<string>("");
  const [perPage, setPerPage] = useState<number>()

  const optionsSituacao = [
    { label: "Aguardando confirmação", value: "aguardando_confirmacao"},
    { label: "Concluído", value: "concluido" },
    { label: "Cancelado", value: "cancelado" },
  ];

  useEffect(() => {
    getUser(empregadoId)
  }, [])

  console.log(feriasList);

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      getFeriasList([{label: "user_id", value: empregadoId}, { label: "per", value: perPage }]);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getFeriasList([{label: "user_id", value: empregadoId}, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getFeriasList([{label: "user_id", value: empregadoId}, { label: "per", value: perPage }, { label: "page", value: feriasList.pagination.current_page + page }]);
  };

  const organizeData = (data: any[]) => {
    const withoutData = <i>Sem dados</i>;
    if (typeof data === "object") {
      data = data.map((item) => {
        return {
          id: item?.id,
          data_admissao: formatarData(item?.user?.data_admissao, false) ?? withoutData,
          periodo_aquisitivo: `${formatarData(item?.periodo_aquisitivo_de, false)} a ${formatarData(item?.periodo_aquisitivo_ate, false)}` ?? withoutData,
          periodo_gozo: `${formatarData(item?.periodo_gozo_de, false)} a ${formatarData(item?.periodo_gozo_ate, false)}` ?? withoutData,
          mes_saida: new Date(item?.inicio).getMonth() ?? withoutData,
          quantidade_dias: item?.quantidade_dias ?? withoutData,
          data_inicio: formatarData(item?.inicio, false) ?? withoutData,
          data_fim: formatarData(item?.termino, false) ?? withoutData,
          adiantamento: item?.adiantamento ? "Sim" : "Não",
          situacao: item.situacao ? optionsSituacao.find(o => o.value == item?.situacao)?.label : withoutData,
          ativo: item.ativo,
        };
      });
    }
    return data;
  };

  useEffect(() => {

  }, [feriasList])

  const columnDataEmpregado = [
    { headerName: "Data Admissão", field: "data_admissao" },
    // { headerName: "Período aquisitivo", field: "periodo_aquisitivo" },
    // { headerName: "Período para gozo", field: "periodo_gozo" },
    // { headerName: "Mês de saída", field: "mes_saida" },
    // { headerName: "Nº de Dias", field: "quantidade_dias" },
    { headerName: "Início", field: "data_inicio" },
    { headerName: "Fim", field: "data_fim" },
    // { headerName: "Adiantamento 13º", field: "adiantamento" },
    { headerName: "Situação", field: "situacao" },
    { headerName: "Status", field: "ativo" },
  ];

  const columnDataEstagiario = [
    { headerName: "Data Admissão", field: "data_admissao" },
    // { headerName: "Período aquisitivo", field: "periodo_aquisitivo" },
    // { headerName: "Período para gozo", field: "periodo_gozo" },
    // { headerName: "Mês de saída", field: "mes_saida" },
    // { headerName: "Nº de Dias", field: "quantidade_dias" },
    { headerName: "Início", field: "data_inicio" },
    { headerName: "Fim", field: "data_fim" },
    { headerName: "Situação", field: "situacao" },
    { headerName: "Status", field: "ativo" },
  ];

  return (
    <>
      <Title color="#333">
        <span>Férias do empregado - {usuario?.nome}</span>
      </Title>
      <Container color="#FFF">
        <MainTable
          columns={usuario?.employee_type?.key == 'empregado' ? columnDataEmpregado : (usuario?.employee_type?.key == 'estagiario' || usuario?.employee_type?.key == 'jovem_aprendiz') ? columnDataEstagiario : columnDataEmpregado}
          data={organizeData(feriasList.data)}
          pagination={feriasList.pagination}
          isMaxWidthCell
          handleItemsPerPage={handleItemsPerPage}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          registerLink={`/rh/empregados/ferias/cadastrar/${empregadoId}`}
          dropdown={{
            view: { linkTo: "/rh/empregados/ferias/visualizar" },
            editar: { linkTo: "/rh/empregados/ferias/editar" },
            confirmarFerias: { linkTo: "/rh/empregados/ferias/confirmar" },
            cancelar: { linkTo: "user_vacations", activateSituacao: "aguardando_confirmacao" },
          }}
        />
      </Container>
      <ContainerButton2>
        <Link to={`/rh/empregados`}>
          <button className="button_return">
            <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
          </button>
        </Link>
      </ContainerButton2>
    </>
  );
};

export default RhFerias;
