import styled from 'styled-components';

export const Title = styled.div`
   color: ${props => props.color};
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  margin-left: 2rem;
  text-align: start;
  float: left; 
  display: grid;
  
  span{
    font-size: 1.8rem;
    font-weight: 600;
  }

  input{
    width: 70%;
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;
    border: #ccc solid 1px;
    float: left;
    padding: 10px 20px 10px 15px;
  }

  button{
    position: absolute;
    align-items: flex-end;
    margin: 2.2rem 0px 0px 36.5rem;
    background: transparent;
    font-weight: 600;
    cursor: pointer;
    border: none;
  }
  button:hover{
    border: none;
  }
`;

export const Container = styled.div`
    display: flex; 
    width: 95%;
    height: 100%;
    margin: 3rem;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 60px 20px #00000014;
    opacity: 1;
    background: ${props => props.color};
    flex-direction: column;
`;