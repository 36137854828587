import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useContaBancaria = () => {

    const history = useHistory();
    const [contaBancaria, setContaBancaria] = useState([]);
    const [contasBancarias, setContasBancarias] = useState([]);
    const [error, setError] = useState(null);

    async function getContasBancarias(params?: object) {
        try {
            const response = await api.get(`bank_accounts${stringfyParams(params)}`);
            setContasBancarias(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getContaBancaria(id: string) {
        try {
            const response = await api.get(`bank_accounts/${id}`);
            // console.log(response);
            setContaBancaria(response.data);
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createContaBancaria(data: any) {
        try {
            const response = await api.post('bank_accounts', data);
            alert("Conta bancária cadastrada com sucesso!");
            history.push("/financeiro/cadastros/contabancaria");

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro ao cadastrar Conta bancária");
            setError(e);
            // console.log(error);
        }
    }
 
    async function updateContaBancaria(id: string, data: any) {
        try {
            const response = await api.put(`bank_accounts/${id}`, data);
            alert("Conta bancária atualizada com sucesso!");
            history.push("/financeiro/cadastros/contabancaria");

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro ao atualizar Conta bancária");
            setError(e);
            // console.log(error);
        }
    }


    return { contaBancaria, contasBancarias, getContaBancaria, getContasBancarias, createContaBancaria, updateContaBancaria, error };
}

export default useContaBancaria;