// Ex(multiplos parametros caso necessario): params = [{label: 'comittee_id', value: '1'}, {label: 'comittee_nome', value: 'teste'}]. Transforma em ums string '?comittee_id=1'.
const stringfyParams = (params) => {
    if (!params) {
        return '';
    }
    let stringParams = '';
    params.forEach(p => stringParams += `${p.label}=${p.value}&`);

    return `?${stringParams}`;
};
export default stringfyParams;