import { Form as Unform } from "@unform/web";
import styled, { createGlobalStyle } from "styled-components";
import { CreatableSelectInputInput } from "../components/CreatableSelect";
import { TextInputInput } from "../components/Input/Input";
import { SelectInputInput } from "../components/Select";
import { Textarea as TextInput } from "../components/Textarea/Textarea";
import Select from 'react-select';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  body {
    color: #414141;
    -webkit-font-smoothing: antialiased;
    background-color: #F4F6F9;
  }
  body, input, button, textarea {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  button {
    cursor: pointer;
  }
  .muiDataGridCellOverflowVisible {
    overflow: visible!important;
  }

  .displayOnPrint{
    display: none;
  }

  @media print {
    * {
        background: transparent;
        color: #000;
        text-shadow: none; 
        filter: none;
        -ms-filter: none;
        /* -webkit-print-color-adjust: exact; */ // Assim  fica bonito mas em caso de impressao preto e branco pode ficar confuso
    }

    .no-print {
      display: none!important;
    }

    .displayOnPrint{
      display: block!important;
      -webkit-print-color-adjust: exact;
    }

    .lateralMenuContainer {
      display: none;
    }

    .superiorMenuContainer {
      display: none;
    }
  }
`;

export const Form = styled(Unform)`
  > div > div {
    /* margin-bottom: 25px; */
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  /* padding: 20px; */

  .input_instrumentos {
    margin-top: 26px;
  }

  > div {
    display: flex;

    > div {
      width: 100%;

      /* justify-content: space-between; */
      /* padding-right: 2rem;
      padding-bottom: 1rem; */
      padding-top: 1rem;

      > div {
        /* padding-bottom: 1rem; */

        > div {
          .icontrash {
            float: right;
            color: red;
          }
        }
      }
    }
  }

  .selectInput {
    margin: 1.65rem 0 0 0;
    /* background: #333; */
  }

  .uploadArq {
    background: #e3e3e3;
    /* width: 65rem; */
    border-radius: 8px;
    cursor: pointer;
    padding: 3rem;
    display: flex;

    p {
      text-align: center;
      margin: auto;
      padding: 1rem 0 0 0;
    }
    span {
      width: 15rem;
      padding: 0 0 0 2rem;
      word-break: break-all;
    }
  }
  .inputUpload {
    display: none;
  }
  .documentos {
    padding: 2rem 0 0 0rem;
    table {
      width: 100%;
      thead {
        tr {
          > td:nth-child(1) {
            float: left;
            font-weight: 600;
          }
          > td:nth-child(2) {
            text-align: center;
            font-weight: 600;
          }
        }
      }
      tbody {
        border: 1px solid #e3e3e3;
        border-bottom: 1px solid;
        tr {
          > td:nth-child(1) {
          }
          > td:nth-child(2) {
            text-align: center;
          }
          td {
            padding: 1rem 0 1rem 0;
            border-bottom: 1px solid #c4c4c4;
            > a:nth-child(1) {
              text-decoration: none;
              color: #366efa;
              padding-right: 1rem;
            }
            > a:nth-child(2) {
              text-decoration: none;
              color: #ff000099;
            }
          }
        }
      }
    }
  }

  .ButtonPesquisa {
    input {
      width: 90%;
      padding: 1rem 1rem 1rem 1rem;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
  }
`;

export const ButtonAdd = styled.button`
  background: #23b5d3;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.6rem;
  margin: 20px 0 20px;
`;

export const InputAddBtn = styled(ButtonAdd)`
  height: calc(100% - 10px);
  margin: 10px 0 0;
  position: absolute;
  top: 0;
  right: 0;
`;

export const Container = styled.div`
  margin: 35px 70px;
`;

export const Input = styled(TextInputInput)`
  height: 40px;
  width: 100%;
  /* max-width: 450px; */
  padding: 1rem;
  margin: 10px 0 0;
  font-size: 14px;
  border-radius: 8px;

  border: 1px solid #c4c4c4;
  background-color: #ffffff;
  box-shadow: 10px 2px 2px solid #333;

  &:disabled {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
`;

export const Textarea = styled(TextInput)`
  min-height: 40px;
  width: 100%;
  /* max-width: 450px; */
  padding: 1rem;
  margin: 10px 0 0;
  font-size: 14px;
  border-radius: 8px;

  border: 1px solid #c4c4c4;
  background-color: #ffffff;
  box-shadow: 10px 2px 2px solid #333;

  &:disabled {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
`;

export const SelectInput = styled(SelectInputInput)`
  width: 100%;
  /* max-width: 450px; */
  /* padding: 0.3rem 0 0.5rem 0rem; */
  margin: 10px 0 0 0;
  /* border-radius: 8px; */
  opacity: 1;

  .react-select__control {
    border-radius: 8px;
    height: 40px;
  }
`;

export const CreatableSelectInput = styled(CreatableSelectInputInput)`
  width: 100%;
  /* max-width: 450px; */
  /* padding: 0.3rem 0 0.5rem 0rem; */
  margin: 10px 0 0 0;
  /* border-radius: 8px; */
  opacity: 1;

  .react-creatable-select__control {
    border-radius: 8px;
    height: 40px;
  }
`;

export const SelectInputReact = styled(Select)`
  width: 100%;
  /* max-width: 450px; */
  /* padding: 0.3rem 0 0.5rem 0rem; */
  margin: 10px 0 0 0;
  /* border-radius: 8px; */
  opacity: 1;

  .react-select__control {
    border-radius: 8px;
    height: 40px;
  }
`;

export const TextTitle = styled.p`
  margin-bottom: 0;
  span {
    color: #e62121;
  }
`;

export const Title = styled.div`
  color: ${(props) => props.color};
  /* padding-top: 2rem; */
  padding-bottom: 2rem;
  /* padding-left: 2rem; */
  /* margin-left: 2rem; */
  text-align: start;
  /* float: left;  */
  display: grid;

  span {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .displayOnPrint {
    display: none!important;
  }
`;

export const InputButtonDownload = styled(ButtonAdd)`
  background: #1473e6;
  height: calc(100% - 10px);
  margin: 10px 0 0;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ButtonDownloadArch = styled.button`
  background: #1473e6;
  height: 40px;
  max-width: 100%;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.6rem;
  margin: 10px 0 0;
  cursor: pointer; 
  display: inline-block;
`;

export const ButtonUploadArch = styled.label`
  /* width: 70%; */
  margin: 10px 0rem 1rem;
  /* float: right; */
  background: #1473e6;
  color: white;
  border: none;
  border-radius: 16px;
  padding: 1rem;
  cursor: pointer;
  display: inline-block;
`;

export const ButtonUploadImg = styled.label`
  width: 100%;
  margin: 0;
  /* float: right; */
  background: #1473e6;
  color: white;
  border: none;
  border-radius: 0 0 16px 16px;
  padding: 1rem;
  cursor: pointer;
  display: inline-block;
`;

export const ContainerButton = styled.div`
  button {
    padding: 10px;
    cursor: pointer;
  }
  button:nth-child(1) {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 8px;
  }
  button:nth-child(2) {
    float: right;
    color: #fff;
    background: #366efa 0% 0% no-repeat padding-box;
    border: 2px solid #366efa;
    border-radius: 8px;
  }
  button:nth-child(3) {
    float: right;
    color: #fff;
    background: #12b851 0% 0% no-repeat padding-box;
    border: 2px solid #12b851;
    border-radius: 8px;
    margin-right: 20px;
  }
`;

export const Instancia = styled.div`
  display: flex;
  margin: 2.4rem 0rem 0rem 0px;
  padding: 0 0 0 0;
  letter-spacing: 0.1rem;
  width: 60% !important;

  div {
    margin-right: 5rem;

    input {
      margin-right: 0.2rem;
    }
  }
`;

// CSS GLOBAL TABLE 2

export const ButtonRegister = styled.button`
  color: #fff;
  font-weight: 600;
  background: #12b851;
  padding: 16px;
  height: 46px;
  margin-left: 1rem;
  border: none;
  border-radius: 8px;

  a {
    display: flex;
    color: white;
    text-decoration: none;
    border: none;
  }
`;

export const ButtonSearch = styled.button`
  position: relative;
  background-color: transparent;
  height: 46px;
  left: -3rem;
  padding-left: 16px;

  border: 1px solid #ccc;
  border-right: none;
  border-top: none;
  border-bottom: none;
`;

export const Table = styled.div`
  border-collapse: collapse;

  justify-content: center;
  align-items: center;
  font-size: 0.2rem;
  text-transform: capitalize;

  .search_container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .td_codigo {
    position: relative;
    left: 20px;
  }

  .td_cpf {
    font-weight: 600;
  }

  .td_nome_razao {
    color: #505050;
    text-align: left;
  }

  .td_data {
    color: #505050;
  }

  .td_status {
    position: relative;
    left: 10px;
    font-weight: 600;
    font-size: 14px;
  }

  input {
    width: 100%;
    height: 46px;
    font-size: 1.2rem;
    padding: 16px;
    border-radius: 0.4rem;
    border: 1px solid #cccccc;
  }

  table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;

    border-bottom: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    box-shadow: 1px 1px 5px #cccccc;
  }
  .buttonFiltro {
    display: flex;
    color: #fff;
    font-weight: 600;
    background: #00aeef;
    padding: 16px;
    height: 46px;
    border: none;
    border-radius: 8px;
  }
  thead {
    background: ${(props) => props.color};
    tr {
      td {
        color: white;
        font-weight: 600;
      }
    }
  }

  tbody {
    width: 100%;
    background-color: white;

    tr {
      display: tablecell;
      td {
        color: #414141;
        > button {
          border: 1px #cccccc solid;
        }
      }
    }

    button {
      background: none;
      border: none;
      font-size: 0.9rem;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      border-radius: 0.4rem;
    }
    .menu {
      position: absolute;
      justify-content: center;
      list-style-type: none;
      margin: 5px 0;
      box-shadow: 0px 4px 15px 13px rgba(0, 0, 0, 0.1);
      /* border: 1px solid gray; */
    }
    .menu > li {
      margin: 0;

      background-color: #fff;
    }
    .menu > li:hover {
      background-color: lightgray;
    }

    .menu > li > button {
      width: 100%;
      height: 100%;
      color: black;
      text-align: left !important;
      padding-left: 0 !important;
      border: none;
      cursor: pointer;
    }
    .menu > li > button > button {
      span {
        padding-left: 1rem;
        font: inherit;
        font-size: 1rem;
        font-weight: 500;
      }
    }
    .menu > li > a > button {
      width: 100%;
      height: 100%;
      text-align: left;

      background: none;
      color: inherit;
      border: none;
      padding: 5px;
      margin: 0;
      font: inherit;
      cursor: pointer;
      color: black;

      span {
        padding-left: 1rem;
      }
    }
  }

  tr {
    border-bottom: 1px solid #cccc;
  }

  th,
  td {
    padding: 0.8rem;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    color: #202020;
  }

  .sort-button {
    background-color: transparent;
    border: none;

    padding: 5px 10px;
    margin: 0;
    line-height: 1;
    font-size: 15px;
    color: #ddd;
    cursor: pointer;

    transition: transform 0.05s ease-out;
  }
`;

export const ContainerButton2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  div {
    width: auto;
  }

  .button_return {
    background-color: #ffffff;
    border: 1px solid #707070;
    border-radius: 6px;
    padding: 10px;
  }

  .button_save {
    background-color: #37b5d3;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 12px 16px;
  }

  .button_next {
    background-color: #366efa;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 12px 16px;
    margin-left: 32px;
  }

  .button_post {
    padding: 10px;
    cursor: pointer;
    color: #fff;
    background: #366efa 0% 0% no-repeat padding-box;
    border: 2px solid #366efa;
    border-radius: 8px;

    &:disabled {
      cursor: default;
      color: #767676;
      background-color: #f5f5f5;
      border-color: #f5f5f5;
    }
  }
`;

//  Fim CSS GLOBAL TABLE2
