import {
  faCloudArrowUp,
  faPlus,
  faSave,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import useComite from "../../../actions/cruds/useComite";
import useInstancia from "../../../actions/cruds/useInstancia";
import useInstituicao from "../../../actions/cruds/useInstituicao";
import useRepresentanteInstituicao from "../../../actions/cruds/useRepresentanteInstituicao";
import Panel from "../../../components/Panel";
import bankApi from "../../../services/bankApi";

import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton,
  Form,
  Input,
  InputAddBtn,
  SelectInput,
  TextTitle,
  Title,
} from "../../../styles/global";

import { FormHandles } from "@unform/core";
import useEndereco from "../../../actions/cruds/useEndereco";
import InputFile from "../../../components/InputFile";
import { Link, useParams } from "react-router-dom";
import useCompetenciasRepresentanteInstituicao from "../../../actions/cruds/useCompetenciasRepresentanteInstituicao";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import useContatoRepresentanteInstituicao from "../../../actions/cruds/useContatoRepresentanteInstituicao";
import Grid from "@mui/material/Unstable_Grid2";
import emailChecker from "../../../utils/emailChecker";

export function EditRepresentante() {
  const [banks, setBanks] = useState([]);

  const { getAddress, endereco, setEndereco } = useEndereco();
  const {
    representanteInstituicao,
    setRepresentanteInstituicao,
    getRepresentanteInstituicao,
    updateRepresentanteInstituicao,
  } = useRepresentanteInstituicao();
  const { deleteCompetence } = useCompetenciasRepresentanteInstituicao();
  const { deleteContatoRepresentanteInstituicao } = useContatoRepresentanteInstituicao();

  //Variáveis para visualização dos dados na página
  const [contacts, setContacts] = useState([]);
  const [institutionCompetences, setInstitutionCompetences] = useState([]);
  //Variáveis para implementação dos dados no db
  const [contactsUpdate, setContactsUpdate] = useState([]);
  const [institutionCompetencesUpdate, setInstitutionCompetencesUpdate] = useState([]);
  const [competenceOptions, setCompetenceOptions] = useState([]);
  const [instanceOptions, setInstanceOptions] = useState([]);
  const [valueInstitution, setValueInstitution] = useState([]);
  const [valueCompetence, setValueCompetence] = useState([]);
  const [valueInstance, setValueInstance] = useState([]);
  const [valueUF, setValueUF] = useState([]);
  const [valueAccountType, setValueAccountType] = useState([]);
  const [valueBank, setValueBank] = useState([]);

  const [updatedValues, setUpdatedValues] = useState(0);

  const { representativeId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await bankApi.get("banks/v1");
        setBanks(
          data.map((d) => ({
            value: d.fullName,
            label: `${d.code ? d.code + " - " : ""} ${d.fullName}`,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    getRepresentanteInstituicao(representativeId);

  }, []);

  //Popula os campos do tipo SelectInput (dropdown) - valor da instituição, UF, Banco e Tipo de Conta - quando os valores de representanteInstituição for retornado e carregado//
  useEffect(() => {
    const initialValueUF = optionsUF.find(
      (o) => o.value === representanteInstituicao.uf
    );
    setValueUF(initialValueUF);

    const initialValueBank = banks.find(
      (o) => o.value === representanteInstituicao.banco_nome
    );
    setValueBank(initialValueBank);

    const initialValueAccountType = optionsAccountType.find(
      (o) => o.value === representanteInstituicao.banco_tipo_conta
    );
    setValueAccountType(initialValueAccountType);

    if (representanteInstituicao.id) {
      if (representanteInstituicao.representative_contacts) {
        const contactMap = organizeDataContact(
          representanteInstituicao.representative_contacts
        );
        setContacts([...contactMap]);
        setContactsUpdate([
          ...representanteInstituicao.representative_contacts,
        ]);
      }
    }
  }, [representanteInstituicao]);

  useEffect(() => {
    if (endereco.uf.length != 0) {
      const selected = optionsUF.find((o) => o.value === endereco.uf);
      setValueUF(selected);
    }
  }, [endereco]);

  const organizeDataContact = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          id: d.id,
          tipo: d.tipo,
          contato: d.contato,
        }));
        return data;
      }
    }
    return [];
  };

  const dataAddress = () => {
    getAddress(formRef.current.getFieldValue("cep"));
  };

  const handlerContact = (btn) => {
    const randon_id = Date.now();
    const tipoContato = btn.target.id;

    if (tipoContato === 'email') {
      const emailValue = formRef.current?.getFieldValue("email");
      const emailValido = emailChecker(emailValue);

      if (!emailValido) {
        alert("Email inválido! Insira um email valido. Ex: 'email@email.com'");
        return;
      }
    };

    if (tipoContato != "telefone" && tipoContato != "email") {
      return;
    };

    setContacts([
      ...contacts,
      {
        new_insert: true,
        id: randon_id,
        tipo: btn.target.id,
        contato:
          btn.target.id === "telefone"
            ? formRef.current.getFieldValue("telefone")
            : formRef.current.getFieldValue("email"),
      },
    ]);

    setContactsUpdate([
      ...contactsUpdate,
      {
        new_insert: true,
        front_id: randon_id,
        tipo: btn.target.id,
        contato:
          btn.target.id === "telefone"
            ? formRef.current.getFieldValue("telefone")
            : formRef.current.getFieldValue("email"),
      },
    ]);

    formRef.current?.setFieldValue("telefone", "");
    formRef.current?.setFieldValue("email", "");
  };

  //Muda os valores da UF
  const handleChangeUF = (selectedUF: object) => {
    setValueUF(selectedUF);
  };

  //Muda os valores do Tipo de Conta
  const handleChangeAccountType = (selectedAccountType: object) => {
    setValueAccountType(selectedAccountType);
  };

  //Muda os valores de Banco
  const handleChangeBank = (selectedBank: object) => {
    setValueBank(selectedBank);
  };

  const optionsUF = [
    { value: "AC", label: "AC - Acre" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "BA", label: "BA - Bahia" },
    { value: "CE", label: "CE - Ceará" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "GO", label: "GO - Goiás" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "PA", label: "PA - Pará" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PI", label: "PI - Piauí" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RR", label: "RR - Roraima" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "EX", label: "EX - Estrangeiro" },
  ];

  const optionsAccountType = [
    { value: "corrente", label: "Conta Corrente" },
    { value: "poupanca", label: "Conta Poupança" },
  ];

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        data["representative_contacts_attributes"] = contactsUpdate;

        console.log("data", data);
        updateRepresentanteInstituicao(representativeId, data);
        // console.log(data);
      } catch (err) { }
    },
    [contactsUpdate]
  );

  const dataTitleContact: GridColDef[] = [
    { field: "tipo", headerName: "Tipo de contato", flex: 1 },
    { field: "contato", headerName: "Contato", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteContactClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  async function onDeleteContactClick(e: any, row: any): Promise<void> {
    if (row.new_insert) {
      setContactsUpdate(contactsUpdate.filter((el) => el.front_id !== row.id));
    } else {
      deleteContatoRepresentanteInstituicao(row.id);
      setContactsUpdate(contactsUpdate.filter((el) => el.id !== row.id));
    }
    setContacts(contacts.filter((el) => el.id !== row.id));
  }

  return (
    <Container>
      <Form id="editRepresentante" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Edição do representante</span>
        </Title>
        <Panel titulo="Identificação do representante">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>
                CPF: <span>*</span>
              </TextTitle>
              <Input
                name="cpf"
                defaultValue={representanteInstituicao.cpf}
                type="text"
                mask="cpf"
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Data de nascimento: <span>*</span>
              </TextTitle>
              <Input
                name="data_nascimento"
                defaultValue={representanteInstituicao.data_nascimento}
                type="date"
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>
                Nome: <span>*</span>
              </TextTitle>
              <Input
                name="nome"
                type="text"
                defaultValue={representanteInstituicao.nome}
                required
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                RG: <span>*</span>
              </TextTitle>
              <Input
                name="rg"
                type="text"
                defaultValue={representanteInstituicao.rg}
                mask="rg"
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Órgão Expedidor: <span>*</span>
              </TextTitle>
              <Input
                name="orgao_expedidor"
                type="text"
                defaultValue={
                  representanteInstituicao.orgao_expedidor
                }
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                CNH: <span>*</span>
              </TextTitle>
              <Input
                name="cnh"
                type="text"
                defaultValue={representanteInstituicao.cnh}
                mask="cnh"
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Data de vencimento: <span>*</span>
              </TextTitle>
              <Input
                name="cnh_data_vencimento"
                type="date"
                defaultValue={
                  representanteInstituicao.cnh_data_vencimento
                }
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "150px" }}
                  type="text"
                  name="cep"
                  defaultValue={representanteInstituicao.cep}
                  mask="cep"
                />
                <InputAddBtn type="button" onClick={dataAddress}>
                  <FontAwesomeIcon icon={faPlus} /> Pesquisar CEP{" "}
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro: </TextTitle>
              <Input
                type="text"
                name="logradouro"
                value={
                  endereco.logradouro
                    ? endereco.logradouro
                    : representanteInstituicao.logradouro
                }
                onChange={(e) =>
                  setEndereco({
                    ...endereco,
                    logradouro: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro:</TextTitle>
              <Input
                type="text"
                name="bairro"
                value={
                  endereco.bairro
                    ? endereco.bairro
                    : representanteInstituicao.bairro
                }
                onChange={(e) =>
                  setEndereco({ ...endereco, bairro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input
                type="text"
                name="numero"
                defaultValue={representanteInstituicao.numero}
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input
                type="text"
                name="complemento"
                defaultValue={representanteInstituicao.complemento}
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={
                  endereco.localidade
                    ? endereco.localidade
                    : representanteInstituicao.municipio
                }
                onChange={(e) =>
                  setEndereco({
                    ...endereco,
                    localidade: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF: </TextTitle>
              <SelectInput
                id="idEstado"
                name="uf"
                value={valueUF}
                options={optionsUF}
                onChange={handleChangeUF}
                placeholder="Selecione"
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Contato">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Email:</TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "115px" }}
                  type="text"
                  name="email"
                />
                <InputAddBtn
                  id="email"
                  name="email_contato"
                  type="button"
                  onClick={handlerContact}
                >
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs={6}>
              <TextTitle>Telefone:</TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "115px" }}
                  type="text"
                  name="telefone"
                  mask="phone"
                />
                <InputAddBtn
                  id="telefone"
                  name="telefone_contato"
                  type="button"
                  onClick={handlerContact}
                >
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            {contacts.length > 0 && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleContact}
                  rowsData={contacts}
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Dados bancários">
          <Grid container spacing={6}>
            <Grid xs={6} lg={3}>
              <TextTitle>Tipo de conta:</TextTitle>
              <SelectInput
                id="tipoConta"
                name="banco_tipo_conta"
                options={optionsAccountType}
                value={valueAccountType}
                onChange={handleChangeAccountType}
                placeholder="Selecione"
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Banco:</TextTitle>
              <SelectInput
                id="tipoBanco"
                name="banco_nome"
                options={banks}
                value={valueBank}
                onChange={handleChangeBank}
                placeholder="Selecione"
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Agência: </TextTitle>
              <Input
                type="text"
                name="banco_agencia"
                defaultValue={representanteInstituicao.banco_agencia}
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Conta:</TextTitle>
              <Input
                type="text"
                name="banco_conta"
                defaultValue={representanteInstituicao.banco_conta}
              />
            </Grid>
          </Grid>
        </Panel>

        <ContainerButton>
          <Link to="/membros/representantes">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
            </button>
          </Link>
          <button type="submit" form="editRepresentante">
            <FontAwesomeIcon icon={faSave} /> Salvar
          </button>
        </ContainerButton>
      </Form>
    </Container >
  );
}
