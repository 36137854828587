// import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { Table } from "./styles";

interface TableProps {
  rowsData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columsData: any;
}

const SecondaryTable: React.FC<TableProps> = ({
  rowsData,
  columsData,
  ...props
}) => {
  const gridElement = document.getElementById("grid_id");
  const finalWidth = gridElement?.parentElement?.offsetWidth - 100;

  return (
    <div id="grid_id">
      <Table
        style={{ maxWidth: finalWidth, borderRadius: 6 }}
        rows={rowsData || []}
        columns={columsData}
        {...props}
      />
    </div>
  );
};

export default SecondaryTable;
