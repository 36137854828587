import {
  faCloudArrowUp,
  faPlus,
  faSave,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import useInstituicao from "../../../actions/cruds/useInstituicao";
import Panel from "../../../components/Panel";

import Grid from "@mui/material/Unstable_Grid2";


import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton,
  Form,
  Input,
  InputAddBtn,
  SelectInput,
  TextTitle,
} from "../../../styles/global";

import { SubTitle, Title } from "./styles";
import useInstancia from "../../../actions/cruds/useInstancia";
import useComite from "../../../actions/cruds/useComite";
import useEndereco from "../../../actions/cruds/useEndereco";
import Radio from "../../../components/InputRadio";
import { Link, useParams } from "react-router-dom";

import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import useCompetenciaInstituicao from "../../../actions/cruds/useCompetenciaInstituicao";
import SecondaryTable from "../../../components/SecondaryTable";
import useRepresentanteInstituicao from "../../../actions/cruds/useRepresentanteInstituicao";
import InputFile from "../../../components/InputFile";
import useAtuacao from "../../../actions/cruds/useAtuacao";
import useSeguimento from "../../../actions/cruds/useSeguimento";

interface CreateInstituicaoFormData {
  nome: string;
  email: string;
  cpf: string;
  password: string;
  organization_id: number;
}

export function EditMembers() {
  const { instancias, getInstances } = useInstancia();
  const { comites, getComittees } = useComite();
  const { getAddress, endereco, setEndereco } = useEndereco();
  const { instituicao, setInstituicao, updateInstituicao, getInstituicao } =
    useInstituicao();
  const {
    competenciaInstituicao,
    getCompetenciaInstituicao,
    createCompetenciaInstituicao,
    deleteCompetenciaInstituicao,
  } = useCompetenciaInstituicao();
  const { representantesInstituicao, getRepresentantesInstituicao } = useRepresentanteInstituicao();
  const { atuacoes, getAtuacoes } = useAtuacao();
  const { seguimentos, getSeguimentos } = useSeguimento();

  const [selectedOption, setSelectedOption] = useState({});
  const [institutionCompetences, setInstitutionCompetences] = useState([]);
  const [institutionCompetencesUpdate, setInstitutionCompetencesUpdate] = useState([]);
  const [valueAtuacao, setValueAtuacao] = useState([]);
  const [valueSeguimento, setValueSeguimento] = useState([]);
  const [valueCompetence, setValueCompetence] = useState([]);
  const [instanceOptions, setInstanceOptions] = useState([]);
  const [valueInstance, setValueInstance] = useState([]);
  const [valueRepresentante, setValueRepresentante] = useState([]);

  const dataAddress = () => {
    getAddress(formRef.current.getFieldValue("cep"));
  };

  const { memberId } = useParams();

  useEffect(() => {
    getInstituicao(memberId);
    getCompetenciaInstituicao(memberId);
    getRepresentantesInstituicao([{ label: 'ativo', value: true }]);
    getComittees([{ label: 'ativo', value: true }]);
  }, []);

  console.log("competenciaInstituicao", competenciaInstituicao)
  console.log("instituicao", instituicao)

  useEffect(() => {
    if (instituicao.id) {
      if (instituicao.institution_competences) {
        setInstitutionCompetences(organizeDataCI(instituicao.institution_competences));
      }
    }

    const initialValueUF = optionsUF.find((o) => o.value === instituicao.uf);
    setSelectedOption(initialValueUF);

    if (instituicao.cep) {
      // ja popula o estado de endereço caso traga um cep cadastrado
      dataAddress(instituicao.cep);
    }

  }, [instituicao]);

  useEffect(() => {
    if (endereco.uf.length != 0) {
      const selected = optionsUF.find((o) => o.value === endereco.uf);
      setSelectedOption(selected);
    }
  }, [endereco]);

  const handleChangeSelect = (selectedOption) => {
    // necessario para selecionar programaticamente uma opção do select
    setSelectedOption(selectedOption);
  };

  const optionsUF = [
    { value: "AC", label: "AC - Acre" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "BA", label: "BA - Bahia" },
    { value: "CE", label: "CE - Ceará" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "GO", label: "GO - Goiás" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "PA", label: "PA - Pará" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PI", label: "PI - Piauí" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RR", label: "RR - Roraima" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "EX", label: "EX - Estrangeiro" },
  ];

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        data["institution_competences_attributes"] = institutionCompetencesUpdate;

        console.log(data);
        updateInstituicao(memberId, data);

      } catch (err) {
        console.log(err);
      }
    },
    [institutionCompetencesUpdate]
  );

  const organizeDataSelect = (data) => {
    if (typeof data === "object") {
      data = data.map((d) => ({
        value: d.id,
        label: d.nome.toUpperCase(),
      }));
    }

    if (typeof data == "object") {
      data.sort((a, b) => {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }

    return data;
  };


  const organizeDataSelectRepresentante = (data) => {
    if (typeof data === "object") {
      data = data.map((d) => ({
        value: d.id,
        label: `${d.cpf} - ${d.nome.toUpperCase()}`,
        nome: d.nome.toUpperCase()
      }));
    }

    if (typeof data == "object") {
      data.sort((a, b) => {
        const nameA = a.nome.toLowerCase();
        const nameB = b.nome.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }

    return data;
  };


  const organizeDataSort = (data) => {
    if (typeof data == "object") {
      data.sort((a, b) => {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    return data;
  }

  const organizeDataCI = (data) => {
    if (typeof data === "object") {
      data = data.map((d) => ({
        id: d.id,
        front_id: d.id,
        comittee_name: d.comittee.nome ? d.comittee.nome.toUpperCase() : '',
        comittee_id: d.comittee.id,
        instance_name: d.instance.nome ? d.instance.nome.toUpperCase() : '',
        instance_id: d.instance.id,
        actuation_name: d.actuation.nome ? d.actuation.nome.toUpperCase() : '',
        actuation_id: d.actuation.id,
        segment_name: d.segment.nome ? d.segment.nome.toUpperCase() : '',
        segment_id: d.segment.id,
        representative_name: d.representative.nome ? d.representative.nome.toUpperCase() : '',
        representative_id: d.representative.id,
        titulo_arquivo: d.titulo_arquivo ? d.titulo_arquivo.toUpperCase() : '',
        data_inicio_mandato: formatData(d.data_inicio_mandato),
        data_termino_mandato: formatData(d.data_termino_mandato),
        situacao: d.ativo ? "Ativo" : "Inativo"
      }));
    }
    return data;
  };

  const formatData = (data) => {
    if (data) {
      const dateSplitted = data.split('-');
      const year = parseInt(dateSplitted[0]);
      const month = parseInt(dateSplitted[1]) - 1;
      const day = parseInt(dateSplitted[2]);
      const dateObject = new Date(year, month, day);

      return `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  };

  const handlerCI = () => {
    const randon_id = Date.now();

    let dataInicioMandato = formRef.current.getFieldValue("data_inicio_mandato");
    let dataFimMandato = formRef.current.getFieldValue("data_fim_mandato");
    let documentFile = formRef.current?.getFieldValue("nome_arquivo");
    let titleDocument = formRef.current.getFieldValue("nome_documento");

    if (!valueCompetence.value) {
      alert("Adicione a Competência!");
      return;
    };
    if (!valueInstance.value) {
      alert("Adicione a Instância!");
      return;
    };
    if (!valueAtuacao.value) {
      alert("Adicione a Atuação!");
      return;
    };
    if (!valueSeguimento.value) {
      alert("Adicione o Seguimento!");
      return;
    };
    if (!valueRepresentante.value) {
      alert("Adicione o Representante!");
      return;
    };
    if (!dataInicioMandato || !dataFimMandato) {
      alert("Adicione as datas de Início e Fim de Mandato");
      return
    };
    if (!titleDocument && documentFile) {
      titleDocument = documentFile.name
    };

    dataInicioMandato = dataInicioMandato.toString() + "-01";
    dataFimMandato = dataFimMandato.toString() + "-01";

    setInstitutionCompetences([
      ...institutionCompetences,
      {
        front_id: randon_id,
        new_insert: true,
        instance_id: valueInstance.value,
        instance_name: valueInstance.label,
        comittee_id: valueCompetence.value,
        comittee_name: valueCompetence.label,
        actuation_id: valueAtuacao.value,
        actuation_name: valueAtuacao.label,
        segment_id: valueSeguimento.value,
        segment_name: valueSeguimento.label,
        representative_id: valueRepresentante.value,
        representative_name: valueRepresentante.nome,
        data_inicio_mandato: formRef.current.getFieldValue("data_inicio_mandato"),
        data_termino_mandato: formRef.current.getFieldValue("data_fim_mandato"),
        titulo_arquivo: titleDocument,
        nome_arquivo: documentFile,
      },
    ]);

    setInstitutionCompetencesUpdate([
      ...institutionCompetencesUpdate,
      {
        front_id: randon_id,
        new_insert: true,
        instance_id: valueInstance.value,
        instance_name: valueInstance.label,
        comittee_id: valueCompetence.value,
        comittee_name: valueCompetence.label,
        actuation_id: valueAtuacao.value,
        actuation_name: valueAtuacao.label,
        segment_id: valueSeguimento.value,
        segment_name: valueSeguimento.label,
        representative_id: valueRepresentante.value,
        representative_name: valueRepresentante.nome,
        data_inicio_mandato: dataInicioMandato,
        data_termino_mandato: dataFimMandato,
        titulo_arquivo: titleDocument,
        nome_arquivo: documentFile,
      },
    ]);

    setValueCompetence([]);
    setValueInstance([]);
    setValueAtuacao([]);
    setValueSeguimento([]);
    setValueRepresentante([]);
    formRef.current?.clearField("representante_id");
    formRef.current?.clearField("data_inicio_mandato");
    formRef.current?.clearField("data_fim_mandato");
    formRef.current?.clearField("nome_documento");
    formRef.current?.clearField("nome_arquivo");
  };

  const dataTitleCI: GridColDef[] = [
    {
      field: "comittee_name",
      headerName: "Competência",
      flex: 1,
    },
    {
      field: "instance_name",
      headerName: "Instância",
      flex: 1,
    },
    {
      field: "actuation_name",
      headerName: "Atuação",
      flex: 1,
    },
    {
      field: "segment_name",
      headerName: "Seguimento",
      flex: 1,
    },
    {
      field: "representative_name",
      headerName: "Representante",
      flex: 2,
    },
    {
      field: "titulo_arquivo",
      headerName: "Documento",
      flex: 1,
    },
    {
      field: "data_inicio_mandato",
      headerName: "Data Início",
      type: "number",
      flex: 1,
    },
    {
      field: "data_termino_mandato",
      headerName: "Data Fim",
      type: "number",
      flex: 1,
    },
    {
      field: "situacao",
      headerName: "Situação",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  async function onDeleteClick(e: any, row: any) {
    if (row.new_insert) {
      setInstitutionCompetencesUpdate(institutionCompetencesUpdate.filter((el) => el.front_id !== row.id));
    } else {
      setInstitutionCompetencesUpdate([
        ...institutionCompetencesUpdate, {
          id: row.id,
          _destroy: row.id,
        }
      ]);
    }
    setInstitutionCompetences(institutionCompetences.filter((el) => el.front_id !== row.id));
  };

  const handleChangeAtuacao = (atuacaoSelected) => {
    setValueAtuacao(atuacaoSelected);
  };

  const handleChangeSeguimento = (seguimentoSelected) => {
    setValueSeguimento(seguimentoSelected);
  };

  const handleChangeCompetence = (selectedCompetence) => {
    setValueCompetence(selectedCompetence);

    if (selectedCompetence) {
      getInstances([{ label: "comittee_id", value: selectedCompetence.value }, { label: "ativo", value: true }]);
      getSeguimentos([{ label: "comittee_id", value: selectedCompetence.value }, { label: "ativo", value: true }]);
    };

    setValueInstance([]);
    setValueAtuacao([]);
    setValueSeguimento([]);
  };

  //Modifica o valor da instância//
  const handleChangeInstance = (selectedInstance: object) => {
    setValueInstance(selectedInstance);

    if (selectedInstance) {
      getAtuacoes([{ label: "instance_id", value: selectedInstance.value }, { label: "ativo", value: true }]);
    }

    setValueAtuacao([]);
  };

  const handleChangeRepresentante = (selectedRepresentante: object) => {
    setValueRepresentante(selectedRepresentante);
  };

  return (
    <Container>
      <Title color="#333">
        <span>Edição de membro do comitê - Instituição</span>
      </Title>
      <Form id="formInstituicoes" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Instituição">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Informe sua instituição:</TextTitle>
              <Input
                type="text"
                name="nome"
                defaultValue={instituicao.nome}
                required
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço da Instituição">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  type="text"
                  id="cep"
                  name="cep"
                  defaultValue={instituicao.cep}
                  mask="cep"
                />
                <InputAddBtn type="button" onClick={dataAddress}>
                  <FontAwesomeIcon icon={faPlus} /> Pesquisar CEP{" "}
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro:</TextTitle>
              <Input
                type="text"
                id="logradouro"
                name="logradouro"
                value={
                  endereco.logradouro
                    ? endereco.logradouro
                    : instituicao.logradouro
                }
                onChange={(e) =>
                  setEndereco({ ...endereco, logradouro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro:</TextTitle>
              <Input
                type="text"
                id="bairro"
                name="bairro"
                value={
                  endereco.bairro ? endereco.bairro : instituicao.bairro
                }
                onChange={(e) =>
                  setEndereco({ ...endereco, bairro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input
                type="text"
                id="numero"
                name="numero"
                defaultValue={instituicao.numero}
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input type="text" id="complemento" name="complemento" defaultValue={instituicao.complemento} />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={
                  endereco.localidade
                    ? endereco.localidade
                    : instituicao.municipio
                }
                onChange={(e) =>
                  setEndereco({ ...endereco, localidade: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF:</TextTitle>
              <SelectInput
                id="idEstado"
                name="uf"
                options={optionsUF}
                onChange={handleChangeSelect}
                value={selectedOption}
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Competências e instâncias">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Competência:</TextTitle>
              <SelectInput
                id="idcompetencia"
                name="comittee_id"
                value={valueCompetence}
                options={organizeDataSelect(comites.data)}
                onChange={handleChangeCompetence}
                placeholder="Selecionar"
                onInputChange={(e) => {
                  getComittees([{ label: "nome", value: e }, [{ label: 'ativo', value: true }]]);
                }}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Instância: </TextTitle>
              <SelectInput
                id="idInstancia"
                name="instance_id"
                value={valueInstance}
                options={valueCompetence?.value ? organizeDataSelect(instancias.data) : []}
                placeholder="Selecionar"
                onChange={handleChangeInstance}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Atuação da instituição dentro da instância:
              </TextTitle>
              <SelectInput
                id="atuacao"
                name="atuacao"
                value={valueAtuacao}
                options={valueInstance?.value ? organizeDataSelect(atuacoes.data) : []}
                placeholder="Selecionar"
                onChange={handleChangeAtuacao}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Seguimento:</TextTitle>
              <SelectInput
                id="segmento"
                name="segmento"
                options={valueCompetence?.value ? organizeDataSelect(seguimentos.data) : []}
                value={valueSeguimento}
                onChange={handleChangeSeguimento}
                placeholder="Selecionar"
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Representante: </TextTitle>
              <SelectInput
                id="idRepresentante"
                name="representante_id"
                value={valueRepresentante}
                options={organizeDataSelectRepresentante(representantesInstituicao.data)}
                placeholder="Selecionar"
                onChange={handleChangeRepresentante}
                onInputChange={(e) => {
                  getRepresentantesInstituicao([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                }}
              />
            </Grid>
            {/* <Grid xs="auto"></Grid>
            <Grid xs={6}>
              <TextTitle>
                Documento: <span>*</span>
              </TextTitle>
              <Input name="nome_documento" type="text" />
            </Grid> */}
            <Grid xs={6}>
              <ButtonUploadArch
                for="documento"
                id="selecionararquivo"
                name="selecionararquivo"
                type="button"
              >
                <FontAwesomeIcon icon={faCloudArrowUp} size="xl" />{" "}
                Selecione o arquivo ...
              </ButtonUploadArch>
              <InputFile
                id="documento"
                name="nome_arquivo"
                style={{ display: "none" }}
              />
            </Grid>
            <Grid xs={12}>
              <SubTitle style={{ display: "flex" }}>Mandato </SubTitle>
            </Grid>
            <Grid xs={6}>
              <TextTitle>Início: </TextTitle>
              <Input type="month" name="data_inicio_mandato" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Fim: </TextTitle>
              <Input type="month" name="data_fim_mandato" />
            </Grid>
          </Grid>
          <Grid xs={12}>
            <ButtonAdd type="button" onClick={handlerCI}>
              <FontAwesomeIcon icon={faPlus} /> Adicionar
            </ButtonAdd>
          </Grid>
          {institutionCompetences.length > 0 && (
            <Grid xs={12}>
              <SecondaryTable
                rowsData={institutionCompetences}
                columsData={dataTitleCI}
                getRowId={(row) => row.front_id}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              />
            </Grid>
          )}
        </Panel>
      </Form >

      <ContainerButton>
        <Link to="/membros/instituicoes">
          <button>
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <button type="submit" form="formInstituicoes">
          <FontAwesomeIcon icon={faSave} /> Salvar
        </button>
      </ContainerButton>
    </Container >
  );
}
