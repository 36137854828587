import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import api from "../services/api";

export interface IProcessoForm {
  solicitante_id: number;
  tipo_demanda: number;
  tipo_processo: number;
  assunto_processo: string;
  rateio: boolean;
  management_contract_ids: number[];
  gestor_processo_id: number;
  gestor_substituto_id: number;
  administration_id: number;
  unit_id: number;
  documento_abertura: string;
  comittee_id: number;
  norma_interna_numero: string;
  norma_interna_criacao: Date;
  observacao: string;
  law_process_plannings_attributes: ILawProcessPlanningsAttribute[];
}

export interface ILawProcessPlanningsAttribute {
  law_process_category_action_id: number;
  previsao_inicio: Date;
  previsao_termino: Date;
}

// Tipo para o contexto
interface IProcessoFormContextProps {
  processoForm: IProcessoForm | undefined;
  onSubmit: () => void;
  resetForm: () => void;
  updateForm: ({}) => void;
}

// Contexto global
const ProcessoFormContext = createContext<
  IProcessoFormContextProps | undefined
>(undefined);

// Provedor do contexto
export const ProcessoFormProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [processoForm, setProcessoForm] = useState<IProcessoForm>();

  useEffect(() => {
    // console.log("🗒Processo Form (atualizado):", processoForm);
  }, [processoForm]);

  const updateForm = (data: any) => {
    setProcessoForm((prevForm) => ({ ...prevForm, ...data }));
  };

  const resetForm = () => {
    setProcessoForm(undefined);
  };

  const onSubmit = async () => {
    console.log("processoFormOnSubmit", processoForm)
    api
      .post("law_processes", processoForm)
      .then((response) => console.log("✅ Processo criado", response.data))
      .catch(() => alert("Erro ao enviar dados de Cadastros."));
  };

  return (
    <ProcessoFormContext.Provider
      value={{ processoForm, updateForm, resetForm, onSubmit }}
    >
      {children}
    </ProcessoFormContext.Provider>
  );
};

// Hook personalizado para facilitar o acesso ao contexto
export const useProcessoForm = () => {
  const context = useContext(ProcessoFormContext);
  if (!context) {
    throw new Error(
      "useProcessoForm deve ser usado dentro de ProcessoFormProvider"
    );
  }
  return context;
};
