import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useSeguimento = () => {

    const history = useHistory();
    const [seguimentos, setSeguimentos] = useState([]);
    const [seguimento, setSeguimento] = useState([]);
    const [error, setError] = useState(null);

    async function getSeguimentos(params) {
        try {
            const response = await api.get(`segments${stringfyParams(params)}`);
            setSeguimentos(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    };

    async function getSeguimento(id) {
        try {
            const response = await api.get(`segments/${id}`);
            setSeguimento(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    };

    return { seguimentos, getSeguimentos, seguimento, getSeguimento, error };
}

export default useSeguimento;