import React, {
    ChangeEvent,
    useRef,
    useEffect,
    useCallback,
    useState,
  } from 'react';
  import { useField } from '@unform/core';
import { ButtonUploadImg } from '../../styles/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faUser } from '@fortawesome/free-solid-svg-icons';
import { ButtonImgUploadContainer, ContainerImgUpload, DefaultImage, PreviewImgUploadContainer } from './styles';
  interface Props {
    name: string;
    image?: string;
  }
  type InputProps = JSX.IntrinsicElements['input'] & Props;
  export default function InputImage({ name, image, ...rest }: InputProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);
    const [preview, setPreview] = useState(defaultValue);
    const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) {
        setPreview(null);
      }
      const previewURL = URL.createObjectURL(file);
      setPreview(previewURL);
    }, []);
    useEffect(() => {
      if(image){
        setPreview(image)
      }

      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'files[0]',
        clearValue(ref: HTMLInputElement) {
          ref.value = '';
          setPreview(null);
        },
        setValue(_: HTMLInputElement, value: string) {
          setPreview(value);
        },
      });
    }, [fieldName, registerField, image]);
    return (
      <>
        <ContainerImgUpload>
          <PreviewImgUploadContainer>
            {preview ? <img src={preview} alt="Preview" width="100" /> : <DefaultImage><FontAwesomeIcon icon={faUser} size="2xl" /></DefaultImage>}
          </PreviewImgUploadContainer>
          <ButtonImgUploadContainer>
            <ButtonUploadImg for={name} id='selecionararquivopublicacao' name='selecionararquivopublicacao' type="button">
              <FontAwesomeIcon icon={faCloudArrowUp}  />  Upload de imagem
            </ButtonUploadImg>
            <input type="file" id={name} ref={inputRef} onChange={handlePreview} {...rest} style={{display: 'none'}} />
          </ButtonImgUploadContainer>
        </ContainerImgUpload>
      </>
    );
  };