import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const usePoaSubAcao = () => {
  const history = useHistory();
  
  const { addToast } = useToast();

  const [poaSubacoes, setPoaSubacoes] = useState([]);
  const [poaSubacao, setPoaSubacao] = useState({});
  const [newPoaSubacao, setNewPoaSubacao] = useState({});

  async function getPoaSubacoes(params?: object) {
    try {
      const response = await api.get(`poa_sub_actions${stringfyParams(params)}`);
      setPoaSubacoes(response.data);
    } catch (e) {
      alert("Erro ao retornar SubAção POA");
    }
  }

  async function getPoaSubacao(id) {
    try {
      const response = await api.get(`poa_sub_actions/${id}`);
      setPoaSubacao(response.data);
    } catch (e) {
      alert("Erro ao retornar SubAção POA");
    }
  }

  async function createPoaSubacao(data) {
    try {
        const response = await api.post('poa_sub_actions', data);

        setNewPoaSubacao( response.data );
    } catch (e) {
        alert("Erro ao cadastrar SubAção POA");
    }
  }

  async function updatePapSubacao(id: number, data: object) {
    try {
      const response = await api.put(`poa_sub_actions/${id}`, data);
      
      addToast({
        type: 'success',
        title: 'Sub-ação alterada',
        description: 'Alteração da Sub-ação foi realizada com sucesso!',
      });
    } catch (e) {
      throw new Error("error");
    }
  }

  return {
    poaSubacoes,
    poaSubacao,
    getPoaSubacao,
    getPoaSubacoes,
    setPoaSubacao,
    createPoaSubacao,
    newPoaSubacao,
    updatePapSubacao
  };
};

export default usePoaSubAcao;
