import { Box, Button, Container, Step, StepLabel, Stepper } from '@mui/material';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton, ContainerButton2, Input, SelectInput, TextTitle, Title } from '../../../../../styles/global';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faSave, faX } from '@fortawesome/free-solid-svg-icons';
import useCargo from '../../../../../actions/cruds/useCargo';
import { FormHandles } from '@unform/core';
import organizeDataSelect from '../../../../../utils/organizeDataSelect';
import useUsuario from '../../../../../actions/cruds/useUsuario';
import Panel from '../../../../../components/Panel';
import { steps } from '../..';
import usePoa from '../../../../../actions/cruds/usePoa';
import useOrgao from '../../../../../actions/cruds/useOrgao';
import useContratoGestao from '../../../../../actions/cruds/useContratoGestao';
import useComite from '../../../../../actions/cruds/useComite';
import usePap from '../../../../../actions/cruds/usePap';

// import { Container } from './styles';


const PoaEditIdentificacao: React.FC = () => {

    const {idPoa} = useParams();

    const {createPoa, newPoa, getPoas, getPoa, selectedPoa, updatePoa} = usePoa();
    const {getOrganizations, orgaos} = useOrgao();
    const {getContratos, contrGestao, setContrGestao} = useContratoGestao();
    const {getComittees, comites, setComites} = useComite();
    const {getPaps, paps, setPaps, getPap, selectedPap} = usePap();

    const [selectedOrg, setSelectedOrg] = useState([]);
    const [selectedContrGestao, setSelectedContrGestao] = useState([]);
    const [selectedComite, setSelectedComite] = useState([]);
    const [selectedPapInput, setSelectedPapInput] = useState([]);

    const [yearsPoa, setYearsPoa] = useState([]);
    

    useEffect(() => {
        getOrganizations();
        // getContratos();
        // getComittees();
        // getPaps();
        // getPoas();
        getPoa(idPoa);
    }, []);

    useEffect(() => {
        formRef.current?.setFieldValue("organization_id", [{label: selectedPoa.pap.comittee.management_contract.organization.nome.nome, value:selectedPoa.pap.comittee.management_contract.organization.nome.id}]);
        formRef.current?.setFieldValue("management_contract_id", [{label: selectedPoa.pap.comittee.management_contract.nome, value:selectedPoa.pap.comittee.management_contract.id}]);
        formRef.current?.setFieldValue("comittee_id", [{label: selectedPoa.pap.comittee.nome, value:selectedPoa.pap.comittee.id}]);
        formRef.current?.setFieldValue("pap_id", selectedPoa.id ? [{label: `${selectedPoa.pap.periodo_de} - ${selectedPoa.pap.periodo_ate}`, value:selectedPoa.pap.id}] : []);
        formRef.current?.setFieldValue("poa", [{label: selectedPoa.poa, value: selectedPoa.poa}]);
    }, [selectedPoa]);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => { 
        try {
            console.log(data);
            
            updatePoa(idPoa, data);

            // addToast({
            //   type: 'success',
            //   title: 'Login efetuado',
            //   description: 'Seu login foi feito com sucesso!',
            // });

            // history.push('/dashboard');
        } catch (err) {    
            // addToast({
            //   type: 'error',
            //   title: 'Erro na autenticação',
            //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
            // });
        }
        },
        [],
    );

    const [activeStep, setActiveStep] = useState(0);

    const onChangeOrganization = (selectedOrg: any) => {
        const selectedOrgObj = Array.isArray(selectedOrg) ? selectedOrg[0] : selectedOrg;
        setSelectedOrg(selectedOrgObj);
        setSelectedComite([]);
        setComites([]);
        setSelectedContrGestao([]); // limpa campo contrato gestao
        setContrGestao([]);
        setPaps([]);
        setSelectedPapInput([]);
        formRef.current?.clearField("poa");
        setYearsPoa([]);
        getContratos([{ label: "organization_id", value: selectedOrgObj.value }]);
        // console.log(o);
    }

    const onChangeContrGestao = (selectedContrGestao: any) => {
        const selectedContrGestaoObj = Array.isArray(selectedContrGestao) ? selectedContrGestao[0] : selectedContrGestao;
        setSelectedContrGestao(selectedContrGestaoObj);
        setSelectedComite([]);
        setComites([]);
        setPaps([]);
        setSelectedPapInput([]);
        formRef.current?.clearField("poa");
        setYearsPoa([]);
        getComittees([{ label: "management_contract_id", value: selectedContrGestaoObj.value }]);
    }

    const onChangeComite = (selectedComite: any) => {
        const selectedComiteObj = Array.isArray(selectedComite) ? selectedComite[0] : selectedComite;
        setSelectedComite(selectedComiteObj);
        setPaps([]);
        setSelectedPapInput([]);
        getPaps([{label: "comittee_id", value: selectedComiteObj.value}]);
        formRef.current?.clearField("poa");
        setYearsPoa([]);
    }

    const onChangePap = (selectedPapInp: any) => {
        const selectedPapInpObj = Array.isArray(selectedPapInp) ? selectedPapInp[0] : selectedPapInp;
        if (selectedPapInpObj !== undefined) {
            setSelectedPapInput(selectedPapInpObj);
            getPap(selectedPapInpObj.value);
            formRef.current?.clearField("poa");
            setYearsPoa([]);
        }
    }



    useEffect(() => { // quando um PAP dor selecionado ele preenche os anos para o POA
        const {periodo_de, periodo_ate} = selectedPap;

        const aux = [];
        for (let y: number = periodo_de; y <= periodo_ate; y++) {
            aux.push( {label: y, value: y} );
        }
        setYearsPoa(aux);
        
    }, [selectedPap]);

    return (
        <Container>
            <Title>
                <span>Editar POA</span>
            </Title>

            <Stepper activeStep={activeStep} alternativeLabel sx={{my: 5}}>
                {steps.map(({label}, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Identificação'>
                    <Grid container spacing={6}>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Organização: <span>*</span></TextTitle>
                            <SelectInput
                                id="organization_id" 
                                name="organization_id"
                                isDisabled={newPoa.id}
                                options={organizeDataSelect(orgaos.data, "id", "nome") || []}
                                onInputChange={d => { getOrganizations([{label: 'nome', value: d}]) } }
                                onChange={onChangeOrganization}
                                placeholder="Selecione"
                                required
                                // onInputChange={e => { getTiposEmpregados([{label: 'nome', value: e}]) }}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Contrato de gestão: <span>*</span></TextTitle>
                            <SelectInput
                                id="management_contract_id" 
                                name="management_contract_id"
                                isDisabled={newPoa.id}
                                value={selectedContrGestao}
                                options={organizeDataSelect(contrGestao.data, "id", "nome_completo") || []}
                                onChange={onChangeContrGestao}
                                placeholder="Selecione"
                                required
                                // onInputChange={e => { getTiposEmpregados([{label: 'nome', value: e}]) }}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Comitê: <span>*</span></TextTitle>
                            <SelectInput
                                id="comittee_id" 
                                name="comittee_id"
                                isDisabled={newPoa.id}
                                value={selectedComite}
                                options={organizeDataSelect(comites.data, "id", "nome") || []}
                                onChange={onChangeComite}
                                placeholder="Selecione"
                                required
                                // onInputChange={e => { getTiposEmpregados([{label: 'nome', value: e}]) }}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>PAP: <span>*</span></TextTitle>
                            <SelectInput
                                id="pap_id" 
                                name="pap_id"
                                isDisabled={newPoa.id}
                                value={selectedPapInput}
                                options={organizeDataSelect(paps.data, "id", "periodo_de", ["periodo_ate"]) || []}
                                onChange={onChangePap}
                                placeholder="Selecione"
                                required
                                // onInputChange={e => { getTiposEmpregados([{label: 'nome', value: e}]) }}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>POA: <span>*</span></TextTitle>
                            <SelectInput
                                id="poa" 
                                name="poa"
                                isDisabled={newPoa.id}
                                options={yearsPoa || []}
                                placeholder="Selecione"
                                required
                                // onInputChange={e => { getTiposEmpregados([{label: 'nome', value: e}]) }}
                            />
                        </Grid>
                    </Grid>
                </Panel>

                <ContainerButton2>
                    <Link
                        to={"/orcamentario/poa"}
                        className="button_return"
                        style={{ textDecoration: "none", color: "black" }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} /> Voltar
                    </Link>
                    <div>
                        <button className="button_save" type="submit">
                            <FontAwesomeIcon icon={faSave} style={{ marginRight: 10 }} />
                            Salvar
                        </button>

                        <Link
                            to={`/orcamentario/poa/subacao/${idPoa}`}
                            className="button_next"
                            style={{ textDecoration: "none" }}
                        >
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                color="#ffffff"
                                style={{ marginRight: 10 }}
                            />
                            Proxima Etapa
                        </Link>
                    </div>
                </ContainerButton2>                
            </Form>
        </Container>
    );
};

export default PoaEditIdentificacao;