import {
  faChevronLeft,
  faCloudArrowUp,
  faSave,
} from "@fortawesome/free-solid-svg-icons";

import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton, Form, Input, SelectInput, TextTitle, Textarea, Title
} from '../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../components/Panel";
import { Link, useParams } from "react-router-dom";
import InputFile from "../../../components/InputFile";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "../../../components/InputRadio";
import useProtocolo from "../../../actions/cruds/Protocolo/useProtocolo";
import InputFilev2 from "../../../components/InputFilev2";


export function ProtocoloComprovante() {
  const { updateProtocolo } = useProtocolo();

  const { protocoloId } = useParams();

  useEffect(() => {
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        data["situacao"] = "concluido"
        console.log("data", data);
        updateProtocolo(protocoloId, data);

      } catch (err) {

      }
    },
    [],
  );

  return (
    <Container>
      <Title color='#333'>
        <span>
          Comprovante
        </span>
      </Title>
      <Form id="formComprovante" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Comprovação do atendimento">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Insira a data de comprovação do atendimento:</TextTitle>
              <Input name="data_encerramento_atendimento" type="date"/>
            </Grid>
            <Grid xs={6}>
              <TextTitle>Insira o documento referente a comprovação do atendimento:</TextTitle>
              <InputFilev2 name="nome_comprovacao" required />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Descrição da comprovação do atendimento:</TextTitle>
              <Textarea name="titulo_comprovacao" />
            </Grid>
          </Grid>
        </Panel>
      </Form >

      <ContainerButton>
        <Link to="/protocolo">
          <button><FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar</button>
        </Link>
        <button type="submit" form="formComprovante">
          <FontAwesomeIcon icon={faSave} /> Salvar
        </button>
      </ContainerButton>
    </Container >
  )
}