import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ContainerButton2,
  Form,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../styles/global";
import Panel from "../../../../components/Panel";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useHistory } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { SaveWithProgress } from "../../../../components/SaveCircularIntegration";
import organizeDataSelect from "../../../../utils/organizeDataSelect";
import useAteste from "../../../../actions/cruds/Financeiro/useAteste";
import useAutorizacaoDespesa from "../../../../actions/cruds/Financeiro/useAutorizacaoDespesa";
import { useToast } from "../../../../hooks/toast";

const FinanceiroAutorizacaoDespesaCreate: React.FC = () => {
  const { createAutorizacaoDespesa } = useAutorizacaoDespesa();
  const { atestes, getAtestes, ateste, getAteste } = useAteste();
  const history = useHistory();
  const { addToast } = useToast();

  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getAtestes([{ label: 'ativo', value: true }]);
  }, []);

  useEffect(() => {
    console.log(ateste?.invoices)
  }, [ateste])

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true)

        console.log("invoices", ateste?.invoices)

        if(!ateste?.invoices){
          return;
        };

        for (const invoice of ateste?.invoices) {
          console.log(invoice)
          const responseCreateAutorizacao = await createAutorizacaoDespesa({ invoice_id: invoice.id, attest_id: ateste.id, situacao: "aguardando_realizacao_pagamento" });
          if (responseCreateAutorizacao.status !== 201) {
            throw new Error(`Erro ao cadastrar Autorização de Despesa do fornecedor. Status: ${responseCreateAutorizacao.status}`);
          }
        };

        addToast({
          type: 'success',
          title: 'Autorizações geradas!',
          description: 'Autorizações de Despesa geradas com sucesso!',
        });
        history.push("/financeiro/autorizacaodespesa");

        console.log("data", data);
        // await createAutorizacaoDespesa({invoice_id: "44", attest_id: "50"});
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: `${err}`,
        });
        return;
      } finally {
        setSalvando(false);
      }
    }, [ateste]
  );

  return (
    <Container>
      <Form id="formAutorizacao" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Gerar Autorização de despesa</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Selecione o Ateste para gerar as Autorizações de Despesa:</TextTitle>
              <SelectInput
                name="expense_type_id"
                placeholder="Selecione..."
                options={organizeDataSelect(atestes.data, "id", "numero_ateste")}
                onInputChange={(e) => {
                  getAtestes([{ label: 'numero_ateste', value: e }, { label: 'ativo', value: true }]);
                }}
                onChange={(e) => getAteste(e.value)}
                required
              />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/financeiro/autorizacaodespesa">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formAutorizacao"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default FinanceiroAutorizacaoDespesaCreate;
