import { Container } from '@mui/material';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef } from 'react';
import Panel from '../../../../../components/Panel';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton, Input, SelectInput, TextTitle, Title } from '../../../../../styles/global';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faX } from '@fortawesome/free-solid-svg-icons';
import useCargo from '../../../../../actions/cruds/useCargo';
import { FormHandles } from '@unform/core';
import organizeDataSelect from '../../../../../utils/organizeDataSelect';
import useUsuario from '../../../../../actions/cruds/useUsuario';
import { formatarRealparaDecimal } from '../../../../../utils/formatDados';
// import { Container } from './styles';


const CargoRhCreate: React.FC = () => {

    const { createCargo } = useCargo();
    const { tiposEmpregados, getTiposEmpregados } = useUsuario();

    useEffect(() => {
        getTiposEmpregados([{ label: 'ativo', value: true }]);
    }, []);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => {
            try {
                data['salario'] = formatarRealparaDecimal(formRef.current?.getFieldValue("salario"));

                // console.log(data);

                // const field = formRef.current?.getFieldRef("salario");
                // field.setValue();
                // formRef.current.setFieldValue('salario', 12);
                // formRef.current.clearField('salario');
                // console.log(field.getUnformatted());

                createCargo(data);

                // addToast({
                //   type: 'success',
                //   title: 'Login efetuado',
                //   description: 'Seu login foi feito com sucesso!',
                // });

                // history.push('/dashboard');
            } catch (err) {
                // addToast({
                //   type: 'error',
                //   title: 'Erro na autenticação',
                //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
                // });
            }
        },
        [],
    );

    return (
        <Container>
            <Title>
                <span>Cadastrar Cargo</span>
            </Title>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Cargo'>
                    <Grid container spacing={6}>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Cargo:</TextTitle>
                            <Input type="text" name='nome' required />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Tipo de Vínculo:</TextTitle>
                            <SelectInput
                                id="employee_type_id"
                                name="employee_type_id"
                                options={organizeDataSelect(tiposEmpregados.data, "id", "nome") || []}
                                placeholder="Selecione um vínculo"
                                onInputChange={e => { getTiposEmpregados([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
                                required
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Salário:</TextTitle>
                            <Input
                                type="text"
                                name="salario"
                                format="currency"
                                currency="BRL"
                            // defaultValue="R$5.500,89"
                            />
                        </Grid>
                    </Grid>
                </Panel>
                <ContainerButton>
                    <Link to="/rh/cadastros/cargo">
                        <button><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
                    </Link>
                    <button type="submit" form="form1"><FontAwesomeIcon icon={faSave} /> Salvar</button>
                </ContainerButton>
            </Form>
        </Container>
    );
};

export default CargoRhCreate;