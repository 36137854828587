import styled from 'styled-components';

export const Container = styled.div`
    border: 1px #77CCD0 solid;
    border-radius: 10px;
    padding: 32px;
    flex: 1;

    box-shadow: 0px 25px 30px #00000033;


    > div {
        display: flex;
        align-items: center;

        p {
            font-size: 0.75rem;
        }

        > div {
            flex: 4;
            color: #707070;

            p:last-child {
                font-size: 1.5rem;
                color: #000000;
            }
        }

        > div:first-child {
            flex: 2;

            > * {
                color: #4791FF;
                background-color: rgb(71 145 255 / 20%);
                padding: 10px;
                border-radius: 10px;
            }
        }

        > div:last-child {
            flex: 1;
            
            p {
                color: #02BC77;
                font-size: 1rem;
            }
        }
    }
`;