import {
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  ContainerButton2, Form, Input, TextTitle, Textarea, Title
} from '../../../../../styles/global';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../../../components/Panel";
import { Link, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import InputFilev2 from "../../../../../components/InputFilev2";
import { SaveWithProgress } from "../../../../../components/SaveCircularIntegration";
import useFerias from "../../../../../actions/cruds/Rh/useFerias";

const RhFeriasConfirmar: React.FC = () => {
  const { feriasId } = useParams();
  const { ferias, getFerias, updateFerias } = useFerias();

  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true);

        data["situacao"] = "concluido"

        console.log("data", data);
        await updateFerias(feriasId, data);

      } catch (err) {

      } finally {
        setSalvando(false);
      }
    },
    [],
  );

  return (
    <Container>
      <Form id="formFerias" ref={formRef} onSubmit={handleSubmit}>
        <Title color='#333'>
          <span>Comfirmar Férias do empregado</span>
        </Title>
        <Panel titulo="Confirmação das férias">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data:</TextTitle>
              <Input name="data_confirmacao" type="date" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Insira o documento referente a confirmação das férias:</TextTitle>
              <InputFilev2 name="nome_arquivo" />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Observação:</TextTitle>
              <Textarea name="observacao" />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/rh/empregados/ferias/1">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formFerias"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default RhFeriasConfirmar;