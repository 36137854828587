import {
  faPlus,
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import {
  ButtonAdd,
  ContainerButton, Form, FormContainer, Input, SelectInput, TextTitle, Title
} from '../../../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../../../components/Panel";
import useOrgao from "../../../../../actions/cruds/useOrgao";
import useOrgaoGestor from "../../../../../actions/cruds/useOrgaoGestor";
import useContratoGestao from "../../../../../actions/cruds/useContratoGestao";
import useUnidade from "../../../../../actions/cruds/useUnidade";
import useComite from "../../../../../actions/cruds/useComite";
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import SecondaryTable from "../../../../../components/SecondaryTable";



export function RegComite() {
  const { orgaos, getOrganizations } = useOrgao();
  const { orgaoGestor, getOrgaoGestor } = useOrgaoGestor();
  const { contrGestao, getContratos, setContrGestao } = useContratoGestao();
  const { unidades, getUnidades, createUnidadeComite } = useUnidade();
  const { createComittees } = useComite();

  const [unidadesComite, setUnidadesComite] = useState([]);
  const [valueUnidade, setValueUnidade] = useState<Object>();

  useEffect(() => {
    getOrganizations();
    getOrgaoGestor();
    // getContratos();
    getUnidades();
  }, [])

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        data["unit_ids"] = unidadesComite.map(item => item.id);
        createComittees(data);


        // console.log(data)
        // const createComite = createComittees(data);
        // console.log(createComite.status);
        // if(createComite.status == "201"){
        //   const idComite = createComite.data.id;
        //   console.log(idComite);
        //   unidadesComite.map(item => item.id).forEach(c => {
        //     data = {"comittee_id": idComite, "unit_id": c}
        //     createUnidadeComite(data);
        //   })
        // }

        // const idComite = 

      } catch (err) {

      }
    }, [unidadesComite]);


  const organizeDataSelect = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.id,
        label: d.nome,
      }));
    }

    return data;
  }

  const organizeDataContratoGestao = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.id,
        label: d.nome_completo,
      }));
    }

    return data;
  }

  const organizeDataUnidadesComite = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        nome: d.nome,
        id: d.id
      }));
    }

    return data;
  }

  const handleChangeUnidade = (unidadeSelected) => {
    setValueUnidade(unidadeSelected);
  }

  const handleAdicionarUnidade = () => {
    setUnidadesComite([...unidadesComite, {
      "id": valueUnidade.value,
      "nome": valueUnidade.label
    }]);

  }

  useEffect(() => {
    console.log('valueUnidade: ', valueUnidade)
    console.log('unidadesComite: ', unidadesComite);

  }, [unidadesComite]);

  const dataTitle: GridColDef[] = [
    { field: "nome", headerName: "Unidade", flex: 4 },
    {
      field: "actions",
      type: 'actions',
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-trash-can" />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
            sx={{ color: "#e62121" }}
          />
        ];
      }
    }
  ];

  function handleDeleteClick(id: number): void {
    setUnidadesComite(unidadesComite.filter(u => u.id !== id));
  }

  const onChangeOrganizacao = (selectedOrganizacao: object) => {
    formRef.current?.clearField("management_contract_id");
    setContrGestao([]);
    const orgao = formRef.current?.getFieldValue("orgao_id");
    if (orgao) {
      getContratos([
        { label: "organization_id", value: selectedOrganizacao.value },
        { label: "management_entity_id", value: orgao }
      ]);
    }
  };

  const onChangeOrgao = (selectedOrgao: object) => {
    formRef.current?.clearField("management_contract_id");
    setContrGestao([]);
    const organizacao = formRef.current?.getFieldValue("organizacao_id");
    if (organizacao) {
      getContratos([
        { label: "organization_id", value: organizacao },
        { label: "management_entity_id", value: selectedOrgao.value }
      ]);
    }
  };

  return (
    <Container>
      <Title color='#333'>
        <span>
          Cadastrar comitê
        </span>
      </Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Comitê">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Organização: </TextTitle>
              <SelectInput
                id='organizacao_id'
                name='organizacao_id'
                options={organizeDataSelect(orgaos.data)}
                onChange={onChangeOrganizacao}
                placeholder="Selecionar"
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Órgão gestor: </TextTitle>
              <SelectInput
                id='orgao_id'
                name='orgao_id'
                options={organizeDataSelect(orgaoGestor.data)}
                onChange={onChangeOrgao}
                placeholder="Selecionar"
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Contrato de gestão: <small>(Com base na organização e órgão gestor escolhidos)</small></TextTitle>
              <SelectInput
                id='idContratogestao'
                name='management_contract_id'
                options={organizeDataContratoGestao(contrGestao.data)}
                placeholder="Selecionar"
                required
              />
            </Grid>
            <Grid xs={6} lg={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input
                mask="__"
                replacement="allNumber"
                id="codigo"
                name='codigo'
                required
              />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>Comitê: </TextTitle>
              <Input type="text" name='nome' required />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Sigla:</TextTitle>
              <Input type="text" name="sigla" />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Data de assinatura:</TextTitle>
              <Input type="date" name="data_assinatura" />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Decreto de criação:</TextTitle>
              <Input type="text" name="decreto" />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Unidades">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Unidade: </TextTitle>
              <SelectInput
                id='idUnidade'
                name='unidade_id'
                options={organizeDataSelect(unidades.data)}
                onChange={handleChangeUnidade}
                placeholder="Selecionar"
              />
            </Grid>

            <Grid xs={12}>
              <ButtonAdd id='adicionarunidade' name='adicionarunidade' type="button" onClick={handleAdicionarUnidade}>
                <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
              </ButtonAdd>
            </Grid>

            <Grid xs={12}>
              {unidadesComite.length > 0 && (
                <SecondaryTable
                  hideFooter={true}
                  rowsData={organizeDataUnidadesComite(unidadesComite)}
                  columsData={dataTitle}
                />
              )}
            </Grid>
          </Grid>
        </Panel>

        <ContainerButton>
          <Link to="/orcamentario/cadastros/comites">
            <button type="button"><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
          </Link>
          <button type="submit"><FontAwesomeIcon icon={faSave} /> Salvar</button>
        </ContainerButton>
      </Form>
    </Container >
  )
}
