import { Box, Button, Container, Step, StepLabel, Stepper } from '@mui/material';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton, ContainerButton2, Input, SelectInput, TextTitle, Title } from '../../../../../styles/global';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faSave, faX } from '@fortawesome/free-solid-svg-icons';
import useCargo from '../../../../../actions/cruds/useCargo';
import { FormHandles } from '@unform/core';
import organizeDataSelect from '../../../../../utils/organizeDataSelect';
import useUsuario from '../../../../../actions/cruds/useUsuario';
import Panel from '../../../../../components/Panel';
import { steps } from '../..';
import usePap from '../../../../../actions/cruds/usePap';
import useOrgao from '../../../../../actions/cruds/useOrgao';
import useContratoGestao from '../../../../../actions/cruds/useContratoGestao';
import useComite from '../../../../../actions/cruds/useComite';

// import { Container } from './styles';


const PapCreateIdentificacao: React.FC = () => {

    const [disableButtonAfterSave, setDisableButtonAfterSave] = useState(false);

    // const {createCargo} = useCargo();
    // const {tiposEmpregados, getTiposEmpregados} = useUsuario();
    const {createPap, newPap} = usePap();
    const {getOrganizations, orgaos} = useOrgao();
    const {getContratos, contrGestao, setContrGestao} = useContratoGestao();
    const {getComittees, comites, setComites} = useComite();

    useEffect(() => {
        getOrganizations();
    }, []);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => { 
        try {
            console.log(data);
            
            const response = createPap(data);
            console.log('resposta: ', response);

            // addToast({
            //   type: 'success',
            //   title: 'Login efetuado',
            //   description: 'Seu login foi feito com sucesso!',
            // });

            // history.push('/dashboard');
        } catch (err) {    
            // addToast({
            //   type: 'error',
            //   title: 'Erro na autenticação',
            //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
            // });
        }
        },
        [],
    );

    const [activeStep] = useState(0);

    const onChangeOrganization = (selectedOrg: object) =>{
        const selectedOrgObj = Array.isArray(selectedOrg) ? selectedOrg[0] : selectedOrg;
        if (selectedOrgObj) {
            formRef.current?.clearField("management_contract_id");
            formRef.current?.clearField("comittee_id");
            setContrGestao([]);
            setComites([]);
            getContratos([{ label: "organization_id", value: selectedOrgObj.value }]);
        }
    }

    const onChangeContrGestao = (selectedContrGestao: object) => {
        const selectedContrGestaoObj = Array.isArray(selectedContrGestao) ? selectedContrGestao[0] : selectedContrGestao;
        if (selectedContrGestaoObj) {
            formRef.current?.clearField("comittee_id");
            setComites([]);
            getComittees([{ label: "management_contract_id", value: selectedContrGestaoObj.value }]);
        }
        
    }

    return (
        <Container>
            <Title>
                <span>Cadastrar PAP</span>
            </Title>

            <Stepper activeStep={activeStep} alternativeLabel sx={{my: 5}}>
                {steps.map(({label}, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Identificação'>
                    <Grid container spacing={6}>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Organização: <span>*</span></TextTitle>
                            <SelectInput
                                id="organization_id" 
                                name="organization_id"
                                options={organizeDataSelect(orgaos.data, "id", "nome") || []}
                                isDisabled={newPap.id}
                                placeholder="Selecione"
                                required
                                onChange={onChangeOrganization}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Contrato de gestão: <span>*</span></TextTitle>
                            <SelectInput
                                id="management_contract_id" 
                                name="management_contract_id"
                                options={organizeDataSelect(contrGestao.data, "id", "nome_completo") || []}
                                isDisabled={newPap.id}
                                placeholder="Selecione"
                                required
                                onChange={onChangeContrGestao}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Comitê: <span>*</span></TextTitle>
                            <SelectInput
                                id="comittee_id" 
                                name="comittee_id"
                                options={organizeDataSelect(comites.data, "id", "nome") || []}
                                isDisabled={newPap.id}
                                placeholder="Selecione"
                                required
                            />
                        </Grid>
                        <Grid xs={6} lg={3}>
                            <TextTitle>Período do PAP início: <span>*</span></TextTitle>
                            <Input type="number" min="2001" max="2099" step="1" name='periodo_de' disabled={newPap.id} placeholder="Apenas ano" required />
                        </Grid>
                        <Grid xs={6} lg={3}>
                            <TextTitle>Período do PAP fim: <span>*</span></TextTitle>
                            <Input type="number" min="2001" max="2099" step="1" name='periodo_ate' disabled={newPap.id} placeholder="Apenas ano" required />
                        </Grid>
                    </Grid>
                </Panel>

                <ContainerButton2>
                    <Link
                        to={"/orcamentario/pap"}
                        className="button_return"
                        style={{ textDecoration: "none", color: "black" }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} /> Voltar
                    </Link>
                    <div>
                        {!newPap.id &&
                            <button className="button_save" type="submit" disabled={disableButtonAfterSave}>
                                <FontAwesomeIcon icon={faSave} style={{ marginRight: 10 }} />
                                Salvar
                            </button>
                        }
                        {newPap.id && (
                            <Link
                                to={`/orcamentario/pap/finalidade/${newPap.id}`}
                                className="button_next"
                                style={{ textDecoration: "none" }}
                            >
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    color="#ffffff"
                                    style={{ marginRight: 10 }}
                                />
                                Proxima Etapa
                            </Link>
                        )}
                    </div>
                </ContainerButton2>
                
            </Form>
        </Container>
    );
};

export default PapCreateIdentificacao;