import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton, Input, SelectInput, TextTitle, Title } from '../../../styles/global';
import Panel from '../../../components/Panel';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faX } from '@fortawesome/free-solid-svg-icons';
import useProcesso from '../../../actions/cruds/Processos/useProcesso';
import SecondaryTable from '../../../components/SecondaryTable';
import { GridColDef } from '@mui/x-data-grid';
import organizeDataSelect from '../../../utils/organizeDataSelect';
import useProtocolo from '../../../actions/cruds/Protocolo/useProtocolo';
import { ButtonAddSide } from '../styles';

const ProcessoVincularProtocolo: React.FC = () => {
  const { processoId } = useParams();
  const { processo, getProcesso, updateProcesso } = useProcesso();
  const { protocolos, getProtocolos } = useProtocolo();

  const [protocoloVinculo, setProtocoloVinculo] = useState<Object>([]);
  const [protocolosList, setProtocolosList] = useState<Array<Object>>([]);
  const [protocolosUpdate, setProtocolosUpdate] = useState<Array<Object>>([]);
  const [contratosGestaoUpdate, setContratosGestaoUpdate] = useState<Array<Number>>([]);

  useEffect(() => {
    getProcesso(processoId);
    getProtocolos([{ label: 'ativo', value: true }]);
  }, [])

  useEffect(() => {
    if (processo?.management_contracts) {
      let contratosGestao: number[] = [];
      processo?.management_contracts.forEach((contrato) => contratosGestao.push(contrato.id));
      setContratosGestaoUpdate(contratosGestao);
    };
  }, [processo])

  const organizeDataProtocoolo = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.demand.id,
          codigo: d.demand.codigo,
        }));
        return data;
      };
    };
    return [];
  };

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        data["management_contract_ids"] = contratosGestaoUpdate;
        data["law_process_demands_attributes"] = protocolosUpdate;

        console.log("data", data);
        updateProcesso(processoId, data);
      } catch (err) {
        console.log(err);
      }
    }, [contratosGestaoUpdate, protocolosUpdate],
  );

  const handlerAddProtocolo = () => {
    if (protocolosList?.find((protocolo) => protocolo.id == protocoloVinculo.id) || protocoloVinculo.codigo == processo?.demand?.codigo || processo?.law_process_demands?.find((demands) => demands.demand_id == protocoloVinculo.id)) {
      alert("Protocolo já adicionado!");
      return;
    }
    setProtocolosList([...protocolosList, protocoloVinculo]);
    setProtocolosUpdate([...protocolosUpdate, { "demand_id": protocoloVinculo.id }]);
  };

  const dataTitleProtocoloVinculado: GridColDef[] = [
    { headerName: "Código do Protocolo", field: "codigo", flex: 1 },
  ];

  const dataTitleProtocoloUpdate: GridColDef[] = [
    { headerName: "Código do Protocolo", field: "codigo", flex: 3 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteProtocolo(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  function onDeleteProtocolo(e: MouseEvent<HTMLButtonElement, MouseEvent>, row: any): void {
    if (row?.new) {
      setProtocolosList(protocolosList.filter((el) => el.id !== row.id));
      setProtocolosUpdate(protocolosUpdate.filter((el) => el !== row.id));
    }
  };

  return (
    <Container>
      <Title>
        <span>Vincular Protocolo</span>
      </Title>

      <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
        <Panel titulo='Vincular Protocolo'>
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Protocolo base:</TextTitle>
              <Input type="text" name='protocolo' value={processo?.demand?.codigo} disabled />
            </Grid>
            {processo?.law_process_demands?.length && (
              <>
                <Grid xs={6}>
                  <TextTitle>Protocolos vinculados:</TextTitle>
                  <SecondaryTable
                    columsData={dataTitleProtocoloVinculado}
                    rowsData={organizeDataProtocoolo(processo?.law_process_demands)}
                  />
                </Grid>
                <Grid xs="auto"></Grid>
              </>
            )}
            <Grid xs={6}>
              <TextTitle>Vincular protocolo:</TextTitle>
              <SelectInput
                name="vincular_protocolo"
                placeholder="Selecione..."
                options={organizeDataSelect(protocolos.data, "id", "codigo")}
                onInputChange={(e) => {
                  getProtocolos([{ label: 'codigo', value: e }, { label: 'ativo', value: true }]);
                }}
                onChange={(e) => setProtocoloVinculo({ id: e.value, codigo: e.label, new: true })}
                required
              />
            </Grid>
            <Grid xs={6}>
              <ButtonAddSide type="button" onClick={() => handlerAddProtocolo()}>
                <FontAwesomeIcon icon={faPlus} /> Adicionar
              </ButtonAddSide>
            </Grid>
            {protocolosList.length > 0 && (
              <Grid xs={6}>
                <SecondaryTable
                  columsData={dataTitleProtocoloUpdate}
                  rowsData={protocolosList}
                />
              </Grid>
            )}
          </Grid>
        </Panel>
        <ContainerButton>
          <Link to="/processos">
            <button><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
          </Link>
          <button type="submit" form="form1"><FontAwesomeIcon icon={faSave} /> Salvar</button>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default ProcessoVincularProtocolo;