import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";

const useApostilamentoInstrumento = () => {

    const history = useHistory();
    const [apostilamentoInstrumento, setApostilamentoinstrumento] = useState([]);
    const [apostilamentosInstrumento, setApostilamentosinstrumento] = useState([]);
    const [error, setError] = useState(null);

    async function getApostilamentosInstrumento() {
        try {
            const response = await api.get('apostilles');
            setApostilamentosinstrumento(response.data);
        } catch (e) {
            setError(e);
        }
    }

    async function getApostilamentoInstrumento(id: string) {
        try {
            const response = await api.get(`apostilles/${id}`);
            setApostilamentoinstrumento(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    }

    async function createApostilamentoInstrumento(data: object) {
        try {
            const response = await api.post('apostilles', data,
            {
                headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            alert("Apostilamento cadastrado com sucesso!");
            history.goBack();

        } catch (e) {
            setError(e);
        }
    }

    async function updateApostilamentoInstrumento(id: string, data: object) {
        try {
            const response = await api.put(`apostilles/${id}`, data,
            {
                headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            alert("Apostilamento atualizado com sucesso!");
            history.goBack();
            return response
        } catch (e) {
            setError(e);
            // console.log(error);
        }        
    }


    return {apostilamentoInstrumento, apostilamentosInstrumento, getApostilamentosInstrumento, getApostilamentoInstrumento, createApostilamentoInstrumento, updateApostilamentoInstrumento, error};
}

export default useApostilamentoInstrumento;