import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { CSidebar } from "../../components/Sidebar";

import { Container, Title } from "./styles";
import MainTable from "../../components/MainTable";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import getPermission from "../../utils/getPermission";

export function Members() {
  const [instituicoes, setInstituicoes] = useState([]);
  const [searchValue, setSearchValue] = useState("");


  const {login} = useAuth();
  const membrosPermission = getPermission(login,"Membros") ?? '';

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      api
        .get(`institutions?busca_rapida=${searchValue}`)
        .then((response) => {
          console.log(response.data);
          setInstituicoes(response.data);
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`institutions?page=${instituicoes.pagination.current_page + page}`)
      .then((response) => {
        setInstituicoes(response.data);
      })
      .catch((error) => console.log(error));
  };

  const handleItemsPerPage = (per_page: number) => {
    console.log(per_page);

    api
      .get(`institutions?per=${per_page}`)
      .then((response) => {
        setInstituicoes(response.data);
        console.log(response.data);
      })
      .catch((error) => console.log(error));
  };

  const organizeData = (data: any) => {
    if (typeof data === "object") {
      data = data.map((d: any) => ({
        id: d.id,
        nome: d.nome,
        ativo: d.ativo,
      }));
    }

    return data;
  };

  const columnData = [
    { headerName: "Código", field: "id" },
    { headerName: "Instituição", field: "nome" },
    { headerName: "Situação", field: "ativo" },
  ];

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ height: "100%" }}>
        <CSidebar />
      </div>
      <div style={{ display: "block", width: "100%" }}>
        <Header />
        <Title color="#333">
          <span>Gestão de membros - Instituições</span>
        </Title>
        <Container color="#FFF">
          <MainTable
            handleItemsPerPage={handleItemsPerPage}
            onSearchChange={setSearchValue}
            data={organizeData(instituicoes.data)}
            pagination={instituicoes.pagination}
            permission={membrosPermission}
            handlePagination={handlePagination}
            hideFilter={true}
            isMaxWidthCell
            registerLink="/membros/instituicoes/cadastrar"
            columns={columnData}
            dropdown={{
              view: { linkTo: "/membros/instituicoes/visualizar" },
              editar: { linkTo: "/membros/instituicoes/editar" },
              documentos: {
                linkTo: "/membros/instituicoes/documentos/cadastrar",
              },
              desativar: { linkTo: "institutions" },
            }}
          />
        </Container>
      </div>
    </div>
  );
}
