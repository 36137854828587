import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useUnidade = () => {
  const history = useHistory();
  const [unidade, setUnidade] = useState({
    organization: {},
  });
  const [unidades, setUnidades] = useState([]);
  const [unidadeComite, setUnidadeComite] = useState([]);
  const [unidadesComite, setUnidadesComite] = useState([]);
  const [error, setError] = useState(null);

  async function getUnidades(params?: object) {
    try {
      const response = await api.get(`units${stringfyParams(params)}`);
      setUnidades(response.data);
    } catch (e) {
      setError(e);
    }
  }

  async function getUnidade(id: string) {
    try {
      const response = await api.get(`units/${id}`);
      setUnidade(response.data);
      return response.data;
    } catch (e) {
      setError(e);
    }
  }

  async function createUnidade(data: object) {
    try {
      const response = await api.post("units", data);
      alert("Unidade cadastrado com sucesso!");
      history.goBack();
    } catch (e) {
      alert("Erro: não foi possível cadastrar.");
      setError(e);
    }
  }

  async function updateUnidade(id: string, data: object) {
    try {
      const response = await api.put(`units/${id}`, data);
      alert("Unidade atualizado com sucesso!");
      history.goBack();
      return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function getUnidadesComite(params?: object) {
    try {
      const response = await api.get(`comittee_units${stringfyParams(params)}`);
      setUnidadesComite(response.data);
    } catch (e) {
      setError(e);
    }
  }

  async function getUnidadeComite(id: string) {
    try {
      const response = await api.get(`comittee_units/${id}`);
      setUnidadeComite(response.data);
      return response.data;
    } catch (e) {
      setError(e);
    }
  }

  async function createUnidadeComite(data: object) {
    try {
      const response = await api.post("comittee_units", data);
      alert("Unidade cadastrado com sucesso!");
      history.goBack();
    } catch (e) {
      setError(e);
    }
  }

  async function updateUnidadeComite(id: string, data: object) {
    try {
      const response = await api.put(`comittee_units/${id}`, data);
      alert("Unidade atualizado com sucesso!");
      history.goBack();
      return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function DesativarUnidade(id: any, data: any) {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("ativo", data);

    try {
      const response = await api.put(`units/${id}`, formData);
      window.location.reload();
      return response.data;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  return {
    unidade,
    unidades,
    unidadeComite,
    unidadesComite,
    getUnidade,
    DesativarUnidade,
    getUnidades,
    getUnidadeComite,
    getUnidadesComite,
    createUnidade,
    createUnidadeComite,
    updateUnidade,
    updateUnidadeComite,
    setUnidades
  };
};

export default useUnidade;
