import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 60px 20px #00000014;
    opacity: 1;
    background: ${props => props.color};
`;

export const SubTitle = styled.div`
  position: relative;
  display: grid;
  color: black;
  font-size: 1rem;
  margin: 8px 8px 8px 0px; 
  font-weight: 600;
  
  &:after {
    content: ""; 
    margin: 10px 0 0 10px;
    width: 100%;
    height: 0.05em;
    background-color: #707070;  
  }

`;
