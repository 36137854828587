import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

const usePassword = () => {
  const history = useHistory();
  const [passwordById, setPasswordById] = useState('');
  const [error, setError] = useState(null);


  async function updatePassword(id: number, data: { old_password: string, password: string }) {
    try {
      const response = await api.put(`logins/change_my_password/${id}`, data);

      console.log("response: ", response);
      alert("Senha atualizada com sucesso");
      history.push("/usuarios");

    } catch (e) {
      setError(e);
      console.log(error);
    }
  }

  return {
    updatePassword,
    error,
  };
};

export default usePassword;
