import { FormHandles } from "@unform/core";

import {
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  useCallback,
  useEffect,
  useRef
} from "react";

import Header from "../../../../components/Header";

import { CSidebar } from "../../../../components/Sidebar";

import {
  ContainerButton,
  Form,
  FormContainer,
  Input,
  TextTitle,
  Title
} from "../../../../styles/global";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import Panel from "../../../../components/Panel";

import { Link, useParams } from "react-router-dom";
import useCnae from "../../../../actions/cruds/useCnae";

interface UpdateCNAEFormData {
  codigo: string;
  nome: string;
}

export function EditCNAE() {

  const { cnae, updateCnae, getCnae } = useCnae();

  const { idCnae } = useParams();

  useEffect(() => {
    getCnae(idCnae);
  }, [idCnae]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: UpdateCNAEFormData) => {
      try {
        updateCnae(idCnae, data);
      } catch (err) {

      }
    },
    [],
  );

  return (
    <Container>
      <Title color='#333'>
        <span>
          Editar atividade CNAE
        </span>
      </Title>
      <Form id="updateCnae" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Atividade CNAE">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6} >
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input type="text" name="codigo" defaultValue={cnae.codigo} />
            </Grid>
            <Grid xs={12} lg={6} >
              <TextTitle>Descrição: <span>*</span></TextTitle>
              <Input type="text" name="nome" defaultValue={cnae.nome} />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/fornecedores/cnae">
          <button>
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <button type="submit" form="updateCnae"><FontAwesomeIcon icon={faSave} /> Salvar</button>
      </ContainerButton>
    </Container>
  )

}