import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container } from "@mui/material";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";

import Panel from "../../../components/Panel";
import { ContainerButton2, Form, Input, SelectInput, TextTitle, Title } from "../../../styles/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { FormHandles } from "@unform/core";
import Grid from "@mui/material/Unstable_Grid2";
import useProcessoCategoriaAcao from "../../../actions/cruds/Processos/useProcessoCategoriaAcao";
import useProcesso from "../../../actions/cruds/Processos/useProcesso";
import useProcessoPlanejamento from "../../../actions/cruds/Processos/useProcessoPlanejamento";
import { formatarData } from "../../../utils/formatDados";

const ProcessoPlanejamento: React.FC = () => {
  const { processoId } = useParams();
  const { acoesCategoriasProcessos, getAcoesCategoriasProcessos } = useProcessoCategoriaAcao();
  const { processo, getProcesso, updateProcesso } = useProcesso();
  const { createPlanejamentoProcesso } = useProcessoPlanejamento();

  const [acoesCategoria, setAcoesCategoria] = useState<Array<Object>>([]);
  const [possuiPlanejamento, setPossuiPlanejamento] = useState<boolean>(false);
  const [contratosGestaoUpdate, setContratosGestaoUpdate] = useState<Array<Number>>([]);

  useEffect(() => {
    getProcesso(processoId);
  }, [])

  useEffect(() => {
    if (processo?.id && processo?.law_process_plannings) {
      setPossuiPlanejamento(true)
      formRef.current?.setFieldValue("prazo_termino", processo.prazo_termino);
    };

    if(processo?.id && processo?.law_process_category){
      formRef.current?.setFieldValue("categoriaProcesso", { value: processo.law_process_category?.id, label: processo.law_process_category?.nome });    
    };

    if (processo?.management_contracts) {
      let contratosGestao: number[] = [];
      processo?.management_contracts.forEach((contrato) => contratosGestao.push(contrato.id));
      setContratosGestaoUpdate(contratosGestao);
    }
  }, [processo])

  useEffect(() => {
    setAcoesCategoria(acoesCategoriasProcessos.data);
  }, [acoesCategoriasProcessos])

  const organizeData = (data: any) => {
    if (typeof data === "object") {
      data = data.map((d: any) => {
        return {
          id: d.id,
          item: d.item,
          acao: d.nome,
          previsao_inicio: d.previsao_inicio,
          previsao_termino: d.previsao_termino,
        };
      });
    }
    return data;
  };

  const organizeDataActionPlanning = (data: any) => {
    if (typeof data === "object") {
      data = data.map((d: any) => {
        return {
          id: d.id,
          item: d.law_process_category_action.item,
          acao: d.law_process_category_action.nome,
          previsao_inicio: formatarData(d.previsao_inicio, false),
          previsao_termino: formatarData(d.previsao_termino, false),
        };
      });
      data = data.sort((a, b) => a.item - b.item);
    }
    return data;
  };

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {

        let datasNull: number[] = [];
        let datasCorrecao: number[] = [];
        acoesCategoria.forEach((acoes) => {
          if (!acoes.previsao_inicio || !acoes.previsao_termino) {
            datasNull.push(acoes.item);
          } else if (acoes.previsao_inicio > acoes.previsao_termino) {
            datasCorrecao.push(acoes.item);
          }
        });

        if (datasNull.length > 0) {
          alert(`Inserir as datas dos itens: ${datasNull}`)
          return;
        } else if (datasCorrecao.length > 0) {
          alert(`As datas de Início precisam ser anteriores às datas de Término.\nCorrigir os itens: ${datasCorrecao}`);
          return;
        };


        acoesCategoria.forEach(async (acoes) =>
          await createPlanejamentoProcesso(acoes)
        );

        const prazoTermino = formRef.current?.getFieldValue("prazo_termino");

        console.log("data", data);
        updateProcesso(processoId, { "prazo_termino": prazoTermino, "management_contract_ids" : contratosGestaoUpdate });

      } catch (err) {

      }
    }, [acoesCategoria, contratosGestaoUpdate]
  );

  const handleUpdateDate = (updatedRow: any, originalRow: any) => {
    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const { previsao_inicio, previsao_termino } = updatedRow;
    const { id } = originalRow;

    const initialAcaoCategoria = acoesCategoria.find((acao) => acao.id === id);
    if (previsao_inicio) {
      const updatedAcaoCategoria = { law_process_id: processoId, law_process_category_action_id: initialAcaoCategoria?.id, id: initialAcaoCategoria?.id, nome: initialAcaoCategoria?.nome, item: initialAcaoCategoria?.item, previsao_termino: initialAcaoCategoria?.previsao_termino, previsao_inicio: formatDate(previsao_inicio), status: "a_fazer" }
      const removeAcaoCategoria = acoesCategoria.filter((acao) => acao.id !== id);
      setAcoesCategoria(removeAcaoCategoria.concat(updatedAcaoCategoria));

    } else if (previsao_termino) {
      const updatedAcaoCategoria = { law_process_id: processoId, law_process_category_action_id: initialAcaoCategoria?.id, id: initialAcaoCategoria?.id, nome: initialAcaoCategoria?.nome, item: initialAcaoCategoria?.item, previsao_termino: formatDate(previsao_termino), previsao_inicio: initialAcaoCategoria?.previsao_inicio, status: "a_fazer" }
      const removeAcaoCategoria = acoesCategoria.filter((acao) => acao.id !== id);
      setAcoesCategoria(removeAcaoCategoria.concat(updatedAcaoCategoria));
    };
  };

  const placeholderCell = (params: any) => (
    <div
      style={{
        cursor: "pointer",
        color: params.row.name ? "inherit" : "#757575",
      }}
      onClick={() => params.api.getCellMode(params.id, params.field)}
    >
      {params.row.name || "Clique para adicionar"}
    </div>
  );

  const dataTitleAcoes: GridColDef[] = [
    { field: "item", headerName: "Item", flex: 1 },
    { field: "acao", headerName: "Ação", flex: 6 },
    {
      field: "previsao_inicio",
      headerName: "Previsão de Início",
      editable: true,
      type: "date",
      renderCell: placeholderCell,
      flex: 2,
    },
    {
      field: "previsao_termino",
      headerName: "Previsão de Término",
      editable: true,
      type: "date",
      renderCell: placeholderCell,
      flex: 2,
    },
  ];

  const dataTitleAcoesPlanning: GridColDef[] = [
    { field: "item", headerName: "Item", flex: 1 },
    { field: "acao", headerName: "Ação", flex: 6 },
    {
      field: "previsao_inicio",
      headerName: "Previsão de Início",
      flex: 2,
    },
    {
      field: "previsao_termino",
      headerName: "Previsão de Término",
      flex: 2,
    },
  ];

  return (
    <Container>
      <Form id="formPlanejamento" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span >
            Planejamento do processo
          </span>
        </Title>

        <Panel titulo="Planejamento de processo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Categoria:</TextTitle>
              <SelectInput
                id="categoriaProcesso"
                name="categoriaProcesso"
                options={[]}
                onChange={(e) => getAcoesCategoriasProcessos([{ label: 'law_process_category_id', value: e.value }])}
                isDisabled
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Prazo para o término do processo:</TextTitle>
              <Input id="prazo_termino" name="prazo_termino" type="date" disabled={possuiPlanejamento} required />
            </Grid>
            {acoesCategoriasProcessos?.data?.length > 0 && !possuiPlanejamento && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleAcoes}
                  rowsData={organizeData(acoesCategoriasProcessos.data)}
                  processRowUpdate={handleUpdateDate}
                  onProcessRowUpdateError={() => { }}
                />
              </Grid>
            )}
            {processo?.law_process_plannings?.length > 0 && possuiPlanejamento && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleAcoesPlanning}
                  rowsData={organizeDataActionPlanning(processo.law_process_plannings)}
                />
              </Grid>
            )}
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/processos">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <button className="button_post" type="submit" form="formPlanejamento" disabled={possuiPlanejamento}>
          <FontAwesomeIcon icon={faSave} /> Salvar
        </button>
      </ContainerButton2>
    </Container>
  );
};

export default ProcessoPlanejamento;
