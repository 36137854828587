import styled from "styled-components";

export const Container = styled.div`
    height: 100%;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 60px 20px #00000014;
    opacity: 1;
    background: ${props => props.color};
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 24px;

  border: 1px solid #cccccc;
`;

export const StyledHeaderCell = styled.th`
  background-color: #08acef;
  color: white;
  padding: 14px;
  text-align: left;
`;

export const StyledDataCell = styled.td`
  border: 1px solid #cccccc;
  border-left: 0;
  border-right: 0;
  padding: 12px;

  max-width: 200px;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    border: 0;
    width: 100%;
  }

  .input_container {
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 42px;
    padding: 0 12px 0 16px;
    border-radius: 8px;
    border: 1px solid #cccccc;

    button {
      border: 0;
      border-left: 1px solid #cccccc;
      padding-left: 12px;
      background-color: #ffffff;
    }
  }

  .container_option {
    display: flex;
  }

  .button_option {
    display: flex;
    align-items: center;
    font-weight: 600;

    color: #ffffff;

    border: 0;
    border-radius: 6px;
    height: 42px;
    font-size: 16px;
    padding: 0 16px;
    margin-left: 16px;
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 110px;

  background-color: #3cc3f6;
  color: #ffffff;
  padding: 4px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 700;
`;
