import styled from 'styled-components';

export const Title = styled.div`

  color: ${props => props.color};
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* padding-left: 2rem; */
  margin-left: 2rem;
  text-align: start;
  /* float: left;  */
  display: grid;
  
  span{
    font-size: 1.8rem;
    font-weight: 600;
  }
`;

export const SubTitle = styled.div`
  position: relative;
  display: grid;
  color: black;
  font-size: 1rem;
  margin: 8px 8px 8px 0px; 
  font-weight: 600;
  
  &:after {
    content: ""; 
    margin: 10px 0 0 10px;
    width: 100%;
    height: 0.05em;
    background-color: #707070;  
  }

`;


export const Input = styled.input`
  width: 100%; 
  padding: 10px;
  margin: 1.3rem 1.3rem 2.3rem 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C4C4C4;
  border-radius: 8px; 
  opacity: 1;
`;


export const Instancia = styled.div`
  display: flex;
  margin: 1rem 0rem 1rem 0px;

  label{
    padding-left: 0.2rem;
    padding-right: 0.5rem;
  }
`;
