import { Fragment } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import agevapLogoImg from './../../../assets/agevapLogoImg.png';
import { styles } from './styles';

interface dataTableRowProps {
    id: string,
    row: string,
    value: string,
    logoWithColumns?: boolean,
    itemsColumn?: any,
};

interface TableRowProps {
    items: dataTableRowProps[],
};

const TableRow: React.FC<TableRowProps> = ({ items }) => {
    const rows = items.map((item: dataTableRowProps) =>
        <View style={styles.table} key={item.id}>
            {item.logoWithColumns &&
                <>
                    <View style={styles.tableLogo} key={item.id}>
                        <Image style={styles.logo} src={agevapLogoImg} />
                    </View>
                    <View style={styles.tableColumns} key={item.id}>
                        {item.itemsColumn.map((item: any) => (
                            <View style={styles.tableColumnRow} key={item.id}>
                                <>
                                    <Text style={styles.rowColumn}>{item.row}</Text>
                                    <Text style={styles.valueColumn}>{item.value}</Text>
                                </>
                            </View >
                        ))}
                    </View>
                </>
            }
            {!item.logoWithColumns &&
                <>
                    <Text style={styles.row}>{item.row}</Text>
                    <Text style={styles.value}>{item.value}</Text>
                </>
            }
        </View>
    );

    return (<Fragment>{rows}</Fragment>)
};

export default TableRow;