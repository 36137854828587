import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const usePapPrograma = () => {
  const history = useHistory();

  const { addToast } = useToast();
  
  const [papProgramas, setPapProgramas] = useState([]);
  const [papPrograma, setPapPrograma] = useState({});
  const [newPapPrograma, setNewPapPrograma] = useState({});

  async function getPapProgramas(params) {
    try {
      const response = await api.get(`pap_programs${stringfyParams(params)}`);
      setPapProgramas(response.data);
    } catch (e) {
      alert("Erro ao retornar Finalidades PAP");
    }
  }

  async function getPapPrograma(id) {
    try {
      const response = await api.get(`pap_programs/${id}`);
      setPapPrograma(response.data);
    } catch (e) {
      alert("Erro ao retornar Finalidade PAP");
    }
  }

  async function createPapPrograma(data) {
    try {
        const response = await api.post('pap_programs', data);

        setNewPapPrograma( response.data );
    } catch (e) {
        // alert("Erro ao cadastrar Programa PAP");
    }
  }

  async function updatePapPrograma(id: number, data: object) {
    try {
      const response = await api.put(`pap_programs/${id}`, data);
      
      addToast({
        type: 'success',
        title: 'Programa alterado',
        description: 'Alteração do Programa foi realizado com sucesso!',
      });
    } catch (e) {}
  }

  return {
    papProgramas,
    papPrograma,
    getPapProgramas,
    getPapPrograma,
    setPapPrograma,
    createPapPrograma,
    newPapPrograma,
    updatePapPrograma
  };
};

export default usePapPrograma;
