import {
  faChevronLeft,
  faCloudArrowUp,
  faPlus,
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import useInstituicao from "../../../actions/cruds/useInstituicao";
import Header from "../../../components/Header";
import Panel from "../../../components/Panel";
import { CSidebar } from "../../../components/Sidebar";

import Grid from '@mui/material/Unstable_Grid2';

import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton,
  Form,
  FormContainer,
  Input,
  SelectInput,
  TextTitle
} from '../../../styles/global';


import {
  Title
} from "./style";
import InputFile from "../../../components/InputFile";
import useDocumentosInstituicao from "../../../actions/cruds/useDocumentosInstituicao";
import { Link, useParams } from "react-router-dom";
import organizeDataSelect from "../../../utils/organizeDataSelect";
import useComite from "../../../actions/cruds/useComite";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";


interface createInstitutionDocumentFormData {
  nome: string;
  email: string;
  cpf: string;
  password: string;
  organization_id: number;
}

export function createInstitutionDocument() {

  const { idInstituicao } = useParams();

  const { documentosPorInstituicao, getDocumentosPorInstituicao, createDocumentosInstituicao, deleteDocumentosInstituicao } = useDocumentosInstituicao();
  const { instituicao, getInstituicao } = useInstituicao();
  const { comites, getComittees } = useComite();

  const [documents, setDocuments] = useState([]);
  const [documentsNames, setDocumentsNames] = useState([]);

  useEffect(() => {
    getInstituicao(idInstituicao);
    getComittees();
    getDocumentosPorInstituicao(idInstituicao);
  }, []);


  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {

        const documentFile = formRef.current?.getFieldValue("nome_arquivo");
        const nomeFile = formRef.current?.getFieldValue("nome");

        if (!documentFile) {
          alert("Selecione o arquivo do documento!")
          return;
        };
        if (!nomeFile) {
          data["nome"] = documentFile.name
        };

        data['institution_id '] = idInstituicao;
        console.log(data);
        createDocumentosInstituicao(data);

        // addToast({
        //   type: 'success',
        //   title: 'Login efetuado',
        //   description: 'Seu login foi feito com sucesso!',
        // });

        // history.push('/dashboard');
      } catch (err) {
        // addToast({
        //   type: 'error',
        //   title: 'Erro na autenticação',
        //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        // });
      }
    },
    [],
  );

  const organizeData = (data) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        institution_id: d.institution_id,
        competencia: d.comittee.nome,
        documento: d.nome,
        arquivo: `${d.url_arquivo}/${d.nome_arquivo}`
      }));
    }
    return data;
  }


  const handlerDocument = () => {
    const docFile = formRef.current?.getFieldValue('document');
    console.log(docFile);
    setDocuments(
      [
        ...documents,
        {
          "document_name": formRef.current.getFieldValue('document_name'),
        }
      ])
  };

  const dataTitle: GridColDef[] = [
    { field: "institution_id", headerName: "ID da instituição", flex: 1 },
    { field: "competencia", headerName: "Nome da Competência", flex: 1 },
    { field: "documento", headerName: "Documento", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Link to={{ pathname: params.row.arquivo }} target="_blank">
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
              </Button>
            </Link>
            <Button variant="text" onClick={(e) => onDeleteClick(e, params.row)}>
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      }
    }
  ];

  function onDeleteClick(e: MouseEvent<HTMLButtonElement, MouseEvent>, row: any): void {
    deleteDocumentosInstituicao(row);
  }

  return (
    <Container>
      <Title color='#333'>
        <span>Documentos</span>
      </Title>
      <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
        <Panel titulo={`Instituição: ${instituicao.nome}`}>
          <Grid container spacing={6}>
            <Grid xs={12} lg={6} >
              <TextTitle>Competência: <span>*</span></TextTitle>
              <SelectInput
                id='idcompetencia'
                name='comittee_id'
                options={organizeDataSelect(comites.data, "id", "nome")}
                placeholder="Selecionar"
                required
              />
            </Grid>
            {/* <Grid xs={12} lg={7} >
              <TextTitle>Documento:</TextTitle>
              <Input type="text" name='nome' />
            </Grid> */}
            <Grid xs={6}>
              <TextTitle>Insira os anexos referentes à pauta: </TextTitle>
              <ButtonUploadArch for="nome_arquivo" id='selecionararquivo' name='selecionararquivo' type="button">
                <FontAwesomeIcon icon={faCloudArrowUp} size="xl" /> Selecione o arquivo ...
              </ButtonUploadArch>
              <InputFile id="nome_arquivo" name="nome_arquivo" style={{ display: 'none' }} />
            </Grid>
          </Grid>
          <Grid xs={12}>
            <ButtonAdd type="submit" onClick={handlerDocument}><FontAwesomeIcon icon={faPlus} /> Adicionar</ButtonAdd>
          </Grid>
          {documentosPorInstituicao.data ? documentosPorInstituicao.data.length > 0 && (
            <Grid xs={12}>
              <SecondaryTable
                rowsData={organizeData(documentosPorInstituicao.data)}
                columsData={dataTitle}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      institution_id: false
                    },
                  },
                }}
              />
            </Grid>
          ) : null}
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/membros/instituicoes">
          <button><FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar</button>
        </Link>
      </ContainerButton>
    </Container>
  )
}

