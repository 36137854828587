import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useHistoricoProcessoJuridico = () => {
    const history = useHistory();
    const [historicoProcessosJuridicos, setHistoricoProcessosJuridicos] = useState([]);
    const [historicoProcessoJuridico, setHistoricoProcessoJuridico] = useState([]);
    const [error, setError] = useState(null);

    async function getHistoricoProcessosJuridicos(params?: object) {
        try {
            const response = await api.get(`judicial_process_histories${stringfyParams(params)}`);
            setHistoricoProcessosJuridicos(response.data);
        } catch (e) {
            setError(e);
        }
    };

    async function getHistoricoProcessoJuridico(id: string) {
        try {
            const response = await api.get(`judicial_process_histories/${id}`);
            setHistoricoProcessoJuridico(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    };

    async function createHistoricoProcessoJuridico(data: any) {
        try {
            const response = await api.post('judicial_process_histories', data);
        } catch (e) {
            alert("Erro ao cadastrar Histórico do Processo jurídico");
            setError(e);
        }
    };

    async function updateHistoricoProcessoJuridico(id: string, data: any) {
        try {
            const response = await api.put(`judicial_process_histories/${id}`, data);
        } catch (e) {
            alert("Erro ao atualizar Histórico do Processo jurídico");
            setError(e);
        }
    };

    async function deleteHistoricoProcessoJuridico(id: string) {
        try {
            const response = await api.delete(`judicial_process_histories/${id}`);
        } catch (e) {
            alert("Erro ao deletar Histórico do Processo jurídico");        
            setError(e);
        }
    };


    return { historicoProcessosJuridicos, historicoProcessoJuridico, getHistoricoProcessosJuridicos, getHistoricoProcessoJuridico, createHistoricoProcessoJuridico, updateHistoricoProcessoJuridico, deleteHistoricoProcessoJuridico, error };
};

export default useHistoricoProcessoJuridico;