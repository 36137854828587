import styled from "styled-components";

export const ContainerInputFile = styled.div`
    position: relative;
    display: flex;
    height: 40px;
    width: 100%;

    margin: 10px 0 0;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #c4c4c4;
    background-color: #ffffff;
    z-index: 2;
`;

export const CustomInputFile = styled.label`
    height: 100%;
    width: 100%;
    padding: 0 0 0 1rem;

    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    svg {
        color: #366efa;
        transform: rotate(-45deg);
    }

    > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
`;

export const RemoveFileBtn = styled.button`
    padding: 0 1rem;
    background: none;
    border: none;
    
    svg {
        color: #993330;
    }
`;

export const InvisibleInput = styled.input`
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;