/**
 * Função responsável por retornar array de objetos (VALUE e LABEL)
 * da entidade passada como parametro para ser usado em um SELECT.
 *
 * @param {object} data Objeto a ser desestruturado
 * @param {string} v Nome do parâmetro VALUE
 * @param {string} l Nome do parâmetro LABEL principal
 * @param {Array} [multiLabel] Nome(s) do(s) parâmetro(s) LABEL opcional
 */
const organizeDataSelect = (data: object, v: string,  l: string, multiLabel?: object) => {
    if (typeof data === 'object') {
        data = data.map( d => 
            ({
                value: d[v],
                label: multiLabel ? `${d[l]} - ${multiLabel.map(t => d[t]).reduce((accumulator, currentValue) => `${accumulator} - ${currentValue}`)}` : d[l], // Se o parametro multiLabel existir retorna todas as chaves fornecidas para visualização do select
            })
        );
    }
    return data;
}
export default organizeDataSelect;