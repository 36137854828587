import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useDemandas = () => {
  const history = useHistory();
  const[demandas, setDemandas] = useState([]);
  const[demanda, setDemanda] = useState({
    demand_origin: {}
  });
  const [error, setError] = useState(null);


  // Lista todas as demandas
  async function getDemandas(params?: object){
    try{
      const response = await api.get(`demands${stringfyParams(params)}`);
      setDemandas(response.data);
    } catch(e){
      setError(e);
    }
  }

  // Busca demanda pelo ID
  async function getDemanda(id: number){
    try{
      const response = await api.get(`demands/${id}`);
      setDemanda(response.data);
    } catch(e){
      setError(e);
    }
  }

  return { getDemandas, demandas, getDemanda, demanda }
}

export default useDemandas;