import { PDFViewer } from '@react-pdf/renderer'
import DocumentPage from '../../../components/PDFComponents/DocumentPage';
import { useEffect, useState } from 'react';
import useProtocolo from '../../../actions/cruds/Protocolo/useProtocolo';
import { formatarData } from '../../../utils/formatDados';
import { useParams } from 'react-router-dom';

const ProtocoloFichaPDF: React.FC = () => {
  const { protocoloId } = useParams();
  const { protocolo, getProtocolo } = useProtocolo();

  const [protocoloData, setProtocoloData] = useState<Array<String>>([]);

  useEffect(() => {
    getProtocolo(protocoloId);
  }, [])

  useEffect(() => {
    if (protocolo?.id) {

      const dataInicio = new Date(protocolo?.data_arquivo);
      const dataFim = new Date(protocolo?.data_previsao_conclusao);

      const diferencaEmMilissegundos = Math.abs(
        dataFim.getTime() - dataInicio.getTime()
      );

      const differenceDate = Math.floor(
        diferencaEmMilissegundos / (1000 * 60 * 60 * 24)
      );

      let estimativaDias = differenceDate;

      const updatedProtocolo = [];
      if (protocolo?.local == 'interno') {
        updatedProtocolo.push(
          {
            id: "solicitante",
            row: "Solicitante:",
            value: protocolo?.representative?.id ? protocolo?.representative?.nome : protocolo?.solicitante?.id ? protocolo?.solicitante?.nome : "-"
          },
        );

        if (protocolo?.comittees?.length > 0 && protocolo?.comittees[0]?.id) {
          protocolo?.comittees?.forEach((comittee, index) => {
            if (index == 0) {
              updatedProtocolo.push(
                {
                  id: "comittees",
                  row: "",
                  value: "Comitês",
                },
              )
            }
            updatedProtocolo.push(
              {
                id: "comittee",
                row: `Comitê ${index + 1}: `,
                value: comittee?.nome ?? "-"
              },
            );
          });
        };

      } else if (protocolo?.local == 'externo') {
        updatedProtocolo.push(
          {
            id: "solicitante",
            row: "Solicitante externo:",
            value: protocolo?.external_requester?.nome ?? "-"
          },
          {
            id: "telefone",
            row: "Telefone:",
            value: protocolo?.external_requester?.telefone ?? "-"
          },
          {
            id: "email",
            row: "Email:",
            value: protocolo?.external_requester?.email ?? "-"
          },
          {
            id: "referencia",
            row: "Local de referência:",
            value: protocolo?.external_requester?.local ?? "-"
          },
        )
      };

      updatedProtocolo.push(
        {
          id: "nomeDocumento",
          row: "Nome do documento:",
          value: protocolo?.titulo_arquivo ?? "-"
        },
        {
          id: "dataDocumento",
          row: "Data do documento:",
          value: formatarData(protocolo?.data_entrada, false) ?? "-"
        },
        {
          id: "origemDemanda",
          row: "Origem da demanda:",
          value: protocolo?.demand_origin?.nome ?? "-"
        },
        {
          id: "demanda",
          row: "Demanda:",
          value: protocolo?.demanda ?? "-"
        },
        {
          id: "tipoEncaminhamento",
          row: "Tipo de encaminhamento:",
          value: `[ ${protocolo?.forwarding_type?.codigo == '01' ? "X" : "  "} ]   Administrativo          [ ${protocolo?.forwarding_type?.codigo == '04' ? "X" : "  "} ]   Institucional\n[ ${protocolo?.forwarding_type?.codigo == '02' ? "X" : "  "} ]   Estratégico                [ ${protocolo?.forwarding_type?.codigo == '05' ? "X" : "  "} ]   Jurídico\n[ ${protocolo?.forwarding_type?.codigo == '03' ? "X" : "  "} ]   Financeiro                 [ ${protocolo?.forwarding_type?.codigo == '06' ? "X" : "  "} ]   Técnico`
        },
        {
          id: "situacao",
          row: "Situação:",
          value: protocolo?.situacao ?? "-"
        },
        {
          id: "responsavel",
          row: "Responsável pela demanda:",
          value: protocolo?.user?.nome ?? "-"
        },
        {
          id: "dataEstimada",
          row: "Data estimada de conclusão:",
          value: formatarData(protocolo?.data_previsao_conclusao, false) ?? "-"
        },
        {
          id: "codigoEncaminhamento",
          row: "Código do encaminhamento:",
          value: protocolo?.codigo ?? "-"
        },
        {
          id: "estimativa",
          row: "Estimativa em dias:",
          value: `${estimativaDias} dias`
        },
        {
          id: "dataConclusao",
          row: "Data de conclusão:",
          value: formatarData(protocolo?.data_encerramento_atendimento, false) ?? "-"
        },
        {
          id: "observacao",
          row: "Observação:",
          value: protocolo?.observacao ?? "-"
        },
        {
          id: "comprovanteAtendimento",
          row: "Comprovação do atendimento:",
          value: protocolo?.data_encerramento_atendimento ? `${protocolo?.titulo_comprovacao ?? ""}\nData: ${protocolo?.data_encerramento_atendimento ?? "-"}` : "-"
        },
      );

      setProtocoloData(updatedProtocolo);
    };

  }, [protocolo]);

  if (protocolo?.id) {
    const pdfData = {
      version: "Formulário eletrônico - SIGA Águas",
      title: "FICHA DE PROTOCOLO",
      items: [
        {
          id: "agevapImg",
          logoWithColumns: true,
          itemsColumn: [
            {
              id: "protocoloSiga",
              row: "PROTOCOLO SIGA Nº",
              value: protocolo?.codigo ?? "-"
            },
            {
              id: "processoSigad",
              row: "CÓDIGO NUP - SIGAD",
              value: protocolo?.codigo_nup ?? "-"
            },
          ]
        },
        {
          id: "dataProtocolo",
          row: "Data de entrada",
          value: formatarData(protocolo?.data_entrada, false) ?? "-"
        },
        {
          id: "tipoDemanda",
          row: "Tipo da demanda:",
          value: `[ ${protocolo?.local == 'interno' ? "X" : "  "} ] Interno           [ ${protocolo?.local == 'externo' ? "X" : "  "} ] Externo`
        },
        {
          id: "organizacao",
          row: "Organização:",
          value: protocolo?.organization?.nome ?? "-"
        },
        {
          id: "despesa",
          row: "Despesa:",
          value: `[ ${protocolo?.expense_type?.codigo == '4' ? "X" : "  "} ] Custeio           [ ${protocolo?.expense_type?.codigo == '5' ? "X" : "  "} ] Investimento`,
        },
        {
          id: "instancia",
          row: "Instância:",
          value: protocolo?.protocol_instance?.nome ?? "-"
        },
      ],
    };

    if (protocoloData?.length > 0) {
      protocoloData.forEach((item) => pdfData.items.push(item))
    };

    return (
      <PDFViewer width="100%" height="1300" className="app" >
        <DocumentPage data={pdfData} />
      </PDFViewer>
    );
  };
};


export default ProtocoloFichaPDF;