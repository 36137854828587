import { styled } from "styled-components";
import Select from 'react-select';

export const Container = styled.div`
    width: 100%;
    height: 87px;
    background-color: #F2EBF2;
    box-shadow: 0px 3px 6px #00000029;
`

export const ContentContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: end;
    align-items: center;
`;

export const ContentMenu = styled.div`
    display: flex;
    padding-right: 50px;
    gap: 40px;

    a {
        color: #373737;
    }

    .logout {
        color: #993330;
    } 

    p {
        font-size: 1.5rem;
        font-weight: 500;
    }
`;

export const SelectOrg = styled(Select)`
    width: 280px;
`;