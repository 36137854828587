import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useOrgao = () => {
  const history = useHistory();

  const [orgaos, setOrgaos] = useState([]);
  const [orgao, setOrgao] = useState({});
  const [error, setError] = useState(null);

  async function getOrganizations(params?: object) {
    try {
      const response = await api.get(`organizations${stringfyParams(params)}`);
      setOrgaos(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function getOrganization(id: string) {
    try {
      const response = await api.get(`organizations/${id}`);
      setOrgao(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function createOrganization(data: any) {
    try {
      const response = await api.post("organizations", data);
      alert("Organização criado com sucesso!");
      history.goBack();
    } catch (e) {
      alert("Erro: Não foi possível cadastrar!");
      setError(e);
    }
  }

  // Atualiza a Orgao Gestor pelo id
  async function updateOrganizarion(id: string, data: any) {
    try {
      const response = await api.put(`organizations/${id}`, data);
      alert("Organização editada com sucesso!");
      history.goBack();

      return response.data;
    } catch (e) {
      alert("Erro: Não foi possível editar.");
      setError(e);
    }
  }

  async function DesativarOrganization(id: any, data: any) {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("ativo", data);

    try {
      const response = await api.put(`organizations/${id}`, formData);
      window.location.reload();
      return response.data;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  return {
    orgao,
    orgaos,
    error,
    getOrganization,
    getOrganizations,
    createOrganization,
    updateOrganizarion,
    DesativarOrganization,
  };
};

export default useOrgao;
