import React, { useEffect, useRef, useState } from "react";
import {
  ContainerButton2,
  Form,
  Input,
  TextTitle,
  Textarea,
  Title,
} from "../../../../styles/global";
import Panel from "../../../../components/Panel";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { formatarReais } from "../../../../utils/formatDados";
import Radio from "../../../../components/InputRadio";
import SecondaryTable from "../../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import useDotacao from "../../../../actions/cruds/Orcamentario/useDotacao";
import useProcesso from "../../../../actions/cruds/Processos/useProcesso";

const OrcamentarioDotacaoView: React.FC = () => {
  const { dotacaoId } = useParams();
  const { dotacao, getDotacao } = useDotacao();
  const { processo, getProcesso } = useProcesso();

  const [linhasProjeto, setLinhasProjeto] = useState<Array<Object>>([]);

  useEffect(() => {
    getDotacao(dotacaoId);
  }, []);

  useEffect(() => {
    if (dotacao?.id) {
      formRef.current?.setFieldValue("rateio", `${dotacao?.rateio}`);
      formRef.current?.setFieldValue("parecer", dotacao?.parecer);
      getProcesso(dotacao?.law_process?.id);
    }

  }, [dotacao])

  const organizeDataLinhas = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d?.project_line?.id,
          codigo: `${d?.project_line.expense_type?.codigo}.${d?.project_line?.organization?.codigo.padStart(2, '0')}.${d?.project_line?.management_contract?.codigo.padStart(2, '0')}.${d?.project_line?.comittee?.codigo.padStart(2, '0')}.${d?.project_line?.unit?.codigo.padStart(2, '0')}.${d?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${d.project_line.pap_program.codigo.padStart(2, '0')}.${d.project_line.pap_action.codigo.padStart(2, '0')}.${d.project_line.poa_sub_action.codigo.padStart(2, '0')}.${processo?.project?.id.toString().padStart(2, '0')}.${d?.project_line?.description?.codigo.padStart(3, '0')}`,
          pap: `${d?.project_line?.pap_finality?.codigo}.${d?.project_line?.pap_program?.codigo}.${d?.project_line?.pap_action?.codigo}`,
          valor: formatarReais(d?.valor),
        }));
        return data;
      }
    }
    return [];
  };

  const optionsBoolean = [
    { id: 'true', value: 'true', label: "Sim" },
    { id: 'false', value: 'false', label: "Não" },
  ];

  const parecerOptions = [
    { id: "Prosseguir", value: "Prosseguir", label: "Prosseguir" },
    { id: "Não Prosseguir", value: "Não Prosseguir", label: "Não Prosseguir" },
  ]

  const formRef = useRef<FormHandles>(null);

  const dataTitleLinhas: GridColDef[] = [
    { field: "codigo", headerName: "Código do projeto", minWidth: 220, flex: 3 },
    { field: "pap", headerName: "Código do PAP", minWidth: 115, flex: 1 },
    { field: "valor", headerName: "Valor da Dotação", minWidth: 115, flex: 2 },
  ];

  return (
    <Container>
      <Form id="formDotacao" ref={formRef} >
        <Title color="#333">
          <span>Visualizar Dotação</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data da solicitação:</TextTitle>
              <Input type="date" name="dataSolicitacao" value={dotacao?.data_solicitacao} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número do processo:</TextTitle>
              <Input type="text" name="numProcesso" value={`${dotacao?.law_process?.codigo} - (SIGAD - ${dotacao?.law_process?.numero})`} disabled />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Assunto:</TextTitle>
              <Input type="text" name="assunto" value={dotacao?.assunto} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Tipo de atividade:</TextTitle>
              <Input type="text" name="tipoAtividade" value={dotacao?.tipo_atividade} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>O projeto é um rateio?</TextTitle>
              <Radio
                name="rateio"
                options={optionsBoolean}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Linha">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {dotacao?.allocation_budgets?.length > 0 && processo?.id && (
                <>
                  <SecondaryTable
                    columsData={dataTitleLinhas}
                    rowsData={organizeDataLinhas(dotacao?.allocation_budgets)}
                  />
                  <Grid xs={6}>
                    <TextTitle>Total: {formatarReais(dotacao?.allocation_budgets?.reduce(function (acc, obj) { return acc + parseFloat(obj?.valor); }, 0))}</TextTitle>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Parecer da análise">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Parecer da solicitação de pagamento:</TextTitle>
              <Radio
                name="parecer"
                options={parecerOptions}
                disabled
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Justificativa:</TextTitle>
              <Textarea name="justificativa" defaultValue={dotacao?.justificativa} disabled></Textarea>
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/orcamentario/dotacao">
          <button className="button_return">
            <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
          </button>
        </Link>
        <Link to={`/orcamentario/dotacao/fichaPDF/${dotacaoId}`} target="_blank">
          <button className="button_save">
            <FontAwesomeIcon icon={faDownload} size="1x" /> Download PDF
          </button>
        </Link>
      </ContainerButton2>
    </Container >
  );
};

export default OrcamentarioDotacaoView;
