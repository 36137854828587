import React, { useEffect, useRef } from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ContainerButton,
  Input,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import Panel from "../../../../../components/Panel";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link, useParams } from "react-router-dom";
import useGerencia from "../../../../../actions/cruds/useGerencia";
// import { Container } from './styles';

const GerenciaRhView: React.FC = () => {
  const { getGerencia, gerencia } = useGerencia();
  const { gerenteId } = useParams();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    getGerencia(gerenteId);
  }, []);

  return (
    <Container>
      <Title>
        <span>Visualizar Gerência</span>
      </Title>

      <Form id="form1" ref={formRef}>
        <Panel titulo="Gerência">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Nome:</TextTitle>
              <Input type="text" name="nome" value={gerencia.nome} disabled />
            </Grid>
          </Grid>
        </Panel>
        <ContainerButton>
          <Link to="/rh/cadastros/gerencia">
            <button>Voltar</button>
          </Link>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default GerenciaRhView;
