import {
  faChevronLeft, faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  ButtonDownloadArch,
  ContainerButton2, Form, Input, TextTitle, Textarea, Title
} from '../../../../../styles/global';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useEffect, useRef } from "react";
import Panel from "../../../../../components/Panel";
import { Link, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "../../../../../components/InputRadio";
import useFerias from "../../../../../actions/cruds/Rh/useFerias";

const RhFeriasView: React.FC = () => {
  const { feriasId } = useParams();
  const { ferias, getFerias } = useFerias();

  useEffect(() => {
    getFerias(feriasId);
  }, []);

  useEffect(() => {
    console.log(formRef.current?.getFieldRef("adiantamento"))
    if (ferias?.user?.employee_type?.key == 'empregado') {
      formRef.current?.setFieldValue("adiantamento", ferias?.adiantamento ? "true" : "false");
    }

  }, [ferias])

  const formRef = useRef<FormHandles>(null);

  const optionsBoolean = [
    { id: "true", value: "true", label: "Sim" },
    { id: "false", value: "false", label: "Não" },
  ];

  return (
    <Container>
      <Form id="formFerias" ref={formRef}>
        <Title color='#333'>
          <span>Visualizar Férias do empregado</span>
        </Title>

        <Panel titulo="Exercício">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data de Admissão:</TextTitle>
              <Input name="data_admissao" type="date" value={ferias?.user?.data_admissao} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número de dias:</TextTitle>
              <Input type="text" name="quantidade_dias" value={ferias?.quantidade_dias} disabled />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Período aquisitivo de:</TextTitle>
              <Input name="periodo_aquisitivo_inicio" type="date" value={ferias?.periodo_aquisitivo_de} disabled />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>até:</TextTitle>
              <Input name="periodo_aquisitivo_termino" type="date" value={ferias?.periodo_aquisitivo_ate} disabled />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Período para gozo de:</TextTitle>
              <Input type="date" name="periodo_gozo_inicio" value={ferias?.periodo_gozo_de} disabled />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>até:</TextTitle>
              <Input type="date" name="periodo_gozo_termino" value={ferias?.periodo_gozo_ate} disabled />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Programação de Férias">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Início:</TextTitle>
              <Input name="data_inicio" type="date" value={ferias?.inicio} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Término:</TextTitle>
              <Input name="data_termino" type="date" value={ferias?.termino} disabled />
            </Grid>
            {ferias?.user?.employee_type?.key == 'empregado' && (
              <Grid xs={6}>
                <TextTitle>Adiantamento do 13º?</TextTitle>
                <Radio name="adiantamento" options={optionsBoolean} disabled />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Confirmação das férias">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data:</TextTitle>
              <Input name="data_confirmacao" type="date" value={ferias?.data_confirmacao} disabled />
            </Grid>
            {ferias?.url_arquivo && (
              <Grid xs={6}>
                <TextTitle>Documento:</TextTitle>
                <Link
                  to={{
                    pathname: `${ferias?.url_arquivo}/${ferias?.nome_arquivo}`,
                  }}
                  target="_blank"
                >
                  <ButtonDownloadArch name='selecionararquivo' type="button">
                    <FontAwesomeIcon icon={faDownload} size="1x" /> Download...
                  </ButtonDownloadArch>
                </Link>
              </Grid>
            )}
            <Grid xs={12}>
              <TextTitle>Observação:</TextTitle>
              <Textarea name="observacao" defaultValue={ferias?.observacao} disabled />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to={`/rh/empregados/ferias/${ferias?.user?.id}`}>
          <button className="button_return">
            <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
          </button>
        </Link>
      </ContainerButton2>
    </Container>
  );
};

export default RhFeriasView;