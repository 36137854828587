import {
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";


import {
  ContainerButton,
  Form,
  FormContainer,
  Input,
  TextTitle,
  Title
} from '../../../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";

import {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import { Link, useParams } from "react-router-dom";

import useDespesas from "../../../../../actions/cruds/useDespesas";

import Panel from "../../../../../components/Panel";
import { Container } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';


interface UpdateDespesa {
  id: string;
  codigo: string;
  nome: string;
}

export function EditDespesa() {

  const formRef = useRef<FormHandles>(null);
 
  const {despesas, getDespesaId, updateDespesa} = useDespesas();

  const {idDespesa} = useParams();
  
  useEffect(() => {
    getDespesaId(idDespesa);
  }, []);

  useEffect(() => {
    formRef.current?.setFieldValue("codigo", despesas.codigo);
  }, [despesas]);
  
  const handleSubmit = useCallback(
    async(data: UpdateDespesa) => {
        try {
          updateDespesa(idDespesa, data);
        }catch(err) {
  
        }
      },
      [],
  );

  return (
    <Container>
      <Title color='#333'>
        <span>
          Editar despesa
        </span>
      </Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Despesa">
          <Grid container spacing={6}>
            <Grid xs={2}>
                <TextTitle>Código: <span>*</span></TextTitle>
                <Input 
                  mask="_"
                  replacement="allNumber"
                  id="codigo" 
                  name='codigo' 
                  required
                  // defaultValue={despesas.codigo}
                />
            </Grid>
            <Grid xs={10}>
                <TextTitle>Despesa: <span>*</span></TextTitle>
                <Input 
                  id="nome"
                  type="text" 
                  name='nome'
                  defaultValue={despesas.nome}
                  required
                /> 
            </Grid>
          </Grid>                        
        </Panel>
      </Form>
      <ContainerButton>
        <Link to="/orcamentario/cadastros/despesas">
          <button>
            <FontAwesomeIcon icon={faX} size="1x"/> Cancelar
          </button>
        </Link>
        <button type="submit" onClick={() =>formRef.current?.submitForm()}>
          <FontAwesomeIcon icon={faSave}/> Salvar
        </button>
      </ContainerButton>
    </Container>
  )
}