import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  input {
    border: 1px solid #cccccc;
    width: 100%;
    border-radius: 5px;
    margin-top: 4px;
    height: 32px;
    padding: 10px 4px;
  }

  hr {
    margin-top: 8px;
  }

  .modal {
    border-radius: 10px;
    width: 40%;
    background-color: #fff;
  }

  .header {
    display: flex;
    justify-content: space-between;

    background-color: #3cc3f6;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    > button {
      border: none;
      background-color: #3cc3f6;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    h2 {
      color: #fff;
      font-weight: 700;
    }
  }

  .content {
    padding: 20px;

    > h3 {
      margin-top: 24px;
      font-weight: bold;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 20px;
  }

  .button_save {
    border: none;
    background-color: #366efa;
    color: #fff;
    padding: 8px 10px;
    border-radius: 6px;
  }
  .button_cancel {
    border: 1px solid #cccccc;
    background-color: #fff;
    padding: 8px 10px;
    border-radius: 6px;
  }
`;
