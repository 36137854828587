import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";

const useAditivoInstrumento = () => {

    const history = useHistory();
    const [aditivoInstrumento, setAditivoinstrumento] = useState([]);
    const [aditivosInstrumento, setAditivosInstrumento] = useState([]);
    const [error, setError] = useState(null);

    async function getAditivosInstrumento() {
        try {
            const response = await api.get('additives');
            setAditivosInstrumento(response.data);
        } catch (e) {
            setError(e);
        }
    }

    async function getAditivoInstrumento(id: string) {
        try {
            const response = await api.get(`additives/${id}`);
            setAditivoinstrumento(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    }

    async function createAditivoInstrumento(data: object) {
        try {
            const response = await api.post('additives', data,
            {
                headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            alert("Aditivo cadastrado com sucesso!");
            history.goBack();

        } catch (e) {
            setError(e);
        }
    }

    async function updateAditivoInstrumento(id: string, data: object) {
        try {
            const response = await api.put(`additives/${id}`, data,
            {
                headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            alert("Aditivo atualizado com sucesso!");
            history.goBack();
            return response
        } catch (e) {
            setError(e);
            // console.log(error);
        }        
    }


    return {aditivosInstrumento, aditivoInstrumento, getAditivosInstrumento, getAditivoInstrumento, createAditivoInstrumento, updateAditivoInstrumento, error};
}

export default useAditivoInstrumento;