import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/material';
import { ButtonUploadArch } from '../../styles/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faPaperclip, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ContainerInputFile, CustomInputFile, InvisibleInput, RemoveFileBtn } from './styles';
import { formatarTamanhoArquivo } from '../../utils/formatDados';

interface Props {
  name: string;
}
type InputProps = JSX.IntrinsicElements['input'] & Props;
export default function InputFile({ name, id, ...rest }: InputProps) {

  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const [fileSize, setFileSize] = useState("");

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.files?.[0]);
    if (e.target.files?.[0] === undefined) {
      setPreview(null);
      setFileSize("");
      return;
    } else {
      const nameFile = e.target.files?.[0].name;
      const sizeFile = e.target.files?.[0].size;
      setPreview(nameFile);
      setFileSize(formatarTamanhoArquivo(sizeFile));
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  const clearFile = () => {
    inputRef.current.value = null;
    setPreview(null);
  }

  return (
    <div style={{position: 'relative'}}>      
      <ContainerInputFile>
        <CustomInputFile type='button' for={name}>
          <FontAwesomeIcon icon={faPaperclip} size="lg" />
          <div>
            {preview ?
              <><p>{preview}</p><small>{fileSize}</small></>
              : <p>Insira um arquivo</p>}
          </div>
        </CustomInputFile>
        {preview &&
          <>
            <RemoveFileBtn type='button' onClick={clearFile}>
              <FontAwesomeIcon icon={faXmark} />
            </RemoveFileBtn>
          </>
        }
      </ContainerInputFile>

      <InvisibleInput type="file" id={name} ref={inputRef} onChange={handlePreview} {...rest} />
    </div>
  );
};