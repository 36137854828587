import {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { FormHandles } from "@unform/core";
import {
  faCloudArrowUp,
  faPlus,
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "../../../../components/Panel";
import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton,
  ContainerButton2,
  Form,
  FormContainer,
  Input,
  Instancia,
  SelectInput,
  TextTitle,
  Title
} from "../../../../styles/global";
import { Link, useParams } from "react-router-dom";
import InputFile from "../../../../components/InputFile";
import useAditivoInstrumento from "../../../../actions/cruds/InstrumentoContratual/useAditivo.ts";
import useInstrumento from "../../../../actions/cruds/InstrumentoContratual/useInstrumento";
import Radio from "../../../../components/InputRadio";
import useAcao from "../../../../actions/cruds/useAcao.ts";
import useComite from "../../../../actions/cruds/useComite";
import useContratoGestao from "../../../../actions/cruds/useContratoGestao";
import useDespesas from "../../../../actions/cruds/useDespesas";
import useFinalidades from "../../../../actions/cruds/useFinalidade";
import useOrgao from "../../../../actions/cruds/useOrgao";
import usePrograma from "../../../../actions/cruds/usePrograma";
import useSubAcao from "../../../../actions/cruds/useSubAcao";
import useProjeto from "../../../../actions/cruds/useProjeto";
import useUnidade from "../../../../actions/cruds/useUnidade";
import useDescricao from "../../../../actions/cruds/useDescricao";
import useCodigoContabil from "../../../../actions/cruds/useCodigoContabil";
import { Button, Container, DialogContent, Tab, Tabs } from "@mui/material";
import BasicDialog from "../../../../components/Dialog";
import SecondaryTable from "../../../../components/SecondaryTable";
import Grid from "@mui/material/Unstable_Grid2";
import InputFilev2 from "../../../../components/InputFilev2/index.tsx";
import { SaveWithProgress } from "../../../../components/SaveCircularIntegration/index.tsx";
import { formatarReais, formatarRealparaDecimal } from "../../../../utils/formatDados";
import ModalCodigoContabil from "../../../../template_parts/ModalCodigoContabil";
import usePapFinalidade from "../../../../actions/cruds/usePapFinalidade";
import usePap from "../../../../actions/cruds/usePap";
import usePoa from "../../../../actions/cruds/usePoa";
import organizeDataSelect from "../../../../utils/organizeDataSelect";
import { GridActionsCellItem, GridRenderEditCellParams, GridValueFormatterParams, useGridApiRef } from "@mui/x-data-grid";
import InputNumberFormat from "../../../../components/InputNumberFormat";


interface AditivoProps {

  contractual_instrument_id: string,
  nome: string,
  aditivo_valor: boolean,
  aditivo_prazo: boolean,
  aditivo_outros: boolean,
  descricao: string,
  data_assinatura: string,
  data_inicio_vigencia: string,
  data_termino_vigencia: string,
  motivo_valor: string,
  valor: number,
  mesma_dotacao: boolean,
  pagamento_rateado: boolean,
  aditivo_descricao: string,
  aditivo_arquivo: File,
  publicacao_descricao: string,
  publicacao_data: string,
  publicacao_arquivo: File,
  ativo: boolean,
  excluido: boolean,
  additive_budgets_attributes: [{
    accounting_code_id: number,
    valor: number,
    percentual: number

  }]
}

const InstrumentoAditivoCreate: React.FC = () => {

  const { createAditivoInstrumento } = useAditivoInstrumento();
  const { instrumento, getInstrumento, ultimaRubricaInstrumento, getUltimaRubricaInstrumento } = useInstrumento();
  const { despesas, getDespesas } = useDespesas();
  const { orgaos, getOrganizations } = useOrgao();
  const { contrGestao, getContratos } = useContratoGestao();
  const { comites, getComittees } = useComite();
  const { unidadesComite, getUnidadesComite } = useUnidade();
  const { unidades, getUnidades } = useUnidade();
  const { finalidades, getFinalidades } = useFinalidades();
  const { programas, getProgramas } = usePrograma();
  const { acao, getAcoes } = useAcao();
  const { subacao, getSubAcao } = useSubAcao();
  const { projetos, getProjetos } = useProjeto();
  const { descricao, getDescricoes } = useDescricao();
  const { codigosContabeis, getCodigosContabeis, setCodigosContabeis } = useCodigoContabil();

  // Novas inportações para novo vinculo de rubricas
  const { projeto } = useProjeto();
  const { selectComite, getComittee, setComites, setSelectComite } = useComite();
  const { setContrGestao } = useContratoGestao();
  const { getPapFinalidades } = usePapFinalidade();
  const { getPaps, paps, getPap, selectedPap, setPaps, setSelectedPap } = usePap();
  const { getPoas, poas } = usePoa();
  const [yearsPoa, setYearsPoa] = useState([]);
  const [filtredPrograms, setFiltredPrograms] = useState([]);
  const [filtredActions, setFiltredActions] = useState([]);
  const [filtredSubactions, setFiltredSubactions] = useState([]);


  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [tipoValor, setTipoValor] = useState<Boolean>(false);
  const [tipoPrazo, setTipoPrazo] = useState<Boolean>(false);
  const [tipoOutros, setTipoOutros] = useState<Boolean>(false);
  const [valorInstrumentoAtualizado, setValorInstrumentoAtualizado] = useState<Number>(0);
  const [valorAditivo, setValorAditivo] = useState<Number>(0);
  const [dotacao, setDotacao] = useState<Boolean>();
  const [rateado, setRateado] = useState<Boolean>();
  const [valueTipoDespesa, setValueTipoDespesa] = useState<Object>();
  const [valueOrganizacao, setValueOrganizacao] = useState<Object>();
  const [optionsContrato, setOptionsContrato] = useState<Object>([]);
  const [valueContrato, setValueContrato] = useState<Object>();
  const [optionsComite, setOptionsComite] = useState<Object>([]);
  const [valueComite, setValueComite] = useState<Object>();
  const [optionsUnidade, setOptionsUnidade] = useState<Object>([]);
  const [valueUnidade, setValueUnidade] = useState<Object>();
  const [optionsFinalidade, setOptionsFinalidade] = useState<Object>([]);
  const [valueFinalidade, setValueFinalidade] = useState<Object>();
  const [optionsPrograma, setOptionsPrograma] = useState<Object>([]);
  const [valuePrograma, setValuePrograma] = useState<Object>();
  const [optionsAcao, setOptionsAcao] = useState<Object>([]);
  const [valueAcao, setValueAcao] = useState<Object>();
  const [optionsSubacao, setOptionsSubacao] = useState<Object>([]);
  const [valueSubacao, setValueSubacao] = useState<Object>();
  const [optionsProjeto, setOptionsProjeto] = useState<Object>([]);
  const [valueProjeto, setValueProjeto] = useState<Object>();
  const [optionsDescricao, setOptionsDescricao] = useState<Object>([]);
  const [valueDescricao, setValueDescricao] = useState<Object>();
  const [valueCodigoContabil, setValueCodigoContabil] = useState<Array<Object>>([]);
  const [codigoContabil, setCodigoContabil] = useState<Array<Object>>();

  const [valueTab, setValueTab] = useState<number>(0);

  const [rubricaFront, setRubricaFront] = useState([]);
  const [rubricaBack, setRubricaBack] = useState([]);
  const [salvando, setSalvando] = useState<boolean>(false);

  const [valorLinhas, setValorLinhas] = useState<Array<Object>>([]);
  const [linhasProjeto, setLinhasProjeto] = useState<Array<Object>>([]);

  const { instrumentId } = useParams();

  useEffect(() => {
    getInstrumento(instrumentId);
    getUltimaRubricaInstrumento(instrumentId);
    getDespesas();
    getOrganizations();
    // getComittees();
    // getContratos();
    getFinalidades();
    getProgramas();
    getAcoes();
    getSubAcao();
    getProjetos();
    getUnidadesComite();
    getUnidades();
    getDescricoes();
    // getCodigosContabeis();
  }, [instrumentId])

  useEffect(() => {
    setValorInstrumentoAtualizado(instrumento.valor_atual);
    setValorAditivo(instrumento.valor_atual);
  }, [instrumento]);

  useEffect(() => { // quando um PAP for selecionado ele preenche os anos para o POA
    const {periodo_de, periodo_ate} = selectedPap;

    const aux = [];
    for (let y: number = periodo_de; y <= periodo_ate; y++) {
        aux.push( {label: y, value: y} );
    }
    setYearsPoa(aux);
    
  }, [selectedPap]);

  useEffect(() => {
    // console.log(poas)
    if (poas.data && poas.data.length) {
      const pap_action_id = formRef.current?.getFieldValue("pap_action_id");
      const filter = poas.data[0].subacoes.filter(p => p.pap_action.id === pap_action_id); // data[0] porque de acordo com as regras de negocio deve existir apenas 1 unico POA vinculado ao mesmo PAP e ao mesmo ANO_POA
      setFiltredSubactions(filter);
    }
  }, [poas]);

  useEffect(() => {
    if (valueTipoDespesa && valueProjeto && valueDescricao) {
      const codigoContabilRubrica = codigosContabeis.data.filter(obj => obj.expense_type.id == valueTipoDespesa.value && obj.organization.id == valueOrganizacao.value && obj.management_contract.id == valueContrato.value && obj.comittee.id == valueComite.value && obj.unit.id == valueUnidade.value && obj.finality.id == valueFinalidade.value && obj.program.id == valuePrograma.value && obj.tbl_action.id == valueAcao.value && obj.sub_action.id == valueSubacao.value && obj.project.id == valueProjeto.value && obj.description.id == valueDescricao.value);
      setValueCodigoContabil([])
      setCodigoContabil([])
      if (codigoContabilRubrica.length) {
        setValueCodigoContabil(codigoContabilRubrica[0].codigo_contabil)
        setCodigoContabil(organizeDataCodigoContabil(codigoContabilRubrica));
      } else {
        alert("Código Contábil não encontrado");
      }
    }
  }, [valueTipoDespesa, valueProjeto, valueDescricao]);

  const organizeDataCodigoContabil = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.id,
        label: d.codigo_contabil,
      }));
    }

    return data;
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };

  const resetarOptions = (...optionsField) => {
    optionsField.forEach((optionsField) => {
      optionsField([]);
    });
  };
  const resetarValues = (...fieldName) => {
    fieldName.forEach((fieldName) => {
      const field = formRef.current?.getFieldRef(fieldName);
      if (field) {
        field.clearValue();
      }
    })
  };

  const apiRef = useGridApiRef();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: AditivoProps) => {
      try {
        setSalvando(true);

        data["contractual_instrument_id"] = instrumentId;
        data["aditivo_valor"] = tipoValor;
        data["aditivo_prazo"] = tipoPrazo;
        data["aditivo_outros"] = tipoOutros;
        const valor = formRef.current?.getFieldValue('valor');
        data['valor'] = formatarRealparaDecimal(valor);
        const novoValorContrato = formRef.current?.getFieldValue('novo_valor_contrato');
        data["novo_valor_contrato"] = formatarRealparaDecimal(novoValorContrato);

        if (!tipoValor && !tipoPrazo && !tipoOutros) {
          alert("Selecione um tipo de Aditivo");
          return;
        };

        if (tipoValor || tipoOutros) {
          data["additive_budgets_attributes"] = rubricaBack
        };

        if (tipoOutros && !tipoValor) {
          data["novo_valor_contrato"] = formatarRealparaDecimal(valorAditivo);
        };


        const rowsData = Array.from( apiRef.current.getRowModels(), ([, value]) => (value) );
        const rubricaSemValor = rowsData.every(row => row.valor);

        if (!rubricaSemValor) {
          alert("Todas as linhas da Rubrica orçamentária devem conter valor.");
          setSalvando(false);
          return;
        }

        data["additive_budgets_attributes"] = rowsData.map(d => ({
          accounting_code_id: d.id,
          valor: d.valor
        }));

        console.log(data);
        await createAditivoInstrumento(data);

      } catch (err) {
        setSalvando(false);
      } finally {
        setSalvando(false);
      }
    },
    [instrumentId, tipoValor, tipoPrazo, tipoOutros, rateado, valorAditivo, codigoContabil, rubricaBack, apiRef, formRef],
  );

  const handleChangeTipoValor = (tipo: object) => {
    let valorChecked = tipo.target.checked;
    setTipoValor(valorChecked)
    setValorAditivo(0)
    if (valorChecked === false && tipoOutros) {
      setValorAditivo(instrumento.valor_atual);
    }
  };
  const handleChangeTipoPrazo = (tipo: object) => {
    let prazoChecked = tipo.target.checked;
    setTipoPrazo(prazoChecked);
  };
  const handleChangeTipoOutros = (tipo: object) => {
    let outrosChecked = tipo.target.checked;
    setTipoOutros(outrosChecked);
    if (!tipoValor) {
      setValorAditivo(instrumento.valor_atual);
    }
  };

  const handleChangeMotivo = (motivo: object) => {
    let valorAdicionado = formRef.current?.getFieldValue("valor");
    let novoValor = 0;

    if (motivo.target.id === 'motivoSim') {
      if (valorAdicionado) {
        novoValor = instrumento.valor_atual + parseInt(valorAdicionado);
        setValorInstrumentoAtualizado(novoValor);
      }
    } else if (motivo.target.id === 'motivoNao') {
      if (valorAdicionado) {
        if (valorAdicionado > instrumento.valor_atual) {
          valorAdicionado = instrumento.valor_atual;
          formRef.current?.setFieldValue("valor", valorAdicionado);
          alert("O valor do Aditivo não pode subtrair além do valor total do contrato");
        }
        novoValor = instrumento.valor_atual - parseInt(valorAdicionado);
        setValorInstrumentoAtualizado(novoValor);
      }
      setValorAditivo(valorAdicionado);
    }
  };

  const handleChangeValor = (valor: object) => {
    var novoValor = 0;
    var valorField = valor;
    const motivo = formRef.current?.getFieldValue("motivo_valor");

    if (!valorField) {
      setValorInstrumentoAtualizado(instrumento.valor_atual);
    } else if (motivo === 'acrescimo') {
      novoValor = instrumento.valor_atual + parseFloat(valorField);
      setValorInstrumentoAtualizado(novoValor);
    } else if (motivo === 'supressao') {

      console.log(instrumento, valorField);

      novoValor = instrumento.valor_atual - parseFloat(valorField);
      if (valorField > instrumento.valor_atual) {
        novoValor = 0;
        valor = instrumento.valor_atual;
        alert("O valor do Aditivo não pode subtrair além do valor total do contrato");
      }
      setValorInstrumentoAtualizado(novoValor);
    }
    setValorAditivo(valor);
  };

  const handleChangeDotacao = (dotacao: object) => {
    if (dotacao.target.id === 'dotacaoSim') {
      setDotacao(true);
    } else if (dotacao.target.id === 'dotacaoNao') {
      setDotacao(false);
    }
  };
  const handleChangeRateado = (rateado: object) => {
    if (rateado.target.id === 'rateadoSim') {
      setRateado(true);
    } else if (rateado.target.id === 'rateadoNao') {
      setRateado(false);
    }
  };

  const organizeData = (data) => {
    data = {
      id: data.id,
      contrato: data.management_contract.nome_completo,
      comite: data.comittee.nome,
      codigo_contabil: `${data.expense_type.codigo}.${data.organization.codigo.padStart(2, '0')}.${data.management_contract.codigo.padStart(2, '0')}.${data.comittee.codigo.padStart(2, '0')}.${data.unit.codigo.padStart(2, '0')}.${data.pap_finality.codigo.padStart(2, '0')}.${data.pap_program.codigo.padStart(2, '0')}.${data.pap_action.codigo.padStart(2, '0')}.${data.poa_sub_action.codigo.padStart(2, '0')}.${data.project.id.toString().padStart(2, '0')}.${data.description.codigo.padStart(3, '0')}`
    };
    return data;
  };

  const handleAddRubrica = () => {

    // console.log(codigosContabeis);

    if (!codigosContabeis?.data || !codigosContabeis?.data.length) {
      alert('Pesquise a Rubrica antes. O campo \'Rubrica final\' deve estar preenchido.');
    } else {
      setRubricaFront([ ...rubricaFront, organizeData(codigosContabeis.data[0]) ]);
    }

    // handleCloseDialog();
  };

  function onDeleteRubricaClick(id: number, row: any): void {
    setRubricaFront(rubricaFront.filter((el) => el.id !== id));
    
    // const objUpdatedRow = { id: updatedRow?.id, val: updatedRow?.valor };
    const updatedValueLinhas = [...valorLinhas.filter((linha) => linha.id !== id)];
    setValorLinhas(updatedValueLinhas);
    // console.log(updatedValueLinhas);

    // setRubricaBack(rubricaBack.filter((el) => el.front_id !== row.front_id));
  }

  const dataTitleRubrica: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    { 
      field: "valor",
      headerName: "Valor (R$)",
      flex: 2,
      editable: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
            return "Defina um valor!";
        }
        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <InputNumberFormat {...params} />
      ),
    },
    {
      field: "percentual",
      headerName: "Percentual (%)",
      flex: 1,
      valueGetter: (params) => {
        if (!params.row.valor || !valorInstrumentoAtualizado) {
          return "Defina valor da linha e valor do instrumento!";
        }
        return ((params.row.valor / valorInstrumentoAtualizado) * 100).toFixed(2);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    },
    {
      field: "actions",
      type: 'actions',
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      getActions: ({ id }) => {
        return [
            <GridActionsCellItem
              icon={<FontAwesomeIcon icon="fa-solid fa-trash-can" />}
              label="Delete"
              onClick={() => onDeleteRubricaClick(id)}
              color="inherit"
              sx={{color: "#e62121"}}
            />
        ];
      }
    }
  ];


  // Novo vinculo de rubricas
  const onChangeDespesa = (selectedDespesa: any) => {
    getPapFinalidades([{label: "expense_type_id", value: selectedDespesa.value}]);
  }

  const onChangeOrganization = (selectedOrg: any) => {
    formRef.current?.clearField("management_contract_id");
    formRef.current?.clearField()
    setContrGestao([]);
    formRef.current?.clearField("comittee_id");
    setComites([]);
    formRef.current?.clearField("unit_id");
    formRef.current?.clearField("periodo");
    setPaps([]);
    formRef.current?.clearField("pap_finality_id");
    setSelectedPap({});
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    setYearsPoa([]);
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);

    getContratos([{ label: "organization_id", value: selectedOrg.value }]);

  }

  const onChangeContrGestao = (selectedContrGestao: any) => {
    formRef.current?.clearField("comittee_id");
    setComites([]);
    formRef.current?.clearField("unit_id");
    formRef.current?.clearField("periodo");
    setPaps([]);
    formRef.current?.clearField("pap_finality_id");
    setSelectedPap({});
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    setYearsPoa([]);
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);

    if (selectedContrGestao) {
        getComittees([{ label: "management_contract_id", value: selectedContrGestao.value }]);
    }
  }

  const onChangeComite = (selectedComite: any) => {
      const {project_lines, rateio} = projeto;

      console.log(project_lines, !rateio);
      if (project_lines.length && !rateio && selectedComite) { // Se tiver ao menos UMA linha
          if (project_lines[0].comittee.id !== selectedComite.value) {
          alert("O Projeto não é rateado! Só é possível adicionar mais linhas se for do mesmo Comitê.");
          // formRef.current?.clearField("comittee_id");
          }
      }


      formRef.current?.clearField("unit_id");
      setSelectComite({
          management_contract: {
              organization: {
                  nome: {}
              },
              management_entity: {}
          },
          units: []
      });
      formRef.current?.clearField("periodo");
      setPaps([]);
      formRef.current?.clearField("pap_finality_id");
      setSelectedPap({});
      formRef.current?.clearField("pap_program_id");
      setFiltredPrograms([]);
      formRef.current?.clearField("pap_action_id");
      setFiltredActions([]);
      formRef.current?.clearField("ano");
      setYearsPoa([]);
      formRef.current?.clearField("poa_sub_action_id");
      setFiltredSubactions([]);

      if (selectedComite) {
          getComittee(selectedComite.value);
          getPaps([{label: "comittee_id", value: selectedComite.value}]);
      }
  }

  useEffect(() => {
    console.log(paps);
  }, [paps]);

  const onChangePeriodo = (selectedPeriodo: any) => {
    formRef.current?.clearField("pap_finality_id");
    setSelectedPap({});
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    setYearsPoa([]);
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);
    if (selectedPeriodo) {
        // getPapFinalidades([{label: "expense_type_id", value: selectedDespesa.value}]);
        getPap(selectedPeriodo.value);
    }
  }

  const onChangeFinalidade = (selectedFinalidade: any) => {
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);
    if (selectedFinalidade) {
        const filter = selectedPap.programas.filter(p => p.pap_finality.id === selectedFinalidade.value);
        setFiltredPrograms(filter);
    }

  }

  const onChangePrograma = (selectedPrograma: any) => {
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);
    if (selectedPrograma) {
        const filter = selectedPap.acoes.filter(p => p.pap_program.id === selectedPrograma.value);
        setFiltredActions(filter);
    }

  }

  const onChangeAcao = (selectedAcao: any) => {
      formRef.current?.clearField("ano");
      formRef.current?.clearField("poa_sub_action_id");
          setFiltredSubactions([]);
  }

  const onChangeAno = (selectedAno: any) => {
      formRef.current?.clearField("poa_sub_action_id");
      setFiltredSubactions([]);

      const pap_id = formRef.current?.getFieldValue("periodo");

      if (selectedAno) {
          getPoas([
              {label: "pap_id", value: pap_id},
              {label: "poa", value: selectedAno.value}
          ]);
      }
  }

  const handleSearchLine = async () => {
    const despesa = formRef.current?.getFieldValue('expense_type_id');
    const org = formRef.current?.getFieldValue('organization_id');
    const contrato = formRef.current?.getFieldValue('management_contract_id');
    const comite = formRef.current?.getFieldValue('comittee_id');
    const unidade = formRef.current?.getFieldValue('unit_id');
    const finalidade = formRef.current?.getFieldValue('pap_finality_id');
    const programa = formRef.current?.getFieldValue('pap_program_id');
    const acao = formRef.current?.getFieldValue('pap_action_id');
    const subacao = formRef.current?.getFieldValue('poa_sub_action_id');
    const projeto = formRef.current?.getFieldValue('project_id');
    const descricao = formRef.current?.getFieldValue('description_id');

    // const rubrica = `${despesa}.${org}.${contrato}.${comite}.${unidade}.${finalidade}.${programa}.${acao}.${subacao}.${projeto}.${descricao}`;
    // console.log(rubrica);

    const {data} = await getCodigosContabeis([
      {label: 'expense_type_id', value: despesa},
      {label: 'organization_id', value: org},
      {label: 'management_contract_id', value: contrato},
      {label: 'comittee_id', value: comite},
      {label: 'unit_id', value: unidade},
      {label: 'pap_finality_id', value: finalidade},
      {label: 'pap_program_id', value: programa},
      {label: 'pap_action_id', value: acao},
      {label: 'poa_sub_action_id', value: subacao},
      {label: 'project_id', value: projeto},
      {label: 'description_id', value: descricao}
    ]);

    const rubrica = data?.data[0];
    if (!rubrica) {
      alert("Rubrica não encontrada! Crie uma nova rubrica secundária.");
      setCodigosContabeis([]);
      formRef.current?.setFieldValue('final_rubrica', '');
    } else {
      formRef.current?.setFieldValue('final_rubrica', `${rubrica.expense_type.codigo}.${rubrica.organization.codigo.padStart(2, '0')}.${rubrica.management_contract.codigo.padStart(2, '0')}.${rubrica.comittee.codigo.padStart(2, '0')}.${rubrica.unit.codigo.padStart(2, '0')}.${rubrica.pap_finality.codigo.padStart(2, '0')}.${rubrica.pap_program.codigo.padStart(2, '0')}.${rubrica.pap_action.codigo.padStart(2, '0')}.${rubrica.poa_sub_action.codigo.padStart(2, '0')}.${rubrica.project.id.toString().padStart(2, '0')}.${rubrica.description.codigo.padStart(3, '0')}`);
    }
  };

  const handleUpdateLinha = (updatedRow: Object) => {
    const objUpdatedRow = { id: updatedRow?.id, val: updatedRow?.valor };
    const updatedValueLinhas = [...valorLinhas.filter((linha) => linha.id !== updatedRow?.id), objUpdatedRow];
    setValorLinhas(updatedValueLinhas);

    // setRubricaFront(rubricaFront[[updatedRow.id]]  updatedRow?.valor ]);
    setRubricaFront(prevState => {
        // loop sobre a lista
        return prevState.map((item) => {
            // verifica pelo item com o ID especifico e atualiza
            return item.id === updatedRow?.id ? {...item, valor: updatedRow?.valor} : item
        })
    });

    return updatedRow;
  };

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };


  return (
    <Container>
      <Title color='#333'>
        <span>
          Cadastro de Aditivo
        </span>
      </Title>

      <Form ref={formRef} onSubmit={handleSubmit} id="formAditivo">
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Nome do aditivo: <span>*</span></TextTitle>
              <Input type="text" name="nome" />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Selecione o tipo de aditivo: <span>*</span></TextTitle>
              <Instancia>
                <div>
                  <input
                    type="checkbox"
                    name="checkvalor"
                    id="checkvalor"
                    onChange={handleChangeTipoValor}
                  /> Valor
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkprazo"
                    id="checkprazo"
                    onChange={handleChangeTipoPrazo}
                  /> Prazo
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkoutros"
                    id="checkoutros"
                    onChange={handleChangeTipoOutros}
                  /> Outros
                </div>
              </Instancia>
            </Grid>
            <Grid xs={12}>
              <TextTitle>Descrição: <span>*</span></TextTitle>
              <Input type="text" name="descricao" />
            </Grid>
          </Grid>
        </Panel>

        {tipoPrazo ?
          <Panel titulo="Prazo">
            <Grid container spacing={6}>
              <Grid xs={6}>
                <TextTitle>Data de assinatura do termo aditivo: <span>*</span></TextTitle>
                <Input type="date" name="data_assinatura" />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Data do início da vigência / ordem de serviço:</TextTitle>
                <Input type="date" name="data_inicio_vigencia" />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Nova data do término da vigência:</TextTitle>
                <Input type="date" name="data_termino_vigencia" />
              </Grid>
            </Grid>
          </Panel>
          : null}

        {tipoValor ?
          <Panel titulo="Valor">
            <Grid container spacing={6}>
              <Grid xs={12}>
                <TextTitle>Selecione o motivo do valor: <span>*</span></TextTitle>
                <Radio
                  name='motivo_valor'
                  options={[
                    { id: "motivoSim", value: "acrescimo", label: "Acréscimo" },
                    { id: "motivoNao", value: "supressao", label: "Supressão" }
                  ]}
                  onClick={handleChangeMotivo}
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Informe o valor a ser adicionado/suprimido: <span>*</span></TextTitle>
                {/* <Input type="text" name="valor" onChange={handleChangeValor} /> */}
                <Input
                  format="currency"
                  currency="BRL"
                  name="valor"
                  onNumberFormat={(event: object) => handleChangeValor(event.detail?.number)}
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Novo valor do contrato: <span>*</span></TextTitle>
                <Input type="text" name="novo_valor_contrato" value={formatarReais(valorInstrumentoAtualizado)} disabled={true} />
              </Grid>
            </Grid>
          </Panel>
          : null}

        {
          tipoValor || tipoOutros ?
            <Panel titulo="Rubrica orçamentária">
              <Grid container spacing={6}>
                <Grid xs={6}>
                  <TextTitle>A dotação é a mesma do contrato?: <span>*</span></TextTitle>
                  <Radio
                    name='mesma_dotacao'
                    options={[
                      { id: "dotacaoSim", value: "true", label: "Sim" },
                      { id: "dotacaoNao", value: "false", label: "Não" }
                    ]}
                    onClick={handleChangeDotacao}
                  />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Valor do termo aditivo:</TextTitle>
                  <span>{formatarReais(valorAditivo)}</span>
                </Grid>
                <Grid xs={12}>
                  <TextTitle>Os pagamentos serão rateados? <span>*</span></TextTitle>
                  <Radio
                    name='pagamento_rateado'
                    options={[
                      { id: "rateadoSim", value: "true", label: "Sim" },
                      { id: "rateadoNao", value: "false", label: "Não" }
                    ]}
                    onClick={handleChangeRateado}
                  />
                </Grid>
                <Grid xs={6}>
                  <ButtonAdd type="button" onClick={handleClickOpenDialog}>
                    <FontAwesomeIcon icon={faPlus} /> Adicionar
                  </ButtonAdd>
                </Grid>
                <Grid xs={6}>
                  <BasicDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    fullWidth={true}
                    maxWidth="lg"
                    titulo="Dados contábeis"
                    buttons={
                      <Container>
                        <Grid container justifyContent="space-between" alignItems="center" width={'100%'}>
                          <Grid><Button onClick={setOpenDialog2}>Criar nova Rubrica secundária</Button></Grid>
                          <Grid container columnSpacing={3}>
                            <Grid><Button  onClick={handleCloseDialog}>Cancelar</Button></Grid>
                            <Grid><Button variant="contained" onClick={handleAddRubrica}>Vincular Rubrica</Button></Grid>
                          </Grid>
                        </Grid>
                      </Container>
                    }
                  >
                    
                    <Grid container spacing={3}>
                      <Grid xs={6}>
                          <TextTitle>Despesas: </TextTitle>
                          <SelectInput
                              id='expense_type_id'
                              name='expense_type_id'
                              options={organizeDataSelect(despesas.data, "id", "nome")}
                              onInputChange={d => { getDespesas([{ label: 'nome', value: d }]) }}
                              onChange={onChangeDespesa}
                              placeholder="Selecionar"
                          />
                      </Grid>

                      <Grid xs={12} lg={6}>
                          <TextTitle>Organização: <span>*</span></TextTitle>
                          <SelectInput
                              id='organization_id'
                              name='organization_id'
                              // value={selectedOrg}
                              options={organizeDataSelect(orgaos.data, "id", "nome")}
                              onInputChange={d => { getOrganizations([{ label: 'nome', value: d }]) }}
                              onChange={onChangeOrganization}
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>
                      <Grid xs={12} lg={6}>
                          <TextTitle>Contrato de gestão: <span>*</span></TextTitle>
                          <SelectInput
                              id='management_contract_id'
                              name='management_contract_id'
                              options={organizeDataSelect(contrGestao.data, "id", "nome")}
                              // onInputChange={d => { getContratos([{label: 'nome', value: d}]) } }
                              onChange={onChangeContrGestao}
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>

                      <Grid xs={12} lg={6}>
                          <TextTitle>Comitê: <span>*</span></TextTitle>
                          <SelectInput
                              id='comittee_id'
                              name='comittee_id'
                              options={organizeDataSelect(comites.data, "id", "nome")}
                              // onInputChange={d => { getComittees([{label: 'nome', value: d}]) } }
                              onChange={onChangeComite}
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>
                      <Grid xs={12} lg={6}>
                          <TextTitle>Unidade: <span>*</span> <small>(De acordo com o comitê)</small></TextTitle>
                          <SelectInput
                              id='unit_id'
                              name='unit_id'
                              options={organizeDataSelect(selectComite.units, "id", "nome")}
                              // onInputChange={d => { getUnidades([{label: 'nome', value: d}]) } }
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>

                      <Grid xs={6}>
                          <TextTitle>Período: <span>*</span> <small>(De acordo com o comitê)</small></TextTitle>
                          <SelectInput
                              id='periodo'
                              name='periodo'
                              options={organizeDataSelect(paps.data, "id", "periodo_de", ["periodo_ate"])}
                              onChange={onChangePeriodo}
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>

                      <Grid xs={12}>
                          <TextTitle>Finalidade: <span>*</span></TextTitle>
                          <SelectInput
                              id='pap_finality_id'
                              name='pap_finality_id'
                              options={organizeDataSelect(selectedPap.finalidades, "id", "nome")}
                              onChange={onChangeFinalidade}
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>
                      <Grid xs={12}>
                          <TextTitle>Programa: <span>*</span></TextTitle>
                          <SelectInput
                              id='pap_program_id'
                              name='pap_program_id'
                              options={organizeDataSelect(filtredPrograms, "id", "nome")}
                              onChange={onChangePrograma}
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>
                      <Grid xs={12}>
                          <TextTitle>Ação: <span>*</span></TextTitle>
                          <SelectInput
                              id='pap_action_id'
                              name='pap_action_id'
                              options={organizeDataSelect(filtredActions, "id", "nome")}
                              onChange={onChangeAcao}
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>

                      <Grid xs={6}>
                          <TextTitle>Ano: <span>*</span> <small>(Com base no Período)</small></TextTitle>
                          <SelectInput
                              id="ano"
                              name="ano"
                              options={yearsPoa || []}
                              onChange={onChangeAno}
                              placeholder="Selecione"
                              required
                          />
                      </Grid>

                      <Grid xs={12}>
                          <TextTitle>Sub-ação: <span>*</span> <small>(Com base no Ano e Ação)</small></TextTitle>
                          <SelectInput
                              id='poa_sub_action_id'
                              name='poa_sub_action_id'
                              options={organizeDataSelect(filtredSubactions, "id", "nome")}
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>
                      <Grid xs={12}>
                          <TextTitle>Projeto: </TextTitle>
                          <SelectInput
                              id='project_id'
                              name='project_id'
                              options={organizeDataSelect(projetos.data, "id", "nome")}
                              onInputChange={d => { getProjetos([{ label: "nome", value: d }]) }}
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>
                      <Grid xs={12}>
                          <TextTitle>Descrição: </TextTitle>
                          <SelectInput
                              id='description_id'
                              name='description_id'
                              options={organizeDataSelect(descricao.data, "id", "nome")}
                              onInputChange={d => { getDescricoes([{ label: "nome", value: d }]) }}
                              placeholder="Selecionar"
                              required
                          />
                      </Grid>

                      <Grid xs={12}>
                          <ButtonAdd 
                              id='adicionar' 
                              name='adicionar' 
                              type="button" 
                              onClick={handleSearchLine}
                          >
                              <FontAwesomeIcon icon={faPlus} size="1x" /> Buscar Rubrica
                          </ButtonAdd>
                      </Grid>

                      <Grid xs={12}>
                      <TextTitle>Rubrica final: </TextTitle>
                        <Input name="final_rubrica" disabled />
                      </Grid>

                    </Grid>

                  </BasicDialog>
                </Grid>
                <Grid xs={12}>
                  <SecondaryTable
                    apiRef={apiRef}
                    columsData={dataTitleRubrica}
                    rowsData={rubricaFront}
                    processRowUpdate={(updatedRow: Object) => handleUpdateLinha(updatedRow)}
                    onKeyDown={(e) => checkKeyDown(e)}
                  />
                </Grid>
                <Grid xs={6} style={{marginTop: "20px"}}>
                  <TextTitle>Total: {formatarReais(valorLinhas?.reduce(function (acc, obj) { return acc + parseFloat(obj?.val); }, 0))}</TextTitle>
                </Grid>
              </Grid>
            </Panel >
            : null
        }

        {
          tipoValor || tipoPrazo || tipoOutros ?
            <Panel titulo="Aditivo">
              <Grid container spacing={6}>
                <Grid xs={6}>
                  <TextTitle>Descrição: </TextTitle>
                  <Input type="text" name="aditivo_descricao" />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Aditivo: </TextTitle>
                  <InputFilev2 id="documento_aditivo" name="aditivo_arquivo" />
                </Grid>
              </Grid>
            </Panel >
            : null
        }

        {
          tipoValor || tipoPrazo || tipoOutros ?
            <Panel titulo="Extrato de publicação">
              <Grid container spacing={6}>
                <Grid xs={6}>
                  <TextTitle>Data da Publicação:</TextTitle>
                  <Input type="date" name="publicacao_data" />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Descrição: <span>*</span></TextTitle>
                  <Input type="text" name="publicacao_descricao" />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Extrato:</TextTitle>
                  <InputFilev2 id="documento_publicacao" name="publicacao_arquivo" />
                </Grid>
              </Grid>
            </Panel >
            : null}
      </Form >

      <BasicDialog
        open={openDialog2}
        handleClose={handleCloseDialog2}
        fullWidth={true}
        maxWidth="lg"
        titulo="Dados contábeis - Rubricas secundárias"
        buttons={
              <>
                <Button  onClick={handleCloseDialog2}>Cancelar</Button>
                {/* <Button variant="contained" onClick={handleAddRubrica}>Criar nova Rubrica secundária</Button> */}
              </>
        }
      >
        <ModalCodigoContabil />
      </BasicDialog>

      <ContainerButton2>
        <Link to="/instrumentos">
          <button className="button_return"><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formAditivo"}></SaveWithProgress>
      </ContainerButton2>
    </Container >
  )
};

export default InstrumentoAditivoCreate;