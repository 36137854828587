import { useEffect, useState } from "react";
import * as Dialog from '@radix-ui/react-dialog';
import { faExclamationTriangle, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  Content,
  Description,
  Overlay,
  Title
} from './styles';

interface DialogProps {
  open: boolean;
  modulo: string;
}

export function DialogInDev(props: DialogProps) {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open])

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Dialog.Root open={modalOpen}>
        <Dialog.Portal>
          <Dialog.Overlay>
            <Overlay />
          </Dialog.Overlay>
          <Dialog.Content>
            <Content>
              <Dialog.Title>
                <Title>
                  <label>
                    <FontAwesomeIcon icon={faExclamationTriangle} size='1x' color="orange" /> - {"Módulo em desenvolvimento!"}
                  </label>
                </Title>
              </Dialog.Title>
              <hr />
              <Dialog.Description>
                <Description>
                  <span>{"Prezado(a),"}<br />{`O módulo '${props.modulo}' encontra-se em desenvolvimento e sua integração ainda não foi realizada.`}<br />{"O apresentado neste módulo é apenas de forma visual e ilustrativa."}<br />{"Agradecemos pela sua compreensão."}</span>
                </Description>
              </Dialog.Description>
              <footer>
                <Dialog.Close asChild>
                  <Button
                    id="cancelar"
                    className="red"
                    type='button'
                    onClick={handleClose}
                  >
                    <FontAwesomeIcon icon={faX} /> {"Fechar"}
                  </Button>
                </Dialog.Close>
              </footer>
            </Content>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}