import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useProcessoCategoriaAcao = () => {

    const history = useHistory();
    const [acoesCategoriasProcessos, setAcoesCategoriasProcessos] = useState([]);
    const [acaoCategoriaProcesso, setAcaoCategoriaProcesso] = useState({});
    const [error, setError] = useState(null);

    async function getAcoesCategoriasProcessos(params?: object) {
        try {
            const response = await api.get(`law_process_category_actions${stringfyParams(params)}`);
            setAcoesCategoriasProcessos(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getAcaoCategoriaProcesso(id: string) {
        try {
            const response = await api.get(`law_process_category_actions/${id}`);
            // console.log(response);
            setAcaoCategoriaProcesso(response.data);
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createAcaoCategoriaProcesso(data: any) {
        try {
            const response = await api.post('law_process_category_actions', data);
            alert("Categoria de Processo cadastrado com sucesso!");
            history.push("/processos");

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            alert("Erro ao cadastrar Processo");
            setError(e);
            // console.log(error);
        }
    }

    async function updateAcaoCategoriaProcesso(id: number, data: any) {
        try {
          const response = await api.put(`law_process_category_actions/${(id)}`, data);
          alert("Categoria do Processo atualizado com sucesso!");
          history.push("/processos");
        } catch (e) {
          setError(e);
          // console.log(error);
        }
      }


    return {acoesCategoriasProcessos, getAcoesCategoriasProcessos, acaoCategoriaProcesso, getAcaoCategoriaProcesso, createAcaoCategoriaProcesso, updateAcaoCategoriaProcesso, error};
}

export default useProcessoCategoriaAcao;