import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";
import { useToast } from "../../../hooks/toast";

const useAteste = () => {

    const history = useHistory();
    const { addToast } = useToast();
    const [ateste, setAteste] = useState([]);
    const [atestes, setAtestes] = useState([]);
    const [error, setError] = useState(null);

    async function getAtestes(params?: object) {
        try {
            const response = await api.get(`attests${stringfyParams(params)}`);
            setAtestes(response.data);
        } catch (e) {
            setError(e);
        }
    }

    async function getAteste(id: string) {
        try {
            const response = await api.get(`attests/${id}`);
            setAteste(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    }

    async function createAteste(data: object) {
        try {
            const response = await api.post('attests', data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            addToast({
                type: 'success',
                title: 'Ateste cadastrado!',
                description: 'Ateste cadastrado com sucesso!',
            });
            history.push("/financeiro/formularioateste");
            return response;

        } catch (e) {
            setError(e);
        }
    }

    async function updateAteste(id: string, data: object) {
        try {
            const response = await api.put(`attests/${id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            addToast({
                type: 'success',
                title: 'Ateste atualizado!',
                description: 'Ateste atualizado com sucesso!',
            });            
            history.push("/financeiro/formularioateste");
            return response
        } catch (e) {
            setError(e);
        }
    }


    return { ateste, atestes, getAtestes, getAteste, createAteste, updateAteste, error };
}

export default useAteste;