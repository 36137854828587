import styled from "styled-components";

export const Title = styled.div`
  color: ${(props) => props.color};
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: 2rem;
  text-align: start;
  display: grid;

  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
`;

export const Container = styled.div`
  margin: 20px 50px;
`;

export const ButtonSearch = styled.button`
  position: relative;
  flex-direction: row;
  border: none;
  background-color: #23b5d3;
  padding: 11px 14px;
  margin-left: 10px;
  border-radius: 8px;
  border-color: #366efa;
  color: #fff;
`;

export const Instancia = styled.div`
  display: flex;
  margin: 2.4rem 0rem 0rem 0px;
  padding: 0 0 0 0;
  letter-spacing: 0.1rem;
  width: 60% !important;

  div {
    margin-right: 5rem;

    input {
      margin-right: 0.2rem;
    }
  }
`;
