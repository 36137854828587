import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useCodigoContabil = () => {

    const history = useHistory();
    const [codigoContabil, setCodigoContabil] = useState([]);
    const [codigosContabeis, setCodigosContabeis] = useState([]);
    const [error, setError] = useState(null);

    async function getCodigosContabeis(params?: object) {
        try {
            const response = await api.get(`accounting_codes${stringfyParams(params)}`);
            setCodigosContabeis(response.data);
            return response;
        } catch (e) {
            setError(e);
        }
    }

    async function getCodigoContabil(id: string) {
        try {
            const response = await api.get(`accounting_codes/${id}`);
            setCodigoContabil(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    }

    async function createCodigoContabil(data: object) {
        try {
            const response = await api.post('accounting_codes', data);
            alert("Código Contábil cadastrado com sucesso!");
            // history.goBack();

        } catch (e) {
            setError(e);
        }
    }

    async function updateCodigoContabil(id: string, data: object) {
        try {
            const response = await api.put(`accounting_codes/${id}`, data);
            alert("Código Contábil atualizado com sucesso!");
            history.goBack();
            return response
        } catch (e) {
            setError(e);
            // console.log(error);
        }        
    }

    return {codigoContabil, codigosContabeis, getCodigosContabeis, getCodigoContabil, createCodigoContabil, updateCodigoContabil, setCodigosContabeis};
}

export default useCodigoContabil;