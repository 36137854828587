import { PDFViewer } from '@react-pdf/renderer'
import DocumentPage from '../../../components/PDFComponents/DocumentPage';
import { useEffect, useState } from 'react';
import { formatCPF, formatarData, formatarReais } from '../../../utils/formatDados';
import { useParams } from 'react-router-dom';
import useProjeto from '../../../actions/cruds/useProjeto';
import useProtocolo from '../../../actions/cruds/Protocolo/useProtocolo';

const ProjetoFichaPDF: React.FC = () => {
  const { idProjeto } = useParams();
  const { projeto, getProjetoId } = useProjeto();
  const { protocolo, getProtocolo } = useProtocolo();

  const [projetoData, setProjetoData] = useState<Array<Object>>([]);

  useEffect(() => {
    getProjetoId(idProjeto);
  }, [])

  const organizeDataLinhas = (data: any[]) => {
    if (typeof data === 'object' && data !== undefined) {
      data = data.map(d => ({
        id: d.id,
        codigo: `${d?.expense_type?.codigo}.${d?.organization?.codigo.padStart(2, '0')}.${d?.management_contract?.codigo.padStart(2, '0')}.${d?.comittee?.codigo.padStart(2, '0')}.${d?.unit?.codigo.padStart(2, '0')}.${d?.pap_finality?.codigo.padStart(2, '0')}.${d?.pap_program?.codigo.padStart(2, '0')}.${d?.pap_action?.codigo.padStart(2, '0')}.${d?.poa_sub_action?.codigo.padStart(2, '0')}.${idProjeto.toString().padStart(2, '0')}.${d?.description?.codigo.padStart(3, '0')}`,
        pap: `${d.pap_finality.codigo}.${d.pap_program.codigo}.${d.pap_action.codigo}`,
        acesso: "EM BREVE",
        classificador: "EM BREVE",
        valor_previsto: formatarReais(d.valor_previsto),
        valor_projeto: "EM BREVE"
      }));
    }
    return data;
  }

  useEffect(() => {
    if (projeto?.id) {
      getProtocolo(projeto?.demand?.id);

      const updatedProjeto = [];

      if (projeto?.project_lines?.length > 0 && projeto?.project_lines[0]?.id) {
        const sumValorPrevisto = projeto.project_lines.reduce(
          (accumulator, currentValue) => accumulator + currentValue.valor_previsto,
          0
        );

        updatedProjeto.push(
          {
            id: "valorPrevistoTotal",
            row: "Valor previsto total:",
            value: formatarReais(sumValorPrevisto) ?? "-",
          },
          {
            id: "valorTotal",
            row: "Valor total do projeto:",
            value: "-",
          },
        );

        projeto?.project_lines?.forEach((linha, index) => {
          if (index == 0) {
            updatedProjeto.push(
              {
                id: "linhas",
                row: "",
                value: "Linhas",
              },
            )
          }
          updatedProjeto.push(
            {
              id: "linha",
              row: `Linha ${index + 1}:`,
              value: `Código do Projeto: ${linha?.expense_type?.codigo}.${linha?.organization?.codigo.padStart(2, '0')}.${linha?.management_contract?.codigo.padStart(2, '0')}.${linha?.comittee?.codigo.padStart(2, '0')}.${linha?.unit?.codigo.padStart(2, '0')}.${linha?.pap_finality?.codigo.padStart(2, '0')}.${linha?.pap_program?.codigo.padStart(2, '0')}.${linha?.pap_action?.codigo.padStart(2, '0')}.${linha?.poa_sub_action?.codigo.padStart(2, '0')}.${idProjeto.toString().padStart(2, '0')}.${linha?.description?.codigo.padStart(3, '0')}\nCódigo PAP: ${linha?.pap_finality?.codigo}.${linha?.pap_program?.codigo}.${linha?.pap_action?.codigo}\nValor previsto: ${formatarReais(linha?.valor_previsto)}`
            },
          );
        });
      };

      if (projeto?.project_documents?.length > 0 && projeto?.project_documents[0]?.id) {
        projeto?.project_documents?.forEach((doc, index) => {
          if (index == 0) {
            updatedProjeto.push(
              {
                id: "documentos",
                row: "",
                value: "Documentos",
              },
            )
          }
          updatedProjeto.push(
            {
              id: "linha",
              row: `Documento ${index + 1}:`,
              value: doc?.nome ?? "-"
            },
          );
        });
      };

      setProjetoData(updatedProjeto);
    };
  }, [projeto]);

  if (projeto?.id && protocolo?.id) {
    const pdfData = {
      version: "Formulário eletrônico - SIGA Águas",
      title: "FICHA DE PROJETO",
      items: [
        {
          id: "agevapImg",
          logoWithColumns: true,
          itemsColumn: [
            {
              id: "projetoNumero",
              row: "PROJETO Nº",
              value: projeto?.id ?? "-"
            },
            {
              id: "codigoProtocolo",
              row: "PROTOCOLO Nº",
              value: projeto?.demand?.codigo ?? "-"
            },
          ]
        },
        {
          id: "origemDemanda",
          row: "Origem da demanda:",
          value: protocolo?.demand_origin?.nome ?? "-"
        },
        {
          id: "tipoAtividade",
          row: "Tipo de atividade:",
          value: `[ ${projeto?.tipo_atividade == 'Meio' ? "X" : "  "} ] Meio           [ ${projeto?.tipo_atividade == 'Fim' ? "X" : "  "} ] Fim`,
        },
        {
          id: "nomeFantasia",
          row: "O projeto será um rateio?",
          value: `[ ${projeto?.rateio ? "X" : "  "} ] Sim           [ ${projeto?.rateio ? "  " : "X"} ] Não`,
        },
        {
          id: "projeto",
          row: "Projeto:",
          value: projeto?.nome ?? "-"
        },
      ],
    };

    if (projetoData?.length > 0) {
      projetoData.forEach((item) => pdfData.items.push(item))
    };

    return (
      <PDFViewer width="100%" height="1300" className="app" >
        <DocumentPage data={pdfData} />
      </PDFViewer>
    );
  };
};

export default ProjetoFichaPDF;