import React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { desativeStatus } from "../../../utils/desativeStatus";
import {
  faBan,
  faCircle,
  faCircleCheck,
  faEye,
  faFileInvoiceDollar,
  faFolderOpen,
  faPen,
  faPlus,
  faFileAlt,
  faTrash,
  faBook,
  faGripLines,
  faFileSignature,
  faFileContract,
  faArrowsRotate,
  faFileCirclePlus,
  faFolderClosed,
  faClock,
  faMoneyCheckDollar,
  faReceipt,
  faFileCircleCheck,
  faSuitcaseRolling,
  faCheck,
  faFileLines,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { deleteItem } from "../../../utils/deleteItem";
import { cancelarSituacao } from "../../../utils/cancelarSituacao";

export interface IDropdownItem {
  linkTo?: string;
  handler?: Function;
}

export interface IDropdownDesativar {
  id?: number;
  status?: boolean;
  linkTo?: string;
  desactiveText?: string;
}

export interface IDropdownCancelar {
  id?: number;
  situacao?: string;
  linkTo?: string;
  activateSituacao?: string;
}

export interface IDropdownExcluir {
  id?: number;
  linkTo?: string;
}

export interface IDropdownNumeroProcesso {
  id?: number;
  openModal?: () => void;
  updateId?: (id: number) => void;
}

export interface IDropdownCodigoNup {
  id?: number;
  openModal?: () => void;
  updateId?: (id: number) => void;
}

export interface IDropdownInstrumento {
  id?: number;
  openModal?: () => void;
  tipo?: () => void;
  updateId?: (id: number) => void;
}

export const DropdownView: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faEye} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Visualizar</span>
    </Link>
  );
};

export const DropdownOrcamento: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faFileInvoiceDollar} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Orçamento</span>
    </Link>
  );
};

export const DropdownEdit: React.FC<IDropdownItem> = ({ linkTo, handler }) => {
  return (
    <Link to={linkTo || ""}>
      <button onClick={() => handler}>
        <FontAwesomeIcon icon={faPen} size="1x" color="#366EFA" />
        <span style={{ marginLeft: 16 }}>Editar</span>
      </button>
    </Link>
  );
};

export const DropdownExlcuir: React.FC<IDropdownExcluir> = ({ id, linkTo }) => {
  return (
    <button onClick={() => deleteItem({ id, linkTo })}>
      <FontAwesomeIcon icon={faTrash} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 19 }}>Excluir</span>
    </button>
  );
};

export const DropdownDesativar: React.FC<IDropdownDesativar> = ({
  id,
  status,
  linkTo,
  desactiveText = "Desativar",
}) => {
  return (
    <button onClick={() => desativeStatus({ id, status, linkTo })}>
      {status ? (
        <FontAwesomeIcon icon={faBan} size="1x" color="#366EFA" />
      ) : (
        <FontAwesomeIcon icon={faCircleCheck} size="1x" color="#366EFA" />
      )}
      <span style={{ marginLeft: 16 }}>
        {status ? desactiveText : "Ativar"}
      </span>
    </button>
  );
};

export const DropdownCancelar: React.FC<IDropdownCancelar> = ({
  id,
  situacao,
  linkTo,
  activateSituacao,
}) => {
  return (
    <button onClick={() => cancelarSituacao({ id, situacao, linkTo, activateSituacao })}>
      {situacao != "Cancelado" ? (
        <>
          <FontAwesomeIcon icon={faBan} size="1x" color="#366EFA" />
          <span style={{ marginLeft: 16 }}>Cancelar</span>
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faCircleCheck} size="1x" color="#366EFA" />
          <span style={{ marginLeft: 16 }}>Reativar</span>
        </>
      )}
    </button>
  );
};

export const DropdownDocumentos: React.FC<IDropdownItem> = ({
  handler,
  linkTo,
}) => {
  return (
    <Link to={linkTo || ""}>
      <button onClick={() => handler}>
        <FontAwesomeIcon icon={faFolderOpen} size="1x" color="#366EFA" />
        <span style={{ marginLeft: 16 }}>Documentos</span>
      </button>
    </Link>
  );
};

export const DropdownCertidoes: React.FC<IDropdownItem> = ({
  handler,
  linkTo,
}) => {
  return (
    <Link to={linkTo || ""}>
      <button onClick={() => handler}>
        <FontAwesomeIcon icon={faCircle} size="1x" color="#366EFA" />
        <span style={{ marginLeft: 16 }}>Certidões</span>
      </button>
    </Link>
  );
};

export const DropdownAditivos: React.FC<IDropdownItem> = ({
  handler,
  linkTo,
}) => {
  return (
    <Link to={linkTo || ""}>
      <button onClick={() => handler}>
        <FontAwesomeIcon icon={faPlus} size="1x" color="#366EFA" />
        <span style={{ marginLeft: 16 }}>Aditivo</span>
      </button>
    </Link>
  );
};

export const DropdownApostilamento: React.FC<IDropdownItem> = ({
  handler,
  linkTo,
}) => {
  return (
    <Link to={linkTo || ""}>
      <button onClick={() => handler}>
        <FontAwesomeIcon icon={faFileInvoiceDollar} size="1x" color="#366EFA" />
        <span style={{ marginLeft: 16 }}>Apostilamento</span>
      </button>
    </Link>
  );
};

export const DropdownPlanejamento: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faFileContract} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Planejamento</span>
    </Link>
  );
};

export const DropdownAcaoPlanejamento: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faFileSignature} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Ação do Planejamento</span>
    </Link>
  );
};

export const DropdownNumeroProcesso: React.FC<IDropdownNumeroProcesso> = ({
  id,
  openModal,
  updateId,
}) => {
  if (id && updateId) {
    updateId(id);
  }

  return (
    <button onClick={() => openModal && openModal()} className="dropdown_link">
      <FontAwesomeIcon icon={faBook} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Número do processo</span>
    </button>
  );
};

export const DropdownCodigoNup: React.FC<IDropdownCodigoNup> = ({
  id,
  openModal,
  updateId,
}) => {
  if (id && updateId) {
    updateId(id);
  }

  return (
    <button onClick={() => openModal && openModal()} className="dropdown_link">
      <FontAwesomeIcon icon={faBook} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Código NUP - SIGAD</span>
    </button>
  );
};

export const DropdownLinha: React.FC<IDropdownItem> = ({ linkTo, handler }) => {
  return (
    <Link to={linkTo || ""}>
      <button onClick={() => handler}>
        <FontAwesomeIcon icon={faGripLines} size="1x" color="#366EFA" />
        <span style={{ marginLeft: 16 }}>Linhas</span>
      </button>
    </Link>
  );
};

export const DropdownComprovante: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faCircle} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Comprovante</span>
    </Link>
  );
};

export const DropdownNormaInterna: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faFileAlt} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Norma Interna</span>
    </Link>
  );
};

export const DropdownAtualizarAcaoPlanejamento: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faArrowsRotate} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Atualizar ação</span>
    </Link>
  );
};

export const DropdownVincularProtocolo: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faFileCirclePlus} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Vincular Protocolo</span>
    </Link>
  );
};

export const DropdownHistoricoProcessoJudicial: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faClock} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Histórico do Processo</span>
    </Link>
  );
};

export const DropdownFinalizarProcessoJudicial: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faFolderClosed} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Finalizar Processo</span>
    </Link>
  );

};
export const DropdownRealizarPagamentoDespesa: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faMoneyCheckDollar} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Realizar Pagamento</span>
    </Link>
  );
};

export const DropdownInformarPagamentoDespesa: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faReceipt} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Informar Pagamento</span>
    </Link>
  );
};

export const DropdownValidarDotacao: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faFileCircleCheck} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Validar Dotação</span>
    </Link>
  );
};

export const DropdownViewInstrumento: React.FC<IDropdownInstrumento> = ({
  id,
  openModal,
  updateId,
  tipo
}) => {
  if (id && updateId) {
    updateId(id);
  }
  
  const handlerOpenView = () => {
    if(openModal){
      openModal();
    }
    if(tipo){
      tipo();
    };
  }
  
  return (
    <button onClick={handlerOpenView} className="dropdown_link">
      <FontAwesomeIcon icon={faEye} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Visualizar</span>
    </button>
  );
};

export const DropdownEditInstrumento: React.FC<IDropdownInstrumento> = ({
  id,
  openModal,
  updateId,
  tipo,
}) => {
  if (id && updateId) {
    updateId(id);
  }
  
  const handlerOpenEdit = () => {
    if(openModal){
      openModal();
    }
    if(tipo){
      tipo();
    };
  }
  
  return (
    <button onClick={handlerOpenEdit} className="dropdown_link">
      <FontAwesomeIcon icon={faPen} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Editar</span>
    </button>
  );
};

export const DropdownFerias: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faSuitcaseRolling} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Férias</span>
    </Link>
  );
};

export const DropdownConfirmarFerias: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faCheck} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Confirmar Férias</span>
    </Link>
  );
};

export const DropdownDePara: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faFileLines} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>De Para</span>
    </Link>
  );
};

export const DropdownNotaFiscal: React.FC<IDropdownItem> = ({ linkTo }) => {
  return (
    <Link to={linkTo || ""} className="dropdown_link">
      <FontAwesomeIcon icon={faFile} size="1x" color="#366EFA" />
      <span style={{ marginLeft: 16 }}>Nota Fiscal</span>
    </Link>
  );
};