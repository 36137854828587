import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { faPlus, faSave, faX } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";

import Panel from "../../../../../components/Panel";

import {
  ButtonAdd,
  ContainerButton,
  Form,
  Input,
  InputAddBtn,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../../styles/global";

import Radio from "../../../../../components/InputRadio";
import useEndereco from "../../../../../actions/cruds/useEndereco";
import SecondaryTable from "../../../../../components/SecondaryTable";
import { GridColDef, GridRenderEditCellParams, GridValueFormatterParams, useGridApiRef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import bankApi from "../../../../../services/bankApi";
import organizeDataSelect from "../../../../../utils/organizeDataSelect";
import BasicDialog from "../../../../../components/Dialog";
import useDespesas from "../../../../../actions/cruds/useDespesas";
import useOrgao from "../../../../../actions/cruds/useOrgao";
import useContratoGestao from "../../../../../actions/cruds/useContratoGestao";
import useComite from "../../../../../actions/cruds/useComite";
import useFinalidades from "../../../../../actions/cruds/useFinalidade";
import usePrograma from "../../../../../actions/cruds/usePrograma";
import useAcao from "../../../../../actions/cruds/useAcao";
import useSubAcao from "../../../../../actions/cruds/useSubAcao";
import useProjeto from "../../../../../actions/cruds/useProjeto";
import useDescricao from "../../../../../actions/cruds/useDescricao";
import useUnidade from "../../../../../actions/cruds/useUnidade";
import useCodigoContabil from "../../../../../actions/cruds/useCodigoContabil";
import useUsuario from "../../../../../actions/cruds/useUsuario";
import useCargo from "../../../../../actions/cruds/useCargo";
import useGerencia from "../../../../../actions/cruds/useGerencia";
import { Link } from "react-router-dom";
import InputImage from "../../../../../components/InputImage";
import Grid from '@mui/material/Unstable_Grid2';
import emailChecker from "../../../../../utils/emailChecker";
import { formatarReais, formatarRealparaDecimal, pontuarCpf } from "../../../../../utils/formatDados";
import InputNumberFormat from "../../../../../components/InputNumberFormat";
import useProcesso from "../../../../../actions/cruds/Processos/useProcesso";
import organizeDataNumeroProcesso from "../../../../../utils/organizeDataNumeroProcesso";

export function EditJovemAprendiz() {
  const { empregadoId } = useParams();
  const { usuarios, getUsers, usuario, getUser, updateUsers } = useUsuario();
  const { getAddress, endereco, setEndereco } = useEndereco();
  const { despesas, getDespesas } = useDespesas();
  const { orgaos, getOrganizations } = useOrgao();
  const { contrGestao, getContratos } = useContratoGestao();
  const { comites, getComittees } = useComite();
  const { finalidades, getFinalidades } = useFinalidades();
  const { programas, getProgramas } = usePrograma();
  const { acao, getAcoes } = useAcao();
  const { subacao, getSubAcao } = useSubAcao();
  const { projetos, getProjetos } = useProjeto();
  const { descricao, getDescricoes } = useDescricao();
  const { unidadesComite, getUnidadesComite } = useUnidade();
  const { unidades, getUnidades } = useUnidade();
  const { codigosContabeis, getCodigosContabeis } = useCodigoContabil();
  const { cargos, getCargos, cargo, getCargo } = useCargo();
  const { gerencias, getGerencias } = useGerencia();
  const { processos, processo, getProcesso, getProcessos } = useProcesso();

  const [openDialog, setOpenDialog] = useState(false);

  const [valueOrganizacao, setValueOrganizacao] = useState<Object>();
  const [optionsContrato, setOptionsContrato] = useState<Object>([]);
  const [valueContrato, setValueContrato] = useState<Object>();
  const [optionsComite, setOptionsComite] = useState<Object>([]);
  const [valueComite, setValueComite] = useState<Object>();
  const [optionsFinalidade, setOptionsFinalidade] = useState<Object>([]);
  const [valueFinalidade, setValueFinalidade] = useState<Object>();
  const [optionsPrograma, setOptionsPrograma] = useState<Object>([]);
  const [valuePrograma, setValuePrograma] = useState<Object>();
  const [optionsAcao, setOptionsAcao] = useState<Object>([]);
  const [valueAcao, setValueAcao] = useState<Object>();
  const [optionsSubacao, setOptionsSubacao] = useState<Object>([]);
  const [valueSubacao, setValueSubacao] = useState<Object>();
  const [optionsProjeto, setOptionsProjeto] = useState<Object>([]);
  const [valueProjeto, setValueProjeto] = useState<Object>();
  const [valueDescricao, setValueDescricao] = useState<Object>();
  const [valueTipoDespesa, setValueTipoDespesa] = useState<Object>();
  const [optionsUnidade, setOptionsUnidade] = useState<Object>([]);
  const [valueUnidade, setValueUnidade] = useState<Object>();
  const [valueCodigoContabil, setValueCodigoContabil] = useState<Array<Object>>(
    []
  );
  const [codigoContabil, setCodigoContabil] = useState<Array<Object>>();
  const [valueSalario, setValueSalario] = useState<Number | String>();

  const [banks, setBanks] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});

  const [valueGenero, setValueGenero] = useState([]);
  const [valueGrauInstrucao, setValueGrauInstrucao] = useState([]);
  const [valueEstadoCivil, setValueEstadoCivil] = useState([]);
  const [valueAccountType, setValueAccountType] = useState([]);
  const [valueBank, setValueBank] = useState([]);
  const [contactsFront, setContactsFront] = useState([]);
  const [contactsBack, setContactsBack] = useState([]);
  const [rubricaFront, setRubricaFront] = useState([]);
  const [rubricaBack, setRubricaBack] = useState([]);
  const [contractsFront, setContractsFront] = useState([]);
  const [contractsBack, setContractsBack] = useState([]);
  const [valueCargoAtual, setValueCargoAtual] = useState([]);
  const [valueOrganizacaoTrabalho, setValueOrganizacaoTrabalho] = useState([]);
  const [valueLocalTrabalho, setValueLocalTrabalho] = useState([]);
  const [valueGerencia, setValueGerencia] = useState([]);
  const [valueSuperiorImediato, setValueSuperiorImediato] = useState([]);
  const [valueTipoTrabalho, setValueTipoTrabalho] = useState([]);
  const [valueCargaHoraria, setValueCargaHoraria] = useState([]);
  const [showConvenioInput, setShowConvenioInput] = useState(false);
  const [tipoCarteiraProfissional, setTipoCarteiraProfissional] = useState<String>('fisica');

  const [valorLinhas, setValorLinhas] = useState<Array<Object>>([]);
  const [linhasProjeto, setLinhasProjeto] = useState<Array<Object>>([]);

  useEffect(() => {
    console.log("tela de Jovem Aprendiz");
    getUser(empregadoId);

    getUsers([{ label: 'ativo', value: true }]);
    getCargos([{ label: 'ativo', value: true }]);
    getGerencias([{ label: 'ativo', value: true }]);
    getDespesas([{ label: 'ativo', value: true }]);
    getOrganizations([{ label: 'ativo', value: true }]);
    getComittees([{ label: 'ativo', value: true }]);
    getContratos([{ label: 'ativo', value: true }]);
    getFinalidades([{ label: 'ativo', value: true }]);
    getProgramas([{ label: 'ativo', value: true }]);
    getAcoes([{ label: 'ativo', value: true }]);
    getSubAcao([{ label: 'ativo', value: true }]);
    getProjetos([{ label: 'ativo', value: true }]);
    getUnidadesComite([{ label: 'ativo', value: true }]);
    getUnidades([{ label: 'ativo', value: true }]);
    getDescricoes([{ label: 'ativo', value: true }]);
    getCodigosContabeis([{ label: 'ativo', value: true }]);
    getProcessos();
  }, []);

  useEffect(() => {
    formRef.current.setFieldValue("law_process_desc", processo.assunto_processo);
  }, [processo]);

  useEffect(() => {

    if (usuario.vinculado_convenio != null) {
      const vinculadoConvenioRadio = formRef.current?.getFieldRef("vinculado_convenio");
      if (typeof vinculadoConvenioRadio == 'object' && usuario.vinculado_convenio == true) {
        vinculadoConvenioRadio[0].checked = true;
        setShowConvenioInput(true)
      } else if (typeof vinculadoConvenioRadio == 'object' && usuario.vinculado_convenio == false) {
        vinculadoConvenioRadio[1].checked = true;
      }
    };

    const initialValueEstadoCivil = optionsEstadoCivil.find(o => o.value === usuario.estado_civil);
    setValueEstadoCivil(initialValueEstadoCivil);

    // const initialValueGrauInstrucao = optionsGrau.find(o => o.value === usuario.grau_instrucao);
    // setValueGrauInstrucao(initialValueGrauInstrucao);

    const initialValueGenero = optionsGenero.find(o => o.value === usuario.genero);
    setValueGenero(initialValueGenero);

    const initialValueBank = banks.find((o) => o.value === usuario.banco_nome);
    setValueBank(initialValueBank);

    const initialValueAccountType = optionsTipoConta.find(
      (o) => o.value === usuario.banco_tipo_conta
    );
    setValueAccountType(initialValueAccountType);

    if (usuario.cep) {
      getAddress(usuario.cep);
    };

    if (usuario.user_contacts) {
      const contactMap = organizeDataContact(usuario.user_contacts)
      setContactsFront([...contactMap]);
    };

    if (usuario.cargo_atual) {
      setValueCargoAtual(organizeDataSelect([usuario.cargo_atual], "id", "nome"));
    };

    if (usuario.organization) {
      setValueOrganizacaoTrabalho(organizeDataSelect([usuario.organization], "id", "nome"));
    };

    if (usuario.local_trabalho) {
      setValueLocalTrabalho(organizeDataSelect([usuario.local_trabalho], "id", "nome"));
    };

    if (usuario.gerencia) {
      setValueGerencia(organizeDataSelect([usuario.gerencia], "id", "nome"));
    };

    if (usuario.superior_imediato) {
      setValueSuperiorImediato(organizeDataSelect([usuario.superior_imediato], "id", "nome"));
    };

    if (usuario.superior_imediato) {
      setValueSuperiorImediato(
        organizeDataSelect([usuario.superior_imediato], "id", "nome")
      );
    }

    const initialValueTipoTrabalho = optionsTipoTrabalho.find(
      (o) => o.value === usuario.tipo_trabalho
    );
    setValueTipoTrabalho(initialValueTipoTrabalho);

    const initialValueCargaHoraria = optionsCargaHoraria.find(
      (o) => o.value === usuario.carga_horaria
    );
    setValueCargaHoraria(initialValueCargaHoraria);

    if (usuario.uso_veiculo != null) {
      const usoVeiculoRadio = formRef.current?.getFieldRef("uso_veiculo");
      if (typeof usoVeiculoRadio == 'object' && usuario.uso_veiculo == true) {
        usoVeiculoRadio[0].checked = true;
      } else if (typeof usoVeiculoRadio == 'object' && usuario.uso_veiculo == false) {
        usoVeiculoRadio[1].checked = true;
      }
    }

    if (usuario.carteira_trabalho_tipo != null) {
      const carteiraTrabalhoTipoRadio = formRef.current?.getFieldRef("carteira_trabalho_tipo");
      if (typeof carteiraTrabalhoTipoRadio == 'object' && usuario.carteira_trabalho_tipo == 'fisica') {
        carteiraTrabalhoTipoRadio[0].checked = true;
        setTipoCarteiraProfissional('fisica');
      } else if (typeof carteiraTrabalhoTipoRadio == 'object' && usuario.carteira_trabalho_tipo == 'digital') {
        carteiraTrabalhoTipoRadio[1].checked = true;
        setTipoCarteiraProfissional('digital');
      }
    };

    if (usuario.salario != null) {
      setValueSalario(usuario.salario);
    }

    if (usuario.area_atuacao != null) {
      const areaAtuacaoRadio = formRef.current?.getFieldRef("area_atuacao");
      if (typeof areaAtuacaoRadio == 'object' && usuario.area_atuacao == 'meio') {
        areaAtuacaoRadio[0].checked = true;
      } else if (typeof areaAtuacaoRadio == 'object' && usuario.area_atuacao == 'fim') {
        areaAtuacaoRadio[1].checked = true;
      }
    }

    if (usuario.user_budgets) {
      const newRubricaFront = usuario.user_budgets.map((rubrica) => {
        return {
          front_id: rubrica.id,
          contrato: rubrica?.accounting_code?.management_contract.nome,
          comite: rubrica.accounting_code?.comittee.nome,
          codigo_contabil: rubrica.accounting_code?.codigo_contabil,
          valor: rubrica.valor,
          percentual: rubrica.percentual,
          accounting_code: rubrica.accounting_code,
        };
      });

      setRubricaFront(newRubricaFront);
    }

    if (usuario.user_contracts) {
      const newContractFront = usuario.user_contracts.map((contract) => {
        return {
          front_id: contract.id,
          data_assinatura: contract.data_assinatura,
          data_vencimento: contract.data_vencimento,
        };
      });


      setContractsFront(newContractFront);
    };

    if (usuario.law_process) {
      formRef.current?.setFieldValue('law_process_id', {value: usuario?.law_process?.id, label: `${usuario.law_process?.codigo} - (SIGAD - ${usuario.law_process?.numero || 'sem nº'})`});
    }

  }, [usuario]);

  useEffect(() => {
    if (valueTipoDespesa && valueProjeto && valueDescricao) {
      const codigoContabilRubrica = codigosContabeis.data.filter(
        (obj) =>
          obj.expense_type?.id == valueTipoDespesa?.value &&
          obj.organization?.id == valueOrganizacao?.value &&
          obj.management_contract?.id == valueContrato?.value &&
          obj.comittee?.id == valueComite?.value &&
          obj.unit?.id == valueUnidade?.value &&
          obj.finality?.id == valueFinalidade?.value &&
          obj.program?.id == valuePrograma?.value &&
          obj.tbl_action?.id == valueAcao?.value &&
          obj.sub_action?.id == valueSubacao?.value &&
          obj.project?.id == valueProjeto?.value &&
          obj.description?.id == valueDescricao?.value
      );
      setValueCodigoContabil([]);
      setCodigoContabil([]);
      if (codigoContabilRubrica.length) {
        setValueCodigoContabil(codigoContabilRubrica[0].codigo_contabil);
        setCodigoContabil(
          organizeDataSelect(codigoContabilRubrica, "id", "codigo_contabil")
        );
      } else {
        alert("Código Contábil não encontrado");
      }
    }
  }, [valueTipoDespesa, valueProjeto, valueDescricao]);

  const apiRef = useGridApiRef();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {

        const dataDesligamento = formRef.current?.getFieldValue("data_desligamento");
        if (dataDesligamento) {
          data["ativo"] = false;
        };

        data["user_budgets_attributes"] = rubricaBack;
        data["user_contacts_attributes"] = contactsBack;
        data["user_contracts_attributes"] = contractsBack

        // Lógica para verificar a somatória das Rubricas desabilitada temporariamente
        // const totalValorRubricas = rubricaFront.reduce((accumulator, currentItem) => {
        //   return parseFloat(accumulator) + parseFloat(currentItem.valor);
        // }, 0);
        // const salario = formRef.current?.getFieldValue("salario");

        // if(parseFloat(totalValorRubricas) != parseFloat(salario)){
        //   alert("Somatório das Rubricas Orçamentárias precisa ser igual ao Salário")
        //   return;
        // }

        const rowsData = Array.from( apiRef.current.getRowModels(), ([, value]) => (value) );
        const rubricaSemValor = rowsData.every(row => row.valor);

        if (!rubricaSemValor) {
          alert("Todas as linhas da Rubrica orçamentária devem conter valor.");
          return;
        }

        data["user_budgets_attributes"] = rowsData.map(d => ({
          id: d.budget_id || null,
          project_line_id: d.id,
          valor: d.valor
        }));

        data["salario"] = formatarRealparaDecimal(formRef.current?.getFieldValue("salario"));
        console.log("data", data);
        updateUsers(empregadoId, data);
      } catch (err) { }
    },
    [usuario, rubricaBack, contactsBack, contractsBack, apiRef]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await bankApi.get("banks/v1");
        setBanks(
          data.map((d) => ({
            value: d.fullName,
            label: `${d.code ? d.code + " - " : ""} ${d.fullName}`,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const organizeDataContact = (data) => {

    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          front_id: d.id,
          tipo: d.tipo,
          contato: d.contato,
        }));
        return data;
      }
    }
    return [];
  };

  const handlerContact = (btn) => {
    const randon_id = Date.now();
    const tipoContato = btn.target.id;

    if (tipoContato === 'email') {
      const emailValue = formRef.current?.getFieldValue("email");
      const emailValido = emailChecker(emailValue);

      if (!emailValido) {
        alert("Email inválido! Insira um email valido. Ex: 'email@email.com'");
        return;
      }
    };

    if (tipoContato != "telefone" && tipoContato != "email") {
      return;
    };

    setContactsFront([
      ...contactsFront,
      {
        new_insert: true,
        front_id: randon_id,
        tipo: btn.target.id,
        contato:
          btn.target.id === "telefone"
            ? formRef.current.getFieldValue("telefone")
            : formRef.current.getFieldValue("email"),
      },
    ]);

    setContactsBack(
      [
        ...contactsBack,
        {
          "new_insert": true,
          "front_id": randon_id,
          "tipo": btn.target.id,
          "contato": btn.target.id === "telefone" ? formRef.current.getFieldValue('telefone') : formRef.current.getFieldValue('email')
        }
      ]
    );

    formRef.current?.setFieldValue('telefone', "");
    formRef.current?.setFieldValue('email', "");
  };

  const optionsUF = [
    { value: "AC", label: "AC - Acre" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "BA", label: "BA - Bahia" },
    { value: "CE", label: "CE - Ceará" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "GO", label: "GO - Goiás" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "PA", label: "PA - Pará" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PI", label: "PI - Piauí" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RR", label: "RR - Roraima" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "EX", label: "EX - Estrangeiro" },
  ];

  const optionsGenero = [
    { value: "masculino", label: "Masculino" },
    { value: "feminino", label: "Feminino" },
  ];

  // const optionsGrau = [
  //   { value: "Ensino Médio", label: "Ensino Médio" },
  //   { value: "Técnico", label: "Técnico" },
  //   { value: "Superior (Graduação)", label: "Superior (Graduação)" },
  //   { value: "Pós-graduação", label: "Pós-graduação" },
  //   { value: "Mestrado", label: "Mestrado" },
  //   { value: "Doutorado", label: "Doutorado" }
  // ];

  const optionsEstadoCivil = [
    { value: "solteiro", label: "Solteiro(a)" },
    { value: "casado", label: "Casado(a)" },
    { value: "separado", label: "Separado(a)" },
    { value: "divorciado", label: "Divorciado(a)" },
    { value: "viuvo", label: "Viúvo(a)" },
  ];

  const optionsTipoConta = [
    { value: "corrente", label: "Conta Corrente" },
    { value: "poupanca", label: "Conta Poupança" },
  ];

  const optionsBoolean = [
    { value: "true", label: "Sim" },
    { value: "false", label: "Não" },
  ];

  const optionsCarteiraProfissional = [
    { value: "fisica", label: " Física" },
    { value: "digital", label: " Digital" },
  ];

  const optionsAreaAtuacao = [
    { value: "meio", label: "Área meio" },
    { value: "fim", label: "Área fim" },
  ];

  const optionsTipoTrabalho = [
    { value: "presencial", label: "Presencial" },
    { value: "hibrido", label: "Híbrido" },
    { value: "remoto", label: "Remoto" },
  ];

  const optionsCargaHoraria = [
    { value: "40", label: "40 horas semanais (Segunda a sexta)" },
  ];

  const dataAddress = () => {
    getAddress(formRef.current.getFieldValue("cep"));
  };

  const handleChangeSelect = (selectedOption) => {
    // necessario para selecionar programaticamente uma opção do select
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    // necessario para selecionar programaticamente uma opção do select
    const selected = optionsUF.find((o) => o.value === endereco.uf);
    setSelectedOption(selected);
  }, [endereco]);

  const dataTitleContacts: GridColDef[] = [
    { field: "tipo", headerName: "Tipo", flex: 1 },
    { field: "contato", headerName: "Contato", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteContactClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  function onDeleteContactClick(e: any, row: any): void {
    if (row.new_insert) {
      setContactsFront(contactsFront.filter((el) => el.front_id !== row.front_id));
      setContactsBack(contactsBack.filter((el) => el.front_id !== row.front_id));
    } else {
      console.log(row);
      setContactsFront(contactsFront.filter((el) => el.front_id !== row.front_id));
      setContactsBack([
        ...contactsBack,
        {
          id: row.front_id,
          _destroy: row.front_id,
        },
      ]);
    }
  }

  const handleChangeGenero = (selectedGenero: object) => {
    setValueGenero(selectedGenero);
  };

  // const handleChangeGrauInstrucao = (selectedGrauInstrucao: object) => {
  //   setValueGrauInstrucao(selectedGrauInstrucao);
  // };

  const handleChangeEstadoCivil = (selectedEstadoCivil: object) => {
    setValueEstadoCivil(selectedEstadoCivil);
  };

  //Muda os valores do Tipo de Conta
  const handleChangeAccountType = (selectedAccountType: object) => {
    setValueAccountType(selectedAccountType);
  };

  //Muda os valores de Banco
  const handleChangeBank = (selectedBank: object) => {
    setValueBank(selectedBank);
  };

  const handleChangeCargoAtual = (cargoSelected: { value: string } | null) => {
    setValueCargoAtual(cargoSelected);

    if (cargoSelected == null) {
      setValueSalario("");
    } else {
      getCargo(cargoSelected.value);
    }
  };

  const handleChangeOrganizacaoTrabalho = (selectedOrganizaoTrabalho: object) => {
    setValueOrganizacaoTrabalho(selectedOrganizaoTrabalho);
  };

  const handleChangeLocalTrabalho = (selectedLocalTrabalho: object) => {
    setValueLocalTrabalho(selectedLocalTrabalho);
  };

  const handleChangeGerencia = (selectedGerencia: object) => {
    setValueGerencia(selectedGerencia);
  };

  const handleChangeSuperiorImediato = (selectedSuperiorImediato: object) => {
    setValueSuperiorImediato(selectedSuperiorImediato);
  };

  const handleChangeTipoTrabalho = (selectedTipoTrabalho: object) => {
    setValueTipoTrabalho(selectedTipoTrabalho);
  };

  const handleChangeCargaHoraria = (selectedCargaHoraria: object) => {
    setValueCargaHoraria(selectedCargaHoraria);
  };

  useEffect(() => {
    if (cargo.id) {
      setValueSalario(cargo.salario);
    }
  }, [cargo]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const resetarOptions = (...optionsField) => {
    optionsField.forEach((optionsField) => {
      optionsField([]);
    });
  };
  const resetarValues = (...fieldName) => {
    fieldName.forEach((fieldName) => {
      const field = formRef.current?.getFieldRef(fieldName);
      if (field) {
        field.clearValue();
      }
    });
  };

  const handleChangeTipoDespesa = (valueTipoDespesaSelected: object) => {
    setValueTipoDespesa(valueTipoDespesaSelected);
  };

  const handleChangeOrganizacao = (valueOrganizacaoSelected: object) => {
    setValueOrganizacao(valueOrganizacaoSelected);
    resetarOptions(
      setOptionsContrato,
      setOptionsComite,
      setOptionsFinalidade,
      setOptionsFinalidade,
      setOptionsPrograma,
      setOptionsAcao,
      setOptionsSubacao,
      setOptionsProjeto
    );
    resetarValues(
      "management_contract_id",
      "comittee_id",
      "unit_id",
      "finality_id",
      "program_id",
      "tbl_action_id",
      "sub_action_id",
      "project_id"
    );

    if (valueOrganizacaoSelected) {
      const opcoesContrato = contrGestao.data.filter(
        (obj) => obj.id == valueOrganizacaoSelected.value
      );
      setOptionsContrato(opcoesContrato);
    }
  };

  const handleChangeContrato = (valueContratoSelected: object) => {
    setValueContrato(valueContratoSelected);
    resetarOptions(
      setOptionsComite,
      setOptionsFinalidade,
      setOptionsFinalidade,
      setOptionsPrograma,
      setOptionsAcao,
      setOptionsSubacao,
      setOptionsProjeto
    );
    resetarValues(
      "comittee_id",
      "unit_id",
      "finality_id",
      "program_id",
      "tbl_action_id",
      "sub_action_id",
      "project_id"
    );

    if (valueContratoSelected) {
      const opcoesComite = comites.data.filter(
        (obj) => obj.management_contract.id == valueContratoSelected.value
      );
      setOptionsComite(opcoesComite);
    }
  };

  const handleChangeComite = (valueComiteSelected: object) => {
    setValueComite(valueComiteSelected);
    resetarOptions(
      setOptionsUnidade,
      setOptionsFinalidade,
      setOptionsPrograma,
      setOptionsAcao,
      setOptionsSubacao,
      setOptionsProjeto
    );
    resetarValues(
      "unit_id",
      "finality_id",
      "program_id",
      "tbl_action_id",
      "sub_action_id",
      "project_id"
    );

    if (valueComiteSelected) {
      const opcoesFinalidade = finalidades.data.filter(
        (obj) => obj.comite.id == valueComiteSelected.value
      );
      setOptionsFinalidade(opcoesFinalidade);

      const opcoesUnidadeComite = unidadesComite.data.filter(
        (obj) => obj.comittee_id === valueComiteSelected.value
      );
      const opcoesUnidade = unidades.data.filter((obj) =>
        opcoesUnidadeComite.some((opc) => opc.comittee_id === obj.id)
      );
      setOptionsUnidade(opcoesUnidade);
    }
  };

  const handleChangeUnidade = (valueUnidadeSelected: object) => {
    setValueUnidade(valueUnidadeSelected);
  };

  const handleChangeFinalidade = (valueFinalidadeSelected: object) => {
    setValueFinalidade(valueFinalidadeSelected);
    resetarOptions(
      setOptionsPrograma,
      setOptionsAcao,
      setOptionsSubacao,
      setOptionsProjeto
    );
    resetarValues("program_id", "tbl_action_id", "sub_action_id", "project_id");

    if (valueFinalidadeSelected) {
      const opcoesPrograma = programas.data.filter(
        (obj) => obj.finality.id == valueFinalidadeSelected.value
      );
      setOptionsPrograma(opcoesPrograma);
    }
  };

  const handleChangePrograma = (valueProgramaSelected: object) => {
    setValuePrograma(valueProgramaSelected);
    resetarOptions(setOptionsAcao, setOptionsSubacao, setOptionsProjeto);
    resetarValues("tbl_action_id", "sub_action_id", "project_id");

    if (valueProgramaSelected) {
      const opcoesAcao = acao.data.filter(
        (obj) => obj.program.id == valueProgramaSelected.value
      );
      setOptionsAcao(opcoesAcao);
    }
  };

  const handleChangeAcao = (valueSelectedAcao: object) => {
    setValueAcao(valueSelectedAcao);
    resetarOptions(setOptionsSubacao, setOptionsProjeto);
    resetarValues("sub_action_id", "project_id");

    if (valueSelectedAcao) {
      const opcoesSubacao = subacao.data.filter(
        (obj) => obj.tbl_action.id == valueSelectedAcao.value
      );
      setOptionsSubacao(opcoesSubacao);
    }
  };

  const handleChangeSubacao = (valueSelectedSubacao: object) => {
    setValueSubacao(valueSelectedSubacao);
    resetarOptions(setOptionsProjeto);
    resetarValues("project_id");

    if (valueSelectedSubacao) {
      const opcoesProjeto = projetos.data.filter((obj) =>
        obj.project_lines.some(
          (sub_action) => sub_action.id == valueSelectedSubacao.value
        )
      );
      setOptionsProjeto(opcoesProjeto);
    }
  };

  const handleChangeProjeto = (valueSelectedProjeto: object) => {
    setValueProjeto(valueSelectedProjeto);
  };

  const handleChangeDescricao = (valueSelectedDescricao: object) => {
    setValueDescricao(valueSelectedDescricao);
  };

  const handleAddRubrica = () => {
    const randon_id = Date.now();

    // 100 / 3 = 50

    // function totalValorRubricas(total, item) {
    //   // return total + (item.value);
    //   return console.log(total);
    // }

    const totalValorRubricas = rubricaFront.reduce((accumulator, currentItem) => {
      return parseFloat(accumulator) + parseFloat(currentItem.valor);
    }, 0);

    const valorRubrica = formatarRealparaDecimal(formRef.current.getFieldValue("valorDadosSalario"));
    const salario = formatarRealparaDecimal(formRef.current?.getFieldValue("salario"));

    console.log("valorRubrica", parseFloat(valorRubrica), " parseFloat(totalValorRubricas) ", parseFloat(totalValorRubricas));

    if ((parseFloat(valorRubrica) + parseFloat(totalValorRubricas)) > parseFloat(salario)) {
      alert("Somatório das Rubricas Orçamentárias não pode ultrapassar o valor do Salário")
      return;
    }

    setRubricaFront([
      ...rubricaFront,
      {
        //visivel na edição da linha (valor e codigo_contabil em ambas)
        front_id: randon_id,
        expense_type_id: formRef.current.getFieldValue("expense_type_id"),
        organization_id: formRef.current.getFieldValue(
          "organization_id_salario"
        ),
        management_contract_id: formRef.current.getFieldValue(
          "management_contract_id"
        ),
        comittee_id: formRef.current.getFieldValue("comittee_id"),
        unit_id: formRef.current.getFieldValue("unit_id"),
        finality_id: formRef.current.getFieldValue("finality_id"),
        program_id: formRef.current.getFieldValue("program_id"),
        tbl_action_id: formRef.current.getFieldValue("tbl_action_id"),
        sub_action_id: formRef.current.getFieldValue("sub_action_id"),
        project_id: formRef.current.getFieldValue("project_id"),
        description_id: formRef.current.getFieldValue("description_id"),
        //Visivel na listagem (valor e codigo_contabil em ambas)
        contrato: contrGestao.data.find(
          (i) =>
            i.id === formRef.current.getFieldValue("management_contract_id")
        ).nome,
        comite: comites.data.find(
          (i) => i.id === formRef.current.getFieldValue("comittee_id")
        ).nome,
        codigo_contabil: formRef.current.getFieldValue("accounting_code_id"),
        valor: formatarReais(formRef.current.getFieldValue("valorDadosSalario")),
        percentual:
          (formatarRealparaDecimal(formRef.current.getFieldValue("valorDadosSalario")) /
          formatarRealparaDecimal(formRef.current?.getFieldValue("salario") ) ) *
          100,
        accounting_code_id: codigoContabil[0].value,
      },
    ]);

    setRubricaBack([
      ...rubricaBack,
      {
        front_id: randon_id,
        valor: formatarRealparaDecimal(formRef.current.getFieldValue("valorDadosSalario")),
        percentual:
          (formatarRealparaDecimal(formRef.current.getFieldValue("valorDadosSalario")) /
          formatarRealparaDecimal(formRef.current?.getFieldValue("salario") ) ) *
          100,
        accounting_code_id: codigoContabil[0].value,
      },
    ]);

    resetarValues(
      "expense_type_id",
      "organization_id_salario",
      "management_contract_id",
      "comittee_id",
      "unit_id",
      "finality_id",
      "program_id",
      "tbl_action_id",
      "sub_action_id",
      "project_id",
      "description_id"
    );
    setValueCodigoContabil([]); //reseta o ultimo campo "codigo contabil" maneira diferente da de cima pq ele é text input
    handleCloseDialog();
  };

  const dataTitleRubrica: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    { 
      field: "valor",
      headerName: "Valor (R$)",
      flex: 2,
      editable: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
            return "Defina um valor!";
        }
        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <InputNumberFormat {...params} />
      ),
    },
    {
      field: "percentual",
      headerName: "Percentual (%)",
      flex: 1,
      valueGetter: (params) => {
        if (!params.row.valor || !valueSalario) {
          return "Defina valor da linha e cargo atual!";
        }
        return ((params.row.valor / valueSalario) * 100).toFixed(2);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    }
  ];

  const organizeData = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          contrato: d.management_contract.nome_completo,
          comite: d.comittee.nome,
          codigo_contabil: `${d.expense_type.codigo}.${d.organization.codigo.padStart(2, '0')}.${d.management_contract.codigo.padStart(2, '0')}.${d.comittee.codigo.padStart(2, '0')}.${d.unit.codigo.padStart(2, '0')}.${d.pap_finality.codigo.padStart(2, '0')}.${d.pap_program.codigo.padStart(2, '0')}.${d.pap_action.codigo.padStart(2, '0')}.${d.poa_sub_action.codigo.padStart(2, '0')}.${processo?.project.id.toString().padStart(2, '0')}.${d.description.codigo.padStart(3, '0')}`
        }));
        return data;
      }
    }
    return [];
  };

  // const organizeRubricas = useCallback(() => {
    
  //   let projectLines = organizeData(processo.project?.project_lines);
  //   const userBudgets = usuario?.user_budgets;

  //   if ( (projectLines && projectLines.length) && (userBudgets && userBudgets.length) ) {

  //     userBudgets.map(b => {
  //         const idx = projectLines.findIndex(line => b.project_line.id === line.id);
  //         if (idx !== -1) {
  //           // console.log(projectLines, b, idx);
  //           projectLines[idx].valor = b.valor;
  //           projectLines[idx].budget_id = b.id;
  //         } else {
  //           projectLines = [];
  //         }
          
  //     });      
  //   }

  //   return projectLines;
  // }, [usuario, processo]);

  useEffect(() => {
    setLinhasProjeto(() => {
      let projectLines = organizeData(processo.project?.project_lines);
      const userBudgets = usuario?.user_budgets;

      if ( (projectLines && projectLines.length) && (userBudgets && userBudgets.length) ) {

        userBudgets.map(b => {
            const idx = projectLines.findIndex(line => b.project_line.id === line.id);
            if (idx !== -1) {
              // console.log(projectLines, b, idx);
              projectLines[idx].valor = b.valor;
              projectLines[idx].budget_id = b.id;
            } else {
              projectLines = [];
            }
            
        });      
      }

      return projectLines;
    });

    setValorLinhas(() => {
      let projectLines = organizeData(processo.project?.project_lines);
      const userBudgets = usuario?.user_budgets;

      if ( (projectLines && projectLines.length) && (userBudgets && userBudgets.length) ) {

        userBudgets.map(b => {
            const idx = projectLines.findIndex(line => b.project_line.id === line.id);
            if (idx !== -1) {
              // console.log(projectLines, b, idx);
              projectLines[idx].valor = b.valor;
              projectLines[idx].budget_id = b.id;
            } else {
              projectLines = [];
            }
            
        });      
      }

      return projectLines;
    });
  }, [usuario, processo]);

  const handlerContracts = () => {
    const randon_id = Date.now();

    setContractsFront([
      ...contractsFront,
      {
        new_insert: true,
        front_id: randon_id,
        data_assinatura: formRef.current?.getFieldValue(
          "data_assinatura_contrato"
        ),
        data_vencimento: formRef.current?.getFieldValue(
          "data_vencimento_contrato"
        ),
      },
    ]);

    setContractsBack([
      ...contractsBack,
      {
        new_insert: true,
        front_id: randon_id,
        data_assinatura: formRef.current?.getFieldValue(
          "data_assinatura_contrato"
        ),
        data_vencimento: formRef.current?.getFieldValue(
          "data_vencimento_contrato"
        ),
      },
    ]);
  };

  const dataTitleContracts: GridColDef[] = [
    { field: "data_assinatura", headerName: "Data de assinatura", flex: 1 },
    { field: "data_vencimento", headerName: "Data de vencimento", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteContractClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  function onDeleteContractClick(e: any, row: any): void {
    if (row.new_insert) {
      setContractsFront(contractsFront.filter((el) => el.front_id !== row.front_id));
      setContractsBack(contractsBack.filter((el) => el.front_id !== row.front_id));
    } else {
      console.log(row);
      setContractsFront(contractsFront.filter((el) => el.front_id !== row.front_id));
      setContractsBack([
        ...contractsBack,
        {
          id: row.front_id,
          _destroy: row.front_id,
        },
      ]);
    }
  }

  const handleChangeTipoCarteiraProfissional = () => {
    const tipoCarteiraProfissionalRadioField = formRef.current?.getFieldValue("carteira_trabalho_tipo");
    console.log(tipoCarteiraProfissionalRadioField)
    setTipoCarteiraProfissional(tipoCarteiraProfissionalRadioField);
  };

  const handleUpdateLinha = (updatedRow: Object) => {
    setValorLinhas([]);
    const objUpdatedRow = { id: updatedRow?.id, valor: updatedRow?.valor };
    const updatedValueLinhas = [...valorLinhas.filter((linha) => linha.id !== updatedRow?.id), objUpdatedRow];
    setValorLinhas(updatedValueLinhas);

    return updatedRow;
  };

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <Container>
      <Title color="#333">
        <span>Edição de Cadastro do Jovem Aprendiz</span>
      </Title>
      <Form id="formEmpregados" ref={formRef} onSubmit={handleSubmit}>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12} lg={3}>
              <InputImage
                id="avatar"
                name="avatar"
                image={usuario.avatar}
              />
            </Grid>
            <Grid container xs={12} lg={9}>
              <Grid container>
                <Grid xs={12} lg={6}>
                  <TextTitle>Gênero: </TextTitle>
                  <SelectInput
                    name="genero"
                    options={optionsGenero}
                    placeholder="Selecionar"
                    value={valueGenero}
                    onChange={handleChangeGenero}
                  />
                </Grid>
                {/* <Grid xs={12} lg={6}>
                  <TextTitle>Grau de Instrução: </TextTitle>
                  <SelectInput
                    name='grau_instrucao'
                    options={optionsGrau}
                    value={valueGrauInstrucao}
                    onChange={handleChangeGrauInstrucao}
                    placeholder="Selecionar"
                  />
                </Grid> */}
                <Grid xs={12} lg={12}>
                  <TextTitle>Nome: </TextTitle>
                  <Input type="text" name="nome" defaultValue={usuario.nome} required />
                </Grid>
                <Grid xs={6} lg={6}>
                  <TextTitle>
                    Data de nascimento: <span>*</span>
                  </TextTitle>
                  <Input type="date" name="data_nascimento" defaultValue={usuario.data_nascimento} />
                </Grid>
                <Grid xs={6} lg={6}>
                  <TextTitle>Estado civil: </TextTitle>
                  <SelectInput
                    name="estado_civil"
                    options={optionsEstadoCivil}
                    placeholder="Selecionar"
                    value={valueEstadoCivil}
                    onChange={handleChangeEstadoCivil}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} lg={4}>
              <TextTitle>CPF: </TextTitle>
              <Input type="text" name='cpf' defaultValue={usuario.cpf ? pontuarCpf(usuario.cpf) : ''} mask="cpf" required />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>
                RG: <span>*</span>
              </TextTitle>
              <Input type="text" name="rg" defaultValue={usuario.rg} mask="rg" />
            </Grid>
            <Grid xs={6} lg={4}>
              <TextTitle>
                Órgão Expedidor: <span>*</span>
              </TextTitle>
              <Input type="text" name="orgao_expedidor" defaultValue={usuario.orgao_expedidor} />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Curso: </TextTitle>
              <Input type="text" name="curso" defaultValue={usuario.curso} />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Período atual: </TextTitle>
              <Input type="text" name="periodo_atual" defaultValue={usuario.periodo_atual} />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Previsão de conclusão: </TextTitle>
              <Input type="date" name="previsao_conclusao" defaultValue={usuario.previsao_conclusao} />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>
                O jovem aprendiz está vinculado a um convênio?
              </TextTitle>
              <Radio
                name="vinculado_convenio"
                options={optionsBoolean}
                placeholder="Selecionar"
                onChange={(event) =>
                  setShowConvenioInput(JSON.parse(event.target.value))
                }
              />
            </Grid>
            {showConvenioInput && (
              <Grid xs={12} lg={6}>
                <TextTitle>Convênio: </TextTitle>
                <Input type="text" name="convenio" defaultValue={usuario.convenio} />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Contato">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>Email:</TextTitle>
              <div style={{ position: 'relative' }}>
                <Input style={{ paddingRight: '115px' }} type="text" name="email" />
                <InputAddBtn id='email' name='email_contato' type="button" onClick={handlerContact}>
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Telefone:</TextTitle>
              <div style={{ position: 'relative' }}>
                <Input style={{ paddingRight: '115px' }} type="text" name="telefone" mask="phone" />
                <InputAddBtn id='telefone' name='telefone_contato' type="button" onClick={handlerContact}>
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            {contactsFront.length > 0 ?
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleContacts}
                  rowsData={contactsFront}
                  getRowId={(row) => row.front_id}
                />
              </Grid>
              : null}
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: 'relative' }}>
                <Input type="text" name="cep" defaultValue={usuario.cep} mask="cep" />
                <InputAddBtn type="button" onClick={dataAddress}><FontAwesomeIcon icon={faPlus} /> Pesquisar CEP </InputAddBtn>
              </div>
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6} >
              <TextTitle>Logradouro: </TextTitle>
              <Input
                type="text"
                name="logradouro"
                value={endereco.logradouro}
                onChange={(e) =>
                  setEndereco({ ...endereco, logradouro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={12} lg={6} >
              <TextTitle>Bairro: </TextTitle>
              <Input
                type="text"
                name="bairro"
                value={endereco.bairro}
                onChange={(e) =>
                  setEndereco({ ...endereco, bairro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input type="text" name="numero" defaultValue={usuario.numero} />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input type="text" name="complemento" defaultValue={usuario.complemento} />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={endereco.localidade}
                onChange={(e) =>
                  setEndereco({ ...endereco, localidade: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF: </TextTitle>
              <SelectInput
                name="uf"
                options={optionsUF}
                value={selectedOption}
                onChange={handleChangeSelect}
                placeholder="Selecionar"
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Carteira profissional">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>
                Tipo de carteira profissional:
              </TextTitle>
              <Radio
                name="carteira_trabalho_tipo"
                options={optionsCarteiraProfissional}
                onChange={handleChangeTipoCarteiraProfissional}
              />
            </Grid>
            {tipoCarteiraProfissional == 'fisica' ? (
              <>
                <Grid xs={6} lg={6}>
                  <TextTitle>Data de emissão: </TextTitle>
                  <Input type="date" name="pis_data_emissao" defaultValue={usuario.pis_data_emissao} />
                </Grid>
                <Grid xs={6} lg={6}>
                  <TextTitle>Número: </TextTitle>
                  <Input type="text" name="carteira_trabalho_numero" defaultValue={usuario.carteira_trabalho_numero} />
                </Grid>
                <Grid xs={6} lg={6}>
                  <TextTitle>Série: </TextTitle>
                  <Input type="text" name="carteira_trabalho_serie" defaultValue={usuario.carteira_trabalho_serie} />
                </Grid>
              </>
            ) : null}
            <Grid xs={6} lg={6}>
              <TextTitle>Número do PIS: </TextTitle>
              <Input type="text" name="pis_numero" defaultValue={usuario.pis_numero} mask="pis" />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados bancários">
          <Grid container spacing={6}>
            <Grid xs={6} lg={3}>
              <TextTitle>Tipo de conta:</TextTitle>
              <SelectInput
                name="banco_tipo_conta"
                options={optionsTipoConta}
                value={valueAccountType}
                onChange={handleChangeAccountType}
                placeholder="Selecionar"
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Banco:</TextTitle>
              <SelectInput
                name="banco_nome"
                options={banks}
                value={valueBank}
                onChange={handleChangeBank}
                placeholder="Selecionar"
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Agência:</TextTitle>
              <Input type="text" name="banco_agencia" defaultValue={usuario.banco_agencia} />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Nº da Conta:</TextTitle>
              <Input type="text" name="banco_conta" defaultValue={usuario.banco_conta} />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados institucionais">
          <Grid container spacing={6}>
            <Grid xs={6} lg={6}>
              <TextTitle>Email: </TextTitle>
              <Input type="email" name="email" defaultValue={usuario.email} required />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Cargo: </TextTitle>
              <SelectInput
                name="cargo_atual_id"
                options={cargos.data ? organizeDataSelect(cargos.data.filter(cargo => cargo.employee_type.key == 'jovem_aprendiz'), "id", "nome") : []}
                value={valueCargoAtual}
                placeholder="Selecionar"
                onChange={handleChangeCargoAtual}
                onInputChange={e => { getCargos([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Gerencia: </TextTitle>
              <SelectInput
                name="gerencia_id"
                options={organizeDataSelect(gerencias.data, "id", "nome")}
                value={valueGerencia}
                onChange={handleChangeGerencia}
                placeholder="Selecionar"
                onInputChange={e => { getGerencias([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Superior imediato: </TextTitle>
              <SelectInput
                name="superior_imediato_id"
                options={usuarios.data ? organizeDataSelect(usuarios.data.filter(usuario => usuario.employee_type && usuario.employee_type.key == 'empregado'), "id", "nome") : []}
                value={valueSuperiorImediato}
                onChange={handleChangeSuperiorImediato}
                placeholder="Selecionar"
                onInputChange={e => { getUsers([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Data de admissão: </TextTitle>
              <Input type="date" name="data_admissao" defaultValue={usuario.data_admissao} />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Data de desligamento: </TextTitle>
              <Input type="date" name="data_desligamento" defaultValue={usuario.data_desligamento} />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados do local de trabalho">
          <Grid container spacing={6}>
            <Grid xs={6} lg={6}>
              <TextTitle>Organização: </TextTitle>
              <SelectInput
                name="organization_id"
                options={organizeDataSelect(orgaos.data, "id", "nome")}
                value={valueOrganizacaoTrabalho}
                onChange={handleChangeOrganizacaoTrabalho}
                placeholder="Selecionar"
                onInputChange={e => { getOrganizations([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Local de trabalho: </TextTitle>
              <SelectInput
                name="local_trabalho_id"
                options={organizeDataSelect(unidades.data, "id", "nome")}
                value={valueLocalTrabalho}
                onChange={handleChangeLocalTrabalho}
                placeholder="Selecionar"
                onInputChange={e => { getUnidades([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados salariais">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>Salário: </TextTitle>
              <Input
                type="text"
                name="salario"
                value={formatarReais(valueSalario)}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Area de atuação: </TextTitle>
              <Radio
                name="area_atuacao"
                options={optionsAreaAtuacao}
                placeholder="Selecionar"
              />
            </Grid>

            <Grid xs={6}>
              <TextTitle>
                Número do processo: <span>*</span>
              </TextTitle>
              <SelectInput
                id="law_process_id"
                name="law_process_id"
                placeholder=""
                options={organizeDataNumeroProcesso(processos.data, "id", "codigo", "numero")}
                onChange={(e) => e ? getProcesso(e.value) : formRef.current.setFieldValue("law_process_desc", "")}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Descrição do processo:</TextTitle>
              <Input name="law_process_desc" type="text" disabled />
            </Grid>
            
            <Grid xs={12}>
              <SecondaryTable
                apiRef={apiRef}
                columsData={dataTitleRubrica}
                rowsData={linhasProjeto}
                processRowUpdate={(updatedRow: Object) => handleUpdateLinha(updatedRow)}
                onKeyDown={(e) => checkKeyDown(e)}
              />
            </Grid>
            <Grid xs={6} style={{marginTop: "20px"}}>
              <TextTitle>Total: {formatarReais(valorLinhas?.reduce(function (acc, obj) { return acc + parseFloat(obj?.valor); }, 0))}</TextTitle>
            </Grid>
          </Grid>

          
        </Panel>

        {/* <Panel titulo="Jornada de trabalho">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>Informe o tipo do trabalho: </TextTitle>
              <SelectInput
                name="tipo_trabalho"
                options={optionsTipoTrabalho}
                value={valueTipoTrabalho}
                onChange={handleChangeTipoTrabalho}
                placeholder="Selecionar"
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Informe a carga horária: </TextTitle>
              <SelectInput
                name="carga_horaria"
                options={optionsCargaHoraria}
                value={valueCargaHoraria}
                onChange={handleChangeCargaHoraria}
                placeholder="Selecionar"
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Entrada período 1: <span>*</span>
              </TextTitle>
              <Input type="text" name="hora_entrada_1" defaultValue={usuario.hora_entrada_1} mask="time" />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Saída período 1: <span>*</span>
              </TextTitle>
              <Input type="text" name="hora_saida_1" defaultValue={usuario.hora_saida_1} mask="time" />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Entrada período 2: <span>*</span>
              </TextTitle>
              <Input type="text" name="hora_entrada_2" defaultValue={usuario.hora_entrada_2} mask="time" />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>
                Saída período 2: <span>*</span>
              </TextTitle>
              <Input type="text" name="hora_saida_2" defaultValue={usuario.hora_saida_2} mask="time" />
            </Grid>
          </Grid>
        </Panel> */}

        <Panel titulo="Contrato">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data de assinatura: </TextTitle>
              <Input type="date" name="data_assinatura_contrato" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de vencimento: </TextTitle>
              <Input type="date" name="data_vencimento_contrato" />
            </Grid>
            <Grid xs={12}>
              <ButtonAdd
                id="addContrato"
                name="contratos"
                type="button"
                onClick={handlerContracts}
              >
                <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
              </ButtonAdd>
            </Grid>
            {contractsFront.length > 0 ?
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleContracts}
                  rowsData={contractsFront}
                  getRowId={(row) => row.front_id}
                />
              </Grid>
              : null}
          </Grid>
        </Panel>
      </Form >

      <ContainerButton>
        <Link to="/rh/empregados">
          <button><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
        </Link>
        <button type="submit" form="formEmpregados">
          <FontAwesomeIcon icon={faSave} /> Salvar
        </button>
      </ContainerButton>
    </Container >
  );
}
