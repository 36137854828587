import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Form } from "@unform/web";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useDocumentosInstituicao from "../../../actions/cruds/useDocumentosInstituicao";
import useInstituicao from "../../../actions/cruds/useInstituicao";
import Panel from "../../../components/Panel";
import SecondaryTable from "../../../components/SecondaryTable";

import Grid from "@mui/material/Unstable_Grid2";

import {
  ContainerButton,
  Input,
  TextTitle,
  Title,
} from "../../../styles/global";


export function ViewMembers() {
  const { instituicao, getInstituicao } = useInstituicao();
  const { documentosPorInstituicao, getDocumentosPorInstituicao } =
    useDocumentosInstituicao();

  let { memberId } = useParams();

  useEffect(() => {
    // console.log(instituicao);
    getInstituicao(memberId);
    getDocumentosPorInstituicao(memberId);
  }, []);

  const organizeDataCI = (data) => {
    if (typeof data === "object") {
      data = data.map((d) => ({
        id: d.id,
        front_id: d.id,
        competencia: d.comittee.nome ? d.comittee.nome.toUpperCase() : '',
        instancia: d.instance.nome ? d.instance.nome.toUpperCase() : '',
        atuacao: d.actuation.nome ? d.actuation.nome.toUpperCase() : '',
        segmento: d.segment.nome ? d.segment.nome.toUpperCase() : '',
        representante: d.representative.nome ? d.representative.nome.toUpperCase() : '',
        documento: d.titulo_arquivo,
        arquivo: `${d.url_arquivo}/${d.nome_arquivo}`,
        data_inicio_mandato: formatData(d.data_inicio_mandato),
        data_termino_mandato: formatData(d.data_termino_mandato),
      }));
    }
    return data;
  };

  const formatData = (data) => {
    if (data) {
      const dateSplitted = data.split('-');
      const year = parseInt(dateSplitted[0]);
      const month = parseInt(dateSplitted[1]) - 1;
      const day = parseInt(dateSplitted[2]);
      const dateObject = new Date(year, month, day);

      return `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  };

  const organizeDataDocs = (data) => {
    if (typeof data === "object") {
      data = data.map((d) => ({
        id: d.id,
        documento_nome: d.nome,
        competencia: d.comittee.nome,
        arquivo: `${d.url_arquivo}/${d.nome_arquivo}`,
      }));
    }
    return data;
  };

  const dataTitleCI: GridColDef[] = [
    { field: "competencia", headerName: "Competência", flex: 1 },
    { field: "instancia", headerName: "Instância", flex: 1 },
    { field: "atuacao", headerName: "Atuação", flex: 1 },
    { field: "segmento", headerName: "Seguimento", flex: 1 },
    { field: "representante", headerName: "Representante", flex: 1 },
    { field: "documento", headerName: "Documento", flex: 1 },
    { field: "data_inicio_mandato", headerName: "Mandato - inicío", flex: 1 },
    { field: "data_termino_mandato", headerName: "Mandato - Fim", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.arquivo != 'null/null' ?
              <Link to={{ pathname: params.row.arquivo }} target="_blank">
                <Button variant="text">
                  <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
                </Button>
              </Link>
              : null}
          </>
        );
      },
    },
  ];

  const dataTitleDocs: GridColDef[] = [
    { field: "documento_nome", headerName: "Nome do documento", flex: 1 },
    { field: "competencia", headerName: "Competência", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.arquivo != 'null/null' ?
              <Link to={{ pathname: params.row.arquivo }} target="_blank">
                <Button variant="text">
                  <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
                </Button>
              </Link>
              : null}
          </>
        );
      },
    },
  ];

  return (
    <Container>
      <Title color="#333">
        <span>Visualização de membros - Instituições</span>
      </Title>
      <Form>
        <Panel titulo="Instituição">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Instituição: </TextTitle>
              <Input
                type="text"
                name="nome"
                value={instituicao.nome}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "150px" }}
                  type="text"
                  name="cep"
                  value={instituicao.cep}
                  mask="cep"
                  disabled
                />
              </div>
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro:</TextTitle>
              <Input
                type="text"
                name="logradouro"
                value={instituicao.logradouro}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro:</TextTitle>
              <Input
                type="text"
                name="bairro"
                value={instituicao.bairro}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número:</TextTitle>
              <Input
                type="text"
                name="numero"
                value={instituicao.numero}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento:</TextTitle>
              <Input
                type="text"
                name="complemento"
                value={instituicao.complemento}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Múnicipio:</TextTitle>
              <Input
                type="text"
                name="municipio"
                value={instituicao.municipio}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF:</TextTitle>
              <Input
                type="text"
                name="uf"
                value={instituicao.uf}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Competências e Instâncias">
          <SecondaryTable
            columsData={dataTitleCI}
            rowsData={organizeDataCI(instituicao.institution_competences)}
          />
        </Panel>
        {documentosPorInstituicao.data ? documentosPorInstituicao.data.length > 0 && (
          <Panel titulo="Documentos">
            <SecondaryTable
              columsData={dataTitleDocs}
              rowsData={organizeDataDocs(documentosPorInstituicao.data)}
            />
          </Panel>
        ) : null}
      </Form>
      <ContainerButton>
        <Link to="/membros/instituicoes">
          <button>
            <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
          </button>
        </Link>
      </ContainerButton>
    </Container>
  );
}
