import React, { useEffect, useState } from "react";

import { Container } from "./styles";
import { Title } from "../../../../styles/global";
import MainTable from "../../../../components/MainTable";
import useContaBancaria from "../../../../actions/cruds/Financeiro/useContaBancaria";
import { capitalizeFirstLetter } from "../../../../utils/formatDados";
import { useAuth } from "../../../../hooks/auth";
import getPermission from "../../../../utils/getPermission";

const FinanceiroContaBancaria: React.FC = () => {
  const { contasBancarias, getContasBancarias } = useContaBancaria();

  const [searchValue, setSearchValue] = useState<string>("");
  const [perPage, setPerPage] = useState<number>()

  const {login} = useAuth();
  const financeiroPermission = getPermission(login,"Financeiro") ?? '';

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      getContasBancarias([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }]);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getContasBancarias([{ label: "busca_rapida", value: searchValue }, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getContasBancarias([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }, { label: "page", value: contasBancarias.pagination.current_page + page }]);
  };

  const organizeData = (data: any[]) => {
    const withoutData = <i>Sem dados</i>;
    if (typeof data === "object") {
      data = data.map((item) => {
        return {
          id: item.id,
          despesa: item.expense_type?.nome ?? withoutData,
          contrato_gestao: item.management_contract?.nome_completo ?? withoutData,
          organizacao: item.organization?.nome ?? withoutData,
          comite: item.comittee?.nome ?? withoutData,
          apelido: item.apelido ?? withoutData,
          tipo: capitalizeFirstLetter(item.conta_tipo) ?? withoutData,
          banco: item.conta_banco ?? withoutData,
          agencia: item.conta_agencia ?? withoutData,
          conta: item.conta_numero ?? withoutData,
          ativo: item.ativo,
        };
      });
    }
    return data;
  };

  console.log(contasBancarias)

  const columnData = [
    { headerName: "ID", field: "id" },
    { headerName: "Despesa", field: "despesa" },
    { headerName: "Contrato de Gestão", field: "contrato_gestao" },
    { headerName: "Organização", field: "organizacao" },
    { headerName: "Comitê", field: "comite" },
    { headerName: "Apelido da conta", field: "apelido" },
    { headerName: "Tipo", field: "tipo" },
    { headerName: "Banco", field: "banco" },
    { headerName: "Agência", field: "agencia" },
    { headerName: "Conta", field: "conta" },
    { headerName: "Status", field: "ativo" },
  ];

  return (
    <>
      <Title color="#333">
        <span>Gestão de Contas bancárias</span>
      </Title>
      <Container color="#FFF">
        <MainTable
          columns={columnData}
          data={organizeData(contasBancarias.data)}
          pagination={contasBancarias.pagination}
          permission={financeiroPermission}
          isMaxWidthCell
          handleItemsPerPage={handleItemsPerPage}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          registerLink="/financeiro/cadastros/contabancaria/cadastrar"
          dropdown={{
            view: { linkTo: "/financeiro/cadastros/contabancaria/visualizar" },
            editar: { linkTo: "/financeiro/cadastros/contabancaria/editar" },
            desativar: { linkTo: "bank_accounts" },
          }}
        />
      </Container>
    </>
  );
};

export default FinanceiroContaBancaria;
