import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const usePapOrcamento = () => {
  const history = useHistory();
  const [papOrcamento, setPapOrcamento] = useState({});
  const {addToast} = useToast();

  async function getPapOrcamento(id: number) {
    try {
      const response = await api.get(`pap_budgets/${id}`);
      setPapOrcamento(response.data);
    } catch (e) {}
  }

  async function createPapOrcamento(data: object) {
    try {
        const response = await api.post('pap_budgets', data);
        // addToast({type: 'success', title: 'Valor adicionado!', description: 'Orçamento adicionado com sucesso.'});
    } catch (e) {}
  }

  async function updatePapOrcamento(id: number, data: object) {
    try {
        const response = await api.put(`pap_budgets/${id}`, data);

        // addToast({type: 'success', title: 'Valor alterado!', description: 'Orçamento alterado com sucesso.'});
    } catch (e) {}
  }

  return {
    papOrcamento,
    getPapOrcamento,
    createPapOrcamento,
    updatePapOrcamento
  };
};

export default usePapOrcamento;
