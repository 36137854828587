import { useEffect, useState } from "react";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import stringfyParams from "../../utils/stringfyParams";

const useOrgaoGestor = () => {

    const history = useHistory();
    const [orgaoGestor, setOrgaoGestor] = useState([]);
    const [selectedOrgaoGestor, setSelectedOrgaoGestor] = useState({});
    const [error, setError] = useState(null);

    async function getOrgaoGestor (params?: object) {
        try {
            const response = await api.get(`management_entities${stringfyParams(params)}`);
            setOrgaoGestor(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

     // Busca uma Contrato pelo id
     async function getOrgaoGestorId(id: string){
      try {
        const response = await api.get(`management_entities/${id}`);

        setSelectedOrgaoGestor(response.data);

        return response.data;

      } catch(e){
        setError(e);
      }
    }

    // Cria um novo contrato 
    async function createOrgaoGestor(data: any) {
      try {
        const response = await api.post('management_entities', data);
        alert("Orgão gestor criado!");
        history.goBack();

      } catch(e) {
        setError(e);
      }
    } 

    // Atualiza a Orgao Gestor pelo id
    async function updateOrgaoGestor(id: string, data: any){
    try {
      const response = await api.put(`management_entities/${id}`, data);
      setOrgaoGestor(response.data);
      alert("Orgão gestor alterado!");

      return response.data;

    } catch(e){
      setError(e);
    }
  }

    return {orgaoGestor, createOrgaoGestor, getOrgaoGestor, getOrgaoGestorId, updateOrgaoGestor, selectedOrgaoGestor, error};
}

export default useOrgaoGestor;