import { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import * as Dialog from '@radix-ui/react-dialog';

import { faCheck, faChevronLeft, faExclamationTriangle, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Form } from '@unform/web';
import {
  Button,
  Content,
  Description,
  Overlay,
  Title
} from './styles';

import { FormHandles } from "@unform/core";
import { useAuth } from "../../hooks/auth";
import useLogin from "../../actions/cruds/useLogin";


interface DialogProps {
  open: boolean;
}

export function DialogAccept(props: DialogProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const { login, signOut } = useAuth();
  const { updateLogin } = useLogin();

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open])

  const formRef = useRef<FormHandles>(null);

  function handleSubmit() { 
    updateLogin(login.id, { "termo_aceite": true });
  }

  return (
    <>
    <Dialog.Root open={modalOpen}>
      <Dialog.Portal>
        <Dialog.Overlay>
          <Overlay />
        </Dialog.Overlay>
        <Dialog.Content>
          <Content>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Dialog.Title>
                <Title>
                  <label>
                    <FontAwesomeIcon icon={faExclamationTriangle} size='1x' color="orange" /> - {"Termo de aceite"}
                  </label>
                </Title>
              </Dialog.Title>
              <hr />
              <Dialog.Description>
                <Description>
                  <span>{"Prezado(a),"}<br/>{"Gostaríamos de lembrar que os dados pessoais fornecidos neste sistema são estritamente relacionados às finalidades da Agência da Bacia Hidrográfica do Rio Paraíba do Sul (AGEVAP) e os Comitês de Bacia atendidos por ela. Essas informações são dados pessoais e devem ser tratadas com a máxima responsabilidade."}<br/>{"Ao utilizar este sistema, comprometa-se a seguir as diretrizes da Lei Geral de Proteção de Dados (LGPD) e garantir a privacidade e segurança dos dados pessoais inseridos. Destacamos que é de responsabilidade exclusiva do usuário não utilizar esses dados para fins diferentes dos estabelecidos pela AGEVAP."}<br/>{"Pedimos que, por favor, não repasse ou compartilhe essas informações com terceiros sem a devida autorização. A divulgação não autorizada de dados pessoais é uma violação séria da privacidade e está sujeita a medidas legais."}<br/>{"Caso tenha dúvidas sobre como os dados serão utilizados ou precise de mais informações sobre a LGPD, estamos à disposição para esclarecimentos: encarregado.lgpd@agevap.org.br (Simone Domiciano)."}<br/>{"Ao possuir senha e login sua autorização para uso e enquadramento já esta definido e autorizado, portanto, sua utilização está enquadrada na LGPD."}<br/>{"Agradecemos pela sua compreensão e colaboração na manutenção da segurança e integridade dos dados pessoais. Não forneça sua senha nem usuário com outras pessoas."}</span>
                </Description>
              </Dialog.Description>
              <footer>
                <Button
                  id='confirmar'
                  className="blue"
                  // type='submit'
                  type="button"
                  onClick={() => setModalOpen(false)}
                  >
                  <FontAwesomeIcon icon={faCheck} /> {"Aceito"}
                </Button>
                <Dialog.Close asChild>
                  <Button
                    id="cancelar"
                    className="red"
                    type='button'
                    onClick={signOut}
                    >
                    <FontAwesomeIcon icon={faX} /> {"Não aceito"}
                  </Button>
                </Dialog.Close>
              </footer>
            </Form>
          </Content>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
    </>
  )
}