import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";


const useFinalidades = () => {

  const history = useHistory();
  const [finalidades, setFinalidades] = useState('');
  const [ error, setError] = useState(null);

  // Lista todas as despesas
  async function getFinalidades(params){
    try{
      const response = await api.get(`finalities${stringfyParams(params)}`);
      setFinalidades(response.data);
    } catch(e){
      setError(e);
    }
  }
 

  // Cria uma nova despesa 
  async function createFinalidade(data: string) {
    try {
      const response = await api.post('/finalities', data);
      alert("Despesa criada!");
      console.log('Response createDespesa: ' , response);
      history.goBack();

    } catch(e) {
      setError(e);
    }
  } 

  // Busca uma despesa pelo id
  async function getFinalidadeId(id: string){
    try {
      const response = await api.get(`/finalities/${id}`);

      console.log('Response getdespesaID: ', response);
      setFinalidades(response.data);

      return response.data;

    } catch(e){
      setError(e);
    }
  }

  // Atualiza a despesa pelo id
  async function updateFinalidade(id: string, data: any){
    try {
      const response = await api.put(`/finalities/${id}`, data);
      console.log('Response updateDespesa: ', response);
      setFinalidades(response.data);

      return response.data;

    } catch(e){
      setError(e);
    }
  }
  
  // Desativa a despesa pelo id
  async function DesativarFinalidade(id: any, data: any){

    const formData = new FormData();

    formData.append('id', id);
    formData.append('ativo', data); 

    try {
      const response = await api.put(`/finalities/${id}`, formData);
      
      // console.log('Response updateDespesa: ', response);
      
      window.location.reload();

      return response.data;

    } catch(e){
      setError(e);
    }
  }

  // useEffect(() => {
  //   getDespesas();
  // }, []);

  return { finalidades, getFinalidades, getFinalidadeId, updateFinalidade, createFinalidade, DesativarFinalidade, error };
}

export default useFinalidades;