import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useDocumentosUsuarios = () => {

    const history = useHistory();
    const [documentoUsuario, setDocumentoUsuario] = useState([]);
    // const [selectedDocumentoUsuario, setSelectedDocumentoUsuario] = useState({});

    const [error, setError] = useState(null);

    async function getDocumentoUsuario(params) {
        try {
            const response = await api.get(`user_documents${stringfyParams(params)}`);
            setDocumentoUsuario(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createDocumentoUsuario(data) {
        try {
            const response = await api.post('user_documents', data, {headers: {
                "Content-Type": "multipart/form-data",
            },});

            // getDocumentoUsuario([{label: 'user_id', value: data.user_id}]);
            // console.log('response: ', data);
            // history.goBack();

            getDocumentoUsuario([{ label: 'user_id', value: data.user_id }]);
            alert("Sucesso: Documento adicionado.");

            // history.goBack();

            // return response;
        } catch (e) {
            setError(e);
            alert("Erro: Documento não foi adicionado.")
            // console.log(error);
        }
    }

    async function deleteDocumentoUsuario(data) {
        const {id, user_id} = data;
        try {
            const response = await api.delete(`user_documents/${id}`);
            // getDocumentoUsuario([{label: 'user_id', value: user_id}]);
            // console.log(documentoUsuario);
            // getDocumentosPorInstituicao(institution_id);
            // history.go(0);
            getDocumentoUsuario([{ label: 'user_id', value: user_id }]);
            alert("Sucesso: Documento removido.");
            // return response;
        } catch (e) {
            setError(e);
            alert("Algo deu errado ao remover documento.");
            // console.log(error);
        }
    }


    return {documentoUsuario, getDocumentoUsuario, createDocumentoUsuario, deleteDocumentoUsuario, error};
}

export default useDocumentosUsuarios;