import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faClock } from "@fortawesome/free-solid-svg-icons";

import { Container } from "./styles";

interface IBadgeTableProps {
  status: string;
  tempo?: boolean;
}

const BadgeTable: React.FC<IBadgeTableProps> = ({ status, tempo }) => {
  const statusColors: { [status: string]: string } = {
    "Em dia": "#44B556",
    Vencida: "#F60103",
    "A vencer": "#FDF701",
    Nenhuma: "#99B29E",
    "Concluido com atraso": "#FDAD01",
    "Concluido": "#44B556",
    "No prazo": "#99B29E",
    "Atrasado": "#F60103",
    "INDEFINIDA": "#D3D3D3",
  };

  const getColor = (): string => statusColors[status] || "cor_padrão_aqui";

  if (tempo) {
    return (
      <Container color={getColor()}>
        <FontAwesomeIcon icon={faClock} color="#fff" />
        <span>{status}</span>
      </Container>
    );
  } else {
    return (
      <Container color={getColor()}>
        <FontAwesomeIcon icon={faCheckCircle} color="#fff" />
        <span>{status}</span>
      </Container>
    );
  }
};

export default BadgeTable;
