import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";


const useLinhaProjeto = () => {
  const history = useHistory();
  const [linhasProjeto, setLinhasProjeto] = useState([]);
  const [linhaProjeto, setLinhaProjeto] = useState({});
  const [ error, setError] = useState(null);

  // Lista todas as Linhas de projetos
  async function getLinhasProjetos(params?: object){
    try{
      const response = await api.get(`project_lines${stringfyParams(params)}`);
      setLinhasProjeto(response.data);
    } catch(e){
      setError(e);
    }
  }

  // Lista o projeto por ID
  async function getLinhaProjeto(id: string){
    try{
      const response = await api.get(`project_lines/${id}`);

      setLinhaProjeto(response.data);

      // return response.data;
    } catch(e){
      setError(e);
    }
  }

  // Cria um novo projeto 
  async function createLinhaProjeto(data: any) {
    try {
      await api.post('project_lines', data);
      alert("Linha do Projeto criada!");

    } catch(e) {
      alert("Erro ao criar Linha do Projeto.");
      setError(e);
    }
  } 

  // async function updateLinhaProjeto(id: any, data: any){
  //   try{
  //     const response = await api.put(`project_lines/${id}`, data);

  //     alert("Linha do Projeto atualizada!");

  //   }catch(e){
  //     setError(e);
  //   }
  // }

  async function deleteLinhaProjeto(id: number){
    try{
      await api.delete(`project_lines/${id}`);

      alert("Linha do Projeto deletada!");

    }catch(e){
      alert("Erro ao deletar Linha do Projeto.");
      setError(e);
    }
  }

  // async function DesativarProjeto(id: any, data: any){
    
  //   const formData = new FormData();

  //   formData.append('id', id);
  //   formData.append('ativo', data);

  //   try{
  //     console.log('Erro!');

  //     const response = await api.put(`projects/${id}`, formData);
      
  //     // console.log('funcao data', data);
      
  //     window.location.reload();

  //     return response.data;
  //   }
  //   catch(e){
  //     setError(e);
  //   }
  // }

  return { getLinhasProjetos, linhasProjeto, createLinhaProjeto, deleteLinhaProjeto, error };
}

export default useLinhaProjeto;