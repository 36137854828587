import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ContainerButton2,
  Form,
  Input,
  TextTitle,
  Textarea,
  Title,
} from "../../../../styles/global";
import Panel from "../../../../components/Panel";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { SaveWithProgress } from "../../../../components/SaveCircularIntegration";
import { formatarReais, formatarRealparaDecimal } from "../../../../utils/formatDados";
import Radio from "../../../../components/InputRadio";
import SecondaryTable from "../../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import useDotacao from "../../../../actions/cruds/Orcamentario/useDotacao";
import useProcesso from "../../../../actions/cruds/Processos/useProcesso";

const OrcamentarioDotacaoValidar: React.FC = () => {
  const { dotacaoId } = useParams();
  const { dotacao, getDotacao, updateDotacao } = useDotacao();
  const { processo, getProcesso } = useProcesso();

  const [linhasProjeto, setLinhasProjeto] = useState<Array<Object>>([]);
  const [parecerSolicitacao, setParecerSolicitacao] = useState<String>('');
  const [salvando, setSalvando] = useState<boolean>(false);


  useEffect(() => {
    getDotacao(dotacaoId);
  }, []);

  useEffect(() => {
    if (dotacao?.id) {
      formRef.current?.setFieldValue("rateio", `${dotacao?.rateio}`);
      getProcesso(dotacao?.law_process?.id);
    }

  }, [dotacao])

  const organizeDataLinhas = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d?.project_line?.id,
          codigo: `${d?.project_line.expense_type?.codigo}.${d?.project_line?.organization?.codigo.padStart(2, '0')}.${d?.project_line?.management_contract?.codigo.padStart(2, '0')}.${d?.project_line?.comittee?.codigo.padStart(2, '0')}.${d?.project_line?.unit?.codigo.padStart(2, '0')}.${d?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${d.project_line.pap_program.codigo.padStart(2, '0')}.${d.project_line.pap_action.codigo.padStart(2, '0')}.${d.project_line.poa_sub_action.codigo.padStart(2, '0')}.${processo?.project?.id.toString().padStart(2, '0')}.${d?.project_line?.description?.codigo.padStart(3, '0')}`,
          pap: `${d?.project_line?.pap_finality?.codigo}.${d?.project_line?.pap_program?.codigo}.${d?.project_line?.pap_action?.codigo}`,
          valor: formatarReais(d?.valor),
        }));
        return data;
      }
    }
    return [];
  };

  const optionsBoolean = [
    { id: 'true', value: true, label: "Sim" },
    { id: 'false', value: false, label: "Não" },
  ];

  const parecerOptions = [
    { id: "Prosseguir", value: "Prosseguir", label: "Prosseguir" },
    { id: "Não Prosseguir", value: "Não Prosseguir", label: "Não Prosseguir" },
  ]

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true)

        data["situacao"] = "concluido";

        console.log("data", data);
        await updateDotacao(dotacaoId, data);
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    }, []
  );

  const dataTitleLinhas: GridColDef[] = [
    { field: "codigo", headerName: "Código do projeto", minWidth: 220, flex: 3 },
    { field: "pap", headerName: "Código do PAP", minWidth: 115, flex: 1 },
    { field: "valor", headerName: "Valor", minWidth: 115, flex: 2 },
  ];

  return (
    <Container>
      <Form id="formDotacao" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Validar Dotação</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data da solicitação:</TextTitle>
              <Input type="date" name="dataSolicitacao" value={dotacao?.data_solicitacao} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número do processo:</TextTitle>
              <Input type="text" name="numProcesso" value={`${dotacao?.law_process?.codigo} - (SIGAD - ${dotacao?.law_process?.numero})`} disabled />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Assunto:</TextTitle>
              <Input type="text" name="assunto" value={dotacao?.assunto} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Tipo de processo:</TextTitle>
              <Input type="text" name="tipoProcesso" value={dotacao?.tipo_atividade} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>O projeto é um rateio?</TextTitle>
              <Radio
                name="rateio"
                options={optionsBoolean}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Linha">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {dotacao?.allocation_budgets?.length > 0 && processo?.id && (
                <SecondaryTable
                  columsData={dataTitleLinhas}
                  rowsData={organizeDataLinhas(dotacao?.allocation_budgets)}
                />
              )}
            </Grid>
            <Grid xs={6}>
              <TextTitle>Total: {formatarReais(dotacao?.allocation_budgets?.reduce(function (acc, obj) { return acc + parseFloat(obj?.valor); }, 0))}</TextTitle>
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Parecer da análise">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Parecer da solicitação de pagamento:</TextTitle>
              <Radio
                name="parecer"
                options={parecerOptions}
                onChange={(e) => setParecerSolicitacao(e.target.value)}
                required
              />
            </Grid>
            {parecerSolicitacao == 'Não Prosseguir' && (
              <Grid xs={12}>
                <TextTitle>Justificativa:</TextTitle>
                <Textarea name="justificativa" required></Textarea>
              </Grid>
            )}
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/orcamentario/dotacao">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formDotacao"}></SaveWithProgress>
      </ContainerButton2>
    </Container >
  );
};

export default OrcamentarioDotacaoValidar;
