import { useCallback, useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import {
  faCloudArrowUp,
  faMagnifyingGlass,
  faPlus,
  faSave,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "../../../components/Panel";
import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton,
  ContainerButton2,
  Form,
  FormContainer,
  Input,
  SelectInput,
  TextTitle,
  Title,
} from "../../../styles/global";
import TableSimple from "../../../components/SimpleTable";
import { ButtonSearch, Instancia } from "./styles";
import useProcesso from "../../../actions/cruds/Processos/useProcesso";
import organizeDataSelect from "../../../utils/organizeDataSelect";
import useFornecedor from "../../../actions/cruds/useFornecedor";
import useUsuario from "../../../actions/cruds/useUsuario";
import useBancos from "../../../actions/cruds/useBancos";
import InputFile from "../../../components/InputFile";
import Radio from "../../../components/InputRadio";
import useInstrumento from "../../../actions/cruds/InstrumentoContratual/useInstrumento";
import BasicDialog from "../../../components/Dialog";
import useDespesas from "../../../actions/cruds/useDespesas";
import useOrgao from "../../../actions/cruds/useOrgao";
import useContratoGestao from "../../../actions/cruds/useContratoGestao";
import useComite from "../../../actions/cruds/useComite";
import useFinalidades from "../../../actions/cruds/useFinalidade";
import usePrograma from "../../../actions/cruds/usePrograma";
import useAcao from "../../../actions/cruds/useAcao";
import useSubAcao from "../../../actions/cruds/useSubAcao";
import useProjeto from "../../../actions/cruds/useProjeto";
import SecondaryTable from "../../../components/SecondaryTable";
import useDescricao from "../../../actions/cruds/useDescricao";
import useUnidade from "../../../actions/cruds/useUnidade";
import useCodigoContabil from "../../../actions/cruds/useCodigoContabil";
import { GridColDef, GridRenderEditCellParams, GridValueFormatterParams, useGridApiRef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { SaveWithProgress } from "../../../components/SaveCircularIntegration";
import InputFilev2 from "../../../components/InputFilev2";
import organizeDataNumeroProcesso from "../../../utils/organizeDataNumeroProcesso";
import InputNumberFormat from "../../../components/InputNumberFormat";
import { useToast } from "../../../hooks/toast";
import { formatarReais } from "../../../utils/formatDados";

const InstrumentoCreate: React.FC = () => {
  const { processos, processo, getProcesso, getProcessos } = useProcesso();
  const { fornecedores, fornecedor, getFornecedor, getFornecedores } = useFornecedor();
  const { usuarios, getUsers } = useUsuario();
  const { bancos, getBancos } = useBancos();
  const { createInstrumentos } = useInstrumento();
  const { despesas, getDespesas } = useDespesas();
  const { orgaos, getOrganizations } = useOrgao();
  const { contrGestao, getContratos } = useContratoGestao();
  const { comites, getComittees } = useComite();
  const { finalidades, getFinalidades } = useFinalidades();
  const { programas, getProgramas } = usePrograma();
  const { acao, getAcoes } = useAcao();
  const { subacao, getSubAcao } = useSubAcao();
  const { projetos, getProjetos } = useProjeto();
  const { descricao, getDescricoes } = useDescricao();
  const { unidadesComite, getUnidadesComite } = useUnidade();
  const { unidades, getUnidades } = useUnidade();
  const { codigosContabeis, getCodigosContabeis } = useCodigoContabil();

  const [openDialog, setOpenDialog] = useState(false);

  const [valorContrato, setValorContrato] = useState<Number>();
  const [valueOrganizacao, setValueOrganizacao] = useState<Object>();
  const [optionsContrato, setOptionsContrato] = useState<Object>([]);
  const [valueContrato, setValueContrato] = useState<Object>();
  const [optionsComite, setOptionsComite] = useState<Object>([]);
  const [valueComite, setValueComite] = useState<Object>();
  const [optionsFinalidade, setOptionsFinalidade] = useState<Object>([]);
  const [valueFinalidade, setValueFinalidade] = useState<Object>();
  const [optionsPrograma, setOptionsPrograma] = useState<Object>([]);
  const [valuePrograma, setValuePrograma] = useState<Object>();
  const [optionsAcao, setOptionsAcao] = useState<Object>([]);
  const [valueAcao, setValueAcao] = useState<Object>();
  const [optionsSubacao, setOptionsSubacao] = useState<Object>([]);
  const [valueSubacao, setValueSubacao] = useState<Object>();
  const [optionsProjeto, setOptionsProjeto] = useState<Object>([]);
  const [valueProjeto, setValueProjeto] = useState<Object>();
  const [valueDescricao, setValueDescricao] = useState<Object>();
  const [valueTipoDespesa, setValueTipoDespesa] = useState<Object>();
  const [optionsUnidade, setOptionsUnidade] = useState<Object>([]);
  const [valueUnidade, setValueUnidade] = useState<Object>();
  const [valueCodigoContabil, setValueCodigoContabil] = useState<Array<Object>>(
    []
  );
  const [codigoContabil, setCodigoContabil] = useState<Array<Object>>();

  const [rubricaFront, setRubricaFront] = useState([]);
  const [rubricaBack, setRubricaBack] = useState([]);

  const [instrumentoOptions, setInstrumentoOptions] = useState([]);
  const [bankAccount, setBankAccount] = useState([]);
  const [convenio, setConvenio] = useState(false);
  const [outroTipoIntrumento, setOutroTipoIntrumento] = useState(false);
  const [isRateio, setIsRateio] = useState();
  const [btnAddRubrica, setBtnAddRubrica] = useState();
  const [salvando, setSalvando] = useState<boolean>(false);

  const [valorTotalInstrumento, setValorTotalInstrumento] = useState<number>();
  const [valorLinhas, setValorLinhas] = useState<Array<Object>>([]);
  const [linhasProjeto, setLinhasProjeto] = useState<Array<Object>>([]);

  const {addToast} = useToast();

  useEffect(() => {
    getProcessos();
    getFornecedores();
    getUsers();
    getBancos();

    getDespesas();
    getOrganizations();
    getComittees();
    getContratos();
    getFinalidades();
    getProgramas();
    getAcoes();
    getSubAcao();
    getProjetos();
    getUnidadesComite();
    getUnidades();
    getDescricoes();
    getCodigosContabeis();
    setInstrumentoOptions(tipo_instrumento_options);
  }, []);

  useEffect(() => {
    formRef.current.setFieldValue(
      "supplier_name",
      fornecedor.nome_razao_social
    );
    if (
      fornecedor.consorcio &&
      Array.isArray(fornecedor.consorcio_ids) &&
      fornecedor.consorcio_ids.length
    ) {
      setBankAccount([...fornecedor.consorcio_parceiro]);
    } else {
      setBankAccount([
        {
          nome_razao_social: fornecedor.nome_razao_social,
          banco_tipo_conta: fornecedor.banco_tipo_conta,
          banco_nome: fornecedor.banco_nome,
          banco_agencia: fornecedor.banco_agencia,
          banco_conta: fornecedor.banco_conta,
        },
      ]);
    }

    if (fornecedor?.consorcio) {
      const tipoInstrumentoConvenio = formRef.current?.getFieldValue("tipo_instrumento");
      if (tipoInstrumentoConvenio == 'Convênios') {
        formRef.current?.setFieldValue("tipo_instrumento", "");
      }
      setInstrumentoOptions(tipo_instrumento_options.filter((opt) => opt.value != 'Convênios'));
    } else if (fornecedor?.consorcio == false) {
      setInstrumentoOptions(tipo_instrumento_options);
    };
  }, [fornecedor]);

  useEffect(() => {
    formRef.current.setFieldValue("law_process_desc", processo.assunto_processo);

    setLinhasProjeto(organizeData(processo.project?.project_lines));
  }, [processo]);

  const organizeDataCodigoContabil = (data: any) => {
    if (typeof data === "object") {
      data = data.map((d) => ({
        value: d.id,
        label: d.codigo_contabil,
      }));
    }

    return data;
  };

  useEffect(() => {
    // if (valueTipoDespesa && valueProjeto && valueDescricao) {
    if (valueTipoDespesa && valueDescricao) {
      const codigoContabilRubrica = codigosContabeis.data.filter(
        (obj) =>
          obj.expense_type.id == valueTipoDespesa.value &&
          obj.organization.id == valueOrganizacao.value &&
          obj.management_contract.id == valueContrato.value &&
          obj.comittee.id == valueComite.value &&
          obj.unit.id == valueUnidade.value &&
          obj.finality.id == valueFinalidade.value &&
          obj.program.id == valuePrograma.value &&
          obj.tbl_action.id == valueAcao.value &&
          obj.sub_action.id == valueSubacao.value &&
          // obj.project.id == valueProjeto.value &&
          obj.description.id == valueDescricao.value
      );
      setValueCodigoContabil([]);
      setCodigoContabil([]);
      if (codigoContabilRubrica.length) {
        setValueCodigoContabil(codigoContabilRubrica[0].codigo_contabil);
        setCodigoContabil(organizeDataCodigoContabil(codigoContabilRubrica));
      } else {
        alert("Código Contábil não encontrado");
      }
    }
  }, [valueTipoDespesa, valueProjeto, valueDescricao]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // const organizeDataSelectInst = (data: any) => {

  //   if (typeof data === 'object') {
  //     data = data.map(d=>({
  //       value: d.id,
  //       label: d.nome,
  //     }));
  //   }

  //   return data;
  // }

  const resetarOptions = (...optionsField) => {
    optionsField.forEach((optionsField) => {
      optionsField([]);
    });
  };
  const resetarValues = (...fieldName) => {
    fieldName.forEach((fieldName) => {
      const field = formRef.current?.getFieldRef(fieldName);
      if (field) {
        field.clearValue();
      }
    });
  };

  const handleChangeTipoDespesa = (valueTipoDespesaSelected: object) => {
    setValueTipoDespesa(valueTipoDespesaSelected);
  };

  const handleChangeOrganizacao = (valueOrganizacaoSelected: object) => {
    setValueOrganizacao(valueOrganizacaoSelected);
    resetarOptions(
      setOptionsContrato,
      setOptionsComite,
      setOptionsFinalidade,
      setOptionsFinalidade,
      setOptionsPrograma,
      setOptionsAcao,
      setOptionsSubacao,
      setOptionsProjeto
    );
    resetarValues(
      "management_contract_id",
      "comittee_id",
      "unit_id",
      "finality_id",
      "program_id",
      "tbl_action_id",
      "sub_action_id",
      "project_id"
    );

    console.log("valueOrganizacaoSelected: ", valueOrganizacaoSelected);
    if (valueOrganizacaoSelected) {
      const opcoesContrato = contrGestao.data.filter(
        (obj) => obj.id == valueOrganizacaoSelected.value
      );
      console.log("opcoesContrato: ", opcoesContrato);
      setOptionsContrato(opcoesContrato);
    }
  };

  const handleChangeContrato = (valueContratoSelected: object) => {
    setValueContrato(valueContratoSelected);
    resetarOptions(
      setOptionsComite,
      setOptionsFinalidade,
      setOptionsFinalidade,
      setOptionsPrograma,
      setOptionsAcao,
      setOptionsSubacao,
      setOptionsProjeto
    );
    resetarValues(
      "comittee_id",
      "unit_id",
      "finality_id",
      "program_id",
      "tbl_action_id",
      "sub_action_id",
      "project_id"
    );

    if (valueContratoSelected) {
      const opcoesComite = comites.data.filter(
        (obj) => obj.management_contract.id == valueContratoSelected.value
      );
      setOptionsComite(opcoesComite);
    }
  };

  const handleChangeComite = (valueComiteSelected: object) => {
    setValueComite(valueComiteSelected);
    resetarOptions(
      setOptionsUnidade,
      setOptionsFinalidade,
      setOptionsPrograma,
      setOptionsAcao,
      setOptionsSubacao,
      setOptionsProjeto
    );
    resetarValues(
      "unit_id",
      "finality_id",
      "program_id",
      "tbl_action_id",
      "sub_action_id",
      "project_id"
    );

    if (valueComiteSelected) {
      const opcoesFinalidade = finalidades.data.filter(
        (obj) => obj.comite.id == valueComiteSelected.value
      );
      setOptionsFinalidade(opcoesFinalidade);

      const opcoesUnidadeComite = unidadesComite.data.filter(
        (obj) => obj.comittee_id === valueComiteSelected.value
      );
      const opcoesUnidade = unidades.data.filter((obj) =>
        opcoesUnidadeComite.some((opc) => opc.comittee_id === obj.id)
      );
      setOptionsUnidade(opcoesUnidade);
    }
  };

  const handleChangeUnidade = (valueUnidadeSelected: object) => {
    setValueUnidade(valueUnidadeSelected);
  };

  const handleChangeFinalidade = (valueFinalidadeSelected: object) => {
    setValueFinalidade(valueFinalidadeSelected);
    resetarOptions(
      setOptionsPrograma,
      setOptionsAcao,
      setOptionsSubacao,
      setOptionsProjeto
    );
    resetarValues("program_id", "tbl_action_id", "sub_action_id", "project_id");

    if (valueFinalidadeSelected) {
      const opcoesPrograma = programas.data.filter(
        (obj) => obj.finality.id == valueFinalidadeSelected.value
      );
      setOptionsPrograma(opcoesPrograma);
    }
  };

  const handleChangePrograma = (valueProgramaSelected: object) => {
    setValuePrograma(valueProgramaSelected);
    resetarOptions(setOptionsAcao, setOptionsSubacao, setOptionsProjeto);
    resetarValues("tbl_action_id", "sub_action_id", "project_id");

    if (valueProgramaSelected) {
      const opcoesAcao = acao.data.filter(
        (obj) => obj.program.id == valueProgramaSelected.value
      );
      setOptionsAcao(opcoesAcao);
    }
  };

  const handleChangeAcao = (valueSelectedAcao: object) => {
    setValueAcao(valueSelectedAcao);
    resetarOptions(setOptionsSubacao, setOptionsProjeto);
    resetarValues("sub_action_id", "project_id");

    if (valueSelectedAcao) {
      const opcoesSubacao = subacao.data.filter(
        (obj) => obj.tbl_action.id == valueSelectedAcao.value
      );
      setOptionsSubacao(opcoesSubacao);
    }
  };

  const handleChangeSubacao = (valueSelectedSubacao: object) => {
    setValueSubacao(valueSelectedSubacao);
    resetarOptions(setOptionsProjeto);
    resetarValues("project_id");

    if (valueSelectedSubacao) {
      const opcoesProjeto = projetos.data.filter((obj) =>
        obj.project_lines.some(
          (sub_action) => sub_action.id == valueSelectedSubacao.value
        )
      );
      setOptionsProjeto(opcoesProjeto);
    }
  };

  const handleChangeProjeto = (valueSelectedProjeto: object) => {
    setValueProjeto(valueSelectedProjeto);
  };

  const handleChangeDescricao = (valueSelectedDescricao: object) => {
    setValueDescricao(valueSelectedDescricao);
  };

  const apiRef = useGridApiRef();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true);

        data["valor_total"] = valorTotalInstrumento;

        const rowsData = Array.from( apiRef.current.getRowModels(), ([, value]) => (value) );
        // console.log('rowsData: ', rowsData);
        const rubricaSemValor = rowsData.every(row => row.valor);
        
        if (!rubricaSemValor) {
          alert("Todas as linhas da Rubrica orçamentária devem conter valor.");
          return;
        }

        data["contractual_instrument_budgets_attributes"] = rowsData.map(d => ({
          project_line_id: d.id,
          valor: d.valor
        }));

        // console.log("data", data);
        await createInstrumentos(data);
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    },
    [rubricaBack, apiRef, valorTotalInstrumento]
  );

  const handleAddRubrica = () => {
    const randon_id = Date.now();

    // 100 / 3 = 50

    function totalValorRubricas(total, item) {
      // return total + (item.value);
      return console.log(total);
    }

    console.log(rubricaBack.reduce(totalValorRubricas, 0));

    setRubricaFront([
      ...rubricaFront,
      {
        //visivel na edição da linha (valor e codigo_contabil em ambas)
        front_id: randon_id,
        expense_type_id: formRef.current.getFieldValue("expense_type_id"),
        organization_id: formRef.current.getFieldValue("organization_id"),
        management_contract_id: formRef.current.getFieldValue(
          "management_contract_id"
        ),
        comittee_id: formRef.current.getFieldValue("comittee_id"),
        unit_id: formRef.current.getFieldValue("unit_id"),
        finality_id: formRef.current.getFieldValue("finality_id"),
        program_id: formRef.current.getFieldValue("program_id"),
        tbl_action_id: formRef.current.getFieldValue("tbl_action_id"),
        sub_action_id: formRef.current.getFieldValue("sub_action_id"),
        project_id: formRef.current.getFieldValue("project_id"),
        description_id: formRef.current.getFieldValue("description_id"),
        //Visivel na listagem (valor e codigo_contabil em ambas)
        contrato: contrGestao.data.find(
          (i) =>
            i.id === formRef.current.getFieldValue("management_contract_id")
        ).nome,
        comite: comites.data.find(
          (i) => i.id === formRef.current.getFieldValue("comittee_id")
        ).nome,
        codigo_contabil: formRef.current.getFieldValue("accounting_code_id"),
        valor: formRef.current.getFieldValue("valor"),
        percentual: "100%",
      },
    ]);

    setRubricaBack([
      ...rubricaBack,
      {
        front_id: randon_id,
        valor: formRef.current.getFieldValue("valor"),
        percentual: 100,
        accounting_code_id: codigoContabil[0].value,
      },
    ]);

    resetarValues(
      "expense_type_id",
      "organization_id",
      "management_contract_id",
      "comittee_id",
      "unit_id",
      "finality_id",
      "program_id",
      "tbl_action_id",
      "sub_action_id",
      "project_id",
      "description_id"
    );
    setValueCodigoContabil([]); //reseta o ultimo campo "codigo contabil" maneira diferente da de cima pq ele é text input
    handleCloseDialog();

    if (rubricaBack.length) {
      console.log(ENTROU);
      // setBtnAddRubrica();
    }
  };

  const tipo_instrumento_options = [
    {
      value: "Contratos",
      label: "Contratos",
    },
    {
      value: "Termo de Doação",
      label: "Termo de Doação",
    },
    {
      value: "Contratos de Transferências",
      label: "Contratos de Transferências",
    },
    {
      value: "Ordens de Serviços",
      label: "Ordens de Serviços",
    },
    {
      value: "Convênios",
      label: "Convênios",
    },
    {
      value: "Termo de Cooperação Técnica",
      label: "Termo de Cooperação Técnica",
    },
    {
      value: "Protocolo de Intenções",
      label: "Protocolo de Intenções",
    },
    {
      value: "Termo de Cessão de Uso",
      label: "Termo de Cessão de Uso",
    },
    {
      value: "Termo de Concessão de Auxílio Financeiro",
      label: "Termo de Concessão de Auxílio Financeiro",
    },
    {
      value: "Acordo de Cooperação Técnica",
      label: "Acordo de Cooperação Técnica",
    },
    {
      value: "Outros",
      label: "Outros",
    },
  ];

  const tiposConta = [
    { value: "corrente", label: "Conta corrente" },
    { value: "poupanca", label: "Conta poupança" },
  ];

  const dataTitleRubrica: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    { 
      field: "valor",
      headerName: "Valor (R$)",
      flex: 2,
      editable: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "R$ 0,00";
        }
        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <InputNumberFormat {...params} />
      ),
    },
    {
      field: "percentual",
      headerName: "Percentual (%)",
      flex: 1,
      valueGetter: (params) => {
        if (!params.row.valor || !valorTotalInstrumento) {
          return "Defina valor da linha e valor total do instrumento!";
        }
        return ((params.row.valor / valorTotalInstrumento) * 100).toFixed(2);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    }
  ];

  const dataTitleAccounts: GridColDef[] = [
    { field: "nome_razao_social", headerName: "Fornecedor", flex: 1 },
    { field: "banco_tipo_conta", headerName: "Tipo de conta", flex: 1 },
    { field: "banco_nome", headerName: "Banco", flex: 1 },
    { field: "banco_agencia", headerName: "Agência", flex: 1 },
    { field: "banco_conta", headerName: "Conta", flex: 1 },
  ];

  const tipoInstrumentoChange = (e) => {
    if (e) {
      const { value } = e;

      switch (value) {
        case "Convênios":
          setConvenio(true);
          setOutroTipoIntrumento(false);
          break;

        case "Outros":
          setOutroTipoIntrumento(true);
          setConvenio(false);
          break;

        default:
          setConvenio(false);
          setOutroTipoIntrumento(false);
          break;
      }
    } else if (!e) {
      setConvenio(false);
      setOutroTipoIntrumento(false);
    }
    // e.value === "CONVÊNIOS" ? setConvenio(true) : setConvenio(false)
  };

  const organizeData = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          contrato: d.management_contract.nome_completo,
          comite: d.comittee.nome,
          codigo_contabil: `${d.expense_type.codigo}.${d.organization.codigo.padStart(2, '0')}.${d.management_contract.codigo.padStart(2, '0')}.${d.comittee.codigo.padStart(2, '0')}.${d.unit.codigo.padStart(2, '0')}.${d.pap_finality.codigo.padStart(2, '0')}.${d.pap_program.codigo.padStart(2, '0')}.${d.pap_action.codigo.padStart(2, '0')}.${d.poa_sub_action.codigo.padStart(2, '0')}.${processo?.project.id.toString().padStart(2, '0')}.${d.description.codigo.padStart(3, '0')}`
        }));
        return data;
      }
    }
    return [];
  };

  const handleUpdateLinha = (updatedRow: Object) => {
    const objUpdatedRow = { id: updatedRow?.id, val: updatedRow?.valor };
    const updatedValueLinhas = [...valorLinhas.filter((linha) => linha.id !== updatedRow?.id), objUpdatedRow];
    setValorLinhas(updatedValueLinhas);

    return updatedRow;
  };

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <Container>
      <Form id="createInstrumentoForm" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Cadastro de instrumento contratual</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>
                Número do processo: <span>*</span>
              </TextTitle>
              <SelectInput
                id="law_process_id"
                name="law_process_id"
                placeholder=""
                options={organizeDataNumeroProcesso(processos.data, "id", "codigo", "numero")}
                onChange={(e) => e ? getProcesso(e.value) : formRef.current.setFieldValue("law_process_desc", "")}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Descrição do processo:</TextTitle>
              <Input name="law_process_desc" type="text" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Tipo de instrumento: <span>*</span>
              </TextTitle>
              <SelectInput
                id="tipoInstrumento"
                name="tipo_instrumento"
                placeholder="Selecionar"
                options={instrumentoOptions}
                onChange={(e) => tipoInstrumentoChange(e)}
              />
            </Grid>
            {outroTipoIntrumento ?
              <Grid xs={6}>
                <TextTitle>
                  Descrição: <span>*</span>
                </TextTitle>
                <Input name="tipo_descricao" type="text"></Input>
              </Grid>
              : <Grid xs="auto" />}
            <Grid xs={6}>
              <TextTitle>
                CPF/CNPJ do fornecedor: <span>*</span>
              </TextTitle>
              <SelectInput
                id="supplier_id"
                name="supplier_id"
                placeholder=""
                options={organizeDataSelect(fornecedores.data, "id", "cpf_cnpj")}
                onChange={(e) => e ? getFornecedor(e.value) : formRef.current.setFieldValue("supplier_name", "")}
                onInputChange={(e) => {
                  getFornecedores([{ label: "cpf_cnpj", value: e }, { label: 'ativo', value: true }])
                }}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Nome do Fonecedor: </TextTitle>
              <Input name="supplier_name" type="text" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Número do instrumento: <span>*</span>
              </TextTitle>
              <Input name="numero" type="text" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Instrumento Convocatório: </TextTitle>
              <Input
                name="instrumento_convocatorio"
                type="text"
                placeholder=""
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>
                Objeto do instrumento: <span>*</span>
              </TextTitle>
              <Input
                name="objeto"
                type="text"
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Data de assinatura do contrato: <span>*</span>
              </TextTitle>
              <Input name="data_assinatura_contrato" type="date" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data do início da vigência: </TextTitle>
              <Input name="data_inicio_vigencia" type="date" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de término da vigência: </TextTitle>
              <Input name="data_termino_vigencia" type="date" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Valor total do instrumento: <span>*</span>
              </TextTitle>
              <Input
                format="currency"
                currency="BRL"
                name="valor_total"
                onNumberFormat={(event: object) => setValorTotalInstrumento(event.detail?.number)}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Gestor do instrumento contratual: <span>*</span>
              </TextTitle>
              <SelectInput
                id="gesInstrContratual"
                name="gestor_id"
                placeholder="Selecionar"
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: "nome", value: e }, { label: 'ativo', value: true }])
                }}
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Substituto do gestor do instrumento contratual:<span>*</span>
              </TextTitle>
              <SelectInput
                id="subgesInstrContratual"
                name="gestor_substituto_id"
                placeholder="Selecionar"
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: "nome", value: e }, { label: 'ativo', value: true }])
                }}
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados Bancários">
          <Grid container spacing={6}>
            {convenio ? (
              <>
                <Grid xs={12}>
                  <TextTitle>Conta bancária do Fornecedor (convênio): </TextTitle>
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Tipo de conta:</TextTitle>
                  <SelectInput
                    id="tipoConta"
                    name="banco_tipo_conta"
                    placeholder="Selecionar"
                    options={tiposConta}
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Banco:</TextTitle>
                  <SelectInput
                    id="banco"
                    name="banco_nome"
                    placeholder="Selecionar"
                    options={organizeDataSelect(bancos, "name", "name")}
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Agência:</TextTitle>
                  <Input name="banco_agencia" type="text" />
                </Grid>
                <Grid xs={6} lg={3}>
                  <TextTitle>Conta:</TextTitle>
                  <Input name="banco_conta" type="text" />
                </Grid>
              </>
            ) : (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleAccounts}
                  rowsData={bankAccount}
                  getRowId={(row) => row.nome_razao_social}
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Rubrica orçamentária">
          <Grid container spacing={6}>
              <Grid xs={12}>
                <SecondaryTable
                  apiRef={apiRef}
                  columsData={dataTitleRubrica}
                  rowsData={linhasProjeto}
                  processRowUpdate={(updatedRow: Object) => handleUpdateLinha(updatedRow)}
                  onKeyDown={(e) => checkKeyDown(e)}
                  onProcessRowUpdateError={(error) => console.log(error)}
                />
              </Grid>
          </Grid>
          <Grid xs={6} style={{marginTop: "20px"}}>
            <TextTitle>Total: {formatarReais(valorLinhas?.reduce(function (acc, obj) { return acc + parseFloat(obj?.val); }, 0))}</TextTitle>
          </Grid>
        </Panel>

        <Panel titulo="Contrato">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Descrição: </TextTitle>
              <Input type="text" name="contrato_descricao" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Contrato:</TextTitle>
              <InputFilev2
                id="contrato_arquivo"
                name="contrato_arquivo"
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Extrato da publicação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data da Publicação:</TextTitle>
              <Input type="date" name="publicacao_data" />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Extrato:</TextTitle>
              <InputFilev2
                id="publicacao_arquivo"
                name="publicacao_arquivo"
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>
                Descrição: <span>*</span>
              </TextTitle>
              <Input type="text" name="publicacao_descricao" />
            </Grid>
          </Grid>
        </Panel>
      </Form >

      <ContainerButton2>
        <Link to="/instrumentos">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form="createInstrumentoForm" />
      </ContainerButton2>
    </Container>
  );
}

export default InstrumentoCreate;