import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";
import { useToast } from "../../../hooks/toast";

const useAutorizacaoDespesa = () => {

    const history = useHistory();
    const [autorizacaoDespesa, setAutorizacaoDespesa] = useState([]);
    const [autorizacoesDespesas, setAutorizacaoDespesas] = useState([]);
    const [error, setError] = useState(null);
    const { addToast } = useToast();

    async function getAutorizacoesDespesas(params?: object) {
        try {
            const response = await api.get(`expense_authorizations${stringfyParams(params)}`);
            setAutorizacaoDespesas(response.data);
        } catch (e) {
            setError(e);
        }
    };

    async function getAutorizacaoDespesa(id: string) {
        try {
            const response = await api.get(`expense_authorizations/${id}`);
            setAutorizacaoDespesa(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    };

    async function createAutorizacaoDespesa(data: object) {
        try {
            const response = await api.post('expense_authorizations', data);
            return response;
        } catch (e) {
            setError(e);
        }
    };

    async function updateAutorizacaoDespesa(id: string, data: object) {
        try {
            const response = await api.put(`expense_authorizations/${id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            addToast({
                type: 'success',
                title: 'Autorização atualizada!',
                description: 'Autorização de Despesa atualizada com sucesso!',
            });
            history.push("/financeiro/autorizacaodespesa");
            return response
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    };


    return { autorizacaoDespesa, autorizacoesDespesas, getAutorizacaoDespesa, getAutorizacoesDespesas, createAutorizacaoDespesa, updateAutorizacaoDespesa, error };
}

export default useAutorizacaoDespesa;