import { useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";


const useContratoGestao = () => {

  const history = useHistory();
  const [contrGestao, setContrGestao] = useState([]);
  const [selectContrGestao, setSelectContrGestao] = useState({
    organization: {},
    management_entity: {}
  });
  const [ error, setError] = useState(null);

  // Lista todos os contratos
  async function getContratos(params?: object){
    try{
      const response = await api.get(`management_contracts${stringfyParams(params)}`);
      setContrGestao(response.data);
    } catch(e){
      setError(e);
    }
  }
 

  // Cria um novo contrato 
  async function createContrato(data: any) {
    try {
      const response = await api.post('management_contracts', data);
      alert("Contrato de gestão criado!");
      history.goBack();

    } catch(e) {
      setError(e);
    }
  } 

  // Busca um contrato pelo id
  async function getContratoId(id: string){
    try {
      const response = await api.get(`management_contracts/${id}`);

      console.log('Response contrato ID: ', response);
      setSelectContrGestao(response.data);

      return response.data;

    } catch(e){
      setError(e);
    }
  }

  // Atualiza um contrato pelo id
  async function updateContrato(id: string, data: any){
    try {
      const response = await api.put(`management_contracts/${id}`, data);
      alert("Contrato de gestão alterado!");
      history.push("/orcamentario/cadastros/contratosgestao")

      return response.data;

    } catch(e){
      setError(e);
    }
  }

  // useEffect(() => {
  //   getDespesas();
  // }, []);

  return { contrGestao, getContratos, getContratoId, updateContrato, createContrato, setContrGestao, selectContrGestao, error };
}

export default useContratoGestao;