import { PDFViewer } from '@react-pdf/renderer'
import DocumentPage from '../../../components/PDFComponents/DocumentPage';
import { useEffect, useState } from 'react';
import { formatCPF, formatarData, formatarReais } from '../../../utils/formatDados';
import { useParams } from 'react-router-dom';
import useFornecedor from '../../../actions/cruds/useFornecedor';

const FornecedorFichaPDF: React.FC = () => {
  const { supplierId } = useParams();
  const { fornecedor, getFornecedor } = useFornecedor();

  const [fornecedorData, setFornecedorData] = useState<Array<Object>>([]);

  useEffect(() => {
    getFornecedor(supplierId);
  }, [])

  useEffect(() => {
    if (fornecedor?.id) {
      if (fornecedor?.cpf_cnpj?.length == 11) {
        const updatedFornecedorCPF = [
          {
            id: "rg",
            row: "RG:",
            value: fornecedor?.rg,
          },
        ];

        if (fornecedor?.supplier_contacts?.length > 0 && fornecedor?.supplier_contacts[0]?.id) {
          fornecedor?.supplier_contacts?.forEach((contato, index) => {
            if (index == 0) {
              updatedFornecedorCPF.push(
                {
                  id: "contatos",
                  row: "",
                  value: "Contatos",
                },
              )
            }
            updatedFornecedorCPF.push(
              {
                id: "tipoContato",
                row: `Contato ${index + 1}:`,
                value: `${contato?.tipo} : ${contato?.contato}`
              },
            );
          });
        };

        updatedFornecedorCPF.push(
          {
            id: "endereco",
            row: "Endereço:",
            value: `${fornecedor?.logradouro}, nº ${fornecedor?.numero} - ${fornecedor?.complemento}\n${fornecedor?.bairro}, ${fornecedor?.municipio} / ${fornecedor?.uf}\nCEP: ${fornecedor?.cep}`
          },
          {
            id: "dadosBancarios",
            row: "Dados Bancários:",
            value: `Conta ${fornecedor?.banco_tipo_conta}\n${fornecedor?.banco_nome}\nAgência ${fornecedor?.banco_agencia}, Número ${fornecedor?.banco_conta}`
          },
          {
            id: "atividadeCnae",
            row: "Atividade CNAE:",
            value: fornecedor?.cnae_activity?.nome ?? "-"
          },
        );

        if (fornecedor?.supplier_certificates?.length > 0 && fornecedor?.supplier_certificates[0]?.id) {
          fornecedor?.supplier_certificates?.forEach((certidoes, index) => {
            if (index == 0) {
              updatedFornecedorCPF.push(
                {
                  id: "certidoes",
                  row: "",
                  value: "Certidões",
                },
              )
            }
            updatedFornecedorCPF.push(
              {
                id: "certidao",
                row: `Certidão ${index + 1}:`,
                value: certidoes?.tipo == 'OUTROS' ? `${certidoes?.tipo}: ${certidoes?.descricao}\nVencimento ${formatarData(certidoes?.data_vencimento, false)}` : `${certidoes?.tipo}\nVencimento ${formatarData(certidoes?.data_vencimento, false)}`,
              },
            );
          });
        };
        setFornecedorData(updatedFornecedorCPF);
      };

      if (fornecedor?.cpf_cnpj?.length > 11) {
        console.log(fornecedor?.cpf_cnpj.length)
        const updatedFornecedorCNPJ = [
          {
            id: "inscricao",
            row: "Inscrição estadual:",
            value: fornecedor?.inscricao_estadual,
          },
          {
            id: "nomeFantasia",
            row: "Nome fantasia:",
            value: fornecedor?.nome_fantasia,
          },
          {
            id: "nomeFantasia",
            row: "Trata-se de um consórcio?:",
            value: `[ ${fornecedor?.consorcio ? "X" : "  "} ] Sim           [ ${fornecedor?.consorcio ? "  " : "X"} ] Não`,
          },
        ];

        if (fornecedor?.supplier_contacts?.length > 0 && fornecedor?.supplier_contacts[0]?.id) {
          fornecedor?.supplier_contacts?.forEach((contato, index) => {
            if (index == 0) {
              updatedFornecedorCNPJ.push(
                {
                  id: "contatos",
                  row: "",
                  value: "Contatos",
                },
              )
            }
            updatedFornecedorCNPJ.push(
              {
                id: "tipoContato",
                row: `Contato ${index + 1}: `,
                value: `${contato?.tipo} : ${contato?.contato}`
              },
            );
          });
        };

        updatedFornecedorCNPJ.push(
          {
            id: "endereco",
            row: "Endereço:",
            value: `${fornecedor?.logradouro}, nº ${fornecedor?.numero} - ${fornecedor?.complemento}\n${fornecedor?.bairro}, ${fornecedor?.municipio} / ${fornecedor?.uf}\nCEP: ${fornecedor?.cep}`
          },
        );

        if (fornecedor?.consorcio) {

          if (fornecedor?.consorcio_parceiro?.length > 0 && fornecedor?.consorcio_parceiro[0]?.id) {
            fornecedor?.consorcio_parceiro?.forEach((cons, index) => {
              if (index == 0) {
                updatedFornecedorCNPJ.push(
                  {
                    id: "consorcios",
                    row: "",
                    value: "Consórcios",
                  },
                )
              }
              updatedFornecedorCNPJ.push(
                {
                  id: "consorcio",
                  row: `Consórcio ${index + 1}: `,
                  value: `${formatCPF(cons?.cpf_cnpj)} - ${cons?.nome_razao_social} `
                },
              );
            });
          };

        } else if (!fornecedor?.consorcio) {
          updatedFornecedorCNPJ.push(
            {
              id: "dadosBancarios",
              row: "Dados Bancários:",
              value: `Conta ${fornecedor?.banco_tipo_conta}\n${fornecedor?.banco_nome}\nAgência ${fornecedor?.banco_agencia}, Número ${fornecedor?.banco_conta}`
            },
            {
              id: "atividadeCnae",
              row: "Atividade CNAE:",
              value: fornecedor?.cnae_activity?.nome ?? "-"
            },
          )
        };

        if (fornecedor?.supplier_representatives?.length > 0 && fornecedor?.supplier_representatives[0]?.id) {
          fornecedor?.supplier_representatives?.forEach((rep, index) => {
            if (index == 0) {
              updatedFornecedorCNPJ.push(
                {
                  id: "representantes",
                  row: "",
                  value: "Representantes Legais",
                },
              )
            }
            updatedFornecedorCNPJ.push(
              {
                id: "representante",
                row: `Representante ${index + 1}: `,
                value: `${formatCPF(rep?.cpf)} - ${rep?.nome} `
              },
            );
          });
        };

        if (fornecedor?.supplier_certificates?.length > 0 && fornecedor?.supplier_certificates[0]?.id) {

          fornecedor?.supplier_certificates?.forEach((certidoes, index) => {
            if (index == 0) {
              updatedFornecedorCNPJ.push(
                {
                  id: "certidoes",
                  row: "",
                  value: "Certidões",
                },
              )
            }
            updatedFornecedorCNPJ.push(
              {
                id: "certidao",
                row: `Certidão ${index + 1}:`,
                value: certidoes?.tipo == 'OUTROS' ? `${certidoes?.tipo}: ${certidoes?.descricao}\nVencimento ${formatarData(certidoes?.data_vencimento, false)}` : `${certidoes?.tipo}\nVencimento ${formatarData(certidoes?.data_vencimento, false)}`,
              },
            );
          });
        }

        setFornecedorData(updatedFornecedorCNPJ);
      };
    };
  }, [fornecedor]);

  if (fornecedor?.id) {
    const pdfData = {
      version: "Formulário eletrônico - SIGA Águas",
      title: "FICHA DE FORNECEDOR",
      items: [
        {
          id: "agevapImg",
          logoWithColumns: true,
          itemsColumn: [
            {
              id: "fornecedorNumero",
              row: "CPF/CNPJ",
              value: formatCPF(fornecedor?.cpf_cnpj) ?? "-"
            },
            {
              id: "processoNumero",
              row: "NOME/RAZÃO SOCIAL",
              value: fornecedor?.nome_razao_social ?? "-"
            },
          ]
        },
      ],
    };

    if (fornecedorData?.length > 0) {
      fornecedorData.forEach((item) => pdfData.items.push(item))
    };

    return (
      <PDFViewer width="100%" height="1300" className="app" >
        <DocumentPage data={pdfData} />
      </PDFViewer>
    );
  };
};

export default FornecedorFichaPDF;