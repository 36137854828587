import {
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import {
  ContainerButton, Form, FormContainer, Input, TextTitle, Title
} from '../../../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import useOrgaoGestor from "../../../../../actions/cruds/useOrgaoGestor";
import Panel from "../../../../../components/Panel";
import { Container} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';


interface OrgaoGestor{ 
  codigo: string;
  nome: string; 
  ativo: string;
}

export function RegOrgaoGestor() {

  const formRef = useRef<FormHandles>(null);

  const { createOrgaoGestor} = useOrgaoGestor();

  const handleSubmit = useCallback(
    async(data: OrgaoGestor) => {
      try{
        createOrgaoGestor(data)
      }catch(err) {
        alert(err);
      }
    },
    [],
  );

  return (
    <Container>
      <Title color='#333'>
        <span>
          Cadastrar órgão gestor
        </span>
      </Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Órgão gestor">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Órgão gestor: </TextTitle>
              <Input type="text" name='nome' required/>
            </Grid>
          </Grid>                 
        </Panel>
      </Form>
      <ContainerButton>
        <Link to="/orcamentario/cadastros/orgaosgestores">
          <button>
            <FontAwesomeIcon icon={faX} size="1x"/> Cancelar
          </button>
        </Link>
        <button type="submit" onClick={() =>formRef.current?.submitForm()}>
          <FontAwesomeIcon icon={faSave}/> Salvar
        </button>
      </ContainerButton>
    </Container>
  )
}