import { Container } from "@mui/material";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useCallback, useEffect, useRef } from "react";
import Panel from "../../../../../components/Panel";
import {
  ContainerButton,
  Input,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";
import useSolicitanteExterno from "../../../../../actions/cruds/Protocolo/useSolicitanteExterno";
import { useAuth } from "../../../../../hooks/auth";
import getPermission from "../../../../../utils/getPermission";

const SolicitanteExternoEdit: React.FC = () => {
  const {solicitanteExterno, getSolicitanteExterno, updateSolicitanteExterno} = useSolicitanteExterno();
  const { solicitanteId } = useParams();

  const { login } = useAuth();
  const protocoloPermission = getPermission(login,"Protocolo");
  const isVisitante = protocoloPermission == "Visitante";

  useEffect(() => {
    getSolicitanteExterno(solicitanteId);
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: object) => {
    try {
      console.log(data);
      updateSolicitanteExterno(solicitanteId, data);

    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div>
      {
      isVisitante ? <>Você não tem permissão para acessar esse módulo.</> :

    <Container>
      <Title>
        <span>Editar Solicitante Externo</span>
      </Title>

      <Form id="form1" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Solicitante Externo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Nome:</TextTitle>
              <Input type="text" name="nome" defaultValue={solicitanteExterno.nome} required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Telefone:</TextTitle>
              <Input type="text" name="telefone" mask="phone" defaultValue={solicitanteExterno.telefone} />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Email:</TextTitle>
              <Input type="email" name="email" defaultValue={solicitanteExterno.email} />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Local de referência:</TextTitle>
              <Input type="text" name="local" defaultValue={solicitanteExterno.local} required />
            </Grid>
          </Grid>
        </Panel>
        <ContainerButton>
          <Link to="/protocolo/cadastros/solicitante_externo">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
            </button>
          </Link>
          <button type="submit" form="form1">
            <FontAwesomeIcon icon={faSave} /> Salvar
          </button>
        </ContainerButton>
      </Form>
    </Container>
      }
    </div>

  );
};

export default SolicitanteExternoEdit;
