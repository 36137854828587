import React, { useEffect, useRef } from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ContainerButton,
  Input,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import Panel from "../../../../../components/Panel";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link, useParams } from "react-router-dom";
import useSolicitanteExterno from "../../../../../actions/cruds/Protocolo/useSolicitanteExterno";

const SolicitanteExternoView: React.FC = () => {
  const {solicitanteExterno, getSolicitanteExterno} = useSolicitanteExterno();
  const { solicitanteId } = useParams();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    getSolicitanteExterno(solicitanteId);
  }, []);

  return (
    <Container>
      <Title>
        <span>Visualizar Solicitante Externo</span>
      </Title>

      <Form id="form1" ref={formRef}>
        <Panel titulo="Solicitante Externo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Nome:</TextTitle>
              <Input type="text" name="nome" value={solicitanteExterno.nome} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Telefone:</TextTitle>
              <Input type="text" name="telefone" mask="phone" value={solicitanteExterno.telefone} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Email:</TextTitle>
              <Input type="text" name="email" value={solicitanteExterno.email} disabled />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Local de referência:</TextTitle>
              <Input type="text" name="nome" value={solicitanteExterno.local} disabled />
            </Grid>
          </Grid>
        </Panel>
        <ContainerButton>
          <Link to="/protocolo/cadastros/solicitante_externo">
            <button>Voltar</button>
          </Link>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default SolicitanteExternoView;
