import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useGerencia = () => {
  const history = useHistory();
  const [gerencia, setGerencia] = useState([]);
  const [gerencias, setGerencias] = useState([]);
  const [error, setError] = useState(null);

  async function getGerencias(params?: object) {
    try {
      const response = await api.get(
        `administrations${stringfyParams(params)}`
      );
      setGerencias(response.data);
    } catch (e) {
      setError(e);
    }
  }

  async function getGerencia(id: string) {
    try {
      const response = await api.get(`administrations/${id}`);
      setGerencia(response.data);
      return response.data;
    } catch (e) {
      setError(e);
    }
  }

  async function createGerencia(data: object) {
    try {
      const response = await api.post("administrations", data);
      alert("Gerência cadastrado com sucesso!");
      history.goBack();
    } catch (e) {
      setError(e);
    }
  }

  async function updateGerencia(id: string, data: object) {
    try {
      const response = await api.put(`administrations/${id}`, data);
      alert("Gerência atualizado com sucesso!");
      history.goBack();
      return response;
    } catch (e) {
      alert("Erro: Não foi possível atualizar.");
      setError(e);
      // console.log(error);
    }
  }

  async function DesativarGerencia(id: any, data: any) {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("ativo", data);

    try {
      const response = await api.put(`administrations/${id}`, formData);
      window.location.reload();
      return response.data;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  return {
    gerencias,
    error,
    gerencia,
    getGerencias,
    getGerencia,
    createGerencia,
    updateGerencia,
    DesativarGerencia,
  };
};

export default useGerencia;
