import { FormHandles } from "@unform/core";

import {
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  useCallback,
  useRef
} from "react";

import Header from "../../../../components/Header";

import { CSidebar } from "../../../../components/Sidebar";

import {
  ContainerButton,
  Form,
  FormContainer,
  Input,
  TextTitle,
  Title
} from "../../../../styles/global";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import Panel from "../../../../components/Panel";

import useCnae from "../../../../actions/cruds/useCnae";
import { Link } from "react-router-dom";

interface CreateCNAEFormData {
  codigo: string;
  nome: string;
}

export function RegCNAE() {

  const { createCnaes } = useCnae();

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: CreateCNAEFormData) => {
      try {
        createCnaes(data);
      } catch (err) {
        console.log(err);
      }
    },
    [],
  );

  return (
    <Container>
      <Title color='#333'>
        <span>
          Cadastrar atividade CNAE
        </span>
      </Title>
      <Form id="regCnae" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Atividade CNAE">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6} >
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input type="text" name="codigo" />
            </Grid>
            <Grid xs={12} lg={6} >
              <TextTitle>Descrição: <span>*</span></TextTitle>
              <Input type="text" name="nome" placeholder="Outros" />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/fornecedores/cnae">
          <button>
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <button type="submit" form="regCnae"><FontAwesomeIcon icon={faSave} /> Salvar</button>
      </ContainerButton>
    </Container>
  )
}