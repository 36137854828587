import React from 'react';
import { useTransition } from 'react-spring';
import { ToastMessage } from '../../hooks/toast';
import Toast from './Toast';
import { Container } from './styles';

interface ToastContainerProps {
  messages: ToastMessage[];
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  // const messageWithTransitions = useTransition(
  //   messages,
  //   (message) => message.id,
  //   {
  //     from: { right: '-120%' },
  //     enter: { right: '0' },
  //     leave: { right: '-120%' },
  //   },
  // );

  // return (
  //   <Container>
  //     {messageWithTransitions.map(({ item, key, props }) => (
  //       <Toast key={key} style={props} message={item} />
  //     ))}
  //   </Container>
  // );

  return (
    <Container>
      {messages.map((message) => (
        <Toast key={message.id} message={message} />
      ))}
    </Container>
  );
};

export default ToastContainer;