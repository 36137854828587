import styled from 'styled-components';
import { ButtonAdd } from '../../styles/global';

export const Container = styled.div`
  height: 100%;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 60px 20px #00000014;
  opacity: 1;
  background: ${props => props.color};
`;

export const ButtonAddSide = styled(ButtonAdd)`
  margin: 28px 0px;
`;
