import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";
import { useToast } from "../../../hooks/toast";

const useInvoice = () => {

    const history = useHistory();
    const { addToast } = useToast();
    const [invoice, setInvoice] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [error, setError] = useState(null);

    async function getInvoices(params?: object) {
        try {
            const response = await api.get(`invoices${stringfyParams(params)}`);
            setInvoices(response.data);
        } catch (e) {
            setError(e);
        }
    }

    async function getInvoice(id: string) {
        try {
            const response = await api.get(`invoices/${id}`);
            setInvoice(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    }

    async function createInvoice(data: object) {
        try {
            const response = await api.post('invoices', data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            addToast({
                type: 'success',
                title: 'Nota Fiscal do Ateste cadastrada!',
                description: 'Nota Fiscal do Ateste cadastrada com sucesso!',
            });
            return response;

        } catch (e) {
            setError(e);
        }
    }

    async function deleteInvoice(id: string) {
        try {
            const response = await api.delete(`invoices/${id}`);
            addToast({
                type: 'success',
                title: 'Nota Fiscal do Ateste deletada!',
                description: 'Nota Fiscal do Ateste deletada com sucesso!',
            });            
            return response
        } catch (e) {
            setError(e);
        }
    }


    return { invoice, invoices, getInvoices, getInvoice, createInvoice, deleteInvoice, error };
}

export default useInvoice;