import { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useHistory } from 'react-router-dom';

import { Logo } from '../../Logo';
import { Button } from '../../components/Button/Button';
import { TextInput } from '../../components/Input/Input';
import { Text } from '../../components/Text/Text';
import { Card, FormDiv, FormatedTextInput, LogoDiv, TextTitle, TitleDiv } from './styles';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Input } from '../../styles/global';


const NovaSenha: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { token } = useParams();
    const {addToast} = useToast();
    const history = useHistory();

    interface NovaSenhaFormData {
        password: string;
        confirmPassword: string;
    }

    const handleSubmit = useCallback(
        async (data: NovaSenhaFormData) => {
            if(data.password != data.confirmPassword) {
                addToast({
                    type: 'error',
                    title: 'Senhas diferentes',
                    description: 'As senhas estão diferentes, por favor confirme novamente!',
                });
                return;
            }

            console.log(data);
            
          try {
            const response = await api.put(`/logins/change_password/${token}`, {
                "password": data.password
            })    

            addToast({
                type: 'success',
                title: 'Sua senha foi alterada',
                description: 'Sua senha foi alterada. Faça o Login com a sua nova senha!',
            });

            history.push("/");

          } catch (err) {    
            addToast({
                type: 'error',
                title: 'Ocorreu algum erro.',
                description: 'Ocorreu algum erro ao se comunicar com o servidor!',
            });
            console.log(err);
          }
        },
        [],
    );

    
    return(
        <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card>
                <LogoDiv>
                    <Logo />
                </LogoDiv>
                <FormDiv>
                    <TitleDiv>
                        <Text title='Crie sua nova senha!' />
                    </TitleDiv>
                    <Form ref={formRef} onSubmit={handleSubmit} >
                        <label htmlFor='password' >
                            <TextTitle>Nova Senha</TextTitle>
                            <Input
                                type="password"
                                name='password'
                                required
                            /> 
                        </label> 
                        <label htmlFor='confirmPassword' >
                            <TextTitle>Confirmar Senha</TextTitle>
                            <Input
                                type="password"
                                name='confirmPassword'
                                required
                            /> 
                        </label> 
                        <Button type='submit'>Criar Senha</Button>
                    </Form>
                </FormDiv>
            </Card>
        </div>
    )
}

export default NovaSenha;