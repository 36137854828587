import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

const useCertidao = () => {

    const history = useHistory();
    const [certidoes, setcertidoes] = useState([]);
    const [certidaoPorFornecedor, setcertidaoPorFornecedor] = useState([]);
    const [error, setError] = useState(null);

    async function getCertidaoPorFornecedor(id) {
        try {
            const response = await api.get(`supplier_certificates/?supplier_id=${id}`);
            setcertidaoPorFornecedor(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createCertidoes(data) {
        try {
            const response = await api.post('supplier_certificates', data,{headers: {
                "Content-Type": "multipart/form-data",
              },});

            getCertidaoPorFornecedor(response.data.supplier_id);

            alert("Certidão criada!");
            // history.goBack();
            // history.go(0);

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function deleteCertidoes(data) {
        const {id, supplier_id} = data;
        try {
            const response = await api.delete(`supplier_certificates/${id}`);

            getCertidaoPorFornecedor(supplier_id);

            alert("Sucesso! Certidão removida.");
            // return response;
        } catch (e) {
            setError(e);
            alert("Algo deu errado ao remover certidão.");
            // console.log(error);
        }
    }


    return {certidaoPorFornecedor, getCertidaoPorFornecedor, createCertidoes, deleteCertidoes, error};
}

export default useCertidao;