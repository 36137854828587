import {
  faArrowLeft,
  faSave
} from "@fortawesome/free-solid-svg-icons";

import {
  ContainerButton, Form, FormContainer, Input, TextTitle, Title
} from '../../../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import useDespesas from "../../../../../actions/cruds/useDespesas";
import Panel from "../../../../../components/Panel";
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from "@mui/material";


interface CreateDespesaFormData {
  nome: string;
  codigo: string;
}


export function RegDespesa() {

  const formRef = useRef<FormHandles>(null);

  const {createDespesas} = useDespesas();

  

  const handleSubmit = useCallback(
    async(data: CreateDespesaFormData) => {
      try{
        createDespesas(data);
      }catch(err) {
        alert(err);
      }
    },
    [],
  );

  return (
    <Container>
      <Title color='#333'>
        <span>
          Cadastrar despesa
        </span>
      </Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Despesa">
          <Grid container spacing={6}>
            <Grid xs={2}>
              <TextTitle>Código: <span>*</span></TextTitle>
              <Input 
                mask="_"
                replacement="allNumber"
                id="codigo" 
                name='codigo' 
                required
              />
            </Grid>
            <Grid xs={10}>
              <TextTitle>Despesa: <span>*</span></TextTitle>
              <Input
                type="text"
                id="nome" 
                name='nome' 
                required
              /> 
            </Grid>
          </Grid>               
        </Panel>
      </Form>
      <ContainerButton>
        <Link to="/orcamentario/cadastros/despesas">
          <button>
            <FontAwesomeIcon icon={faArrowLeft} size="1x"/> Voltar
          </button>
        </Link>
        <button type="submit" onClick={() => formRef.current?.submitForm()}>
          <FontAwesomeIcon icon={faSave}/> Salvar
        </button>
      </ContainerButton>
    </Container>
  )
}