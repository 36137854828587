import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import api from "../../../../services/api";
import useUsuario from "../../../../actions/cruds/useUsuario";
import { EditEmpregado } from "./Empregado";
import { EditEstagiario } from "./Estagiario";
import { EditJovemAprendiz } from "./JovemAprendiz";
import { EditTerceirizado } from "./Terceirizado";

const funcionarios_id = {
  EMPREGADO: 1,
  ESTAGIARIO: 2,
  JOVEM_APRENDIZ: 3,
  TERCEIRIZADO: 4,
};

const Edit: React.FC = () => {
  const { empregadoId } = useParams();
  const { getUser, usuario } = useUsuario();

  useEffect(() => {
    getUser(empregadoId);
  }, []);

  useEffect(() => {
    console.log("id userrr", usuario);
  }, [usuario]);

  if (usuario.id) {
    if (usuario.employee_type.id === funcionarios_id.EMPREGADO) {
      return <EditEmpregado />;
    }
    if (usuario.employee_type.id === funcionarios_id.ESTAGIARIO) {
      return <EditEstagiario />;
    }
    if (usuario.employee_type.id === funcionarios_id.JOVEM_APRENDIZ) {
      return <EditJovemAprendiz />;
    }
    if (usuario.employee_type.id === funcionarios_id.TERCEIRIZADO) {
      return <EditTerceirizado />;
    }
  }
};

export default Edit;
