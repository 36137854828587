import styled from 'styled-components';


export const Overlay = styled.div`
  background-color: #ccc;
  opacity: 0.6;
  position: fixed;
  z-index: 999;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16,1,0.3,1);
`;

export const Content = styled.div`
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 90vw;
  max-height: 100%;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1000;
  overflow-y: auto;
  
  &focus{
    outline: none;
  }

  footer{ 
    display: flex;
    justify-content: end;
    align-items: flex-end;
    .red{ 
      background-color: #505050; 
    }
    .blue{ 
      background-color: #0E62C4; 
      margin-right: 1rem;
    }
  }
`;

export const Title = styled.div`
  padding: 20px 0 20px 0;
  margin: 0;
  font-weight: 500;
  color: black;
  font-size: 1.5rem;
`;

export const Description = styled.div`
  padding: 20px 0 20px 0;
  margin: 10px 0 20px;
  color: black;
  font-size: 15px;
  line-height: 1.5;
`;

export const Button = styled.button`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center; 
  line-height: 1;
  height: 35px;

  padding: 1.4rem; 
  color: white;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 16px; 
  background-color: red;
 
`;

export const IconButton = styled.button`
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  position: absolute;
  top: 10px;
  right: 10px;

  :hover{
    background-color: #ccc;
  }
  :focus{
    background-color: #ccc;
  }
`;
