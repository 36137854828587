import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 11,
        padding: '40 55 30 55',
        lineHeight: 1.5,
        display: 'flex',
        flexDirection: 'column',
    },
    // logo: {
    //     width: 74,
    //     height: 66,
    //     marginLeft: 'auto',
    //     marginRight: 'auto'
    // }
});