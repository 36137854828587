import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import { Title } from "../../styles/global";
import MainTable from "../../components/MainTable";
import { formatarData } from "../../utils/formatDados";
import useProtocolo from "../../actions/cruds/Protocolo/useProtocolo";
import ModalCodigoNup from "../../components/CodigoNupModal";
import { useAuth } from "../../hooks/auth";
import getPermission from "../../utils/getPermission";

const Protocolo: React.FC = () => {
  const { protocolos, getProtocolos } = useProtocolo();

  const [searchValue, setSearchValue] = useState<string>("");
  const [showModalCodigoNup, setShowModalCodigoNup] = useState(false);
  const [numeroProtocolo, setNumeroProtocolo] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>()

  const optionsSituacao = [
    { label: "Não iniciado", value: "nao_iniciado", id: "001" },
    { label: "Em andamento", value: "em_andamento", id: "002" },
    { label: "Concluído", value: "concluido", id: "003" },
  ];

  const {login} = useAuth();
  const protocoloPermission = getPermission(login,"Protocolo") ?? '';

  console.log(protocoloPermission == '');
  
  

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      getProtocolos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }]);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getProtocolos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getProtocolos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }, { label: "page", value: protocolos.pagination.current_page + page }]);
  };

  const organizeData = (data: any[]) => {
    const withoutData = <i>Sem dados</i>;

    if (typeof data === "object") {
      data = data.map((item) => {
        return {
          id: item.id,
          codigo: item.codigo ?? withoutData,
          demanda: item.demanda ?? withoutData,
          solicitante: item?.local == 'interno' ? (item?.representative?.id ? item?.representative?.nome : item?.solicitante?.id ? item?.solicitante?.nome : withoutData) : 'externo' ? item?.external_requester?.nome : withoutData,
          responsavel: item.user.nome ?? withoutData,
          data_entrada: formatarData(item.data_entrada, false) ?? withoutData,
          data_estimativa: formatarData(item.data_previsao_conclusao, false) ?? withoutData,
          situacao: item.situacao ? optionsSituacao.find(o => o.value == item?.situacao).label : withoutData,
          codigoNup: item.codigo_nup ?? withoutData,
          ativo: item.ativo,
        };
      });
    }
    return data;
  };

  const columnData = [
    { headerName: "Código", field: "codigo" },
    { headerName: "Demanda", field: "demanda" },
    { headerName: "Solicitante", field: "solicitante" },
    { headerName: "Responsável", field: "responsavel" },
    { headerName: "Data Entrada", field: "data_entrada" },
    { headerName: "Data Estimativa Conclusão", field: "data_estimativa" },
    { headerName: "Situação", field: "situacao" },
    { headerName: "Status", field: "ativo" },
  ];

  return (
    <>
      <Title color="#333">
        <span>Gestão de Protocolo</span>
      </Title>
      <Container color="#FFF">
        <ModalCodigoNup
          itemId={numeroProtocolo}
          route={"demands"}
          isOpen={showModalCodigoNup}
          handleClose={() => setShowModalCodigoNup(false)}
        />

        <MainTable
          columns={columnData}
          data={organizeData(protocolos.data)}
          pagination={protocolos.pagination}
          permission={protocoloPermission}
          isMaxWidthCell
          handleItemsPerPage={handleItemsPerPage}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          registerLink="/protocolo/cadastrar"
          dropdown={{
            view: { linkTo: "/protocolo/visualizar" },
            editar: { linkTo: "/protocolo/editar" },
            comprovante: { linkTo: "/protocolo/comprovante" },
            desativar: { linkTo: "demands" },
            codigoNup: {
              openModal: () => setShowModalCodigoNup(true),
              updateId: setNumeroProtocolo,
            },
          }}
        />
      </Container>
    </>
  );
};

export default Protocolo;
