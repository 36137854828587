import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const usePapAcao = () => {
  const history = useHistory();

  const { addToast } = useToast();
  
  const [papAcoes, setPapAcoes] = useState([]);
  const [papAcao, setPapAcao] = useState({});
  const [newPapAcao, setNewPapAcao] = useState({});

  async function getPapAcoes(params?: object) {
    try {
      const response = await api.get(`pap_actions${stringfyParams(params)}`);
      setPapAcoes(response.data);
    } catch (e) {
      alert("Erro ao retornar Finalidades PAP");
    }
  }

  async function getPapAcao(id) {
    try {
      const response = await api.get(`pap_actions/${id}`);
      setPapAcao(response.data);
    } catch (e) {
      alert("Erro ao retornar Finalidade PAP");
    }
  }

  async function createPapAcao(data) {
    try {
        const response = await api.post('pap_actions', data);

        setNewPapAcao( response.data );
    } catch (e) {
        // alert("Erro ao cadastrar Programa PAP");
    }
  }

  async function updatePapAcao(id: number, data: object) {
    try {
      const response = await api.put(`pap_actions/${id}`, data);
      
      addToast({
        type: 'success',
        title: 'Ação alterada',
        description: 'Alteração da Ação foi realizada com sucesso!',
      });
    } catch (e) {}
  }

  return {
    papAcoes,
    papAcao,
    getPapAcoes,
    getPapAcao,
    setPapAcao,
    createPapAcao,
    newPapAcao,
    updatePapAcao
  };
};

export default usePapAcao;
