import { useEffect, useState } from "react";
import { Container } from "./style";
import MainTable from "../../components/MainTable";
import { Title } from "../../styles/global";
import useInstrumento from "../../actions/cruds/InstrumentoContratual/useInstrumento";
import { formatarData } from "../../utils/formatDados";
import ModalInstrumento from "../../components/ModalInstrumentos";
import { useAuth } from "../../hooks/auth";
import getPermission from "../../utils/getPermission";

const Instrumentos: React.FC = () => {
  const { instrumentos, getInstrumentos } = useInstrumento();

  const [searchValue, setSearchValue] = useState<string>("");
  const [perPage, setPerPage] = useState<number>()
  const [showModalInstrumento, setShowModalInstrumento] = useState(false);
  const [numeroInstrumento, setNumeroInstrumento] = useState<number>(0);
  const [tipoInstrumento, setTipoInstrumento] = useState<String>('');

  const {login} = useAuth();
  const instrumentosPermission = getPermission(login,"Instrumentos") ?? '';

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      getInstrumentos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }]);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getInstrumentos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getInstrumentos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }, { label: "page", value: instrumentos.pagination.current_page + page }]);
  };

  const organizeData = (data: any) => {
    const withoutData = <i>Sem dados</i>;

    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        tipo: d.tipo_instrumento ?? withoutData,
        numero: d.numero ?? withoutData,
        gestor: d.gestor?.nome ?? withoutData,
        cpf_cnpj_fornecedor: d.supplier?.cpf_cnpj ?? withoutData,
        nome_fornecedor: d.supplier?.nome_razao_social ?? withoutData,
        termino: formatarData(d.data_termino_vigencia, false) ?? withoutData,
        ativo: d.ativo
      }));
    };
    return data;
  };

  const columnData = [
    { headerName: "ID", field: "id" },
    { headerName: "Tipo de Instrumento", field: "tipo" },
    { headerName: "Nº do Instrumento", field: "numero" },
    { headerName: "Gestor do Instrumento", field: "gestor" },
    { headerName: "CPF/CNPJ do fornecedor", field: "cpf_cnpj_fornecedor" },
    { headerName: "Nome/Razão Social do fornecedor", field: "nome_fornecedor" },
    { headerName: "Término da vigência", field: "termino" },
    { headerName: "Status", field: "ativo" },
  ];

  return (
    <>
      <Title color="#333">
        <span>Gestão de Instrumentos contratuais</span>
      </Title>
      <Container color="#FFF">
        <ModalInstrumento
          instrumentoId={numeroInstrumento}
          isOpen={showModalInstrumento}
          type={tipoInstrumento}
          handleClose={() => setShowModalInstrumento(false)}
        />

        <MainTable
          columns={columnData}
          data={organizeData(instrumentos.data)}
          pagination={instrumentos.pagination}
          permission={instrumentosPermission}
          isMaxWidthCell
          handleItemsPerPage={handleItemsPerPage}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          registerLink="/instrumentos/cadastrar"
          dropdown={{
            viewInstrumento: {
              openModal: () => setShowModalInstrumento(true),
              updateId: setNumeroInstrumento,
              tipo: () => setTipoInstrumento('visualizar'),
            },
            editarInstrumento: {
              openModal: () => setShowModalInstrumento(true),
              updateId: setNumeroInstrumento,
              tipo: () => setTipoInstrumento('editar'),
            },
            aditivo: { linkTo: "/instrumentos/aditivo/cadastrar" },
            apostilamento: { linkTo: "/instrumentos/apostilamento/cadastrar" },
            desativar: { linkTo: "contractual_instruments" },
          }}
        />
      </Container>
    </>
  );
};

export default Instrumentos;