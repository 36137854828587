import { faChevronLeft, faCloudArrowUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useUsuario from '../../../../actions/cruds/useUsuario';
import useDocumentosUsuario from '../../../../actions/cruds/useDocumentosUsuarios';
import InputFile from '../../../../components/InputFile';
import Radio from '../../../../components/InputRadio';
import Panel from '../../../../components/Panel';
import SecondaryTable from '../../../../components/SecondaryTable';
import { ButtonAdd, ButtonUploadArch, ContainerButton, Form, Input, TextTitle, Title } from '../../../../styles/global';
import { Button, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const DocumentosEmpregados: React.FC = () => {

  const { empregadoId } = useParams();

  const [possuiValidade, setPossuiValidade] = useState(false);


  const { documentoUsuario, getDocumentoUsuario, createDocumentoUsuario, deleteDocumentoUsuario } = useDocumentosUsuario();
  const { usuario, getUser } = useUsuario();

  useEffect(() => {
    getUser(empregadoId);
    getDocumentoUsuario([{ label: 'user_id', value: empregadoId }]);
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {

        const documentFile = formRef.current?.getFieldValue("nome_arquivo");
        const nomeFile = formRef.current?.getFieldValue("nome");

        if (!documentFile) {
          alert("Selecione o arquivo do documento!")
          return;
        }
        if (!nomeFile) {
          data["nome"] = documentFile.name
        };

        data["user_id"] = empregadoId;
        console.log(data);
        createDocumentoUsuario(data);

        // addToast({
        //   type: 'success',
        //   title: 'Login efetuado',
        //   description: 'Seu login foi feito com sucesso!',
        // });

        // history.push('/dashboard');
      } catch (err) {
        // addToast({
        //   type: 'error',
        //   title: 'Erro na autenticação',
        //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        // });
      }
    },
    [],
  );

  const dataTitleDocumentos: GridColDef[] = [
    { field: "nome", headerName: "Documento", flex: 3 },
    { field: "data_validade", headerName: "Validade", flex: 2 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.arquivo && <Link to={{ pathname: params.row.arquivo }} target="_blank">
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
              </Button>
            </Link>}
            <Button variant="text" onClick={(e) => onDeleteClick(e, params.row)}>
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      }
    }
  ];

  function onDeleteClick(e: MouseEvent<HTMLButtonElement, MouseEvent>, row: any): void {
    row['user_id'] = empregadoId;
    deleteDocumentoUsuario(row);
  }

  const handlerDocument = () => {
    // const docFile = formRef.current?.getFieldValue('document');
    // console.log(docFile);
    // setDocuments(
    //   [
    //     ...documents,
    //     {
    //       "document_name": formRef.current.getFieldValue('document_name'),
    //     }
    //   ])
  };

  const organizeData = (data) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        // user_id: d.user.id,
        nome: d.nome,
        possui_validade: d.possui_validade,
        data_validade: d.data_validade ? d.data_validade : "Sem validade",
        arquivo: d.nome_arquivo ? `${d.url_arquivo}/${d.nome_arquivo}` : null
      }));
    }
    return data;
  }

  const changeValidade = () => {
    console.log(formRef.current.getFieldValue('possui_validade'));
    setPossuiValidade(JSON.parse(formRef.current.getFieldValue('possui_validade')));
  }

  return (
    <Container>
      <Title color='#333'>
        <span>
          Cadastro de documentos
        </span>
      </Title>
      <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
        <Panel titulo={`Documentos do empregado: ${usuario.nome}`}>
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>Documento: <span>*</span></TextTitle>
              <Input type="text" name='nome' required />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle style={{ marginLeft: '35px' }}>O documento possui validade?:</TextTitle>
              <Radio
                style={{ marginTop: '35px', marginLeft: '35px' }}
                name='possui_validade'
                onChange={e => changeValidade()}
                options={[
                  { id: "1", value: "true", label: " Sim", required: true },
                  { id: "2", value: "false", label: " Não", required: true }
                ]}
              />
            </Grid>
            <Grid xs={12} lg={6}>
              {possuiValidade &&
                <>
                  <TextTitle>Data de validade:</TextTitle>
                  <Input type="date" name='data_validade' />
                </>
              }
            </Grid>
            <Grid xs={6}>
              <ButtonUploadArch for="nome_arquivo" id='selecionararquivo' name='selecionararquivo' type="button">
                <FontAwesomeIcon icon={faCloudArrowUp} size="xl" /> Selecione o arquivo ...
              </ButtonUploadArch>
              <InputFile id="nome_arquivo" name="nome_arquivo" style={{ display: 'none' }} />
            </Grid>
            <Grid xs={12}>
              <ButtonAdd type="submit" /*onClick={handlerDocument}*/><FontAwesomeIcon icon={faPlus} /> Adicionar</ButtonAdd>
            </Grid>
            <Grid xs={12}>
              <Box sx={{ overflow: "auto" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                  {documentoUsuario.data ? documentoUsuario.data.length > 0 &&
                    <SecondaryTable
                      columsData={dataTitleDocumentos}
                      rowsData={organizeData(documentoUsuario.data)}
                    />
                    : null}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/rh/empregados">
          <button><FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar</button>
        </Link>
      </ContainerButton>
    </Container >
  );
};

export default DocumentosEmpregados;