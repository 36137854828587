import styled from "styled-components";

export const Container = styled.div`
  .dropdown_content {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .dropdown_item {
    padding: 16px 30px 16px 16px;
    > span {
      margin-top: 50px;
    }
  }

  .dropdown_link {
    text-decoration: none;
    color: #111111;
  }

  button {
    border: 0;
    background-color: #ffffff;
  }

  .button_trigger {
    border: 1px solid #cccccc;
    background-color: #ffffff;

    border-radius: 8px;
    padding: 8px;
    width: 100px;

    span {
      margin-right: 8px;
      font-size: 15px;
    }
  }
`;
