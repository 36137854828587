import { Page, Document } from '@react-pdf/renderer';
import ItensTable from '../ItensTable'
import { styles } from './styles';

interface DocumentPageProps {
  data: any
};

const DocumentPage: React.FC<DocumentPageProps> = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <ItensTable data={data} />
    </Page>
  </Document>
);

export default DocumentPage;