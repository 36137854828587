import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useCargo = () => {
  const history = useHistory();
  const [cargos, setCargos] = useState([]);
  const [cargo, setCargo] = useState({
    employee_type: [],
  });
  const [error, setError] = useState(null);

  async function getCargos(params) {
    try {
      const response = await api.get(`positions${stringfyParams(params)}`);
      setCargos(response.data);
      return response.data;
    } catch (e) {
      setError(e);
    }
  }

  async function getCargo(id: string) {
    try {
      const response = await api.get(`positions/${id}`);
      setCargo(response.data);
      return response.data;
    } catch (e) {
      setError(e);
    }
  }

  async function createCargo(data: object) {
    try {
      const response = await api.post("positions", data);
      alert("Cargo cadastrado com sucesso!");
      history.goBack();
    } catch (e) {
      alert("Erro: Não foi possível cadastrar o cargo!");
      setError(e);
    }
  }

  async function updateCargo(id: string, data: object) {
    try {
      const response = await api.put(`positions/${id}`, data);
      alert("Cargo editado com sucesso!");
      history.goBack();
      return response;
    } catch (e) {
      alert("Erro: Não foi possível editar o cargo!");
      setError(e);
      // console.log(error);
    }
  }

  async function DesativarCargo(id: any, data: any) {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("ativo", data);

    try {
      const response = await api.put(`positions/${id}`, formData);
      window.location.reload();
      return response.data;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  return {
    cargos,
    cargo,
    error,
    getCargos,
    getCargo,
    createCargo,
    updateCargo,
    DesativarCargo,
  };
};

export default useCargo;
