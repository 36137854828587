import { Container } from "@mui/material";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useCallback, useEffect, useRef } from "react";
import Panel from "../../../../../components/Panel";
import {
  ContainerButton,
  Input,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";
import useProcessoTipos from "../../../../../actions/cruds/Processos/useProcessoTipos";

const TipoProcessoEdit: React.FC = () => { 
  const { tipoProcessoId } = useParams();
  const {tipoProcesso, getTipoProcesso, updateTipoProcesso} = useProcessoTipos();

  useEffect(() => {
    getTipoProcesso(tipoProcessoId);
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: object) => {
    try {
      console.log("data", data);
      updateTipoProcesso(tipoProcessoId, data);

    } catch (err) {
      console.log(err);      
    }
  }, []);

  return (
    <Container>
      <Title>
        <span>Editar Tipo do processo</span>
      </Title>

      <Form id="form1" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Tipo do processo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Nome:</TextTitle>
              <Input type="text" name="nome" defaultValue={tipoProcesso.nome} />
            </Grid>
          </Grid>
        </Panel>

        <ContainerButton>
          <Link to="/processos/cadastros/tipoprocesso">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
            </button>
          </Link>
          <button type="submit" form="form1">
            <FontAwesomeIcon icon={faSave} /> Salvar
          </button>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default TipoProcessoEdit;
