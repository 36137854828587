import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";

import { Container } from "./styles";
import { TextTitle } from "../../styles/global";
import api from "../../services/api";
import { InputMask } from "@react-input/mask";

interface IProcessoModalProps {
  processoId: number;
  isOpen: boolean;
  handleClose: () => void;
}

const ProcessoModal: React.FC<IProcessoModalProps> = ({
  processoId,
  isOpen,
  handleClose,
}) => {
  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [processo, setProcesso] = useState([]);
  const [contratosGestaoUpdate, setContratosGestaoUpdate] = useState<Array<Number>>([]);

  useEffect(() => {
    if (processoId > 0)
      api
        .get(`law_processes/${processoId}`)
        .then((response) => {
          if (response.data.numero) {
            setNumeroProcesso(response.data.numero);
          } else if(!response.data.numero) {
            setNumeroProcesso("");
          }
          setProcesso(response.data)
        })
        .catch((error) => console.log(error));
  }, [processoId]);

  useEffect(() => {
    if (processo?.management_contracts) {
      let contratosGestao: number[] = [];
      processo?.management_contracts.forEach((contrato) => contratosGestao.push(contrato.id));
      setContratosGestaoUpdate(contratosGestao);
    } else{
      setContratosGestaoUpdate([]);
    };
  }, [processo])

  const handleSubmit = () => {
    api
      .put(`law_processes/${processoId}`, { numero: numeroProcesso, "management_contract_ids": contratosGestaoUpdate })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        window.alert("Erro ao cadastrar numero do processo.");
        console.log(error);
      });
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Container>
        <div className="modal">
          <div className="header">
            <h2>Número do Processo</h2>

            <button onClick={handleClose}>X</button>
          </div>

          <div className="content">
            <TextTitle>Número do processo - SIGAD:</TextTitle>
            <InputMask
              name="numero"
              mask="_____.______/____"
              replacement={{ _: /\d/ }}
              defaultValue={numeroProcesso}
              onChange={(e) => setNumeroProcesso(e.target.value)}
            />
          </div>

          <div className="row">
            <button className="button_cancel" onClick={handleClose}>
              Cancelar
            </button>
            <button className="button_save" onClick={handleSubmit}>
              Cadastrar
            </button>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default ProcessoModal;
