import { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useToast } from '../../hooks/toast';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { Card, FormDiv, FormatedTextInput, LogoDiv, TextTitle, TitleDiv } from './styles';
import { Logo } from '../../Logo';
import { Text } from '../../components/Text/Text';
import { Form } from '@unform/web';
import { Input } from '../../styles/global';
import { Button } from '../../components/Button/Button';


const EsqueciSenha: React.FC = () => {
    const history = useHistory();

    const formRef = useRef<FormHandles>(null);

    const {addToast} = useToast();

    interface EsqueciSenhaFormData {
        email: string;
    }

    const handleSubmit = useCallback(
        async (data: EsqueciSenhaFormData) => {
            console.log(data);
          try {
            const response = await api.put('/logins/send_forget_password_email', {
                "email": data.email
            })
            
            addToast({
                type: 'success',
                title: 'Um Email foi mandado para você',
                description: 'Mandamos um Email com um link para seu Email cadastrado!',
            });

            history.push("/");

          } catch (err) {    

          }
        },
        [],
    );

    return <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Card>
        <LogoDiv>
            <Logo />
        </LogoDiv>
        <FormDiv>
            <TitleDiv>
                <Text href='' title='Esqueci minha senha!' />
            </TitleDiv>
            <Form ref={formRef} onSubmit={handleSubmit} >
                <label htmlFor='email' >
                    <TextTitle>EMAIL</TextTitle>
                    <Input
                        type="text"
                        name="email"
                        required
                    />             
                </label>
                <Button type='submit'>Enviar Email</Button>
            </Form>
        </FormDiv>
    </Card>
</div>
}

export default EsqueciSenha;