import { SVGAttributes } from "react";


type LogoProps = SVGAttributes<HTMLOrSVGElement>

export function Logo(props: LogoProps ){
    return(
        <svg
            width={532}
            height={950}
            viewBox="0 0 532 1070"
            fill='none'
            xmlns="http://www.w3.org/2000/svg" 
            {...props}
        >
        <defs>
                <filter id="Caminho_8" x="0" y="0" width="532" height="950" filterUnits="userSpaceOnUse">
                <feOffset dy="30" />
                <feGaussianBlur stdDeviation="40" result="blur"/>
                <feFlood floodOpacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
                </filter>
                <filter id="MyFitnessPal_Logo" x="87" y="379" width="359" height="359" filterUnits="userSpaceOnUse">
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="30" result="blur-2"/>
                <feFlood floodOpacity="0.161"/>
                <feComposite operator="in" in2="blur-2"/>
                <feComposite in="SourceGraphic"/>
                </filter>
                <clipPath id="clip-path">
                <path id="Caminho_509" data-name="Caminho 509" d="M126.88,17.07c-6.606,9.47-7.333,21.357,2.18,28.2s19.348.273,32.936-8.96c11.89-7.884,22.15-15.329,33-16.035C189.444,10.742,178.415,0,159.906,0a43.348,43.348,0,0,0-8.993.918C140.089,4.463,131.671,10.205,126.88,17.07Z" transform="translate(-121.914 0.001)" fill="none" />
                </clipPath>
                <clipPath id="clip-path-2">
                <path id="Caminho_512" data-name="Caminho 512" d="M194.764,170.934c8.835-14.41,11.991-25.7,11.214-35.972-8.816-2.406-17.2,3.747-29.093,11.871-14.876,10.309-30.669,20.349-47.145,11.4a100.7,100.7,0,0,0,6.69,12.7c8.222,13.416,29.159,38.735,29.159,38.735s7.426-8.979,15.171-19.076C180.771,190.593,192.015,175.422,194.764,170.934Z" transform="translate(-129.74 -134.435)" fill="none"/>
                </clipPath>
            </defs>
            <g id="Grupo_162826" data-name="Grupo 162826" transform="translate(-418 -180)">
                <g id="Grupo_162824" data-name="Grupo 162824">
                <g id="Grupo_162825" data-name="Grupo 162825">
                    <g transform="matrix(1, 0, 0, 1, 418, 180)" filter="url(#Caminho_8)">
                    <path id="Caminho_8-2" data-name="Caminho 8" d="M0,25.315,292,0V450.5H0Z" transform="translate(120 90)" fill="#77ccd0" opacity="0.8"/>
                    </g>
                    <path id="Caminho_9" data-name="Caminho 9" d="M0,25.315,292,0V450.5H0Z" transform="translate(830 1170.5) rotate(180)" fill="#77ccd0" opacity="0.8"/>
                    <rect id="Retângulo_22" data-name="Retângulo 22" width="292" height="700" transform="translate(538 370)" fill="#77ccd0" opacity="0.1"/>
                </g>
                </g>
                <g id="Grupo_162823" data-name="Grupo 162823" transform="translate(-2)">
                <g id="Grupo_162818" data-name="Grupo 162818" transform="translate(420)">
                    <g transform="matrix(1, 0, 0, 1, 0, 180)" filter="url(#MyFitnessPal_Logo)">
                    <rect id="MyFitnessPal_Logo-2" data-name="MyFitnessPal_Logo" width="179" height="179" rx="9" transform="translate(177 466)" fill="#00aeef"/>
                    </g>
                    <path id="Caminho_83963" data-name="Caminho 83963" d="M0,0H164V143H0Z" transform="translate(185 666)" fill="#00aeef"/>
                </g>
                <g id="Camada_2" data-name="Camada 2" transform="translate(645 682)">
                    <g id="Camada_1" data-name="Camada 1">
                    <path id="Caminho_505" data-name="Caminho 505" d="M194.764,170.934c8.835-14.41,11.991-25.7,11.214-35.972-8.816-2.406-17.2,3.747-29.093,11.871-14.876,10.309-30.669,20.349-47.145,11.4a100.7,100.7,0,0,0,6.69,12.7c8.222,13.416,29.159,38.735,29.159,38.735s7.426-8.979,15.171-19.076C180.771,190.593,192.015,175.422,194.764,170.934Z" transform="translate(-127.607 -97.8)" fill="#fff"/>
                    <path id="Caminho_506" data-name="Caminho 506" d="M126.88,17.07c-6.606,9.47-7.333,21.357,2.18,28.2s19.348.273,32.936-8.96c11.89-7.884,22.15-15.329,33-16.035C189.444,10.742,178.415,0,159.906,0a43.348,43.348,0,0,0-8.993.918C140.089,4.463,131.671,10.205,126.88,17.07Z" transform="translate(-121.914 0.001)" fill="#fff"/>
                    <g id="Grupo_2515" data-name="Grupo 2515" transform="translate(0)" clipPath="url(#clip-path)">
                        <path id="Caminho_507" data-name="Caminho 507" d="M124.154,84.582c-15.3,3.668-48.014,6.766-48.014,6.766s3.09-32.7,6.764-48.014C87.9,22.547,95.755,11.69,107.073,5.324c8.865-5,30.6-10.65,48.169,6.922s11.92,39.3,6.922,48.169C155.8,71.733,144.941,79.595,124.154,84.582Z" transform="translate(-88.614 -0.228)" fill="#fff" opacity="0.11"/>
                        <path id="Caminho_508" data-name="Caminho 508" d="M38.735,70.235C25.319,62.016,0,41.071,0,41.071S25.319,20.12,38.735,11.912C56.955.731,70.2-1.386,82.7,2.115c9.81,2.725,29.159,14.105,29.159,38.969S92.5,77.3,82.7,80.038C70.2,83.531,56.955,81.408,38.735,70.235Z" transform="translate(-33.223 -0.415)" fill="#fff" opacity="0.05"/>
                    </g>
                    <g id="Grupo_2516" data-name="Grupo 2516" transform="translate(2.133 36.635)" clipPath="url(#clip-path-2)">
                        <path id="Caminho_510" data-name="Caminho 510" d="M124.154,84.582c-15.3,3.668-48.014,6.766-48.014,6.766s3.09-32.7,6.764-48.014C87.9,22.547,95.755,11.69,107.073,5.324c8.865-5,30.6-10.65,48.169,6.922s11.92,39.3,6.922,48.169C155.8,71.733,144.941,79.595,124.154,84.582Z" transform="translate(-90.747 -36.864)" fill="#fff" opacity="0.11"/>
                        <path id="Caminho_511" data-name="Caminho 511" d="M38.735,70.235C25.319,62.016,0,41.071,0,41.071S25.319,20.12,38.735,11.912C56.955.731,70.2-1.386,82.7,2.115c9.81,2.725,29.159,14.105,29.159,38.969S92.5,77.3,82.7,80.038C70.2,83.531,56.955,81.408,38.735,70.235Z" transform="translate(-35.356 -37.05)" fill="#fff" opacity="0.05"/>
                    </g>
                    </g>
                </g>
                </g>
            </g>
        </svg>
    )
}