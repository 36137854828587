import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const usePoaDepara = () => {
  
  const { addToast } = useToast();

  const [poasDepara, setPoasDepara] = useState([]);

  async function getPoasDepara(params?: object) {
    try {
      const response = await api.get(`poa_from_tos${stringfyParams(params)}`);
      setPoasDepara(response.data);
    } catch (e) {}
  }

  async function getPoaDepara(id) {
    try {
      const response = await api.get(`poa_from_tos/${id}`);
    } catch (e) {}
  }

  async function createPoaDepara(data) {
    try {
        const response = await api.post('poa_from_tos', data);
        addToast({
          type: 'success',
          title: 'POA de-para criado!',
          description: 'Novo POA de-para foi cadastrado.',
        });
    } catch (e) {}
  }

  async function deletePoaDepara(id: number) {
    try {
        const response = await api.delete(`poa_from_tos/${id}`);

        addToast({
          type: 'success',
          title: 'POA de-para excluído!',
          description: 'POA de-para foi excluído.',
        });
        // return response;
    } catch (e) {}
  }

  return {
    getPoasDepara,
    poasDepara,
    getPoaDepara,
    createPoaDepara,
    deletePoaDepara
  };
};

export default usePoaDepara;
