import { Text, View } from '@react-pdf/renderer';
import { styles } from './styles';

interface TableHeaderProps {
    version: string,
    title: string,
};

const TableHeader: React.FC<TableHeaderProps> = ({ version, title }) => {
    return (
        <View style={styles.row}>
            <Text style={styles.version}>{version}</Text>
            <Text style={styles.title}>{title}</Text>
        </View>
    )
};

export default TableHeader;