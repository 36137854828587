import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Step, StepLabel, Stepper } from '@mui/material';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ButtonAdd, ContainerButton, ContainerButton2, CreatableSelectInput, Input, SelectInput, TextTitle, Title } from '../../../../../styles/global';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPenToSquare, faPlus, faSave, faX } from '@fortawesome/free-solid-svg-icons';
import useCargo from '../../../../../actions/cruds/useCargo';
import { FormHandles } from '@unform/core';
import organizeDataSelect from '../../../../../utils/organizeDataSelect';
import useUsuario from '../../../../../actions/cruds/useUsuario';
import Panel from '../../../../../components/Panel';
import { steps } from '../..';
import SecondaryTable from '../../../../../components/SecondaryTable';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import usePap from '../../../../../actions/cruds/usePap';
import usePapFinalidade from '../../../../../actions/cruds/usePapFinalidade';
import usePapPrograma from '../../../../../actions/cruds/usePapPrograma';
import { useToast } from '../../../../../hooks/toast';

// import { Container } from './styles';


const PapCreatePrograma: React.FC = () => {

    const {idPap} = useParams();

    const {addToast} = useToast();
    const {getPap, selectedPap, updatePap} = usePap();
    const { setPapFinalidade, getPapFinalidade, getPapFinalidades, papFinalidade, papFinalidades, createPapFinalidades, newPapFinalidades } = usePapFinalidade();
    const { getPapProgramas, papProgramas, getPapPrograma, papPrograma, setPapPrograma, createPapPrograma, newPapPrograma, updatePapPrograma } = usePapPrograma();

    const [newPrograma, setNewPrograma] = useState(true);
    const [finalidadeAtual, setFinalidadeAtual] = useState([]);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        getPap(idPap);
        getPapProgramas();
    }, []);

    useEffect(() => {
        if (newPapPrograma.id) {
            updatePap(idPap, {programa_ids: [...selectedPap.programas.map(f => f.id), newPapPrograma.id]});
        }
    }, [newPapPrograma]);

    useEffect(() => {
        const {pap_finality} = papPrograma;
        if (pap_finality) {
            setFinalidadeAtual(organizeDataSelect([pap_finality], "id", "codigo", ["nome"]));
        }
    }, [papPrograma]);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => { 
        try {
            // console.log(data);
            
            if (newPrograma) {
                createPapPrograma(data);
            } else {
                updatePap(idPap, {programa_ids: [...selectedPap.programas.map(f => f.id), papPrograma.id]});
            }

            // addToast({
            //   type: 'success',
            //   title: 'Login efetuado',
            //   description: 'Seu login foi feito com sucesso!',
            // });

            // history.push('/dashboard');
        } catch (err) {    
            // addToast({
            //   type: 'error',
            //   title: 'Erro na autenticação',
            //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
            // });
        }
        },
        [papPrograma],
    );

    const form2Ref = useRef<FormHandles>(null);

    const handleSubmit2 = useCallback(
        async (data: object) => { 
            try {
                const programaId = formRef.current?.getFieldValue("nome");
                // console.log(finalidadeId, data);

                await updatePapPrograma(programaId, data);

                getPap(idPap);
                getPapProgramas();

                formRef.current?.reset();
                setOpen(false);

                // history.push('/dashboard');
            } catch (err) {}
        },
        [addToast, open],
    );

    const [activeStep] = useState(2);

    const dataTitle: GridColDef[] = [
        { field: "finalidade", headerName: "Finalidade", flex: 2 },
        { field: "codigo", headerName: "Código", flex: 1 },
        { field: "nome", headerName: "Programa", flex: 4 },
        {
          field: "actions",
          type: 'actions',
          headerName: "Ações",
          description: "Ações por coluna.",
          sortable: false,
          flex: 1,
          getActions: ({ id }) => {
            return [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon="fa-solid fa-trash-can" />}
                  label="Delete"
                  onClick={() => handleDeleteClick(id)}
                  color="inherit"
                  sx={{color: "#e62121"}}
                />
            ];
          }
        }
    ];

    function handleDeleteClick(id: GridRowId): React.MouseEventHandler<HTMLButtonElement> | undefined {
        updatePap(idPap, {programa_ids: [...selectedPap.programas.filter(f => f.id !== id).map(f => f.id)]});
    }

    const organizeData = (data: any[]) => {
        if (typeof data === 'object') {
          data = data.map(d => ({
            id: d.id,
            finalidade: `${d.pap_finality.codigo} - ${d.pap_finality.nome}`,
            codigo: d.codigo,
            nome: d.nome
          }));
        }
        return data;
    }

    const changePrograma = (programaSelected) => {  
        setNewPrograma(true);
        if (programaSelected !== null) {
            if (programaSelected && programaSelected.__isNew__) {
                
                setPapPrograma({});
                
            } else {
                formRef.current?.clearField("codigo");
                setNewPrograma(false);
                getPapPrograma(programaSelected.value);
            }
        }
    }

    const organizeProgramsOptions = () => {

        const {finalidades} = selectedPap;
        console.log(finalidades);
        if (selectedPap.id && papProgramas.data) { // So retorna os programas para SELECIONAR que tem relação com as finalidades disponiveis (previamente cadastradas)
            const filtredFinalidades = finalidades.map(i => i.id);
            return organizeDataSelect(papProgramas.data.filter(e => filtredFinalidades.includes(e.pap_finality.id)), "id", "codigo", ["nome"]) || [];
        }
        
        // return organizeDataSelect(papProgramas.data, "id", "nome") || [];
    }

    return (
        <Container>
            <Title>
                <span>PAP: Gerenciamento de Programas</span>
            </Title>

            <Stepper activeStep={activeStep} alternativeLabel sx={{my: 5}}>
                {steps.map(({label}, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Programa'>
                    <Grid container spacing={6}>
                        <Grid xs={12}>
                            <TextTitle>Finalidade: <span>*</span></TextTitle>
                            <SelectInput
                                id="pap_finality_id" 
                                name="pap_finality_id"
                                value={finalidadeAtual}
                                options={selectedPap ?
                                    organizeDataSelect(selectedPap.finalidades.filter((d) => d.ativo == true), "id", "codigo", ["nome"])
                                    : []}
                                placeholder="Selecione"
                                isDisabled={!newPrograma}
                                onChange={v => setFinalidadeAtual(v)}
                                // onInputChange={e => { getTiposEmpregados([{label: 'nome', value: e}]) }}
                                required
                            />
                        </Grid>

                        <Grid xs={12} lg={2}>
                            <TextTitle>Código: <span>*</span></TextTitle>
                            <Input 
                                mask="__"
                                replacement="allNumber"
                                id="codigo" 
                                name='codigo' 
                                required
                                defaultValue={papPrograma.codigo ?? ""}
                                disabled={!newPrograma}
                            />
                        </Grid>
                        <Grid xs={12} lg={10}>
                            <TextTitle>Programa: <span>*</span></TextTitle>
                            <div style={{display: "flex", alignItems: 'center'}}>
                                <CreatableSelectInput
                                    id="nome"
                                    name="nome"
                                    // options={organizeDataSelect(papProgramas.data.filter(e => e.pap_finality.id == "1"), "id", "nome") || []} // Tenho que comparar os PROGRAMAS pra saber quais deles tem as finalidades previamente cadastrados
                                    options={organizeProgramsOptions()}
                                    // options={organizeDataSelect(papProgramas.data, "id", "nome") || []}
                                    placeholder="Selecione ou crie um novo programa"
                                    onInputChange={(e) => {
                                        getPapProgramas([{ label: "nome", value: e }]);
                                    }}
                                    onChange={changePrograma}
                                    required
                                />
                                {!newPrograma && 
                                    <div>
                                        <IconButton color="primary" aria-label="editar" onClick={e => setOpen(true)}>
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </IconButton>
                                    </div>
                                }
                            </div>                          
                        </Grid>

                        <Grid xs={12}>
                            <Grid xs={12}>
                                <ButtonAdd type="submit" onClick={null}><FontAwesomeIcon icon={faPlus} /> Adicionar</ButtonAdd>
                            </Grid>
                            <SecondaryTable
                                hideFooter={true}
                                rowsData={organizeData(selectedPap.programas) || []}
                                columsData={dataTitle}
                            />
                        </Grid>
                    </Grid>
                </Panel>

                <ContainerButton2>
                    <Link
                        to={`/orcamentario/pap/finalidade/${idPap}`}
                        className="button_return"
                        style={{ textDecoration: "none", color: "black" }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} /> Voltar
                    </Link>
                    <div>
                        {/* <button className="button_save" type="submit">
                            <FontAwesomeIcon icon={faSave} style={{ marginRight: 10 }} />
                            Salvar
                        </button> */}
                        <Link
                            to={`/orcamentario/pap/acao/${idPap}`}
                            className="button_next"
                            style={{ textDecoration: "none" }}
                        >
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                color="#ffffff"
                                style={{ marginRight: 10 }}
                            />
                            Proxima Etapa
                        </Link>
                    </div>
                </ContainerButton2>

            </Form>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>Editar Programa</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    A edição do Programa por aqui altera também seu vínculo em outros PAPs.
                </DialogContentText>

                <Form id="form2" ref={form2Ref} onSubmit={handleSubmit2} >
                    
                    <Grid container spacing={3} style={{marginTop: "10px"}}>
                        <Grid xs={12}>
                            <TextTitle>Finalidade: <span>*</span></TextTitle>
                            <SelectInput
                                id="pap_finality_id" 
                                name="pap_finality_id"
                                value={finalidadeAtual}
                                options={selectedPap ?
                                    organizeDataSelect(selectedPap.finalidades.filter((d) => d.ativo == true), "id", "codigo", ["nome"])
                                    : []}
                                placeholder="Selecione"
                                isDisabled={!newPrograma}
                                onChange={v => setFinalidadeAtual(v)}
                                // onInputChange={e => { getTiposEmpregados([{label: 'nome', value: e}]) }}
                                required
                            />
                        </Grid>

                        <Grid xs={2}>
                            <TextTitle>Código: <span>*</span></TextTitle>
                            <Input 
                                mask="__"
                                replacement="allNumber"
                                id="codigo" 
                                name='codigo' 
                                required
                                defaultValue={papPrograma.codigo ?? ""}
                            />
                        </Grid>
                        <Grid xs={10}>
                            <TextTitle>Programa: <span>*</span></TextTitle>
                            <Input
                                name="nome"
                                type="text"
                                
                                defaultValue={papPrograma.nome ?? ""}
                                required
                            />
                        </Grid>
                    </Grid>
                    
                </Form>

                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancelar</Button>
                <Button type="submit" variant="contained" form="form2">Salvar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default PapCreatePrograma;