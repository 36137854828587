import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useRepresentanteInstituicao = () => {

    const history = useHistory();
    const [representantesInstituicao, setRepresentantesInstituicao] = useState({
        institution: {
            nome: ""
        }
    });
    const [representanteInstituicao, setRepresentanteInstituicao] = useState({
        institution: {
            nome: ""
        },
        representative_contacts: [],
        representative_competences: {}
    });
    const [error, setError] = useState(null);

    async function getRepresentantesInstituicao(params) {
        try {
            const response = await api.get(`representatives${stringfyParams(params)}`);
            setRepresentantesInstituicao(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getRepresentanteInstituicao(id: string) {
        try {
            const response = await api.get(`representatives/${id}`);
            // console.log(response);
            setRepresentanteInstituicao(response.data);
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createRepresentanteInstituicao(data: object) {
        try {
            const response = await api.post('representatives', data,{headers: {
                "Content-Type": "multipart/form-data",
              },});
            // console.log('response: ', data);
            history.goBack();

            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function updateRepresentanteInstituicao(id: string, data: object) {
        try {
            const response = await api.put(`representatives/${id}`, data,{headers: {
                "Content-Type": "multipart/form-data",
              },});
            console.log('response: ', response);
            history.goBack();
            return response
        } catch (e) {
            setError(e);
            // console.log(error);
        }        
    }

    async function DesativarRepresentanteInstituicao(id: any, data: any) {
        
        
        const formData = new FormData();

        formData.append('id', id);
        formData.append('ativo', data);

        try {
            const response = await api.put(`representatives/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            // console.log('response: ', data);
            
            window.location.reload();

            return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getRepresentantesXls(data?: any) {
        try {
            const response = await api.get(`representatives/xls`, {
                responseType: "blob", // importante!
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'representantes.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }


    return {representanteInstituicao, representantesInstituicao, setRepresentanteInstituicao, DesativarRepresentanteInstituicao, getRepresentanteInstituicao, getRepresentantesInstituicao, createRepresentanteInstituicao, updateRepresentanteInstituicao, getRepresentantesXls, error};
}

export default useRepresentanteInstituicao;