import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faSave,
  faX
} from "@fortawesome/free-solid-svg-icons";

import {
  ContainerButton, ContainerButton2, Form, Input, SelectInput, TextTitle, Title
} from '../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef } from "react";
import useProjeto from "../../../actions/cruds/useProjeto";
import Panel from "../../../components/Panel";
import { Container } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Radio from "../../../components/InputRadio";
import organizeDataSelect from "../../../utils/organizeDataSelect";
import useDemandas from "../../../actions/cruds/useDemandas";
import { Link, useParams } from "react-router-dom";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { formatarReais } from "../../../utils/formatDados";

export function ViewProjeto() {

  const { idProjeto } = useParams();

  const formRef = useRef<FormHandles>(null);

  const { getProjetoId, projeto } = useProjeto();
  const { getDemandas, demandas, getDemanda, demanda } = useDemandas();

  useEffect(() => {
    getProjetoId(idProjeto);
  }, []);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {

      } catch (err) {

      }
    }, []
  );

  const dataTitle: GridColDef[] = [
    { field: "codigo", headerName: "Código do projeto", minWidth: 220, flex: 2 },
    { field: "pap", headerName: "Código do PAP", minWidth: 115, flex: 1 },
    { field: "acesso", headerName: "Acesso", minWidth: 100, flex: 1 },
    { field: "classificador", headerName: "Classificador", minWidth: 100, flex: 1 },
    { field: "valor_previsto", headerName: "Valor previsto", minWidth: 115, flex: 1 },
    { field: "valor_projeto", headerName: "Valor do projeto", minWidth: 115, flex: 1 }
  ];

  const dataTitleDocument: GridColDef[] = [
    { field: "descricao", headerName: "Descrição do documento", flex: 4 },
    {
      field: "actions",
      type: 'actions',
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      getActions: (params) => {
        return [
          <Link to={{ pathname: params.row.url_arquivo }} target="_blank">
            <GridActionsCellItem
              icon={<FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />}
              label="Delete"
              onClick={() => handleDeleteClick(id)}
              color="inherit"
              sx={{ color: "#1473e6" }}
            />
          </Link>
        ];
      }
    }
  ];

  const organizeData = (data: any[]) => {
    if (typeof data === 'object' && data !== undefined) {
      data = data.map(d => ({
        id: d.id,
        codigo: `${d.expense_type.codigo}.${d.organization.codigo}.${d.management_contract.codigo}.${d.comittee.codigo}.${d.unit.codigo}.${d.pap_finality.codigo}.${d.pap_program.codigo}.${d.pap_action.codigo}.${d.poa_sub_action.codigo}.${idProjeto}.${d.description.codigo}`,
        pap: `${d.pap_finality.codigo}.${d.pap_program.codigo}.${d.pap_action.codigo}`,
        acesso: "EM BREVE",
        classificador: "EM BREVE",
        valor_previsto: formatarReais(d.valor_previsto),
        valor_projeto: "EM BREVE"
      }));
    }
    return data;
  }

  const organizeDataDocument = (data: any[]) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        descricao: d.nome,
        url_arquivo: `${d.url_arquivo}/${d.nome_arquivo}`
      }));
    }
    return data;
  }

  useEffect(() => {
    if (projeto.id) {
      getDemanda(projeto.demand.id);
    }

    const sumValorPrevisto = projeto.project_lines.reduce(
      (accumulator, currentValue) => accumulator + currentValue.valor_previsto,
      0
    );
    formRef.current?.setFieldValue("valor_previsto", formatarReais(sumValorPrevisto));
    formRef.current?.setFieldValue("rateio", `${projeto.rateio}`);
    formRef.current?.setFieldValue("demand_id", projeto.demand.codigo);
    formRef.current?.setFieldValue("tipo_atividade", projeto.tipo_atividade);
  }, [projeto]);

  useEffect(() => {
    formRef.current?.setFieldValue("origem", demanda.demand_origin.nome);
  }, [demanda]);

  return (
    <Container>
      <Title color='#333'>
        <span>
          Visualização do projeto
        </span>
      </Title>

      <Form id="projectForm" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Projeto">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Código do protocolo: </TextTitle>
              <Input type="text" name='demand_id' disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Origem da demanda: </TextTitle>
              <Input type="text" name='origem' disabled />
            </Grid>

            <Grid xs={6}>
              <TextTitle>Tipo de atividade: </TextTitle>
              <Input type="text" name='tipo_atividade' disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>O projeto será um rateio?</TextTitle>
              <Radio
                name="rateio"
                options={[{ id: "true", value: "true", label: " Sim" }, { id: "false", value: "false", label: " Não" }]}
                disabled
              />
            </Grid>

            <Grid xs={12}>
              <TextTitle>Projeto: </TextTitle>
              <Input type="text" name='nome' defaultValue={projeto.nome} disabled />
            </Grid>

            <Grid xs={6}>
              <TextTitle>Valor previsto total: </TextTitle>
              <Input type="text" name='valor_previsto' disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor total projeto: </TextTitle>
              <Input type="text" name='valor_total' defaultValue="EM BREVE" disabled />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Linhas">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {
                projeto.project_lines.length ?
                  (
                    <SecondaryTable
                      hideFooter={true}
                      rowsData={organizeData(projeto.project_lines)}
                      columsData={dataTitle}
                    />
                  ) :
                  <p>Não há linhas cadastradas.</p>
              }
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Documentos">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {
                projeto.project_documents && projeto.project_documents.length ?
                  (
                    <SecondaryTable
                      hideFooter={true}
                      rowsData={organizeDataDocument(projeto.project_documents)}
                      columsData={dataTitleDocument}
                    />
                  ) :
                  <p>Não há documentos cadastrados.</p>
              }
            </Grid>
          </Grid>
        </Panel>
      </Form>
      <ContainerButton2 className="no-print">
        <Link
          to={"/projetos"}
          className="button_return"
          style={{ textDecoration: "none", color: "black" }}
        >
          <FontAwesomeIcon icon={faChevronLeft} /> Voltar
        </Link>
        <Link to={`/projetos/fichaPDF/${idProjeto}`} target="_blank">
          <button className="button_save">
            <FontAwesomeIcon icon={faDownload} size="1x" /> Download PDF
          </button>
        </Link>
      </ContainerButton2>
    </Container>
  )
}
