import {View } from '@react-pdf/renderer';
import TableRow from '../TableRow'
import TableHeader from '../TableHeader'
import { styles } from './styles';

interface dataTableRowProps {
  id: string,
  row: string,
  value: string,
  logoWithColumns?: boolean,
};

interface dataItensTableProps {
  version: string,
  title: string,
  items: dataTableRowProps[],
};

interface ItensTableProps {
  data: dataItensTableProps,
};

  const ItensTable: React.FC<ItensTableProps> = ({data}) => (
    <View style={styles.tableContainer}>
        <TableHeader version={data.version} title={data.title} />
        <TableRow items={data.items} />
    </View>
  );
  
  export default ItensTable;