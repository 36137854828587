import React, { useEffect, useRef } from "react";
import {
  ContainerButton2,
  Form,
  Input,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import Panel from "../../../../../components/Panel";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import useContaBancaria from "../../../../../actions/cruds/Financeiro/useContaBancaria";
import { capitalizeFirstLetter } from "../../../../../utils/formatDados";

const FinanceiroContaBancariaView: React.FC = () => {
  const { contaBancariaId } = useParams();
  const { contaBancaria, getContaBancaria } = useContaBancaria();

  useEffect(() => {
    getContaBancaria(contaBancariaId);
  }, []);

  const formRef = useRef<FormHandles>(null);

  return (
    <Container>
      <Form id="formConta" ref={formRef}>
        <Title color="#333">
          <span>Visualizar Conta bancária</span>
        </Title>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Tipo de despesa:</TextTitle>
              <Input
                type="text"
                name="tipo_despesa"
                value={contaBancaria?.expense_type?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Organização:</TextTitle>
              <Input
                type="text"
                name="organizacao"
                value={contaBancaria?.organization?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Contrato de Gestão:</TextTitle>
              <Input
                type="text"
                name="contrato_gestao"
                value={contaBancaria?.management_contract?.nome_completo}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Comite de Bacia:</TextTitle>
              <Input
                type="text"
                name="apelido_conta"
                value={contaBancaria?.comittee?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Apelido da conta:</TextTitle>
              <Input
                type="text"
                name="apelido_conta"
                value={contaBancaria?.apelido}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Vinculante:</TextTitle>
              <Input
                type="text"
                name="vinculante"
                value={contaBancaria?.vinculante}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados bancários">
          <Grid container spacing={6}>
            <Grid xs={6} lg={3}>
              <TextTitle>Tipo de conta:</TextTitle>
              <Input type="text" name="tipo" value={capitalizeFirstLetter(contaBancaria?.conta_tipo)} disabled />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Banco:</TextTitle>
              <Input type="text" name="banco" value={contaBancaria?.conta_banco} disabled />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Agência:</TextTitle>
              <Input type="text" name="agencia" value={contaBancaria?.conta_agencia} disabled />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Nº da Conta:</TextTitle>
              <Input type="text" name="conta" value={contaBancaria?.conta_numero} disabled />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/financeiro/cadastros/contabancaria">
          <button className="button_return">
            <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
          </button>
        </Link>
      </ContainerButton2>
    </Container>
  );
};

export default FinanceiroContaBancariaView;
