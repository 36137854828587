import { Container } from "./styles";
import { Data } from "./types";
import MainTable from "../../../components/MainTable";
import { useEffect, useState } from "react";
import { Title } from "../../../styles/global";
import usePap from "../../../actions/cruds/usePap";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/auth";
import getPermission from "../../../utils/getPermission";

export const steps = [
  { label: "Identificação", value: 1 },
  { label: "Finalidade", value: 2 },
  { label: "Programa", value: 3 },
  { label: "Ação", value: 4 },
];

const Pap: React.FC = () => {
  // const { getPaps, paps } = usePap();
  const [ paps, setPaps ] = useState([]);

  // useEffect(() => {
  //   getPaps();
  // }, []);

  const {login} = useAuth();
  const orçamentarioPermission = getPermission(login,"Orçamentário") ?? '';

  useEffect(() => {
    api
      .get("paps")
      .then((response) => {
        setPaps(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`paps?page=${paps.pagination.current_page + page}`)
      .then((response) => {
        setPaps(response.data);
      })
      .catch((error) => console.log(error));
  };

  const organizeData = (data: Data[]) => {
    console.log(data);
    if (typeof data === "object") {
      data = data.map((item) => {
        // return;
        return {
          id: item.id,
          organizacao: item.comittee.management_contract.organization.nome.nome,
          contrato: item.comittee.management_contract.nome_completo,
          comite: item.comittee.nome,
          perido: `${item.periodo_de} - ${item.periodo_ate}`,
          ativo: item.ativo
        };
      });
    }

    return data;
  };

  const columnData = [
    { headerName: "Organização", field: "organizacao" },
    { headerName: "Contrato de gestão", field: "contrato" },
    { headerName: "Comitê", field: "comite" },
    { headerName: "Periodo", field: "perido" },
    { headerName: "Situação", field: "ativo" },
  ];

  return (
    <>
        <Title color="#333">
            <span>Gestão do PAP</span>
        </Title>
        <Container color="#FFF">
            <MainTable
            data={organizeData(paps.data)}
            pagination={paps.pagination}
            permission={orçamentarioPermission}
            handlePagination={handlePagination}
            hideFilter={true}
            registerLink="/orcamentario/pap/cadastrar-identificacao"
            columns={columnData}
            dropdown={{
                view: { linkTo: "pap/visualizar" },
                editar: { linkTo: "pap/editar-identificacao" },
                orcamento: { linkTo: "pap/orcamento" },
                desativar: { linkTo: "paps" },
            }}
            />
        </Container>
    </>
  );
};

export default Pap;
