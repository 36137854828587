import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

import {} from "../../pages/RecursosHumanos/Empregados/Edit";
import stringfyParams from "../../utils/stringfyParams";

const useUsuario = () => {
  const history = useHistory();
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState<any>([]);
  const [usuariosByCargo, setUsuariosByCargo] = useState<any>([]);
  const [tiposEmpregados, setTiposEmpregados] = useState([]);
  const [error, setError] = useState(null);

  async function getUsers(params?: object) {
    try {
      const response = await api.get(`users${stringfyParams(params)}`);
      setUsuarios(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }
  
  async function getUser(id: string) {
    try {
      const response = await api.get(`users/${id}`);
      setUsuario(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }
  
  async function getUserByCargoGerenteAssessor(params?: object) {
    try {
      const response = await api.get(`users${stringfyParams(params)}`);
      setUsuariosByCargo(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }
  
  async function createUsers(data: any) {
    try {
      const response = await api.post("users", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("response: ", response);
      alert("Usuário cadastrado com sucesso");
      history.push("/rh/empregados");

      // setUsuarios(response.data);
      // return response;
    } catch (e) {
      alert("Erro: Não foi possível cadastrar, verifique os dados.");
      setError(e);
      // console.log(error);
    }
  }

  async function updateUsers(id: number, data: any) {
    try {
      const response = await api.put(`users/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("response: ", response);
      alert("Usuário editado com sucesso");
      history.goBack();
    } catch (e) {
      setError(e);
      console.log(error);
    }
  }

  async function DesativarUsers(id: any, data: any) {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("ativo", data);

    try {
      const response = await api.put(`users/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log('response: ', data);

      window.location.reload();

      return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function getTiposEmpregados(params?: object) {
    try {
      const response = await api.get(`employee_types${stringfyParams(params)}`);
      setTiposEmpregados(response.data);
    } catch (e) {
      setError(e);
    }
  }

  async function getUsuariosXls(data?: any) {
    try {
      const response = await api.get(`users/xls`, {
        responseType: "blob", // importante!
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Empregados.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  return {
    usuarios,
    getUsers,
    usuario,
    getUser,
    createUsers,
    updateUsers,
    DesativarUsers,
    tiposEmpregados,
    getTiposEmpregados,
    getUsuariosXls,
    getUserByCargoGerenteAssessor,
    usuariosByCargo,
    error,
  };
};

export default useUsuario;
