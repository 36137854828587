import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useInstancia = () => {

    const history = useHistory();
    const [instancias, setInstancias] = useState([]);
    const [error, setError] = useState(null);

    async function getInstances( params ) { // Ex(multiplos parametros caso necessario): params = [{label: 'comittee_id', value: '1'}, {label: 'comittee_nome', value: 'teste'}]
        try {
            const response = await api.get(`instances${stringfyParams(params)}`);
            setInstancias(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createInstances(data) {
        try {
            const response = await api.post('instances', data);
            console.log('response: ', response);
            history.goBack();

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    // useEffect(() => {
    //     getInstances();
    // }, []);

    return {instancias, createInstances, getInstances, error};
}

export default useInstancia;