import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    table: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: 26,
        fontStyle: 'bold',
        alignItems: 'center',
        marginVertical: 0.001,
    },
    row: {
        display: 'flex',
        height: '100%',
        width: '33%',
        borderColor: '#000',
        borderRightWidth: 0.5,
        borderBottomWidth: 2,
        padding: "3 2 0 3",
    },
    value: {
        display: 'flex',
        height: '100%',
        width: '67%',
        borderColor: '#000',
        borderBottomWidth: 2,
        padding: "3 3 0 3",
        textAlign: 'justify',
    },
    tableLogo: {
        height: '100%',
        width: '33%',
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: '#000',
        borderRightWidth: 2,
        borderBottomWidth: 2,
    },
    logo: {
        width: '100%',
        height: '100%',
        maxHeight: 51,
        paddingTop: 1,
        objectFit: 'contain',
    },
    tableColumns: {
        flexDirection: 'column',
        width: '67%',
        height: '100%',
    },
    tableColumnRow: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        fontStyle: 'bold',
        alignItems: 'center',
        marginVertical: 0.001,
    },
    rowColumn: {
        width: '40%',
        height: '100%',
        minHeight: 26,
        borderColor: '#000',
        borderRightWidth: 0.5,
        borderBottomWidth: 2,
        padding: "3 2 0 3",
        textAlign: 'justify',
    },
    valueColumn: {
        width: '60%',
        height: '100%',
        minHeight: 26,
        borderColor: '#000',
        borderBottomWidth: 2,
        padding: "3 3 0 3",
        textAlign: 'justify',
    },
});