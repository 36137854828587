import styled from "styled-components";

export const RadioContainer = styled.div`
    display: flex;
    gap: 2rem;
    margin: 10px 0 0;
`;

export const LabelInput = styled.label``;

export const RadioInput = styled.input``;