import {
  faChevronLeft,
  faCloudArrowUp,
  faSave,
} from "@fortawesome/free-solid-svg-icons";

import Header from "../../../components/Header";
import { CSidebar } from "../../../components/Sidebar";

import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton, Form, FormContainer, Input, SelectInput, TextTitle, Title
} from '../../../styles/global';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../components/Panel";
import TableSimple from "../../../components/SimpleTable";
import { Link, useParams } from "react-router-dom";
import useFornecedor from "../../../actions/cruds/useFornecedor";
import useCertidao from "../../../actions/cruds/useCertidao";
import InputFile from "../../../components/InputFile";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";


export function CertidaoFornecedor() {

  const { supplierId } = useParams();

  const { fornecedor, getFornecedor } = useFornecedor();
  const { certidaoPorFornecedor, getCertidaoPorFornecedor, createCertidoes, deleteCertidoes } = useCertidao();
  const [tipoOutros, setTipoOutros] = useState(false);


  useEffect(() => {
    getFornecedor(supplierId);
    getCertidaoPorFornecedor(supplierId);
  }, [supplierId]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {

        const tipoFile = formRef.current?.getFieldValue("tipo");
        const documentFile = formRef.current?.getFieldValue("nome_arquivo");
        const descricaoFile = formRef.current?.getFieldValue("descricao");

        if (!tipoFile) {
          alert("Selecione o tipo do documento!");
          return;
        };
        if (!documentFile) {
          alert("Selecione o arquivo do documento!")
          return;
        };
        if (tipoOutros && !descricaoFile) {
          data["descricao"] = documentFile.name;
        };

        data['supplier_id'] = supplierId;

        createCertidoes(data);
      } catch (err) {

      }
    },
    [tipoOutros],
  );

  const organizeData = (data) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        supplier_id: d.supplier_id,
        tipo: d.descricao ? `${d.tipo} - ${d.descricao}` : d.tipo,
        data_vencimento: d.data_vencimento,
        // descricao: d.descricao,
        status_certidao: d.status_certidao,
        arquivo: `${d.url_arquivo}/${d.nome_arquivo}`
      }));
    }
    return data;
  }

  const dataTitle: GridColDef[] = [
    // { field: "institution_id", headerName: "ID da instituição" },
    { field: "tipo", headerName: "Documento", flex: 1 },
    { field: "data_vencimento", headerName: "Data de vencimento", flex: 1 },
    { field: "status_certidao", headerName: "Status", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Link to={{ pathname: params.row.arquivo }} target="_blank">
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
              </Button>
            </Link>
            <Button variant="text" onClick={(e) => onDeleteClick(e, params.row)}>
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      }
    }
  ];

  function onDeleteClick(e: any, row: any): void {
    deleteCertidoes(row);
  }

  const tipoDoc = [
    {
      value: "FGTS",
      label: "FGTS"
    },
    {
      value: "INSS/RECEITA_FEDERAL",
      label: "INSS/Receita Federal"
    },
    {
      value: "MUNICIPAL",
      label: "Municipal"
    },
    {
      value: "ESTADUAL",
      label: "Estadual"
    },
    {
      value: "TRABALHISTA",
      label: "Trabalhista"
    },
    {
      value: "CAFIMP",
      label: "CAFIMP"
    },
    {
      value: "CNDT",
      label: "CNDT"
    },
    {
      value: "FALENCIA",
      label: "Falência"
    },
    {
      value: "CADIN",
      label: "CADIN"
    },
    {
      value: "CAGEF",
      label: "CAGEF"
    },
    {
      value: "OUTROS",
      label: "Outros"
    }
  ];

  const handleChangeTipo = (tipo) => {
    if (tipo.value == 'OUTROS') {
      setTipoOutros(true);
    } else {
      setTipoOutros(false);
    }
  };

  console.log(tipoOutros);

  return (
    <Container>
      <Title color='#333'>
        <span>
          Certidões
        </span>
      </Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo={`Fornecedor: ${fornecedor.cpf_cnpj} - ${fornecedor.nome_razao_social}`}>
          <Grid container spacing={6}>
            <Grid xs={12} lg={6} >
              <TextTitle>Selecione o tipo do documento: </TextTitle>
              <SelectInput
                id='idTipodocumento'
                name='tipo'
                options={tipoDoc}
                placeholder="Selecionar"
                onChange={handleChangeTipo}
              />
            </Grid>
            <Grid xs={12} lg={6} >
              <TextTitle>Data de vencimento: </TextTitle>
              <Input type="date" name='data_vencimento' required />
            </Grid>
            {tipoOutros && (
              <Grid xs={12} lg={6}>
                <TextTitle>Descrição: </TextTitle>
                <Input type="text" name='descricao' />
              </Grid>
            )}
            <Grid xs={12} lg={6} >
              {/* <Input type="file" id="documento" name='documento' style={{display: 'none'}} required /> */}
              <TextTitle>Insira o documento referente a certidão:</TextTitle>
              <ButtonUploadArch for="documento" id='selecionararquivo' name='selecionararquivo' type="button">
                <FontAwesomeIcon icon={faCloudArrowUp} size="xl" /> Selecione o arquivo ...
              </ButtonUploadArch>
              <InputFile id="documento" name="nome_arquivo" style={{ display: 'none' }} />
            </Grid>
            <Grid xs={12}>
              <ButtonAdd id='adicionarunidade' name='adicionarunidade' type="submit">
                <FontAwesomeIcon icon={faSave} size="xl" /> Adicionar
              </ButtonAdd>
            </Grid>
            {certidaoPorFornecedor?.data?.length > 0 && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitle}
                  rowsData={organizeData(certidaoPorFornecedor.data)}
                />
              </Grid>
            )}
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/fornecedores">
          <button><FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar</button>
        </Link>
      </ContainerButton>
    </Container>
  )
}