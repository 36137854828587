import React from 'react';
import { Loader } from './styles';
import './style.css';


const AlmoxarifadoEstoque: React.FC = () => {
    return (
        <Loader src="https://homolog.agevap.gt4w.com.br/moduloAlmoxarifado/#/produtos/listagem" frameborder="0" title='Sistema legado almoxarifado'></Loader>
    );
};

export default AlmoxarifadoEstoque;