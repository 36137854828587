import { Box, Button, Container, Step, StepLabel, Stepper } from '@mui/material';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton, ContainerButton2, Input, SelectInput, TextTitle, Title } from '../../../../styles/global';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faSave, faX } from '@fortawesome/free-solid-svg-icons';
import { FormHandles } from '@unform/core';
import Panel from '../../../../components/Panel';
import usePap from '../../../../actions/cruds/usePap';
import useOrgao from '../../../../actions/cruds/useOrgao';
import useContratoGestao from '../../../../actions/cruds/useContratoGestao';
import useComite from '../../../../actions/cruds/useComite';
import organizeDataSelect from '../../../../utils/organizeDataSelect';
import { GridColDef } from '@mui/x-data-grid';
import SecondaryTable from '../../../../components/SecondaryTable';
import usePoa from '../../../../actions/cruds/usePoa';
import Poa from '..';


const PoaView: React.FC = () => {

    const {idPoa} = useParams();
    const {getPoa, selectedPoa} = usePoa();

    useEffect(() => {
        getPoa(idPoa);
    }, []);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => { 
            try {
                console.log(data);
            } catch (err) {    

            }
        },
        [],
    );

    const dataTitle: GridColDef[] = [
        { field: "subacao", headerName: "Nome", flex: 1 },
    ];

    const organizeData = (data: any[]) => {
        console.log('data: ', data);
        if (typeof data === 'object') {
          data = data.map(d => ({
            id: d.id,
            subacao: `${d.codigo} - ${d.nome}`,
          }));
        }
        return data;
    }

    return (
        <Container>
            <Title>
                <span>Visualizar POA</span>
            </Title>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
            <Panel titulo='Identificação'>
                    <Grid container spacing={6}>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Organização: <span>*</span></TextTitle>
                            <SelectInput
                                id="organization_id" 
                                name="organization_id"
                                isDisabled
                                value={[{label: selectedPoa.pap.comittee.management_contract.organization.nome.nome, value: 0}]}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Contrato de gestão: <span>*</span></TextTitle>
                            <SelectInput
                                id="management_contract_id" 
                                name="management_contract_id"
                                isDisabled
                                value={[{label: selectedPoa.pap.comittee.management_contract.nome, value: 0}]}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Comitê: <span>*</span></TextTitle>
                            <SelectInput
                                id="comittee_id" 
                                name="comittee_id"
                                isDisabled
                                value={[{label: selectedPoa.pap.comittee.nome, value: 0}]}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>PAP: <span>*</span></TextTitle>
                            <SelectInput
                                id="pap_id" 
                                name="pap_id"
                                isDisabled
                                value={[{label: selectedPoa.id ? `${selectedPoa.pap.periodo_de} - ${selectedPoa.pap.periodo_ate}` : "", value: 0}]}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>POA: <span>*</span></TextTitle>
                            <SelectInput
                                id="poa" 
                                name="poa"
                                isDisabled
                                value={[{label: selectedPoa.poa, value: 0}]}
                            />
                        </Grid>
                    </Grid>
                </Panel>

                <Panel titulo='Código e Sub-ação'>
                    <SecondaryTable
                        hideFooter={true}
                        rowsData={organizeData(selectedPoa.subacoes) || []}
                        columsData={dataTitle}
                    />
                </Panel>        
            </Form>
        </Container>
    );
};

export default PoaView;