import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useCnae = () => {

    const history = useHistory();
    const [cnaes, setCnaes] = useState([]);
    const [cnae, setCnae] = useState({
        codigo: "",
        nome: ""
    });
    const [error, setError] = useState(null);

    async function getCnaes(params) {
        try {
            console.log("get")
            const response = await api.get(`cnae_activities${stringfyParams(params)}`);
            console.log("response ", response)
            setCnaes(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getCnae(id: string) {
        try {
            const response = await api.get(`cnae_activities/${id}`);
            setCnae(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createCnaes(data) {
        try {
            const response = await api.post('cnae_activities', data);
            alert("Cnae criado!");
            history.goBack();

            // setUsuarios(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function updateCnae(id: string, data: any) {
        try {
            const response = await api.put(`cnae_activities/${id}`, data);
            console.log('response: ', response);
            history.goBack();
        } catch (e) {
            setError(e);
            // console.log(error);
        }        
    }

    async function deleteCnae(id: any) {
        try {
            const response = await api.delete(`cnae_activities/${id}`);
            alert("Cnae deletada!");
            history.go(0);

            return response.data;

        } catch (e) {
            setError(e)            
        }        
    }

    return {cnaes, getCnaes, cnae, getCnae, createCnaes, updateCnae, deleteCnae, error};
}

export default useCnae;