import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useCompetenciaInstituicao = () => {

    const history = useHistory();
    const [competenciasInstituicoes, setCompetenciasInstituicoes] = useState([]);
    const [competenciaInstituicao, setCompetenciaInstituicao] = useState([]);
    const [error, setError] = useState(null);

    async function getCompetenciaInstituicao(id) {
        try {
            const response = await api.get(`institution_competences?institution_id=${id}`);
            // console.log(response);
            setCompetenciaInstituicao(response.data);
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getCompetenciasInstituicao(params?: any) {
        try {
            const response = await api.get(`institution_competences${stringfyParams(params)}`);
            // console.log(response);
            setCompetenciasInstituicoes(response.data);
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createCompetenciaInstituicao(data) {
        try {
            const response = await api.post('institution_competences', data);

            const { institution_id } = data
            getCompetenciaInstituicao(institution_id);

            alert("Sucesso! Competência e Instância adicionados.");
            // setCompetenciaInstituicao(data);

            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
            alert("Algo deu errado, verifique os campos");
        }
    }

    async function deleteCompetenciaInstituicao(id) {
        try {
            const response = await api.delete(`institution_competences/${id}`);

            setCompetenciaInstituicao({...competenciaInstituicao, data: competenciaInstituicao['data'].filter((el) => el.id != id)}); //Apos e caso a requisição anterior seja sucesso retira o objeto do array

            alert("Sucesso! Competência e Instância removidos.");
            // return response;
        } catch (e) {
            setError(e);
            alert("Algo deu errado.");
            // console.log(error);
        }
    }


    return {competenciaInstituicao, getCompetenciaInstituicao, competenciasInstituicoes, getCompetenciasInstituicao, createCompetenciaInstituicao, deleteCompetenciaInstituicao, error};
}

export default useCompetenciaInstituicao;