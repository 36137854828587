import { ReactNode } from 'react';

import styled from 'styled-components';

const TextText = styled.a`
    width: 350px;   
    height: 44px; 
    font-size: 1.01rem; 
    color: #0372bc;
    font-weight: 600;   
    cursor: pointer;
`;

export interface TextProps {
    children?: ReactNode;
    asChild?: boolean; 
    title?: string;
    href?: string;
}

export function Text( props: TextProps){

    return(
        <TextText target='_blank' href={props.href}>
            {props.title}
        </TextText>
    )
}