import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const usePoa = () => {
  const history = useHistory();
  
  const { addToast } = useToast();

  const [poas, setPoas] = useState([]);
  const [selectedPoa, setSelectedPoa] = useState({
    pap: {
      comittee: {
        management_contract: {
          organization: {
            nome: {}
          }
        }
      }
    }
  });
  const [newPoa, setNewPoa] = useState({});
  const [poaBudget, setPoaBudget] = useState([
    {
      comittee: {},
      pap_finalities: [
        {
          pap_programs: [
            {
              pap_actions: []
            }
          ]
        }
      ],

    }
  ]);

  async function getPoas(params?: object) {
    try {
      const response = await api.get(`poas${stringfyParams(params)}`);
      setPoas(response.data);
    } catch (e) {}
  }

  async function getPoa(id) {
    try {
      const response = await api.get(`poas/${id}`);
      setSelectedPoa(response.data);
    } catch (e) {}
  }

  async function createPoa(data) {
    try {
        const response = await api.post('poas', data);
        alert("Poa criado!");
        addToast({
          type: 'success',
          title: 'POA criado!',
          description: 'Novo POA foi cadastrado.',
        });
        setNewPoa( response.data );
    } catch (e) {}
  }

  async function updatePoa(id, data) {
    try {
        const response = await api.put(`poas/${id}`, data);

        addToast({
          type: 'success',
          title: 'POA alterado!',
          description: 'Poa alterado com sucesso.',
        });
        getPoa(id);
    } catch (e) {}
  }

  async function getPoaBudget(params?: object) {
    try {
      const response = await api.get(`poas/budget/${stringfyParams(params)}`);
      setPoaBudget(response.data);
    } catch (e) { }
  }

  return {
    selectedPoa,
    poas,
    getPoas,
    getPoa,
    createPoa,
    newPoa,
    updatePoa,
    getPoaBudget,
    poaBudget
  };
};

export default usePoa;
