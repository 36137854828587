import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { IDropdownDesativar } from "../components/DropdownMainTable/Dropdowns";
import api from "../services/api";

export const desativeStatus = (props: IDropdownDesativar) => {
  const { id, status, linkTo } = props;

  confirmAlert({
    message: `Deseja realmente ${status ? "desativar" : "ativar"
      } o item selecionado?`,
    buttons: [
      {
        label: status ? "Desativar" : "Ativar",
        onClick: () => handleDesativeStatus({ id, status, linkTo }),
        style: { backgroundColor: "#366EFA" },
      },
      {
        label: "Cancelar",
        style: { backgroundColor: "#366EFA" },
      },
    ],
  });
};

const handleDesativeStatus = (props: IDropdownDesativar) => {
  const { id, status, linkTo } = props;

  if (linkTo == 'users') {
    if (status == true) {
      const dataDesligamento = Date.now();
      const dataCompleta = new Date(dataDesligamento);

      const anoData = dataCompleta.getFullYear();
      const mesData = dataCompleta.getMonth() + 1;
      const diaData = dataCompleta.getDate();
      const dataFormatada = `${anoData}-${mesData}-${diaData}`;

      api
        .put(`${linkTo}/${id}`, { ativo: !status, data_desligamento: dataFormatada })
        .then(() => {
          window.alert(`Item ${status ? "desativado" : "ativado"} com sucesso!`);
          window.location.reload();
        })
        .catch(() => alert(`Erro ao desativar o item: ${id}`));
    } else if (status == false) {
      const limparData = '';

      api
        .put(`${linkTo}/${id}`, { ativo: !status, data_desligamento: limparData })
        .then(() => {
          window.alert(`Item ${status ? "desativado" : "ativado"} com sucesso!`);
          window.location.reload();
        })
        .catch(() => alert(`Erro ao desativar o item: ${id}`));

    }
  } else {

    api
      .put(`${linkTo}/${id}`, { ativo: !status })
      .then(() => {
        window.alert(`Item ${status ? "desativado" : "ativado"} com sucesso!`);
        window.location.reload();
      })
      .catch(() => alert(`Erro ao desativar o item: ${id}`));
  }
};
