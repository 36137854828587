import React, { useEffect } from 'react';
import Card from '../../components/Card';
import { ChartContainer, Container, SimplePanel } from './styles';

import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import Header from '../../components/Header';
import { CSidebar } from '../../components/Sidebar';
import { useAuth } from '../../hooks/auth';
import { DialogAccept } from '../../components/ModalAceite';
import useLogin from '../../actions/cruds/useLogin';

const Dashboard: React.FC = () => {
  const { login } = useAuth();
  const { loginById, getLoginById } = useLogin();

  useEffect(() => {
    getLoginById(login.id);
  }, [login])

  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ height: '100%' }}>
          <CSidebar />
        </div>
        <div style={{ display: 'block', width: '100%' }}>
          <Header />
          <Container>
            <h1>Dashboard Geral</h1>

            <div className='CardsContainer'>
              <Card />
              <Card />
              <Card />
              <Card />
              <Card />
            </div>

            <ChartContainer>
              <SimplePanel>
                <h4>Valor de Bens por Contrato de Gestão</h4>
                <LineChart width={600} height={300} data={data}>
                  <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="name" />
                  <YAxis />
                </LineChart>
              </SimplePanel>

              <SimplePanel>
                <h4>Ativos</h4>
                {/* <ResponsiveContainer width="100%" height="100%"> */}
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="pv" fill="#8884d8" />
                  <Bar dataKey="uv" fill="#82ca9d" />
                </BarChart>
                {/* </ResponsiveContainer> */}
              </SimplePanel>
            </ChartContainer>
          </Container>
        </div>
      </div>
      <DialogAccept open={loginById ? !loginById.termo_aceite : false}></DialogAccept>
    </>
  );
};

export default Dashboard;