import styled from "styled-components";

interface IContainerProps {
  color: string;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  /* position: relative; */
  align-items: center;

  /* right: -20px; */
  width: 100px;

  padding: 6px 8px;
  border-radius: 6px;
  background-color: ${({ color }) => color};

  > span {
    color: #ffff;
    font-weight: bold;
    font-size: 12px;
    margin-left: 8px;
  }
`;
