import { useLayoutEffect, useRef } from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { NumericFormatInput } from './styles';

export default function InputNumberFormat(props: GridRenderEditCellParams) {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = useRef();

    useLayoutEffect(() => { // Foca no componente input dentro da celula ao abrir modo edição/foco
        if (hasFocus) {
          ref.current?.focus();
        }
    }, [hasFocus]);
    
    const handleValueChange = (values: { formattedValue: string; value: string; floatValue: number; }) => { // Atualiza o valor da celula, com base no valor do input, apos confirmação da alteração
        const {value, floatValue} = values;
        const newValue = floatValue;
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
          <NumericFormatInput
            placeholder='Defina um valor!'
            getInputRef={ref}
            value={value}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            allowNegative={false}
            onValueChange={handleValueChange}
            decimalScale={2}
            fixedDecimalScale
          />
    );
}