import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Step, StepLabel, Stepper } from '@mui/material';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ButtonAdd, ContainerButton, ContainerButton2, CreatableSelectInput, Input, SelectInput, TextTitle, Title } from '../../../../../styles/global';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPenToSquare, faPlus, faSave, faX } from '@fortawesome/free-solid-svg-icons';
import useCargo from '../../../../../actions/cruds/useCargo';
import { FormHandles } from '@unform/core';
import organizeDataSelect from '../../../../../utils/organizeDataSelect';
import useUsuario from '../../../../../actions/cruds/useUsuario';
import Panel from '../../../../../components/Panel';
import { steps } from '../..';
import SecondaryTable from '../../../../../components/SecondaryTable';
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import useDespesas from '../../../../../actions/cruds/useDespesas';
import usePapFinalidade from '../../../../../actions/cruds/usePapFinalidade';
import usePap from '../../../../../actions/cruds/usePap';
import { useToast } from '../../../../../hooks/toast';

// import { Container } from './styles';


const PapCreateFinalidade: React.FC = () => {

    const {idPap} = useParams();

    const {getDespesas, despesas} = useDespesas();
    const { setPapFinalidade, getPapFinalidade, getPapFinalidades, papFinalidade, papFinalidades, createPapFinalidades, newPapFinalidades, updatePapFinalidade } = usePapFinalidade();
    const {getPap, selectedPap, updatePap} = usePap();

    const [finalidades, setFinalidades] = useState([]);
    const [newFinalidade, setNewFinalidade] = useState(true);
    const [despesaAtual, setDespesaAtual] = useState({});
    const [open, setOpen] = useState(false);

    const { addToast } = useToast();

    useEffect(() => {
        getPap(idPap);
        getDespesas();
    }, []);

    useEffect(() => {
        if (newPapFinalidades.id) {
            // console.log('newPapFinalidades:', [...selectedPap.finalidades.map(f => f.id), newPapFinalidades.id]);
            console.log("newPapFinalidades.id");
            updatePap(idPap, {finalidade_ids: [...selectedPap.finalidades.map(f => f.id), newPapFinalidades.id]});
        }
    }, [newPapFinalidades]);

    useEffect(() => {
        const {expense_type} = papFinalidade;
        if (expense_type) {
            setDespesaAtual(organizeDataSelect([expense_type], "id", "codigo", ["nome"]));
        }
    }, [papFinalidade]);

    useEffect(() => {
        // updatePap();
        // console.log('selectedPap:', selectedPap.data);
        if (selectedPap) {
            setFinalidades(selectedPap.finalidades);
        }
    }, [selectedPap]);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => { 
        try {
            console.log(data);
            
            if (newFinalidade) {
                createPapFinalidades(data);
            } else {
                console.log("papFinalidade.id", idPap, selectedPap, papFinalidade);
                updatePap(idPap, {finalidade_ids: [...selectedPap.finalidades.map(f => f.id), papFinalidade.id]});
            }
            

            // addToast({
            //   type: 'success',
            //   title: 'Finalidade alterada',
            //   description: 'Alteração da Finalidade foi realizada com sucesso!',
            // });

            // history.push('/dashboard');
        } catch (err) {    
            // addToast({
            //   type: 'error',
            //   title: 'Erro na autenticação',
            //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
            // });
        }
        },
        [papFinalidade],
    );

    const form2Ref = useRef<FormHandles>(null);

    const handleSubmit2 = useCallback(
        async (data: object) => { 
            try {
                const finalidadeId = formRef.current?.getFieldValue("nome");
                // console.log(finalidadeId, data);


                
                await updatePapFinalidade(finalidadeId, data);

                getPap(idPap);
                const despesaId = formRef.current?.getFieldValue("expense_type_id");
                getPapFinalidades([{label: "expense_type_id", value: despesaId}]);

                formRef.current?.reset();
                setOpen(false);

                // history.push('/dashboard');
            } catch (err) {}
        },
        [addToast, open],
    );

    const organizeData = (data: any[]) => {
        if (typeof data === 'object') {
          data = data.map(d => ({
            id: d.id,
            despesa: `${d.expense_type.codigo} - ${d.expense_type.nome}`,
            codigo: d.codigo,
            nome: d.nome
          }));
        }
        return data;
    }

    const [activeStep] = useState(1);

    const dataTitle: GridColDef[] = [
        { field: "despesa", headerName: "Despesa", flex: 2 },
        { field: "codigo", headerName: "Código", flex: 1 },
        { field: "nome", headerName: "Finalidade", flex: 4 },
        {
          field: "actions",
          type: 'actions',
          headerName: "Ações",
          description: "Ações por coluna.",
          sortable: false,
          flex: 1,
          getActions: ({ id }) => {
            return [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon="fa-solid fa-trash-can" />}
                  label="Delete"
                  onClick={() => handleDeleteClick(id)}
                  color="inherit"
                  sx={{color: "#e62121"}}
                />
            ];
          }
        //   renderCell: (params) => {
        //     return (
        //       <>
        //         <Button variant="text" onClick={(e) => onDeleteClick(e, params.row)}>
        //             <FontAwesomeIcon icon="fa-solid fa-trash-can" />
        //         </Button>
        //       </>
        //     );
        //   }
        }
    ];

    function handleDeleteClick(id: GridRowId): React.MouseEventHandler<HTMLButtonElement> | undefined {
        // remove o ID selecionado e depois retorna array so dos IDS restantes
        updatePap(idPap, {finalidade_ids: [...selectedPap.finalidades.filter(f => f.id !== id).map(f => f.id)]});
    }

    const onChangeDespesa = (despesaSelected) => {
        // console.log(despesaSelected);
        if (!despesaSelected) return;
        
        setDespesaAtual(despesaSelected);

        getPapFinalidades([{label: "expense_type_id", value: despesaSelected.value}]);
    }

    const changeFinalidade = (finalidadeSelected) => {  
        // console.log(finalidadeSelected);

        setNewFinalidade(true);
        if (!finalidadeSelected) return;

        if (finalidadeSelected.__isNew__) {
            setPapFinalidade({});
        } else {
            formRef.current?.clearField("expense_type_id");
            formRef.current?.clearField("codigo");
            setNewFinalidade(false);
            getPapFinalidade(finalidadeSelected.value);
        }
    }

    return (
        <Container>
            <Title>
                <span>PAP: Gerenciamento de Finalidades</span>
            </Title>

            <Stepper activeStep={activeStep} alternativeLabel sx={{my: 5}}>
                {steps.map(({label}, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Finalidade'>
                    <Grid container spacing={6}>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Despesas: <span>*</span></TextTitle>
                            <SelectInput
                                id="expense_type_id" 
                                name="expense_type_id"
                                value={despesaAtual}
                                options={despesas.data ?
                                    organizeDataSelect(despesas.data.filter((d) => d.ativo == true), "id", "codigo", ["nome"])
                                    : []}
                                placeholder="Selecione"
                                isDisabled={!newFinalidade}
                                // onChange={v => setDespesaAtual(v)}
                                onChange={onChangeDespesa}
                                onInputChange={e => { getDespesas([{label: 'nome', value: e}, { label: 'ativo', value: true }]) }}
                                required
                            />
                        </Grid>
                        <Box width="100%"/>

                        <Grid xs={12} lg={2}>
                            <TextTitle>Código: <span>*</span></TextTitle>
                            <Input 
                                mask="__"
                                replacement="allNumber"
                                id="codigo" 
                                name='codigo' 
                                required
                                defaultValue={papFinalidade.codigo ?? ""}
                                disabled={!newFinalidade}
                            />
                        </Grid>
                        <Grid xs={12} lg={10}>
                            <TextTitle>Finalidade: <span>*</span></TextTitle>
                            <div style={{display: "flex", alignItems: 'center'}}>
                                <CreatableSelectInput
                                    id="nome"
                                    name="nome"
                                    options={organizeDataSelect(papFinalidades.data, "id", "codigo", ["nome"]) || []}
                                    placeholder="Selecione ou crie uma nova finalidade"
                                    onChange={changeFinalidade}
                                    // onInputChange={e => { getPapFinalidades([{label: 'nome', value: e}, { label: 'ativo', value: true }]) }}
                                    required
                                />
                                {!newFinalidade && 
                                    <div>
                                        <IconButton color="primary" aria-label="editar" onClick={e => setOpen(true)}>
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </IconButton>
                                    </div>
                                }

                            </div>
                        </Grid>

                        <Grid xs={12}>
                            <Grid xs={12}>
                                <ButtonAdd type="submit"><FontAwesomeIcon icon={faPlus} /> Adicionar</ButtonAdd>
                            </Grid>
                            <SecondaryTable
                                hideFooter={true}
                                rowsData={organizeData(selectedPap.finalidades) || []}
                                columsData={dataTitle}
                            />
                        </Grid>
                    </Grid>
                </Panel>
            </Form>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>Editar Finalidade</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    A edição da Finalidade por aqui altera também seu vínculo em outros PAPs.
                </DialogContentText>

                <Form id="form2" ref={form2Ref} onSubmit={handleSubmit2} >
                    
                    <Grid container spacing={3} style={{marginTop: "10px"}}>
                        <Grid xs={12}>
                            <TextTitle>Despesas: <span>*</span></TextTitle>
                            <SelectInput
                                id="expense_type_id" 
                                name="expense_type_id"
                                value={despesaAtual}
                                options={despesas.data ?
                                    organizeDataSelect(despesas.data.filter((d) => d.ativo == true), "id", "codigo", ["nome"])
                                    : []}
                                placeholder="Selecione"
                                onChange={v => setDespesaAtual(v)}
                                onInputChange={e => { getDespesas([{label: 'nome', value: e}, { label: 'ativo', value: true }]) }}
                                required
                                isClearable={false}
                            />
                        </Grid>

                        <Grid xs={2}>
                            <TextTitle>Código: <span>*</span></TextTitle>
                            <Input 
                                mask="__"
                                replacement="allNumber"
                                id="codigo" 
                                name='codigo' 
                                required
                                defaultValue={papFinalidade.codigo ?? ""}
                            />
                        </Grid>
                        <Grid xs={10}>
                            <TextTitle>Finalidade: <span>*</span></TextTitle>
                            <Input
                                name="nome"
                                type="text"
                                
                                // value={papFinalidade.nome ?? null}
                                defaultValue={papFinalidade.nome ?? ""}
                                required
                            />
                        </Grid>
                    </Grid>
                    
                </Form>

                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancelar</Button>
                <Button type="submit" variant="contained" form="form2">Salvar</Button>
                </DialogActions>
            </Dialog>

            <ContainerButton2>
                <Link
                    to={`/orcamentario/pap/editar-identificacao/${idPap}`}
                    className="button_return"
                    style={{ textDecoration: "none", color: "black" }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} /> Voltar
                </Link>
                <div>
                    <Link
                        to={`/orcamentario/pap/programa/${idPap}`}
                        className="button_next"
                        style={{ textDecoration: "none" }}
                    >
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            color="#ffffff"
                            style={{ marginRight: 10 }}
                        />
                        Proxima Etapa
                    </Link>
                </div>
            </ContainerButton2>
                
            
        </Container>
    );
};

export default PapCreateFinalidade;
