import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { CSidebar } from "../../components/Sidebar";
import { Container, Title } from "./style";
import MainTable from "../../components/MainTable";
import { formatCPF, formatarData } from "../../utils/formatDados";
import BadgeTable from "../../components/BadgeTable";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import getPermission from "../../utils/getPermission";

export function Fornecedores() {
  const [fornecedores, setFornecedores] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const {login} = useAuth();
  const fornecedoresPermission = getPermission(login,"Fornecedores") ?? '';

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      api
        .get(`suppliers?busca_rapida=${searchValue}`)
        .then((response) => {
          setFornecedores(response.data);
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`suppliers?page=${fornecedores.pagination.current_page + page}`)
      .then((response) => {
        setFornecedores(response.data);
      })
      .catch((error) => console.log(error));
  };

  const columnData = [
    { headerName: "Código", field: "id" },
    { headerName: "CPF/CNPJ", field: "cpf_cnpj" },
    { headerName: "Nome/Razão Social", field: "nome_razao_social" },
    { headerName: "Certidões", field: "certidoes" },
    { headerName: "Data De Cadastro", field: "cadastro" },
    { headerName: "Situação", field: "ativo" },
  ];

  const organizeData = (data: any) => {
    const withoutData = <i>Sem dados</i>;

    if (typeof data === "object") {
      data = data.map((d: any) => {
        const certidoes = <BadgeTable status={d.status_certidoes} />;
        const cpf_cnpj = d.cpf_cnpj ? formatCPF(d.cpf_cnpj) : withoutData;
        const cadastro = d.created_at
          ? formatarData(d.created_at, false)
          : withoutData;

        return {
          id: d.id,
          ativo: d.ativo,
          nome_razao_social: d.nome_razao_social,
          cadastro: cadastro,
          certidoes: certidoes,
          cpf_cnpj: cpf_cnpj,
        };
      });
    }
    return data;
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ height: "100%" }}>
        <CSidebar />
      </div>
      <div style={{ display: "block", width: "100%" }}>
        <Header />
        <Title color="#333">
          <span>Gestão de fornecedores</span>
        </Title>
        <Container color="#FFF">
          <MainTable
            onSearchChange={setSearchValue}
            data={organizeData(fornecedores.data)}
            pagination={fornecedores.pagination}
            permission={fornecedoresPermission}
            handlePagination={handlePagination}
            hideFilter={true}
            registerLink="/fornecedores/cadastrar"
            columns={columnData}
            dropdown={{
              view: { linkTo: "/fornecedores/visualizar" },
              editar: { linkTo: "/fornecedores/editar" },
              certidoes: { linkTo: "/fornecedores/certidao" },
              desativar: { linkTo: "suppliers" },
            }}
          />
        </Container>
      </div>
    </div>
  );
}
