import { PDFViewer } from '@react-pdf/renderer'
import DocumentPage from '../../../components/PDFComponents/DocumentPage';
import { useEffect, useState } from 'react';
import { formatCPF, formatarData, formatarReais } from '../../../utils/formatDados';
import { useParams } from 'react-router-dom';
import useInstrumento from '../../../actions/cruds/InstrumentoContratual/useInstrumento';
import useFornecedor from '../../../actions/cruds/useFornecedor';
import useProcesso from '../../../actions/cruds/Processos/useProcesso';

const InstrumentoFichaPDF: React.FC = () => {
  const { instrumentId } = useParams();
  const { instrumento, getInstrumento } = useInstrumento();
  const { fornecedor, getFornecedor } = useFornecedor();
  const { processo, getProcesso } = useProcesso();

  const [rubricas, setRubricas] = useState<Array<Object>>([]);
  const [dadosBancarios, setDadosBancarios] = useState<Array<Object>>([]);
  const [aditivos, setAditivos] = useState<Array<Object>>([]);
  const [apostilamentos, setApostilamentos] = useState<Array<Object>>([]);

  useEffect(() => {
    getInstrumento(instrumentId);
  }, [])

  useEffect(() => {
    if (instrumento?.id) {
      getProcesso(instrumento?.law_process?.id);
    };

    if (instrumento?.tipo_instrumento == "Convênios") {
      setDadosBancarios([
        {
          id: "dadosBancarios",
          row: "Dados bancários (convênio)",
          value: `Conta ${instrumento?.banco_tipo_conta}\n${instrumento?.banco_nome}\nAgência ${instrumento?.banco_agencia}, Número ${instrumento?.banco_conta}`
        },
      ]);
    } else {
      getFornecedor(instrumento?.supplier?.id);
    };

    if (instrumento?.additives?.length > 0 && instrumento?.additives[0]?.id) {
      const updatedAditivos = [
        {
          id: "aditivos",
          row: "Aditivos",
          value: "",
        },
      ];
      instrumento?.additives?.forEach((aditivo) => {
        updatedAditivos.push(
          {
            id: "aditivo",
            row: "Nome do aditivo:",
            value: aditivo?.nome ?? "-"
          },
          {
            id: "tipoAditivo",
            row: "Tipo de aditivo:",
            value: `[ ${aditivo?.aditivo_valor ? "X" : "  "} ] Valor           [ ${aditivo?.aditivo_prazo ? "X" : "  "} ] Prazo           [ ${aditivo?.aditivo_outros ? "X" : "  "} ] Outros`,
          },
          {
            id: "dataAssinaturaAditivo",
            row: "Data de assinatura do aditivo:",
            value: formatarData(aditivo?.data_assinatura, false) ?? "-"
          },
        );
      });
      setAditivos(updatedAditivos);
    }

    if (instrumento?.apostilles?.length > 0 && instrumento?.apostilles[0]?.id) {
      const updatedApostilamentos = [
        {
          id: "apostilamentos",
          row: "Apostilamentos",
          value: "",
        },
      ];
      instrumento?.apostilles?.forEach((apostilamento) => {
        updatedApostilamentos.push(
          {
            id: "apostilamento",
            row: "Nome do apostilamento:",
            value: apostilamento?.nome ?? "-"
          },

          {
            id: "dataAssinaturaApostilamento",
            row: "Data de assinatura do apostilamento:",
            value: formatarData(apostilamento?.data_assinatura, false) ?? "-"
          },
        );
      });
      setApostilamentos(updatedApostilamentos);
    }

  }, [instrumento])

  useEffect(() => {
    if (fornecedor?.id) {
      if (fornecedor?.consorcio_parceiro) {
        const updatedDadosBancarios = [
          {
            id: "dadosBancarios",
            row: "",
            value: "Dados bancários (consórcio)",
          },
        ];
        fornecedor?.consorcio_parceiro?.forEach((consorcio, index) => {
          updatedDadosBancarios.push(
            {
              id: "fornecedorConsorcio",
              row: `Fornecedor ${index + 1}:`,
              value: consorcio?.nome_razao_social ?? "-"
            },
            {
              id: "dadosBancarios",
              row: "Dados bancários:",
              value: `Conta ${consorcio?.banco_tipo_conta}\n${consorcio?.banco_nome}\nAgência ${consorcio?.banco_agencia}, Número ${consorcio?.banco_conta}`
            },
          );
        });
        setDadosBancarios(updatedDadosBancarios);
      } else {
        setDadosBancarios([
          {
            id: "dadosBancarios",
            row: "Dados bancários (fornecedor):",
            value: `Conta ${fornecedor?.banco_tipo_conta}\n${fornecedor?.banco_nome}\nAgência ${fornecedor?.banco_agencia}, Número ${fornecedor?.banco_conta}`
          },

        ]);
      };

    };

  }, [fornecedor])

  useEffect(() => {
    if (processo?.id) {
      if (instrumento?.contractual_instrument_budgets?.length > 0) {
        const updatedLinhas = []

        instrumento?.contractual_instrument_budgets?.forEach((linha, index) => {
          if (index == 0) {
            updatedLinhas.push(
              {
                id: "linhas",
                row: "",
                value: "Rubrica Orçamentária",
              },
            )
          }
          updatedLinhas.push(
            {
              id: "linha",
              row: `Linha ${index + 1}:`,
              value: `Contrato de gestão: ${linha?.project_line?.management_contract?.nome_completo}\nComitê: ${linha?.project_line?.comittee?.nome}\nCódigo do Projeto: ${linha?.project_line?.expense_type?.codigo}.${linha?.project_line?.organization?.codigo.padStart(2, '0')}.${linha?.project_line?.management_contract?.codigo.padStart(2, '0')}.${linha?.project_line?.comittee?.codigo.padStart(2, '0')}.${linha?.project_line?.unit?.codigo.padStart(2, '0')}.${linha?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${linha?.project_line?.pap_program?.codigo.padStart(2, '0')}.${linha?.project_line?.pap_action?.codigo.padStart(2, '0')}.${linha?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${processo?.project?.id.toString().padStart(2, '0')}.${linha?.project_line?.description?.codigo.padStart(3, '0')}\nValor: ${formatarReais(linha?.valor)}\nPercentual: ${((linha?.valor / instrumento?.valor_total) * 100).toFixed(2) ?? "-"}%`
            },
          );
        });
        setRubricas(updatedLinhas);
      }
    }

  }, [processo])

  if (instrumento?.id && processo?.id) {
    const pdfData = {
      version: "Formulário eletrônico - SIGA Águas",
      title: "FICHA DE INSTRUMENTO CONTRATUAL",
      items: [
        {
          id: "agevapImg",
          logoWithColumns: true,
          itemsColumn: [
            {
              id: "instrumentoNumero",
              row: "INSTRUMENTO Nº",
              value: instrumento?.numero ?? "-"
            },
            {
              id: "processoCodigo",
              row: "PROCESSO Nº",
              value: instrumento?.law_process?.codigo ?? "-"
            },
          ]
        },
        {
          id: "descricaoProcesso",
          row: "Descrição do processo:",
          value: instrumento?.law_process?.titulo ?? "-"
        },
        {
          id: "tipoInstrumento",
          row: "Tipo de Instrumento:",
          value: `${instrumento?.tipo_instrumento ?? ""} ${instrumento?.tipo_descricao ?? ''}` ?? "-"
        },
        {
          id: "cpfCnpjFornecedor",
          row: "CPF/CNPJ do Fornecedor:",
          value: formatCPF(instrumento?.supplier?.cpf_cnpj) ?? "-"
        },
        {
          id: "nomeFornecedor",
          row: "Nome do Fornecedor:",
          value: instrumento?.supplier?.nome_razao_social ?? "-"
        },
        {
          id: "instrumentoConvocatorio",
          row: "Instrumento Convocatório:",
          value: instrumento?.instrumento_convocatorio ?? "-"
        },
        {
          id: "objetoInstrumento",
          row: "Objeto do Instrumento:",
          value: instrumento?.objeto ?? "-"
        },
        {
          id: "dataAssinatura",
          row: "Data de assinatura do contrato:",
          value: formatarData(instrumento?.data_assinatura_contrato, false) ?? "-"
        },
        {
          id: "dataInicioVigencia",
          row: "Data de início da vigência:",
          value: formatarData(instrumento?.data_inicio_vigencia, false) ?? "-"
        },
        {
          id: "dataTerminoVigencia",
          row: "Data de término da vicência:",
          value: formatarData(instrumento?.data_termino_vigencia, false) ?? "-"
        },
        {
          id: "valorTotal",
          row: "Valor total:",
          value: formatarReais(instrumento?.valor_total) ?? "-",
        },
        {
          id: "gestor",
          row: "Gestor do instrumento:",
          value: instrumento?.gestor?.nome ?? "-"
        },
        {
          id: "substituto",
          row: "Substituto do gestor do instrumento:",
          value: instrumento?.gestor_substituto?.nome ?? "-"
        },
        {
          id: "contrato",
          row: "Descrição do contrato:",
          value: instrumento?.contrato_descricao ?? "-"
        },
        {
          id: "dataPublicacao",
          row: "Data da publicação:",
          value: formatarData(instrumento?.publicacao_data, false) ?? "-"
        },
        {
          id: "publicacao",
          row: "Descrição da publicação:",
          value: instrumento?.publicacao_descricao ?? "-"
        },
      ],
    };

    if (dadosBancarios?.length > 0) {
      dadosBancarios.forEach((dado) => pdfData.items.push(dado))
    };
    if (rubricas?.length > 0) {
      rubricas.forEach((rubrica) => pdfData.items.push(rubrica))
    };
    if (aditivos?.length > 0) {
      aditivos.forEach((aditivo) => pdfData.items.push(aditivo))
    };
    if (apostilamentos?.length > 0) {
      apostilamentos.forEach((apostilamento) => pdfData.items.push(apostilamento))
    };

    return (
      <PDFViewer width="100%" height="1300" className="app" >
        <DocumentPage data={pdfData} />
      </PDFViewer>
    );
  };
};


export default InstrumentoFichaPDF;