import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Textarea,
  Title,
} from "../../../styles/global";
import Panel from "../../../components/Panel";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { SaveWithProgress } from "../../../components/SaveCircularIntegration";
import useJuridico from "../../../actions/cruds/Juridico/useJuridico";
import { formatarReais, formatarRealparaDecimal } from "../../../utils/formatDados";

const JuridicoFinalizarProcesso: React.FC = () => {
  const { processoJuridicoId } = useParams();
  const { processoJuridico, getProcessoJuridico, updateProcessoJuridico } = useJuridico();

  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getProcessoJuridico(processoJuridicoId);
  }, []);

  useEffect(() => {
    formRef.current?.setFieldValue("data_termino", processoJuridico?.data_termino);
    formRef.current?.setFieldValue("valor_final", formatarReais(processoJuridico?.valor_final));
  }, [processoJuridico])
  console.log(processoJuridico)

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true)

        data["situacao"] = "concluido";
        data["valor_final"] = formatarRealparaDecimal(data.valor_final);

        console.log("data", data);
        await updateProcessoJuridico(processoJuridicoId, data);
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    }, []
  );

  return (
    <Container>
      <Form id="formJuridico" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Finalizar Processo jurídico</span>
        </Title>

        <Panel titulo="Finalizar processo">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data da finalização do processo:</TextTitle>
              <Input
                type="date"
                name="data_termino"
                disabled={processoJuridico?.data_termino}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor do processo:</TextTitle>
              <Input
                type="text"
                format="currency"
                currency="BRL"
                name="valor_final"
                disabled={processoJuridico?.valor_final}
                required
              />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/juridico">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formJuridico"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default JuridicoFinalizarProcesso;
