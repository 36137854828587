import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useInstrumento = () => {
    const history = useHistory();
    const [instrumentos, setInstrumentos] = useState([]);
    const [instrumento, setInstrumento] = useState(
        {
            "id": "",
            "numero": "",
            "ativo": "",
            "excluido": "",
            "tipo_instrumento": "",
            "tipo_descricao": "",
            "instrumento_convocatorio": "",
            "objeto": "",
            "data_assinatura_contrato": "",
            "data_inicio_vigencia": "",
            "data_termino_vigencia": "",
            "prazo_vigencia": "",
            "tipo_prazo_vigencia": "",
            "valor_total": "",
            "valor_atual": "",
            "banco_nome": "",
            "banco_agencia": "",
            "banco_conta": "",
            "banco_tipo_conta": "",
            "pagamentos_rateados": "",
            "contrato_descricao": "",
            "contrato_arquivo": "",
            "url_contrato_arquivo": "",
            "publicacao_descricao": "",
            "publicacao_data": "",
            "publicacao_arquivo": "",
            "url_publicacao_arquivo": "",
            "gestor": {
                "id": "",
                "nome": "",
                "cpf": "",
                "email": "",
                "celular": "",
                "ultimo_acesso": "",
                "organization": {
                    "id": "",
                    "nome": ""
                }
            },
            "supplier_id": "",
            "supplier": {
                "id": "",
                "cpf_cnpj": "",
                "nome_razao_social": ""
            },
            "law_process_id": "",
            "law_process": {
                "id": "",
                "numero": "",
                "titulo": ""
            },
            "gestor_id": "",
            "gestor_substituto_id": "",
            "gestor_substituto": {
                "id": "",
                "nome": "",
                "cpf": "",
                "email": "",
                "celular": "",
                "ativo": "",
                "ultimo_acesso": "",
                "organization": {
                    "id": "",
                    "nome": ""
                }
            },
            "additives": [
                {
                    "id": "",
                    "nome": "",
                    "ativo": "",
                    "aditivo_valor": "",
                    "aditivo_prazo": "",
                    "aditivo_outros": ""
                }
            ],
            "apostilles": [
                {
                    "id": "",
                    "nome": ""
                }
            ],
            "audits": [
                {
                    "id": "",
                    "auditable_type": "",
                    "auditable_id": "",
                    "action": "",
                    "created_at": "",
                    "user": {
                        "id": "",
                        "nome": ""
                    },
                    "alteracoes": {
                    }
                }
            ]
        }
    );
    const [ultimaRubricaInstrumento, setUltimaRubricaInstrumento] = useState([]);

    const [error, setError] = useState(null);

    async function getInstrumentos(params?: object) {
        try {
            const response = await api.get(`contractual_instruments${stringfyParams(params)}`);
            setInstrumentos(response.data);
        } catch (e) {
            setError(e);
        }
    };

    async function getInstrumento(id: any) {
        try {
            const response = await api.get(`contractual_instruments/${id}`);
            setInstrumento(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    };

    async function updateInstrumento(id: string, data: object) {
        try {
            const response = await api.put(`contractual_instruments/${id}`, data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
            alert("Instrumento atualizado com sucesso!");
            history.push("/instrumentos");
            return response
        } catch (e) {
            setError(e);
        }
    };

    async function createInstrumentos(data: any) {
        try {
            const response = await api.post('contractual_instruments', data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            alert("Instrumento cadastrado com sucesso!");
            history.push("/instrumentos");
        } catch (e) {
            alert("Erro ao cadastrar instrumento");
            setError(e);
        }
    };

    async function getUltimaRubricaInstrumento(id: any) {
        try {
            const response = await api.get(`contractual_instruments/getUltimaRubrica/${id}`);
            setUltimaRubricaInstrumento(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    };

    async function DesativarInstrumento(id: any, data: any) {

        const formData = new FormData();

        formData.append('ativo', data);

        try {
            const response = await api.put(`contractual_instruments/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            );
            // setInstrumento(response.data)
            // console.log('funcao data', data);
            window.location.reload();

            return response.data;
        } catch (e) {
            alert('Erro!')
            setError(e);
        }
    };


    async function getInstrumentosXls(data?: any) {
        try {
            const response = await api.get(`contractual_instruments/xls`, {
                responseType: "blob", // importante!
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Instrumentos.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    };

    return {
        instrumentos,
        instrumento,
        getInstrumentos,
        getInstrumento,
        createInstrumentos,
        updateInstrumento,
        ultimaRubricaInstrumento,
        getUltimaRubricaInstrumento,
        DesativarInstrumento,
        getInstrumentosXls,
        error
    };
}

export default useInstrumento;