import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  ButtonAdd,
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Textarea,
  Title,
} from "../../../styles/global";
import Panel from "../../../components/Panel";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { SaveWithProgress } from "../../../components/SaveCircularIntegration";
import useJuridico from "../../../actions/cruds/Juridico/useJuridico";
import useOrgao from "../../../actions/cruds/useOrgao";
import organizeDataSelect from "../../../utils/organizeDataSelect";
import useProcesso from "../../../actions/cruds/Processos/useProcesso";
import optionsUfs from "../../../utils/UFs";
import useUsuario from "../../../actions/cruds/useUsuario";
import { formatarData, formatarReais, formatarRealparaDecimal } from "../../../utils/formatDados";
import organizeDataNumeroProcesso from "../../../utils/organizeDataNumeroProcesso";

const JuridicoEdit: React.FC = () => {
  const { processoJuridicoId } = useParams();
  const { processoJuridico, getProcessoJuridico, updateProcessoJuridico } = useJuridico();
  const { orgaos, getOrganizations } = useOrgao();
  const { processos, getProcessos } = useProcesso();
  const { usuarios, getUsers } = useUsuario();

  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getProcessoJuridico(processoJuridicoId);
    getOrganizations([{ label: 'ativo', value: true }]);
    getProcessos([{ label: 'ativo', value: true }]);
    getUsers([{ label: 'cargo_atual', value: "Advogado" }, { label: 'ativo', value: true }]);
  }, []);

  useEffect(() => {
    if (processoJuridico?.id) {

      formRef.current?.setFieldValue("organization_id", { value: processoJuridico?.organization?.id, label: processoJuridico?.organization?.nome });
      formRef.current?.setFieldValue("data_inicio_processo", processoJuridico?.data_inicio_processo);
      formRef.current?.setFieldValue("law_process_id", { value: processoJuridico?.law_process?.id, label: `${processoJuridico?.law_process?.numero} - ( SIGAD - ${processoJuridico?.law_process?.codigo})` });
      formRef.current?.setFieldValue("tipo_processo", processoJuridico?.tipo_processo);
      formRef.current?.setFieldValue("local_processo", processoJuridico?.local_processo);

      const estadoCadastrado = optionsUfs.find((uf) => uf.value == processoJuridico?.estado);
      formRef.current?.setFieldValue("estado", { value: estadoCadastrado?.value, label: estadoCadastrado?.label });

      formRef.current?.setFieldValue("prognostico", { value: processoJuridico?.prognostico, label: processoJuridico?.prognostico });

      formRef.current?.setFieldValue("user_id", { value: processoJuridico?.user?.id, label: processoJuridico?.user?.nome });
      formRef.current?.setFieldValue("valor_estimado", formatarReais(processoJuridico?.valor_estimado));
      formRef.current?.setFieldValue("partes_processo", processoJuridico?.partes_processo);

    };

  }, [processoJuridico])

  const prognosticoOptions = [
    { value: "Provável", label: "Provável" },
    { value: "Possível", label: "Possível" },
    { value: "Remoto", label: "Remoto" },
  ];

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true)

        data["valor_estimado"] = formatarRealparaDecimal(data.valor_estimado);

        console.log("data", data);
        await updateProcessoJuridico(processoJuridicoId, data);
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    }, []
  );

  return (
    <Container>
      <Form id="formJuridico" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Edição do Processo jurídico</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Organização:</TextTitle>
              <SelectInput
                name="organization_id"
                placeholder="Selecione..."
                options={organizeDataSelect(orgaos.data, "id", "nome")}
                onInputChange={(e) => {
                  getOrganizations([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data do inicio do processo:</TextTitle>
              <Input
                type="date"
                name="data_inicio_processo"
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número do processo:</TextTitle>
              <SelectInput
                name="law_process_id"
                placeholder="Selecione..."
                options={organizeDataNumeroProcesso(processos.data, "id", "codigo", "numero")}
                onInputChange={(e) => {
                  getProcessos([{ label: 'numero', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Tipo de processo:</TextTitle>
              <Input
                type="text"
                name="tipo_processo"
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Local do processo:</TextTitle>
              <Input
                type="text"
                name="local_processo"
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Estado:</TextTitle>
              <SelectInput
                name="estado"
                placeholder="Selecione..."
                options={optionsUfs}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Prognóstico:</TextTitle>
              <SelectInput
                name="prognostico"
                placeholder="Selecione..."
                options={prognosticoOptions}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Advogado:</TextTitle>
              <SelectInput
                name="user_id"
                placeholder="Selecione..."
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: 'nome', value: e }, { label: 'cargo_atual', value: "Advogado" }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor estimado:</TextTitle>
              <Input
                type="text"
                format="currency"
                currency="BRL"
                name="valor_estimado"
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Partes do processo:</TextTitle>
              <Input
                type="text"
                name="partes_processo"
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Assunto do processo:</TextTitle>
              <Textarea
                name="assunto"
                defaultValue={processoJuridico?.assunto}
                required
              />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/juridico">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formJuridico"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default JuridicoEdit;
