import { clsx } from 'clsx';
import { ButtonHTMLAttributes, ReactNode } from "react";
import styled from 'styled-components';

const ButtonCss = styled.button` 
    width: 350px;
    height: 44px;
    background: #0372BC 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 0.3rem;
    opacity: 1;
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: 1rem;
`;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{
    children: ReactNode;
    // asChild?: boolean;
}

export function Button({ children,  className, ...props }: ButtonProps){

    return(
        <>
            <ButtonCss className={clsx(ButtonCss, className,)} {...props} >
                {children}
            </ButtonCss>
        </>
    )
}