import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Textarea,
  Title,
} from "../../../styles/global";
import Panel from "../../../components/Panel";
import Radio from "../../../components/InputRadio";
import { Button, Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import organizeDataSelect from "../../../utils/organizeDataSelect";
import InputFilev2 from "../../../components/InputFilev2";
import SecondaryTable from "../../../components/SecondaryTable";
import { SaveWithProgress } from "../../../components/SaveCircularIntegration";
import useUsuario from "../../../actions/cruds/useUsuario";
import optionsBoolean from "../../../utils/optionsBoolean";
import useGerencia from "../../../actions/cruds/useGerencia";
import useUnidade from "../../../actions/cruds/useUnidade";
import { GridColDef } from "@mui/x-data-grid";
import useProcesso from "../../../actions/cruds/Processos/useProcesso";
import { ButtonAddSide } from "../styles";

const ProcessoEdit: React.FC = () => {
  const { processoId } = useParams();
  const { usuarios, getUsers } = useUsuario();
  const { gerencias, getGerencias } = useGerencia();
  const { unidades, getUnidades } = useUnidade();
  const { processo, getProcesso, updateProcesso } = useProcesso();

  const [possuiProjeto, setPossuiProjeto] = useState<String>();
  const [contratosGestaoUpdate, setContratosGestaoUpdate] = useState<Array<Number>>([]);
  const [documentoProcesso, setDocumentoProcesso] = useState<Object>();
  const [salvando, setSalvando] = useState<boolean>(false);

  useEffect(() => {
    getProcesso(processoId);
    getUsers([{ label: 'employee_type_id', value: 1 }, { label: 'ativo', value: true }]);
    getGerencias([{ label: 'ativo', value: true }]);
    getUnidades([{ label: 'ativo', value: true }]);
  }, []);

  useEffect(() => {
    if (processo?.id) {
      formRef.current?.setFieldValue("solicitante_id", { value: processo?.solicitante?.id, label: processo?.solicitante?.nome });

      const possuiProjetoRadio = formRef.current?.getFieldRef("possui_projetoRadio");
      if (processo?.possui_projeto == true) {
        possuiProjetoRadio[0].checked = true;
      } else if (processo?.possui_projeto == false) {
        possuiProjetoRadio[1].checked = true;
      };
      setPossuiProjeto(`${processo?.possui_projeto}`);

      const processoRateioRadio = formRef.current?.getFieldRef("rateioRadio");
      if (processo?.rateio == true) {
        processoRateioRadio[0].checked = true;
      } else if (processo?.rateio == false) {
        processoRateioRadio[1].checked = true;
      };

      if (processo?.management_contracts) {
        let contratosGestao: number[] = [];
        processo?.management_contracts.forEach((contrato) => contratosGestao.push(contrato.id));
        setContratosGestaoUpdate(contratosGestao);
      };

      formRef.current?.setFieldValue("gestor_processo_id", { value: processo?.gestor_processo?.id, label: processo?.gestor_processo?.nome });
      formRef.current?.setFieldValue("gestor_substituto_id", { value: processo?.gestor_substituto?.id, label: processo?.gestor_substituto?.nome });
      formRef.current?.setFieldValue("administration_id", { value: processo?.administration?.id, label: processo?.administration?.nome });
      formRef.current?.setFieldValue("unit_id", { value: processo?.unit?.id, label: processo?.unit?.nome });
      setDocumentoProcesso({ id: processo?.id, titulo_arquivo: processo?.processo_arquivo, url_arquivo: processo?.url_processo_arquivo, isNew: false })
    }
  }, [processo]);

  const organizeDataLinhasProjeto = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          contratoGestao: d.nome_completo,
        }));
        return data;
      };
    };
    return [];
  };

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true);

        data["management_contract_ids"] = contratosGestaoUpdate;

        console.log("data", data);
        await updateProcesso(processoId, data);
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    }, [contratosGestaoUpdate]
  );

  const handlerDocument = () => {
    const tituloArquivo = formRef.current?.getFieldValue("processo_arquivo");
    setDocumentoProcesso({ id: Date.now(), titulo_arquivo: tituloArquivo.name, isNew: true })
  };

  const dataTitleContratoGestao: GridColDef[] = [
    { field: "contratoGestao", headerName: "Contrato de Gestão", flex: 2 },
  ];

  const dataTitleDocumento: GridColDef[] = [
    {
      field: "titulo_arquivo",
      headerName: "Nome do Documento",
      flex: 3,
      headerClassName: "header_table",
    },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      headerClassName: "header_table",
      renderCell: (params) => {
        return (
          <>
            {!params.row.isNew ?
              <Link to={{ pathname: `${params.row.url_arquivo}/${params.row.titulo_arquivo}` }} target="_blank">
                <Button variant="text">
                  <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
                </Button>
              </Link>
              :
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
              </Button>
            }
          </>
        );
      }
    }
  ];

  return (
    <Container>
      <Form id="formProcesso" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Edição da Solicitação de abertura de Processo</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Solicitante:</TextTitle>
              <SelectInput
                name="solicitante_id"
                placeholder="Selecione..."
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: 'nome', value: e }, { label: 'employee_type_id', value: 1 }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Esse processo possui um projeto?</TextTitle>
              <Radio name="possui_projetoRadio" options={optionsBoolean} disabled />
            </Grid>
            {possuiProjeto == 'true' ?
              <Grid xs={6}>
                <TextTitle>Código do Projeto:</TextTitle>
                <Input
                  name="codigoProjeto"
                  type="text"
                  value={`${processo?.project?.id} - ${processo?.project?.nome}`}
                  disabled
                />
              </Grid>
              : null}
            <Grid xs={6}>
              <TextTitle>Código do Protocolo:</TextTitle>
              <Input
                name="codigoProtocolo"
                type="text"
                value={processo?.demand?.codigo}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Competências">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Esse processo é um rateio?</TextTitle>
              <Radio
                name="rateioRadio"
                options={[{ id: "true", value: "true", label: "Sim" }, { id: "false", value: "false", label: "Não" }]}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              {processo?.management_contracts?.length > 0 && (
                <SecondaryTable
                  columsData={dataTitleContratoGestao}
                  rowsData={organizeDataLinhasProjeto(processo?.management_contracts)}
                />
              )}
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Processo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Categoria do Processo:</TextTitle>
              <Input
                type="text"
                name="categoriaProcesso"
                value={processo?.law_process_category?.nome}
                disabled
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Assunto do Processo:</TextTitle>
              <Textarea name="assunto_processo" defaultValue={processo?.assunto_processo} required />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados Institucionais">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Gestor do processo:</TextTitle>
              <SelectInput
                name="gestor_processo_id"
                placeholder="Selecione..."
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: 'employee_type_id', value: 1 }, { label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Gestor substituto do processo:</TextTitle>
              <SelectInput
                name="gestor_substituto_id"
                placeholder="Selecione..."
                options={organizeDataSelect(usuarios.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsers([{ label: 'employee_type_id', value: 1 }, { label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Área responsável:</TextTitle>
              <SelectInput
                name="administration_id"
                placeholder="Selecione..."
                options={organizeDataSelect(gerencias.data, "id", "nome")}
                onInputChange={(e) => {
                  getGerencias([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Local:</TextTitle>
              <SelectInput
                name="unit_id"
                placeholder="Selecione..."
                options={organizeDataSelect(unidades.data, "id", "nome")}
                onInputChange={(e) => {
                  getUnidades([{ label: 'nome', value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Processo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Documentos de Formalização:</TextTitle>
              <Textarea name="documento_abertura" placeholder={`Informe os documentos referentes à abertura do Processo. Ex: Carta de solicitação da demanda do Comitê que justifique a abertura desse processo.\nCaso não haja um documento, insira uma breve informação que justifique sua abertura.\nObs: A carta ou documento correlatado deverá ser cadastrada e inserida no sistema Protón.`} defaultValue={processo?.documento_abertura} required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Documento: <span>*</span></TextTitle>
              <InputFilev2 name="processo_arquivo" />
            </Grid>
            <Grid xs={12}>
              <ButtonAddSide type="button" onClick={handlerDocument}><FontAwesomeIcon icon={faPlus} /> Substituir o arquivo</ButtonAddSide>
            </Grid>
            {documentoProcesso?.id && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleDocumento}
                  rowsData={[documentoProcesso]}
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Observação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Observação:</TextTitle>
              <Textarea name="observacao" placeholder={`Esse campo deverá ser preenchido caso haja alguma observação relevante que deva constar na Ficha de Abertura do Processo.\nEx: Número de um processo que deu origem à abertura desse novo processo.`} defaultValue={processo?.observacao} />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/processos">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress form={"formProcesso"} disabled={salvando} ></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default ProcessoEdit;
