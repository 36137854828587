import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  ButtonAdd,
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Textarea,
  Title,
} from "../../../styles/global";
import Panel from "../../../components/Panel";
import { Button, Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { SaveWithProgress } from "../../../components/SaveCircularIntegration";
import useJuridico from "../../../actions/cruds/Juridico/useJuridico";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import useHistoricoProcessoJuridico from "../../../actions/cruds/Juridico/useHistoricoProcessoJuridico";
import { formatarData } from "../../../utils/formatDados";

const JuridicoHistoricoProcesso: React.FC = () => {
  const { processoJuridicoId } = useParams();
  const { processoJuridico, getProcessoJuridico } = useJuridico();
  const { createHistoricoProcessoJuridico, deleteHistoricoProcessoJuridico } = useHistoricoProcessoJuridico();

  const [salvando, setSalvando] = useState<boolean>(false);
  const [historicoProcessoList, setHistoricoProcessoList] = useState<Array<Object>>([]);
  const [historicoProcessoUpdate, setHistoricoProcessoUpdate] = useState<Array<Object>>([]);
  const [historicoProcessoDelete, setHistoricoProcessoDelete] = useState<Array<Object>>([]);

  const history = useHistory();

  useEffect(() => {
    getProcessoJuridico(processoJuridicoId);

  }, []);

  useEffect(() => {
    if (processoJuridico?.id) {
      if (processoJuridico?.judicial_process_histories) {
        setHistoricoProcessoList(organizeDataHistorico(processoJuridico?.judicial_process_histories));
      };
    };
  }, [processoJuridico])

  const organizeDataHistorico = (data: any) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        data: formatarData(d.data, false),
        judicial_process_id: d.judicial_process_id,
        observacao: d.observacao,
        new: false,
      }));
    };
    return data;
  };

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true)

        if (historicoProcessoUpdate?.length > 0) {
          await Promise.all(historicoProcessoUpdate.map(async (historico) => {
            try {
              await createHistoricoProcessoJuridico(historico);
            } catch (error) {
              throw error;
            }
          }));
        };

        if (historicoProcessoDelete?.length > 0) {
          await Promise.all(historicoProcessoDelete.map(async (historico) => {
            try {
              await deleteHistoricoProcessoJuridico(historico.id);
            } catch (error) {
              throw error;
            }
          }));
        };
        alert("Histórico do Processo Jurídico salvo com sucesso!");
        history.push("/juridico");

      } catch (err) {
        throw err;
      } finally {
        setSalvando(false);
      }

    }, [historicoProcessoUpdate, historicoProcessoDelete]
  );

  const handlerAdicionarHistorico = () => {
    const id = Date.now()
    const data = formRef.current?.getFieldValue("dataHistorico");
    const observacao = formRef.current?.getFieldValue("descricaoHistorico");

    if (!data) {
      alert("Adicione a data!");
      return;
    } else if (!observacao) {
      alert("Adicione a descrição!");
      return;
    };

    setHistoricoProcessoList([...historicoProcessoList,
    { id: id, judicial_process_id: processoJuridicoId, data: formatarData(data, false), observacao: observacao, new: true }
    ]);
    setHistoricoProcessoUpdate([...historicoProcessoUpdate,
    { id: id, judicial_process_id: processoJuridicoId, data: data, observacao: observacao, new: true }
    ]);
  };

  const dataTitleHistorico: GridColDef[] = [
    { headerName: "Data", field: "data", flex: 1 },
    { headerName: "Descrição", field: "observacao", flex: 8 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="text"
            onClick={(e) => onDeleteHistorico(e, params.row)}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash-can" />
          </Button>
        );
      },
    },
  ];

  function onDeleteHistorico(e: any, row: any): void {
    console.log(row);
    setHistoricoProcessoList(historicoProcessoList?.filter((el) => el.id !== row.id));
    setHistoricoProcessoUpdate(historicoProcessoUpdate?.filter((el) => el.id !== row.id));
    if (row.new == false) {
      console.log(false)
      setHistoricoProcessoDelete([...historicoProcessoDelete, row]);
    };
  };

  return (
    <Container>
      <Form id="formJuridico" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Histórico do Processo jurídico</span>
        </Title>

        <Panel titulo="Histórico do processo">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data:</TextTitle>
              <Input
                type="date"
                name="dataHistorico"
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Descrição:</TextTitle>
              <Textarea
                name="descricaoHistorico"
              />
            </Grid>
          </Grid>
          <Grid xs={6}>
            <ButtonAdd type="button" onClick={handlerAdicionarHistorico}>
              <FontAwesomeIcon icon={faPlus} /> Adicionar
            </ButtonAdd>
          </Grid>
          <Grid xs={12}>
            {historicoProcessoList?.length > 0 && (
              <SecondaryTable
                columsData={dataTitleHistorico}
                rowsData={historicoProcessoList}
              />
            )}
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/juridico">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formJuridico"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
  );
};

export default JuridicoHistoricoProcesso;
