import {
  useEffect,
  useRef,
  useState
} from "react";

import Header from "../../../../components/Header";
import { CSidebar } from "../../../../components/Sidebar";

import { FormHandles } from "@unform/core";

import {
  faChevronLeft, faDownload,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "../../../../components/Panel";

import { Link, useParams } from "react-router-dom";
import useAditivoInstrumento from "../../../../actions/cruds/InstrumentoContratual/useAditivo.ts";
import {
  ButtonDownloadArch,
  ContainerButton,
  Form,
  FormContainer,
  Input,
  Instancia,
  TextTitle,
  Title
} from "../../../../styles/global";
import Radio from "../../../../components/InputRadio";
import useInstrumento from "../../../../actions/cruds/InstrumentoContratual/useInstrumento";
import SecondaryTable from "../../../../components/SecondaryTable";
import { Button, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import InputFilev2 from "../../../../components/InputFilev2/index.tsx";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import { formatarReais } from "../../../../utils/formatDados";

interface Aditivo {
  id: string;
  nome: string;
  ativo: boolean;
  aditivo_valor: boolean;
  aditivo_prazo: boolean;
  aditivo_outros: boolean;
  contractual_instrument: {
    id: string;
    numero: string;
    ativo: boolean;
    excluido: boolean;
  },
  descricao: string;
  data_assinatura: string;
  data_inicio_vigencia: string;
  data_termino_vigencia: string;
  motivo_valor: string;
  valor: string;
  mesma_dotacao: string;
  pagamento_rateado: string;
  aditivo_descricao: string;
  aditivo_arquivo: File;
  url_aditivo_arquivo: string;
  publicacao_descricao: string;
  publicacao_data: string,
  publicacao_arquivo: File;
  url_publicacao_arquivo: string;
  excluido: boolean;
  // created_at: string;
  // updated_at: string;

}

const InstrumentoAditivoView: React.FC = () => {

  const { aditivoInstrumento, getAditivoInstrumento } = useAditivoInstrumento();
  const { instrumento, getInstrumento } = useInstrumento();

  const [tipoValor, setTipoValor] = useState<Boolean>();
  const [tipoPrazo, setTipoPrazo] = useState<Boolean>();
  const [tipoOutros, setTipoOutros] = useState<Boolean>();
  const [organizeDataAditivo, setOrganizeDataAditivo] = useState<Object>([{ id: '0' }]);
  const [organizeDataExtrato, setOrganizeDataExtrato] = useState<Object>([{ id: '0' }]);
  const [valueTipoDespesa, setValueTipoDespesa] = useState<Object>();
  const [valueOrganizacao, setValueOrganizacao] = useState<Object>();
  const [valueContrato, setValueContrato] = useState<Object>();
  const [valueComite, setValueComite] = useState<Object>();
  const [valueUnidade, setValueUnidade] = useState<Object>();
  const [valueFinalidade, setValueFinalidade] = useState<Object>();
  const [valuePrograma, setValuePrograma] = useState<Object>();
  const [valueAcao, setValueAcao] = useState<Object>();
  const [valueSubacao, setValueSubacao] = useState<Object>();
  const [valueProjeto, setValueProjeto] = useState<Object>();
  const [valueDescricao, setValueDescricao] = useState<Object>();
  const [valueCodigoContabil, setValueCodigoContabil] = useState<Array<Object>>([]);


  const additiveId = useParams();
  const idAditivo = additiveId.idAditivo;

  useEffect(() => {
    getAditivoInstrumento(idAditivo);

  }, [])

  useEffect(() => {
    if (aditivoInstrumento.id) {
      getInstrumento(aditivoInstrumento.contractual_instrument.id);
      setTipoValor(aditivoInstrumento.aditivo_valor);
      setTipoPrazo(aditivoInstrumento.aditivo_prazo);
      setTipoOutros(aditivoInstrumento.aditivo_outros);

      if (aditivoInstrumento.motivo_valor) {
        const consorcioMotivoRadio = formRef.current?.getFieldRef("motivo_valor");
        if (typeof consorcioMotivoRadio == 'object' && aditivoInstrumento.motivo_valor == 'acrescimo') {
          consorcioMotivoRadio[0].checked = true;
        } else if (typeof consorcioMotivoRadio == 'object' && aditivoInstrumento.motivo_valor == 'supressao') {
          consorcioMotivoRadio[1].checked = true;
        }
      };

      if (aditivoInstrumento.mesma_dotacao != null) {
        const consorcioDotacaoRadio = formRef.current?.getFieldRef("mesma_dotacao");
        if (typeof consorcioDotacaoRadio == 'object' && aditivoInstrumento.mesma_dotacao == true) {
          consorcioDotacaoRadio[0].checked = true;
        } else if (typeof consorcioDotacaoRadio == 'object' && aditivoInstrumento.mesma_dotacao == false) {
          consorcioDotacaoRadio[1].checked = true;
        }
      };

      if (aditivoInstrumento.pagamento_rateado != null) {
        const consorcioRateadoRadio = formRef.current?.getFieldRef("pagamento_rateado");
        if (typeof consorcioRateadoRadio == 'object' && aditivoInstrumento.pagamento_rateado == true) {
          consorcioRateadoRadio[0].checked = true;
        } else if (typeof consorcioRateadoRadio == 'object' && aditivoInstrumento.pagamento_rateado == false) {
          consorcioRateadoRadio[1].checked = true;
        }
      };

      setOrganizeDataAditivo([
        {
          id: aditivoInstrumento.id,
          aditivo_descricao: aditivoInstrumento.aditivo_descricao,
          arquivo: `${aditivoInstrumento.url_aditivo_arquivo}/${aditivoInstrumento.aditivo_arquivo}`
        }
      ]);

      setOrganizeDataExtrato([
        {
          id: aditivoInstrumento.id,
          publicacao_descricao: aditivoInstrumento.publicacao_descricao,
          publicacao_data: aditivoInstrumento.publicacao_data,
          arquivo: `${aditivoInstrumento.url_publicacao_arquivo}/${aditivoInstrumento.publicacao_arquivo}`
        }
      ]);

      // if (aditivoInstrumento.additive_budgets != undefined) {
      //   const aditivoAccountingCode = aditivoInstrumento.additive_budgets[0].accounting_code;
      //   setValueTipoDespesa(aditivoAccountingCode.expense_type.nome);
      //   setValueOrganizacao(aditivoAccountingCode.organization.nome);
      //   setValueContrato(aditivoAccountingCode.management_contract.nome);
      //   setValueComite(aditivoAccountingCode.comittee.nome);
      //   setValueUnidade(aditivoAccountingCode.unit.nome);
      //   setValueFinalidade(aditivoAccountingCode.finality.nome);
      //   setValuePrograma(aditivoAccountingCode.program.nome);
      //   setValueAcao(aditivoAccountingCode.tbl_action.nome);
      //   setValueSubacao(aditivoAccountingCode.sub_action.nome);
      //   setValueProjeto(aditivoAccountingCode.project.nome);
      //   setValueDescricao(aditivoAccountingCode.description.nome);
      //   setValueCodigoContabil(aditivoAccountingCode.codigo_contabil);
      // };

    };

  }, [aditivoInstrumento])

  const formRef = useRef<FormHandles>(null);

  const dataTitleRubrica: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    { 
      field: "valor",
      headerName: "Valor (R$)",
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
            return "Defina um valor!";
        }
        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      }
    },
    {
      field: "percentual",
      headerName: "Percentual (%)",
      flex: 1,
      valueGetter: (params) => {
        return ((params.row.valor / instrumento?.valor_total) * 100).toFixed(2);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    }
  ];

  const organizeData = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.accounting_code.id,
          contrato: d.accounting_code.management_contract.nome_completo,
          comite: d.accounting_code.comittee.nome,
          codigo_contabil: `${d.accounting_code.expense_type.codigo}.${d.accounting_code.organization.codigo.padStart(2, '0')}.${d.accounting_code.management_contract.codigo.padStart(2, '0')}.${d.accounting_code.comittee.codigo.padStart(2, '0')}.${d.accounting_code.unit.codigo.padStart(2, '0')}.${d.accounting_code.pap_finality.codigo.padStart(2, '0')}.${d.accounting_code.pap_program.codigo.padStart(2, '0')}.${d.accounting_code.pap_action.codigo.padStart(2, '0')}.${d.accounting_code.poa_sub_action.codigo.padStart(2, '0')}.${d.accounting_code.project?.id.toString().padStart(2, '0')}.${d.accounting_code.description.codigo.padStart(3, '0')}`,
          valor: d.valor
        }));
        return data;
      }
    }
    return [];
  };

  return (
    <Container>
      <Title color='#333'>
        <span>
          Visualização do Aditivo
        </span>
      </Title>

      <Form ref={formRef}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Nome do aditivo: <span>*</span></TextTitle>
              <Input
                type="text"
                name="nome"
                disabled
                value={aditivoInstrumento.nome}
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Selecione o tipo de aditivo: <span>*</span></TextTitle>
              <Instancia>
                <div>
                  <input
                    type="checkbox"
                    name="checkvalor"
                    id="checkvalor"
                    disabled
                    defaultChecked={aditivoInstrumento.aditivo_valor}
                  /> Valor
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkprazo"
                    id="checkprazo"
                    disabled
                    defaultChecked={aditivoInstrumento.aditivo_prazo}
                  /> Prazo
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkoutros"
                    id="checkoutros"
                    disabled
                    defaultChecked={aditivoInstrumento.aditivo_outros}
                  /> Outros
                </div>
              </Instancia>
            </Grid>
            <Grid xs={12}>
              <TextTitle>Descrição: <span>*</span></TextTitle>
              <Input
                type="text"
                name="descricao"
                disabled
                value={aditivoInstrumento.descricao}
              />
            </Grid>
          </Grid>
        </Panel>

        {tipoPrazo ?
          <Panel titulo="Prazo">
            <Grid container spacing={6}>
              <Grid xs={6}>
                <TextTitle>Data de assinatura do termo aditivo: <span>*</span></TextTitle>
                <Input
                  type="date"
                  name="data_assinatura"
                  disabled
                  value={aditivoInstrumento.data_assinatura}
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Data do início da vigência / ordem de serviço:</TextTitle>
                <Input
                  type="date"
                  name="data_inicio_vigencia"
                  disabled
                  value={aditivoInstrumento.data_inicio_vigencia}
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Nova data do término da vigência:</TextTitle>
                <Input
                  type="date"
                  name="data_termino_vigencia"
                  disabled
                  value={aditivoInstrumento.data_termino_vigencia}
                />
              </Grid>
            </Grid>
          </Panel>
          : null}

        {tipoValor ?
          <Panel titulo="Valor">
            <Grid container spacing={6}>
              <Grid xs={12}>
                <TextTitle>Selecione o motivo do valor: <span>*</span></TextTitle>
                <Radio
                  name='motivo_valor'
                  options={[
                    { id: "motivoSim", value: "acrescimo", label: "Acréscimo" },
                    { id: "motivoNao", value: "supressao", label: "Supressão" }
                  ]}
                  disabled
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Informe o valor a ser adicionado/suprimido: <span>*</span></TextTitle>
                <Input
                  type="text"
                  name="valorAdicao"
                  value={aditivoInstrumento.valor}
                  disabled
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Novo valor do contrato: <span>*</span></TextTitle>
                <Input
                  type="text"
                  name="novoValorContrato"
                  value={aditivoInstrumento.novo_valor_contrato}
                  disabled
                />
              </Grid>
            </Grid>
          </Panel>
          : null}

        {tipoValor || tipoOutros ?
          <Panel titulo="Rubrica orçamentária">
            <Grid container spacing={6}>
              <Grid xs={6}>
                <TextTitle>A dotação é a mesma do contrato?: <span>*</span></TextTitle>
                <Radio
                  name='mesma_dotacao'
                  options={[
                    { id: "dotacaoSim", value: "true", label: "Sim" },
                    { id: "dotacaoNao", value: "false", label: "Não" }
                  ]}
                  disabled
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Valor do termo aditivo:</TextTitle>
                <span>R${aditivoInstrumento.valor}</span>
              </Grid>
              <Grid xs={12}>
                <TextTitle>Os pagamentos serão rateados? <span>*</span></TextTitle>
                <Radio
                  name='pagamento_rateado'
                  options={[
                    { id: "rateadoSim", value: "true", label: "Sim" },
                    { id: "rateadoNao", value: "false", label: "Não" }
                  ]}
                  disabled
                />
              </Grid>
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleRubrica}
                  rowsData={organizeData(aditivoInstrumento.additive_budgets)}
                />
              </Grid>
              <Grid xs={6} style={{marginTop: "20px"}}>
                <TextTitle>Total: {formatarReais(organizeData(aditivoInstrumento.additive_budgets)?.reduce(function (acc, obj) { return acc + parseFloat(obj?.valor); }, 0))}</TextTitle>
              </Grid>
            </Grid>
          </Panel>
          : null}

        <Panel titulo="Aditivo">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Descrição: </TextTitle>
              <Input type="text" name="aditivo_descricao" value={aditivoInstrumento.aditivo_descricao} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Aditivo: </TextTitle>
              <Link
                to={{
                  pathname: `${aditivoInstrumento?.url_aditivo_arquivo}/${aditivoInstrumento?.aditivo_arquivo}`,
                }}
                target="_blank"
              >
                <ButtonDownloadArch name='selecionararquivo' type="button">
                  <FontAwesomeIcon icon={faDownload} size="1x" /> Download...
                </ButtonDownloadArch>
              </Link>
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Extrato de publicação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data da Publicação:</TextTitle>
              <Input type="date" name="publicacao_data" value={aditivoInstrumento.publicacao_data} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Descrição: <span>*</span></TextTitle>
              <Input type="text" name="publicacao_descricao" value={aditivoInstrumento.publicacao_descricao} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Extrato:</TextTitle>
              <Link
                to={{
                  pathname: `${aditivoInstrumento?.url_publicacao_arquivo}/${aditivoInstrumento?.publicacao_arquivo}`,
                }}
                target="_blank"
              >
                <ButtonDownloadArch name='selecionararquivo' type="button">
                  <FontAwesomeIcon icon={faDownload} size="1x" /> Download...
                </ButtonDownloadArch>
              </Link>
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/instrumentos">
          <button><FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar</button>
        </Link>
      </ContainerButton>
    </Container>
  )
};

export default InstrumentoAditivoView;