import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Step, StepLabel, Stepper } from '@mui/material';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ButtonAdd, ContainerButton, ContainerButton2, CreatableSelectInput, Input, SelectInput, TextTitle, Title } from '../../../../../styles/global';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import useCargo from '../../../../../actions/cruds/useCargo';
import { FormHandles } from '@unform/core';
import organizeDataSelect from '../../../../../utils/organizeDataSelect';
import useUsuario from '../../../../../actions/cruds/useUsuario';
import Panel from '../../../../../components/Panel';
import { steps } from '../..';
import SecondaryTable from '../../../../../components/SecondaryTable';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import usePap from '../../../../../actions/cruds/usePap';
import usePapAcao from '../../../../../actions/cruds/usePapAcao';
import { useToast } from '../../../../../hooks/toast';

// import { Container } from './styles';


const PapCreateAcao: React.FC = () => {

    const {idPap} = useParams();

    const {getPap, selectedPap, updatePap} = usePap();
    const { getPapAcoes, papAcoes, getPapAcao, papAcao, setPapAcao, createPapAcao, newPapAcao, updatePapAcao } = usePapAcao();
    const { addToast } = useToast();

    const [programaAtual, setProgramaAtual] = useState([]);
    const [newAcao, setNewAcao] = useState(true);
    const [finaldadeAutomatica, setFinalidadeAutomatica] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        getPap(idPap);
        getPapAcoes();
    }, []);

    useEffect(() => {
        if (newPapAcao.id) {
            updatePap(idPap, {acao_ids: [...selectedPap.acoes.map(f => f.id), newPapAcao.id]});
        }
    }, [newPapAcao]);

    useEffect(() => {
        const {pap_program} = papAcao;
        if (pap_program) {
            setFinalidadeAutomatica(
                [
                    {
                        label: `${papAcao.pap_program.pap_finality.codigo} - ${papAcao.pap_program.pap_finality.nome}`,
                        value: papAcao.pap_program.pap_finality.id
                    }
                ]
                
            );
            setProgramaAtual(organizeDataSelect([pap_program], "id", "codigo", ["nome"]));
            
        }
    }, [papAcao]);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => { 
        try {
            // console.log(data);
            
            if (newAcao) {
                createPapAcao(data);
            } else {
                updatePap(idPap, {acao_ids: [...selectedPap.acoes.map(f => f.id), papAcao.id]});
            }

            // addToast({
            //   type: 'success',
            //   title: 'Login efetuado',
            //   description: 'Seu login foi feito com sucesso!',
            // });

            // history.push('/dashboard');
        } catch (err) {    
            // addToast({
            //   type: 'error',
            //   title: 'Erro na autenticação',
            //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
            // });
        }
        },
        [papAcao],
    );

    const form2Ref = useRef<FormHandles>(null);

    const handleSubmit2 = useCallback(
        async (data: object) => { 
            try {
                const acaoId = formRef.current?.getFieldValue("nome");

                await updatePapAcao(acaoId, data);

                getPap(idPap);
                getPapAcoes();

                formRef.current?.clearField("nome");
                setOpen(false);

            } catch (err) {}
        },
        [addToast, open],
    );

    const [activeStep] = useState(3);


    const dataTitle: GridColDef[] = [
        { field: "finalidade", headerName: "Finalidade", flex: 1  },
        { field: "programa", headerName: "Programa", flex: 1  },
        { field: "acao", headerName: "Ação", flex: 3  },
        {
            field: "actions",
            type: 'actions',
            headerName: "Ações",
            description: "Ações por coluna.",
            sortable: false,
            flex: 1,
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                      icon={<FontAwesomeIcon icon="fa-solid fa-trash-can" />}
                      label="Delete"
                      onClick={() => handleDeleteClick(id)}
                      color="inherit"
                      sx={{color: "#e62121"}}
                    />
                ];
            }
        }
      ];

      function handleDeleteClick(id: GridRowId): React.MouseEventHandler<HTMLButtonElement> | undefined {
        updatePap(idPap, {acao_ids: [...selectedPap.acoes.filter(f => f.id !== id).map(f => f.id)]});
    }

      const organizeActionsOptions = () => {

        const {programas} = selectedPap;
        // console.log(programas);
        if (selectedPap.id && papAcoes.data) { // So retorna as acoes para SELECIONAR que tem relação com os programas disponiveis (previamente cadastradas)
            const filtredProgramas = programas.map(i => i.id);
            return organizeDataSelect(papAcoes.data.filter(e => filtredProgramas.includes(e.pap_program.id)), "id", "codigo", ["nome"]) || [];
        }
        
        // return organizeDataSelect(papProgramas.data, "id", "nome") || [];
    }

    const changeAcao = (acaoSelected: object) => {  
        // console.log(acaoSelected);
        if (acaoSelected !== null) {
            if (acaoSelected && acaoSelected.__isNew__) {
                setNewAcao(true);
                setPapAcao({});
                
            } else {
                formRef.current?.clearField("pap_finality_id");
                formRef.current?.clearField("pap_program_id");
                formRef.current?.clearField("codigo");
                setNewAcao(false);
                getPapAcao(acaoSelected.value);
            }
        }
    }

    const organizeData = (data: any[]) => {
        if (typeof data === 'object') {
          data = data.map(d => ({
            id: d.id,
            finalidade: `${d.pap_program.pap_finality.codigo} - ${d.pap_program.pap_finality.nome}`,
            programa: `${d.pap_program.codigo} - ${d.pap_program.nome}`,
            acao: `${d.codigo} - ${d.nome}`
          }));
        }
        return data;
    }

    return (
        <Container>
            <Title>
                <span>PAP: Gerenciamento de Ações</span>
            </Title>

            <Stepper activeStep={activeStep} alternativeLabel sx={{my: 5}}>
                {steps.map(({label}, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Ação'>
                    <Grid container spacing={6}>
                        <Grid xs={12}>
                            <TextTitle>Finalidade:</TextTitle>
                            <SelectInput
                                id="pap_finality_id" 
                                name="pap_finality_id"
                                options={selectedPap ?
                                    organizeDataSelect(selectedPap.finalidades.filter((d) => d.ativo == true), "id", "codigo", ["nome"])
                                    : []}
                                placeholder="Apenas para consulta"
                                value={finaldadeAutomatica}
                                // onInputChange={e => { getTiposEmpregados([{label: 'nome', value: e}]) }}
                                isDisabled
                            />
                        </Grid>
                        <Grid xs={12}>
                            <TextTitle>Programa: <span>*</span></TextTitle>
                            <SelectInput
                                required
                                id="pap_program_id" 
                                name="pap_program_id"
                                isDisabled={!newAcao}
                                value={programaAtual}
                                options={selectedPap ?
                                    organizeDataSelect(selectedPap.programas.filter((p) => p.ativo == true), "id", "codigo", ["nome"])
                                    : []}
                                placeholder="Selecione"
                                onChange={v => {
                                                    setProgramaAtual(v);
                                                    if (v) {
                                                        setFinalidadeAutomatica(
                                                            selectedPap.programas.filter(p => p.id == v.value).map(m => {
                                                                return {
                                                                    label: m.pap_finality.nome, value: m.pap_finality.id
                                                                };
                                                            })
                                                        );
                                                    }
                                                }  
                                }
                            />
                        </Grid>

                        <Grid xs={12} lg={2}>
                            <TextTitle>Código: <span>*</span></TextTitle>
                            <Input 
                                mask="__"
                                replacement="allNumber"
                                id="codigo" 
                                name='codigo' 
                                required
                                defaultValue={papAcao.codigo ?? ""}
                                disabled={!newAcao}
                            />
                        </Grid>
                        <Grid xs={12} lg={10}>
                            <TextTitle>Ação: <span>*</span></TextTitle>
                            <div style={{display: "flex", alignItems: 'center'}}>
                                <CreatableSelectInput
                                    id="nome"
                                    name="nome"
                                    // options={organizeDataSelect(papProgramas.data.filter(e => e.pap_finality.id == "1"), "id", "nome") || []} // Tenho que comparar os PROGRAMAS pra saber quais deles tem as finalidades previamente cadastrados
                                    options={organizeActionsOptions()}
                                    // options={organizeDataSelect(papProgramas.data, "id", "nome") || []}
                                    placeholder="Selecione ou crie uma nova ação"
                                    onInputChange={(e) => {
                                        getPapAcoes([{ label: "nome", value: e }]);
                                    }}
                                    onChange={changeAcao}
                                    required
                                />
                                {!newAcao && 
                                    <div>
                                        <IconButton color="primary" aria-label="editar" onClick={e => setOpen(true)}>
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </IconButton>
                                    </div>
                                }
                            </div>
                        </Grid>

                        <Grid xs={12}>
                            <Grid xs={12}>
                                <ButtonAdd type="submit" onClick={null}><FontAwesomeIcon icon={faPlus} /> Adicionar</ButtonAdd>
                            </Grid>
                            <SecondaryTable
                                hideFooter={true}
                                rowsData={organizeData(selectedPap.acoes) || []}
                                columsData={dataTitle}
                            />
                        </Grid>
                    </Grid>
                </Panel>

                <ContainerButton2>
                    <Link
                        to={`/orcamentario/pap/programa/${idPap}`}
                        className="button_return"
                        style={{ textDecoration: "none", color: "black" }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} /> Voltar
                    </Link>
                    <div>
                        <Link
                            to={"/orcamentario/pap"}
                            className="button_next"
                            style={{ textDecoration: "none" }}
                        >
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                color="#ffffff"
                                style={{ marginRight: 10 }}
                            />
                                Finalizar
                            </Link>
                    </div>
                </ContainerButton2>
            </Form>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>Editar Ação</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    A edição da Ação por aqui altera também seu vínculo em outros PAPs.
                </DialogContentText>

                <Form id="form2" ref={form2Ref} onSubmit={handleSubmit2} >
                    
                    <Grid container spacing={3} style={{marginTop: "10px"}}>
                        <Grid xs={12}>
                            <TextTitle>Finalidade:</TextTitle>
                            <SelectInput
                                id="pap_finality_id" 
                                name="pap_finality_id"
                                options={selectedPap ?
                                    organizeDataSelect(selectedPap.finalidades.filter((d) => d.ativo == true), "id", "codigo", ["nome"])
                                    : []}
                                placeholder="Apenas para consulta"
                                value={finaldadeAutomatica}
                                // onInputChange={e => { getTiposEmpregados([{label: 'nome', value: e}]) }}
                                isDisabled
                            />
                        </Grid>

                        <Grid xs={12}>
                            <TextTitle>Programa: <span>*</span></TextTitle>
                            <SelectInput
                                required
                                id="pap_program_id" 
                                name="pap_program_id"
                                isDisabled={!newAcao}
                                value={programaAtual}
                                options={selectedPap ?
                                    organizeDataSelect(selectedPap.programas.filter((p) => p.ativo == true), "id", "codigo", ["nome"])
                                    : []}
                                placeholder="Selecione"
                                onChange={v => {
                                                    setProgramaAtual(v);
                                                    if (v) {
                                                        setFinalidadeAutomatica(
                                                            selectedPap.programas.filter(p => p.id == v.value).map(m => {
                                                                return {
                                                                    label: m.pap_finality.nome, value: m.pap_finality.id
                                                                };
                                                            })
                                                        );
                                                    }
                                                }  
                                }
                            />
                        </Grid>

                        <Grid xs={2}>
                            <TextTitle>Código: <span>*</span></TextTitle>
                            <Input 
                                mask="__"
                                replacement="allNumber"
                                id="codigo" 
                                name='codigo' 
                                required
                                defaultValue={papAcao.codigo ?? ""}
                            />
                        </Grid>
                        <Grid xs={10}>
                            <TextTitle>Ação: <span>*</span></TextTitle>
                            <Input
                                name="nome"
                                type="text"
                                
                                defaultValue={papAcao.nome ?? ""}
                                required
                            />
                        </Grid>
                    </Grid>
                    
                </Form>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button type="submit" variant="contained" form="form2">Salvar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default PapCreateAcao;