import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { IDropdownExcluir } from "../components/DropdownMainTable/Dropdowns";
import api from "../services/api";

export const deleteItem = (props: IDropdownExcluir) => {
  const { id, linkTo } = props;

  confirmAlert({
    message: "Deseja realmente excluir o item selecionado?",
    buttons: [
      {
        label: "Excluir",
        onClick: () => handleDeleteItem({ id, linkTo }),
        style: { backgroundColor: "#E65B65" },
      },
      {
        label: "Cancelar",
        style: { backgroundColor: "#366EFA" },
      },
    ],
  });
};

const handleDeleteItem = (props: IDropdownExcluir) => {
  const { id, linkTo } = props;

  api
    .delete(`${linkTo}/${id}`)
    .then(() => window.location.reload())
    .catch(() => alert(`Erro ao excluir o item: ${id}`));
};
