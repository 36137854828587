import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAcao from '../../actions/cruds/useAcao';
import useCodigoContabil from '../../actions/cruds/useCodigoContabil';
import useComite from '../../actions/cruds/useComite';
import useContratoGestao from '../../actions/cruds/useContratoGestao';
import useDemandas from '../../actions/cruds/useDemandas';
import useDescricao from '../../actions/cruds/useDescricao';
import useDespesas from '../../actions/cruds/useDespesas';
import useFinalidades from '../../actions/cruds/useFinalidade';
import useOrgao from '../../actions/cruds/useOrgao';
import usePap from '../../actions/cruds/usePap';
import usePapAcao from '../../actions/cruds/usePapAcao';
import usePapFinalidade from '../../actions/cruds/usePapFinalidade';
import usePapPrograma from '../../actions/cruds/usePapPrograma';
import usePoa from '../../actions/cruds/usePoa';
import usePrograma from '../../actions/cruds/usePrograma';
import useProjeto from '../../actions/cruds/useProjeto';
import useSubAcao from '../../actions/cruds/useSubAcao';
import useUnidade from '../../actions/cruds/useUnidade';
import { useToast } from '../../hooks/toast';
import { ButtonAdd, Form, Input, SelectInput, TextTitle } from '../../styles/global';
import organizeDataSelect from '../../utils/organizeDataSelect';

const ModalCodigoContabil: React.FC = () => {

    const { getProjetos, projetos, createProjeto, getProjetoId, projeto, UpdateProjeto } = useProjeto();
    
    const { despesas, getDespesas } = useDespesas();
    const { orgaos, getOrganizations } = useOrgao();
    const { comites, getComittees, selectComite, getComittee, setComites, setSelectComite } = useComite();
    const { contrGestao, getContratos, setContrGestao } = useContratoGestao();
    const { finalidades, getFinalidades } = useFinalidades();
    const { programas, getProgramas } = usePrograma();
    const { acao, getAcoes } = useAcao();
    const { subacao, getSubAcao } = useSubAcao();
    const { getUnidades, unidades, setUnidades } = useUnidade();
    const { getPapFinalidades, papFinalidades } = usePapFinalidade();
    const { getPapProgramas, papProgramas } = usePapPrograma();
    const { getPapAcoes, papAcoes } = usePapAcao();
    const { getDemandas, demandas, getDemanda, demanda } = useDemandas();
    const { getDescricoes, descricao } = useDescricao();
    const { getPaps, paps, getPap, selectedPap, setPaps, setSelectedPap } = usePap();
    const { getPoas, poas } = usePoa();
    const { createCodigoContabil } = useCodigoContabil();

    const { addToast } = useToast();

    const [yearsPoa, setYearsPoa] = useState([]);
    const [filtredPrograms, setFiltredPrograms] = useState([]);
    const [filtredActions, setFiltredActions] = useState([]);
    const [filtredSubactions, setFiltredSubactions] = useState([]);

    const formRef = useRef<FormHandles>(null);

    useEffect(() => {    
        getDespesas();
        getOrganizations();
        getProjetos();
        getDescricoes();
    }, []);

    useEffect(() => { // quando um PAP for selecionado ele preenche os anos para o POA
        const {periodo_de, periodo_ate} = selectedPap;
    
        const aux = [];
        for (let y: number = periodo_de; y <= periodo_ate; y++) {
            aux.push( {label: y, value: y} );
        }
        setYearsPoa(aux);
        
    }, [selectedPap]);

    useEffect(() => {
        // console.log(poas)
        if (poas.data && poas.data.length) {
          const pap_action_id = formRef.current?.getFieldValue("pap_action_id");
          const filter = poas.data[0].subacoes.filter(p => p.pap_action.id === pap_action_id); // data[0] porque de acordo com as regras de negocio deve existir apenas 1 unico POA vinculado ao mesmo PAP e ao mesmo ANO_POA
          setFiltredSubactions(filter);
        }
    }, [poas]);

    const handleSubmit = useCallback(
        async (data: object) => {
            try {
                // data["project_id"] = idProjeto;
                // data["valor_previsto"] = formatarRealparaDecimal(data.valor_previsto);

                console.log("modal 2", data);
                await createCodigoContabil(data);
                addToast({type: 'success', title: 'Rubrica secundária cadastrada', description: 'Nova Rubrica secundária ja pode ser vinculada.'});
                // await createLinhaProjeto(data);
                // getProjetoId(idProjeto);
            } catch (err) {
                addToast({type: 'error', title: 'Erro', description: 'Verifique os dados e tente novamente'});
            }
        },
    []);

    const onChangeDespesa = (selectedDespesa: any) => {
        getPapFinalidades([{label: "expense_type_id", value: selectedDespesa.value}]);
    }

    const onChangeOrganization = (selectedOrg: any) => {
    formRef.current?.clearField("management_contract_id");
    formRef.current?.clearField()
    setContrGestao([]);
    formRef.current?.clearField("comittee_id");
    setComites([]);
    formRef.current?.clearField("unit_id");
    formRef.current?.clearField("periodo");
    setPaps([]);
    formRef.current?.clearField("pap_finality_id");
    setSelectedPap({});
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    setYearsPoa([]);
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);

    getContratos([{ label: "organization_id", value: selectedOrg.value }]);

    }

    const onChangeContrGestao = (selectedContrGestao: any) => {
    formRef.current?.clearField("comittee_id");
    setComites([]);
    formRef.current?.clearField("unit_id");
    formRef.current?.clearField("periodo");
    setPaps([]);
    formRef.current?.clearField("pap_finality_id");
    setSelectedPap({});
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    setYearsPoa([]);
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);

    if (selectedContrGestao) {
        getComittees([{ label: "management_contract_id", value: selectedContrGestao.value }]);
    }
    }

    const onChangeComite = (selectedComite: any) => {
        const {project_lines, rateio} = projeto;

        console.log(project_lines, !rateio);
        if (project_lines.length && !rateio && selectedComite) { // Se tiver ao menos UMA linha
            if (project_lines[0].comittee.id !== selectedComite.value) {
            alert("O Projeto não é rateado! Só é possível adicionar mais linhas se for do mesmo Comitê.");
            // formRef.current?.clearField("comittee_id");
            }
        }

        formRef.current?.clearField("unit_id");
        setSelectComite({
            management_contract: {
                organization: {
                    nome: {}
                },
                management_entity: {}
            },
            units: []
        });
        formRef.current?.clearField("periodo");
        setPaps([]);
        formRef.current?.clearField("pap_finality_id");
        setSelectedPap({});
        formRef.current?.clearField("pap_program_id");
        setFiltredPrograms([]);
        formRef.current?.clearField("pap_action_id");
        setFiltredActions([]);
        formRef.current?.clearField("ano");
        setYearsPoa([]);
        formRef.current?.clearField("poa_sub_action_id");
        setFiltredSubactions([]);

        if (selectedComite) {
            getComittee(selectedComite.value);
            getPaps([{label: "comittee_id", value: selectedComite.value}]);
        }
    }

    const onChangePeriodo = (selectedPeriodo: any) => {
        formRef.current?.clearField("pap_finality_id");
        setSelectedPap({});
        formRef.current?.clearField("pap_program_id");
        setFiltredPrograms([]);
        formRef.current?.clearField("pap_action_id");
        setFiltredActions([]);
        formRef.current?.clearField("ano");
        setYearsPoa([]);
        formRef.current?.clearField("poa_sub_action_id");
        setFiltredSubactions([]);
        if (selectedPeriodo) {
            // getPapFinalidades([{label: "expense_type_id", value: selectedDespesa.value}]);
            getPap(selectedPeriodo.value);
        }
    }

    const onChangeFinalidade = (selectedFinalidade: any) => {
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);
    if (selectedFinalidade) {
        const filter = selectedPap.programas.filter(p => p.pap_finality.id === selectedFinalidade.value);
        setFiltredPrograms(filter);
    }
    
    }

    const onChangePrograma = (selectedPrograma: any) => {
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);
    if (selectedPrograma) {
        const filter = selectedPap.acoes.filter(p => p.pap_program.id === selectedPrograma.value);
        setFiltredActions(filter);
    }
    
    }

    const onChangeAcao = (selectedAcao: any) => {
        formRef.current?.clearField("ano");
        formRef.current?.clearField("poa_sub_action_id");
            setFiltredSubactions([]);
    }

    const onChangeAno = (selectedAno: any) => {
        formRef.current?.clearField("poa_sub_action_id");
        setFiltredSubactions([]);

        const pap_id = formRef.current?.getFieldValue("periodo");

        if (selectedAno) {
            getPoas([
                {label: "pap_id", value: pap_id},
                {label: "poa", value: selectedAno.value}
            ]);
        }
    }

    return (
        <Container>
            <Form id="projectForm" ref={formRef} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid xs={6}>
                        <TextTitle>Despesas: </TextTitle>
                        <SelectInput
                            id='expense_type_id'
                            name='expense_type_id'
                            options={organizeDataSelect(despesas.data, "id", "nome")}
                            onInputChange={d => { getDespesas([{ label: 'nome', value: d }]) }}
                            onChange={onChangeDespesa}
                            placeholder="Selecionar"
                        />
                    </Grid>

                    <Grid xs={12} lg={6}>
                        <TextTitle>Organização: <span>*</span></TextTitle>
                        <SelectInput
                            id='organization_id'
                            name='organization_id'
                            // value={selectedOrg}
                            options={organizeDataSelect(orgaos.data, "id", "nome")}
                            onInputChange={d => { getOrganizations([{ label: 'nome', value: d }]) }}
                            onChange={onChangeOrganization}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12} lg={6}>
                        <TextTitle>Contrato de gestão: <span>*</span></TextTitle>
                        <SelectInput
                            id='management_contract_id'
                            name='management_contract_id'
                            options={organizeDataSelect(contrGestao.data, "id", "nome")}
                            // onInputChange={d => { getContratos([{label: 'nome', value: d}]) } }
                            onChange={onChangeContrGestao}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>

                    <Grid xs={12} lg={6}>
                        <TextTitle>Comitê: <span>*</span></TextTitle>
                        <SelectInput
                            id='comittee_id'
                            name='comittee_id'
                            options={organizeDataSelect(comites.data, "id", "nome")}
                            // onInputChange={d => { getComittees([{label: 'nome', value: d}]) } }
                            onChange={onChangeComite}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12} lg={6}>
                        <TextTitle>Unidade: <span>*</span> <small>(De acordo com o comitê)</small></TextTitle>
                        <SelectInput
                            id='unit_id'
                            name='unit_id'
                            options={organizeDataSelect(selectComite.units, "id", "nome")}
                            // onInputChange={d => { getUnidades([{label: 'nome', value: d}]) } }
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>

                    <Grid xs={6}>
                        <TextTitle>Período: <span>*</span> <small>(De acordo com o comitê)</small></TextTitle>
                        <SelectInput
                            id='periodo'
                            name='periodo'
                            options={organizeDataSelect(paps.data, "id", "periodo_de", ["periodo_ate"])}
                            onChange={onChangePeriodo}
                            // onInputChange={d => { getUnidades([{label: 'nome', value: d}]) } }
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>

                    <Grid xs={12}>
                        <TextTitle>Finalidade: <span>*</span></TextTitle>
                        <SelectInput
                            id='pap_finality_id'
                            name='pap_finality_id'
                            options={organizeDataSelect(selectedPap.finalidades, "id", "nome")}
                            onChange={onChangeFinalidade}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextTitle>Programa: <span>*</span></TextTitle>
                        <SelectInput
                            id='pap_program_id'
                            name='pap_program_id'
                            options={organizeDataSelect(filtredPrograms, "id", "nome")}
                            onChange={onChangePrograma}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextTitle>Ação: <span>*</span></TextTitle>
                        <SelectInput
                            id='pap_action_id'
                            name='pap_action_id'
                            options={organizeDataSelect(filtredActions, "id", "nome")}
                            onChange={onChangeAcao}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>

                    <Grid xs={6}>
                        <TextTitle>Ano: <span>*</span> <small>(Com base no Período)</small></TextTitle>
                        <SelectInput
                            id="ano"
                            name="ano"
                            options={yearsPoa || []}
                            onChange={onChangeAno}
                            placeholder="Selecione"
                            required
                        />
                    </Grid>

                    <Grid xs={12}>
                        <TextTitle>Sub-ação: <span>*</span> <small>(Com base no Ano e Ação)</small></TextTitle>
                        <SelectInput
                            id='poa_sub_action_id'
                            name='poa_sub_action_id'
                            options={organizeDataSelect(filtredSubactions, "id", "nome")}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextTitle>Projeto: </TextTitle>
                        <SelectInput
                            id='project_id'
                            name='project_id'
                            options={organizeDataSelect(projetos.data, "id", "nome")}
                            onInputChange={d => { getProjetos([{ label: "nome", value: d }]) }}
                            placeholder="Selecionar"
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextTitle>Descrição: </TextTitle>
                        <SelectInput
                            id='description_id'
                            name='description_id'
                            options={organizeDataSelect(descricao.data, "id", "nome")}
                            onInputChange={d => { getDescricoes([{ label: "nome", value: d }]) }}
                            placeholder="Selecionar"
                        />
                    </Grid>

                    <Grid xs={12}>
                        <ButtonAdd 
                            id='adicionar' 
                            name='adicionar' 
                            type="submit" 
                            // onClick={handleAdicionarLinha}
                        >
                            <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                        </ButtonAdd>
                    </Grid>
                </Grid>
            </Form>
        </Container>
    )
};

export default ModalCodigoContabil;