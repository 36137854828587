import {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { FormHandles } from "@unform/core";
import {
  faCloudArrowUp,
  faDownload,
  faPlus,
  faSave, faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "../../../../components/Panel";
import { Link, useParams } from "react-router-dom";
import useAditivoInstrumento from "../../../../actions/cruds/InstrumentoContratual/useAditivo.ts";
import {
  ButtonAdd,
  ButtonUploadArch,
  ContainerButton,
  ContainerButton2,
  Form,
  FormContainer,
  Input,
  InputButtonDownload,
  Instancia,
  SelectInput,
  TextTitle,
  Title
} from "../../../../styles/global";
import Radio from "../../../../components/InputRadio";
import useInstrumento from "../../../../actions/cruds/InstrumentoContratual/useInstrumento";
import SecondaryTable from "../../../../components/SecondaryTable";
import { Button, Container } from "@mui/material";
import InputFile from "../../../../components/InputFile";
import useAcao from "../../../../actions/cruds/useAcao.ts";
import useComite from "../../../../actions/cruds/useComite";
import useContratoGestao from "../../../../actions/cruds/useContratoGestao";
import useDespesas from "../../../../actions/cruds/useDespesas";
import useFinalidades from "../../../../actions/cruds/useFinalidade";
import useOrgao from "../../../../actions/cruds/useOrgao";
import usePrograma from "../../../../actions/cruds/usePrograma";
import useSubAcao from "../../../../actions/cruds/useSubAcao";
import useProjeto from "../../../../actions/cruds/useProjeto";
import useUnidade from "../../../../actions/cruds/useUnidade";
import useDescricao from "../../../../actions/cruds/useDescricao";
import useCodigoContabil from "../../../../actions/cruds/useCodigoContabil";
import Grid from "@mui/material/Unstable_Grid2";
import InputFilev2 from "../../../../components/InputFilev2/index.tsx";
import { SaveWithProgress } from "../../../../components/SaveCircularIntegration/index.tsx";
import ModalCodigoContabil from "../../../../template_parts/ModalCodigoContabil";
import BasicDialog from "../../../../components/Dialog";
import { GridActionsCellItem, GridColDef, GridRenderEditCellParams, GridValueFormatterParams, useGridApiRef } from "@mui/x-data-grid";
import InputNumberFormat from "../../../../components/InputNumberFormat";
import organizeDataSelect from "../../../../utils/organizeDataSelect";
import usePapFinalidade from "../../../../actions/cruds/usePapFinalidade";
import usePap from "../../../../actions/cruds/usePap";
import usePoa from "../../../../actions/cruds/usePoa";
import { formatarReais } from "../../../../utils/formatDados";

interface AditivoProps {
  contractual_instrument_id: string,
  nome: string,
  aditivo_valor: boolean,
  aditivo_prazo: boolean,
  aditivo_outros: boolean,
  descricao: string,
  data_assinatura: string,
  data_inicio_vigencia: string,
  data_termino_vigencia: string,
  motivo_valor: string,
  valor: number,
  mesma_dotacao: boolean,
  pagamento_rateado: boolean,
  aditivo_descricao: string,
  aditivo_arquivo: File,
  publicacao_descricao: string,
  publicacao_data: string,
  publicacao_arquivo: File,
  ativo: boolean,
  excluido: boolean,
  additive_budgets_attributes: [{
    accounting_code_id: number,
    valor: number,
    percentual: number

  }]
}

const InstrumentoAditivoEdit: React.FC = () => {

  const { aditivoInstrumento, getAditivoInstrumento, updateAditivoInstrumento } = useAditivoInstrumento();
  const { instrumento, getInstrumento, updateInstrumento } = useInstrumento();
  const { despesas, getDespesas } = useDespesas();
  const { orgaos, getOrganizations } = useOrgao();
  const { contrGestao, getContratos } = useContratoGestao();
  const { comites, getComittees } = useComite();
  const { unidadesComite, getUnidadesComite } = useUnidade();
  const { unidades, getUnidades } = useUnidade();
  const { finalidades, getFinalidades } = useFinalidades();
  const { programas, getProgramas } = usePrograma();
  const { acao, getAcoes } = useAcao();
  const { subacao, getSubAcao } = useSubAcao();
  const { projetos, getProjetos } = useProjeto();
  const { descricao, getDescricoes } = useDescricao();
  const { codigosContabeis, getCodigosContabeis, setCodigosContabeis } = useCodigoContabil();

  // Novas inportações para novo vinculo de rubricas
  const { projeto } = useProjeto();
  const { selectComite, getComittee, setComites, setSelectComite } = useComite();
  const { setContrGestao } = useContratoGestao();
  const { getPapFinalidades } = usePapFinalidade();
  const { getPaps, paps, getPap, selectedPap, setPaps, setSelectedPap } = usePap();
  const { getPoas, poas } = usePoa();
  const [yearsPoa, setYearsPoa] = useState([]);
  const [filtredPrograms, setFiltredPrograms] = useState([]);
  const [filtredActions, setFiltredActions] = useState([]);
  const [filtredSubactions, setFiltredSubactions] = useState([]);


  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [instrumentId, setInstrumentId] = useState<Number>();
  const [tipoValor, setTipoValor] = useState<Boolean>();
  const [tipoPrazo, setTipoPrazo] = useState<Boolean>();
  const [tipoOutros, setTipoOutros] = useState<Boolean>();
  const [valorInstrumentoAtualizado, setValorInstrumentoAtualizado] = useState<Number>(0);
  const [valorAditivo, setValorAditivo] = useState<Number>(0);
  const [dotacao, setDotacao] = useState<Boolean>();
  const [rateado, setRateado] = useState<Boolean>();
  const [organizeDataAditivo, setOrganizeDataAditivo] = useState<Object>([{ id: '0' }]);
  const [organizeDataExtrato, setOrganizeDataExtrato] = useState<Object>([{ id: '0' }]);
  const [valueTipoDespesa, setValueTipoDespesa] = useState<Object>();
  const [valueOrganizacao, setValueOrganizacao] = useState<Object>();
  const [optionsContrato, setOptionsContrato] = useState<Object>([]);
  const [valueContrato, setValueContrato] = useState<Object>();
  const [optionsComite, setOptionsComite] = useState<Object>([]);
  const [valueComite, setValueComite] = useState<Object>();
  const [optionsUnidade, setOptionsUnidade] = useState<Object>([]);
  const [valueUnidade, setValueUnidade] = useState<Object>();
  const [optionsFinalidade, setOptionsFinalidade] = useState<Object>([]);
  const [valueFinalidade, setValueFinalidade] = useState<Object>();
  const [optionsPrograma, setOptionsPrograma] = useState<Object>([]);
  const [valuePrograma, setValuePrograma] = useState<Object>();
  const [optionsAcao, setOptionsAcao] = useState<Object>([]);
  const [valueAcao, setValueAcao] = useState<Object>();
  const [optionsSubacao, setOptionsSubacao] = useState<Object>([]);
  const [valueSubacao, setValueSubacao] = useState<Object>();
  const [optionsProjeto, setOptionsProjeto] = useState<Object>([]);
  const [valueProjeto, setValueProjeto] = useState<Object>();
  const [optionsDescricao, setOptionsDescricao] = useState<Object>([]);
  const [valueDescricao, setValueDescricao] = useState<Object>();
  const [valueCodigoContabil, setValueCodigoContabil] = useState<Object>([]);
  const [codigoContabil, setCodigoContabil] = useState<Array<Object>>();
  const [salvando, setSalvando] = useState<boolean>(false);
  const [rubricaFront, setRubricaFront] = useState([]);

  const [valorLinhas, setValorLinhas] = useState<Array<Object>>([]);
  const [linhasProjeto, setLinhasProjeto] = useState<Array<Object>>([]);

  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const additiveId = useParams();
  const idAditivo = additiveId.idAditivo;

  useEffect(() => {
    getAditivoInstrumento(idAditivo);
    getDespesas();
    getOrganizations();
    // getComittees();
    // getContratos();
    getFinalidades();
    getProgramas();
    getAcoes();
    getSubAcao();
    getProjetos();
    getUnidadesComite();
    getUnidades();
    getDescricoes();
    // getCodigosContabeis();

  }, [])

  useEffect(() => {
    if (aditivoInstrumento.id) {
      setInstrumentId(aditivoInstrumento.contractual_instrument.id);
      getInstrumento(aditivoInstrumento.contractual_instrument.id);
      handleChangeTipoPrazo({
        "target": {
          "checked": aditivoInstrumento.aditivo_prazo
        }
      })
      handleChangeTipoValor({
        "target": {
          "checked": aditivoInstrumento.aditivo_valor
        }
      })
      handleChangeTipoOutros({
        "target": {
          "checked": aditivoInstrumento.aditivo_outros
        }
      })

      setOrganizeDataAditivo([
        {
          id: aditivoInstrumento.id,
          aditivo_descricao: aditivoInstrumento.aditivo_descricao,
          arquivo: `${aditivoInstrumento.url_aditivo_arquivo}/${aditivoInstrumento.aditivo_arquivo}`
        }
      ]);

      setOrganizeDataExtrato([
        {
          id: aditivoInstrumento.id,
          publicacao_descricao: aditivoInstrumento.publicacao_descricao,
          publicacao_data: aditivoInstrumento.publicacao_data,
          arquivo: `${aditivoInstrumento.url_publicacao_arquivo}/${aditivoInstrumento.publicacao_arquivo}`
        }
      ]);

      // if (aditivoInstrumento.additive_budgets != undefined) {
      //   const aditivoAccountingCode = aditivoInstrumento.additive_budgets[0].accounting_code;

      //   // setValorAditivo(aditivoInstrumento.additive_budgets[0].valor)

      //   setValueTipoDespesa({
      //     value: aditivoAccountingCode.expense_type.id,
      //     label: aditivoAccountingCode.expense_type.nome
      //   });

      //   setValueOrganizacao({
      //     value: aditivoAccountingCode.organization.id,
      //     label: aditivoAccountingCode.organization.nome
      //   });

      //   setValueContrato({
      //     value: aditivoAccountingCode.management_contract.id,
      //     label: aditivoAccountingCode.management_contract.nome
      //   });

      //   setValueComite({
      //     value: aditivoAccountingCode.comittee.id,
      //     label: aditivoAccountingCode.comittee.nome
      //   });

      //   setValueUnidade({
      //     value: aditivoAccountingCode.unit.id,
      //     label: aditivoAccountingCode.unit.nome
      //   });

      //   setValueFinalidade({
      //     value: aditivoAccountingCode.finality.id,
      //     label: aditivoAccountingCode.finality.nome
      //   });

      //   setValuePrograma({
      //     value: aditivoAccountingCode.program.id,
      //     label: aditivoAccountingCode.program.nome
      //   });

      //   setValueAcao({
      //     value: aditivoAccountingCode.tbl_action.id,
      //     label: aditivoAccountingCode.tbl_action.nome
      //   });

      //   setValueSubacao({
      //     value: aditivoAccountingCode.sub_action.id,
      //     label: aditivoAccountingCode.sub_action.nome
      //   });

      //   setValueProjeto({
      //     value: aditivoAccountingCode.project.id,
      //     label: aditivoAccountingCode.project.nome
      //   });

      //   setValueDescricao({
      //     value: aditivoAccountingCode.description.id,
      //     label: aditivoAccountingCode.description.nome
      //   });


      // };

    };

    setRubricaFront(organizeData2(aditivoInstrumento?.additive_budgets));
    setValorLinhas(organizeData2(aditivoInstrumento?.additive_budgets));

  }, [aditivoInstrumento]);

  const organizeData2 = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.id,
          accounting_code_id: d.accounting_code.id,
          contrato: d.accounting_code.management_contract.nome_completo,
          comite: d.accounting_code.comittee.nome,
          codigo_contabil: `${d.accounting_code.expense_type.codigo}.${d.accounting_code.organization.codigo.padStart(2, '0')}.${d.accounting_code.management_contract.codigo.padStart(2, '0')}.${d.accounting_code.comittee.codigo.padStart(2, '0')}.${d.accounting_code.unit.codigo.padStart(2, '0')}.${d.accounting_code.pap_finality.codigo.padStart(2, '0')}.${d.accounting_code.pap_program.codigo.padStart(2, '0')}.${d.accounting_code.pap_action.codigo.padStart(2, '0')}.${d.accounting_code.poa_sub_action.codigo.padStart(2, '0')}.${d.accounting_code.project.id.toString().padStart(2, '0')}.${d.accounting_code.description.codigo.padStart(3, '0')}`,
          valor: d.valor,
          saved: true
        }));
        return data;
      }
    }
    return [];
  };

  useEffect(() => {
    if (valueTipoDespesa && valueProjeto && valueDescricao && codigosContabeis.data) {
      const codigoContabilRubrica = codigosContabeis.data.filter(obj => obj.expense_type.id == valueTipoDespesa.value && obj.organization.id == valueOrganizacao.value && obj.management_contract.id == valueContrato.value && obj.comittee.id == valueComite.value && obj.unit.id == valueUnidade.value && obj.finality.id == valueFinalidade.value && obj.program.id == valuePrograma.value && obj.tbl_action.id == valueAcao.value && obj.sub_action.id == valueSubacao.value && obj.project.id == valueProjeto.value && obj.description.id == valueDescricao.value);
      setValueCodigoContabil([])
      setCodigoContabil([])
      if (codigoContabilRubrica.length) {
        setValueCodigoContabil(codigoContabilRubrica[0].codigo_contabil)
        setCodigoContabil(organizeDataCodigoContabil(codigoContabilRubrica));
      } else {
        alert("Código Contábil não encontrado");
      }
    }
  }, [valueTipoDespesa, valueProjeto, valueDescricao])

  useEffect(() => {
    if (!aditivoInstrumento.valor) {
      setValorAditivo(instrumento.valor_atual)
      setValorInstrumentoAtualizado(instrumento.valor_atual);
    }

  }, [instrumento])

  useEffect(() => {

    if (aditivoInstrumento.motivo_valor) {
      const consorcioMotivoRadio = formRef.current?.getFieldRef("motivo_valor");
      if (typeof consorcioMotivoRadio == 'object' && aditivoInstrumento.motivo_valor == 'acrescimo') {
        consorcioMotivoRadio[0].checked = true;
      } else if (typeof consorcioMotivoRadio == 'object' && aditivoInstrumento.motivo_valor == 'supressao') {
        consorcioMotivoRadio[1].checked = true;
      }
    };

    if (aditivoInstrumento.mesma_dotacao != null) {
      const consorcioDotacaoRadio = formRef.current?.getFieldRef("mesma_dotacao");
      if (typeof consorcioDotacaoRadio == 'object' && aditivoInstrumento.mesma_dotacao == true) {
        consorcioDotacaoRadio[0].checked = true;
      } else if (typeof consorcioDotacaoRadio == 'object' && aditivoInstrumento.mesma_dotacao == false) {
        consorcioDotacaoRadio[1].checked = true;
      }
    };

    if (aditivoInstrumento.pagamento_rateado != null) {
      const consorcioRateadoRadio = formRef.current?.getFieldRef("pagamento_rateado");
      if (typeof consorcioRateadoRadio == 'object' && aditivoInstrumento.pagamento_rateado == true) {
        consorcioRateadoRadio[0].checked = true;
      } else if (typeof consorcioRateadoRadio == 'object' && aditivoInstrumento.pagamento_rateado == false) {
        consorcioRateadoRadio[1].checked = true;
      }
    };

    setValorInstrumentoAtualizado(aditivoInstrumento.novo_valor_contrato);
    if (aditivoInstrumento.valor) {
      setValorAditivo(aditivoInstrumento.valor)
    } else {
      setValorAditivo(instrumento.valor_atual);
    };

    if (aditivoInstrumento.aditivo_valor && !tipoValor && (tipoOutros || tipoPrazo)) {
      let novoValor;
      if (aditivoInstrumento.motivo_valor === 'acrescimo') {
        novoValor = aditivoInstrumento.novo_valor_contrato - aditivoInstrumento.valor;
      } else if (aditivoInstrumento.motivo_valor === 'supressao') {
        novoValor = aditivoInstrumento.novo_valor_contrato + aditivoInstrumento.valor;
      }
      setValorAditivo(novoValor)
    };

    if (aditivoInstrumento.aditivo_prazo && !tipoPrazo && (tipoValor || tipoOutros)) {
      const instrumentoPrazoReduce = instrumento.audits.reduce((latestAlteration, alteration) => {
        if (alteration.alteracoes.data_termino_vigencia_atual && alteration.id > latestAlteration.id) {
          return alteration;
        }

        return latestAlteration;
      }, { id: -1 });
      // console.log("reduce",instrumentoPrazoReduce)
      console.log("reduce.de", instrumentoPrazoReduce.alteracoes.data_termino_vigencia_atual.de)
    };

    if (aditivoInstrumento.aditivo_valor && !tipoValor && (tipoPrazo || !tipoOutros)) {
      const instrumentoValueReduce = instrumento.audits.reduce((latestAlteration, alteration) => {
        if (alteration.alteracoes.valor_atual && alteration.id > latestAlteration.id) {
          return alteration;
        }

        return latestAlteration;
      }, { id: -1 });

      console.log("reduce", instrumentoValueReduce);
      console.log("reduce.de", instrumentoValueReduce.alteracoes.valor_atual.de);
    }

    // if(instrumento && aditivoInstrumento.aditivo_prazo && !tipoPrazo){
    //   console.log("entrou");

    //   // console.log(instrumentoPrazoReduce.created_at)
    //   // console.log(aditivoInstrumento.created_at)
    //   if(instrumento.data_termino_vigencia_atual != instrumentoPrazoReduce.alteracoes.data_termino_vigencia_atual.para){
    //     console.log("prazo diferente");
    //   }
    // };

    // setValorInstrumentoAtualizado(aditivoInstrumento.novo_valor_contrato);
    // setValorAditivo(aditivoInstrumento.valor)

    // if(instrumento && aditivoInstrumento.aditivo_valor && !tipoValor){
    //   let novoValor;
    //   if(aditivoInstrumento.motivo_valor === 'acrescimo'){
    //     novoValor = aditivoInstrumento.novo_valor_contrato - aditivoInstrumento.valor;
    //     console.log("acres",novoValor);
    //   } else if(aditivoInstrumento.motivo_valor === 'supressao'){
    //     novoValor = aditivoInstrumento.novo_valor_contrato + aditivoInstrumento.valor;
    //     console.log("supr", novoValor);
    //   }

    //   setValorInstrumentoAtualizado(novoValor);
    //   setValorAditivo(novoValor)


    //   console.log(instrumento.valor_atual)
    //   if(instrumentoValueReduce.alteracoes.valor_atual.para != instrumento.valor_atual){
    //     console.log("valor diferente");
    //   }      
    // };

    // if(instrumento && (aditivoInstrumento.aditivo_valor || aditivoInstrumento.aditivo_outros) && (!tipoValor && !tipoOutros)){
    //   const budgetDestroy = [];
    //   console.log(budgetDestroy);
    //   aditivoInstrumento.additive_budgets.forEach(c => budgetDestroy.push({"id": c.id, "_destroy": c.id}));
    //   console.log(budgetDestroy);
    // };


  }, [tipoValor, tipoPrazo, tipoOutros])


  // const organizeDataSelect = (data: any) => {

  //   if (typeof data === 'object') {
  //     data = data.map(d => ({
  //       value: d.id,
  //       label: d.nome,
  //     }));
  //   }

  //   return data;
  // };

  const organizeDataCodigoContabil = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.id,
        label: d.codigo_contabil,
      }));
    }

    return data;
  };

  const resetarOptions = (...optionsField) => {
    optionsField.forEach((optionsField) => {
      optionsField([]);
    });
  };
  const resetarValues = (...fieldName) => {
    fieldName.forEach((fieldName) => {
      const field = formRef.current?.getFieldRef(fieldName);
      if (field) {
        field.clearValue();
      }
    })
  };

  const apiRef = useGridApiRef();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: AditivoProps) => {
      try {
        setSalvando(true);

        data["contractual_instrument_id"] = instrumentId;
        data["aditivo_valor"] = tipoValor;
        data["aditivo_prazo"] = tipoPrazo;
        data["aditivo_outros"] = tipoOutros;

        if (!tipoValor && !tipoPrazo && !tipoOutros) {
          alert("Selecione um tipo de Aditivo");
          return;
        };

        // if (tipoValor || tipoOutros) {
        //   if (!rateado) {
        //     const budget = [{
        //       "valor": valorAditivo,
        //       "percentual": 100,
        //       "accounting_code_id": codigoContabil[0]?.value
        //     }];
        //     data["additive_budgets_attributes"] = budget
        //   }
        // };

        if (aditivoInstrumento.aditivo_valor && !tipoValor) {
          data["motivo_valor"] = '';
          data["novo_valor_contrato"] = valorAditivo;
          const dataUpdateInstrumento = { "valor_atual": valorAditivo };
          data["new"] = dataUpdateInstrumento;
          // updateInstrumento(instrumentId, dataUpdateInstrumento)
          if (!tipoOutros) {
            data["valor"] = '';
            data["mesma_dotacao"] = '';
            data["pagamento_rateado"] = '';
            data["additive_budgets_attributes"] = [{ "id": "id", "_destroy": "id" }];
          }
        } else if (aditivoInstrumento.aditivo_prazo && !tipoPrazo) {
          data["data_assinatura"] = '';
          data["data_inicio_vigencia"] = '';
          data["data_termino_vigencia"] = '';
          const dataUpdateInstrumento = {
            "data_termino_vigencia": instrumento.audits[0].alteracoes.data_termino_vigencia_atual.de
          };
          data["new"] = dataUpdateInstrumento;
          // updateInstrumento(instrumentId, dataUpdateInstrumento)
        } else if (aditivoInstrumento.aditivo_outros && !tipoOutros && !tipoValor) {
          data["valor"] = '';
          data["novo_valor_contrato"] = valorAditivo;
          data["motivo_valor"] = '';
          data["mesma_dotacao"] = '';
          data["pagamento_rateado"] = '';
          data["additive_budgets_attributes"] = [{ "id": "id", "_destroy": "id" }];
          const dataUpdateInstrumento = { "valor_atual": valorInstrumentoAtualizado };
          data["new"] = dataUpdateInstrumento;
          // updateInstrumento(instrumentId, dataUpdateInstrumento)
        }

        const rowsData = Array.from( apiRef.current.getRowModels(), ([, value]) => (value) );
        const rubricaSemValor = rowsData.every(row => row.valor);

        if (!rubricaSemValor) {
          alert("Todas as linhas da Rubrica orçamentária devem conter valor.");
          setSalvando(false);
          return;
        }

        data["additive_budgets_attributes"] = rowsData.map(d => {

          if (d?._destroy) {
            return {
              id: d.id,
              _destroy: d._destroy
            }
          } else if (d?.id) {
            return {
              id: d.id,
              accounting_code_id: d?.accounting_code_id,
              valor: d.valor
            }
          } else {
            return {
              accounting_code_id: d?.accounting_code_id,
              valor: d.valor
            }
          }
          
        });

        console.log(data);
        await updateAditivoInstrumento(idAditivo, data);

      } catch (err) {
        setSalvando(false);
      } finally {
        setSalvando(false);
      }
    },
    [instrumentId, tipoValor, tipoPrazo, tipoOutros, rateado, valorInstrumentoAtualizado, valorAditivo, codigoContabil],
  );

  const handleChangeTipoValor = (tipo: object) => {
    let valorChecked = tipo.target.checked;
    setTipoValor(valorChecked)
    if (valorChecked === false && tipoOutros) {
      // setValorAditivo(instrumento.valor_atual);
    }
  };
  const handleChangeTipoPrazo = (tipo: object) => {
    let prazoChecked = tipo.target.checked;
    setTipoPrazo(prazoChecked);
  };
  const handleChangeTipoOutros = (tipo: object) => {
    let outrosChecked = tipo.target.checked;
    setTipoOutros(outrosChecked);
    if (!tipoValor) {
      // setValorAditivo(instrumento.valor_atual);
    }
  };

  const handleChangeMotivo = (motivo: object) => {
    let valorAdicionado = formRef.current?.getFieldValue("valor");
    let novoValor;
    if (aditivoInstrumento.motivo_valor === 'acrescimo') {
      novoValor = aditivoInstrumento.novo_valor_contrato - aditivoInstrumento.valor;
    } else if (aditivoInstrumento.motivo_valor === 'supressao') {
      novoValor = aditivoInstrumento.novo_valor_contrato + aditivoInstrumento.valor;
    } else {
      novoValor = instrumento.valor_atual;
    }

    if (motivo.target.id === 'motivoSim') {
      if (valorAdicionado) {
        novoValor += parseInt(valorAdicionado);
        setValorInstrumentoAtualizado(novoValor);
      }
    } else if (motivo.target.id === 'motivoNao') {
      if (valorAdicionado) {
        if (valorAdicionado > novoValor) {
          valorAdicionado = novoValor;
          formRef.current?.setFieldValue("valor", valorAdicionado);
          alert("O valor do Aditivo não pode subtrair além do valor total do contrato");
          setValorInstrumentoAtualizado(0);
        } else {
          novoValor -= parseInt(valorAdicionado);
          setValorInstrumentoAtualizado(novoValor);
        }
      }
      setValorAditivo(valorAdicionado);
    }
  };

  const handleChangeValor = (valor: object) => {
    let novoValor;
    if (aditivoInstrumento.motivo_valor === 'acrescimo') {
      novoValor = aditivoInstrumento.novo_valor_contrato - aditivoInstrumento.valor;
    } else if (aditivoInstrumento.motivo_valor === 'supressao') {
      novoValor = aditivoInstrumento.novo_valor_contrato + aditivoInstrumento.valor;
    } else {
      novoValor = instrumento.valor_atual;
    }
    var valorField = valor.target.value;
    const motivo = formRef.current?.getFieldValue("motivo_valor");

    if (!valorField) {
      setValorInstrumentoAtualizado(novoValor);
    } else if (motivo === 'acrescimo') {
      novoValor += parseFloat(valorField);
      setValorInstrumentoAtualizado(novoValor);
    } else if (motivo === 'supressao') {
      if (valorField > novoValor) {
        valor.target.value = novoValor;
        novoValor = 0;
        alert("O valor do Aditivo não pode subtrair além do valor total do contrato");
        setValorInstrumentoAtualizado(novoValor);
      } else {
        novoValor -= parseFloat(valorField);
        setValorInstrumentoAtualizado(novoValor);
      }
    }
    setValorAditivo(valor.target.value);
  };

  const handleChangeDotacao = (dotacao: object) => {
    if (dotacao.target.id === 'dotacaoSim') {
      setDotacao(true);
    } else if (dotacao.target.id === 'dotacaoNao') {
      setDotacao(false);
    }
  };
  const handleChangeRateado = (rateado: object) => {
    if (rateado.target.id === 'rateadoSim') {
      setRateado(true);
    } else if (rateado.target.id === 'rateadoNao') {
      setRateado(false);
    }
  };

  const dataTitleRubrica: GridColDef[] = [
    { field: "contrato", headerName: "Contrato de gestão", flex: 2 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    { field: "codigo_contabil", headerName: "Código Contábil", flex: 3 },
    { 
      field: "valor",
      headerName: "Valor (R$)",
      flex: 2,
      editable: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
            return "Defina um valor!";
        }
        return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <InputNumberFormat {...params} />
      ),
    },
    {
      field: "percentual",
      headerName: "Percentual (%)",
      flex: 1,
      valueGetter: (params) => {
        if (!params.row.valor || !valorInstrumentoAtualizado) {
          return "Defina valor da linha e valor do instrumento!";
        }
        return ((params.row.valor / valorInstrumentoAtualizado) * 100).toFixed(2);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    },
    {
      field: "actions",
      type: 'actions',
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      getActions: ({ id, row }) => {
        return [
            <GridActionsCellItem
              icon={<FontAwesomeIcon icon="fa-solid fa-trash-can" />}
              label="Delete"
              onClick={() => onDeleteRubricaClick(id, row)}
              color="inherit"
              sx={{color: "#e62121"}}
            />
        ];
      }
    }
  ];

  function onDeleteRubricaClick(id: number, row: object): void {

    // console.log(apiRef.current.state); return;
    if (row?._destroy) {
      alert("Esta rubrica ja foi selecionada para exclusão, clique em salvar para aplicar as mudanças.")
      return;
    }

    const rowsData = Array.from( apiRef.current.getRowModels(), ([, value]) => (value) );
    // console.log(rowsData);

    if (row?.saved) {
      setRubricaFront(
        rowsData.map(r => {
          if (r.accounting_code_id === row.accounting_code_id) {
            return {...r, _destroy: r.id};
          } else {
            return r;
          }
        })
      );
    } else {
      setRubricaFront(rowsData.filter((el) => el.accounting_code_id !== row.accounting_code_id));
    }

    const updatedValueLinhas = [...valorLinhas.filter((linha) => linha.id !== id)];
    setValorLinhas(updatedValueLinhas);
  }

  const handleSearchLine = async () => {
    const despesa = formRef.current?.getFieldValue('expense_type_id');
    const org = formRef.current?.getFieldValue('organization_id');
    const contrato = formRef.current?.getFieldValue('management_contract_id');
    const comite = formRef.current?.getFieldValue('comittee_id');
    const unidade = formRef.current?.getFieldValue('unit_id');
    const finalidade = formRef.current?.getFieldValue('pap_finality_id');
    const programa = formRef.current?.getFieldValue('pap_program_id');
    const acao = formRef.current?.getFieldValue('pap_action_id');
    const subacao = formRef.current?.getFieldValue('poa_sub_action_id');
    const projeto = formRef.current?.getFieldValue('project_id');
    const descricao = formRef.current?.getFieldValue('description_id');

    // const rubrica = `${despesa}.${org}.${contrato}.${comite}.${unidade}.${finalidade}.${programa}.${acao}.${subacao}.${projeto}.${descricao}`;
    // console.log(rubrica);

    const {data} = await getCodigosContabeis([
      {label: 'expense_type_id', value: despesa},
      {label: 'organization_id', value: org},
      {label: 'management_contract_id', value: contrato},
      {label: 'comittee_id', value: comite},
      {label: 'unit_id', value: unidade},
      {label: 'pap_finality_id', value: finalidade},
      {label: 'pap_program_id', value: programa},
      {label: 'pap_action_id', value: acao},
      {label: 'poa_sub_action_id', value: subacao},
      {label: 'project_id', value: projeto},
      {label: 'description_id', value: descricao}
    ]);

    const rubrica = data?.data[0];
    if (!rubrica) {
      alert("Rubrica não encontrada! Crie uma nova rubrica secundária.");
      setCodigosContabeis([]);
      formRef.current?.setFieldValue('final_rubrica', '');
    } else {
      formRef.current?.setFieldValue('final_rubrica', `${rubrica.expense_type.codigo}.${rubrica.organization.codigo.padStart(2, '0')}.${rubrica.management_contract.codigo.padStart(2, '0')}.${rubrica.comittee.codigo.padStart(2, '0')}.${rubrica.unit.codigo.padStart(2, '0')}.${rubrica.pap_finality.codigo.padStart(2, '0')}.${rubrica.pap_program.codigo.padStart(2, '0')}.${rubrica.pap_action.codigo.padStart(2, '0')}.${rubrica.poa_sub_action.codigo.padStart(2, '0')}.${rubrica.project.id.toString().padStart(2, '0')}.${rubrica.description.codigo.padStart(3, '0')}`);
    }
  };

  const organizeData = (data) => {
    data = {
      accounting_code_id: data.id,
      contrato: data.management_contract.nome_completo,
      comite: data.comittee.nome,
      codigo_contabil: `${data.expense_type.codigo}.${data.organization.codigo.padStart(2, '0')}.${data.management_contract.codigo.padStart(2, '0')}.${data.comittee.codigo.padStart(2, '0')}.${data.unit.codigo.padStart(2, '0')}.${data.pap_finality.codigo.padStart(2, '0')}.${data.pap_program.codigo.padStart(2, '0')}.${data.pap_action.codigo.padStart(2, '0')}.${data.poa_sub_action.codigo.padStart(2, '0')}.${data.project.id.toString().padStart(2, '0')}.${data.description.codigo.padStart(3, '0')}`
    };
    return data;
  };

  const handleAddRubrica = () => {

    console.log(codigosContabeis);
    const rowsData = Array.from( apiRef.current.getRowModels(), ([, value]) => (value) );

    if (!codigosContabeis?.data || !codigosContabeis?.data.length) {
      alert('Pesquise a Rubrica antes. O campo \'Rubrica final\' deve estar preenchido.');
    } else {
      setRubricaFront((prevRows) => [ ...prevRows, organizeData(codigosContabeis.data[0]) ]);
    }
  };

  // Novo vinculo de rubricas
  const onChangeDespesa = (selectedDespesa: any) => {
    getPapFinalidades([{label: "expense_type_id", value: selectedDespesa.value}]);
  }

  const onChangeOrganization = (selectedOrg: any) => {
    formRef.current?.clearField("management_contract_id");
    formRef.current?.clearField()
    setContrGestao([]);
    formRef.current?.clearField("comittee_id");
    setComites([]);
    formRef.current?.clearField("unit_id");
    formRef.current?.clearField("periodo");
    setPaps([]);
    formRef.current?.clearField("pap_finality_id");
    setSelectedPap({});
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    setYearsPoa([]);
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);

    getContratos([{ label: "organization_id", value: selectedOrg.value }]);

  }

  const onChangeContrGestao = (selectedContrGestao: any) => {
    formRef.current?.clearField("comittee_id");
    setComites([]);
    formRef.current?.clearField("unit_id");
    formRef.current?.clearField("periodo");
    setPaps([]);
    formRef.current?.clearField("pap_finality_id");
    setSelectedPap({});
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    setYearsPoa([]);
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);

    if (selectedContrGestao) {
        getComittees([{ label: "management_contract_id", value: selectedContrGestao.value }]);
    }
  }

  const onChangeComite = (selectedComite: any) => {
      const {project_lines, rateio} = projeto;

      console.log(project_lines, !rateio);
      if (project_lines.length && !rateio && selectedComite) { // Se tiver ao menos UMA linha
          if (project_lines[0].comittee.id !== selectedComite.value) {
          alert("O Projeto não é rateado! Só é possível adicionar mais linhas se for do mesmo Comitê.");
          // formRef.current?.clearField("comittee_id");
          }
      }


      formRef.current?.clearField("unit_id");
      setSelectComite({
          management_contract: {
              organization: {
                  nome: {}
              },
              management_entity: {}
          },
          units: []
      });
      formRef.current?.clearField("periodo");
      setPaps([]);
      formRef.current?.clearField("pap_finality_id");
      setSelectedPap({});
      formRef.current?.clearField("pap_program_id");
      setFiltredPrograms([]);
      formRef.current?.clearField("pap_action_id");
      setFiltredActions([]);
      formRef.current?.clearField("ano");
      setYearsPoa([]);
      formRef.current?.clearField("poa_sub_action_id");
      setFiltredSubactions([]);

      if (selectedComite) {
          getComittee(selectedComite.value);
          getPaps([{label: "comittee_id", value: selectedComite.value}]);
      }
  }

  useEffect(() => {
    console.log(paps);
  }, [paps]);

  useEffect(() => {
    // console.log(poas)
    if (poas.data && poas.data.length) {
      const pap_action_id = formRef.current?.getFieldValue("pap_action_id");
      const filter = poas.data[0].subacoes.filter(p => p.pap_action.id === pap_action_id); // data[0] porque de acordo com as regras de negocio deve existir apenas 1 unico POA vinculado ao mesmo PAP e ao mesmo ANO_POA
      setFiltredSubactions(filter);
    }
  }, [poas]);

  useEffect(() => { // quando um PAP for selecionado ele preenche os anos para o POA
    const {periodo_de, periodo_ate} = selectedPap;

    const aux = [];
    for (let y: number = periodo_de; y <= periodo_ate; y++) {
        aux.push( {label: y, value: y} );
    }
    setYearsPoa(aux);
    
}, [selectedPap]);

  const onChangePeriodo = (selectedPeriodo: any) => {
    formRef.current?.clearField("pap_finality_id");
    setSelectedPap({});
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    setYearsPoa([]);
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);
    if (selectedPeriodo) {
        // getPapFinalidades([{label: "expense_type_id", value: selectedDespesa.value}]);
        getPap(selectedPeriodo.value);
    }
  }

  const onChangeFinalidade = (selectedFinalidade: any) => {
    formRef.current?.clearField("pap_program_id");
    setFiltredPrograms([]);
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);
    if (selectedFinalidade) {
        const filter = selectedPap.programas.filter(p => p.pap_finality.id === selectedFinalidade.value);
        setFiltredPrograms(filter);
    }

  }

  const onChangePrograma = (selectedPrograma: any) => {
    formRef.current?.clearField("pap_action_id");
    setFiltredActions([]);
    formRef.current?.clearField("ano");
    formRef.current?.clearField("poa_sub_action_id");
    setFiltredSubactions([]);
    if (selectedPrograma) {
        const filter = selectedPap.acoes.filter(p => p.pap_program.id === selectedPrograma.value);
        setFiltredActions(filter);
    }

  }

  const onChangeAcao = (selectedAcao: any) => {
      formRef.current?.clearField("ano");
      formRef.current?.clearField("poa_sub_action_id");
          setFiltredSubactions([]);
  }

  const onChangeAno = (selectedAno: any) => {
      formRef.current?.clearField("poa_sub_action_id");
      setFiltredSubactions([]);

      const pap_id = formRef.current?.getFieldValue("periodo");

      if (selectedAno) {
          getPoas([
              {label: "pap_id", value: pap_id},
              {label: "poa", value: selectedAno.value}
          ]);
      }
  }

  const handleUpdateLinha = (updatedRow: Object) => {
    const objUpdatedRow = { id: updatedRow?.id, valor: updatedRow?.valor };
    const updatedValueLinhas = [...valorLinhas.filter((linha) => linha.id !== updatedRow?.id), objUpdatedRow];
    setValorLinhas(updatedValueLinhas);

    // setRubricaFront(rubricaFront[[updatedRow.id]]  updatedRow?.valor ]);
    setRubricaFront(prevState => {
        // loop sobre a lista
        return prevState.map((item) => {
            // verifica pelo item com o ID especifico e atualiza
            return item.id === updatedRow?.id ? {...item, valor: updatedRow?.valor} : item
        })
    });

    return updatedRow;
  };

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <Container>
      <Title color='#333'>
        <span>
          Edição do Aditivo
        </span>
      </Title>

      <Form ref={formRef} onSubmit={handleSubmit} id="formAditivo">
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Nome do aditivo: <span>*</span></TextTitle>
              <Input
                type="text"
                name="nome"
                defaultValue={aditivoInstrumento.nome}
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Selecione o tipo de aditivo: <span>*</span></TextTitle>
              <Instancia>
                <div>
                  <input
                    type="checkbox"
                    name="checkvalor"
                    id="checkvalor"
                    checked={tipoValor}
                    onChange={handleChangeTipoValor}
                  /> Valor
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkprazo"
                    id="checkprazo"
                    checked={tipoPrazo}
                    onChange={handleChangeTipoPrazo}
                  /> Prazo
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkoutros"
                    id="checkoutros"
                    checked={tipoOutros}
                    onChange={handleChangeTipoOutros}
                  /> Outros
                </div>
              </Instancia>
            </Grid>
            <Grid xs={12}>
              <TextTitle>Descrição: <span>*</span></TextTitle>
              <Input
                type="text"
                name="descricao"
                defaultValue={aditivoInstrumento.descricao}
              />
            </Grid>
          </Grid>
        </Panel>

        {tipoPrazo ?
          <Panel titulo="Prazo">
            <Grid container spacing={6}>
              <Grid xs={6}>
                <TextTitle>Data de assinatura do termo aditivo: <span>*</span></TextTitle>
                <Input
                  type="date"
                  name="data_assinatura"
                  defaultValue={aditivoInstrumento.data_assinatura}
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Data do início da vigência / ordem de serviço:</TextTitle>
                <Input
                  type="date"
                  name="data_inicio_vigencia"
                  defaultValue={aditivoInstrumento.data_inicio_vigencia}
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Nova data do término da vigência:</TextTitle>
                <Input
                  type="date"
                  name="data_termino_vigencia"
                  defaultValue={aditivoInstrumento.data_termino_vigencia}
                />
              </Grid>
            </Grid>
          </Panel>
          : null}

        {tipoValor ?
          <Panel titulo="Valor">
            <Grid container spacing={6}>
              <Grid xs={12}>
                <TextTitle>Selecione o motivo do valor: <span>*</span></TextTitle>
                <Radio
                  name='motivo_valor'
                  options={[
                    { id: "motivoSim", value: "acrescimo", label: "Acréscimo" },
                    { id: "motivoNao", value: "supressao", label: "Supressão" }
                  ]}
                  onChange={handleChangeMotivo}
                  required
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Informe o valor a ser adicionado/suprimido: <span>*</span></TextTitle>
                <Input
                  type="text"
                  name="valor"
                  defaultValue={aditivoInstrumento.valor}
                  onChange={handleChangeValor}
                  required
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Novo valor do contrato: <span>*</span></TextTitle>
                <Input
                  type="text"
                  name="novo_valor_contrato"
                  value={valorInstrumentoAtualizado}
                  defaultValue={instrumento.valor_atual}
                  disabled
                />
              </Grid>
            </Grid>
          </Panel>
          : null}

        {tipoValor || tipoOutros ?
          <Panel titulo="Rubrica orçamentária">
            <Grid container spacing={6}>
              <Grid xs={6}>
                <TextTitle>A dotação é a mesma do contrato?: <span>*</span></TextTitle>
                <Radio
                  name='mesma_dotacao'
                  options={[
                    { id: "dotacaoSim", value: "true", label: "Sim" },
                    { id: "dotacaoNao", value: "false", label: "Não" }
                  ]}
                  onClick={handleChangeDotacao}
                  required
                />
              </Grid>
              <Grid xs={6}>
                <TextTitle>Valor do termo aditivo:</TextTitle>
                <span>R${valorAditivo},00</span>
              </Grid>
              <Grid xs={12}>
                <TextTitle>Os pagamentos serão rateados? <span>*</span></TextTitle>
                <Radio
                  name='pagamento_rateado'
                  options={[
                    { id: "rateadoSim", value: "true", label: "Sim" },
                    { id: "rateadoNao", value: "false", label: "Não" }
                  ]}
                  onClick={handleChangeRateado}
                  required
                />
              </Grid>
              <Grid xs={6}>
                  <ButtonAdd type="button" onClick={handleClickOpenDialog}>
                    <FontAwesomeIcon icon={faPlus} /> Adicionar
                  </ButtonAdd>
              </Grid>
              <Grid xs={6}>
                <BasicDialog
                  open={openDialog}
                  handleClose={handleCloseDialog}
                  fullWidth={true}
                  maxWidth="lg"
                  titulo="Dados contábeis"
                  buttons={
                    <Container>
                      <Grid container justifyContent="space-between" alignItems="center" width={'100%'}>
                        <Grid><Button onClick={setOpenDialog2}>Criar nova Rubrica secundária</Button></Grid>
                        <Grid container columnSpacing={3}>
                          <Grid><Button  onClick={handleCloseDialog}>Cancelar</Button></Grid>
                          <Grid><Button variant="contained" onClick={handleAddRubrica}>Vincular Rubrica</Button></Grid>
                        </Grid>
                      </Grid>
                    </Container>
                  }
                >
                  
                  <Grid container spacing={3}>
                    <Grid xs={6}>
                        <TextTitle>Despesas: </TextTitle>
                        <SelectInput
                            id='expense_type_id'
                            name='expense_type_id'
                            options={organizeDataSelect(despesas.data, "id", "nome")}
                            onInputChange={d => { getDespesas([{ label: 'nome', value: d }]) }}
                            onChange={onChangeDespesa}
                            placeholder="Selecionar"
                        />
                    </Grid>

                    <Grid xs={12} lg={6}>
                        <TextTitle>Organização: <span>*</span></TextTitle>
                        <SelectInput
                            id='organization_id'
                            name='organization_id'
                            // value={selectedOrg}
                            options={organizeDataSelect(orgaos.data, "id", "nome")}
                            onInputChange={d => { getOrganizations([{ label: 'nome', value: d }]) }}
                            onChange={onChangeOrganization}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12} lg={6}>
                        <TextTitle>Contrato de gestão: <span>*</span></TextTitle>
                        <SelectInput
                            id='management_contract_id'
                            name='management_contract_id'
                            options={organizeDataSelect(contrGestao.data, "id", "nome")}
                            // onInputChange={d => { getContratos([{label: 'nome', value: d}]) } }
                            onChange={onChangeContrGestao}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>

                    <Grid xs={12} lg={6}>
                        <TextTitle>Comitê: <span>*</span></TextTitle>
                        <SelectInput
                            id='comittee_id'
                            name='comittee_id'
                            options={organizeDataSelect(comites.data, "id", "nome")}
                            // onInputChange={d => { getComittees([{label: 'nome', value: d}]) } }
                            onChange={onChangeComite}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12} lg={6}>
                        <TextTitle>Unidade: <span>*</span> <small>(De acordo com o comitê)</small></TextTitle>
                        <SelectInput
                            id='unit_id'
                            name='unit_id'
                            options={organizeDataSelect(selectComite.units, "id", "nome")}
                            // onInputChange={d => { getUnidades([{label: 'nome', value: d}]) } }
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>

                    <Grid xs={6}>
                        <TextTitle>Período: <span>*</span> <small>(De acordo com o comitê)</small></TextTitle>
                        <SelectInput
                            id='periodo'
                            name='periodo'
                            options={organizeDataSelect(paps.data, "id", "periodo_de", ["periodo_ate"])}
                            onChange={onChangePeriodo}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>

                    <Grid xs={12}>
                        <TextTitle>Finalidade: <span>*</span></TextTitle>
                        <SelectInput
                            id='pap_finality_id'
                            name='pap_finality_id'
                            options={organizeDataSelect(selectedPap.finalidades, "id", "nome")}
                            onChange={onChangeFinalidade}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextTitle>Programa: <span>*</span></TextTitle>
                        <SelectInput
                            id='pap_program_id'
                            name='pap_program_id'
                            options={organizeDataSelect(filtredPrograms, "id", "nome")}
                            onChange={onChangePrograma}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextTitle>Ação: <span>*</span></TextTitle>
                        <SelectInput
                            id='pap_action_id'
                            name='pap_action_id'
                            options={organizeDataSelect(filtredActions, "id", "nome")}
                            onChange={onChangeAcao}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>

                    <Grid xs={6}>
                        <TextTitle>Ano: <span>*</span> <small>(Com base no Período)</small></TextTitle>
                        <SelectInput
                            id="ano"
                            name="ano"
                            options={yearsPoa || []}
                            onChange={onChangeAno}
                            placeholder="Selecione"
                            required
                        />
                    </Grid>

                    <Grid xs={12}>
                        <TextTitle>Sub-ação: <span>*</span> <small>(Com base no Ano e Ação)</small></TextTitle>
                        <SelectInput
                            id='poa_sub_action_id'
                            name='poa_sub_action_id'
                            options={organizeDataSelect(filtredSubactions, "id", "nome")}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextTitle>Projeto: </TextTitle>
                        <SelectInput
                            id='project_id'
                            name='project_id'
                            options={organizeDataSelect(projetos.data, "id", "nome")}
                            onInputChange={d => { getProjetos([{ label: "nome", value: d }]) }}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextTitle>Descrição: </TextTitle>
                        <SelectInput
                            id='description_id'
                            name='description_id'
                            options={organizeDataSelect(descricao.data, "id", "nome")}
                            onInputChange={d => { getDescricoes([{ label: "nome", value: d }]) }}
                            placeholder="Selecionar"
                            required
                        />
                    </Grid>

                    <Grid xs={12}>
                        <ButtonAdd 
                            id='adicionar' 
                            name='adicionar' 
                            type="button" 
                            onClick={handleSearchLine}
                        >
                            <FontAwesomeIcon icon={faPlus} size="1x" /> Buscar Rubrica
                        </ButtonAdd>
                    </Grid>

                    <Grid xs={12}>
                    <TextTitle>Rubrica final: </TextTitle>
                      <Input name="final_rubrica" disabled />
                    </Grid>

                  </Grid>

                </BasicDialog>
              </Grid>
              <Grid xs={12}>
                <SecondaryTable
                  apiRef={apiRef}
                  columsData={dataTitleRubrica}
                  rowsData={rubricaFront}
                  getRowId={(row) => row.accounting_code_id}
                  processRowUpdate={(updatedRow: Object) => handleUpdateLinha(updatedRow)}
                  onKeyDown={(e) => checkKeyDown(e)}
                />
              </Grid>
              <Grid xs={6} style={{marginTop: "20px"}}>
                <TextTitle>Total: {formatarReais(valorLinhas?.reduce(function (acc, obj) { return acc + parseFloat(obj?.valor); }, 0))}</TextTitle>
              </Grid>
            </Grid>
          </Panel>
          : null}

        <Panel titulo="Aditivo">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Descrição: </TextTitle>
              <div style={{ position: "relative" }}>
                <Input type="text" name="aditivo_descricao" defaultValue={aditivoInstrumento.aditivo_descricao} />
                <Link
                  to={{
                    pathname: `${aditivoInstrumento?.url_aditivo_arquivo}/${aditivoInstrumento?.aditivo_arquivo}`,
                  }}
                  target="_blank"
                >
                  <InputButtonDownload
                    id="arquivo_nota"
                    name="arquivo_nota"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faDownload} size="1x" />
                  </InputButtonDownload>
                </Link>
              </div>
            </Grid>
            <Grid xs={6}>
              <TextTitle>Aditivo: </TextTitle>
              <InputFilev2 id="documento_aditivo" name="aditivo_arquivo" />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Extrato de publicação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data da Publicação:</TextTitle>
              <Input type="date" name="publicacao_data" defaultValue={aditivoInstrumento.publicacao_data} />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Descrição: <span>*</span></TextTitle>
              <div style={{ position: "relative" }}>
                <Input type="text" name="publicacao_descricao" defaultValue={aditivoInstrumento.publicacao_descricao} />
                <Link
                  to={{
                    pathname: `${aditivoInstrumento?.url_publicacao_arquivo}/${aditivoInstrumento?.publicacao_arquivo}`,
                  }}
                  target="_blank"
                >
                  <InputButtonDownload
                    id="arquivo_nota"
                    name="arquivo_nota"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faDownload} size="1x" />
                  </InputButtonDownload>
                </Link>
              </div>
            </Grid>
            <Grid xs={6}>
              <TextTitle>Extrato:</TextTitle>
              <InputFilev2 id="documento_publicacao" name="publicacao_arquivo" />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <BasicDialog
        open={openDialog2}
        handleClose={handleCloseDialog2}
        fullWidth={true}
        maxWidth="lg"
        titulo="Dados contábeis - Rubricas secundárias"
        buttons={
              <>
                <Button  onClick={handleCloseDialog2}>Cancelar</Button>
                {/* <Button variant="contained" onClick={handleAddRubrica}>Criar nova Rubrica secundária</Button> */}
              </>
        }
      >
        <ModalCodigoContabil />
      </BasicDialog>

      <ContainerButton2>
        <Link to="/instrumentos">
          <button className="button_return"><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formAditivo"}></SaveWithProgress>
      </ContainerButton2>
    </Container >
  )
};

export default InstrumentoAditivoEdit;