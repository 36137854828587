import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useOrigemDemanda = () => {
  const history = useHistory();
  const [origensDemanda, setOrigensDemanda] = useState([]);
  const [origemDemanda, setOrigemDemanda] = useState([]);
  const [error, setError] = useState(null);

  async function getOrigensDemanda(params) {
    try {
      const response = await api.get(`demand_origins${stringfyParams(params)}`);
      setOrigensDemanda(response.data);
    } catch (e) {
      setError(e);
    }
  }

  async function getOrigemDemanda(id: string) {
    try {
      const response = await api.get(`demand_origins/${(id)}`);
      setOrigemDemanda(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function createOrigemDemanda(data: any) {
    try {
      const response = await api.post('demand_origins', data);
      alert("Origem da Demanda cadastrado com sucesso");
      history.push("/protocolo/cadastros/origem_demanda");
      return response.data;
    } catch (e) {
      setError(e);
    }
  }


  async function updateOrigemDemanda(id: number, data: any) {
    try {
      const response = await api.put(`demand_origins/${(id)}`, data);
      alert("Origem da Demanda atualizado com sucesso!");
      history.push("/protocolo/cadastros/origem_demanda");
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  return { origensDemanda, getOrigensDemanda, origemDemanda, getOrigemDemanda, createOrigemDemanda, updateOrigemDemanda }

};

export default useOrigemDemanda;