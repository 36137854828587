import styled from 'styled-components';

import { TextInputInput } from '../../components/Input/Input';


export const FormatedTextInput = styled(TextInputInput)`

    font-size: 1.2rem;
`;

export const TextTitle = styled.text`
    color: #333;
    font-weight: 600;
    float: left;
    padding-top: 2rem;
`;


export const Card = styled.div` 
    display: flex;
    align-items: center;
    width: 960px; 
    height: 600px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 60px 20px #00000014;
    opacity: 1;
`;

export const LogoDiv = styled.div`   
    float: left; 
`;

export const FormDiv = styled.div` 
    float: right;
    padding-right: 80px;
`;

export const LabelItem = styled.div`
    gap: 3;
`;

export const TitleDiv = styled.div`
    text-align: start;
    display: grid;
`;
