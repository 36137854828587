import React, { useEffect } from "react";
import {
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import Panel from "../../../components/Panel";
import { ContainerButton2, Form, Input, TextTitle, Textarea, Title } from "../../../styles/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import { Button, Container } from "@mui/material";
import { formatarData } from "../../../utils/formatDados";
import Grid from "@mui/material/Unstable_Grid2";
import useProcesso from "../../../actions/cruds/Processos/useProcesso";

const ProcessoView: React.FC = () => {
  const { processoId } = useParams();
  const { processo, getProcesso } = useProcesso();

  useEffect(() => {
    getProcesso(processoId);
  }, []);

  const optionsStatus = [
    { value: "em_andamento", label: "Em Andamento" },
    { value: "a_fazer", label: "A fazer" },
    { value: "concluido", label: "Concluído" },
  ];

  const organizeDataActionPlanning = (data: any) => {
    if (typeof data === "object") {
      data = data.map((d: any) => {
        return {
          id: d.id,
          item: d.law_process_category_action.item,
          acao: d.law_process_category_action.nome,
          status: d.status ? optionsStatus.find((status) => status.value == d.status)?.label : "",
          previsao_inicio: formatarData(d.previsao_inicio, false),
          previsao_termino: formatarData(d.previsao_termino, false),
          data_inicio: formatarData(d.data_inicio, false),
          data_termino: formatarData(d.data_termino, false),
        };
      });
      data = data.sort((a, b) => a.item - b.item);
    }
    return data;
  };

  const organizeDataProtocoolo = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.demand.id,
          codigo: d.demand.codigo,
        }));
        return data;
      };
    };
    return [];
  };

  const dataTitleContratoGestao: GridColDef[] = [
    { field: "nome_completo", headerName: "Contrato de Gestão", flex: 1 },
  ];

  const dataTitleAcoesPlanning: GridColDef[] = [
    { field: "item", headerName: "Item", flex: 1 },
    { field: "acao", headerName: "Ação", flex: 5 },
    { field: "status", headerName: "Status", flex: 2 },
    {
      field: "previsao_inicio",
      headerName: "Previsão Início",
      flex: 2,
    },
    {
      field: "previsao_termino",
      headerName: "Previsão Término",
      flex: 2,
    },
    {
      field: "data_inicio",
      headerName: "Data Inicial",
      flex: 2,
    },
    {
      field: "data_termino",
      headerName: "Data Conclusão",
      flex: 2,
    },
  ];

  const dataTitleProtocoloVinculado: GridColDef[] = [
    { headerName: "Código do Protocolo", field: "codigo", flex: 1 },
  ];

  const dataTitleDocumento: GridColDef[] = [
    {
      field: "processo_arquivo",
      headerName: "Nome do Documento",
      flex: 3,
      headerClassName: "header_table",
    },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      headerClassName: "header_table",
      renderCell: (params) => {
        return (
          <>
            <Link to={{ pathname: `${params.row.url_processo_arquivo}/${params.row.processo_arquivo}` }} target="_blank">
              <Button variant="text">
                <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
              </Button>
            </Link>

          </>
        );
      }
    }
  ];

  return (
    <Container>
      <Form>
        <Title color="#333">
          <span>Visualizar Processo</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Número do processo - SIGAD:</TextTitle>
              <Input name="numero_processo" value={processo?.numero} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Código SIGA:</TextTitle>
              <Input name="codigo_siga" value={processo?.codigo} disabled />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Solicitante:</TextTitle>
              <Input name="solicitante" value={processo?.solicitante?.nome} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Esse processo possui um projeto?</TextTitle>
              <Input name="rateio" value={processo?.possui_projeto == true ? "Sim" : processo?.possui_projeto == false ? "Não" : ""} disabled />
            </Grid>
            <Grid xs="auto"></Grid>
            {processo?.possui_projeto && (
              <Grid xs={6}>
                <TextTitle>Código do Projeto:</TextTitle>
                <Input name="codigo_projeto" value={`${processo?.project?.id} - ${processo?.project?.nome}`} disabled />
              </Grid>
            )}
            <Grid xs={6}>
              <TextTitle>Código do Protocolo:</TextTitle>
              <Input name="codigo_protocolo" value={processo?.demand?.codigo} disabled />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Competências">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Esse processo é um rateio?</TextTitle>
              <Input
                name="rateio"
                value={processo?.rateio == true ? "Sim" : processo?.rateio == false ? "Não" : ""}
                disabled
              />
            </Grid>
            <Grid xs="auto"></Grid>
            {processo?.management_contracts?.length && (
              <Grid xs={6}>
                <SecondaryTable
                  columsData={dataTitleContratoGestao}
                  rowsData={processo?.management_contracts}
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Processo">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Categoria do processo:</TextTitle>
              <Input
                name="categoria_processo"
                value={processo?.law_process_category?.nome}
                disabled
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Assunto do processo:</TextTitle>
              <Textarea
                name="assunto_processo"
                value={processo?.assunto_processo}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados Institucionais">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Gestor do processo:</TextTitle>
              <Input
                name="gestor"
                value={processo?.gestor_processo?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Gestor subtitulo do processo:</TextTitle>
              <Input
                name="gestor_subtitulo"
                value={processo?.gestor_substituto?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Área responsável:</TextTitle>
              <Input
                name="area_responsavel"
                value={processo?.administration?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Local:</TextTitle>
              <Input
                name="local"
                value={processo?.unit?.nome}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Documentos">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Documentos de formalização:</TextTitle>
              <Textarea
                name="documentos"
                value={processo?.documento_abertura}
                disabled
              />
            </Grid>
            {processo?.url_processo_arquivo && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleDocumento}
                  rowsData={[processo]}
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Observação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Observação:</TextTitle>
              <Textarea
                name="observacao"
                value={processo?.observacao}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Protocolos">
          <Grid container spacing={6}>
            {processo?.law_process_demands?.length && (
              <>
                <Grid xs={6}>
                  <TextTitle>Protocolos vinculados:</TextTitle>
                  <SecondaryTable
                    columsData={dataTitleProtocoloVinculado}
                    rowsData={organizeDataProtocoolo(processo?.law_process_demands)}
                  />
                </Grid>
                <Grid xs="auto"></Grid>
              </>
            )}
          </Grid>
        </Panel>

        <Panel titulo='Norma Interna'>
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Número:</TextTitle>
              <Input type="text" name='norma_interna_numero' value={processo?.norma_interna_numero} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data de criação:</TextTitle>
              <Input name='norma_interna_criacao' value={formatarData(processo?.norma_interna_criacao, false)} disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Proton:</TextTitle>
              <Input type="text" name='norma_interna_codigo_proton' value={processo?.norma_interna_codigo_proton} disabled />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Planejamento de Processo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Categoria</TextTitle>
              <Input
                name="categoria"
                value={processo?.law_process_category?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Prazo para término do processo</TextTitle>
              <Input
                name="prazo_termino"
                value={formatarData(processo?.prazo_termino, false)}
                disabled
              />
            </Grid>
            {processo?.law_process_plannings &&
              processo?.law_process_plannings.length > 0 && (
                <Grid xs={12}>
                  <SecondaryTable
                    columsData={dataTitleAcoesPlanning}
                    rowsData={organizeDataActionPlanning(processo?.law_process_plannings)}
                  />
                </Grid>
              )}
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/processos" >
          <button className="button_return">
            <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
          </button>
        </Link>
        <Link to={`/processos/fichaPDF/${processoId}`} target="_blank">
          <button className="button_save">
            <FontAwesomeIcon icon={faDownload} size="1x" /> Download PDF
          </button>
        </Link>
      </ContainerButton2>
    </Container >
  );
};

export default ProcessoView;
