import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";
import { useToast } from "../../../hooks/toast";

const useDotacao = () => {

    const history = useHistory();
    const { addToast } = useToast();
    const [dotacao, setDotacao] = useState([]);
    const [dotacoes, setDotacoes] = useState([]);
    const [error, setError] = useState(null);

    async function getDotacoes(params?: object) {
        try {
            const response = await api.get(`allocations${stringfyParams(params)}`);
            setDotacoes(response.data);
        } catch (e) {
            setError(e);
        }
    };

    async function getDotacao(id: string) {
        try {
            const response = await api.get(`allocations/${id}`);
            setDotacao(response.data);
            return response.data;
        } catch (e) {
            setError(e);
        }
    };

    async function createDotacao(data: any) {
        try {
            const response = await api.post('allocations', data);
            addToast({
                type: 'success',
                title: 'Dotação cadastrada!',
                description: 'Dotação cadastrada com sucesso!',
            });
            history.push("/orcamentario/dotacao");

        } catch (e) {
            setError(e);
        }
    };

    async function updateDotacao(id: string, data: any) {
        try {
            const response = await api.put(`allocations/${id}`, data);
            addToast({
                type: 'success',
                title: 'Dotação atualizada!',
                description: 'Dotação atualizada com sucesso!',
            });
            history.push("/orcamentario/dotacao");

        } catch (e) {
            setError(e);
        }
    };


    return { dotacao, dotacoes, getDotacao, getDotacoes, createDotacao, updateDotacao, error };
};

export default useDotacao;