import { PDFViewer } from '@react-pdf/renderer'
import DocumentPage from '../../../../components/PDFComponents/DocumentPage';
import { useEffect, useState } from 'react';
import { formatCPF, formatarData, formatarReais } from '../../../../utils/formatDados';
import { useParams } from 'react-router-dom';
import useUsuario from '../../../../actions/cruds/useUsuario';
import useFerias from '../../../../actions/cruds/Rh/useFerias';

const RHEmpregadosFichaPDF: React.FC = () => {
  const { empregadoId } = useParams();
  const { usuario, getUser } = useUsuario();
  const { feriasList, getFeriasList } = useFerias();

  const [empregadoData, setEmpregadoData] = useState<Array<Object>>([]);
  const [empregadoFerias, setEmpregadoFerias] = useState<Array<Object>>([]);

  useEffect(() => {
    getUser(empregadoId);
  }, [])

  useEffect(() => {
    if (usuario?.id) {
      getFeriasList([{ label: "user_id", value: empregadoId }]);

      const updatedEmpregado = [];
      if (usuario?.employee_type?.key == 'empregado') {
        updatedEmpregado.push(
          {
            id: "estadoCivil",
            row: "Estado civil:",
            value: usuario?.estado_civil ?? "-",
          },
          {
            id: "cnh",
            row: "CNH:",
            value: `${usuario?.cnh} - Categoria: ${usuario?.cnh_categoria}\nVencimento: ${formatarData(usuario?.cnh_vencimento, false)}`,
          },
          {
            id: "sus",
            row: "Número do cartão SUS:",
            value: usuario?.cartao_sus ?? "-",
          },
          {
            id: "formacao",
            row: "Formação:",
            value: usuario?.formacao ?? "-",
          },
          {
            id: "grauInstrucao",
            row: "Grau de instrução:",
            value: usuario?.grau_instrucao ?? "-",
          },
        );
      } else if (usuario?.employee_type?.key == 'estagiario' || usuario?.employee_type?.key == 'jovem_aprendiz') {
        updatedEmpregado.push(
          {
            id: "estadoCivil",
            row: "Estado civil:",
            value: usuario?.estado_civil ?? "-",
          },
          {
            id: "vinculadoConvenio",
            row: "Vinculado a um convênio?",
            value: `[ ${usuario?.vinculado_convenio ? "X" : "  "} ] Sim           [ ${usuario?.vinculado_convenio ? "  " : "X"} ] Não`,
          },
          {
            id: "convenio",
            row: "Convênio:",
            value: usuario?.convenio ?? "-",
          },
          {
            id: "curso",
            row: "Curso:",
            value: usuario?.curso ?? "-",
          },
          {
            id: "periodoAtual",
            row: "Período Atual:",
            value: usuario?.periodo_atual ?? "-",
          },
          {
            id: "previsaoConclusao",
            row: "Previsão de conclusão:",
            value: formatarData(usuario?.previsao_conclusao, false) ?? "-",
          },
        )
      } else if (usuario?.employee_type?.key == 'terceirizado') {
        updatedEmpregado.push(
          {
            id: "formacao",
            row: "Formação:",
            value: usuario?.formacao ?? "-",
          },
        );
      };

      if (usuario?.user_contacts?.length > 0 && usuario?.user_contacts[0]?.id) {
        usuario?.user_contacts?.forEach((contato, index) => {
          if (index == 0) {
            updatedEmpregado.push(
              {
                id: "contatos",
                row: "",
                value: "Contatos",
              },
            )
          }
          updatedEmpregado.push(
            {
              id: "tipoContato",
              row: `Contato ${index + 1}:`,
              value: `${contato?.tipo} : ${contato?.contato}`
            },
          );
        });
      };

      updatedEmpregado.push(
        {
          id: "endereco",
          row: "Endereço:",
          value: `${usuario?.logradouro}, nº ${usuario?.numero} - ${usuario?.complemento}\n${usuario?.bairro}, ${usuario?.municipio} / ${usuario?.uf}\nCEP: ${usuario?.cep}`
        },
      );

      if (usuario?.employee_type?.key == 'estagiario' || usuario?.employee_type?.key == 'jovem_aprendiz' || usuario?.employee_type?.key == 'empregado') {
        updatedEmpregado.push(
          {
            id: "tipoCarteira",
            row: "Tipo de carteira profissional:",
            value: `[ ${usuario?.carteira_trabalho_tipo == 'fisica' ? "X" : "  "} ] Física           [ ${usuario?.carteira_trabalho_tipo == 'digital' ? "X" : "  "} ] Digital`,
          },
        );

        if (usuario?.carteira_trabalho_tipo == 'fisica') {
          updatedEmpregado.push(
            {
              id: "carteira",
              row: "Carteira de trabalho:",
              value: `Número: ${usuario?.carteira_trabalho_numero} - Série: ${usuario?.carteira_trabalho_serie}\nData de emissão: ${formatarData(usuario?.pis_data_emissao, false)}`,
            },
          )
        };

        updatedEmpregado.push(
          {
            id: "numeroPis",
            row: "Número do PIS:",
            value: usuario?.pis_numero ?? "-"
          },
          {
            id: "dadosBancarios",
            row: "Dados Bancários:",
            value: `Conta ${usuario?.banco_tipo_conta}\n${usuario?.banco_nome}\nAgência ${usuario?.banco_agencia}, Número ${usuario?.banco_conta}`
          },
        );
      };

      if (usuario?.employee_type?.key == 'empregado') {
        updatedEmpregado.push(
          {
            id: "numeroSeletivo",
            row: "Número do processo seletivo:",
            value: usuario?.numero_processo_seletivo,
          },
          {
            id: "numeroInscricao",
            row: "Número da inscrição:",
            value: usuario?.numero_inscricao ?? "-",
          },
          {
            id: "dataAssinatura",
            row: "Data de assinatura do contrato:",
            value: formatarData(usuario?.data_assinatura_contrato, false) ?? "-",
          },
          {
            id: "dataAdmissao",
            row: "Data de admissão:",
            value: formatarData(usuario?.data_admissao, false) ?? "-",
          },
          {
            id: "cargoAdmissao",
            row: "Cargo de admissao:",
            value: usuario?.cargo_admissao?.nome ?? "-",
          },
          {
            id: "cargoAtual",
            row: "Cargo atual:",
            value: usuario?.cargo_atual?.nome ?? "-",
          },
          {
            id: "dataDesligamento",
            row: "Data de desligamento:",
            value: formatarData(usuario?.data_desligamento, false) ?? "-",
          },
          {
            id: "email",
            row: "Email:",
            value: usuario?.email ?? "-",
          },
          {
            id: "organizacao",
            row: "Organização:",
            value: usuario?.organization?.nome ?? "-",
          },
          {
            id: "local",
            row: "Local de trabalho:",
            value: usuario?.local_trabalho?.nome ?? "-",
          },
          {
            id: "gerencia",
            row: "Gerência:",
            value: usuario?.gerencia?.nome ?? "-",
          },
          {
            id: "superior",
            row: "Superior imediato:",
            value: usuario?.superior_imediato?.nome ?? "-",
          },
          {
            id: "backup",
            row: "Backup:",
            value: usuario?.substituto?.nome ?? "-",
          },
          {
            id: "usoVeiculo",
            row: "Autorizado o uso de veículo?",
            value: `[ ${usuario?.uso_veiculo ? "X" : "  "} ] Sim           [ ${usuario?.uso_veiculo ? "  " : "X"} ] Não`,
          },
          {
            id: "salario",
            row: "Salário:",
            value: formatarReais(usuario?.salario),
          },
          {
            id: "areaAtuacao",
            row: "Área de atuação:",
            value: `[ ${usuario?.area_atuacao == 'meio' ? "X" : "  "} ] Área meio           [ ${usuario?.area_atuacao == 'fim' ? "X" : "  "} ] Área fim`,
          },
        );
      } else if (usuario?.employee_type?.key == 'estagiario' || usuario?.employee_type?.key == 'jovem_aprendiz') {
        updatedEmpregado.push(
          {
            id: "email",
            row: "Email:",
            value: usuario?.email ?? "-",
          },
          {
            id: "cargo",
            row: "Cargo:",
            value: usuario?.cargo_atual?.nome ?? "-",
          },
          {
            id: "gerencia",
            row: "Gerência:",
            value: usuario?.gerencia?.nome ?? "-",
          },
          {
            id: "superior",
            row: "Superior imediato:",
            value: usuario?.superior_imediato?.nome ?? "-",
          },
          {
            id: "dataAdmissao",
            row: "Data de admissão:",
            value: formatarData(usuario?.data_admissao, false) ?? "-",
          },
          {
            id: "dataDesligamento",
            row: "Data de desligamento:",
            value: formatarData(usuario?.data_desligamento, false) ?? "-",
          },
          {
            id: "organizacao",
            row: "Organização:",
            value: usuario?.organization?.nome ?? "-",
          },
          {
            id: "local",
            row: "Local de trabalho:",
            value: usuario?.local_trabalho?.nome ?? "-",
          },
          {
            id: "salario",
            row: "Salário:",
            value: formatarReais(usuario?.salario),
          },
          {
            id: "areaAtuacao",
            row: "Área de atuação:",
            value: `[ ${usuario?.area_atuacao == 'meio' ? "X" : "  "} ] Área meio           [ ${usuario?.area_atuacao == 'fim' ? "X" : "  "} ] Área fim`,
          },
        );

        if (usuario?.user_contracts?.length > 0 && usuario?.user_contracts[0]?.id) {
          usuario?.user_contracts?.forEach((contrato, index) => {
            if (index == 0) {
              updatedEmpregado.push(
                {
                  id: "contratos",
                  row: "",
                  value: "Contratos",
                },
              )
            }
            updatedEmpregado.push(
              {
                id: "tipocontrato",
                row: `Contrato ${index + 1}:`,
                value: `Data de assinatura: ${formatarData(contrato?.data_assinatura, false)}\nData de vencimento: ${formatarData(contrato?.data_vencimento, false)}`
              },
            );
          });
        };
      } else if (usuario?.employee_type?.key == 'terceirizado') {
        updatedEmpregado.push(
          {
            id: "cargo",
            row: "Cargo:",
            value: usuario?.cargo_atual?.nome ?? "-",
          },
          {
            id: "empresa",
            row: "Empresa:",
            value: usuario?.empresa?.nome_razao_social ?? "-",
          },
          {
            id: "dataAdmissao",
            row: "Data de admissão:",
            value: formatarData(usuario?.data_admissao, false) ?? "-",
          },
          {
            id: "dataDesligamento",
            row: "Data de desligamento:",
            value: formatarData(usuario?.data_desligamento, false) ?? "-",
          },
          {
            id: "projeto",
            row: "Projeto:",
            value: `${usuario?.project?.id} - ${usuario?.project?.nome}`,
          },
          {
            id: "email",
            row: "Email:",
            value: usuario?.email ?? "-",
          },
          {
            id: "gerencia",
            row: "Gerência:",
            value: usuario?.gerencia?.nome ?? "-",
          },
          {
            id: "gestor",
            row: "Gestor do contrato:",
            value: usuario?.gestor_contrato?.nome ?? "-",
          },
          {
            id: "organizacao",
            row: "Organização:",
            value: usuario?.organization?.nome ?? "-",
          },
          {
            id: "local",
            row: "Local de trabalho:",
            value: usuario?.local_trabalho?.nome ?? "-",
          },
        );
      };

      setEmpregadoData(updatedEmpregado);
    };

  }, [usuario]);

  useEffect(() => {
    if (feriasList?.data?.length > 0 && feriasList?.data[0]?.id) {
      console.log(feriasList.data)
      console.log("entrou")

      const updatedFerias = [];

      feriasList?.data?.forEach((ferias, index) => {
        if (index == 0) {
          updatedFerias.push(
            {
              id: "ferias",
              row: "",
              value: "Férias",
            },
          )
        }
        updatedFerias.push(
          {
            id: "periodoAquisitivo",
            row: `Férias ${index + 1}:`,
            value: `Período aquisitivo: ${formatarData(ferias?.periodo_aquisitivo_de, false)} a ${formatarData(ferias?.periodo_aquisitivo_ate, false)}\nPeríodo para gozo: ${formatarData(ferias?.periodo_gozo_de, false)} a ${formatarData(ferias?.periodo_gozo_ate, false)}\nQuantidade de dias: ${ferias?.quantidade_dias ?? "-"}\nPeríodo: ${formatarData(ferias?.inicio, false)} a ${formatarData(ferias?.termino, false)}`
          },
        );
      });

      setEmpregadoFerias(updatedFerias);
    };

  }, [feriasList])


  if (usuario?.id) {
    const pdfData = {
      version: "Formulário eletrônico - SIGA Águas",
      title: "FICHA DE EMPREGADO",
      items: [
        {
          id: "agevapImg",
          logoWithColumns: true,
          itemsColumn: [
            {
              id: "empregadoCpf",
              row: "CPF",
              value: formatCPF(usuario?.cpf) ?? "-"
            },
            {
              id: "empregadoNome",
              row: "NOME",
              value: usuario?.nome ?? "-"
            },
          ]
        },
        {
          id: "tipo",
          row: "Tipo de empregado:",
          value: usuario?.employee_type?.nome ?? "-"
        },
        {
          id: "genero",
          row: "Gênero:",
          value: usuario?.genero ?? "-"
        },
        {
          id: "dataNascimento",
          row: "Data de nascimento:",
          value: formatarData(usuario?.data_nascimento, false) ?? "-"
        },
        {
          id: "rg",
          row: "RG:",
          value: `${usuario?.rg} - ${usuario?.orgao_expedidor}` ?? "-"
        },
      ],
    };

    if (empregadoData?.length > 0) {
      empregadoData.forEach((item) => pdfData.items.push(item))
    };
    if (empregadoFerias?.length > 0) {
      empregadoFerias.forEach((ferias) => pdfData.items.push(ferias))
    };

    return (
      <PDFViewer width="100%" height="1300" className="app" >
        <DocumentPage data={pdfData} />
      </PDFViewer>
    );
  };
};

export default RHEmpregadosFichaPDF;