import { useEffect } from "react";
import useContratoGestao from "../../../../actions/cruds/useContratoGestao";
import MainTable from "../../../../components/MainTable";
import { Title } from "../../../../styles/global";
import { formatarData } from "../../../../utils/formatDados";
import { Container } from "./styles";
import { useAuth } from "../../../../hooks/auth";
import getPermission from "../../../../utils/getPermission";

export function ContratosGestao() {

  const { contrGestao, getContratos } = useContratoGestao();

  useEffect(() => {
    getContratos();
  }, [])

  const {login} = useAuth();
  const orçamentarioPermission = getPermission(login,"Orçamentário") ?? '';

  const organizeData = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        organization: d.organization.nome.nome,
        management_entity_id: d.management_entity_id,
        nome: d.nome,
        codigo: d.codigo,
        data_assinatura: formatarData(d.data_assinatura, false),
        prazo_delegacao: formatarData(d.prazo_delegacao, false),
        ativo: d.ativo
      }));
    }
    return data;
  }

  const columnData = [
    { headerName: "Código", field: "codigo" },
    { headerName: "Contrato de gestão", field: "nome" },
    { headerName: "Organização", field: "organization" },
    { headerName: "Data de assinatura", field: "data_assinatura" },
    { headerName: "Prazo de delegação", field: "prazo_delegacao" },
    { headerName: "Status", field: "ativo" }
  ];

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getContratos([{ label: "page", value: contrGestao.pagination.current_page + page }]);
  };

  return (
    <>
      <Title color='#333'>
        <span>
          Gestão de contratos de gestão
        </span>
      </Title>
      <Container color='#FFF'>
        <MainTable
          data={organizeData(contrGestao.data)}
          pagination={contrGestao.pagination}
          permission={orçamentarioPermission}
          handlePagination={handlePagination}
          hideFilter={true}
          registerLink="/orcamentario/cadastros/contratosgestao/cadastrar"
          columns={columnData}
          dropdown={{
            editar: { linkTo: "/orcamentario/cadastros/contratosgestao/editar" },
            desativar: { linkTo: "management_contracts", desactiveText: "Desativar" },
          }}
        />
      </Container>
    </>
  )
}