import React, { useEffect, useRef } from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ContainerButton,
  Input,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import Panel from "../../../../../components/Panel";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link, useParams } from "react-router-dom";
import useProcessoTipos from "../../../../../actions/cruds/Processos/useProcessoTipos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const TipoProcessoView: React.FC = () => {
  const { tipoProcessoId } = useParams();
  const { tipoProcesso, getTipoProcesso } = useProcessoTipos();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    getTipoProcesso(tipoProcessoId);
  }, []);

  return (
    <Container>
      <Title>
        <span>Visualizar Tipo do processo</span>
      </Title>

      <Form id="form1" ref={formRef}>
        <Panel titulo="Tipo do processo">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Nome:</TextTitle>
              <Input type="text" name="nome" value={tipoProcesso.nome} disabled />
            </Grid>
          </Grid>
        </Panel>

        <ContainerButton>
          <Link to="/processos/cadastros/tipoprocesso">
            <button>
              <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
            </button>
          </Link>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default TipoProcessoView;
