import { FormHandles } from "@unform/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../components/Panel";
import useUsuario from "../../../actions/cruds/useUsuario";

import {
  ContainerButton,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Title,
} from "../../../styles/global";

import { Container, FormControl } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "../../../components/InputRadio";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import useRepresentanteInstituicao from "../../../actions/cruds/useRepresentanteInstituicao";
import { formatCPF, pontuarCpf } from "../../../utils/formatDados";
import useModulo from "../../../actions/cruds/useModulo";
import usePerfilAcesso from "../../../actions/cruds/usePerfilAcesso";
import useLogin from "../../../actions/cruds/useLogin";
import { useToast } from "../../../hooks/toast";
import usePassword from "../../../actions/cruds/usePassword";
import { useAuth } from './../../../hooks/auth';


export function EditMyUser() {
  const { loginById, getLoginById, updateLogin } = useLogin();
  const { updatePassword } = usePassword();
  const { usuarios, getUsers } = useUsuario();
  const { representantesInstituicao, getRepresentantesInstituicao } = useRepresentanteInstituicao();
  const { modulos, getModulos } = useModulo();
  const { perfisAcesso, getPerfisAcesso } = usePerfilAcesso();
  const {login} = useAuth();
  const { loginId } = useParams();
  const {addToast} = useToast();

  const [tipoUsuario, setTipoUsuario] = useState<String>();
  const [valueCPF, setValueCPF] = useState<Object>({});
  const [valueNome, setValueNome] = useState<Object>({});
  const [dados, setDados] = useState([]);
  const [empregadoId, setEmpregadoId] = useState<Number>();
  const [representanteId, setRepresentanteId] = useState<Number>();
  const [defaultValueDados, setDefaultValueDados] = useState([]);

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    
    if(loginId != login.id.toString()) {
        addToast({
            type: 'error',
            title: 'Você não tem acesso!',
            description: 'Você não tem acesso a esse usuário!',
        });
        return;
    }

    getLoginById(loginId);

    getUsers([{ label: "ativo", value: true }]);
    getRepresentantesInstituicao([{ label: "ativo", value: true }]);
    getModulos([{ label: "ativo", value: true }, { label: "per", value: "200" }]);
    getPerfisAcesso([{ label: "ativo", value: true }, { label: "per", value: "200" }]);
  }, [])


  useEffect(() => {
    console.log(loginById);
    console.log(login.id);
    console.log(modulos);
    console.log(perfisAcesso);
    setTipoUsuario(loginById?.tipo_usuario);

    

    setDados(loginById?.ver_organizacoes);
    if (dados?.length > 0) {
      dados.forEach((obj) => {
        let initialValueDados = optionsDadosOrganizacoes.find((org) => org.value == obj)

        if (!defaultValueDados.some((item) => item.value === initialValueDados.value)) {
          setDefaultValueDados((prevValue) => [
            ...prevValue,
            initialValueDados
          ]);
        };

      });
    };

  }, [loginById])

  const handleSubmit = useCallback(
    async (data) => {
      try {

        console.log("teste")
        console.log(data);

        console.log(loginById);
        console.log(login.id);

        const perfilAcessoPorModulo = [];
        let counter = 851;

        Object.keys(data).forEach(obj => {
          const moduloPerfil = parseInt(obj, 10);
          console.log(moduloPerfil);
          if (!isNaN(moduloPerfil)) {
            const moduloEncontrado = modulos.data.find((modulo) => modulo.id == moduloPerfil);
            console.log(moduloEncontrado);
            if (moduloEncontrado) {
              
              console.log(loginById);
              console.log(loginById.login_profiles);

              //loginById.login_profiles.find((profile) =>)

              const idPerfilCadastrado = loginById.login_profiles == undefined ? [{id: counter}] : 
              loginById.login_profiles.filter((profile) => profile.profile.tbl_module.id == moduloEncontrado.id);
              
              if(loginById.login_profiles == undefined) {
                counter++;
              }
              console.log(idPerfilCadastrado);
              if(idPerfilCadastrado.length != 0) {
                console.log("xxxxxxxteste");
                perfilAcessoPorModulo.push({ id: idPerfilCadastrado[0].id, profile_id: data[obj] });
              } else {
                perfilAcessoPorModulo.push({  profile_id: data[obj] });
              }
              console.log("depoisss");
            }
          }
        });

        console.log(perfilAcessoPorModulo);

        if(loginById.login_profiles ==  undefined) {
          data["dados_usuario"] = loginById.id;
          data["cpf"] = pontuarCpf(loginById.cpf);
        }

        if (tipoUsuario == "empregado") {
          data["user_id"] = empregadoId;
          if (loginById?.representative) {
            data["representative_id"] = '';
          };
        } else if (tipoUsuario == 'representante') {
          data["representative_id"] = representanteId;
          if (loginById?.user) {
            data["user_id"] = '';
          };
        } else if (tipoUsuario == 'externo') {
          if (loginById?.representative) {
            data["representative_id"] = '';
          } else if (loginById?.user) {
            data["user_id"] = '';
          };
        };


        data["login_profiles_attributes"] = perfilAcessoPorModulo;
        data["ver_organizacoes"] = dados;
        
        console.log("dataSubmit", data, loginId);
        updateLogin(loginId, data);

      } catch (err) {
      }
    }, [loginId, dados, modulos, empregadoId, representanteId, tipoUsuario]
  );

  const organizeDataCPF = (data: any) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.cpf,
        label: pontuarCpf(d.cpf),
        id: d.id,
        nome: d.nome,
        email: d.email,
      }));
    }
    return data;
  };

  const organizeDataNome = (data: any) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.nome,
        label: d.nome,
        id: d.id,
        cpf: d.cpf,
        email: d.email,
      }));
    }
    return data;
  };

  const organizeDataPermissao = (data: any, id: string) => {
    if (typeof data === 'object') {
      data = data.filter((perm) => perm.tbl_module?.id == id);

      data = data.map(d => ({
        value: d.id,
        // label: `${d.id} - ${d.nome} - ${d.tbl_module?.nome}`, Verifica id e nome do múdulo durante o desenvolvimento
        label: d.nome,
        module: d.tbl_module?.id,
      }));
    };

    return data;
  };

  const organizeDataPermissaoDefault = (data: any, id: string) => {
    if (typeof data === 'object') {
      data = data.filter((perm) => perm.profile.tbl_module?.id == id);


      data = data.map(d => ({
        value: d.profile.id,
        // label: `${d.profile.id} - ${d.profile.nome} - ${d.profile.tbl_module?.nome}`, Verifica id e nome do múdulo durante o desenvolvimento
        label: d.profile.nome,
        id_perfil: d.id,
      }));
    };

    return data;
  };

  const optionsTipoUsuario = [
    { value: "empregado", label: " Empregado" },
    { value: "representante", label: " Representante" },
    { value: "externo", label: " Acesso externo (novo usuário)" },
  ];

  const optionsDadosOrganizacoes = [
    { value: 1, label: "AGEVAP" },
    { value: 2, label: "AGEDOCE" },
  ]

  const dataTitlePermissao: GridColDef[] = [
    { field: "id", headerName: "Item", flex: 1 },
    { field: "nome", headerName: "Módulo", flex: 2 },
    {
      field: "permissao",
      headerName: "Permissão",
      cellClassName: "muiDataGridCellOverflowVisible",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <FormControl fullWidth={true}>
            {
              perfisAcesso.data && (
                <SelectInput
                  name={`${params.row.id}`}
                  defaultValue={organizeDataPermissaoDefault(loginById.login_profiles, params.row.id)}
                  options={organizeDataPermissao(perfisAcesso.data, params.row.id)}
                  required
                >
                </SelectInput>
              )}
          </FormControl>
        )
      }
    },
  ];

  const handlerChangeTipoUsuarioRadio = () => {
    const tipoUsuarioRadioField = formRef.current?.getFieldValue("tipo_usuario");
    setTipoUsuario(tipoUsuarioRadioField);
    formRef.current?.setFieldValue("email", "")
    setValueCPF({});
    setValueNome({})
  };

  const resetValues = () => {
    setValueNome({});
    setValueCPF({});
    formRef.current?.setFieldValue("email", "");
  };

  const handlerChangeCpf = (cpfSelected: object) => {
    if (!cpfSelected) {
      resetValues();
      return;
    };
    console.log(cpfSelected);

    setEmpregadoId(cpfSelected?.id);
    setRepresentanteId(cpfSelected?.id);

    setValueCPF(cpfSelected);
    setValueNome({ value: cpfSelected?.nome, label: cpfSelected?.nome })
    formRef.current?.setFieldValue("email", cpfSelected?.email);
  };

  const handlerChangeNome = (nomeSelected: object) => {
    if (!nomeSelected) {
      resetValues();
      return;
    };
    console.log(nomeSelected)

    setEmpregadoId(nomeSelected?.id);
    setRepresentanteId(nomeSelected?.id);

    setValueNome(nomeSelected);
    setValueCPF({ value: nomeSelected?.cpf, label: pontuarCpf(nomeSelected?.cpf) })
    formRef.current?.setFieldValue("email", nomeSelected?.email ? nomeSelected.email : "");
  };

  const handlerDados = (optionDados: Array<Object>) => {
    let dadosHelper = [];
    optionDados.map((o) => dadosHelper.push(o.value));
    setDados(dadosHelper);

    if (optionDados.length) {
      const dadosValues = dadosHelper.map((obj) =>
        optionsDadosOrganizacoes.find((org) => org.value === obj)
      );
      setDefaultValueDados(dadosValues);

    } else {
      setDefaultValueDados([]);
    }
  };

  const changePassword = () => {
    console.log("clicou");
    const oldPasswordField = formRef.current?.getFieldValue("old_password");
    const newPasswordField = formRef.current?.getFieldValue("new_password");
    const confirmNewPasswordField = formRef.current?.getFieldValue("confirm_new_password");

    if(oldPasswordField == '' || newPasswordField == '' || confirmNewPasswordField == '') {
      addToast({
        type: 'error',
        title: 'Preencha todos os campos de senha',
        description: 'Todos os campos de senha precisam ser preenchidos!',
    });
    return;
    }

    if(newPasswordField != confirmNewPasswordField) {
      addToast({
        type: 'error',
        title: 'Senhas diferentes',
        description: 'As senhas estão diferentes, por favor confirme novamente!',
    });
    return;
    }

    console.log(oldPasswordField);
    console.log(loginById);

    updatePassword(loginById.id, {
      old_password: oldPasswordField,
      password: newPasswordField
    });

  }


  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Edição de usuário</span>
        </Title>
        
        {loginById && (
          <>
            <Panel titulo="Alteração da senha">
                <Grid xs={12} lg={6}>
                  <TextTitle>Senha antiga: </TextTitle>
                    <Input type="password" name="old_password"/>
                </Grid>
                <Grid xs={12} lg={6} style={{marginTop: "15px"}}>
                  <TextTitle>Nova Senha: </TextTitle>
                  <Input type="password" name="new_password"/>
                  <TextTitle style={{marginTop: "15px"}}>Confirme a nova senha: </TextTitle>
                  <Input type="password" name="confirm_new_password"/>
                </Grid>
                <ContainerButton>
          
                  <button onClick={changePassword} type="button" style={{marginTop: "30px"}}>
                    <FontAwesomeIcon icon={faSave} /> Alterar Senha
                  </button>
                </ContainerButton>
            </Panel>
          </>
        )}
      </Form>
    </Container>
  );
};