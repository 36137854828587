import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useComite = () => {

    const history = useHistory();
    const [comites, setComites] = useState([]);
    const [selectComite, setSelectComite] = useState({
        management_contract: {
            organization: {
                nome: {}
            },
            management_entity: {}
        },
        units: []
    });
    const [error, setError] = useState(null);

    async function getComittees(params) {
        try {
            const response = await api.get(`comittees${stringfyParams(params)}`);
            setComites(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getComittee(id: number) {
        try {
            const response = await api.get(`comittees/${id}`);
            setSelectComite(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createComittees(data) {
        try {
            const response = await api.post('comittees', data);
            alert("Comitê criado!");
            history.push("/orcamentario/cadastros/comites")
            
            // setUsuarios(response.data);
            return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }
    
    async function updateComittees(id: string, data: any){
        try {
            const response = await api.put(`comittees/${id}`, data);
            alert("Comitê alterado!");
            history.push("/orcamentario/cadastros/comites")

        } catch(e){
            setError(e);
        }
    }

    useEffect(() => {
        // getComittees();
    }, []);

    return {selectComite, comites, getComittee, getComittees, createComittees, setComites, setSelectComite, updateComittees, error};
}

export default useComite;