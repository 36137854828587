import React, { useEffect, useRef } from "react";
import {
  ContainerButton2,
  Form,
  Input,
  TextTitle,
  Title,
} from "../../../../styles/global";
import Panel from "../../../../components/Panel";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import useReceita from "../../../../actions/cruds/Orcamentario/useReceita";
import useOrgao from "../../../../actions/cruds/useOrgao";
import useContratoGestao from "../../../../actions/cruds/useContratoGestao";
import useComite from "../../../../actions/cruds/useComite";
import { SubTitle } from "../styles";

const OrcamentarioReceitaView: React.FC = () => {
  const { receitaId } = useParams();
  const { reiceta, getReceita } = useReceita();
  const { orgaos, getOrganizations } = useOrgao();
  const { contrGestao, getContratos } = useContratoGestao();
  const { comites, getComittees } = useComite();

  useEffect(() => {
    getOrganizations([{ label: "ativo", value: true }]);
    getContratos([{ label: "ativo", value: true }]);
    getComittees([{ label: "ativo", value: true }]);

  }, []);

  const formRef = useRef<FormHandles>(null);

  return (
    <Container>
      <Form id="formReceita" ref={formRef}>
        <Title color="#333">
          <span>Visualizar Receita anual por Comitê</span>
        </Title>

        <Panel titulo="Receita - Mês">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Organização:</TextTitle>
              <Input type="text" name="organization" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Contrato de Gestão:</TextTitle>
              <Input type="text" name="management_contract" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Comitê:</TextTitle>
              <Input type="text" name="comittee" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Tipo de receita:</TextTitle>
              <Input type="text" name="tipo_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Ano da receita</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Mês:</TextTitle>
              <Input type="text" name="mes_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>*Data da receita*</TextTitle>
              <Input type="month" name="data_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor</TextTitle>
              <Input name="valor_receita" format="currency" currency="BRL" disabled />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Receita Anual">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Estimado - POA:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Saldo ano anterior:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor repassado:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor rendimento:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Ano da receira:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor disponível:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
            <Grid xs={12}>
              <SubTitle>Indicador</SubTitle>
            </Grid>
            <Grid xs={6}>
              <TextTitle>Estimado POA / DISPONÍVEL:</TextTitle>
              <Input type="text" name="ano_receita" disabled />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/orcamentario/receita">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
      </ContainerButton2>
    </Container>
  );
};

export default OrcamentarioReceitaView;
