import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Step, StepLabel, Stepper } from '@mui/material';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ButtonAdd, ContainerButton, ContainerButton2, CreatableSelectInput, Input, SelectInput, TextTitle, Title } from '../../../../../styles/global';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import useCargo from '../../../../../actions/cruds/useCargo';
import { FormHandles } from '@unform/core';
import organizeDataSelect from '../../../../../utils/organizeDataSelect';
import useUsuario from '../../../../../actions/cruds/useUsuario';
import Panel from '../../../../../components/Panel';
import { steps } from '../..';
import SecondaryTable from '../../../../../components/SecondaryTable';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import usePap from '../../../../../actions/cruds/usePap';
import usePapFinalidade from '../../../../../actions/cruds/usePapFinalidade';
import usePapPrograma from '../../../../../actions/cruds/usePapPrograma';
import usePoa from '../../../../../actions/cruds/usePoa';
import usePoaSubAcao from '../../../../../actions/cruds/usePoaSubacao';
import { useToast } from '../../../../../hooks/toast';

// import { Container } from './styles';


const PoaEditSubacao: React.FC = () => {

    const {idPoa} = useParams();

    const {getPap, selectedPap, updatePap} = usePap();
    const {getPoa, selectedPoa, getPoas, poas, updatePoa} = usePoa();
    const {getPoaSubacoes, poaSubacoes, newPoaSubacao, getPoaSubacao, setPoaSubacao, poaSubacao, createPoaSubacao, updatePapSubacao} = usePoaSubAcao(); 
    const { addToast } = useToast();

    const [subactionsOptions, setSubactionsOptions] = useState([]);
    const [newSubacao, setNewSubacao] = useState(true);
    const [finaldadeAutomatica, setFinalidadeAutomatica] = useState<object>([]);
    const [programaAutomatico, setProgramaAutomatico] = useState<object>([]);
    const [acaoAutomatica, setAcaoAutomatica] = useState<object>([]);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        // getPap(idPap);
        getPoa(idPoa);
        getPoaSubacoes([{label: 'per', value: 200}]);
    }, []);

    // useEffect(() => {
    //     const {pap_finality} = papPrograma;
    //     if (pap_finality) {
    //         setFinalidadeAtual(organizeDataSelect([pap_finality], "id", "nome"));
    //     }
    // }, [papPrograma]);

    useEffect(() => {
        const {pap} = selectedPoa;
        if (pap.id) {
            getPap(pap.id);
        }
    }, [selectedPoa]);

    useEffect(() => {
        if (newPoaSubacao.id) {
            updatePoa(idPoa, {subacao_ids: [...selectedPoa.subacoes.map(f => f.id), newPoaSubacao.id]});
        }
    }, [newPoaSubacao]);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => { 
        try {            
            if (newSubacao) {
                createPoaSubacao(data);
            } else {
                updatePoa(idPoa, {subacao_ids: [...selectedPoa.subacoes.map(f => f.id), poaSubacao.id]});
            }

            // addToast({
            //   type: 'success',
            //   title: 'Login efetuado',
            //   description: 'Seu login foi feito com sucesso!',
            // });

            // history.push('/dashboard');
        } catch (err) {    
            // addToast({
            //   type: 'error',
            //   title: 'Erro na autenticação',
            //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
            // });
        }
        },
        [poaSubacao],
    );

    const form2Ref = useRef<FormHandles>(null);

    const handleSubmit2 = useCallback(
        async (data: object) => { 
            try {
                const subacaoId = formRef.current?.getFieldValue("nome");

                await updatePapSubacao(subacaoId, data);

                getPoa(idPoa);
                getPoaSubacoes([{label: 'per', value: 200}]);

                formRef.current.reset();
                setPoaSubacao({});
                setNewSubacao(true);
                setOpen(false);

            } catch (err) {}
        },
        [addToast, open],
    );

    const [activeStep] = useState(1);

    const dataTitle: GridColDef[] = [
        { field: "finalidade", headerName: "Finalidade", flex: 2 },
        { field: "programa", headerName: "Programa", flex: 2 },
        { field: "acao", headerName: "Ação", flex: 2 },
        { field: "subacao", headerName: "Sub-ação", flex: 3 },
        {
          field: "actions",
          type: 'actions',
          headerName: "Ações",
          description: "Ações por coluna.",
          sortable: false,
          flex: 1,
          getActions: ({ id }) => {
            return [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon="fa-solid fa-trash-can" />}
                  label="Delete"
                  onClick={() => handleDeleteClick(id)}
                  color="inherit"
                  sx={{color: "#e62121"}}
                />
            ];
          }
        }
    ];

    function handleDeleteClick(id: GridRowId): React.MouseEventHandler<HTMLButtonElement> | undefined {
        updatePoa(idPoa, {subacao_ids: [...selectedPoa.subacoes.filter(f => f.id !== id).map(f => f.id)]});
    }

    const changeAcao = async (acaoSelected: object) => {
        setAcaoAutomatica(acaoSelected);
        setFinalidadeAutomatica([]);
        setProgramaAutomatico([]);
        setSubactionsOptions([]);

        if (acaoSelected) {
            setFinalidadeAutomatica(
                selectedPap.acoes.filter(p => p.id == acaoSelected.value).map(m => {
                    return {
                        label: `${m.pap_program.pap_finality.codigo} - ${m.pap_program.pap_finality.nome}`, value: m.pap_program.pap_finality.id
                    };
                })
            );

            setProgramaAutomatico(
                selectedPap.acoes.filter(p => p.id == acaoSelected.value).map(m => {
                    return {
                        label: `${m.pap_program.codigo} - ${m.pap_program.nome}`, value: m.pap_program.id
                    };
                })
            );

            await getPoaSubacoes([{label: 'pap_action_id', value: acaoSelected.value}]);
            // setSubactionsOptions(organizeDataSelect(poaSubacoes.data.filter(e => e.pap_action.id == acaoSelected.value), "id", "codigo", ["nome"]) || []);
            setSubactionsOptions(organizeDataSelect(poaSubacoes.data, "id", "codigo", ["nome"]) || []);
        }
    }

    useEffect(() => { // Opções do select de subação
        const {acoes} = selectedPap;

        if (selectedPap.id && poaSubacoes.data) { // So retorna as Subacoes para SELECIONAR que tem relação com as acoes disponiveis (previamente cadastradas)
            const filtredAcoes = acoes.map(i => i.id);
            setSubactionsOptions(organizeDataSelect(poaSubacoes.data.filter(e => filtredAcoes.includes(e.pap_action.id)), "id", "codigo", ["nome"]) || []);
        }
    }, [poaSubacoes, selectedPap]);

    const changeSubacao = (subAcaoSelected: object) => {
        // console.log(subAcaoSelected[0].value);
        if (subAcaoSelected !== null) {
            if (subAcaoSelected && subAcaoSelected.__isNew__) {
                setNewSubacao(true);
                setPoaSubacao({});
                
            } else {
                formRef.current?.clearField("pap_finality_id");
                formRef.current?.clearField("pap_program_id");
                formRef.current?.clearField("pap_action_id");
                formRef.current?.clearField("codigo");
                setNewSubacao(false);
                getPoaSubacao(subAcaoSelected.value);
            }
        }
    }

    useEffect(() => { // Preenche os campos caso uma subacao previamente criada for selecionada
        const {pap_action} = poaSubacao;
        if (pap_action) {
            const {pap_program} = pap_action;
            const {pap_finality} = pap_program;

            setFinalidadeAutomatica(
                [
                    {
                        label: `${pap_finality.codigo} - ${pap_finality.nome}`,
                        value: pap_finality.id
                    }
                ]
            );
            setProgramaAutomatico(
                [
                    {
                        label: `${pap_program.codigo} - ${pap_program.nome}`,
                        value: pap_program.id
                    }
                ]
            );
            setAcaoAutomatica(
                [
                    {
                        label: `${pap_action.codigo} - ${pap_action.nome}`,
                        value: pap_action.id
                    }
                ]
            );
        }
    }, [poaSubacao]);

    const organizeData = (data: any[]) => {
        if (typeof data === 'object') {
          data = data.map(d => ({
            id: d.id,
            finalidade: `${d.pap_action.pap_program.pap_finality.codigo} - ${d.pap_action.pap_program.pap_finality.nome}`,
            programa: `${d.pap_action.pap_program.codigo} - ${d.pap_action.pap_program.nome}`,
            acao: `${d.pap_action.codigo} - ${d.pap_action.nome}`,
            subacao: `${d.codigo} - ${d.nome}`,
          }));
        }
        return data;
    }

    return (
        <Container>
            <Title>
                <span>POA: Gerenciamento de Sub-ação</span>
            </Title>

            <Stepper activeStep={activeStep} alternativeLabel sx={{my: 5}}>
                {steps.map(({label}, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Sub-Ação'>
                    <Grid container spacing={6}>
                        <Grid xs={12}>
                            <TextTitle>Finalidade: <span>*</span></TextTitle>
                            <SelectInput
                                id="pap_finality_id" 
                                name="pap_finality_id"
                                value={finaldadeAutomatica}
                                options={[]}
                                placeholder="Apenas para consulta"
                                isDisabled
                                required
                            />
                        </Grid>

                        <Grid xs={12}>
                            <TextTitle>Programa: <span>*</span></TextTitle>
                            <SelectInput
                                id="pap_program_id"
                                name="pap_program_id"
                                value={programaAutomatico}
                                options={[]}
                                placeholder="Apenas para consulta"
                                isDisabled
                                required
                            />
                        </Grid>

                        <Grid xs={12}>
                            <TextTitle>Ação: <span>*</span></TextTitle>
                            <SelectInput
                                id="pap_action_id"
                                name="pap_action_id"
                                value={acaoAutomatica}
                                options={selectedPap ?
                                    organizeDataSelect(selectedPap.acoes.filter((d) => d.ativo == true), "id", "codigo", ["nome"])
                                    : []}
                                placeholder="Selecione"
                                isDisabled={!newSubacao}
                                // onInputChange={(e) => {
                                //     getPapProgramas([{ label: "nome", value: e }]);
                                // }}
                                onChange={a => changeAcao(a)}
                                required
                            />
                        </Grid>

                        <Grid xs={12} lg={2}>
                            <TextTitle>Código: <span>*</span></TextTitle>
                            <Input
                                mask="__"
                                replacement="allNumber"
                                id="codigo" 
                                name='codigo' 
                                required
                                defaultValue={poaSubacao.codigo ?? ""}
                                disabled={!newSubacao}
                            />
                        </Grid>
                        <Grid xs={12} lg={10}>
                            <TextTitle>Sub-ação: <span>*</span></TextTitle>
                            <div style={{display: "flex", alignItems: 'center'}}>
                                <CreatableSelectInput
                                    id="nome"
                                    name="nome"
                                    // options={organizeDataSelect(poaSubacoes.data, "id", "codigo", ["nome"])}
                                    options={subactionsOptions}
                                    placeholder="Selecione ou crie uma nova Sub-ação"
                                    // onInputChange={(e) => {
                                    //     getPoaSubacoes([{ label: "nome", value: e }]);
                                    // }}
                                    onChange={changeSubacao}
                                    required
                                />
                                {!newSubacao && 
                                    <div>
                                        <IconButton color="primary" aria-label="editar" onClick={e => setOpen(true)}>
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </IconButton>
                                    </div>
                                }
                            </div>
                            
                        </Grid>

                        <Grid xs={12}>
                            <Grid xs={12}>
                                <ButtonAdd type="submit" onClick={null}><FontAwesomeIcon icon={faPlus} /> Adicionar</ButtonAdd>
                            </Grid>
                            <SecondaryTable
                                // hideFooter={true}
                                rowsData={organizeData(selectedPoa.subacoes) || []}
                                columsData={dataTitle}
                            />
                        </Grid>
                    </Grid>
                </Panel>

                <ContainerButton2>
                    <Link
                        to={`/orcamentario/poa/editar-identificacao/${idPoa}`}
                        className="button_return"
                        style={{ textDecoration: "none", color: "black" }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} /> Voltar
                    </Link>
                    <div>
                        <Link
                            to={"/orcamentario/poa"}
                            className="button_next"
                            style={{ textDecoration: "none" }}
                        >
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                color="#ffffff"
                                style={{ marginRight: 10 }}
                            />
                                Finalizar
                        </Link>
                    </div>
                </ContainerButton2>

            </Form>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>Editar Sub-ação</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    A edição da Sub-ação por aqui altera também seu vínculo em outros PAPs.
                </DialogContentText>

                <Form id="form2" ref={form2Ref} onSubmit={handleSubmit2} >
                    
                    <Grid container spacing={3} style={{marginTop: "10px"}}>
                    <Grid xs={12}>
                            <TextTitle>Finalidade: <span>*</span></TextTitle>
                            <SelectInput
                                id="pap_finality_id" 
                                name="pap_finality_id"
                                value={finaldadeAutomatica}
                                options={[]}
                                placeholder="Apenas para consulta"
                                isDisabled
                                required
                            />
                        </Grid>

                        <Grid xs={12}>
                            <TextTitle>Programa: <span>*</span></TextTitle>
                            <SelectInput
                                id="pap_program_id"
                                name="pap_program_id"
                                value={programaAutomatico}
                                options={[]}
                                placeholder="Apenas para consulta"
                                isDisabled
                                required
                            />
                        </Grid>

                        <Grid xs={12}>
                            <TextTitle>Ação: <span>*</span></TextTitle>
                            <SelectInput
                                id="pap_action_id"
                                name="pap_action_id"
                                value={acaoAutomatica}
                                options={selectedPap ?
                                    organizeDataSelect(selectedPap.acoes.filter((d) => d.ativo == true), "id", "codigo", ["nome"])
                                    : []}
                                placeholder="Selecione"
                                // onInputChange={(e) => {
                                //     getPapProgramas([{ label: "nome", value: e }]);
                                // }}
                                onChange={a => changeAcao(a)}
                                required
                            />
                        </Grid>

                        <Grid xs={2}>
                            <TextTitle>Código: <span>*</span></TextTitle>
                            <Input
                                mask="__"
                                replacement="allNumber"
                                id="codigo" 
                                name='codigo' 
                                required
                                defaultValue={poaSubacao.codigo ?? ""}
                            />
                        </Grid>
                        <Grid xs={10}>
                            <TextTitle>Sub-ação: <span>*</span></TextTitle>
                            <Input
                                name="nome"
                                type="text"
                                
                                defaultValue={poaSubacao.nome ?? ""}
                                required
                            />
                        </Grid>
                    </Grid>
                    
                </Form>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button type="submit" variant="contained" form="form2">Salvar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default PoaEditSubacao;
