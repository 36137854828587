import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const usePap = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const [paps, setPaps] = useState([]);
  const [selectedPap, setSelectedPap] = useState({
    comittee: {
      management_contract: {
        organization: { nome: {} }
      }
    },
    finalidades: [],
    programas: [],
    acoes: []
  });
  const [newPap, setNewPap] = useState({});
  const [budget, setBudget] = useState([
    {
      comittee: {},
      pap_finalities: [
        {
          pap_programs: [
            {
              pap_actions: []
            }
          ]
        }
      ],

    }
  ]);

  async function getPaps(params?: object) {
    try {
      const response = await api.get(`paps${stringfyParams(params)}`);
      setPaps(response.data);
    } catch (e) { }
  }

  async function getPap(id: number) {
    try {
      const response = await api.get(`paps/${id}`);
      setSelectedPap(response.data);
    } catch (e) { }
  }

  async function createPap(data: object) {
    try {
      const response = await api.post('paps', data);
      addToast({
        type: 'success',
        title: 'Pap criado!',
        description: 'Novo PAP foi cadastrado.',
      });
      setNewPap(response.data);
    } catch (e) { }
  }

  async function updatePap(id: number, data: object) {
    try {
      const response = await api.put(`paps/${id}`, data);

      addToast({
        type: 'success',
        title: 'PAP alterado!',
        description: 'Alteração no PAP foi realizada com sucesso!',
      });
      getPap(id);
    } catch (e) { }
  }

  async function getPapBudget(params?: object) {
    try {
      const response = await api.get(`paps/budget/${stringfyParams(params)}`);
      setBudget(response.data);
    } catch (e) { }
  }

  return {
    selectedPap,
    paps,
    getPaps,
    getPap,
    createPap,
    newPap,
    updatePap,
    setPaps,
    setSelectedPap,
    getPapBudget,
    budget
  };
};

export default usePap;
