import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { faPlus, faSave, faX } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";

import Panel from "../../../../../components/Panel";

import {
  ButtonAdd,
  ContainerButton,
  Form,
  FormContainer,
  Input,
  InputAddBtn,
  SelectInput,
  TextTitle,
  Title,
} from "../../../../../styles/global";

import Radio from "../../../../../components/InputRadio";
import useEndereco from "../../../../../actions/cruds/useEndereco";
import SecondaryTable from "../../../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import organizeDataSelect from "../../../../../utils/organizeDataSelect";
import useOrgao from "../../../../../actions/cruds/useOrgao";
import useUnidade from "../../../../../actions/cruds/useUnidade";
import useUsuario from "../../../../../actions/cruds/useUsuario";
import useCargo from "../../../../../actions/cruds/useCargo";
import useGerencia from "../../../../../actions/cruds/useGerencia";
import useProjeto from "../../../../../actions/cruds/useProjeto";
import useFornecedor from "../../../../../actions/cruds/useFornecedor";
import { Link } from "react-router-dom";
import InputImage from "../../../../../components/InputImage";
import Grid from '@mui/material/Unstable_Grid2';
import emailChecker from "../../../../../utils/emailChecker";
import { pontuarCpf } from "../../../../../utils/formatDados";

export function EditTerceirizado() {
  const { updateUsers, usuario, getUser, usuarios, getUsers } = useUsuario();
  const { empregadoId } = useParams();
  const { getAddress, endereco, setEndereco } = useEndereco();
  const { orgaos, getOrganizations } = useOrgao();
  const { unidades, getUnidades } = useUnidade();
  const { cargos, getCargos } = useCargo();
  const { gerencias, getGerencias } = useGerencia();
  const { projetos, getProjetos } = useProjeto();
  const { fornecedores, getFornecedores } = useFornecedor();

  const [selectedOption, setSelectedOption] = useState({});

  const [valueGenero, setValueGenero] = useState([]);
  const [valueGrauInstrucao, setValueGrauInstrucao] = useState([]);
  const [valueEstadoCivil, setValueEstadoCivil] = useState([]);
  const [contactsFront, setContactsFront] = useState([]);
  const [contactsBack, setContactsBack] = useState([]);
  const [valueCargoAtual, setValueCargoAtual] = useState([]);
  const [valueOrganizacaoTrabalho, setValueOrganizacaoTrabalho] = useState([]);
  const [valueLocalTrabalho, setValueLocalTrabalho] = useState([]);
  const [valueGerencia, setValueGerencia] = useState([]);
  const [valueGestor, setValueGestor] = useState([]);
  const [valueProjeto, setValueProjeto] = useState([]);
  const [valueEmpresa, setValueEmpresa] = useState([]);

  useEffect(() => {
    console.log("tela de Terceirizado");
    getUser(empregadoId);

    getUsers([{ label: 'ativo', value: true }]);
    getOrganizations([{ label: 'ativo', value: true }]);
    getUnidades([{ label: 'ativo', value: true }]);
    getCargos([{ label: 'ativo', value: true }]);
    getGerencias([{ label: 'ativo', value: true }]);
    getProjetos([{ label: 'ativo', value: true }]);
    getFornecedores([{ label: 'ativo', value: true }]);
  }, []);

  useEffect(() => {

    const initialValueGenero = optionsGenero.find(o => o.value === usuario.genero);
    setValueGenero(initialValueGenero);

    const initialValueGrauInstrucao = optionsGrau.find(o => o.value === usuario.grau_instrucao);
    setValueGrauInstrucao(initialValueGrauInstrucao);

    const initialValueEstadoCivil = optionsEstadoCivil.find(o => o.value === usuario.estado_civil);
    setValueEstadoCivil(initialValueEstadoCivil);

    if (usuario.cep) {
      getAddress(usuario.cep);
    }
    ;
    if (usuario.user_contacts) {
      const contactMap = organizeDataContact(usuario.user_contacts)
      setContactsFront([...contactMap]);
    };

    if (usuario.cargo_atual) {
      setValueCargoAtual(organizeDataSelect([usuario.cargo_atual], "id", "nome"));
    };

    if (usuario.organization) {
      setValueOrganizacaoTrabalho(organizeDataSelect([usuario.organization], "id", "nome"));
    };

    if (usuario.local_trabalho) {
      setValueLocalTrabalho(organizeDataSelect([usuario.local_trabalho], "id", "nome"));
    };

    if (usuario.gerencia) {
      setValueGerencia(organizeDataSelect([usuario.gerencia], "id", "nome"));
    }

    if (usuario.empresa) {
      setValueEmpresa(organizeDataSelect([usuario.empresa], "id", "nome_razao_social"));
    };

    if (usuario.project) {
      setValueProjeto(organizeDataSelect([usuario.project], "id", "nome"));
    }

    if (usuario.gestor_contrato) {
      setValueGestor(organizeDataSelect([usuario.gestor_contrato], "id", "nome"));
    };

  }, [usuario]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {

        const dataDesligamento = formRef.current?.getFieldValue("data_desligamento");
        if (dataDesligamento) {
          data["ativo"] = false;
        };

        data["user_contacts_attributes"] = contactsBack;

        console.log("data", data);
        updateUsers(empregadoId, data);
      } catch (err) {
        console.log(err);
      }
    },
    [usuario, contactsBack]
  );

  const organizeDataContact = (data) => {

    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          front_id: d.id,
          tipo: d.tipo,
          contato: d.contato,
        }));
        return data;
      }
    }
    return [];
  };

  const organizeDataEmpresas = (data) => {
    if (typeof data === "object") {
      if (Object.keys(data).length > 0) {
        data = data.map((d) => ({
          value: d.id,
          label: `${d.cpf_cnpj} - ${d.nome_razao_social}`,
        }));
        return data;
      }
    }
    return [];
  };

  const handlerContact = (btn) => {
    const randon_id = Date.now();
    const tipoContato = btn.target.id;

    if (tipoContato === 'email') {
      const emailValue = formRef.current?.getFieldValue("email");
      const emailValido = emailChecker(emailValue);

      if (!emailValido) {
        alert("Email inválido! Insira um email valido. Ex: 'email@email.com'");
        return;
      }
    };

    if (tipoContato != "telefone" && tipoContato != "email") {
      return;
    };

    setContactsFront([
      ...contactsFront,
      {
        new_insert: true,
        front_id: randon_id,
        tipo: btn.target.id,
        contato:
          btn.target.id === "telefone"
            ? formRef.current.getFieldValue("telefone")
            : formRef.current.getFieldValue("email"),
      },
    ]);

    setContactsBack(
      [
        ...contactsBack,
        {
          "new_insert": true,
          "front_id": randon_id,
          "tipo": btn.target.id,
          "contato": btn.target.id === "telefone" ? formRef.current.getFieldValue('telefone') : formRef.current.getFieldValue('email')
        }
      ]
    );

    formRef.current?.setFieldValue('telefone', "");
    formRef.current?.setFieldValue('email', "");
  };

  const optionsUF = [
    { value: "AC", label: "AC - Acre" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "BA", label: "BA - Bahia" },
    { value: "CE", label: "CE - Ceará" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "GO", label: "GO - Goiás" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "PA", label: "PA - Pará" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PI", label: "PI - Piauí" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RR", label: "RR - Roraima" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "EX", label: "EX - Estrangeiro" },
  ];

  const optionsEstadoCivil = [
    { value: "solteiro", label: "Solteiro" },
    { value: "casado", label: "Casado" },
    { value: "separado", label: "Separado" },
    { value: "divorciado", label: "Divorciado" },
    { value: "viuvo", label: "Viúvo" },
  ];

  const optionsGenero = [
    { value: "masculino", label: "Masculino" },
    { value: "feminino", label: "Feminino" },
  ];

  const optionsGrau = [
    { value: "Ensino Médio", label: "Ensino Médio" },
    { value: "Técnico", label: "Técnico" },
    { value: "Superior (Graduação)", label: "Superior (Graduação)" },
    { value: "Pós-graduação", label: "Pós-graduação" },
    { value: "Mestrado", label: "Mestrado" },
    { value: "Doutorado", label: "Doutorado" }
  ];

  const optionsBoolean = [
    { value: "true", label: "Sim" },
    { value: "false", label: "Não" },
  ];

  const dataAddress = () => {
    getAddress(formRef.current.getFieldValue("cep"));
  };

  const handleChangeSelect = (selectedOption) => {
    // necessario para selecionar programaticamente uma opção do select
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    // necessario para selecionar programaticamente uma opção do select
    const selected = optionsUF.find((o) => o.value === endereco.uf);
    setSelectedOption(selected);
  }, [endereco]);

  const dataTitleContacts: GridColDef[] = [
    { field: "tipo", headerName: "Tipo", flex: 1 },
    { field: "contato", headerName: "Contato", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteContactClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  function onDeleteContactClick(e: any, row: any): void {
    if (row.new_insert) {
      setContactsFront(contactsFront.filter((el) => el.front_id !== row.front_id));
      setContactsBack(contactsBack.filter((el) => el.front_id !== row.front_id));
    } else {
      console.log(row);
      setContactsFront(contactsFront.filter((el) => el.front_id !== row.front_id));
      setContactsBack([
        ...contactsBack,
        {
          id: row.front_id,
          _destroy: row.front_id,
        },
      ]);
    }
  }

  const handleChangeGenero = (selectedGenero: object) => {
    setValueGenero(selectedGenero);
  };

  const handleChangeGrauInstrucao = (selectedGrauInstrucao: object) => {
    setValueGrauInstrucao(selectedGrauInstrucao);
  };

  const handleChangeEstadoCivil = (selectedEstadoCivil: object) => {
    setValueEstadoCivil(selectedEstadoCivil);
  };

  const handleChangeCargoAtual = (cargoSelected: object) => {
    setValueCargoAtual(cargoSelected);

  };

  const handleChangeOrganizacaoTrabalho = (selectedOrganizaoTrabalho: object) => {
    setValueOrganizacaoTrabalho(selectedOrganizaoTrabalho);
  };

  const handleChangeLocalTrabalho = (selectedLocalTrabalho: object) => {
    setValueLocalTrabalho(selectedLocalTrabalho);
  };

  const handleChangeGerencia = (selectedGerencia: object) => {
    setValueGerencia(selectedGerencia);
  };

  const handleChangeGestor = (selectedGestor: object) => {
    setValueGestor(selectedGestor);
  };

  const handleChangeProjeto = (selectedProjeto: object) => {
    setValueProjeto(selectedProjeto);
  };

  const handleChangeEmpresa = (selectedEmpresa: object) => {
    setValueEmpresa(selectedEmpresa);
  };

  return (
    <Container>
      <Title color="#333">
        <span>Edição de Cadastro do Terceirizado</span>
      </Title>

      <Form id="formEmpregados" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12} lg={3}>
              <InputImage
                id="avatar"
                name="avatar"
                image={usuario.avatar}
              />
            </Grid>
            <Grid container xs={12} lg={9}>
              <Grid container>
                <Grid xs={6} lg={6}>
                  <TextTitle>Gênero: </TextTitle>
                  <SelectInput
                    name="genero"
                    options={optionsGenero}
                    value={valueGenero}
                    onChange={handleChangeGenero}
                    placeholder="Selecionar"
                  />
                </Grid>
                <Grid xs={6} lg={6}>
                  <TextTitle>Grau de Instrução: </TextTitle>
                  <SelectInput
                    name='grau_instrucao'
                    options={optionsGrau}
                    value={valueGrauInstrucao}
                    onChange={handleChangeGrauInstrucao}
                    placeholder="Selecionar"
                  />
                </Grid>
                <Grid xs={12} lg={12}>
                  <TextTitle>Nome: </TextTitle>
                  <Input type="text" name="nome" defaultValue={usuario.nome} required />
                </Grid>
                <Grid xs={6} lg={6}>
                  <TextTitle>
                    Data de nascimento: <span>*</span>
                  </TextTitle>
                  <Input type="date" name="data_nascimento" defaultValue={usuario.data_nascimento} />
                </Grid>
                <Grid xs={6} lg={6}>
                  <TextTitle>Estado civil: </TextTitle>
                  <SelectInput
                    name="estado_civil"
                    options={optionsEstadoCivil}
                    value={valueEstadoCivil}
                    onChange={handleChangeEstadoCivil}
                    placeholder="Selecionar"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} lg={6}>
              <TextTitle>CPF: </TextTitle>
              <Input type="text" name='cpf' defaultValue={usuario.cpf ? pontuarCpf(usuario.cpf) : ''} mask="cpf" required />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Formação: </TextTitle>
              <Input type="text" name="formacao" defaultValue={usuario.formacao} />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>
                RG: <span>*</span>
              </TextTitle>
              <Input type="text" name="rg" defaultValue={usuario.rg} mask="rg" />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>
                Órgão Expedidor: <span>*</span>
              </TextTitle>
              <Input type="text" name="orgao_expedidor" defaultValue={usuario.orgao_expedidor} />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Contato">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>Email:</TextTitle>
              <div style={{ position: 'relative' }}>
                <Input style={{ paddingRight: '115px' }} type="text" name="email" />
                <InputAddBtn id='email' name='email_contato' type="button" onClick={handlerContact}>
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Telefone:</TextTitle>
              <div style={{ position: 'relative' }}>
                <Input style={{ paddingRight: '115px' }} type="text" name="telefone" mask="phone" />
                <InputAddBtn id='telefone' name='telefone_contato' type="button" onClick={handlerContact}>
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            {contactsFront.length > 0 ?
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleContacts}
                  rowsData={contactsFront}
                  getRowId={(row) => row.front_id}
                />
              </Grid>
              : null}
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: 'relative' }}>
                <Input type="text" name="cep" defaultValue={usuario.cep} mask="cep" />
                <InputAddBtn type="button" onClick={dataAddress}><FontAwesomeIcon icon={faPlus} /> Pesquisar CEP </InputAddBtn>
              </div>
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6} >
              <TextTitle>Logradouro: </TextTitle>
              <Input
                type="text"
                name="logradouro"
                value={endereco.logradouro}
                onChange={(e) =>
                  setEndereco({ ...endereco, logradouro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={12} lg={6} >
              <TextTitle>Bairro: </TextTitle>
              <Input
                type="text"
                name="bairro"
                value={endereco.bairro}
                onChange={(e) =>
                  setEndereco({ ...endereco, bairro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input type="text" name="numero" defaultValue={usuario.numero} />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input type="text" name="complemento" defaultValue={usuario.complemento} />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={endereco.localidade}
                onChange={(e) =>
                  setEndereco({ ...endereco, localidade: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF: </TextTitle>
              <SelectInput
                name="uf"
                options={optionsUF}
                value={selectedOption}
                onChange={handleChangeSelect}
                placeholder="Selecionar"
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados institucionais">
          <Grid container spacing={6}>
            <Grid xs={6} lg={6}>
              <TextTitle>Cargo: </TextTitle>
              <SelectInput
                name="cargo_atual_id"
                options={cargos.data ? organizeDataSelect(cargos.data.filter(cargo => cargo.employee_type.key == 'terceirizado'), "id", "nome") : []}
                value={valueCargoAtual}
                placeholder="Selecionar"
                onChange={handleChangeCargoAtual}
                onInputChange={e => { getCargos([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Empresa: </TextTitle>
              <SelectInput
                name="empresa_id"
                options={organizeDataEmpresas(fornecedores.data)}
                value={valueEmpresa}
                onChange={handleChangeEmpresa}
                placeholder="Selecionar"
                onInputChange={e => { getFornecedores([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Data de admissão: </TextTitle>
              <Input type="date" name="data_admissao" defaultValue={usuario.data_admissao} />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Data de desligamento: </TextTitle>
              <Input type="date" name="data_desligamento" defaultValue={usuario.data_desligamento} />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Projeto: </TextTitle>
              <SelectInput
                name="project_id"
                options={organizeDataSelect(projetos.data, "id", "nome")}
                value={valueProjeto}
                onChange={handleChangeProjeto}
                placeholder="Selecionar"
                onInputChange={e => { getProjetos([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Email: </TextTitle>
              <Input type="email" name="email" defaultValue={usuario.email} required />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Gerencia: </TextTitle>
              <SelectInput
                name="gerencia_id"
                options={organizeDataSelect(gerencias.data, "id", "nome")}
                value={valueGerencia}
                onChange={handleChangeGerencia}
                placeholder="Selecionar"
                onInputChange={e => { getGerencias([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Gestor do contrato: </TextTitle>
              <SelectInput
                name="gestor_contrato_id"
                options={usuarios.data ? organizeDataSelect(usuarios.data.filter(usuario => usuario.employee_type && usuario.employee_type.key == 'empregado'), "id", "nome") : []}
                value={valueGestor}
                onChange={handleChangeGestor}
                placeholder="Selecionar"
                onInputChange={e => { getUsers([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Dados do local de trabalho">
          <Grid container spacing={6}>
            <Grid xs={6} lg={6}>
              <TextTitle>Organização: </TextTitle>
              <SelectInput
                name="organization_id"
                options={organizeDataSelect(orgaos.data, "id", "nome")}
                value={valueOrganizacaoTrabalho}
                onChange={handleChangeOrganizacaoTrabalho}
                placeholder="Selecionar"
                onInputChange={e => { getOrganizations([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
            <Grid xs={6} lg={6}>
              <TextTitle>Local de trabalho: </TextTitle>
              <SelectInput
                name="local_trabalho_id"
                options={organizeDataSelect(unidades.data, "id", "nome")}
                value={valueLocalTrabalho}
                onChange={handleChangeLocalTrabalho}
                placeholder="Selecionar"
                onInputChange={e => { getUnidades([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
              />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton>
        <Link to="/rh/empregados">
          <button><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
        </Link>
        <button type="submit" form="formEmpregados">
          <FontAwesomeIcon icon={faSave} /> Salvar
        </button>
      </ContainerButton>
    </Container>
  );
}
