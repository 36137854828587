import { Container } from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useEndereco from '../../../../../actions/cruds/useEndereco';
import useOrgao from '../../../../../actions/cruds/useOrgao';
import Panel from '../../../../../components/Panel';
import { ContainerButton, Input, InputAddBtn, SelectInput, TextTitle, Title } from '../../../../../styles/global';
import optionsUfs from '../../../../../utils/UFs';
import Grid from '@mui/material/Unstable_Grid2';
import Radio from '../../../../../components/InputRadio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faX } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
// import { Container } from './styles';


const OrganizacaoRhEdit: React.FC = () => {

    const { organizacaoId } = useParams();

    const { getAddress, endereco, setEndereco } = useEndereco();
    const { orgao, getOrganization, updateOrganizarion } = useOrgao();

    const [selectedOption, setSelectedOption] = useState({});

    const handleChangeSelect = selectedOption => { // necessario para selecionar programaticamente uma opção do select
        setSelectedOption(selectedOption);
    };

    useEffect(() => {
        getOrganization(organizacaoId);
    }, []);

    useEffect(() => {
        // necessario para selecionar programaticamente uma opção do select
        const selected = optionsUfs.find(o => o.value === orgao.uf);
        setSelectedOption(selected);

        const tipoOrgRadioField = formRef.current?.getFieldRef("tipo");

        if (orgao.tipo) {
            switch (orgao.tipo) {
                case "matriz":
                    tipoOrgRadioField[0].checked = true;
                    break;

                case "filial":
                    tipoOrgRadioField[1].checked = true;
                    break;

                default:
                    break;
            }
        }

        formRef.current?.setFieldValue("codigo", orgao.codigo);
        formRef.current?.setFieldValue("cnpj", orgao.cnpj);
    }, [orgao]);

    useEffect(() => {
        // necessario para selecionar programaticamente uma opção do select
        const selected = optionsUfs.find(o => o.value === endereco.uf);
        setSelectedOption(selected);
    }, [endereco]);

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => {
            try {
                console.log(data);

                updateOrganizarion(organizacaoId, data);

                // addToast({
                //   type: 'success',
                //   title: 'Login efetuado',
                //   description: 'Seu login foi feito com sucesso!',
                // });

                // history.push('/dashboard');
            } catch (err) {
                // addToast({
                //   type: 'error',
                //   title: 'Erro na autenticação',
                //   description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
                // });
            }
        },
        [],
    );

    const radioOptions = [
        { id: "01", value: "matriz", label: " Matriz" },
        { id: "02", value: "filial", label: " Filial" }
    ];

    const dataAddress = () => {

        const element = document.getElementById('cep');
        getAddress(element.value);

    }


    return (
        <Container>
            <Title>
                <span>Editar Organização</span>
            </Title>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Identificação'>
                    <Grid container spacing={6}>
                        <Grid xs={12}>
                            <TextTitle>Selecione o tipo da organização:</TextTitle>
                            <Radio name="tipo" options={radioOptions} required />
                        </Grid>
                        <Grid xs={12} lg={2}>
                            <TextTitle>Código:</TextTitle>
                            <Input 
                                mask="__"
                                replacement="allNumber"
                                id="codigo" 
                                name='codigo' 
                                required
                                // defaultValue={orgao.codigo}
                            />
                        </Grid>
                        <Grid xs={12} lg={4}>
                            <TextTitle>CNPJ:</TextTitle>
                            <Input type="text" name="cnpj" mask="cnpj" required />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Nome:</TextTitle>
                            <Input type="text" name='nome' defaultValue={orgao.nome} required />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>E-mail:</TextTitle>
                            <Input type="email" name='email' defaultValue={orgao.email} required />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Telefone:</TextTitle>
                            <Input type="text" name='telefone' mask='phone' defaultValue={orgao.telefone} />
                        </Grid>
                    </Grid>
                </Panel>

                <Panel titulo='Endereço'>
                    <Grid container spacing={6}>
                        <Grid xs={12} lg={6}>
                            <TextTitle>CEP: </TextTitle>

                            <div style={{ position: "relative" }}>
                                <Input
                                    style={{ paddingRight: "150px" }}
                                    type="text"
                                    id='cep'
                                    name='cep'
                                    defaultValue={orgao.cep}
                                    mask='cep'
                                />
                                <InputAddBtn type="button" onClick={dataAddress}>
                                    <FontAwesomeIcon icon={faPlus} /> Pesquisar CEP
                                </InputAddBtn>
                            </div>

                        </Grid>
                        <Grid xs="auto">
                        </Grid>

                        <Grid xs={12} lg={6} >
                            <TextTitle>Logradouro:</TextTitle>
                            <Input
                                type="text"
                                id='logradouro'
                                name='logradouro'
                                value={endereco.logradouro ? endereco.logradouro : orgao.logradouro}
                                onChange={e => setEndereco({ ...endereco, logradouro: e.target.value })}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <TextTitle>Bairro:</TextTitle>
                            <Input
                                type="text"
                                id='bairro'
                                name='bairro'
                                value={endereco.bairro ? endereco.bairro : orgao.bairro}
                                onChange={e => setEndereco({ ...endereco, bairro: e.target.value })}
                            />
                        </Grid>
                        <Grid xs={6} lg={3}>
                            <TextTitle>Número:  </TextTitle>
                            <Input
                                type="text"
                                id='numero'
                                name='numero'
                                defaultValue={orgao.numero}
                            />
                        </Grid>
                        <Grid xs={6} lg={3}>
                            <TextTitle>Complemento: </TextTitle>
                            <Input
                                type="text"
                                id='complemento'
                                name='complemento'
                                defaultValue={orgao.complemento}
                            />
                        </Grid>
                        <Grid xs={6} lg={3}>
                            <TextTitle>Município: </TextTitle>
                            <Input type="text" name='municipio' value={endereco.localidade ? endereco.localidade : orgao.municipio}
                                onChange={e => setEndereco({ ...endereco, localidade: e.target.value })} />
                        </Grid>
                        <Grid xs={6} lg={3}>
                            <TextTitle>UF:</TextTitle>
                            <SelectInput
                                id="idEstado"
                                name="uf"
                                options={optionsUfs}

                                value={selectedOption}
                                onChange={handleChangeSelect}

                                placeholder="Selecionar"
                            />
                        </Grid>
                    </Grid>
                </Panel>
                <ContainerButton>
                    <Link to="/rh/cadastros/organizacao">
                        <button><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
                    </Link>
                    <button type="submit" form="form1"><FontAwesomeIcon icon={faSave} /> Salvar</button>
                </ContainerButton>
            </Form>
        </Container>
    );
};

export default OrganizacaoRhEdit;