import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Textarea,
  Title,
} from "../../../styles/global";
import Panel from "../../../components/Panel";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDownload, faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import useJuridico from "../../../actions/cruds/Juridico/useJuridico";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { formatarData, formatarReais } from "../../../utils/formatDados";
import optionsUfs from "../../../utils/UFs";

const JuridicoView: React.FC = () => {
  const { processoJuridicoId } = useParams();
  const { processoJuridico, getProcessoJuridico } = useJuridico();

  useEffect(() => {
    getProcessoJuridico(processoJuridicoId);
  }, []);

  const organizeDataHistorico = (data: any) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        data: formatarData(d.data, false),
        judicial_process_id: d.judicial_process_id,
        observacao: d.observacao,
        new: false,
      }));
    };
    return data;
  };

  const prognosticoOptions = [
    { value: "Provável", label: "Provável" },
    { value: "Possível", label: "Possível" },
    { value: "Remoto", label: "Remoto" },
  ];

  const formRef = useRef<FormHandles>(null);

  const dataTitleHistorico: GridColDef[] = [
    { headerName: "Data", field: "data", flex: 1 },
    { headerName: "Descrição", field: "observacao", flex: 8 },
  ];

  return (
    <Container>
      <Form ref={formRef}>
        <Title color="#333">
          <span>Visualizar Processo jurídico</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Organização:</TextTitle>
              <Input
                type="text"
                name="organization_id"
                value={processoJuridico?.organization?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data do inicio do processo:</TextTitle>
              <Input
                type="text"
                name="data_inicio_processo"
                value={formatarData(processoJuridico?.data_inicio_processo, false)}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Número do processo:</TextTitle>
              <Input
                type="text"
                name="law_process_id"
                value={`${processoJuridico?.law_process?.numero} - ( SIGAD - ${processoJuridico?.law_process?.codigo})`}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Tipo de processo:</TextTitle>
              <Input
                type="text"
                name="tipo_processo"
                value={processoJuridico?.tipo_processo}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Local do processo:</TextTitle>
              <Input
                type="text"
                name="local_processo"
                value={processoJuridico?.local_processo}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Estado:</TextTitle>
              <Input
                type="text"
                name="uf"
                value={optionsUfs.find((uf) => uf.value == processoJuridico?.estado)?.label}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Prognóstico:</TextTitle>
              <Input
                type="text"
                name="prognostico"
                value={processoJuridico?.prognostico}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Advogado:</TextTitle>
              <Input
                type="text"
                name="lawyerd_id"
                value={processoJuridico?.user?.nome}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor estimado:</TextTitle>
              <Input
                type="text"
                format="currency"
                currency="BRL"
                name="estimativa_valor"
                value={formatarReais(processoJuridico?.valor_estimado)}
                disabled
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Partes do processo:</TextTitle>
              <Input
                type="text"
                name="partes_processo"
                value={processoJuridico?.partes_processo}
                disabled
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Assunto do processo:</TextTitle>
              <Textarea
                name="assunto_processo"
                value={processoJuridico?.assunto}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Histórico do processo judicial">
          <Grid container spacing={6}>
            <Grid xs={12}>
              {processoJuridico?.judicial_process_histories?.length > 0 && (
                <SecondaryTable
                  columsData={dataTitleHistorico}
                  rowsData={organizeDataHistorico(processoJuridico?.judicial_process_histories)}
                />
              )}
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Finalização do processo">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data:</TextTitle>
              <Input
                type="text"
                name="data_finalizacao"
                value={formatarData(processoJuridico?.data_termino, false)}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Valor do processo:</TextTitle>
              <Input
                type="text"
                name="valor_processo"
                value={formatarReais(processoJuridico?.valor_final)}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/juridico">
          <button className="button_return">
            <FontAwesomeIcon icon={faChevronLeft} size="1x" /> Voltar
          </button>
        </Link>
        <Link to={`/juridico/fichaPDF/${processoJuridicoId}`} target="_blank">
          <button className="button_save">
            <FontAwesomeIcon icon={faDownload} size="1x" /> Download PDF
          </button>
        </Link>
      </ContainerButton2>
    </Container >
  );
};

export default JuridicoView;
