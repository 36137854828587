import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const usePoaOrcamento = () => {
  const history = useHistory();
  const [poaOrcamento, setPoaOrcamento] = useState({});
  const {addToast} = useToast();

  async function getPoaOrcamento(id: number) {
    try {
      const response = await api.get(`poa_budgets/${id}`);
      setPoaOrcamento(response.data);
    } catch (e) {}
  }

  async function createPoaOrcamento(data: object) {
    try {
        const response = await api.post('poa_budgets', data);
        // addToast({type: 'success', title: 'Valor adicionado!', description: 'Orçamento adicionado com sucesso.'});
    } catch (e) {}
  }

  async function updatePoaOrcamento(id: number, data: object) {
    try {
        const response = await api.put(`poa_budgets/${id}`, data);

        // addToast({type: 'success', title: 'Valor alterado!', description: 'Orçamento alterado com sucesso.'});
    } catch (e) {}
  }

  return {
    poaOrcamento,
    getPoaOrcamento,
    createPoaOrcamento,
    updatePoaOrcamento
  };
};

export default usePoaOrcamento;
