import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import stringfyParams from "../../../utils/stringfyParams";

const useSolicitanteExterno = () => {
  const history = useHistory();
  const [solicitantesExternos, setSolicitantesExternos] = useState([]);
  const [solicitanteExterno, setSolicitanteExterno] = useState([]);
  const [error, setError] = useState(null);

  async function getSolicitantesExternos(params?: any) {
    try {
      const response = await api.get(`external_requesters${stringfyParams(params)}`);
      setSolicitantesExternos(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function getSolicitanteExterno(id:string) {
    try {
      const response = await api.get(`external_requesters/${(id)}`);
      setSolicitanteExterno(response.data);
      // return response;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function createSolicitanteExterno(data: any) {
    try {
      const response = await api.post("external_requesters", data);
      alert("Solicitante Externo cadastrado com sucesso!");
      history.push("/protocolo/cadastros/solicitante_externo");
      return response.data;
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  async function updateSolicitanteExterno(id: number, data: any) {
    try {
      const response = await api.put(`external_requesters/${(id)}`, data);
      alert("Solicitante Externo atualizado com sucesso!");
      history.push("/protocolo/cadastros/solicitante_externo");
    } catch (e) {
      setError(e);
      // console.log(error);
    }
  }

  return { solicitantesExternos, getSolicitantesExternos, solicitanteExterno, getSolicitanteExterno, createSolicitanteExterno, updateSolicitanteExterno }

};

export default useSolicitanteExterno;