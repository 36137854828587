import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useInstituicao = () => {

    const history = useHistory();
    const [instituicoes, setInstituicoes] = useState([]);
    const [instituicao, setInstituicao] = useState({
        nome: ''
    });
    const [instituicoesXls, setInstituicoesXls] = useState("#");
    const [error, setError] = useState(null);

    async function getInstituicoes(params) {
        try {
            const response = await api.get(`institutions${stringfyParams(params)}`);
            setInstituicoes(response.data);
            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function getInstituicao(id) {
        try {
            const response = await api.get(`institutions/${id}`);
            // console.log(response);
            setInstituicao(response.data);
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function createInstituicao(data) {
        try {
            const response = await api.post('institutions', data,{headers: {
                "Content-Type": "multipart/form-data",
              },});
            // console.log('response: ', data);
            alert("Instituição cadastrada com sucesso!")
            history.goBack();

            // return response;
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }

    async function updateInstituicao(id: string, data: any) {
        try {
            const response = await api.put(`institutions/${id}`, data, {headers: {
                "Content-Type": "multipart/form-data",
              },});
            alert("Instituição editada com sucesso!")
            history.goBack();
        } catch (e) {
            setError(e);
            alert('Erro: Tente novamente.');
            // console.log(error);
        }        
    }

    async function DesativarInstituicao(id: any, data: any) {
               
        const formData = new FormData();

        formData.append('id', id);
        formData.append('ativo', data);

        try {
            
            // console.log('Data instituicao: ', data);
            
            const response = await api.put(`institutions/${id}`, formData);
            
            
            window.location.reload();
            
            return response.data;
        } catch (e) {
            setError(e);
            // console.log(error);
        }        
    }

    async function getInstituicoesXls(data?: any) {
        try {
            const response = await api.get(`institutions/xls`, {
                responseType: "blob", // importante!
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'instituicoes.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            setError(e);
            // console.log(error);
        }
    }


    return {instituicoes, instituicao, DesativarInstituicao, getInstituicoes, getInstituicao, createInstituicao, updateInstituicao, setInstituicao, getInstituicoesXls, error};
}

export default useInstituicao;