import styled from 'styled-components';
import { FormContainer, Input } from '../../../../styles/global';
import { Tabs, Container } from '@mui/material';
// import {Container} from './../../../../components/Panel/styles';

export const ContainerView = styled(Container)`

  margin: 0 0 50px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;

  background-color: #758BFD;
    > div {
    color: #FFFFFE;
  }
`;

export const InputView = styled(Input)`
  &:disabled {
    color: #FFFFFE;
    background-color: #758BFD;
    border-color: #758BFD;
    padding: 0px;
  }

  &::placeholder {
    color: #FFFFFE;
  }
`;


export const ImageView = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
`;


export const TabsView = styled(Tabs)`
  && {
    padding: 17px 0px 19px 12px;
    button {
      color: #FFFFFF;
      border: solid 1px #D6D6D6;
      border-radius: 7px;
      opacity: 1;
      width: 200px;
    }

    button.Mui-selected {
        background-color: #378EF0;
      }

    span{
      display: none;
    }
  }
`;

export const ContainerTab = styled(FormContainer)`
  background-color: #1473E6;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  margin: 1rem 0rem 1rem 0rem;
`;