import { PDFViewer } from '@react-pdf/renderer'
import DocumentPage from '../../../../components/PDFComponents/DocumentPage';
import { useEffect, useState } from 'react';
import { formatarData, formatarReais } from '../../../../utils/formatDados';
import { useParams } from 'react-router-dom';
import useAutorizacaoDespesa from '../../../../actions/cruds/Financeiro/useAutorizacaoDespesa';
import useAteste from '../../../../actions/cruds/Financeiro/useAteste';
import useInstrumento from '../../../../actions/cruds/InstrumentoContratual/useInstrumento';
import useProcesso from '../../../../actions/cruds/Processos/useProcesso';

const FinanceiroAutorizacaoDespesaFichaPDF: React.FC = () => {
  const { autorizacaoDespesaId } = useParams();
  const { autorizacaoDespesa, getAutorizacaoDespesa } = useAutorizacaoDespesa();
  const { ateste, getAteste } = useAteste();
  const { instrumento, getInstrumento } = useInstrumento();
  const { processo, getProcesso } = useProcesso();

  const [linhas, setLinhas] = useState<Array<Object>>([]);

  useEffect(() => {
    getAutorizacaoDespesa(autorizacaoDespesaId);
  }, []);

  useEffect(() => {
    if (autorizacaoDespesa?.id) {
      getAteste(autorizacaoDespesa?.attest?.id);
    };
  }, [autorizacaoDespesa]);

  useEffect(() => {
    if (ateste?.id) {
      getInstrumento(ateste?.contractual_instrument?.id);
    };
  }, [ateste]);

  useEffect(() => {
    if (instrumento?.law_process?.id) {
      getProcesso(instrumento?.law_process?.id);
    };
  }, [instrumento]);

  useEffect(() => {
    if (processo?.id) {
      if (autorizacaoDespesa?.expense_authorization_budgets?.length > 0) {
        const updatedLinhas = []


        autorizacaoDespesa?.expense_authorization_budgets?.forEach((linha, index) => {
          // console.log(ateste?.attest_budgets)
          // console.log(linha)
          let budget = ateste?.attest_budgets?.find((budget) => budget.id == linha.attest_budget.id);
          console.log(budget)

          if (index == 0) {
            updatedLinhas.push(
              {
                id: "linhas",
                row: "",
                value: "Linhas",
              },
            )
          }
          if (budget) {
            updatedLinhas.push(
              {
                id: "linha",
                row: `Linha ${index + 1}:`,
                value: `Contrato de gestão: ${budget?.contractual_instrument_budget?.project_line?.management_contract?.nome_completo}\nComitê: ${budget?.contractual_instrument_budget?.project_line?.comittee?.nome}\nCódigo do Projeto: ${budget?.contractual_instrument_budget?.project_line?.expense_type?.codigo}.${budget?.contractual_instrument_budget?.project_line?.organization?.codigo.padStart(2, '0')}.${budget?.contractual_instrument_budget?.project_line?.management_contract?.codigo.padStart(2, '0')}.${budget?.contractual_instrument_budget?.project_line?.comittee?.codigo.padStart(2, '0')}.${budget?.contractual_instrument_budget?.project_line?.unit?.codigo.padStart(2, '0')}.${budget?.contractual_instrument_budget?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${budget?.contractual_instrument_budget?.project_line?.pap_program?.codigo.padStart(2, '0')}.${budget?.contractual_instrument_budget?.project_line?.pap_action?.codigo.padStart(2, '0')}.${budget?.contractual_instrument_budget?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${processo?.project?.id.toString().padStart(2, '0')}.${budget?.contractual_instrument_budget?.project_line?.description?.codigo.padStart(3, '0')}\nValor: ${formatarReais(linha?.valor)}\nConta: ${linha?.bank_account?.apelido} - Conta ${linha?.bank_account?.conta_tipo}, Ag: ${linha?.bank_account?.conta_agencia} CC: ${linha?.bank_account?.conta_numero}`
              },
            );
          }
        });

        setLinhas(updatedLinhas);
      };

    }
  }, [processo])

  if (autorizacaoDespesa?.id && processo?.id) {
    const pdfData = {
      version: "Formulário eletrônico - SIGA Águas",
      title: "FICHA DE AUTORIZAÇÃO DE DESPESA",
      items: [
        {
          id: "agevapImg",
          logoWithColumns: true,
          itemsColumn: [
            {
              id: "atesteNumero",
              row: "AUTORIZAÇÃO Nº",
              value: autorizacaoDespesa?.codigo ?? "-"
            },
            {
              id: "instrumentoNumero",
              row: "INSTRUMENTO Nº",
              value: autorizacaoDespesa?.attest?.contractual_instrument?.numero ?? "-"
            },
          ]
        },
        {
          id: "docFiscal",
          row: "Documento Fiscal:",
          value: autorizacaoDespesa?.invoice?.documento_fiscal ?? "-"
        },
        {
          id: "contrGestao",
          row: "Contrato de Gestão:",
          value: autorizacaoDespesa?.invoice?.management_contract?.nome_completo ?? "-",
        },
        {
          id: "dataInvoice",
          row: "Data:",
          value: formatarData(autorizacaoDespesa?.invoice?.data, false) ?? "-"
        },
        {
          id: "valorInvoice",
          row: "Valor da nota:",
          value: formatarReais(autorizacaoDespesa?.invoice?.valor) ?? "-",
        },
        {
          id: "impostos",
          row: "Impostos:",
          value: `PIS: ${formatarReais(autorizacaoDespesa?.pis) ?? "-"}\nCONFIS: ${formatarReais(autorizacaoDespesa?.cofins) ?? "-"}\nISS: ${formatarReais(autorizacaoDespesa?.iss) ?? "-"}\nIR: ${formatarReais(autorizacaoDespesa?.ir) ?? "-"}\nINSS: ${formatarReais(autorizacaoDespesa?.inss) ?? "-"}\nTotal: ${formatarReais(autorizacaoDespesa?.pis + autorizacaoDespesa?.cofins + autorizacaoDespesa?.iss + autorizacaoDespesa?.ir + autorizacaoDespesa?.inss)}`
        },
        {
          id: "impostos",
          row: "Valor líquido:",
          value: formatarReais(autorizacaoDespesa?.invoice?.valor - (autorizacaoDespesa?.pis + autorizacaoDespesa?.cofins + autorizacaoDespesa?.iss + autorizacaoDespesa?.ir + autorizacaoDespesa?.inss))
        },
        {
          id: "dataPagamento",
          row: "Data de pagamento:",
          value: formatarData(autorizacaoDespesa?.data_pagamento, false) ?? "-"
        },
        {
          id: "observacao",
          row: "Observação:",
          value: autorizacaoDespesa?.observacao ?? "-"
        },
      ],
    };

    if (linhas?.length > 0) {
      linhas.forEach((linha) => pdfData.items.push(linha));
    };

    return (
      <PDFViewer width="100%" height="1300" className="app" >
        <DocumentPage data={pdfData} />
      </PDFViewer>
    );
  };
};


export default FinanceiroAutorizacaoDespesaFichaPDF;