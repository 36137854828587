import { Container } from "@mui/material";
import { Form } from "@unform/web";
import React, { useEffect, useRef } from "react";
import Panel from "../../../../../components/Panel";
import {
  ContainerButton,
  Input,
  TextTitle,
  Title,
} from "../../../../../styles/global";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FormHandles } from "@unform/core";
import useEndereco from "../../../../../actions/cruds/useEndereco";
import useOrgao from "../../../../../actions/cruds/useOrgao";
import Radio from "../../../../../components/InputRadio";

const OrganizacaoRhView: React.FC = () => {
  const { endereco } = useEndereco();
  const { orgao, getOrganization } = useOrgao();
  const { organizacaoId } = useParams();

  useEffect(() => {
    getOrganization(organizacaoId);
  }, []);

  useEffect(() => {
    const tipoOrgRadioField = formRef.current?.getFieldRef("tipo");

    if (orgao.tipo) {
      switch (orgao.tipo) {
        case "matriz":
          tipoOrgRadioField[0].checked = true;
          break;

        case "filial":
          tipoOrgRadioField[1].checked = true;
          break;

        default:
          break;
      }
    }
  }, [orgao]);

  const radioOptions = [
    { id: "01", value: "matriz", label: " Matriz" },
    { id: "02", value: "filial", label: " Filial" }
  ];

  const formRef = useRef<FormHandles>(null);

  return (
    <Container>
      <Title>
        <span>Visualizar Organização</span>
      </Title>

      <Form id="form1" ref={formRef}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Selecione o tipo da organização:</TextTitle>
              <Radio
                name="tipo"
                options={radioOptions}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={2}>
              <TextTitle>Código:</TextTitle>
              <Input
                type="text"
                name="codigo"
                placeholder={orgao?.codigo}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={4}>
              <TextTitle>CNPJ:</TextTitle>
              <Input
                type="text"
                name="cnpj"
                mask="cnpj"
                placeholder={orgao?.cnpj}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Nome:</TextTitle>
              <Input
                type="text"
                name="nome"
                placeholder={orgao?.nome}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>E-mail:</TextTitle>
              <Input
                type="text"
                name="email"
                placeholder={orgao?.email}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Telefone:</TextTitle>
              <Input
                type="text"
                name="telefone"
                placeholder={orgao?.telefone}
                disabled
              />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <Input
                type="text"
                id="cep"
                name="cep"
                placeholder={orgao?.cep}
                disabled
              />
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro:</TextTitle>
              <Input
                type="text"
                id="logradouro"
                name="logradouro"
                value={endereco.logradouro}
                placeholder={orgao?.logradouro}
                disabled
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro:</TextTitle>
              <Input
                type="text"
                id="bairro"
                name="bairro"
                value={endereco.bairro}
                placeholder={orgao?.bairro}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input
                type="text"
                id="numero"
                name="numero"
                placeholder={orgao?.numero}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input
                type="text"
                id="complemento"
                name="complemento"
                placeholder={orgao?.complemento}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={endereco.localidade}
                placeholder={orgao?.municipio}
                disabled
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF:</TextTitle>
              <Input id="idEstado" name="uf" placeholder={orgao?.uf} disabled />
            </Grid>
          </Grid>
        </Panel>
        <ContainerButton>
          <Link to="/rh/cadastros/organizacao">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Voltar
            </button>
          </Link>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default OrganizacaoRhView;
