import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

const useLogin = () => {
  const history = useHistory();
  const [loginById, setLoginById] = useState('');
  const [error, setError] = useState(null);

  async function getLoginById(id: string) {
    try {
      const response = await api.get(`logins/${id}`);

      setLoginById(response.data);

      return response.data;

    } catch (e) {
      setError(e);
    }
  }

  async function createLogin(data: any) {
    try {
      const response = await api.post("logins", data);
      alert("Usuário cadastrado com sucesso");
      history.push("/usuarios");

      // setUsuarios(response.data);
      // return response;
    } catch (e) {
      alert("Erro: Não foi possível cadastrar, verifique os dados.");
      setError(e);
      // console.log(error);
    }
  }

  async function updateLogin(id: number, data: any) {
    try {
      const response = await api.put(`logins/${id}`, data);

      console.log("response: ", response);
      alert("Usuário atualizado com sucesso");
      history.push("/usuarios");

    } catch (e) {
      setError(e);
      console.log(error);
    }
  }

  return {
    loginById,
    getLoginById,
    createLogin,
    updateLogin,
    error,
  };
};

export default useLogin;
