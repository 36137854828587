import { useCallback, useEffect, useRef, useState } from "react";

import { faPlus, faSave, faX } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHandles } from "@unform/core";

import Panel from "../../../components/Panel";

import {
  ButtonAdd,
  ContainerButton,
  Form,
  Input,
  InputAddBtn,
  SelectInput,
  TextTitle,
  Title,
} from "../../../styles/global";

import { ButtonSearch } from "../Edit/style";
import bankApi from "../../../services/bankApi";
import useCnae from "../../../actions/cruds/useCnae";
import Radio from "../../../components/InputRadio";
import useEndereco from "../../../actions/cruds/useEndereco";
import useFornecedor from "../../../actions/cruds/useFornecedor";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Container } from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ButtonReturn } from "../../../components/ButtonReturn";
import { DialogReturn } from "../../../components/ModalReturn";
import Grid from "@mui/material/Unstable_Grid2";
import emailChecker from "../../../utils/emailChecker";

export function RegFornecedor() {
  const { cnaes, getCnaes } = useCnae();
  const { getAddress, endereco, setEndereco } = useEndereco();
  const { fornecedores, getFornecedores, createFornecedores } = useFornecedor();

  const [banks, setBanks] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [representatives, setRepresentatives] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [consorcios, setConsorcios] = useState([]);
  const [showFieldsConsorcio, setShowFieldsConsorcio] = useState(false);
  const [returnPage, setReturnPage] = useState(false);

  const [tipoFornecedor, setTipoFornecedor] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        data["supplier_contacts_attributes"] = contacts;
        data["supplier_representatives_attributes"] = representatives;
        data["consorcio_ids"] = consorcios;

        const cpfCnpj = formRef.current?.getFieldValue("cpf_cnpj");
        if (cpfCnpj && cpfCnpj.match(/\d/g).length <= 11) {
          data["tipo_pessoa"] = "pessoa_fisica";
        } else if (cpfCnpj && cpfCnpj.match(/\d/g).length > 11) {
          data["tipo_pessoa"] = "pessoa_juridica";
        }

        // if(contacts.length === 0){
        //   alert("Adicione contatos ao Fornecedor")
        //   return;
        // }

        console.log(data);
        createFornecedores(data);
      } catch (err) { }
    },
    [contacts, consorcios, representatives]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await bankApi.get("banks/v1");
        setBanks(
          data.map((d) => ({
            value: d.fullName,
            label: `${d.code ? d.code + " - " : ""} ${d.fullName}`,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    getCnaes();
    getFornecedores();
  }, []);

  const organizeDataCnaes = (data) => {
    if (typeof data === "object") {
      data = data.map((d) => ({
        value: d.id,
        label: `${d.codigo} - ${d.nome}`,
      }));
    }

    return data;
  };

  const organizeDataFornecedores = (data) => {
    if (typeof data === "object") {
      data = data.map((d) => ({
        value: d.id,
        label: `${d.cpf_cnpj} - ${d.nome_razao_social}`,
      }));
    }

    return data;
  };

  const handlerContact = (btn) => {
    const randon_id = Date.now();
    const tipoContato = btn.target.id;

    if(tipoContato === 'email'){
      const emailValue = formRef.current?.getFieldValue("email");
      const emailValido = emailChecker(emailValue);

      if(!emailValido){
        alert("Email inválido! Insira um email valido. Ex: 'email@email.com'");
        return;
      }
    };

    if (tipoContato != "telefone" && tipoContato != "email") {
      return;
    };

    setContacts([
      ...contacts,
      {
        front_id: randon_id,
        tipo: btn.target.id,
        contato:
          btn.target.id === "telefone"
            ? formRef.current.getFieldValue("telefone")
            : formRef.current.getFieldValue("email"),
      },
    ]);
    
    formRef.current?.setFieldValue("telefone", "");
    formRef.current?.setFieldValue("email", "");
  };

  const handlerRepresentatives = () => {
    const randon_id = Date.now();

    setRepresentatives([
      ...representatives,
      {
        front_id: randon_id,
        cpf: formRef.current.getFieldValue("representative_cpf"),
        nome: formRef.current.getFieldValue("representative_nome"),
      },
    ]);
  };

  const optionsUF = [
    { value: "AC", label: "AC - Acre" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "BA", label: "BA - Bahia" },
    { value: "CE", label: "CE - Ceará" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "GO", label: "GO - Goiás" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "PA", label: "PA - Pará" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PI", label: "PI - Piauí" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RR", label: "RR - Roraima" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "EX", label: "EX - Estrangeiro" },
  ];

  const dataAddress = () => {
    getAddress(formRef.current.getFieldValue("cep"));
  };

  const handleChangeSelect = (selectedOption) => {
    // necessario para selecionar programaticamente uma opção do select
    setSelectedOption(selectedOption);
  };

  const handleConsorcio = (options) => {
    let consorciosHelper = [];
    options.map((o) => consorciosHelper.push(o.value));
    setConsorcios(consorciosHelper);
  };

  useEffect(() => {
    // necessario para selecionar programaticamente uma opção do select
    const selected = optionsUF.find((o) => o.value === endereco.uf);
    setSelectedOption(selected);
  }, [endereco]);

  const handleChangeRadioConsorcio = () => {
    const consorcioRadioField = formRef.current?.getFieldRef("consorcio");
    setShowFieldsConsorcio(consorcioRadioField[0].checked);
  };

  const handleChangeCPF = (valueCPF) => {
    console.log(valueCPF.target.value);
    if (valueCPF.target.value.length <= 14) {
      setShowFieldsConsorcio(false);
      setTipoFornecedor(false);
    } else {
      setTipoFornecedor(true);
    }
  }

  const dataTitleContacts: GridColDef[] = [
    { field: "tipo", headerName: "Tipo", flex: 1 },
    { field: "contato", headerName: "Contato", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteContactClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  function onDeleteContactClick(
    e: MouseEvent<HTMLButtonElement, MouseEvent>,
    row: any
  ): void {
    setContacts(contacts.filter((el) => el.front_id !== row.front_id));
  }

  const dataTitleRepresentative: GridColDef[] = [
    { field: "cpf", headerName: "CPF", flex: 1 },
    { field: "nome", headerName: "Nome", flex: 1 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => onDeleteRepresentativeClick(e, params.row)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </Button>
          </>
        );
      },
    },
  ];

  function onDeleteRepresentativeClick(e: any, row: any): void {
    setRepresentatives(
      representatives.filter((el) => el.front_id !== row.front_id)
    );
  }

  // useEffect(() => {
  //   const blockHistory = history.block('Você tem alterações não salvas. Tem certeza de que deseja sair?');

  //   return () => {
  //     blockHistory();
  //   };
  // }, [history]);

  return (
    <Container>
      <Title color="#333">
        <span>Cadastro de Fornecedor</span>
      </Title>
      <Form id="formFornecedores" ref={formRef} onSubmit={handleSubmit}>
        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>CPF / CNPJ: </TextTitle>
              <Input
                type="text"
                name="cpf_cnpj"
                mask="cpfCnpj"
                onChange={handleChangeCPF}
                onMask={e => console.log(e.detail.input)} // Retorno dos digitos sem mascara (onChange vai retornar os digitos + mascara completa)
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Nome / Razão Social: </TextTitle>
              <Input type="text" name="nome_razao_social" required />
            </Grid>
            {tipoFornecedor ? (
              <>
                <Grid xs={6}>
                  <TextTitle>Inscrição Estadual: </TextTitle>
                  <Input type="text" name="inscricao_estadual" />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>Nome Fantasia: </TextTitle>
                  <Input type="text" name="nome_fantasia" />
                </Grid>
                <Grid xs={6}>
                  <TextTitle>
                    Este CNPJ que está sendo cadastrado trata-se de um
                    consórcio?
                  </TextTitle>
                  <Radio
                    name="consorcio"
                    options={[
                      { id: "01", value: "true", label: "Sim" },
                      { id: "02", value: "false", label: "Não" },
                    ]}
                    checkedValue={false}
                    onClick={handleChangeRadioConsorcio}
                  />
                </Grid>
              </>
            ) : (
              <Grid xs={6}>
                <TextTitle>RG: </TextTitle>
                <Input type="text" name="rg" mask="rg" />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Contato">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>Email:</TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "115px" }}
                  type="text"
                  name="email"
                />
                <InputAddBtn
                  id="email"
                  name="email_contato"
                  type="button"
                  onClick={handlerContact}
                >
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Telefone:</TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "115px" }}
                  type="text"
                  name="telefone"
                  mask="phone"
                />
                <InputAddBtn
                  id="telefone"
                  name="telefone_contato"
                  type="button"
                  onClick={handlerContact}
                >
                  <FontAwesomeIcon icon={faPlus} size="1x" /> Adicionar
                </InputAddBtn>
              </div>
            </Grid>
            {contacts.length > 0 && (
              <Grid xs={12}>
                <SecondaryTable
                  columsData={dataTitleContacts}
                  rowsData={contacts}
                  getRowId={(row) => row.front_id}
                />
              </Grid>
            )}
          </Grid>
        </Panel>

        <Panel titulo="Endereço">
          <Grid container spacing={6}>
            <Grid xs={12} lg={6}>
              <TextTitle>CEP: </TextTitle>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ paddingRight: "150px" }}
                  type="text"
                  name="cep"
                  mask="cep"
                />
                <InputAddBtn type="button" onClick={dataAddress}>
                  <FontAwesomeIcon icon={faPlus} /> Pesquisar CEP{" "}
                </InputAddBtn>
              </div>
            </Grid>
            <Grid xs="auto"></Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Logradouro: </TextTitle>
              <Input
                type="text"
                name="logradouro"
                value={endereco.logradouro}
                onChange={(e) =>
                  setEndereco({ ...endereco, logradouro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={12} lg={6}>
              <TextTitle>Bairro: </TextTitle>
              <Input
                type="text"
                name="bairro"
                value={endereco.bairro}
                onChange={(e) =>
                  setEndereco({ ...endereco, bairro: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Número: </TextTitle>
              <Input type="text" name="numero" />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Complemento: </TextTitle>
              <Input
                type="text"
                name="complemento"
                value={endereco.complemento}
                onChange={(e) =>
                  setEndereco({ ...endereco, complemento: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>Município: </TextTitle>
              <Input
                type="text"
                name="municipio"
                value={endereco.localidade}
                onChange={(e) =>
                  setEndereco({ ...endereco, localidade: e.target.value })
                }
              />
            </Grid>
            <Grid xs={6} lg={3}>
              <TextTitle>UF: </TextTitle>
              <SelectInput
                name="uf"
                options={optionsUF}
                value={selectedOption}
                onChange={handleChangeSelect}
                placeholder="Selecionar"
              />
            </Grid>
          </Grid>
        </Panel>

        {showFieldsConsorcio ? null : (
          <Panel titulo="Dados bancários">
            <Grid container spacing={6}>
              <Grid xs={6} lg={3}>
                <TextTitle>Tipo de conta:</TextTitle>
                <SelectInput
                  id="tipoConta"
                  name="banco_tipo_conta"
                  options={[
                    { value: "corrente", label: "Conta Corrente" },
                    { value: "poupanca", label: "Conta Poupança" },
                  ]}
                  placeholder="Selecionar"
                />
              </Grid>
              <Grid xs={6} lg={3}>
                <TextTitle>Banco:</TextTitle>
                <SelectInput
                  id="tipoBanco"
                  name="banco_nome"
                  options={banks}
                  placeholder="Selecionar"
                />
              </Grid>
              <Grid xs={6} lg={3}>
                <TextTitle>Agência:</TextTitle>
                <Input type="text" name="banco_agencia" />
              </Grid>
              <Grid xs={6} lg={3}>
                <TextTitle>Nº da Conta:</TextTitle>
                <Input type="text" name="banco_conta" />
              </Grid>
            </Grid>
          </Panel>
        )}

        {showFieldsConsorcio ? null : (
          <Panel titulo="Atividade CNAE">
            <Grid container spacing={6}>
              <Grid xs={12}>
                <TextTitle>Atividade Principal:</TextTitle>
                <SelectInput
                  id="ativPrincipal"
                  name="cnae_activity_id"
                  placeholder="Selecionar"
                  options={cnaes.data ? organizeDataCnaes(cnaes.data) : []}
                  onInputChange={(e) => {
                    getCnaes([{ label: "nome", value: e }]);
                  }}
                />
              </Grid>
            </Grid>
          </Panel>
        )}

        {tipoFornecedor && (
          <Panel titulo="Representantes legais">
            <Grid container spacing={6}>
              <Grid xs={12} lg={6}>
                <TextTitle>CPF:</TextTitle>
                <Input type="text" name="representative_cpf" mask="cpf" />
              </Grid>
              <Grid xs={12} lg={6}>
                <TextTitle>Nome:</TextTitle>
                <Input type="text" name="representative_nome" />
              </Grid>
              <Grid xs={12} lg={6}>
                <ButtonAdd type="button" onClick={handlerRepresentatives}>
                  <FontAwesomeIcon icon={faPlus} /> Adicionar
                </ButtonAdd>
              </Grid>
              {representatives.length > 0 && (
                <Grid xs={12}>
                  <SecondaryTable
                    columsData={dataTitleRepresentative}
                    rowsData={representatives}
                    getRowId={(row) => row.front_id}
                  />
                </Grid>
              )}
            </Grid>
          </Panel>
        )}

        {showFieldsConsorcio ? (
          <Panel titulo="Consórcio">
            <Grid container spacing={6}>
              <Grid xs={12}>
                <TextTitle>
                  Utilize os campos abaixo para adicionar as empresas
                  participantes deste consórcio:
                </TextTitle>
              </Grid>
              <Grid xs={12}>
                <TextTitle>CPF:</TextTitle>
                <SelectInput
                  isMulti
                  id="consorcioId"
                  name="consorcio_ids_select"
                  placeholder="Busque por CNPJ ou razão social"
                  options={fornecedores.data ? organizeDataFornecedores(fornecedores.data.filter((fornecedor) => fornecedor.ativo == true)) : []}
                  onChange={handleConsorcio}
                  onInputChange={(e) => {
                    getFornecedores([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                  }}
                />
              </Grid>
            </Grid>
          </Panel>
        ) : null}
      </Form>

      <ContainerButton>
        <Link to="/fornecedores">
          <button>
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <button type="submit" form="formFornecedores">
          <FontAwesomeIcon icon={faSave} /> Salvar
        </button>
      </ContainerButton>
    </Container >
  );
}
