import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/material';

interface Props {
  name: string;
}
type InputProps = JSX.IntrinsicElements['input'] & Props;
export default function InputFile({ name, ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files?.[0].name);
    const nameFile = e.target.files?.[0].name;
    if (!nameFile) {
      setPreview(null);
    }
    setPreview(nameFile);
  }, []);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  const clearFile = () => {
    inputRef.current.value = null;
    setPreview(null);
  }

  return (
    <>
      {preview && <div><Button size="small" endIcon={<ClearIcon />} onClick={clearFile}>{preview}</Button></div>}
      <input type="file" ref={inputRef} onChange={handlePreview} {...rest} />
    </>
  );
};