import { PDFViewer } from '@react-pdf/renderer'
import DocumentPage from '../../../../components/PDFComponents/DocumentPage';
import { useEffect, useState } from 'react';
import { formatCPF, formatarData, formatarReais } from '../../../../utils/formatDados';
import { useParams } from 'react-router-dom';
import useInstrumento from '../../../../actions/cruds/InstrumentoContratual/useInstrumento';
import useAteste from '../../../../actions/cruds/Financeiro/useAteste';
import useProcesso from '../../../../actions/cruds/Processos/useProcesso';

const FinanceiroAtesteFichaPDF: React.FC = () => {
  const { atesteId } = useParams();
  const { ateste, getAteste } = useAteste();
  const { instrumento, getInstrumento } = useInstrumento();
  const { processo, getProcesso } = useProcesso();

  const [dadosEmpresa, setDadosEmpresa] = useState<Array<Object>>([]);
  const [objetoPagamento, setObjetoPagamento] = useState<Array<Object>>([]);
  const [notasFiscais, setNotasFiscais] = useState<Array<Object>>([]);
  const [linhas, setLinhas] = useState<Array<Object>>([]);

  useEffect(() => {
    getAteste(atesteId);
  }, [])

  const organizeDataLinha = (data, id) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.filter((data) => data.id == id).map(d => ({
          id: d.id,
          contrato: d.management_contract.nome_completo,
          documento_fiscal: d.documento_fiscal,
          valor: formatarReais(d.valor),
          tipo_documento: d.tipo,
          dataDocumento: formatarData(d.data, false),
          url_arquivo: `${d.url_arquivo}/${d.nome_arquivo}`,
          attest_budgets: d.attest_budgets,
        }));

        data.forEach((data) => {
          let codigo = [];
          let linhasConcatenadas;

          // console.log("dataFor", data, id)

          data.attest_budgets?.forEach((inv, index) => {
            codigo.push(organizeDataAttestBudget(inv))
            linhasConcatenadas = codigo.map(objeto => objeto.linha).join('\n');
          });

          data.linhas = linhasConcatenadas;
        });

        return data[0].linhas;
      }
    }
    return [];
  };

  const organizeDataAttestBudget = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        return {
          linha: `Linha: ${data?.contractual_instrument_budget?.project_line?.expense_type?.codigo}.${data?.contractual_instrument_budget?.project_line?.organization?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.management_contract?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.comittee?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.unit?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.pap_program?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.pap_action?.codigo.padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${processo?.project?.id?.toString().padStart(2, '0')}.${data?.contractual_instrument_budget?.project_line?.description?.codigo.padStart(3, '0')}`,
        };
      }
    }
    return [];
  };
  useEffect(() => {
    if (ateste?.id) {
      getInstrumento(ateste?.contractual_instrument?.id);
      getProcesso(ateste?.contractual_instrument?.law_process?.id);

      if (ateste?.tipo_pagamento == 'Boleto/Fatura') {
        setDadosEmpresa([
          {
            id: "empresa",
            row: "Empresa:",
            value: ateste?.supplier?.nome_razao_social ?? "-"
          },
          {
            id: "cpfCnpj",
            row: "CPF/CNPJ da empresa:",
            value: formatCPF(ateste?.supplier?.cpf_cnpj) ?? "-"
          },
          {
            id: "tipoPagamento",
            row: "Tipo de Pagamento:",
            value: `[ ${ateste?.tipo_pagamento == 'Depósito' ? "X" : "  "} ] Depósito           [ ${ateste?.tipo_pagamento == 'Boleto/Fatura' ? "X" : "  "} ] Boleto/Fatura`,
          },
          {
            id: "codigoBoleto",
            row: "Código do boleto:",
            value: ateste?.codigo_boleto ?? "-"
          },
          {
            id: "dataVencimento",
            row: "Data de vencimento:",
            value: formatarData(ateste?.data_vencimento, false) ?? "-"
          },
        ]);
      } else if (ateste?.tipo_pagamento == 'Depósito') {
        if (ateste?.contractual_instrument?.tipo_instrumento == 'Convênios') {
          setDadosEmpresa([
            {
              id: "empresa",
              row: "Empresa:",
              value: ateste?.supplier?.nome_razao_social ?? "-"
            },
            {
              id: "cpfCnpj",
              row: "CPF/CNPJ da empresa:",
              value: formatCPF(ateste?.supplier?.cpf_cnpj) ?? "-"
            },
            {
              id: "tipoPagamento",
              row: "Tipo de Pagamento:",
              value: `[ ${ateste?.tipo_pagamento == 'Depósito' ? "X" : "  "} ] Depósito           [ ${ateste?.tipo_pagamento == 'Boleto/Fatura' ? "X" : "  "} ] Boleto/Fatura`,
            },
            {
              id: "contaBancaria",
              row: "Conta bancária da empresa (convênio)",
              value: `Conta ${ateste?.banco_tipo_conta}\n${ateste?.banco_nome}\nAgência ${ateste?.banco_agencia}, Número ${ateste?.banco_conta}`
            },
          ]);
        } else {
          setDadosEmpresa([
            {
              id: "empresa",
              row: "Empresa:",
              value: ateste?.supplier?.nome_razao_social ?? "-"
            },
            {
              id: "cpfCnpj",
              row: "CPF/CNPJ da empresa:",
              value: formatCPF(ateste?.supplier?.cpf_cnpj) ?? "-"
            },
            {
              id: "tipoPagamento",
              row: "Tipo de Pagamento:",
              value: `[ ${ateste?.tipo_pagamento == 'Depósito' ? "X" : "  "} ] Depósito           [ ${ateste?.tipo_pagamento == 'Boleto/Fatura' ? "X" : "  "} ] Boleto/Fatura`,
            },
            {
              id: "dadosBancarios",
              row: "Dados bancários da empresa:",
              value: `Conta ${ateste?.banco_tipo_conta}\n${ateste?.banco_nome}\nAgência ${ateste?.banco_agencia}, Número ${ateste?.banco_conta}`
            },
          ]);
        };
      };

      setObjetoPagamento([
        {
          id: "objeto",
          row: "Objeto do pagamento:",
          value: ateste?.objeto_pagamento ?? "-"
        },
        {
          id: "medicao",
          row: "Medição:",
          value: ateste?.medicao ?? "-"
        },
        {
          id: "notaTecnica",
          row: "Número da nota técnica:",
          value: ateste?.numero_nota_tecnica ?? "-"
        },
      ]);
    };

  }, [ateste]);

  useEffect(() => {
    if (processo?.id) {
      if (ateste?.attest_budgets?.length > 0) {
        const updatedLinhas = []

        ateste?.attest_budgets?.forEach((linha, index) => {
          if (index == 0) {
            updatedLinhas.push(
              {
                id: "linhas",
                row: "",
                value: "Linhas",
              },
            )
          }
          updatedLinhas.push(
            {
              id: "linha",
              row: `Linha ${index + 1}:`,
              value: `Contrato de gestão: ${linha?.contractual_instrument_budget?.project_line?.management_contract?.nome_completo}\nComitê: ${linha?.contractual_instrument_budget?.project_line?.comittee?.nome}\nCódigo do Projeto: ${linha?.contractual_instrument_budget?.project_line?.expense_type?.codigo}.${linha?.contractual_instrument_budget?.project_line?.organization?.codigo.padStart(2, '0')}.${linha?.contractual_instrument_budget?.project_line?.management_contract?.codigo.padStart(2, '0')}.${linha?.contractual_instrument_budget?.project_line?.comittee?.codigo.padStart(2, '0')}.${linha?.contractual_instrument_budget?.project_line?.unit?.codigo.padStart(2, '0')}.${linha?.contractual_instrument_budget?.project_line?.pap_finality?.codigo.padStart(2, '0')}.${linha?.contractual_instrument_budget?.project_line?.pap_program?.codigo.padStart(2, '0')}.${linha?.contractual_instrument_budget?.project_line?.pap_action?.codigo.padStart(2, '0')}.${linha?.contractual_instrument_budget?.project_line?.poa_sub_action?.codigo.padStart(2, '0')}.${processo?.project?.id.toString().padStart(2, '0')}.${linha?.contractual_instrument_budget?.project_line?.description?.codigo.padStart(3, '0')}\nValor: ${formatarReais(linha?.valor)}\nPercentual: ${linha?.percentual}%`
            },
          );
        });
        setLinhas(updatedLinhas);

        if (ateste?.invoices?.length > 0 && ateste?.invoices[0]?.id) {
          const updatedInvoices = [];

          ateste?.invoices?.forEach((invoice, index) => {
            console.log(invoice)
            if (index == 0) {
              updatedInvoices.push(
                {
                  id: "notasFiscais",
                  row: "",
                  value: "Notas Fiscais",
                },
              )
            }
            updatedInvoices.push(
              {
                id: "docFiscal",
                row: `Documento Fiscal ${index + 1}:`,
                value: `Documento Fiscal: ${invoice?.documento_fiscal ?? "-"}\nContrato de Gestão: ${invoice?.management_contract?.nome_completo ?? "-"}\n${organizeDataLinha(ateste?.invoices, invoice.id)} \nData: ${formatarData(invoice?.data, false) ?? "-"}\nValor: ${formatarReais(invoice?.valor) ?? "-"}`
              },
            );
          });
          setNotasFiscais(updatedInvoices);
        };
      };

    }
  }, [processo])


  if (ateste?.id && instrumento?.id && processo?.id) {
    const pdfData = {
      version: "Formulário eletrônico - SIGA Águas",
      title: "FICHA DE ATESTE",
      items: [
        {
          id: "agevapImg",
          logoWithColumns: true,
          itemsColumn: [
            {
              id: "atesteNumero",
              row: "ATESTE Nº",
              value: ateste?.numero_ateste ?? "-"
            },
            {
              id: "instrumentoNumero",
              row: "INSTRUMENTO Nº",
              value: ateste?.contractual_instrument?.numero ?? "-"
            },
          ]
        },
        {
          id: "processoNumero",
          row: "Processo Nº:",
          value: ateste?.contractual_instrument?.law_process?.codigo ?? "-"
        },
        {
          id: "modalidadeContratacao",
          row: "Modalidade da contratação:",
          value: ateste?.contractual_instrument?.tipo_instrumento ?? "-"
        },
        {
          id: "ordenador1",
          row: "Ordenador 01:",
          value: ateste?.ordenador1?.nome ?? "-"
        },
        {
          id: "ordenador2",
          row: "Ordenador 02:",
          value: ateste?.ordenador2?.nome ?? "-"
        },
        {
          id: "dataInicio",
          row: "Data de início do contrato:",
          value: formatarData(instrumento?.data_inicio_vigencia, false) ?? "-"
        },
        {
          id: "dataTermino",
          row: "Data de término do contrato:",
          value: formatarData(instrumento?.data_termino_vigencia, false) ?? "-"
        },
        {
          id: "valorContrato",
          row: "Valor do contrato:",
          value: formatarReais(instrumento?.valor_total) ?? "-",
        },
      ],
    };

    if (dadosEmpresa?.length > 0) {
      dadosEmpresa.forEach((dado) => pdfData.items.push(dado));
    };
    if (objetoPagamento?.length > 0) {
      objetoPagamento.forEach((obj) => pdfData.items.push(obj));
    };
    if (linhas?.length > 0) {
      linhas.forEach((linha) => pdfData.items.push(linha));
    };
    if (notasFiscais?.length > 0) {
      notasFiscais.forEach((nota) => pdfData.items.push(nota));
    };

    return (
      <PDFViewer width="100%" height="1300" className="app" >
        <DocumentPage data={pdfData} />
      </PDFViewer>
    );
  };
};


export default FinanceiroAtesteFichaPDF;