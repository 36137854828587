import { Box, CircularProgress } from "@mui/material";
import styled from "styled-components";

export const ButtonWithProgress = styled.button`
    padding: 10px;
    cursor: pointer;
    float: right;
    color: #fff;
    background: #366efa 0% 0% no-repeat padding-box;
    border: 2px solid #366efa;
    border-radius: 8px;

    &:disabled {
        cursor: default;
        color: #767676;
        background-color: #f5f5f5;
        border-color: #f5f5f5;
    }
`;

export const ProgressCircle = styled(CircularProgress)`
    color: #0000FF;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
`

export const BoxSave = styled(Box)`
    display: flex;
    alignItems: center;
    position: relative;
`