import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/auth';


const BasicDialog: React.FC = ({open, handleClose, titulo, buttons, children, ...props}) => {

    useEffect(() => {
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            {...props}
        >
            <DialogTitle>{titulo}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                {buttons}
            </DialogActions>
        </Dialog>
    );
};

export default BasicDialog;