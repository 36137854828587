import { Container } from "./styles";
import MainTable from "../../components/MainTable";
import { useEffect, useState } from "react";
import { Title } from "../../styles/global";
import useUsuario from "../../actions/cruds/useUsuario";
import { formatCPF, formatarData  } from "../../utils/formatDados";
import { useAuth } from "../../hooks/auth";
import getPermission from "../../utils/getPermission";

import api from "../../services/api";

export function Users() {
  // const { usuarios, getUsers } = useUsuario();
  const [logins, setLogins] = useState([]);

  // useEffect(() => {
  //   getUsers();
  // }, []);

  const {login} = useAuth();
  const usuariosPermission = getPermission(login,"Usuários") ?? '';

  useEffect(() => {
    api
      .get("logins")
      .then((response) => {
        setLogins(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`logins?page=${logins.pagination.current_page + page}`)
      .then((response) => {
        setLogins(response.data);
      })
      .catch((error) => console.log(error));
  };

  const organizeData = (data: any) => {
    const withoutData = <i>Sem dados</i>;

    if (data && typeof data === "object") {
      data = data.map((d: any) => {
        const orgao = d.organization ? d.organization.nome : withoutData;
        const ultimo_acesso = d.ultimo_acesso ? formatarData(d.ultimo_acesso) : withoutData;

        return {
          id: d.id,
          cpf: formatCPF(d.cpf),
          nome: d.nome,
          email: d.email,
          ativo: d.ativo,
          orgao: orgao,
          ultimo_acesso: ultimo_acesso,
        };
      });
    }

    return data;
  };

  const columnData = [
    { headerName: "Código", field: "id" },
    { headerName: "Usuário", field: "cpf" },
    { headerName: "Nome", field: "nome" },
    { headerName: "E-mail", field: "email" },
    { headerName: "Status", field: "ativo" },
  ];

  return (
    <>
      <Title color="#333">
        <span>Gestão de Usuários</span>
      </Title>
      <Container color="#FFF">
        <MainTable
          data={organizeData(logins.data)}
          pagination={logins.pagination}
          permission={usuariosPermission}
          handlePagination={handlePagination}
          registerLink="/usuarios/cadastrar"
          columns={columnData}
          dropdown={{
            view: { linkTo: "/usuarios/visualizar" },
            editar: { linkTo: "/usuarios/editar" },
            desativar: { linkTo: "users" },
          }}
        />
      </Container>
    </>
  );
}
