import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";

import { Container } from "./styles";
import { SelectInputReact, TextTitle } from "../../styles/global";
import useInstrumento from "../../actions/cruds/InstrumentoContratual/useInstrumento";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface IInstrumentoModalProps {
  instrumentoId: number;
  isOpen: boolean;
  type: string;
  handleClose: () => void;
}

const ModalInstrumento: React.FC<IInstrumentoModalProps> = ({
  instrumentoId,
  isOpen,
  type,
  handleClose,
}) => {
  const { instrumento, getInstrumento } = useInstrumento();
  const [instrumentosView, setInstrumentosView] = useState<Array<Object>>([]);
  const [instrumentosEdit, setInstrumentosEdit] = useState<Array<Object>>([]);
  const [valueInstrumento, setValueInstrumento] = useState<String>('instrumentos');

  const organizeDataAditivo = (data: any) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: `aditivo_${d.id}`,
          id: d.id,
          label: `Aditivo: ${d.nome}`,
          type: "aditivo"
        }));
        return data;
      }
    };
    return [];
  };

  const organizeDataApostilamento = (data: any) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: `apostilamento_${d.id}`,
          id: d.id,
          label: `Apostilamento: ${d.nome}`,
          type: "apostilamento"
        }));
        return data;
      }
    };
    return [];
  };


  useEffect(() => {
    if (instrumentoId != 0) {
      getInstrumento(instrumentoId);
    };

  }, [instrumentoId])

  useEffect(() => {
    if (type == 'visualizar') {
      setInstrumentosView([{ value: `instrumento_${instrumento.id}`, id: instrumento.id, label: `Instrumento ${instrumento.numero}`, type: "instrumento" }, ...organizeDataAditivo(instrumento.additives), ...organizeDataApostilamento(instrumento.apostilles)]);

    } else if (type == 'editar') {
      let maxIdAdditive;
      let maxIdApostilles;
      if (instrumento.id) {
        if (instrumento.additives) {
          maxIdAdditive = instrumento.additives.reduce((maxAdditive, currentAdditive) => {
            return currentAdditive.id > maxAdditive.id ? currentAdditive : maxAdditive;
          }, { id: -1 })
        };

        if (instrumento.apostilles) {
          maxIdApostilles = instrumento.apostilles.reduce((maxApostille, currentApostille) => {
            return currentApostille.id > maxApostille.id ? currentApostille : maxApostille;
          }, { id: -1 })
        };
      };


      if (maxIdAdditive && maxIdApostilles) {
        const dataAdditive = new Date(maxIdAdditive.created_at);
        const dataApostille = new Date(maxIdApostilles.created_at);
        if (dataAdditive > dataApostille) {
          setInstrumentosEdit([{ value: `aditivo_${maxIdAdditive.id}`, id: maxIdAdditive.id, label: `Aditivo: ${maxIdAdditive.nome}`, type: "aditivo" }]);
        } else if (dataApostille > dataAdditive) {
          setInstrumentosEdit([{ value: `apostilamento_${maxIdApostilles.id}`, id: maxIdApostilles.id, label: `Apostilamento: ${maxIdApostilles.nome}`, type: "apostilamento" }]);
        }
      } else if (maxIdAdditive && !maxIdApostilles) {
        setInstrumentosEdit([{ value: `aditivo_${maxIdAdditive.id}`, id: maxIdAdditive.id, label: `Aditivo: ${maxIdAdditive.nome}`, type: "aditivo" }]);
      } else if (maxIdApostilles && !maxIdAdditive) {
        setInstrumentosEdit([{ value: `apostilamento_${maxIdApostilles.id}`, id: maxIdApostilles.id, label: `Apostilamento: ${maxIdApostilles.nome}`, type: "apostilamento" }]);
      } else if (!maxIdAdditive && !maxIdApostilles) {
        setInstrumentosEdit([{ value: `instrumento_${instrumento.id}`, id: instrumento.id, label: `Instrumento ${instrumento.numero}`, type: "instrumento" }]);
      };
    }

  }, [instrumento, type])

  const handlerChangeInstrumento = (instrumento: Object) => {
    if (instrumento.type == 'instrumento') {
      setValueInstrumento(`/instrumentos/${type}/${instrumento.id}`);
    } else {
      setValueInstrumento(`/instrumentos/${instrumento.type}/${type}/${instrumento.id}`);
    };
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Container>
        <div className="modal">
          <div className="header">
            {type == 'visualizar' ? (
              <h2>Visualizar Instrumento</h2>
            ) : type == 'editar' ? (
              <h2>Editar Instrumento</h2>
            ) : null}

            <button onClick={handleClose}>X</button>
          </div>

          <div className="content">
            {type == 'visualizar' ? (
              <>
                <TextTitle>Selecione o instrumento para visualização:</TextTitle>
                <SelectInputReact
                  name="instrumento"
                  options={instrumentosView}
                  onChange={(e) => handlerChangeInstrumento(e)}
                />
              </>
            ) : type == 'editar' ? (
              <>
                <TextTitle>Selecione o instrumento para edição:</TextTitle>
                <SelectInputReact
                  name="instrumento"
                  options={instrumentosEdit}
                  onChange={(e) => handlerChangeInstrumento(e)}
                />
              </>
            ) : null}
          </div>

          <div className="row">
            <button className="button_cancel" onClick={handleClose}>
              Cancelar
            </button>
            {type == 'visualizar' ? (
              <Link to={valueInstrumento}>
                <button className="button_save">
                  <FontAwesomeIcon icon={faEye} size="1x" /> Visualizar
                </button>
              </Link>
            ) : type == 'editar' ? (
              <Link to={valueInstrumento}>
                <button className="button_save">
                  <FontAwesomeIcon icon={faPen} size="1x" /> Editar
                </button>
              </Link>
            ) : null}
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default ModalInstrumento;