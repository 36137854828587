import { FormHandles } from "@unform/core";
import { useEffect, useRef, useState } from "react";
import Panel from "../../../components/Panel";

import {
  ContainerButton,
  Form,
  Input,
  TextTitle,
  Title,
} from "../../../styles/global";

import { Container } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "../../../components/InputRadio";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import { formatCPF } from "../../../utils/formatDados";
import useLogin from "../../../actions/cruds/useLogin";


export function ViewUsers() {
  const { loginById, getLoginById } = useLogin();
  const { loginId } = useParams();

  const [tipoUsuario, setTipoUsuario] = useState<String>();
  const [dados, setDados] = useState([]);
  const [defaultValueDados, setDefaultValueDados] = useState([]);

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    getLoginById(loginId);
  }, [])


  useEffect(() => {
    console.log(loginById)
    setTipoUsuario(loginById?.tipo_usuario);

    const tipoUsuarioRadio = formRef.current?.getFieldRef("tipo_usuario");
    if (tipoUsuario == 'empregado') {
      tipoUsuarioRadio[0].checked = true;
    } else if (tipoUsuario == 'representante') {
      tipoUsuarioRadio[1].checked = true;
    } else if (tipoUsuario == 'externo') {
      tipoUsuarioRadio[2].checked = true;
    };

    setDados(loginById?.ver_organizacoes);
    if (dados?.length > 0) {
      dados.forEach((obj) => {
        let initialValueDados = optionsDadosOrganizacoes.find((org) => org.value == obj)

        if (!defaultValueDados.some((item) => item.value === initialValueDados.value)) {
          console.log(initialValueDados)
          setDefaultValueDados((prevValue) => [
            ...prevValue,
            initialValueDados.label
          ]);
        };
      });
    };

  }, [loginById])

  const organizeDataPermissao = (data: any) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          id: d.profile?.tbl_module?.id,
          nome: d.profile?.tbl_module?.nome,
          permissao: d.profile?.nome,
        }));
      };
    };
    data?.sort((a, b) => a.id - b.id)

    return data;
  };

  const optionsTipoUsuario = [
    { value: "empregado", label: " Empregado" },
    { value: "representante", label: " Representante" },
    { value: "externo", label: " Acesso externo (novo usuário)" },
  ];

  const optionsDadosOrganizacoes = [
    { value: 1, label: "AGEVAP" },
    { value: 2, label: "AGEDOCE" },
  ]

  const dataTitlePermissao: GridColDef[] = [
    { field: "id", headerName: "Item", flex: 1 },
    { field: "nome", headerName: "Módulo", flex: 2 },
    { field: "permissao", headerName: "Permissão", flex: 2 },
  ];

  return (
    <Container>
      <Form ref={formRef}>
        <Title color="#333">
          <span>Visualizar usuário</span>
        </Title>
        {loginById && (
          <>
            <Panel titulo="Cadastro do usuário">
              <Grid container spacing={6}>
                <Grid xs={12}>
                  <TextTitle>Tipo do usuário: </TextTitle>
                  <Radio
                    name="tipo_usuario"
                    options={optionsTipoUsuario}
                    disabled
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>CPF: </TextTitle>
                  <Input type="text" name="cpf" mask="cpf" defaultValue={loginById ? formatCPF(loginById.cpf) : ''} disabled />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Nome: </TextTitle>
                  <Input type="text" name="nome" defaultValue={loginById?.nome} disabled />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Email: </TextTitle>
                  <Input type="email" name="email" defaultValue={loginById?.email} disabled />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Selecione quais dados o usuário irá visualizar: </TextTitle>
                  <Input
                    type="text"
                    name="dados_usuario"
                    value={defaultValueDados}
                    disabled
                  />
                </Grid>
              </Grid>
            </Panel>

            <Panel titulo="Permissões">
              <Grid container spacing={6}>
                <Grid xs={12}>
                  <SecondaryTable
                    columsData={dataTitlePermissao}
                    rowsData={organizeDataPermissao(loginById?.login_profiles)}
                  />
                </Grid>
              </Grid>
            </Panel>
          </>
        )}

        <ContainerButton>
          <Link to="/usuarios">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
            </button>
          </Link>
        </ContainerButton>
      </Form>
    </Container>
  );
};