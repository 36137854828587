import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ContainerButton, Input, TextTitle, Title } from '../../../styles/global';
import Panel from '../../../components/Panel';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faX } from '@fortawesome/free-solid-svg-icons';
import useProcesso from '../../../actions/cruds/Processos/useProcesso';

const ProcessoNormaInterna: React.FC = () => {
    const { processoId } = useParams();
    const { processo, getProcesso, updateProcesso } = useProcesso();

    const [contratosGestaoUpdate, setContratosGestaoUpdate] = useState<Array<Number>>([]);

    useEffect(() => {
        getProcesso(processoId);
    }, [])

    useEffect(() => {
        if (processo?.id) {
            formRef.current?.setFieldValue("norma_interna_numero", processo?.norma_interna_numero);
            formRef.current?.setFieldValue("norma_interna_criacao", processo?.norma_interna_criacao);
            formRef.current?.setFieldValue("norma_interna_codigo_proton", processo?.norma_interna_codigo_proton);
        }

        if (processo?.management_contracts) {
            let contratosGestao: number[] = [];
            processo?.management_contracts.forEach((contrato) => contratosGestao.push(contrato.id));
            setContratosGestaoUpdate(contratosGestao);
        };
    }, [processo])

    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async (data: object) => {
            try {
                data["management_contract_ids"] = contratosGestaoUpdate;

                console.log("data", data);
                updateProcesso(processoId, data);
            } catch (err) {
                console.log(err);
            }
        }, [contratosGestaoUpdate],
    );

    return (
        <Container>
            <Title>
                <span>Norma Interna</span>
            </Title>

            <Form id="form1" ref={formRef} onSubmit={handleSubmit} >
                <Panel titulo='Norma Interna'>
                    <Grid container spacing={6}>
                        <Grid xs={6}>
                            <TextTitle>Número:</TextTitle>
                            <Input type="text" name='norma_interna_numero' required />
                        </Grid>
                        <Grid xs={6}>
                            <TextTitle>Data de criação:</TextTitle>
                            <Input type="date" name='norma_interna_criacao' required />
                        </Grid>
                        <Grid xs={6}>
                            <TextTitle>Proton:</TextTitle>
                            <Input type="text" name='norma_interna_codigo_proton' required />
                        </Grid>
                    </Grid>
                </Panel>

                <ContainerButton>
                    <Link to="/processos">
                        <button><FontAwesomeIcon icon={faX} size="1x" /> Cancelar</button>
                    </Link>
                    <button type="submit" form="form1"><FontAwesomeIcon icon={faSave} /> Salvar</button>
                </ContainerButton>
            </Form>
        </Container>
    );
};

export default ProcessoNormaInterna;