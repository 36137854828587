import React from 'react';
import Header from '../../../components/Header';
import Panel from '../../../components/Panel';
import { CSidebar } from '../../../components/Sidebar';
import { Container } from '../../../styles/global';
import { Loader } from './styles';

const InstrumentsDashboard: React.FC = () => {
    return (
        <div style={{display: 'flex', height: '100%'}}>
            <div style={{height: '100%'}}>
                <CSidebar />
            </div>
            <div style={{display: 'block', width: '100%'}}>
                <Header /> 

                <Container>
                    <Panel titulo='Dashboard de Instrumentos Contratuais'>
                        <Loader src="https://sigaaguas.org.br/portal/apps/opsdashboard/index.html#/c0d3f31563794261ba5ea4f9afd0cd9d" frameborder="0" title='ArcGIS Dashboard'></Loader>
                    </Panel>
                </Container>
            </div>
        </div> 
    );
};

export default InstrumentsDashboard;